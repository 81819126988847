import { number } from 'prop-types';
import React, { memo } from 'react';
import pluralize from 'pluralize';

const styles = {
  color: 'var(--secondary-dark-color)',
  fontFamily: 'Fira Sans',
  fontWeight: 300,
  fontSize: 12,
};

const NumberOfAssets = ({ children }) => <span style={styles}>{pluralize('asset', children || 0, true)}</span>;

NumberOfAssets.defaultProps = {
  children: null,
};

NumberOfAssets.propTypes = {
  children: number,
};

export default memo(NumberOfAssets);
