import getDownloadUrl from '../getDownloadUrl';
import downloadByGS from './downloadByGS';
import downloadFile from './downloadFile';
import * as utils from '../../shared/utils';
import picsioConfig from '../../../../../config';

/**
 * Download file by id and revisionId
 * @param  {String} id - asset id
 * @param  {String} name - filename to save file with.
 * @param  {String?} revisionId - asset revision id
 * @returns {Blob}
 */
async function downloadAsset(id, name, revisionId) {
  /** Get download url */
  const url = await getDownloadUrl({ assetId: id, revisionId });

  if (url.startsWith(picsioConfig.zipperDownloadByGSUrl)) {
    return downloadByGS(url).promise;
  }

  const blob = await downloadFile(url).promise;
  /** save file */
  utils.saveFile(blob, name);
  return blob;
}

export default downloadAsset;
