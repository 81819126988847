/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const changeTheme = createAsyncThunk(
  'inboxes/changeTheme',
  async ({ _id, theme }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  }) => {
    try {
      const { data } = await sdk.inboxes.addTheme(_id, theme);
      return data;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not change inbox theme';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error changing inbox theme'),
        { error },
        ['ChangeInboxThemeFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      changeTheme.pending,
      (state, { meta }) => {
        const { _id } = meta.arg;
        state.inboxes.forEach((inbox) => {
          if (inbox._id === _id) inbox.isBusy = true;
        });
      },
    )
    .addCase(
      changeTheme.fulfilled,
      (state, { payload }) => {
        state.inboxes.forEach((inbox) => {
          if (inbox._id === payload._id) {
            inbox.theme = payload.theme;
            delete inbox.isBusy;
          }
        });
      },
    )
    .addCase(
      changeTheme.rejected,
      (state, { meta, error }) => {
        state.inboxes.forEach((inbox) => {
          if (inbox._id === meta.arg.id) {
            delete inbox.isBusy;
          }
        });
        state.error = error;
      },
    );
};
