import Q from 'q';
import picsioConfig from '../../../../config';
import Logger from '../services/Logger';
import localization from '../shared/strings';
import { showDialog } from '../components/dialog';

// eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
const isMobileApp = __IS_MOBILE__; // it's constant from Webpack

/**
 * Upload file
 * @param {string} url
 * @param {File} file
 * @param {Object?} options
 * @param {string?} options.method - by default PUT
 * @returns {Object} { promise: Promise, cancel: Function }
 */
export function uploadFile(url, file, { method = 'PUT' }) {
  let normalizedApiUrl = url;
  if (isMobileApp) {
    normalizedApiUrl = `${picsioConfig.apiBaseUrl}${url}`;
    if (url.startsWith('https://') || url.startsWith('http://')) {
      normalizedApiUrl = url;
    }
  }
  const deferred = Q.defer();
  const xhr = new XMLHttpRequest();

  deferred.notify({ msg: 'Uploading image...', percent: 0 });
  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const percent = event.loaded / event.total;
      deferred.notify({ msg: `Uploading image... ${Math.round(percent * 100)}%`, progress: percent });
    }
  };

  xhr.onload = () => {
    if (xhr.status >= 400) return deferred.reject(xhr);

    try {
      deferred.resolve(JSON.parse(xhr.response));
    } catch (err) {
      deferred.resolve(xhr.response);
    }
  };
  xhr.onabort = deferred.reject;
  xhr.onerror = () => {
    deferred.reject();
  };
  xhr.open(method, normalizedApiUrl, true);

  xhr.send(file);

  return {
    promise: deferred.promise,
    cancel: xhr.abort,
  };
}

export function showUploadFileErrorDialog(error, extension = '', confirmHandler) {
  const { FILE_TO_LARGE, INVALID_FILE_TYPE, CANNOT_UPLOAD_FILE, btnCancel, btnOk, btnReinstall } = localization.DIALOGS;
  let title;
  let text = CANNOT_UPLOAD_FILE;
  let textBtnCancel = null;
  let textBtnOk = btnOk;
  let onOk;

  const setUnsupportedFileTypeDialogData = () => {
    title = INVALID_FILE_TYPE.TITLE;
    text = extension ? INVALID_FILE_TYPE.TEXT(extension) : INVALID_FILE_TYPE.TEXT_IN_GENERAL;
    if (extension) {
      textBtnCancel = btnCancel;
      textBtnOk = btnReinstall;
      onOk = confirmHandler;
    }
  };

  if (error.message === 'UnsupportedFileType') {
    setUnsupportedFileTypeDialogData();
  }

  if (error && error.response && error.response.data) {
    try {
      const { subcode } = error.response?.data || {};
      if (subcode === 'FileIsToBig') {
        text = FILE_TO_LARGE.TEXT_IN_GENERAL;
        title = FILE_TO_LARGE.TITLE;
      }
      if (subcode === 'UnsupportedFileType') {
        setUnsupportedFileTypeDialogData();
      }
    } catch (err) {
      Logger.warn('Cannot upload file BAD RESPONSE', err);
    }
  }
  showDialog({ title, text, textBtnCancel, textBtnOk, onOk });
  Logger.error(new Error('Cannot upload file'), { error });
}
