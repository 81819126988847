import TYPES from '../../action-types';

/**
 * Change collection description immediately
 * @param {string} collectionId
 * @param {string} color
 */
function changedCollectionDescription(collectionId, description) {
  return async (dispatch) => {
    dispatch({
      type: TYPES.COLLECTIONS.CHANGE_DESCRIPTION.COMPLETE,
      payload: {
        collectionId,
        description,
      },
    });
  };
}

export default changedCollectionDescription;
