import { createAsyncThunk } from '@reduxjs/toolkit';
import { showDialog } from '../../../components/dialog';

const buyAICredits = createAsyncThunk(
  'billing/buyAICredits',
  async (payload, {
    rejectWithValue, extra: {
      sdk, localization, UiBlocker, Logger, Toast,
    },
  }) => {
    try {
      UiBlocker.block(localization.BILLING.textProcessingRequest);
      const { data: newAICreditsAmount } = await sdk.billing.buyAICredits(payload);
      if (newAICreditsAmount) {
        Logger.log('UI', 'AICreditsPurchaseSuccessToast');
        Toast(localization.STORAGE.textAICreditsBuySuccess(payload));
      }
      return newAICreditsAmount;
    } catch (e) {
      const userDialogueMessage = localization.BILLING.textBuyAiCreditsFailed;
      Logger.log('UI', 'SettingsBillingBuyCreditsFailedDialog', { userDialogueMessage });
      showDialog({
        title: localization.BILLING.titleTransactionFailed,
        text: userDialogueMessage,
        textBtnCancel: null,
      });

      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default buyAICredits;
