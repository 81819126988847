import { createAsyncThunk } from '@reduxjs/toolkit';

import * as collectionHelpers from '../../helpers/collections';

const setActiveCollection = createAsyncThunk(
  'collections/setActiveCollection',
  async (collectionId, { rejectWithValue, dispatch, getState }) => {
    const currectActiveCollections = getState().collections.activeCollections;
    try {
      if (collectionId === null) {
        if (currectActiveCollections.length === 0) return;
        dispatch({ type: 'collections/setActiveCollection', payload: { collections: [] } });
        return;
      }

      let collectionIds = collectionId;
      if (typeof collectionId === 'string') {
        collectionIds = [collectionId];
      }
      const collectionsStatuses = await collectionHelpers.forceFindTagWithTagId({
        collections: getState().collections.collections,
        search: getState().collections.search,
        collectionIds,
      });

      const collections = collectionsStatuses.filter((collection) => !!collection);

      // @TODO: receive from server `hasChild` by forceFindTagWithTagId
      // collection.hasChild = true;

      if (collections.length === 1 && currectActiveCollections[0]?._id === collections[0]?._id && currectActiveCollections.length === collections.length) return;

      dispatch({ type: 'collections/setActiveCollection', payload: { collections } });

      return {
        collections,
      };
    } catch (e) {
      return rejectWithValue(e);
    } finally { }
  },
);

export default setActiveCollection;
