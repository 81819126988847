import Logger from '../../../services/Logger';
import l18n from '../../../shared/strings';
import TYPES from '../../action-types';
import Toast from '../../../components/Toast';
import sdk from '../../../sdk';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Set search
 * @param {string} value
 */
function applySearch(value) {
  return async (dispatch) => {
    if (value.length < 3) {
      dispatch({
        type: TYPES.COLLECTIONS.SEARCH.SET,
        payload: { value },
      });
      return;
    }

    dispatch({
      type: TYPES.COLLECTIONS.SEARCH.SET,
      payload: { value },
    });
    dispatch({ type: TYPES.COLLECTIONS.SEARCH.START });
    try {
      const { data: collections } = await sdk.collections.search(value);
      if (Array.isArray(collections)) {
        dispatch({
          type: TYPES.COLLECTIONS.SEARCH.COMPLETE,
          payload: {
            collections,
            value,
          },
        });
      } else {
        console.error('onSearch erorr', collections);
        Toast(l18n.TAGSTREE.textCantCompleteSearch, { type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
        dispatch({ type: TYPES.COLLECTIONS.SEARCH.FAILED, error: collections });
      }
    } catch (error) {
      console.error('onSearch erorr', error);
      Toast(l18n.TAGSTREE.textCantCompleteSearch, { type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
      dispatch({ type: TYPES.COLLECTIONS.SEARCH.FAILED, error });
      Logger.error(new Error('Can not search for collections'), { error }, [
        'SearchForCollectionFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  };
}

export default applySearch;
