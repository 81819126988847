/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createdPresetEvent = createAsyncThunk(
  'websiteSettings/createdPresetEvent',
  async (id, { extra: { sdk, Logger } }) => {
    try {
      const { data: preset } = await sdk.websiteSettingsPresets.getOne(id);
      return preset;
    } catch (error) {
      Logger.error(
        new Error('Can not get newly created website preset (by teammate)'),
        { error },
        ['GetNewlyCreatedPresetFailed', error.message],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    createdPresetEvent.fulfilled,
    (state, { payload }) => {
      state.presets.unshift(payload);
    },
  );
};
