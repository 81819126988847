import TYPES from '../../action-types';

/**
 * @param {Object[]} thumbnails
 */
function setThumbnails(thumbnails) {
  return (dispatch, getAll) => {
    const { team } = getAll().user;
    const isUsedS3Storage = team && team.storageType === 's3';
    dispatch({
      type: TYPES.ASSETS.SET_THUMBNAILS,
      payload: { thumbnails, isUsedS3Storage },
    });
  };
}

export default setThumbnails;
