import TYPES from '../../action-types';
import * as assetsHelpers from '../../../helpers/assets';
import localization from '../../../shared/strings';

import getThumbnails from './getThumbnails';

/**
 * Get one asset
 * @param {string[]} id
 */
const getTmpAssets = (ids) => async (dispatch, getAll) => {
  try {
    dispatch({ type: TYPES.ASSETS.FETCH_TMP.START });
    const data = await Promise.all(ids.map(assetsHelpers.fetchOneAsset));

    const items = data.map((res) => res.data);
    const _ids = items.map((asset) => asset._id);
    if (_ids.length) getThumbnails(_ids)(dispatch, getAll);
    // we need 'rolePermissions=null' for Proofing. We don't need to check assets permissions there.
    const { permissions: rolePermissions = null } = getAll().user?.role || {};

    dispatch({
      type: TYPES.ASSETS.FETCH_TMP.COMPLETE,
      payload: {
        items,
        ids,
        rolePermissions,
      },
    });
  } catch (error) {
    dispatch({ type: TYPES.ASSETS.FETCH_TMP.FAILED, error });
    window.dispatchEvent(
      new CustomEvent('hardError', {
        detail: {
          data: {
            message: localization.PREVIEW_VIEW.fileNotFound,
          },
        },
      }),
    );
  }
};

export default getTmpAssets;
