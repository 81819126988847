import _uniqBy from 'lodash/uniqBy';
import sdk from '../../sdk';
import preparePermissions from './preparePermissions';
import getNegativePermissions from './getNegativePermissions';
import picsioConfig from '../../../../../config';
import localization from '../../shared/strings';
import Logger from '../../services/Logger';
import { showErrorDialog } from '../../components/dialog/index';

const getAggregatedData = async (assetsIds, user) => {
  try {
    const { data } = await sdk.assets.getAggregatedData(assetsIds);
    if (Object.keys(data).length === 0) {
      return {};
    }
    const rolePermissions = (picsioConfig.isMainApp && user.role.permissions) || {};
    let permissions = preparePermissions(
      { ...data.permissions, ...rolePermissions },
      data.archived,
    );
    if (data.isSomeTrashed) permissions = getNegativePermissions(permissions);
    return {
      isBusy: false,
      meta: data.meta,
      color: data.color,
      rating: data.rating,
      flag: data.flag,
      title: data.title,
      description: data.description,
      keywords: data.keywords,
      tags: data.tags
        .filter((n) => n.path !== '/')
        .map((n) => ({
          path: n.path.startsWith('/root/') ? n.path.replace(/\/root\//, '') : n.path,
          _id: n._id,
        })),
      lightboards: data.lightboards.filter(
        (lightboard) => user._id === lightboard.userId,
      ),
      keywording: data.keywording,
      faceRecognizing: data.faceRecognizing,
      replicating: data.replicating,
      metadating: data.metadating,
      transcribing: data.transcribing,
      contenting: data.contenting,
      thumbnailing: data.thumbnailing,
      keywordingReason: data.keywordingReason,
      replicatingReason: data.replicatingReason,
      metadatingReason: data.metadatingReason,
      contentingReason: data.contentingReason,
      thumbnailingReason: data.thumbnailingReason,
      keywordingErrorCode: data.keywordingErrorCode,
      replicatingErrorCode: data.replicatingErrorCode,
      faceRecognizingReason: data.faceRecognizingReason,
      faceRecognizingErrorCode: data.faceRecognizingErrorCode,
      metadatingErrorCode: data.metadatingErrorCode,
      converting: data.converting,
      transcribingReason: data.transcribingReason,
      transcribingErrorCode: data.transcribingErrorCode,
      contentingErrorCode: data.contentingErrorCode,
      convertingErrorCode: data.convertingErrorCode,
      thumbnailingErrorCode: data.thumbnailingErrorCode,
      describing: data.describing,
      describingReason: data.describingReason,
      describingErrorCode: data.describingErrorCode,
      assignees: _uniqBy(
        data.assignees.map((item) => ({ _id: item.assigneeId })),
        '_id',
      ),
      isDownloadable: picsioConfig.isMainApp && data.permissions.downloadFiles && data.isAssetDownloadable,
      permissions,
      isRestricted: data.isSomeRestricted,
      restrictReason: data.restrictReason,
      restrictStartAt: data.restrictStartAt,
      restrictExpiresAt: data.restrictExpiresAt,
      restrictStartAtPlaceholder:
        data.isSomeRestricted && !data.restrictStartAt
          ? localization.DETAILS.placeholderMultipleSelection
          : null,
      restrictExpiresAtPlaceholder:
        data.isSomeRestricted && !data.restrictExpiresAt
          ? localization.DETAILS.placeholderMultipleSelection
          : null,
      isArchived: data.archived,
      archivedByReason: data.archivedByReason,
      modifiedMetaFields: data.modifiedMetaFields,
      paramsForHighlight: data.paramsForHighlight,
      watermarkId: data.watermarkId,
      trashed: data.trashed,
    };
  } catch (error) {
    Logger.error(new Error('Can not get aggregatedData'), { error });
    showErrorDialog(localization.ERROR_GETTING_AGGREGATED_DATA);
  }
};

export default getAggregatedData;
