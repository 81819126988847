import * as assetsHelpers from '../../../helpers/assets';
import { findCollection } from '../../helpers/collections';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import {
  getChildren as getChildrenCollectionsAction,
} from '../collections';
import showSelectFromTreeDialog from '../../../helpers/showSelectFromTreeDialog';
import findAndResolveDuplicates from '../../helpers/assets/findAndResolveDuplicates';
import sdk from '../../../sdk';
import { showDialog } from '../../../components/dialog';
import UiBlocker from '../../../services/UiBlocker';
import { navigate, reloadCurrentPage } from '../../../helpers/history';
import sortFailed from '../../helpers/assets/sortFailed';

const radioItems = [
  { value: 'previousCollection', label: localization.DIALOGS.RESTORE_ASSETS.previousCollectionRadioLabel },
  { value: 'selectCollection', label: localization.DIALOGS.RESTORE_ASSETS.selectCollectionRadioLabel },
];

/**
 * Restore assets from trash
 * @param {string[]} ids
 */
const restoreAssets = (ids) => async (dispatch, getAll) => {
  const store = getAll();
  const { collections } = store.collections;
  const { items: assetsInStore } = store.assets;
  const isUserOnS3 = store.user.team.storageType === 's3';

  showSelectFromTreeDialog({
    title: localization.DIALOGS.RESTORE_ASSETS.title(ids.length),
    text: localization.DIALOGS.RESTORE_ASSETS.text(ids.length),
    treeListItems: [collections.my] || [],
    onLoadChildren: (item) => getChildrenCollectionsAction(item._id)(dispatch),
    onOk,
    openedItems: [collections.my._id],
    type: 'default',
    textBtnOk: localization.DIALOGS.btnRestore,
    radioItems,
    defaultRadioItemValue: 'previousCollection',
    showTreeValue: 'selectCollection',
    showTreePermanent: false,
    multipleSelection: true,
  });

  async function onOk(selectedCollections) {
    const collectionId = selectedCollections[0]?._id;
    const collectionIds = selectedCollections.map(({ _id }) => _id);
    const collection = findCollection(getAll().collections.collections, 'my', { _id: collectionId });
    let idsToRestore = ids;
    let actions;

    if (isUserOnS3) {
      /** Check duplicated filenames */
      try {
        let assets;
        if (assetsInStore.length < ids.length) {
          const { data: res } = await assetsHelpers.getAssetsBySchemaForSearchQuery(
            { _id: 1, name: 1 },
          );
          assets = res.images;
        } else {
          assets = assetsInStore;
        }
        const assetsToRestore = assets.filter((a) => ids.includes(a._id));
        const resolveResult = await findAndResolveDuplicates({
          assets: assetsToRestore,
          collectionPath: collection ? collection.path + collection.name : '/',
          user: store.user,
        });
        idsToRestore = resolveResult.assets.map((a) => a._id);
        actions = resolveResult.actions;
      } catch (error) {
        /** User press "Cancel" */
        if (error?.status === 'CANCELED') return;
        /** if "Too many items to analyze" ( more than 10 000 ) */
        const errorStatus = utils.getStatusFromResponceError(error);
        if (errorStatus === 413) {
          const { TITLE, TEXT, CANCEL_TEXT } = localization.DIALOGS.LIMIT_CHECK_DUPLICATES;
          showDialog({
            title: TITLE,
            text: TEXT,
            textBtnCancel: CANCEL_TEXT,
            textBtnOk: null,
            style: { maxWidth: 600 },
          });
        } else {
          Logger.error(
            new Error('Can not find duplicates on the server [Restore from trash]'),
            { error, showDialog: true },
            ['FindDuplicatesFailed', (error && error.message) || 'NoMessage'],
          );
        }
        /** IF CAN NOT RESOLVE DUPLICATES -> EXIT */
        return;
      }
      /** do nothing if all assets skipped */
      if (idsToRestore.length < 1) return;
    }
    try {
      /** Restore assets */
      UiBlocker.block();
      dispatch({
        type: TYPES.ASSETS.RESTORE_ASSETS.START,
        payload: { ids: idsToRestore },
      });

      const { data } = await sdk.assets.untrash(idsToRestore, collectionIds, actions);

      const {
        queued, successful = [], failed = [], failedAssets = [],
      } = data;

      if (failed.length) {
        const { failedGDArr, failedOtherArr } = sortFailed(failedAssets, 'MissingCanUntrashGoogleDriveCapabilityError');

        if (failedGDArr.length) {
          const hideDialog = showDialog({
            title: localization.DIALOGS.ERROR_UNTRASH_DIALOG.TITLE,
            children: ids.length > 1
              ? localization.DIALOGS.ERROR_UNTRASH_DIALOG.TEXT_ASSET_BULK_OPERATION(
                successful.length, failedGDArr.length, () => {
                  navigate('/storage');
                  hideDialog();
                },
              )
              : localization.DIALOGS.ERROR_UNTRASH_DIALOG.TEXT_ASSET_ONE_OPERATION(
                () => {
                  navigate('/storage');
                  hideDialog();
                },
              ),
            textBtnCancel: null,
          });
        }

        if (failedOtherArr.length) {
          if (failedOtherArr.find(({ error }) => error.code === 403)) {
            Toast(localization.DETAILS.textNotPermissionRestoreAsset, { type: 'error' });
          } else {
            Toast(
              localization.DETAILS.textCantRestoreAsset(failedOtherArr.length),
              { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh },
            );
          }
        }

        dispatch({
          type: TYPES.ASSETS.RESTORE_ASSETS.FAILED,
          payload: { ids: failed },
        });
      }

      if (queued) {
        Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
      } else {
        dispatch({
          type: TYPES.ASSETS.RESTORE_ASSETS.COMPLETE,
          payload: { ids: successful },
        });
      }
    } catch (error) {
      Logger.error(new Error('Error restore assets'), { error }, [
        'RestoreAssetFailed',
        (error && error.message) || 'NoMessage',
      ]);
      dispatch({
        type: TYPES.ASSETS.RESTORE_ASSETS.FAILED,
        payload: { ids: idsToRestore },
        error,
      });
    }
    UiBlocker.unblock();
  }
};

export default restoreAssets;
