import picsioConfig from '../../../../../config';
/**
 * Download zipped assets on proofing
 * @param {string} storageType - 'gd' | 's3'
 */
export default async function downloadWebsiteZip(storageType) {
  let zipperUrl = picsioConfig.zipperZipUrl;
  if (storageType === 'gd') {
    zipperUrl = picsioConfig.zipperZipUrlGD;
  }
  if (storageType === 's3') {
    zipperUrl = picsioConfig.zipperZipUrlS3;
  }

  window.open(
    `${zipperUrl}?websiteId=${picsioConfig.access._id}&mimeType=original&resizing=original`,
    '_blank',
  );
}
