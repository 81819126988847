import React, { useState, useEffect, useMemo } from 'react';

import { connect, useSelector } from 'react-redux';
import l18n from '../../shared/strings';
import sdk from '../../sdk';

import { changeDateFormat } from './helpers/changeDateFormat';
import createTemmateOptions from './helpers/createTeammateOptions';
import AnalyticsGraph from './AnalyticsGraph';
import SearchableDropdown from '../../UIComponents/SearchableDropdown';
import UpgradePlanBlock from '../UpgradePlanBlock';
import helpersLink from '../Billing/Overview/Features/constants/helpersLink';

const AssetAnalyticsScreen = (props) => {
  const isSupported = props.subscriptionFeatures.assetAnalytics;
  const teammates = useSelector((state) => state.teammates.items);
  const assetDownloadedDropdownOptions = useMemo(
    () => createTemmateOptions(teammates), [teammates],
  );

  const [assetDownloaded, setAssetDownloaded] = useState([]);
  const [assetDownloadedFetching, setAssetDownloadedFetching] = useState(false);
  const [filtersDownloadedByTeam, setFilterDownloadedByTeam] = useState([]);

  const [assetChanged, setAssetChanged] = useState([]);
  const [assetChangedFetching, setAssetChangedFetching] = useState(false);

  const [metadataChangedData, setMetadataChangedData] = useState([]);
  const [metadataChangedDataFetching, setMetadataChangedDataFetching] = useState(false);

  const onCheckedFilterDownloadedByTeam = (value) => {
    setFilterDownloadedByTeam(value);
  };

  const fetchDownloadedData = async () => {
    try {
      setAssetDownloadedFetching(true);
      const { data: assetDownloadedByTeamData } = await sdk.metrics.assetStatsByEvents(
        props.asset._id, ['assets.downloaded'], filtersDownloadedByTeam,
      );
      setAssetDownloaded(changeDateFormat(assetDownloadedByTeamData, 'barChart'));
    } catch (err) {
      console.error(err);
    } finally {
      setAssetDownloadedFetching(false);
    }
  };

  const fetchChangedData = async () => {
    try {
      setAssetChangedFetching(true);
      const { data: assetChangedData } = await sdk.metrics.assetStatsByEvents(
        props.asset._id, ['asset.revision.created'],
      );
      setAssetChanged(changeDateFormat(assetChangedData, 'barChart'));
    } catch (err) {
      console.error(err);
    } finally {
      setAssetChangedFetching(false);
    }
  };

  const fetchAssetsWithMetadataChanged = async () => {
    try {
      setMetadataChangedDataFetching(true);
      const { data: assetMetadataChangedData } = await sdk.metrics.assetStatsByEvents(
        props.asset._id,
        [
          'assets.flag.changed',
          'assets.rating.changed',
          'assets.color.changed',
          'assets.keyword_attached',
          'assets.metadata.changed',
          'assets.keyword_detached',
          'assets.keywords_detached',
        ],
      );
      setMetadataChangedData(changeDateFormat(assetMetadataChangedData, 'barChart'));
    } catch (err) {
      console.error(err);
    } finally {
      setMetadataChangedDataFetching(false);
    }
  };

  useEffect(() => {
    if (props.subscriptionFeatures.assetAnalytics) {
      fetchDownloadedData();
    }
  }, [filtersDownloadedByTeam]);

  useEffect(() => {
    if (props.subscriptionFeatures.assetAnalytics) {
      fetchChangedData();
      fetchAssetsWithMetadataChanged();
    }
  }, []);

  return (
    <div className="assetAnalytics">
      <If condition={!isSupported}>
        <div className="upgradePlanContainer">
          <UpgradePlanBlock
            title={l18n.UPGRADE_PLAN_BLOCK.assetAnalytics.title}
            description={l18n.UPGRADE_PLAN_BLOCK.assetAnalytics.description}
            helperText={l18n.UPGRADE_PLAN_BLOCK.assetAnalytics.helperText}
            helperLink={helpersLink.analytics}
            featureName="analytics"
            amplitudeEventName="AssetAnalyticsUpgradePlanClicked"
            maxWidth={800}
            redirectToBilling
          />
        </div>
      </If>
      <div className="analyticDropdownWrapper">
        <SearchableDropdown
          options={assetDownloadedDropdownOptions}
          isSearchable
          tagType="user"
          placeholder={filtersDownloadedByTeam.length > 0 ? 'Teammates' : 'Any teammate'}
          onSelect={onCheckedFilterDownloadedByTeam}
          closeOnSelect
          dataTestId="dropdownAssetDownloaded"
        />
      </div>
      <AnalyticsGraph
        type="barGraph"
        fetching={assetDownloadedFetching}
        data={assetDownloaded}
        isSupported={isSupported}
        title={l18n.ANALYTICS.titleAssetDownloadedByTeam}
      />
      <AnalyticsGraph
        type="barGraph"
        fetching={assetChangedFetching}
        data={assetChanged}
        isSupported={isSupported}
        title={l18n.ANALYTICS.titleAssetChanged}
      />
      <AnalyticsGraph
        type="barGraph"
        fetching={metadataChangedDataFetching}
        data={metadataChangedData}
        isSupported={isSupported}
        title={l18n.ANALYTICS.titleMetadataChangedData}
      />
    </div>
  );
};

const ConnectedAnalytics = connect((store) => ({
  subscriptionFeatures: store.user.subscriptionFeatures,
}))(AssetAnalyticsScreen);

export default ConnectedAnalytics;
