import getThumbnailUrls from '../../../helpers/getThumbnailUrls';
import picsioConfig from '../../../../../../config';
import Logger from '../../../services/Logger';

import setThumbnails from './setThumbnails';

function checkThumbnailErrorInvalidCredentials(thumbnail) {
  return thumbnail.error && thumbnail.error.code === 401;
}

/**
 * @param {String[]} ids
 * @param {Boolean?} checkFor401 - if GD returns 401 error for thumb we needs to refresh roken
 */
function getThumbnails(ids, checkFor401 = true) {
  return async (dispatch, getAll) => {
    try {
      const thumbnails = await getThumbnailUrls(ids);
      if (
        picsioConfig.isMainApp
        && checkFor401
        && checkThumbnailErrorInvalidCredentials(thumbnails[0])
      ) {
        return getThumbnails(ids, false)(dispatch, getAll);
      }
      if (thumbnails?.length) {
        setThumbnails(thumbnails)(dispatch, getAll);
      } else {
        Logger.info(`getThumbnails not returned thumbnails for assetIds [${ids}]`);
      }
    } catch (error) {
      Logger.error(new Error('Can not get asset thumbnails'), { error, assetIds: ids }, [
        'errorFetchingThumbnails',
        (error && error.message) || 'NoMessage',
      ]);
    }
  };
}

export default getThumbnails;
