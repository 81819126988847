import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Emoji } from '@picsio/ui';

export const renderEmojiToString = (emojiName) => ReactDOMServer.renderToString(<Emoji name={emojiName} />);

function parseHTMLStringWithEmoji(htmlString) {
  let isImage = false;
  const parsed = htmlString && htmlString.replace(/<img .*?>/g, (matched) => {
    const dataStringify = /data-stringify="(:[\w\d\s/(/)+-]*:)"/.exec(matched);
    let emojiName = '';
    if (dataStringify) {
      [, emojiName] = dataStringify;
    } else {
      isImage = true;
    }

    return emojiName;
  });
  if (isImage) {
    return null;
  }
  return parsed;
}

export default parseHTMLStringWithEmoji;
