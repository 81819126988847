import sdk from '../../sdk';
import prepareSearchQuery from './prepareSearchQuery';

/**
 * @param {string[]} fields
 * @returns Promise<AxiosResponse>
 */
export default function getDownloadOriginalData(fields) {
  return sdk.assets.search({
    size: 'all',
    ...prepareSearchQuery(),
    fields,
  });
}
