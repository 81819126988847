import amplitude, { AmplitudeClient } from 'amplitude-js';
import { isObject } from './helpers';

interface Settings {
  appName: Function | string;
  key: string;
  apiEndpoint?: string
}

export interface PicsioAmplitudeInterface {
  getAppName: Function;
  instance: AmplitudeClient;
}

export default class Amplitude implements PicsioAmplitudeInterface {
  getAppName: Function;
  instance: AmplitudeClient;
  /**
   * @param {string} settings.appName
   * @param {string} settings.key
   * @param {string} settings.apiEndpoint
   */
  constructor(settings: Settings) {
    this.getAppName = typeof settings.appName === 'function' ? settings.appName : () => settings.appName;

    const params: any = {};
    if (settings.apiEndpoint) params.apiEndpoint = settings.apiEndpoint;
    amplitude.getInstance().init(settings.key, undefined, params);

    this.instance = amplitude.getInstance();
  }

	log(action: string, category: string, label: string | any) {
    const prefix = this.getAppName();
    let params: any;
    if (typeof label === 'string') params = { label };
    if (isObject(label)) params = { ...label };
    this.instance.logEvent(prefix + action, { category, ...params });
  }

  setUserProperties(userProperties: any) {
    this.instance.setUserProperties(userProperties);
  }

  clearUserProperties() {
    this.instance.clearUserProperties();
  }

  unsetTeam() {
    const identify = new amplitude.Identify().unset('team');
    this.instance.identify(identify);
  }

  setUserEmail(email: string) {
    this.instance.setUserId(email);
  }

  unsetUserEmail() {
    this.instance.setUserId(null);
    this.instance.regenerateDeviceId();
  }

  disableAmplitude() {
    this.instance.setOptOut(true);
  }
}
