import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';

import checkMultipleChanges from './checkMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Change description on assets
 * @param {string[]} ids
 * @param {string} description
 * @param {Function} onCancel
 */
const changeDescription = (ids, description, onCancel) => (dispatch, getAll) => {
  checkMultipleChanges(ids.length, doChange, onCancel);

  async function doChange() {
    try {
      dispatch({ type: TYPES.ASSETS.CHANGE_DESCRIPTION.START });

      await assetsHelpers.setDescription(ids, description);

      dispatch({
        type: TYPES.ASSETS.CHANGE_DESCRIPTION.COMPLETE,
        payload: { ids, description, userId: getAll().user._id },
      });
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      const errorMessage = errorStatus === 403
        ? localization.NO_PERMISSION_TO_ACCESS
        : localization.DETAILS.textCantUpdate('description');
      Toast(errorMessage, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      dispatch({ type: TYPES.ASSETS.CHANGE_DESCRIPTION.FAILED, error });
      Logger.error(new Error('Error change description'), { error }, [
        'ChangeDescriptionFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  }
};

export default changeDescription;
