import React, { useState, useEffect, useCallback } from 'react';

/** Store */
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bool } from 'prop-types';
import _union from 'lodash/union';
import store from '../../store';

import sdk from '../../sdk';
import addCollection from '../../store/actions/collections/addCollection';
import getChildren from '../../store/actions/collections/getChildren';
import { findCollection, getParent } from '../../store/helpers/collections';
import DropdownTree from './DropdownTree';

const DropdownTreeWithStore = (props) => {
  const rootId = props.rootCollectionId || props.collectionsStore.collections.my._id;
  const [rootCollection, setRootCollectiond] = useState(null);
  const [openedItems, setOpenedItems] = useState([rootId]);
  const [fetchingCollectionsIds, setFetchingCollectionsIds] = useState([]);
  const [fetchingRootId, setFetchingRootId] = useState(null);

  useEffect(() => {
    if (props.collectionsStore.collections.my.isFetching) return;

    // get custom root collection, if props.rootCollectionId exists
    if (props.rootCollectionId && props.rootCollectionId !== props.collectionsStore.collections.my._id) {
      const storeCollections = props.collectionsStore.collections;
      const collection = findCollection(storeCollections, 'my', { _id: props.rootCollectionId });
      if (!collection) {
        const fetchCustomRootCollection = async (collection) => {
          setFetchingRootId(props.rootCollectionId);
          const { data } = await sdk.collections.getCollection(props.rootCollectionId);
          collection = data;
          if (collection) {
            setRootCollectiond(collection);
          } else {
            setFetchingRootId(null);
          }
        };
        if (!fetchingRootId !== props.rootCollectionId) {
          fetchCustomRootCollection(collection);
        }
      } else {
        setRootCollectiond(collection);
      }
    }

    const findOpenedCollectionsIds = () => {
      const storeCollections = props.collectionsStore.collections;
      // Find parents collections Ids
      const parentsIds = [];
      props.checkedItems?.forEach((collection) => {
        let collectionId = collection;
        if (typeof collection !== 'string') {
          collectionId = collection._id;
        }
        let foundCollection = findCollection(storeCollections, 'my', { _id: collectionId });
        if (!foundCollection && !fetchingCollectionsIds.includes(collectionId)) {
          setFetchingCollectionsIds([...fetchingCollectionsIds, collectionId]);
          // we pushing ids to fetchingCollectionsIds for fetching once
          // if we don't do it, this function loops in componentDidUpdate
          props.collectionsActions.getChildren(props.collectionsStore.collections.my._id, {
            currentCollectionId: collectionId,
          });
        } else if (foundCollection) {
          setFetchingCollectionsIds([...fetchingCollectionsIds.filter((id) => id !== collectionId)]);
          while (foundCollection) {
            foundCollection = getParent(storeCollections, 'my', { _id: foundCollection._id });
            if (foundCollection) {
              parentsIds.push(foundCollection._id);
            }
          }
        }
      });
      const uniqueIds = _union(parentsIds);
      if (uniqueIds !== openedItems) {
        setOpenedItems(uniqueIds);
      }
    };

    findOpenedCollectionsIds();
  }, [props.collectionsStore.collections.my.nodes, props.checkedItems]);

  const handleCreateCollection = useCallback(
    (parent, name) => props.collectionsActions.addCollection(
      `${parent.path}${parent.name}/`,
      name,
      parent._id,
    ),
    [props.collectionsActions],
  );

  if (props.rootCollectionId && !rootCollection) {
    return null;
  }
  return (
    <DropdownTree
      treeListItems={[props.rootCollectionId ? rootCollection : props.collectionsStore.collections.my]}
      checkedItems={props.checkedItems.map(
        (item) => (typeof item === 'string' ? item : item._id),
      ) || []}
      disabledItems={props.disabledItems || []}
      openedItems={openedItems}
      disableRoot={props.disableRoot}
      onClick={props.onClick}
      loadChildren={(item) => props.collectionsActions.getChildren(item._id)}
      createCollection={props.canCreateCollection ? handleCreateCollection : null}
      iconSpecial={props.iconSpecial || 'folder'}
      usePermissions={props.usePermissions}
      type={props.type}
      collectionToMove={props.collectionToMove || {}}
      multipleSelection={props.multipleSelection}
    />
  );
};

const ConnectedDropdownTreeWithStore = connect(
  (state) => ({
    collectionsStore: state.collections,
  }),
  (dispatch) => ({
    collectionsActions: bindActionCreators({ addCollection, getChildren }, dispatch),
  }),
)(DropdownTreeWithStore);

ConnectedDropdownTreeWithStore.propTypes = {
  canCreateCollection: bool,
  multipleSelection: bool,
};

ConnectedDropdownTreeWithStore.defaultProps = {
  canCreateCollection: true,
  multipleSelection: false,

};

export default (props) => (
  <Provider store={store}>
    <ConnectedDropdownTreeWithStore {...props} />
  </Provider>
);
