import { createAction } from '@reduxjs/toolkit';

export const selectFeature = createAction('billing/selectFeature');

/* eslint-disable no-param-reassign */
export const reducer = (builder) => {
  builder.addCase(
    selectFeature.type,
    (state, { payload }) => {
      const {
        value, planId, frequency, featureName, featureType,
      } = payload;

      state.data.plans.forEach((plan) => {
        if (planId === plan.planId) {
          const includedFeatureValue = Number(plan.plans[frequency].metadata[featureName]);
          plan.plans[frequency].addons.forEach((addon) => {
            if (addon.metadata.featureName === featureName) {
              addon.metadata[featureName] = value;
              addon.extraValue = value;
              if (featureType === 'enum') {
                addon.metadata['ui:options'].forEach((option) => {
                  if (option.value === value) {
                    addon.extraAmount = option.price;
                    addon.metadata[featureName] = value + includedFeatureValue;
                  }
                });
              }
              if (featureType === 'number') {
                addon.metadata[featureName] = value + includedFeatureValue;
                addon.extraAmount = addon.amount * value;
              }
            }
          });
        }
      });
    },
  );
};
/* eslint-enable no-param-reassign */
