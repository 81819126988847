import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const VideoSlider = (props) => {
  const {
    marginLeft,
    marginRight,
    handleMouseDownCommentRangeEnd,
    handleMouseDownCommentRangeStart,
    noProofingAccess,
    isHidden = true,
  } = props;

  if (noProofingAccess) {
    return null;
  }

  return (
    <div className={cn('commentsRangeContainer', { hidden: isHidden })}>
      <div style={{ marginRight, marginLeft }} className="commentsRangeControls">
        <div
          className="control left"
          onMouseDown={handleMouseDownCommentRangeStart}
        >
          [
        </div>
        <div className="commentsRangeIndicatorContainer">
          <div className="dot" />
          <div className="commentIndicator" />
        </div>
        <div
          className="control right"
          onMouseDown={handleMouseDownCommentRangeEnd}
        >
          ]
        </div>
      </div>
    </div>
  );
};

VideoSlider.propTypes = {
  isHidden: PropTypes.bool,
  marginLeft: PropTypes.number.isRequired,
  marginRight: PropTypes.number.isRequired,
  handleMouseDownCommentRangeEnd: PropTypes.func.isRequired,
  handleMouseDownCommentRangeStart: PropTypes.func.isRequired,
  noProofingAccess: PropTypes.bool.isRequired,
};

export default VideoSlider;
