/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateUser } from '../actions/user';
import normalizeRoles from '../helpers/roles/normalizeRoles';

export const updateRole = createAsyncThunk(
  'roles/update',
  async ({ role, setImmediately = true }, {
    extra: {
      sdk, Logger, showDialogAsync, localization: l10n,
    },
    getState, dispatch,
  }) => {
    const isCurrentUserRole = getState().user?.role?._id === role._id;
    const updateUserRole = () => updateUser({ role })(dispatch, getState);

    if (setImmediately && isCurrentUserRole) updateUserRole();

    try {
      const { data: { affectedAssetsCount } } = await sdk.roles.update(role);

      /** Affected assets */
      if (affectedAssetsCount) {
        try {
          const {
            TITLE, TEXT, OK_TEXT, CANCEL_TEXT,
          } = l10n.DIALOGS.DISALLOW_COLLECTION;
          const { promise } = showDialogAsync({
            title: TITLE,
            text: TEXT,
            textBtnOk: OK_TEXT,
            textBtnCancel: CANCEL_TEXT,
          });
          await promise;
        } catch (error) {
          /** User cancels the dialog */
          Logger.info('Update role cancelled by initiator');
          return null;
        }

        await sdk.roles.update({ ...role, force: true });
        /** if user isn't updated already */
        if (!setImmediately && isCurrentUserRole) updateUserRole();
        return role;
      }

      /** if user isn't updated already */
      if (!setImmediately && isCurrentUserRole) updateUserRole();
      return role;
    } catch (err) {
      Logger.error(new Error('Can not update role'), { error: err, role }, [
        'UpdateRoleFailed',
        (err && err.message) || 'NoMessage',
      ]);
      throw err;
    }
  },
);

export const reducer = (builder) => builder.addCase(
  updateRole.pending,
  (state, { meta: { arg: { role: updatedRole, setImmediately = true } } }) => {
    state.loading = !setImmediately;
    state.error = null;
    if (setImmediately) {
      state.items = state.items.map((role) => {
        if (role._id === updatedRole?._id) return { ...updatedRole };
        return role;
      });
    }
  },
).addCase(
  updateRole.fulfilled,
  (state, { payload, meta: { arg: { setImmediately = true } } }) => {
    state.loading = false;
    state.error = null;
    if (payload && !setImmediately) {
      state.items = state.items.map((role) => {
        if (role._id === payload._id) {
          const normalizedRole = normalizeRoles([payload])[0];
          return { ...normalizedRole };
        }
        return role;
      });
    }
  },
).addCase(
  updateRole.rejected,
  (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
);
