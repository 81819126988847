import * as Sentry from '@sentry/browser';
import isPlainObject from 'lodash.isplainobject';

import { PicsioAmplitudeInterface } from './Amplitude';

interface Settings {
  env: string;
  appName: Function | string;
  dsn: string;
  amplitude: PicsioAmplitudeInterface;
}

export default class PicsioSentry {
  isEnabled: boolean;
  user: any;
  getAppName: Function;
  amplitude: PicsioAmplitudeInterface;
  /**
   * @param {string} settings.appName
   * @param {string} settings.env
   * @param {string} settings.dsn
   */
  constructor(settings: Settings) {
    this.isEnabled = settings.env !== 'development';
    this.user = {};
    this.getAppName = typeof settings.appName === 'function' ? settings.appName : () => settings.appName;
    this.amplitude = settings.amplitude;

    if (this.isEnabled) {
      const { email, displayName } = this.user;
      Sentry.init({
        dsn: settings.dsn,
        environment: settings.env,
        beforeSend(event) {
          if (event.extra && event.extra.showDialog) {
            Sentry.showReportDialog({
              eventId: event.event_id,
              lang: 'en',
              user: {
                email,
                name: displayName,
              },
            });
          }
          return event;
        },
        ignoreErrors:[
          "ResizeObserver loop limit exceeded", // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
          "ResizeObserver loop completed with undelivered notifications", // https://forum.sentry.io/t/node-js-filtering-with-beforesend-not-having-any-effect/8833
          "Non-Error promise rejection captured", // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
         ]
      });

      window.addEventListener('error', e => {
        this.send(e.error, { ErrorType: 'WindowError' });
      });

      window.addEventListener('unhandledrejection', e => {
        this.send(e.reason, { ErrorType: 'UnhandledRejectionError' });
      });
    }
  }

  setUser(user: any) {
    this.user = user;
  }

  unsetUser() {
    this.user = {};
  }

  send(err: string | any, extraData = {}) {
    if (this.isEnabled) {
      Sentry.configureScope(scope => {
        scope.setUser({ email: this.user.email });
        scope.setExtra('App', this.getAppName());

        // "uBlock Origin" blocks amplitude, so amplitude.getInstance in not a function
        if (this.amplitude && this.amplitude.instance) {
          scope.setExtra('AmplitudeSessionId', this.amplitude.instance.getSessionId());
        } else {
          scope.setExtra('AmplitudeSessionId', 'Can not get SesstionId, adBlock/uBlock is possible');
        }

        Object.entries(extraData).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });
      });
      if (typeof err === 'string') {
        Sentry.captureMessage(err);
      } else {
        Sentry.captureException(err);
      }
    }
  }

  addBreadcrumb(message = 'Undefined') {
    if (isPlainObject(message)) message = JSON.stringify(message, null, 2);

    Sentry.addBreadcrumb({
      category: 'Manual',
      message,
      level: 'info',
    });
  }
}
