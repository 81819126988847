import getCollectionPermissions from '@picsio/db/src/collections/roles/getPermissionsCollection';
import {
  ASYNC_JOB_STATUS_WAITING,
  ASYNC_JOB_STATUS_RUNNING,
  ASYNC_JOB_STATUS_FAILED,
  ASYNC_JOB_STATUS_REJECTED,
} from '@picsio/db/src/constants';
import TYPES from '../../action-types';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import * as assetsHelpers from '../../../helpers/assets';
import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';
import setAggregatedData from '../../helpers/assets/setAggregatedData';
import hasAllAssets from '../../helpers/assets/hasAllAssets';

/**
 * Select all assets
 */
const selectAll = () => async (dispatch, getAll) => {
  const assetsStore = getAll().assets;
  const userStore = getAll().user;
  const { permissions: rolePermissions } = userStore.role;
  function handleError(error, spinner) {
    spinner.destroy();
    dispatch({
      type: TYPES.ASSETS.SELECT_ALL.FAILED,
      error,
    });
    Logger.error(new Error('Can not select all images'), { error, showDialog: true }, [
      'SelectAllFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }

  /** if not all assets loaded */
  if ((assetsStore.total > assetsStore.items.length) && (assetsStore.items.length || assetsStore.geo?.length)) {
    const spinner = UiBlocker.block();
    try {
      dispatch({
        type: TYPES.ASSETS.SELECT_ALL.START,
      });
      const { data: selected } = await assetsHelpers.selectAll();
      if (selected.images?.length) {
        const selectedAssetsIds = selected.images.filter(
          (item) => (![
            ASYNC_JOB_STATUS_WAITING,
            ASYNC_JOB_STATUS_RUNNING,
            ASYNC_JOB_STATUS_FAILED,
            ASYNC_JOB_STATUS_REJECTED,
          ].includes(item.importing)),
        )
          .map((image) => image._id);

        /** case when you click one asset in catalog and then select all */
        const assetsIdsWithoutDuplicates = selectedAssetsIds.filter((item) => !assetsStore.selectedItems.includes(item));

        /** check if assets are already selected and are in selection */
        const allAssetsAlreadyInSelectedItems = assetsIdsWithoutDuplicates.every((id) => assetsStore.selectedItems.includes(id));

        if (!allAssetsAlreadyInSelectedItems) {
          const permissionsNames = Object.keys(assetsStore.items[0]?.permissions || getCollectionPermissions());
          const { data: assetsBatchPermissions } = await sdk.assets.getPermissions(
            [...assetsStore.selectedItems, ...assetsIdsWithoutDuplicates], permissionsNames,
          );

          const aggregatedData = await assetsHelpers.getAggregatedData(
            [...assetsStore.selectedItems, ...assetsIdsWithoutDuplicates], userStore,
          );
          dispatch({
            type: TYPES.ASSETS.SELECT_ALL.COMPLETE,
            payload: {
              selectedAssetsIds: assetsIdsWithoutDuplicates,
              assetsBatchPermissions,
              rolePermissions,
              aggregatedData: { ...aggregatedData },
            },
          });
          window.dispatchEvent(new Event('selection:switched:on'));
        }
      } else {
        handleError(selected, spinner);
      }
    } catch (error) {
      handleError(error, spinner);
    } finally {
      spinner.destroy();
    }
  } else {
    const assetsPermissions = utils.mergePermissions(assetsStore.items.map((n) => n.permissions));
    const { user, customFields } = getAll();
    const aggregatedData = setAggregatedData(
      assetsStore.items,
      assetsStore.aggregatedData,
      user,
      customFields.items,
    );

    /** case when you click one asset in catalog and then select all
     * this is when you have already id of assets in selectedItems
     */
    const assetsIdsWithoutDuplicates = assetsStore.items.filter(
      (item) => !assetsStore.selectedItems.includes(item._id)
      && ![
        ASYNC_JOB_STATUS_WAITING,
        ASYNC_JOB_STATUS_RUNNING,
        ASYNC_JOB_STATUS_FAILED,
        ASYNC_JOB_STATUS_REJECTED]
        .includes(item.importing),
    );

    /** when every asset is selected but user still presses select all in details panel we do nothing
     * so we select all if not every asset in tree is selected
    */
    if (!hasAllAssets(assetsIdsWithoutDuplicates, assetsStore.items)) {
      dispatch({
        type: TYPES.ASSETS.SELECT_ALL.COMPLETE,
        payload: {
          selectedAssetsIds: assetsIdsWithoutDuplicates.map((item) => item._id).filter(Boolean),
          assetsPermissions,
          rolePermissions,
          aggregatedData: { ...aggregatedData },
        },
      });
      window.dispatchEvent(new Event('selection:switched:on'));
    }
  }
};

export default selectAll;
