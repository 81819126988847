import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTargetCollection } from '../helpers/archive';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';
import incrementCount from '../collections/incrementCount';
import deleteCollections from './deleteCollections';
import navigateToRoot from './navigateToRoot';
import showError from './showError';
import TYPES from '../../action-types';

const unarchiveCollection = createAsyncThunk(
  'archive/unarchiveCollection',
  async ({ collectionId }, {
    extra: {
      sdk, utils, localization, UiBlocker, Toast,
    }, rejectWithValue, dispatch, getState,
  }) => {
    const { user } = getState();
    try {
      UiBlocker.block('Processing...');
      const { data: res } = await sdk.collections.unarchive(collectionId);
      const { collections } = res;
      const targetCollection = getTargetCollection(collections, collectionId);
      const { name } = targetCollection;

      dispatch(deleteCollections({ ...res, user: getState().user }));
      dispatch(incrementCount(res.assetsCount));
      dispatch({
        type: TYPES.COLLECTIONS.ARCHIVE.COMPLETE,
        payload: {
          isArchived: false,
        },
      });
      navigateToRoot(getState, dispatch, true);
      Toast(localization.TOAST.COLLECTION_UNARCHIVED(name), { type: 'success', closeButton: false });

      return res;
    } catch (e) {
      showError(e, Toast, utils, localization);
      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default unarchiveCollection;
