import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import * as collectionHelpers from '../../helpers/collections';
import { isChild } from '../../helpers/collections';
import findAndResolveDuplicates from '../../helpers/assets/findAndResolveDuplicates';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { isRoutePreview } from '../../../helpers/history';
import { showDialog, showErrorDialog } from '../../../components/dialog';
import sdk from '../../../sdk';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';
import checkMultipleChanges from './checkMultipleChanges';
import showForbiddenDialog from './showForbiddenDialog';
import setTmpItem from './setTmpItem';
import * as assetsHelpers from '../../../helpers/assets';
import hasAllAssets from '../../helpers/assets/hasAllAssets';
import { getDataFromResponceError } from '../../../shared/utils';

const FINISH_IN_BACKGROUND_SIZE = 30;

/**
 * Add assets to collection
 * @param {string} collectionID
 * @param {string} collectionPath
 * @param {string[]?} assetIDs
 * @param {Boolean} isMove
 * @param {Boolean} withoutAlertDialog
 */
const addToCollection = ({
  collectionID,
  collectionPath,
  assetIDs,
  isMove: isMoveFromParameter = false,
  withoutAlertDialog = false,
  skipCheckMultipleChanges = false,
}) => async (dispatch, getAll) => {
  let isMove = isMoveFromParameter;
  const {
    assets, user, router, collections,
  } = getAll();
  const ids = assetIDs || assets.selectedItems;
  const { items } = assets;
  const isUsedS3Storage = user.team && user.team.storageType === 's3';
  let isAddToCollectionAllowed = true;
  let isMoveIntoNestedCollection = false;
  const { inboxId, collectionIds: activeCollectionID } = router.location.query;
  const isInbox = Boolean(inboxId || items[0].inbox);
  if (isInbox) isMove = true;

  // check that the assets are moved from the collection
  if (activeCollectionID) {
    let collectionIds = activeCollectionID;
    if (typeof activeCollectionID === 'string') {
      collectionIds = [activeCollectionID];
    }
    const foundCollections = await collectionHelpers.forceFindTagWithTagId({ collections: collections.collections, search: collections.search, collectionIds });
    const normalizedCollections = foundCollections.filter((collection) => !!collection);
    const [activeCollection] = normalizedCollections;
    const fullPathActiveCollection = activeCollection.path + activeCollection.name;
    isMoveIntoNestedCollection = isChild(fullPathActiveCollection, `/${collectionPath}`);
  }

  const spinnerText = isMove
    ? localization.TAGSTREE.textMovingToCollection
    : localization.TAGSTREE.textAddAsShortcuts;

  if (ids.length > items.length) {
    const { data: permissions } = await sdk.assets.getPermissions(ids, ['editAssetCollections']);
    // by getAssetPermissions we receive 'false', 'true' or 'mixed'.
    // So if we received 'false' or 'mixed' it means 'false'
    if (permissions.editAssetCollections !== true) {
      isAddToCollectionAllowed = false;
    }
  } else {
    // find assets without permission
    isAddToCollectionAllowed = items
      .filter((item) => ids.includes(item._id))
      .every(
        (item) => (item.permissions && item.permissions.editAssetCollections) || (isInbox && item.inbox),
      );
  }

  if (!isAddToCollectionAllowed) {
    showForbiddenDialog(localization.DIALOGS.WARNING_EDIT_ASSET_COLLECTIONS.TEXT);
    return;
  }

  if (skipCheckMultipleChanges) {
    doAdd();
  } else {
    checkMultipleChanges(ids.length, doAdd, undefined);
  }

  async function doAdd(force) {
    const isPreview = isRoutePreview();
    if (isPreview && isInbox) setTmpItem(ids[0])(dispatch);
    const rootCollectionId = collections.collections.my._id;
    let idsToChange = ids;
    let actions;

    if (isUsedS3Storage && isMove) {
      /** Check duplicated filenames */
      try {
        let assetsToResolve;
        if (items.length >= ids.length && hasAllAssets(ids, items)) {
          assetsToResolve = items;
        } else {
          UiBlocker.block();
          let data;
          if (isInbox) {
            ({ data } = await assetsHelpers.selectAll({ name: 1 }));
          } else {
            ({ data } = await assetsHelpers.selectAll({ name: 1 }, rootCollectionId));
          }
          UiBlocker.unblock();
          assetsToResolve = data.images;
        }
        const assetsToChange = assetsToResolve.filter((a) => ids.includes(a._id));
        const resolveResult = await findAndResolveDuplicates({
          assets: assetsToChange,
          collectionPath: `/${collectionPath}`,
          user,
        });
        idsToChange = resolveResult.assets.map((a) => a._id);
        actions = resolveResult.actions;
      } catch (error) {
        UiBlocker.unblock();
        /** User press "Cancel" */
        if (error?.status === 'CANCELED') return;
        /** if "Too many items to analyze" ( more than 10 000 ) */
        const errorStatus = utils.getStatusFromResponceError(error);
        if (errorStatus === 413) {
          const { TITLE, TEXT, CANCEL_TEXT } = localization.DIALOGS.LIMIT_CHECK_DUPLICATES;
          showDialog({
            title: TITLE,
            text: TEXT,
            textBtnCancel: CANCEL_TEXT,
            textBtnOk: null,
            style: { maxWidth: 600 },
          });
        } else {
          Logger.error(
            new Error('Can not find duplicates on the server [Move to collection]'),
            { error, showDialog: true },
            ['FindDuplicatesFailed', (error && error.message) || 'NoMessage'],
          );
        }
        /** IF CAN NOT RESOLVE DUPLICATES -> EXIT */
        return;
      }
      /** do nothing if all assets skipped */
      if (idsToChange.length < 1) return;
    }

    try {
      UiBlocker.block(spinnerText);
      dispatch({ type: TYPES.ASSETS.ADD_COLLECTION.START, payload: { isMove, ids } });

      /** for s3 storage -> send isMove: true */
      const { data: result } = await sdk.assets.attachToCollection(
        idsToChange, collectionID, isMove, actions, force,
      );
      let successAssetIds = idsToChange;

      /** has failed jobs */
      if (result.failedAssets) {
        const failedAssetsIds = result.failedAssets.map((a) => a._id);
        successAssetIds = idsToChange.filter((id) => !failedAssetsIds.includes(id));
        showErrorDialog(
          localization.COLLECTIONS.textFilesNotAddedToCollection(
            failedAssetsIds.length,
            collectionPath,
          ),
        );
        dispatch({
          type: TYPES.ASSETS.ADD_COLLECTION.FAILED,
          payload: {
            ids: failedAssetsIds,
            isMove,
          },
        });
      }

      if (!withoutAlertDialog && idsToChange.length < FINISH_IN_BACKGROUND_SIZE) {
        Toast(
          localization.COLLECTIONS.textFilesAddedToCollection(
            successAssetIds.length,
            utils.decodeSlash(collectionPath),
          ),
          { type: 'success', closeButton: false },
        );
      }

      if (idsToChange.length < FINISH_IN_BACKGROUND_SIZE) {
        dispatch({
          type: TYPES.ASSETS.ADD_COLLECTION.COMPLETE,
          payload: {
            ids: successAssetIds,
            collectionID,
            collectionPath,
            isMove,
            isTeamDrive: false,
            isMoveIntoNestedCollection,
            activeCollectionID,
            notRecursiveSearch: collections.notRecursiveSearch,
          },
        });
      }

      if (idsToChange.length > FINISH_IN_BACKGROUND_SIZE) {
        Toast(localization.DETAILS.textFinishInBg);
      }
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      const subcode = getDataFromResponceError(error, 'subcode');
      let text = localization.COLLECTIONS.textAssetInCollectionAllreadyExists;
      const title = localization.COLLECTIONS.titleAssetInCollectionAllreadyExists;

      if (errorStatus === 403) {
        /* if no permissions */
        text = localization.NO_PERMISSION_TO_ACCESS;
        showErrorDialog(text, title);
      } else if (subcode === 'AttachRestrictedAssetsToCollectionError') {
        UiBlocker.unblock();
        showDialog({
          title: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TITLE,
          text: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TEXT,
          textBtnOk: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_OK,
          textBtnCancel: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_CANCEL,
          onOk: () => doAdd(true),
          onCancel: () => {
            UiBlocker.unblock();
          },
        });
      } else {
        showErrorDialog(text, title);
        Logger.error(new Error('Can not attach asset to collection'), { error }, [
          'AttachAssetToCollectionFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }

      dispatch({
        type: TYPES.ASSETS.ADD_COLLECTION.FAILED,
        payload: {
          ids: idsToChange,
        },
        error,
      });
    }

    UiBlocker.unblock();
  }
};

export default addToCollection;
