import Logger from '../../../services/Logger';

const sortAlgorithms = {
  createdAt: {
    asc: (a, b) => ((a.createdAt || '') < (b.createdAt || '')
      ? -1
      : (a.createdAt || '') > (b.createdAt || '') ? 1 : sortAlgorithms.name.asc(a, b)),
    desc: (a, b) => ((a.createdAt || '') > (b.createdAt || '')
      ? -1
      : (a.createdAt || '') < (b.createdAt || '') ? 1 : sortAlgorithms.name.desc(a, b)),
  },

  name: {
    asc: (a, b) => ((a.name).toLowerCase() < (b.name).toLowerCase()
      ? -1
      : (a.name).toLowerCase() > (b.name).toLowerCase() ? 1 : 0),
    desc: (a, b) => ((a.name).toLowerCase() > (b.name).toLowerCase()
      ? -1
      : (a.name).toLowerCase() < (b.name).toLowerCase() ? 1 : 0),
  },
  count: {
    asc: (a, b) => ((a.count || 0) < (b.count || 0) ? -1 : (a.count || 0) > (b.count || 0) ? 1 : sortAlgorithms.name.asc(a, b)),
    desc: (a, b) => ((a.count || 0) > (b.count || 0) ? -1 : (a.count || 0) < (b.count || 0) ? 1 : sortAlgorithms.name.desc(a, b)),
  },
};

/**
 * Sort
 * @param {Object[]} list
 * @param {Object} type
 * @returns {Object[]} sorted list
 */
function sort(list, sortType) {
  if (!sortAlgorithms?.[sortType.type]?.[sortType.order]) {
    Logger.info(`Wrong sortType passed to sort helper [${JSON.stringify(sortType)}]`);
    sortType = { type: 'createdAt', order: 'asc' };
  }

  const algorithm = sortAlgorithms[sortType.type][sortType.order];
  list.sort(algorithm);
  list.forEach((node) => {
    if (node && node.length > 0) sort(node, sortType);
  });
  return list;
}

export default sort;
