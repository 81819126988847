import {
  ASYNC_JOB_STATUS_WAITING,
  ASYNC_JOB_STATUS_RUNNING,
} from '@picsio/db/src/constants';

/**
 * Check for converting
 * @param {boolean} data.converting
 * @returns {boolean}
 */
export default function checkForConverting(data) {
  if (!data) return false;
  return [ASYNC_JOB_STATUS_RUNNING, ASYNC_JOB_STATUS_WAITING].includes(data.converting);
}
