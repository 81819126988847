import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCollectionName } from '../../../helpers/paths';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';
import navigateToRoot from './navigateToRoot';
import showError from './showError';
import deleteCollectionsById from './deleteCollectionsById';

const deleteArchiveCollection = createAsyncThunk(
  'archive/deleteArchiveCollection',
  async (collectionId, {
    extra: {
      sdk, utils, UiBlocker, Toast, localization,
    }, rejectWithValue, dispatch, getState,
  }) => {
    const { user } = getState();
    try {
      UiBlocker.block('Processing...');
      const { data: res } = await sdk.collections.delete(collectionId);
      const { collection, collections } = res;
      const collectionName = getCollectionName(collection.path);

      const unarchivedIds = collections.map(({ _id }) => _id);
      dispatch(deleteCollectionsById({ ids: [collection._id, ...unarchivedIds] }));

      navigateToRoot(getState, dispatch, true);
      Toast(localization.TOAST.COLLECTION_REMOVED(collectionName), { type: 'success', closeButton: false });

      return res;
    } catch (e) {
      showError(e, Toast, utils, localization);
      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default deleteArchiveCollection;
