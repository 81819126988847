export default {
  items: {},
  tmpItemsNumber: 0,
  selected: [],
  requiredFields: {},
  fields: {},
  addedCustomFields: [],
  errors: {},
  uploadErrorsCount: 0,
  permissions: {},
  inProgress: false,
  total: 0,
  isUploadSubmitted: false,
};
