/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as utils from '../../shared/utils';
import Toast from '../../components/Toast/Toast';
import { reloadCurrentPage } from '../../helpers/history';

export const updateMetadataOrder = createAsyncThunk(
  'roles/updateMetadataOrder',
  async ({
    titlesToMove, fieldTitleToMoveAfter, role, updateWithMoving = true,
  }, {
    extra: {
      sdk, localization,
    },
  }) => {
    try {
      let newItems = [...role.metadataOrder];

      if (updateWithMoving) {
        const indexToRemove = newItems.findIndex((item) => item === titlesToMove[0]);
        const itemsToMove = newItems.splice(indexToRemove, titlesToMove.length);
        const indexToPut = fieldTitleToMoveAfter ? newItems.findIndex((item) => item === fieldTitleToMoveAfter) : -1;

        newItems.splice(indexToPut + 1, 0, ...itemsToMove);
      }

      await sdk.roles.updateMetadataOrder(role._id, newItems);
      return { metadataOrder: newItems, roleId: role._id };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg');
      if (errorMessage) {
        Toast(errorMessage, { autoClose: false });
      } else {
        Toast(localization.CUSTOMFIELDSSCHEMA.textMoveFieldError, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      }
    }
  },
);

export const reducer = (builder) => builder.addCase(
  updateMetadataOrder.fulfilled,
  (state, { payload: { metadataOrder, roleId } }) => {
    const idx = state.items.findIndex(({ _id }) => roleId === _id);
    state.items[idx].metadataOrder = metadataOrder;
  },
);
