import * as userActions from '../../reducers/user';
import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';

const updateCustomerAddress = (address) => async (dispatch, getState) => {
  try {
    const { data: result } = await sdk.users.updateCustomerAddress(address);
    dispatch(
      userActions.updateUserSuccess({
        result: { customer: { ...getState().user.customer, address } },
      }),
    );
    return result;
  } catch (error) {
    const errorMessage = utils.getDataFromResponceError(error, 'msg');
    return { error: errorMessage };
  }
};

export default updateCustomerAddress;
