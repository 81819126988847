import React from 'react';
import { toast } from 'react-toastify';
import { Button, IconButton } from '@picsio/ui';
import { Interweave } from 'interweave';
import { Close } from '@picsio/icons';
import cn from 'classnames';
import { navigate } from '../../helpers/history';
import Logger from '../../services/Logger';
import * as utils from '../../shared/utils';
import localization from '../../shared/strings';

const queue = new Map();

const INIT_AUTO_CLOSE_TIME = 2000;
const MAX_AUTO_CLOSE_TIME = 7000;
const ONE_ROW_BUTTON_MAX_LENGTH = 12;

const uniqueToasts = [
  localization.offlineToastText,
];

let undo = false;
const currentToast = {};

const unloadHandler = () => {
  const unclosedToasts = [...queue.values()];
  if (unclosedToasts.length) {
    unclosedToasts.forEach(({ options }) => {
      if (options?.onClose) options.onClose();
    });
  }
};
// Force close action if window unload before toast will be closed
window.addEventListener('unload', unloadHandler);

const CustomToast = (children, options) => {
  const intercomState = utils.LocalStorage.get('intercom.intercom-state');
  const isLauncherEnabled = intercomState?.launcher?.isLauncherEnabled;

  const onClick = () => {
    if (options?.undo) undo = true;
    if (options?.onOk) options.onOk();
  };

  let customComponent = null;
  if (options?.btnOkValue) {
    customComponent = () => (
      <div
        className={cn('Toast-body', {
          'Toast-body-vertical': options.btnOkValue.length > ONE_ROW_BUTTON_MAX_LENGTH,
        })}
      >
        <div className="Toast-body-text">
          <Choose>
            <When condition={typeof children === 'string'}>
              <Interweave content={children} />
            </When>
            <Otherwise>{ children }</Otherwise>
          </Choose>
        </div>
        <div className="Toast-body-button">
          <span role="button" tabIndex={0} onClick={onClick} onKeyPress={options.onOk}>
            {options.btnOkValue}
          </span>
        </div>
      </div>
    );
  }

  if (options?.audit) {
    const handleOnClick = () => {
      navigate('/audit?tab=audit');
    };
    customComponent = () => (
      <div className="Toast-body Toast-body-vertical">
        <div className="Toast-body-text">
          {children}
        </div>
        <div className="Toast-body-button">
          <Button
            variant="text"
            color="primary"
            tabIndex={0}
            onClick={handleOnClick}
          >
            Check in Audit trail
          </Button>
        </div>
      </div>
    );
  }

  const CloseButton = ({ closeToast }) => (
    <IconButton
      size="xl"
      role="button"
      tabIndex={0}
      onClick={closeToast}
      onKeyPress={closeToast}
      className="Toastify__close-button"
    >
      <Close />
    </IconButton>
  );

  const getAutoClose = () => {
    // For qa use
    const TOAST_AUTO_CLOSE_TIME = Number(utils.getCookie('TOAST_AUTO_CLOSE_TIME'));
    if (TOAST_AUTO_CLOSE_TIME) return TOAST_AUTO_CLOSE_TIME;

    if (typeof children === 'string' && !options?.btnOkValue) {
      const autoCloseTime = INIT_AUTO_CLOSE_TIME + children.length * 100;
      return autoCloseTime < MAX_AUTO_CLOSE_TIME ? autoCloseTime : MAX_AUTO_CLOSE_TIME;
    }
    return MAX_AUTO_CLOSE_TIME;
  };

  // https://fkhadra.github.io/react-toastify/api/toast
  const configuration = {
    onOpen: () => {},
    onClose: () => {},
    closeButton: (options?.closeButton || options?.btnOkValue) ? CloseButton : false,
    type: toast.TYPE.DEFAULT,
    hideProgressBar: true,
    pauseOnHover: true,
    // Add bottom margin if intercom button is active
    style: { marginBottom: isLauncherEnabled ? '54px' : 0 },
  };

  /** Log event */
  try {
    const text = typeof children === 'string' ? children : '[ content is not a string]';
    Logger.log('UI', 'ToastShow', { text });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Can not log event: ', e?.message || '[ error has no massage ]');
  }

  // Add autoClose to rewrite autoClose if it was passed in options
  return toast(customComponent || children, { ...configuration, ...options, autoClose: getAutoClose() });
};

const handleClose = (toastId, onCloseHandler) => {
  // Remove toast from queue
  queue.delete(toastId);

  // Call onClose method if it was passed in options
  if (typeof onCloseHandler === 'function' && !undo) {
    onCloseHandler();
  }

  // Set undo false before a new toast
  if (undo) undo = false;

  // Show next toast if queue is not empty
  if (queue.size) {
    const nextToastId = queue.keys().next().value;
    const { children, options } = queue.get(nextToastId);
    currentToast.queueId = nextToastId;
    currentToast.id = CustomToast(children, { ...options, onClose: () => handleClose(nextToastId, options.onClose) });
    return currentToast.id;
  }
};

const addToastToQueue = (children, options = {}) => {
  // For qa use
  const TOAST_DISABLE_QUEUE = utils.getCookie('TOAST_DISABLE_QUEUE');
  if (TOAST_DISABLE_QUEUE) return CustomToast(children, options);

  const unclosedToasts = [...queue.entries()];
  const alreadyAdded = unclosedToasts.find(([, item]) => uniqueToasts.some((el) => el === item.children));

  if (alreadyAdded) return null; // some toasts should be added to queue just once

  if (options.undo) {
    const sameActionToast = unclosedToasts.find(([, item]) => item.children === children);

    if (sameActionToast && sameActionToast[1].options.onClose) {
      if (sameActionToast[0] === currentToast.queueId) {
        toast.dismiss(currentToast.id);
      } else {
        sameActionToast[1].options.onClose();
        queue.delete(sameActionToast[0]);
      }
    }
  }

  const toastId = Symbol(Date.now());
  queue.set(toastId, { children, options });

  if (queue.size === 1) {
    currentToast.queueId = toastId;
    currentToast.id = CustomToast(children, { ...options, onClose: () => handleClose(toastId, options.onClose) });
    return currentToast.id;
  }
};

export default addToastToQueue;
