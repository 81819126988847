import TYPES from '../../action-types';

const updateEditedComment = (text, mentions, assetId, commentId) => async (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.EDIT_COMMENT,
    payload: {
      text,
      mentions,
      assetId,
      commentId,
    },
  });
};

export default updateEditedComment;
