import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';

import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';
import { findCollection } from '../../helpers/collections';

/**
 * Add collection
 * @param {string} path
 * @param {string} name
 * @param {string} parentID
 * @param {Function} resetNewName
 */
function addCollection(path, name, parentID, resetNewName) {
  return async (dispatch, getState) => {
    dispatch({ type: TYPES.COLLECTIONS.ADD.START });
    const { collectionsSortType } = getState().user;
    const isCustomCollectionsSortActive = collectionsSortType?.type === 'custom';

    const data = { tag: { name } };
    /** If create NOT in the root collection */
    if (!path.startsWith('root')) data.path = path;
    /** If custom sort is active -> send order for the new collection */
    if (isCustomCollectionsSortActive) {
      const parentCollection = findCollection(getState().collections.collections, 'my', { _id: parentID });
      const children = parentCollection?.nodes || [];
      const firstOrder = children[0]?.order || 0;
      data.order = firstOrder - 1;
    }

    function handleError(error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      let errorMessage = '';

      const reset = () => {
        if (resetNewName) {
          resetNewName();
        }
      };

      switch (errorStatus) {
      case 403: {
        errorMessage = localization.NO_PERMISSION_TO_ACCESS;
        break;
      }
      case 426: {
        errorMessage = localization.COLLECTION_IS_EXISTED_AND_ARCHIVED;
        break;
      }
      default: {
        errorMessage = localization.UNABLE_TO_CREATE_COLLECTION;
        break;
      }
      }

      showDialog({
        title: 'Error',
        text: errorMessage,
        textBtnCancel: null,
        onOk: reset,
        onCancel: reset,
        onClose: reset,
      });
      dispatch({
        type: TYPES.COLLECTIONS.ADD.FAILED,
        error,
      });
      Logger.error(new Error('Can not create collection'), { error }, [
        'CreateCollectionFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }

    try {
      Logger.addBreadcrumb(data);
      const { data: collection } = await sdk.collections.create(data);

      if (collection._id) {
        const pathArr = collection.path.split('/');
        if (pathArr[1] === 'root') pathArr.splice(1, 1);

        collection.name = pathArr.pop();
        collection.path = `${pathArr.join('/')}/`;
        collection.hasChild = false;
        if (typeof data.order === 'number') collection.order = data.order;

        const { selectedAssetsAggregatedData } = getState().assets;
        dispatch({
          type: TYPES.COLLECTIONS.ADD.COMPLETE,
          payload: {
            parentID,
            collection,
          },
        });
        dispatch({
          type: TYPES.ASSETS.CREATE_COLLECTION.COMPLETE,
          payload: {
            aggregatedData: {
              ...selectedAssetsAggregatedData,
            },
          },
        });
        return collection;
      }
      throw new Error(collection.msg || 'Collection is undefined');
    } catch (error) {
      handleError(error);
    }
  };
}

export default addCollection;
