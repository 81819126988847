import dayjs from 'dayjs';

const isUnsubscribed = (user) => {
  const { subscriptionFeatures } = user;

  if (!subscriptionFeatures.access) {
    return true;
  }

  if (subscriptionFeatures.planId || subscriptionFeatures.planName) {
    // @TODO: remove subscriptionFeatures.planId, it is an old condition
    return false;
  }

  // let trialEndsAt = new Date(user.trialEnds);
  // if (trialEndsAt > Date.now()) {
  const trialEndsAt = dayjs(new Date(user.trialEnds));
  const today = dayjs(new Date());
  if (trialEndsAt.isBefore(today)) {
    return false;
  }

  return true;
};

export default isUnsubscribed;
