import TYPES from '../../action-types';

/**
 * Remove meta field modified immediately
 * @param {string[]} ids
 * @param {string} fieldName
 */
const removedModifiedField = (ids, fieldName) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.REMOVE_MODIFIED_FIELD.COMPLETE,
    payload: {
      ids,
      fieldName,
    },
  });
};
export default removedModifiedField;
