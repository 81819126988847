import _union from 'lodash/union';
import _uniqBy from 'lodash/uniqBy';
import _uniq from 'lodash/uniq';
import * as utils from '../../../shared/utils';
import * as pathHelper from '../../../helpers/paths';
import picsioConfig from '../../../../../../config';
import prepareModifiedMetaFields from '../../../helpers/assets/prepareModifiedMetaFields';
import preparePermissions from '../../../helpers/assets/preparePermissions';
import getNegativePermissions from '../../../helpers/assets/getNegativePermissions';
import getDetailsPanelEditable from '../../../components/details/helpers/getDetailsPanelEditable';
import localization from '../../../shared/strings';

function setAggregatedData(selectedAssets, aggregatedData, user, schema) {
  if (selectedAssets.length > 0) {
    const firstAsset = selectedAssets[0];
    let { flag } = firstAsset;
    let { trashed } = firstAsset;
    let { color } = firstAsset;
    let { title } = firstAsset;
    let { description } = firstAsset;
    let { rating } = firstAsset;
    let { keywording } = firstAsset;
    let { faceRecognizing } = firstAsset;
    let { transcribing } = firstAsset;
    let { faceIndexing } = firstAsset;
    let { replicating } = firstAsset;
    let { metadating } = firstAsset;
    let { contenting } = firstAsset;
    let faces = [];
    let { converting } = firstAsset;
    let { thumbnailing } = firstAsset;
    let { keywordingReason } = firstAsset;
    let { faceRecognizingReason } = firstAsset;
    let { transcribingReason } = firstAsset;
    let { replicatingReason } = firstAsset;
    let { metadatingReason } = firstAsset;
    let { contentingReason } = firstAsset;
    let { thumbnailingReason } = firstAsset;
    let { keywordingErrorCode } = firstAsset;
    let { faceRecognizingErrorCode } = firstAsset;
    let { transcribingErrorCode } = firstAsset;
    let { replicatingErrorCode } = firstAsset;
    let { metadatingErrorCode } = firstAsset;
    let { contentingErrorCode } = firstAsset;
    let { convertingErrorCode } = firstAsset;
    let { thumbnailingErrorCode } = firstAsset;
    let { crawling } = firstAsset;
    let { crawlingReason } = firstAsset;
    let { crawlingErrorCode } = firstAsset;
    let { describing } = firstAsset;
    let { describingReason } = firstAsset;
    let { describingErrorCode } = firstAsset;
    const keywords = [];
    let { watermarkId } = firstAsset;
    let tags = [];
    let lightboards = [];
    const assignees = [];
    let isDownloadable = true;
    let { paramsForHighlight } = firstAsset;
    let isRestricted = utils.isAssetRestricted(firstAsset.restrictSettings) || false;
    let restrictStartAt = firstAsset.restrictSettings.startAt || null;
    let restrictExpiresAt = firstAsset.restrictSettings.expiresAt || null;
    let restrictStartAtPlaceholder = firstAsset.restrictSettings.startAt || null;
    let restrictExpiresAtPlaceholder = firstAsset.restrictSettings.expiresAt || null;
    let restrictReason = firstAsset.restrictSettings.reason || null;
    let modifiedMetaFields = prepareModifiedMetaFields(firstAsset.modifiedMetaFields);
    let { archived, archivedByReason } = firstAsset;
    const isRemoveForever = Boolean(firstAsset.inbox);

    /** Get all metadata titles for assets */
    const metaTitles = _uniq(selectedAssets.map(({ meta = {} }) => Object.keys(meta)).flat()).filter(Boolean);
    const multipleEnumFieldTitles = schema
      .filter(({ type, multiple }) => type === 'enum' && multiple)
      .map(({ title: ttl }) => ttl);

    const meta = {};

    selectedAssets.forEach((asset, index) => {
      const assetMeta = asset.meta || {};
      metaTitles.forEach((ttl) => {
        if (index === 0) {
          meta[ttl] = assetMeta[ttl];
        } else {
          const mergedValue = meta[ttl];
          const assetValue = assetMeta[ttl];
          if (multipleEnumFieldTitles.includes(ttl)) {
            /** if Custom field is multiple enum */
            if (!mergedValue) meta[ttl] = typeof assetValue === 'string' ? assetValue : undefined;

            else {
              meta[ttl] = typeof assetValue === 'string'
              /** if both values exists -> merge */
                ? [...new Set([...mergedValue.split(','), ...assetValue.split(',')])].join(',')
              /** if the asset value is falsy */
                : mergedValue;
            }
          } else {
            meta[ttl] = mergedValue === assetValue ? mergedValue : null; // null is DIFFERENT VALUES
          }
        }
      });

      if (asset.flag !== flag) flag = null;
      if (asset.title !== title) title = '';
      if (asset.description !== description) description = '';
      if (asset.color !== color) color = null;
      if (asset.rating !== rating) rating = null;
      if (asset.keywording !== keywording) keywording = null;
      if (asset.faceRecognizing !== faceRecognizing) faceRecognizing = null;
      if (asset.transcribing !== transcribing) transcribing = null;
      if (asset.faceIndexing !== faceIndexing) faceIndexing = null;
      if (asset.replicating !== replicating) replicating = null;
      if (asset.metadating !== metadating) metadating = null;
      if (asset.contenting !== contenting) contenting = null;
      if (asset.thumbnailing !== thumbnailing) thumbnailing = null;
      if (asset.keywordingReason !== keywordingReason) keywordingReason = null;
      if (asset.faceRecognizingReason !== faceRecognizingReason) faceRecognizingReason = null;
      if (asset.transcribingReason !== transcribingReason) transcribingReason = null;
      if (asset.replicatingReason !== replicatingReason) replicatingReason = null;
      if (asset.metadatingReason !== metadatingReason) metadatingReason = null;
      if (asset.contentingReason !== contentingReason) contentingReason = null;
      if (asset.thumbnailingReason !== thumbnailingReason) thumbnailingReason = null;
      if (asset.keywordingErrorCode !== keywordingErrorCode) keywordingErrorCode = null;
      if (asset.faceRecognizingErrorCode !== faceRecognizingErrorCode) faceRecognizingErrorCode = null;
      if (asset.transcribingErrorCode !== transcribingErrorCode) transcribingErrorCode = null;
      if (asset.replicatingErrorCode !== replicatingErrorCode) replicatingErrorCode = null;
      if (asset.metadatingErrorCode !== metadatingErrorCode) metadatingErrorCode = null;
      if (asset.contentingErrorCode !== contentingErrorCode) contentingErrorCode = null;
      if (asset.convertingErrorCode !== convertingErrorCode) convertingErrorCode = null;
      if (asset.converting !== converting) converting = null;
      if (asset.thumbnailingErrorCode !== thumbnailingErrorCode) thumbnailingErrorCode = null;
      if (asset.crawling !== crawling) crawling = null;
      if (asset.crawlingReason !== crawlingReason) crawlingReason = null;
      if (asset.crawlingErrorCode !== crawlingErrorCode) crawlingErrorCode = null;
      if (asset.describing !== describing) describing = null;
      if (asset.describingReason !== describingReason) describingReason = null;
      if (asset.describingErrorCode !== describingErrorCode) describingErrorCode = null;
      // if one of assets is resticted, we set `isRestricted = true`
      if (asset.trashed) trashed = true;
      if (utils.isAssetRestricted(asset.restrictSettings)) isRestricted = true;
      if (asset.restrictSettings.startAt && asset.restrictSettings.startAt !== restrictStartAt) { restrictStartAt = null; }
      if (
        asset.restrictSettings.expiresAt
        && asset.restrictSettings.expiresAt !== restrictExpiresAt
      ) { restrictExpiresAt = null; }
      if (asset.restrictSettings.startAt != restrictStartAtPlaceholder) { restrictStartAtPlaceholder = localization.DETAILS.placeholderMultipleSelection; }
      if (asset.restrictSettings.expiresAt != restrictExpiresAtPlaceholder) { restrictExpiresAtPlaceholder = localization.DETAILS.placeholderMultipleSelection; }

      if (
        utils.isAssetRestricted(asset.restrictSettings)
        && asset.restrictSettings.reason !== restrictReason
      ) {
        restrictReason = localization.DETAILS.placeholderMultipleSelection;
      }

      if (asset.modifiedMetaFields && asset.modifiedMetaFields.length) {
        modifiedMetaFields = {
          ...modifiedMetaFields,
          ...prepareModifiedMetaFields(asset.modifiedMetaFields),
        };
      }

      /** paramsForHighlight */
      const AssetparamsForHighlight = asset.paramsForHighlight;
      if (paramsForHighlight) { paramsForHighlight = _union(AssetparamsForHighlight, paramsForHighlight); }

      /** faces */
      const assetFaces = asset.faces;
      if (assetFaces) faces = [...faces, ...assetFaces];

      /** keywords */
      if (asset.keywords?.length) {
        asset.keywords.forEach((k) => {
          const idx = keywords.findIndex(({ _id }) => k._id === _id);
          const isAlreadyAdded = idx > -1;

          if (isAlreadyAdded) {
            /** Update number of assets on keyword */
            keywords[idx].numberOfAssets += 1;
          } else {
            /** Add to keywords */
            keywords.push({ ...k, numberOfAssets: 1 });
          }
        });
      }

      /** watermarks */
      // if one of assets has watermark, we set `isWatermarked = true`
      const assetWatermark = asset.watermarkId;
      if (assetWatermark) watermarkId = assetWatermark;

      /** collections */
      const assetCollections = asset.tags;
      if (assetCollections) tags = [...tags, ...assetCollections];

      /** lightboards */
      const assetLightboards = asset.lightboards;
      if (assetLightboards) lightboards = [...lightboards, ...assetLightboards];

      /** assignees */
      const assetAssignees = asset.assignees;
      if (assetAssignees && assetAssignees.length > 0) {
        assetAssignees.forEach((assignee) => {
          if (!assignees.find((item) => item._id === assignee.assigneeId)) {
            assignees.push({ _id: assignee.assigneeId });
          }
        });
      }

      /** isDownloadable */
      const assetisIsDownloadable = asset.isDownloadable;
      if (assetisIsDownloadable === false) {
        isDownloadable = false;
      }

      if (!archived && asset.archived) {
        archived = true;
      }
      if (archived && archivedByReason !== asset.archivedByReason) {
        archivedByReason = localization.DETAILS.placeholderMultipleSelection;
      }
    });

    /** faces */
    faces = _uniqBy(faces, (face) => face.faceId);

    /** collections */
    tags = _uniqBy(tags, (collection) => collection._id).map((collection) => ({
      ...collection,
      path: collection.path.startsWith('/root/')
        ? pathHelper.removeRoot(collection.path)
        : collection.path,
    }));
    /** lightboards */
    lightboards = _uniqBy(lightboards, (lihgtboard) => lihgtboard._id).filter(
      (lightboard) => user._id === lightboard.userId,
    );

    // check if changed previewed file or not
    let currentLock;
    const detailsPanelEditable = getDetailsPanelEditable();
    if (selectedAssets.length === 1) {
      currentLock = aggregatedData?.filename === selectedAssets[0].name
        ? aggregatedData.currentLock
        : detailsPanelEditable.lockAlways;
    } else {
      currentLock = aggregatedData?.filename === selectedAssets.length
        ? aggregatedData.currentLock
        : detailsPanelEditable.lockAlways;
    }

    let mergedPermissions;
    if (picsioConfig.isMainApp) {
      mergedPermissions = utils.mergePermissions(selectedAssets.map((n) => n.permissions));
    }

    const rolePermissions = (picsioConfig.isMainApp && user.role.permissions) || {};
    let permissions = preparePermissions(
      { ...mergedPermissions, ...rolePermissions },
      archived,
    );

    /** if one or more assets trashed - disable all permissions */
    if (selectedAssets.some((asset) => asset.trashed)) {
      permissions = getNegativePermissions(permissions);
    }

    return {
      meta,
      color,
      rating,
      flag,
      title,
      description,
      keywords,
      tags,
      faces,
      lightboards,
      keywording,
      faceIndexing,
      replicating,
      metadating,
      contenting,
      converting,
      watermarkId,
      thumbnailing,
      keywordingReason,
      replicatingReason,
      metadatingReason,
      contentingReason,
      crawling,
      crawlingErrorCode,
      crawlingReason,
      describing,
      describingReason,
      describingErrorCode,
      thumbnailingReason,
      keywordingErrorCode,
      faceRecognizing,
      faceRecognizingReason,
      faceRecognizingErrorCode,
      transcribing,
      transcribingReason,
      transcribingErrorCode,
      replicatingErrorCode,
      metadatingErrorCode,
      contentingErrorCode,
      convertingErrorCode,
      thumbnailingErrorCode,
      currentLock,
      assignees,
      isDownloadable,
      paramsForHighlight,
      permissions,
      isRestricted,
      trashed,
      restrictReason,
      restrictStartAt,
      restrictExpiresAt,
      restrictStartAtPlaceholder,
      restrictExpiresAtPlaceholder,
      modifiedMetaFields,
      isRemoveForever,
      isArchived: archived,
      archivedByReason,
    };
  }
}

export default setAggregatedData;
