import CONSTANTS from '@picsio/db/src/constants';
import sdk from '../../../sdk';
import localization from '../../../shared/strings';
import * as assetsHelpers from '../../../helpers/assets';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import {
  added as addedKeywordAction,
  updateCount as updateCountAction,
  updateUsedAt as updateUsedAtAction,
} from '../../keywords/actions';
import { add as addToKeywordsList } from '../../keywordsList/actions';

import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import showForbiddenDialog from './showForbiddenDialog';
import Logger from '../../../services/Logger';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Add keyword to assets
 * @param {string} keywordName
 * @param {string[]?} ids - assets IDs
 */
const attachKeyword = (keywordName, ids) => async (dispatch, getAll) => {
  const selectedItems = ids || getAll().assets.selectedItems;
  const { items } = getAll().assets;
  let isEditAssetKeywordsAllowed = true;

  if (selectedItems > items.length) {
    const { data: permissions } = await sdk.assets.getPermissions(
      selectedItems, ['editAssetKeywords'],
    );
    // by getAssetPermissions we receive 'false', 'true' or 'mixed'.
    // So if we received 'false' or 'mixed' it means 'false'
    if (permissions.editAssetKeywords !== true) {
      isEditAssetKeywordsAllowed = false;
    }
  } else {
    // find assets without permission
    isEditAssetKeywordsAllowed = items
      .filter((item) => selectedItems.includes(item._id))
      .every((item) => item.permissions && item.permissions.editAssetKeywords);
  }

  if (!isEditAssetKeywordsAllowed) {
    showForbiddenDialog(localization.DIALOGS.WARNING_EDIT_ASSET_KEYWORDS.TEXT);
    return;
  }

  try {
    await asyncCheckMultipleChanges(selectedItems.length);
  } catch (error) {
    /** User cancelled operation */
    return;
  }

  try {
    dispatch({ type: TYPES.ASSETS.ATTACH_KEYWORD.START });

    /** wait for response */
    const { data: keywords } = await assetsHelpers.attachKeyword(selectedItems, keywordName);

    const selectedAssetsData = items.filter((asset) => selectedItems.includes(asset._id));

    keywords.forEach((keyword) => {
      const clonedKeyword = { ...keyword };
      const updatedAssetCount = selectedAssetsData.reduce((acc, asset) => {
        let count = acc;
        const isAssetIncludedKeyword = asset.keywords.some((item) => item._id === keyword._id);
        if (!isAssetIncludedKeyword) {
          count += 1;
        }
        return count;
      }, 0);

      dispatch({
        type: TYPES.ASSETS.ATTACH_KEYWORD.COMPLETE,
        payload: {
          ids: selectedItems,
          keyword: clonedKeyword,
          userId: getAll().user._id,
        },
      });

      if (clonedKeyword.isNew) {
        clonedKeyword.isFavorite = false;
        clonedKeyword.count = selectedItems.length;

        /** call keywords action */
        dispatch(addedKeywordAction([clonedKeyword]));
      } else {
        /** call keywords action */
        dispatch(addToKeywordsList([{
          ...clonedKeyword,
          name: clonedKeyword.name || clonedKeyword.path.split(CONSTANTS.NEW_PATH_DELIMITER).pop(),
          count: updatedAssetCount,
        }]));
        dispatch(updateCountAction({ _id: clonedKeyword._id, value: updatedAssetCount }));
        dispatch(updateUsedAtAction({ _id: clonedKeyword._id, value: clonedKeyword.usedAt }));
      }
      if (selectedItems.length > 1) {
        Toast(localization.DETAILS.textKeywordsSuccess);
      }
    });
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    Logger.error(new Error('Can not attach keyword to asset'), { error });
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : utils.getDataFromResponceError(error, 'msg');
    if (errorMessage) {
      Toast(errorMessage, { autoClose: false, type: 'error' });
    } else {
      Toast(localization.DETAILS.textCantUpdateKeywords, {
        autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
      });
    }
    dispatch({ type: TYPES.ASSETS.ATTACH_KEYWORD.FAILED, error });
  }
};

export default attachKeyword;
