import {
  bool, func, string, number,
} from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import Tag from '../Tag';

const Item = ({
  name,
  isSelected,
  onClick,
  onRemove,
  onSelect,
  onDeSelect,
  _id,
  tooltipText,
  numberOfAssets,
  showNumbers,
  onClickNumber,
  maxNumber,
}) => {
  const handleClick = useCallback((event) => {
    const { altKey, ctrlKey, metaKey } = event;
    /** Select only one */
    if (altKey) {
      onSelect([_id], true);
      return;
    }
    /** Add to selection */
    if (ctrlKey || metaKey) {
      if (isSelected && onDeSelect) onDeSelect([_id]);
      if (!isSelected && onSelect) onSelect([_id]);
      return;
    }

    if (onClick) onClick(_id);
  }, [onClick, onSelect, onDeSelect, _id, isSelected]);

  const handleRemove = useCallback((e) => {
    if (typeof onRemove === 'function') {
      e.stopPropagation();
      onRemove({ _id, title: name });
    }
  }, [onRemove, _id, name]);

  const handleClickNumber = useCallback((e) => {
    if (typeof onClickNumber === 'function') {
      e.stopPropagation();
      onClickNumber({ _id, title: name });
    }
  }, [_id, name, onClickNumber]);

  const isNumberInteractive = useMemo(
    () => (numberOfAssets || 0) < maxNumber,
    [numberOfAssets, maxNumber],
  );

  const tooltipTextNumber = useMemo(() => {
    /** keyword NOT on all assets */
    if (isNumberInteractive) return `Added to ${numberOfAssets} selected assets. Click to add to entire selection`;
    /** keyword on all assets */
    return `Added to ${numberOfAssets} selected assets`;
  }, [isNumberInteractive, numberOfAssets]);

  return (
    <Tag
      number={numberOfAssets}
      tooltipTextNumber={tooltipTextNumber}
      isSelected={isSelected}
      text={name}
      tooltipText={tooltipText}
      onClick={handleClick}
      onClose={handleRemove}
      onClickNumber={isNumberInteractive ? handleClickNumber : null}
      tooltipTextClose="Remove from selected assets"
      showNumber={showNumbers}
    />
  );
};

Item.defaultProps = {
  tooltipText: '',
  isSelected: false,
  onClick: null,
  onRemove: null,
  onSelect: null,
  onDeSelect: null,
  numberOfAssets: null,
  showNumbers: true,
  onClickNumber: null,
  maxNumber: 0,
};

Item.propTypes = {
  _id: string.isRequired,
  name: string.isRequired,
  tooltipText: string,
  numberOfAssets: number,
  isSelected: bool,
  onClick: func,
  onRemove: func,
  onSelect: func,
  onDeSelect: func,
  showNumbers: bool,
  onClickNumber: func,
  maxNumber: number,
};

export default memo(Item);
