import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../../../sdk';

const toggleAdBlockWarning = createAsyncThunk(
  'user/toggleAdBlockWarning',
  async (detected) => {
    const { data } = await sdk.users.toggleAdBlockWarning(detected);
    return data;
  },
);

export default toggleAdBlockWarning;
