import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@picsio/ui';
import l18n from '../../shared/strings';
import { rename } from '../../store/faces/rename';
import Logger from '../../services/Logger';
import Icon from '../Icon';

import './style.scss';

export default function EditFaceDialog({ destroy, faceId }) {
  const dispatch = useDispatch();
  const face = useSelector((state) => state.faces.all.find(({ _id }) => _id === faceId));
  const [name, setName] = useState(face.name);
  const [description, setdescription] = useState(face.description);

  const submit = async () => {
    dispatch(rename({
      id: faceId, name, description,
    }));
    Logger.log('Face', 'FaceRecogAllFacesPersonNameChanged');
    destroy();
  };

  useEffect(() => {
    const KEY_ENTER = 13;
    const KEY_ESC = 27;
    const KEY_UP = 38;
    const KEY_DOWN = 40;
    /** @param {KeyboardEvent} event */
    const keyListener = (event) => {
      const { activeElement } = document;

      /** if focus on textarea - skip */
      if (activeElement?.tagName?.toLowerCase() === 'textarea') return;

      switch (event.keyCode) {
      case KEY_ENTER: {
        submit();
        break;
      }
      case KEY_ESC: {
        destroy();
        break;
      }
      case KEY_UP: {
        break;
      }
      case KEY_DOWN: {
        break;
      }
      }
    };
    window.addEventListener('keydown', keyListener);

    return () => window.removeEventListener('keydown', keyListener);
  });

  const handleNewNameChange = (event) => {
    setName(event.target.value);
  };
  const handleNewDescriptionChange = (event) => {
    setdescription(event.target.value);
  };

  return (
    <div className="simpleDialog revisionFieldsDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox">
        <div className="simpleDialogHeader">
          <span className="simpleDialogTitle simpleDialogTitle_center">{l18n.FACESTREE.textEditPerson}</span>
          <span
            className="simpleDialogBtnCross"
            onClick={destroy}
          >
            <Icon name="close" />
          </span>
        </div>
        <div className="simpleFaceDialogContent">
          <TextField
            className="faceTextfield"
            label="Name *"
            placeholder="Name"
            value={name}
            onChange={handleNewNameChange}
          />
          <TextField
            label="Description"
            className="faceTextfield"
            placeholder="Description"
            value={description}
            onChange={handleNewDescriptionChange}
            InputProps={{
              component: 'textarea',
              autoResize: true,
              minHeight: 68,
              maxHeight: 148,
            }}
          />
        </div>
        <div className="simpleDialogFooter">
          <Button
            color="secondary"
            size="md"
            variant="contained"
            onClick={destroy}
          >
            {l18n.DIALOGS.CANCEL}
          </Button>
          <Button
            color="primary"
            size="md"
            variant="contained"
            disabled={!name}
            onClick={submit}
          >
            {l18n.FACESTREE.renameButton}
          </Button>
        </div>
      </div>
    </div>
  );
}
