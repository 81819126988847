/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

import { rename } from '../helpers/keywords';

export const renamed = createAction('keywords/renamed');

export const reducer = (builder) => builder.addCase(
  renamed.type,
  (state, { payload: { _id, name } }) => {
    const { all, favorites, keywords } = rename(state.tree, state.all, _id, name, state.sortType);
    state.tree = { favorites, keywords };
    state.all = all;
  },
);
