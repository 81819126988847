import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import Toast from '../../../components/Toast';
import sdk from '../../../sdk';
import { navigate, reloadCurrentPage } from '../../../helpers/history';

import getThumbnails from './getThumbnails';

/**
 * Attach comment to asset
 * @param {string} targetCollectionId - collection id where new assets will come
 * @param {boolean} resolveDuplicates
 * @param {boolean} overwriteDuplicates
 * @param {string} ids - original assets ids
 */
const duplicateAssets = (
  ids, targetCollectionId, resolveDuplicates, overwriteDuplicates
) => async (dispatch, getAll) => {
  const store = getAll();
  const isUserOnGd = store.user.team.storageType === 'gd';

  const copiedToActiveCollection = store.collections.activeCollections.findIndex((activeCollection) => activeCollection._id === targetCollectionId) > -1;

  try {
    dispatch({ type: TYPES.ASSETS.COPY_ASSETS.START, payload: { ids } });
    UiBlocker.block();

    const { data: result } = await sdk.assets.copy(ids, targetCollectionId, resolveDuplicates,
      overwriteDuplicates);

    const {
      queued, successful = [],
    } = result;

    if (result.copiedAssets && copiedToActiveCollection) {
      const newAssetsIds = result.copiedAssets.map((asset) => asset._id);
      if (newAssetsIds.length) getThumbnails(newAssetsIds)(dispatch, getAll);

      dispatch({
        type: TYPES.ASSETS.COPY_ASSETS.COMPLETE,
        payload: { ids: successful, queued },
      });
    }
    if (queued) {
      Toast(localization.DETAILS.textFinishDuplicationInBg, { autoClose: false });
    }
    UiBlocker.unblock();
  } catch (error) {
    dispatch({
      type: TYPES.ASSETS.COPY_ASSETS.FAILED,
      payload: { ids },
      error,
    });
    const errorStatus = utils.getStatusFromResponceError(error);
    const subcode = utils.getDataFromResponceError(error, 'subcode');
    const { ERROR_COPY_ASSET } = localization.DIALOGS;
    Logger.log('ErrorStatus', errorStatus);

    if (subcode === 'MissingCanCopyGoogleDriveCapabilityError'
      || subcode === 'MissingCanAddChildrenGoogleDriveCapabilityError'
      || subcode === 'StorageSpaceLimitExceededError') {
      const hideDialog = showDialog({
        title: ERROR_COPY_ASSET.TITLE(!isUserOnGd ? 'Picsio' : ''),
        children: ERROR_COPY_ASSET.TEXT(!isUserOnGd ? 'Picsio' : ''),
        textBtnCancel: null,
        textBtnOk: 'Check storage space',
        onOk() {
          Logger.log('User', 'Copy failed, not enough storage');
          navigate('/storage');
          hideDialog();
        },
      });
    } else if (subcode === 'AssetsLimitExceededError') {
      Toast(localization.DETAILS.textAssetsLimitExceededError, { autoClose: false, type: 'error' });
    } else if (subcode === 'CopyAssetsLimitError') {
      Toast(localization.DETAILS.textCopyAssetsLimitError, { autoClose: false, type: 'error' });
    } else if (subcode === 'RestrictedAssetsError') {
      Toast(localization.DETAILS.textRestrictedAssetsError, { autoClose: false });
    } else {
      Toast(localization.DETAILS.textAssetDuplicateFail, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
    }
    Logger.error(new Error('Can not duplicate asset'), { error }, [
      'RestrictAssetsFailed',
      (error && error.message) || 'NoMessage',
    ]);
    UiBlocker.unblock();
  }
};

export default duplicateAssets;
