import { createAsyncThunk } from '@reduxjs/toolkit';
import l18n from '../../../shared/strings';

const fetchApiKeys = createAsyncThunk(
  'user/fetchApiKeys',
  async (_, { extra: { sdk, Logger, Toast } }) => {
    try {
      const { data } = await sdk.users.fetchApiKeys();
      return data;
    } catch (error) {
      Logger.error(new Error('Can not get user api keys'), { error }, [
        'FetchUserApiKeysFailed',
        error?.message || 'NoMessage',
      ]);
      Toast(l18n.TOAST.API_KEY.GET);
      throw error;
    }
  },
);

export default fetchApiKeys;
