import * as utils from '../../../shared/utils';
import localization from '../../../shared/strings';

/**
 * Make search tree
 * @param {Object[]} all
 * @param {string} searchQuery
 * @returns {Object} search tree
 */
const makeSearchTree = (all, searchQuery, userId) => {
  // this code for search by parent node too, it shows all the children of the searched node

  // const savedSearches = all
  //   .filter((savedSearch) => {
  //     const pathArr = savedSearch.path.toLowerCase().split(SEPARATOR);
  //     for (let i = 0; i < pathArr.length; i++) {
  //       if (pathArr[i].includes(searchQuery.toLowerCase())) {
  //         return true;
  //       }
  //     }
  //   })
  //   .map((node) => ({ ...node, name: node.path.split(SEPARATOR)[node.path.split(SEPARATOR).length - 1] }));

  const savedSearches = all.filter((node) => node.name.toLowerCase().includes(searchQuery.toLowerCase()));
  const tree = {
    favorites: {
      name: localization.SAVEDSEARCHESTREE.textFavorites,
      path: 'favorites',
      root: true,
      isOpen:
				utils.getCookie('picsio.savedSearchedTree.favorites.open') === null
				|| utils.getCookie('picsio.savedSearchedTree.favorites.open'),
      nodes: savedSearches.filter(
        (node) => node.favorites !== undefined && node.favorites.length > 0 && node.favorites.includes(userId),
      ),
    },
    savedSearches: {
      name: localization.SAVEDSEARCHESTREE.textSavedSearches,
      path: 'savedSearches',
      root: true,
      isOpen:
				utils.getCookie('picsio.savedSearchedTree.savedSearches.open') === null
				|| utils.getCookie('picsio.savedSearchedTree.savedSearches.open'),
      nodes: savedSearches,
    },
  };

  return tree;
};

export default makeSearchTree;
