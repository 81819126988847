import TYPES from '../../action-types';

/**
 * Asset renamed on the server
 * @param {string} id - asset id
 * @param {string} name - new name
 */
const renamed = (id, name) => async (dispatch) => dispatch({
  type: TYPES.ASSETS.RENAME.COMPLETE,
  payload: { id, name },
});

export default renamed;
