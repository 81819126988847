import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton, Icon, Tooltip, Avatar,
} from '@picsio/ui';
import { User, Logout, QuestionTransparent } from '@picsio/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import sdk from '../../../sdk';
import updateUser from '../../../store/actions/user/updateUser';
import Toast from '../../Toast';
import UiBlocker from '../../../services/UiBlocker';
import List from '../List';
import Breadcrumbs from '../Breadcrumbs';
import i18n from '../../../shared/strings';
import s from './Chooser.module.scss';
import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';

function Chooser({ onLogout, onSelect, selectedItems }) {
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentFolderPath, setCurrentFolderPath] = useState('');

  const dispatch = useDispatch();
  const connectedCloud = useSelector((state) => state.user.connectedCloud);

  const getFileFolderList = useCallback(async (path = '') => {
    try {
      setLoading(true);
      const { data } = await sdk.dropbox.getFileFolderList(path);
      setList(data.entries);
      return data.entries;
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);

      if (errorStatus === 403) {
        Logger.error(new Error('Authentication failed'), { error, error_reason: 'AuthenticationFailed' }, [
          'DropboxImportError',
          (error && error.message) || 'NoMessage',
        ]);
        dispatch(
          updateUser({ connectedCloud: { ...connectedCloud, dropbox: null } }),
        );
        if (onLogout) {
          onLogout();
        }
        Toast(i18n.DROPBOX_CHOOSER.toastFailedAuthentication, {
          autoClose: false,
          type: 'error',
        });
      } else {
        Logger.error(new Error('Can not get file and folder list'), { error }, [
          'DropboxGetFileFolderListFailed',
          (error && error.message) || 'NoMessage',
        ]);
        Toast(i18n.DROPBOX_CHOOSER.toastFailedGetList, {
          autoClose: false,
          type: 'error',
        });
      }
      return [];
    } finally {
      setLoading(false);
    }
  }, [dispatch, connectedCloud, onLogout]);

  useEffect(() => {
    async function fetchData() {
      const items = await getFileFolderList();
      if (items?.length) {
        Logger.log('User', 'DropboxPickerOpened', {
          total_assets_number: items.filter((item) => item['.tag'] === 'file').length,
          total_collections_number: items.filter((item) => item['.tag'] === 'folder').length,
        });
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      UiBlocker.block(i18n.DROPBOX_CHOOSER.spinnerTextDisconnect);
      await sdk.auth.disconnectDropbox();
      dispatch(
        updateUser({ connectedCloud: { ...connectedCloud, dropbox: null } }),
      );
      if (onLogout) {
        onLogout();
      }
      Toast(i18n.DROPBOX_CHOOSER.toastDisconnectSuccess, {
        autoClose: false,
        type: 'success',
      });
      Logger.log('User', 'DropboxImportDisconnect');
    } catch (error) {
      Toast(i18n.DROPBOX_CHOOSER.toastDisconnectFailed, {
        autoClose: false,
        type: 'error',
      });
      Logger.error(new Error('Dropbox import disconnect error'), { error }, [
        'DropboxImportDisconnectFailed',
        (error && error.message) || 'NoMessage',
      ]);
    } finally {
      UiBlocker.unblock();
    }
  }, [dispatch, connectedCloud, onLogout]);

  const handleClickFolder = useCallback((path) => {
    getFileFolderList(path);
    setCurrentFolderPath(path);
    onSelect([]);
  }, [onSelect, getFileFolderList]);

  const handleOpenHelp = useCallback(() => {
    window.open('https://help.pics.io/en/articles/9923966-import-files-and-folders-from-your-dropbox-account#:~:text=Switching%20to%20a%20different%20Dropbox%20account', '_blank');
    Logger.log('User', 'DropboxChooserHelp');
  }, []);

  return (
    <div className={s.Chooser}>
      <div className={s.header}>
        <div className={s.breadcrumbs}>
          <img
            src="https://assets.pics.io/img/icons/importers/dropbox.svg"
            className={s.logo}
            alt="Dropbox logo"
          />
          <span className={cn(isLoading && s.disabled)}>
            <Breadcrumbs
              currentFolderPath={currentFolderPath}
              onClick={handleClickFolder}
            />
          </span>
        </div>
        <div className={s.account}>
          <Tooltip content={connectedCloud?.dropbox?.email} placement="top">
            <div className={s.userInfo}>
              <Choose>
                <When condition={connectedCloud?.dropbox?.profile_photo_url}>
                  <Avatar
                    src={connectedCloud?.dropbox?.profile_photo_url}
                    className={s.avatar}
                    alt="Dropbox account avatar"
                    size={32}
                  />
                </When>
                <Otherwise>
                  <Icon
                    className={cn(s.avatar, 'PicsioAvatar')}
                    size="xxl"
                  >
                    <User />
                  </Icon>
                </Otherwise>
              </Choose>
              <span>
                {connectedCloud?.dropbox?.display_name}
              </span>
            </div>
          </Tooltip>
          <Tooltip content={i18n.DROPBOX_CHOOSER.tooltipDisconnect} placement="top">
            <IconButton onClick={handleLogout} size="lg">
              <Logout />
            </IconButton>
          </Tooltip>
          <IconButton
            id="button-help"
            className={s.buttonHelp}
            onClick={handleOpenHelp}
            size="lg"
            color="primary"
          >
            <QuestionTransparent />
          </IconButton>
        </div>
      </div>
      <div>
        <List
          list={list}
          selectedItems={selectedItems}
          onSelect={onSelect}
          onClickFolder={handleClickFolder}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

Chooser.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  onLogout: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Chooser;
