import PicsioSentry from './PicsioSentry';
import Amplitude from './Amplitude';

import { isObject } from './helpers';

const BASE_STYLE = 'padding: 1px 3px; border-radius: 3px;';
const INFO_STYLE = BASE_STYLE + 'background: #d1fdc6; color: black;';
const LOG_STYLE = BASE_STYLE + 'background: green; color: white; font-weight: bold;';
const WARN_STYLE = BASE_STYLE + 'background: orange; color: black; font-weight: bold;';
const ERROR_STYLE = BASE_STYLE + 'background: red; color: white; font-weight: bold;';

interface ConstructorOptions {
  env: string;
  amplitudeSettings: any;
  sentrySettings: any;
  trackingService: any;
}

export default class BrowserLogger {
  isDevelopment: Boolean;
  amplitude: any;
  sentry: any;
  trackingService: any;
  /**
   * @param {string} settings.env
   * @param {object} settings.amplitudeSettings
   * @param {object} settings.sentrySettings
   * @param {object} settings.trackingService
   */
  constructor({
    env = 'development',
    amplitudeSettings,
    sentrySettings,
    trackingService }: ConstructorOptions
  ) {
    this.isDevelopment = env === 'development';
    this.amplitude = new Amplitude(amplitudeSettings);
    this.sentry = new PicsioSentry({ ...sentrySettings, amplitude: this.amplitude });
    this.trackingService = trackingService || null;
  }

  /* object of user must contain (displayName, email) */
  setUser(user: any) {
    this.sentry.setUser(user);
    this.amplitude.setUserEmail(user.email);
  }

  setUserProperties(userProperties: any) {
    this.amplitude.setUserProperties(userProperties);
  }

  clearUserProperties() {
    this.amplitude.clearUserProperties();
  }

  unsetUser() {
    this.sentry.unsetUser();
    this.amplitude.unsetUserEmail();
  }

  unsetTeam() {
    this.amplitude.unsetTeam();
  }

  disableAmplitude() {
    this.amplitude.disableAmplitude();
  }

  /** Just info to browser console */
  info() {
    const info = this.isDevelopment ? ['%cINFO:', INFO_STYLE, ...arguments] : arguments;
    console.info(...info);
  }

  /**
   * Log to Amplitude, Google etc.
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {number} value
   * @param {boolean} nonInteraction
   */
  log() {
    let [category, action, label] = arguments;

    this.amplitude.log(action, category, label);

    label = isObject(label) ? JSON.stringify(label) : label;
    label += ''; // label always should be string type. or push die | may be old code 🤷‍♂️
    if (this.trackingService) this.trackingService.push(...arguments);

    this.sentry.addBreadcrumb(`${category}: ${action}`);

    if (this.isDevelopment) console.log('%cLOG:', LOG_STYLE, ...arguments);
  }

  /** Add sentry breadcrumb
   * @param {string|Object} message
   */
  addBreadcrumb() {
    this.sentry.addBreadcrumb(...arguments);
  }

  /** Just warning to browser console */
  warn() {
    const log = this.isDevelopment ? ['%cWARNING:', WARN_STYLE, ...arguments] : arguments;
    console.warn(...log);
  }

  /** Send error to the Sentry
   * @param {Error} error
   * @param {Object} extraData - (e.g. { showDialog: true } - shows sentry feedback dialog)
   * @param {string[]} paramsForTracking - [action, label, value, nonInteraction]
   */
  error() {
    let [err, extraData = {}, paramsForTracking] = arguments;
    extraData.ErrorType = 'ManualError';

    const toConsole = this.isDevelopment ? ['%cERROR:', ERROR_STYLE, ...arguments] : arguments;
    console.error(...toConsole);

    if (paramsForTracking && paramsForTracking.length) {
      extraData.Amplitude = paramsForTracking[0];
      this.amplitude.log('Error', ...paramsForTracking);
      if (this.trackingService) this.trackingService.push('Error', ...paramsForTracking);
    }
    this.sentry.send(err, extraData);
  }
};
