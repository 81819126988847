/* eslint-disable no-param-reassign */
import _isEmpty from 'lodash/isEmpty';
import { createAction } from '@reduxjs/toolkit';
import getFilesByIds from './helpers/getFilesByIds';
import validateFields from './helpers/validateFields';
import groupErrors from './helpers/groupErrors';

/** e.g. keywords, assignees */
export const removeFromListField = createAction('import/removeFromListField');

export const reducer = (builder) => {
  builder.addCase(
    removeFromListField.type,
    (state, { payload: { title, _id } }) => {
      const filterFunc = (item) => item._id !== _id;
      /** update fields */
      state.fields[title] = state.fields[title].filter(filterFunc);

      /** update field on selected files */
      const selectedFiles = getFilesByIds(state.items, state.selected);
      selectedFiles.forEach((file) => {
        file.fields[title] = file.fields[title].filter(filterFunc);
        if (state.isUploadSubmitted || !_isEmpty(file.errors || {})) {
          const { isValid, errors, error } = validateFields({ fields: file.fields, requiredFields: state.requiredFields, importFrom: file.file?.importFrom });
          file.errors = errors;
          file.error = error;
          file.isValid = isValid;
        }
      });
      state.errors = groupErrors(selectedFiles);
    },
  );
};
