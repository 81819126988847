import React, { useState, useCallback, useEffect } from 'react';
import { TreeCollapse, Tree } from '@picsio/ui';
import { useSelector } from 'react-redux';
import {
  any, arrayOf, func, objectOf, string,
} from 'prop-types';
import { LocalStorage } from '../../shared/utils';

const KEY_IN_LOCAL_STORAGE = 'picsio.tagsTree.websites.open';

let tempExpanded = [];

export default function WebsitesTree({
  renderNode, selected = [], onNodeSelect = () => {}, titleProps = {},
}) {
  const isLoaded = useSelector((state) => state.collections.isLoaded);
  const websites = useSelector((state) => state.collections.search?.collections?.websites || state.collections.collections?.websites);
  const [expanded, setExpanded] = useState(tempExpanded);

  const [isOpen, setOpen] = useState(LocalStorage.get(KEY_IN_LOCAL_STORAGE) === null ? true : LocalStorage.get(KEY_IN_LOCAL_STORAGE));

  useEffect(() => {
    if (!isLoaded) setExpanded([]);
  }, [isLoaded]);

  const toggleOpen = useCallback(() => {
    setOpen((prev) => {
      LocalStorage.set(KEY_IN_LOCAL_STORAGE, !prev);
      return !prev;
    });
  }, [setOpen]);

  useEffect(() => {
    tempExpanded = [...expanded];
  }, [expanded]);

  const handleToggleChild = useCallback((collectionId) => {
    if (expanded.includes(collectionId)) setExpanded((prev) => prev.filter((id) => id !== collectionId));
    else setExpanded((prev) => [...prev, collectionId]);
  }, [expanded, setExpanded]);

  /** if no websites */
  if (!websites?.nodes?.length) return null;

  /** className added for QA */
  return (
    <TreeCollapse
      className="collectionsWebsites"
      title={websites.name || 'Loading...'}
      isOpen={isOpen}
      {...titleProps}
      selected={selected.includes(websites._id)}
      onClick={toggleOpen}
    >
      <Tree
        nodes={websites?.nodes || []}
        renderNode={renderNode}
        onNodeToggle={handleToggleChild}
        onNodeSelect={onNodeSelect}
        controlled
        expanded={expanded}
        selected={selected}
      />
    </TreeCollapse>

  );
}

WebsitesTree.propTypes = {
  renderNode: func.isRequired,
  titleProps: objectOf(any),
  selected: arrayOf(string),
  onNodeSelect: func,
};
