import React from 'react';

/**
 * Search for value in text
 * @param {string} text
 * @param {string} searchValue
 */

const searchValueInText = (text, searchValue) => {
  const result = [];
  if (searchValue.trim() === '') return text;

  const search = (txt, value) => {
    const startpos = txt.toLowerCase().search(
      new RegExp(value.toLowerCase().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i'),
    );
    if (startpos === -1) {
      result.push(txt);
      return;
    }
    let txtNew = `${txt.substring(0, startpos + value.length)}`;
    txtNew = [`${txtNew.substring(0, startpos)}`, <em>{txtNew.substring(startpos)}</em>];
    result.push(...txtNew);
    search(txt.substring(startpos + value.length), value);
  };

  search(text, searchValue);

  return result;
};

export default searchValueInText;
