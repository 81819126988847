import React from 'react';
import {
  string, bool, func, number,
} from 'prop-types';
import cn from 'classnames';
import { Icon } from '@picsio/ui';
import { Close, Upload } from '@picsio/icons';
import localization from '../shared/strings';
import { pollImage } from '../helpers/images';
import { bytesToSize } from '../shared/utils';
import Logger from '../services/Logger';
import Spinner from './Spinner';
import Tooltip from '../components/Tooltip';
import { showErrorDialog } from '../components/dialog';

class ImagePicker extends React.Component {
  state = {
    caption: this.props.value ? 'Change' : 'Upload',
    isRemoveBtnHover: false,
    isError: false,
  };

  componentWillUnmount() {
    if (this.poller) this.poller.stop();
  }

  handleMouseEnterRemove = (event) => {
    this.setState({ isRemoveBtnHover: true });
    event.preventDefault();
    event.stopPropagation();
  };

  handleMouseLeaveRemove = () => {
    this.setState({ isRemoveBtnHover: false });
  };

  handleError = async () => {
    if (this.props.value) {
      this.setState({ isError: true });
      this.poller = pollImage(this.props.value, 3000);
      try {
        await this.poller.promise;
        this.setState({ isError: false });
      } catch (err) {
        Logger.error(new Error('Error imagePicker image polling'), { error: err });
      }
      this.poller = null;
    }
  };

  handleLoad = () => {
    if (this.poller) this.poller.stop();
  };

  handleChange = (event) => {
    const { props } = this;
    const file = event.currentTarget.files[0];
    if (!file) return;

    if (props.maxFileSize && file.size > props.maxFileSize) {
      const { FILE_TO_LARGE } = localization.DIALOGS;
      showErrorDialog(
        FILE_TO_LARGE.TEXT(bytesToSize(props.maxFileSize), bytesToSize(file.size)),
        FILE_TO_LARGE.TITLE,
      );
    } else {
      this.props.onChange(file);
    }

    /** clear input value */
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = '';
  };

  handleRemove = () => {
    const { onRemove } = this.props;
    this.setState({ isRemoveBtnHover: false });
    if (onRemove) onRemove();
  }

  render() {
    const { state } = this;
    const {
      title,
      btnText,
      description,
      value,
      icon: ControlIcon,
      disabled,
      showSpinner,
      accept,
      id,
    } = this.props;

    let caption = btnText;

    if (value) {
      if (state.isRemoveBtnHover) {
        caption = 'Delete';
      } else {
        caption = 'Change';
      }
    }

    const isIconUploadVisible = !state.isRemoveBtnHover || !value;

    return (
      <Tooltip content={description} placement="top" hideTooltip={state.isRemoveBtnHover}>
        <div
          className={cn('UIImagePicker', { UIImagePicker__uploaded: value, isDisabled: disabled })}
          data-tooltip={description}
        >
          <div className={cn('UIImagePicker__preview', { chessBg: value })}>
            {(state.isError || showSpinner) && <Spinner />}
            <Choose>
              <When condition={value && !state.isError}>
                <div className="UIImagePicker__preview__img">
                  <img
                    src={value}
                    alt={title}
                    width="100%"
                    height="100%"
                    onLoad={this.handleLoad}
                    onError={this.handleError}
                  />
                </div>
              </When>
              <Otherwise>
                <Icon size="inherit" color="inherit">
                  <ControlIcon />
                </Icon>
              </Otherwise>
            </Choose>
            {!value && title && <div className="UIImagePicker__title">{title}</div>}
          </div>
          <div className="UIImagePicker__content">
            {isIconUploadVisible && (
              <Icon size="xxxl">
                <Upload />
              </Icon>
            )}
            {state.caption && <div className="UIImagePicker__content__caption">{caption}</div>}
          </div>
          <div className="UIImagePicker__file">
            <input type="file" accept={accept} id={id} onChange={this.handleChange} />
          </div>
          {value && (
            <span
              className="UIImagePicker__remove"
              onClick={this.handleRemove}
              onMouseEnter={this.handleMouseEnterRemove}
              onMouseLeave={this.handleMouseLeaveRemove}
              role="presentation"
            >
              <Icon size="md">
                <Close />
              </Icon>
            </span>
          )}
        </div>
      </Tooltip>
    );
  }
}

ImagePicker.defaultProps = {
  btnText: '',
  icon: '',
  accept: 'image/*',
  disabled: false,
  showSpinner: false,
  onChange: Function.prototype,
  onRemove: Function.prototype,
  value: '',
  id: '',
};

ImagePicker.propTypes = {
  title: string.isRequired,
  btnText: string,
  description: string.isRequired,
  value: string,
  onChange: func,
  onRemove: func,
  icon: func,
  disabled: bool,
  showSpinner: bool,
  accept: string,
  maxFileSize: number.isRequired,
  id: string,
};

export default ImagePicker;
