import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';

/**
 * Reset recursive search
 */
function setRecursiveSearch(value) {
  return (dispatch) => {
    utils.LocalStorage.set('picsio.recursiveSearch', !value);
    dispatch({
      type: TYPES.COLLECTIONS.RECURSIVE_SEARCH_SET,
      payload: { value },
    });
  };
}

export default setRecursiveSearch;
