import Q from 'q';
import * as utils from '../../shared/utils';
import Logger from '../../services/Logger';
import { showErrorDialog } from '../../components/dialog';
import l18n from '../../shared/strings';

/**
 * Download file by url
 * @param {String} url
 * @param {String?} responseType
 * @returns {Object} { promise: Promise, cancel: Function }
 *   @param {Promise} promise - Promise that returns file (blob by default)
 *   @param {Function} cancel - function to cancel download
 * }
 */
function downloadFile(url, responseType = 'blob') {
  const xhr = new XMLHttpRequest();
  let cancelled = false;

  function cancel() {
    cancelled = true;
    xhr.abort();
  }

  const promise = (() => Q.Promise((resolve, reject, notify) => {
    let numberOfAttempts = 1;

    const handleError = (err) => {
      const connection = utils.getNavigatorConnectionInfo();
      const errorMessageForUser = l18n.DIALOGS.DOWNLOAD_ERROR.TEXT;
      Logger.error(new Error('Can not download file from GD'), { error: err }, [
        'DownloadFileFromGDFailed',
        { errorMessage: (err && err.message) || 'NoMessage', userDialogueMessage: errorMessageForUser, connection },
      ]);
      showErrorDialog(errorMessageForUser, l18n.DIALOGS.DOWNLOAD_ERROR.TITLE);
      reject('Failed to download document. Check your internet connection.');
    };

    const handleAbort = () => {
      if (cancelled) return;

      if (numberOfAttempts > 3) {
        handleError(`Can not download File from GD, request aborted ${numberOfAttempts} times`);
        return;
      }
      numberOfAttempts += 1;
      /** exponential backoff */
      setTimeout(doRequest, numberOfAttempts * 500);
    };

    const handleProgress = (event) => {
      if (event.lengthComputable) {
        notify({ loaded: event.loaded, total: event.total, xhr });
      }
    };

    const handleLoad = (resp) => {
      if (resp.currentTarget.status === 200) {
        const file = resp.currentTarget.response;
        resolve(file);
      } else {
        reject(resp.currentTarget.status);
      }
    };

    function doRequest() {
      xhr.open('GET', url, true);
      xhr.responseType = responseType;

      xhr.onload = handleLoad;
      xhr.onerror = handleError;
      xhr.onabort = handleAbort;
      xhr.onprogress = handleProgress;
      xhr.send();
    }
    doRequest();
  }))();

  return { promise, cancel };
}

export default downloadFile;
