import { createSelector } from 'reselect';

// const archiveSelector = (state) => Boolean(
//  state.main.openedTree === 'archive' && state.archive.activeCollection);
const archiveSelector = (state) => Boolean(
  state.router.location.query.archived && state.archive.activeCollection,
);

const getActiveCollections = (state) => state.collections.activeCollections;

const rootCollectionSelector = (state) => state.collections.collections.my;

const getActiveArchiveCollection = (state) => state.archive.activeCollection;

export const activeCollectionSelector = createSelector(
  archiveSelector,
  getActiveCollections,
  getActiveArchiveCollection,
  // archiveCollection is returned as array to minimize the type handling in the components, object and array
  // collections are returned as array
  (isArchive, collections, archiveCollection) => (isArchive ? [archiveCollection] : collections),
);

export const getRootCollectionId = createSelector(
  rootCollectionSelector,
  (collection) => collection?._id,
);

export default {
  activeCollectionSelector,
};
