import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import createBreadcrumbs from '../utils/createBreadcrumbs';
import s from './Breadcrumbs.module.scss';
import i18n from '../../../shared/strings';

const Breadcrumbs = ({ currentFolderPath, onClick }) => {
  const breadcrumbs = createBreadcrumbs(currentFolderPath);

  const handleClickBreadcrumb = useCallback((e) => {
    const { path } = e.currentTarget.dataset;
    if (!path) {
      onClick('');
      return;
    }
    onClick(e.currentTarget.dataset.path);
  }, [onClick]);

  const renderBreadcrumbs = () => {
    if (breadcrumbs.length <= 4) {
      return breadcrumbs.map((breadcrumb, index) => (
        <span
          key={breadcrumb.link}
          data-path={breadcrumb.link}
          onClick={!(index < breadcrumbs.length - 1) ? () => {} : handleClickBreadcrumb}
          className={cn(s.breadcrumb, !(index < breadcrumbs.length - 1) && s.active)}
          role="presentation"
        >
          {breadcrumb.label}
          {index < breadcrumbs.length - 1 && ' / '}
        </span>
      ));
    }

    return (
      <>
        <span
          onClick={handleClickBreadcrumb}
          data-path={breadcrumbs[0].link}
          className={s.breadcrumb}
          role="presentation"
        >
          {breadcrumbs[0].label} /
        </span>
        <span className={s.breadcrumb}>... /</span>
        {breadcrumbs.slice(-2).map((breadcrumb, index) => (
          <span
            key={breadcrumb.link}
            data-path={breadcrumb.link}
            onClick={!(index < 1) ? () => {} : handleClickBreadcrumb}
            className={cn(s.breadcrumb, index === 1 && s.active)}
            role="presentation"
          >
            {breadcrumb.label}
            {index === 0 && ' / '}
          </span>
        ))}
      </>
    );
  };

  return (
    <nav className={s.Breadcrumbs}>
      <Choose>
        <When condition={!breadcrumbs.length}>
          <span className={s.breadcrumb}>{i18n.DROPBOX_CHOOSER.rootBreadcrumb}</span>
        </When>
        <Otherwise>
          <span
            onClick={handleClickBreadcrumb}
            className={s.breadcrumb}
            role="presentation"
          >
            {`${i18n.DROPBOX_CHOOSER.rootBreadcrumb} / `}
          </span>
          {renderBreadcrumbs()}
        </Otherwise>
      </Choose>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  currentFolderPath: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Breadcrumbs;
