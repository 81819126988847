function prepareModifiedMetaFields(modifiedMetaFields = []) {
  const obj = {};
  if (modifiedMetaFields.length) {
    modifiedMetaFields.forEach((item) => {
      obj[item.name] = item;
    });
  }
  return obj;
}

export default prepareModifiedMetaFields;
