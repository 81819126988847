import {
  arrayOf, bool, func, number, objectOf, oneOfType, string,
} from 'prop-types';
import cn from 'classnames';
import React, { useCallback } from 'react';
import Logger from '../../../../services/Logger';
import Separator from './Separator';
import Field from './Field';

export default function DetailsPanelCustomFieldGroup({
  data, detailsPanelVisibility, customFieldsAllowed, toggleVisibility,
  disabled, modifiedFields, onChange, selectedAssetsIds, inProgressItems, group,
}) {
  const handleChange = useCallback((value, field, onCancel = Function.prototype, { isAttach } = {}) => {
    const {
      title, type, multiple, visibility,
    } = field;
    Logger.log('User', 'InfoPanelChangeField', title);

    let focusedInput = null;
    /** save next focused input to variable */
    setTimeout(() => { focusedInput = document.querySelector('input:focus'); }, 0);

    onChange({
      ids: selectedAssetsIds,
      title,
      type,
      value: type === 'enum' && value === null ? '' : value,
      multiple,
      isAttach,
      onCancel,
      visibility: visibility || 'visible',
    });

    /** return focus to input */
    if (focusedInput) focusedInput.focus();
  }, [onChange, selectedAssetsIds]);

  if (group.isTmp) return null;

  const separator = group[0];
  const isVisible = detailsPanelVisibility[separator.title];
  const groupToRender = customFieldsAllowed && isVisible ? group : [separator];

  return (
    <div
      data-qa="details-component-customFields"
      className={cn('detailsPanel__item detailsPanel__customFields')}
      data-block="detailsCustomFields"
    >
      <div
        data-qa={`details-component-customFields-${separator.title}`}
        key={separator.title}
        className={cn('customFieldGroup', { act: isVisible })}
      >
        {groupToRender.map((field) => {
          if (field.type === 'separator') {
            return (
              <Separator
                title={field.title}
                key={field.title}
                toggleVisibility={toggleVisibility}
                customFieldsAllowed={customFieldsAllowed}
              />
            );
          }
          return (
            <Field
              key={field.title}
              data={field}
              value={data[field.title]}
              disabled={disabled || (field.default && !field.writable)}
              inProgress={inProgressItems.includes(field.title)}
              modified={modifiedFields[field.title]}
              onChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
}

DetailsPanelCustomFieldGroup.defaultProps = {
  onChange: Function.prototype,
  disabled: false,
  inProgressItems: [],
  customFieldsAllowed: true,
  modifiedFields: {},
  group: [],
};

DetailsPanelCustomFieldGroup.propTypes = {
  data: objectOf(oneOfType([bool, string, number, arrayOf(string)])).isRequired,
  detailsPanelVisibility: objectOf(bool).isRequired,
  selectedAssetsIds: arrayOf(string).isRequired,
  toggleVisibility: func.isRequired,
  onChange: func,
  inProgressItems: arrayOf(string),
  disabled: bool,
  customFieldsAllowed: bool,
  modifiedFields: objectOf(objectOf(oneOfType([bool, string, number, arrayOf(string)]))),
  group: arrayOf(objectOf(oneOfType([bool, string, number, arrayOf(string)]))),
};
