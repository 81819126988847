import React, { useCallback } from 'react';
import {
  arrayOf, bool, func, number, objectOf, oneOfType, string,
} from 'prop-types';
import DetailsPanelEditFieldView from './DetailsPanelEditFieldView';

const DetailsPanelEditFieldGroup = ({
  id, title, type, hidden, collapsedGroupsTitles, collapseGroup, toggleField, group,
}) => {

  const hasChildren = group.length > 1;
  const isChecked = !(hidden.includes(id) || hidden.includes(id.replace('meta.', '')));
  const groupIds = group.map((field) => field.id || field.title);

  const handleChangeVisibility = useCallback((checked, fieldId, fieldType) => {
    const nextChecked = !checked;
    const ids = fieldType === 'separator' ? groupIds : [fieldId];
    if (fieldType !== 'separator') {
      const otherFieldsInGroup = groupIds.slice(1).filter((item) => item !== fieldId);
      if (nextChecked || !otherFieldsInGroup.find((el) => !(hidden.includes(el) || hidden.includes(el.replace('meta.', ''))))) {
        ids.push(id);
      }
    }
    toggleField(nextChecked, ids);
  }, [groupIds, hidden, id, toggleField]);

  return (
    <div>
      <DetailsPanelEditFieldView
        id={id}
        title={title}
        type={type}
        isChecked={isChecked}
        hasChildren={hasChildren}
        collapsed={
          type === 'separator'
          && !collapsedGroupsTitles.includes(id)
        }
        collapseGroup={collapseGroup}
        toggleField={handleChangeVisibility}
      />
      <If condition={hasChildren && collapsedGroupsTitles.includes(id)}>
        {
          group.slice(1).map((item) => {
            return (
              <DetailsPanelEditFieldView
                id={item.id || item.title}
                parentId={id}
                title={item.title}
                type={item.type}
                isChecked={!(hidden.includes(item.id) || hidden.includes(item.id.replace('meta.', '')))}
                toggleField={handleChangeVisibility}
              />
            );
          })
        }
      </If>
    </div>
  );
};

DetailsPanelEditFieldGroup.defaultProps = {
  hidden: [],
  collapsedGroupsTitles: [],
  collapseGroup: Function.prototype,
  toggleField: Function.prototype,
  group: [],
};

DetailsPanelEditFieldGroup.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  hidden: arrayOf(string),
  collapsedGroupsTitles: arrayOf(string),
  collapseGroup: func,
  toggleField: func,
  group: arrayOf(objectOf(oneOfType([bool, string, number, arrayOf(string)]))),
};


export default DetailsPanelEditFieldGroup;
