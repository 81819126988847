import queryString from 'query-string';
import { showDialog } from '../../components/dialog/index';
import localization from '../../shared/strings';
import * as assetsHelpers from '../../helpers/assets';
import deselectAll from '../actions/assets/deselectAll';
import { isRouteSearch, history } from '../../helpers/history';
import picsioConfig from '../../../../../config';
import ua from '../../ua';

const location = (store) => (next) => (action) => {
  const {
    type,
  } = action;
  if (type === '@@router/LOCATION_CHANGE' && picsioConfig.isMainApp && !ua.isMobileApp()) {
    // https://github.com/remix-run/history/issues/921
    window.addEventListener('beforeunload', () => {
      unblock();
    });
    const unblock = history.block((tx) => {
      // Navigation was blocked! Let's show a confirmation dialog
      // so the user can decide if they actually want to navigate
      // away and discard changes they've made in the current page.
      const { router, assets } = store.getState();
      const { dispatch } = store;

      const query = queryString.parse(tx?.location?.search);
      const nextLocation = { ...tx?.location, query };
      let prevLocation = router?.location;

      if (!isRouteSearch(prevLocation.pathname) && assets.selectedItems.length > 0 && router.entries.length > 1) {
        /**
         * f.e. select assets from collections entries = [collectionIds]
         * go to the /selectedAssets entries = [selectedAssets, collectionIds]
         * go to the /archive entries = [archive, selectedAssets, collectionIds] - however 'archive' shouldn't be there
         */
        prevLocation = router.entries.find((historyLocation, index) => {
          const isNotCurrent = !!index;
          return isNotCurrent && isRouteSearch(historyLocation.pathname);
        });
      }
      const isDifferentEntity = assetsHelpers.isDifferentEntity(nextLocation, prevLocation);
      const isSearch = isRouteSearch(nextLocation?.pathname) && isRouteSearch(prevLocation?.pathname);

      if (assets.selectedItems.length > 0 && isDifferentEntity && isSearch) {
        /* here history.entries have duplicated paths
        * f.e. select assets from collections entries = [collectionIds]
        * go to the /selectedAssets entries = [selectedAssets, collectionIds]
        * go to the /archive entries = [selectedAssets, archive, selectedAssets, collectionIds]
        * selectedAssets and archive are pushed to the array by history.listen, that is why we use splice to cut them
        * it is a "костыльное решение, но пока что это лучшее что мы нашли"
        */
        showDialog({
          title: localization.CLIPBOARD.title,
          text: localization.CLIPBOARD.text,
          textBtnCancel: localization.CLIPBOARD.btnCancelText,
          textBtnOk: localization.CLIPBOARD.btnConfirmText,
          onOk: () => {
            dispatch(deselectAll({ deselectAllFromClipboard: true }));
            unblock();
            tx.retry();
            return next(action);
          },
          onCancel: () => {},
          onClose: () => {},
        });
      } else {
        unblock();
        tx.retry();
        next(action);
      }
    });
  } else {
    next(action);
  }
};

export default location;
