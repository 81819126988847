import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

/**
 * Change collection color
 * @param {string} collectionId
 * @param {string} color
 */
function changeCollectionColor(collectionId, color) {
  return async (dispatch) => {
    try {
      dispatch({
        type: TYPES.COLLECTIONS.CHANGE_COLOR.START,
        payload: {
          collectionId,
        },
      });
      const { data: result } = await sdk.collections.setColor(collectionId, color);

      if (result.success) {
        dispatch({
          type: TYPES.COLLECTIONS.CHANGE_COLOR.COMPLETE,
          payload: {
            collectionId,
            color,
          },
        });
      }
    } catch (error) {
      Logger.error(new Error('Can not change collection color'), { error }, [
        'ChangeCollectionColorFailed',
        'NoMessage',
      ]);
      showDialog({
        title: 'Error',
        text: localization.UNABLE_TO_CHANGE_COLLECTION_COLOR,
        textBtnCancel: null,
      });
      dispatch({
        type: TYPES.COLLECTIONS.CHANGE_COLOR.FAILED,
        payload: {
          collectionId,
        },
      });
    }
  };
}

export default changeCollectionColor;
