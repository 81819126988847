import TYPES from '../../action-types';

import getThumbnails from './getThumbnails';
/**
 * Add revision
 * @param {Object} data
 */
const addRevision = ({
  assetId,
  headRevisionId,
  imageMediaMetadata,
  thumbnailLink,
}) => async (dispatch, getState) => {
  dispatch({
    type: TYPES.ASSETS.ADD_REVISION.COMPLETE,
    payload: {
      assetId,
      headRevisionId,
      imageMediaMetadata,
      thumbnailLink,
      userId: getState().user._id,
    },
  });
  getThumbnails([assetId])(dispatch, getState);
};

export default addRevision;
