import pluralize from 'pluralize';
import findDuplicates from './findDuplicates';
import renderDownloadDialog from '../../components/DownloadDialog/renderDownloadDialog';
import sdk from '../../sdk';
import UiBlocker from '../../services/UiBlocker';

import hasAllAssets from '../../store/helpers/assets/hasAllAssets';
import { showDialog, showErrorDialog } from '../../components/dialog';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';
import store from '../../store';
import extendAsset from '../../store/helpers/assets/extend';

const LIMIT_ASSETS_COUNT = 300;

/**
 * Show download dialog
 * @param {string[]?} ids - assets ids to download
 */
async function showDownloadDialog(ids) {
  const assetsStore = store.getState().assets;
  const { user } = store.getState();
  let allAssets = [...assetsStore.items];
  let assetsIds = ids || assetsStore.selectedItems;

  if (assetsIds?.length > 0 && !hasAllAssets(assetsIds, assetsStore.items)) {
    try {
      UiBlocker.block();
      const { data } = await sdk.assets.getMany(assetsIds);
      allAssets = extendAsset(data.assets);
      UiBlocker.unblock();
    } catch (error) {
      Logger.error(new Error('Error in fetching selected asset for download'), { error });
      showErrorDialog(
        localization.DOWNLOADDIALOG.errorDownloading,
        localization.DOWNLOADDIALOG.errorDownloadingTitle,
      );
      return;
    } finally {
      UiBlocker.unblock();
    }
  }

  const { items: assetsDownloadingNow } = store.getState().downloadList;
  /** if no assetsIds - get selected assets from store */
  if (assetsIds === undefined) assetsIds = assetsStore.selectedItems;

  /** check files count */
  const countLimit = user.settings?.zipperMaxAssetsLimit || user.team?.settings?.zipperMaxAssetsLimit || window.websiteConfig?.user?.settings?.zipperMaxAssetsLimit || LIMIT_ASSETS_COUNT;
  if (assetsIds.length > countLimit) {
    Logger.log('UI', 'TooManyFilesForDownloadDialog', `${assetsIds.length}`);
    const { TITLE, TEXT } = localization.DIALOGS.DOWNLOAD_ASSETS_QUANTITY_LIMITATIONS;
    showDialog({
      title: TITLE,
      text: TEXT(countLimit),
      textBtnCancel: null,
    });
    return;
  }

  let assets = assetsIds.map((_id) => {
    const asset = allAssets.find((item) => item._id === _id);
    if (asset) {
      return { ...asset };
    }
    return { _id };
  });

  /** Find downloadable duplicates */
  const duplicates = findDuplicates(assetsDownloadingNow, assets);
  if (duplicates.length > 0) {
    /** Show warning */
    Logger.log('UI', 'DownloadableDublicatesDownloadDialog', `${duplicates.length}`);

    showDialog({
      title: localization.DIALOGS.DOWNLOAD_DUPLICATES.TITLE(duplicates),
      text: localization.DIALOGS.DOWNLOAD_DUPLICATES.TEXT(duplicates),
      textBtnCancel: null,
      textBtnOk: 'Ok',
    });
    /** Remove duplicates */
    assets = assets.reduce((acc, asset) => {
      if (!duplicates.find((duplicate) => duplicate._id === asset._id)) acc.push(asset);
      return acc;
    }, []);

    /** If all is duplicates - exit */
    if (assets.length < 1) return;
  }

  renderDownloadDialog(assets, { loadedItems: allAssets });
}

export default showDownloadDialog;
