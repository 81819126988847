import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
// import {
//   updateCount as updateCountAction,
// } from '../../keywords/actions';
import Logger from '../../../services/Logger';
import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';
import { showWarningDetachingSeveralKeywords } from '../../helpers/assets';
import sdk from '../../../sdk';

/**
 * Remove keyword from assets
 * @param {string} keywordName
 * @param {string[]?} ids - assets IDs
 */
const detachKeywords = (keywordIds, assetIds) => async (dispatch, getState) => {
  const selectedItems = assetIds || getState().assets.selectedItems;
  /** show dialog about detaching several keywords at a time */
  if (keywordIds.length > 1) {
    const res = await showWarningDetachingSeveralKeywords(keywordIds.length, assetIds.length);
    if (!res) {
      /** User has cancelled operation */
      return false;
    }
  }

  try {
    await asyncCheckMultipleChanges(selectedItems.length);
  } catch (error) {
    /** User has cancelled operation */
    return false;
  }

  try {
    dispatch({ type: TYPES.ASSETS.DETACH_KEYWORD.START });

    /** wait for response */
    await sdk.keywords.detachMany(selectedItems, keywordIds);
    dispatch({
      type: TYPES.ASSETS.DETACH_KEYWORD.COMPLETE,
      payload: {
        ids: assetIds,
        keywordsIds: keywordIds,
        userId: getState().user._id,
      },
    });
    return true;
    /** @TODO: need to check, not all assets may contain all keywords */
    // dispatch(updateCountAction({ _id: keywordID, value: -selectedItems.length }));
  } catch (error) {
    Logger.error(new Error('Can not dettach keywords from asset'), { error });
    const errorStatus = utils.getStatusFromResponceError(error);
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : utils.getDataFromResponceError(error, 'msg');
    if (errorMessage) {
      Toast(errorMessage, { autoClose: false, type: 'error' });
    } else {
      Toast(localization.DETAILS.textCantUpdateKeywords, {
        autoClose: false,
        type: 'error',
        onOk: reloadCurrentPage,
        btnOkValue: localization.HISTORY.textBtnRefresh,
      });
    }
    dispatch({ type: TYPES.ASSETS.DETACH_KEYWORD.FAILED, error });
    return false;
  }
};

export default detachKeywords;
