import TYPES from '../../action-types';

/**
 * Remove keyword from assets immediately
 * @param {string[]} ids
 * @param {string} keywordID
 */
export const detachedKeyword = (ids, keywordID, userId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.DETACH_KEYWORD.COMPLETE,
    payload: {
      ids,
      keywordsIds: [keywordID],
      notify: true,
      userId,
    },
  });
};

export default detachedKeyword;
