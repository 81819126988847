import React, { useCallback } from 'react';
import {
  MenuItem, MenuItemIcon, MenuItemText,
} from '@picsio/ui';
import PropTypes from 'prop-types';

export default function TranscribeMenuItem({
  id, action, icon, text, onClick,
}) {
  const handleClick = useCallback(() => {
    onClick(action);
  }, [onClick, action]);

  return (
    <MenuItem
      key={id}
      id={id}
      onClick={handleClick}
      className="menuItemDefault"
    >
      <MenuItemIcon size="md">
        {icon}
      </MenuItemIcon>
      <MenuItemText primary={text} />
    </MenuItem>
  );
}

TranscribeMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
