/* eslint-disable default-case */
import React, {
  memo, useMemo, useState, useCallback, useEffect,
} from 'react';
import {
  func, number, arrayOf, shape, oneOfType, string,
} from 'prop-types';

import TwoLevelMenu from '../../../../TwoLevelMenu';
import CONTROLS from './controls';

const PlayerGearMenu = ({
  valuePlaybackRate,
  valueTimeCode,
  valueQuality,
  qualityList,
  onChangePlaybackRate,
  onChangeQuality,
  onChangeTimeCode,
}) => {
  const [value, setValue] = useState({});

  const controls = useMemo(() => {
    const result = [...CONTROLS];
    result[1] = {
      ...result[1],
      items: qualityList.map((i) => ({ ...i, id: i.value })),
    };
    return result;
  }, [qualityList]);

  useEffect(
    () => {
      setValue({
        speed: CONTROLS[0].items.find(({ id }) => id === valuePlaybackRate)?.id,
        quality: valueQuality,
        timeView: valueTimeCode,
      });
    },
    [valuePlaybackRate, valueQuality, valueTimeCode],
  );

  const handleChange = useCallback((menuId, itemId) => {
    switch (menuId) {
    case 'speed':
      onChangePlaybackRate(itemId);
      break;
    case 'quality':
      onChangeQuality(itemId);
      break;
    case 'timeView':
      onChangeTimeCode(itemId);
      break;
    }
  }, [onChangeQuality, onChangeTimeCode, onChangePlaybackRate]);

  return (
    <TwoLevelMenu
      tooltip="Settings"
      menuArrow={false}
      value={value}
      onChange={handleChange}
      controls={controls}
      className="playerGearMenu"
      disablePortal
    />
  );
};

PlayerGearMenu.defaultProps = {
  valuePlaybackRate: 1,
  valueQuality: null,
  valueTimeCode: 'Standard',
  qualityList: [],
};

PlayerGearMenu.propTypes = {
  valuePlaybackRate: oneOfType([string, number]),
  onChangePlaybackRate: func.isRequired,
  valueQuality: oneOfType([string, number]),
  qualityList: arrayOf(shape({
    text: oneOfType([string, number]),
    value: oneOfType([string, number]),
  })),
  onChangeQuality: func.isRequired,
  valueTimeCode: string,
  onChangeTimeCode: func.isRequired,
};

export default memo(PlayerGearMenu);
