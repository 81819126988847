/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addRole = createAsyncThunk(
  'roles/add',
  async (payload, { extra: { sdk, Logger } }) => {
    Logger.log('User', 'SettingsMyTeamRolesCreate');
    const { data } = await sdk.roles.create(payload);
    return {
      ...data,
      allowedCollections: [...payload.allowedCollections],
    };
  },
);

export const reducer = (builder) => builder.addCase(
  addRole.pending,
  (state) => {
    state.loading = true;
    state.error = null;
  },
).addCase(
  addRole.fulfilled,
  (state, { payload }) => {
    state.loading = false;
    state.items.push(payload);
    state.error = null;
  },
).addCase(
  addRole.rejected,
  (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
);
