/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getFacesByIds = createAsyncThunk(
  'faces/getByIds',
  async (
    ids,
    {
      extra: {
        sdk, Logger,
      },
    }) => {
    try {
      const result = await sdk.assets.getFaces(ids);
      return result;
    } catch (error) {
      Logger.error(new Error('Error get faces'), { error }, [
        'GetFacesFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    getFacesByIds.pending,
    () => {
    },
  ).addCase(
    getFacesByIds.fulfilled,
    (state, action) => {
    },
  ).addCase(
    getFacesByIds.rejected,
    () => {
    },
  );
};
