import _get from 'lodash/get';
import picsioConfig from '../../../../../../config';

const getCheckerTarget = (user, type) => {
  const {
    subscriptionFeatures,
    role: { permissions },
  } = user;

  switch (type) {
  case 'permissions':
    return permissions;
  case 'subscriptions':
    return subscriptionFeatures;
  default:
    return {};
  }
};

// simple implementation of permission checker
// now it uses for archive checking.
const checkUserAccess = (type, path, checkerFn = null, user) => {
  if (!picsioConfig.isMainApp) return false;
  if (!user) return false;

  const checkerTarget = getCheckerTarget(user, type);
  const value = _get(checkerTarget, path, false);

  if (checkerFn) {
    return checkerFn(value);
  }
  return !!value;
};

export default checkUserAccess;
