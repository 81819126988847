import { createReducer } from '@reduxjs/toolkit';
import { reducer as downgrade } from './downgrade';
import { reducer as fetchProducts } from './fetchProducts';
import { reducer as selectPlan } from './selectPlan';
import { reducer as selectFeature } from './selectFeature';
import { reducer as getCustomAddonsFromSubscription } from './getCustomAddonsFromSubscription';

const initialState = {
  loading: false,
  downgrading: false,
  error: false,
  data: {
    plans: [],
  },
  customAddonsFromSubscription: [],
};

const billingReducer = createReducer(
  initialState,
  (builder) => {
    downgrade(builder);

    fetchProducts(builder);

    selectPlan(builder);

    selectFeature(builder);

    getCustomAddonsFromSubscription(builder);
  },
);

export default billingReducer;
