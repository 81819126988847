import { createAsyncThunk } from '@reduxjs/toolkit';
import pluralize from 'pluralize';
import * as utils from '../../../shared/utils';

/**
 * Retry importing asset(s)
 * @param {string[]} assetIds
 * @param {string} type
 */
const retryImporting = createAsyncThunk(
  'assets/retryImporting',
  async ({ assetIds, type = 'one' }, {
    extra: {
      sdk, Logger, Toast, localization,
    },
  },
  ) => {
    try {
      if (type === 'all') {
        await sdk.assets.retryImportingAll();
      } else {
        await sdk.assets.rerunJob(assetIds, 'importing');
      }
    } catch (error) {
      const errorSubCode = utils.getDataFromResponceError(error, 'subcode');
      Logger.error(new Error('Retry importing assets error'), { error }, [
        'RetryImportingAssetsFailed',
        (error && error.message) || 'NoMessage',
      ]);

      if (errorSubCode === 'RetryImportingAssetsError') {
        Toast(localization.TOAST.ASSETS_FOR_RETRY_NOT_FOUND, {
          autoClose: false,
          closeButton: false,
          type: 'error',
        });
        return;
      }

      Toast(localization.TOAST.RETRY_IMPORT_ASSETS_FAILED(assetIds.length), {
        autoClose: false,
        closeButton: false,
        type: 'error',
      });
    }
  },
);

export default retryImporting;
