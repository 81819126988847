import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import ScreenPreview from './views/index';

export default function () {
  const { id } = useParams();
  return (
    <div className="wrapperScreenPreview">
      <ErrorBoundary className="errorBoundaryOverlay">
        <ScreenPreview id={id} />
      </ErrorBoundary>
    </div>
  );
}
