import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '@picsio/ui';
import { Info } from '@picsio/icons';
import cn from 'classnames';
import Dropdown from '../dropdown';
import localization from '../../shared/strings';

const CustomFieldsSort = (props) => {
  const {
    hiddenFields,
    customFields,
    hideDisabled,
    selectedCustomFields,
    showCustomFieldsSelector,
    openDropdown,
    dropdownPosition,
    autoFocus,
    disablePortal,
    addField,
    removeField,
    openerTitle,
    additionalClassName,
  } = props;

  const [fields, setFields] = useState({
    all: [],
    writable: [],
  });

  const items = hideDisabled ? fields.writable : fields.all;

  useEffect(() => {
    const newFields = customFields
      .reduce((acc, item) => {
        const { type, title: itemTitle, writable } = item;

        if (type !== 'separator' && !hiddenFields.includes(itemTitle)) {
          const newItem = {
            ...item,
            _id: itemTitle,
          };

          acc.all.push(newItem);

          if (writable !== false) {
            acc.writable.push(newItem);
          }
        }
        return acc;
      }, {
        all: [],
        writable: [],
      });

    setFields(newFields);
  }, [customFields, hiddenFields]);

  return (
    <div className="customFieldsSortSelectorWrapper">
      <Choose>
        <When condition={showCustomFieldsSelector}>
          <Dropdown
            filterText={localization.DROPDOWN.chooseCustomFields}
            checkedItems={selectedCustomFields}
            items={items}
            onCheckedHandler={addField}
            onUncheckedHandler={removeField}
            createHandler={null}
            readOnly={false}
            disabled={false}
            position={dropdownPosition}
            autoFocus={autoFocus}
            type="default"
            disablePortal={disablePortal}
            showChips={false}
            normalizeDropdownPosition={false}
          />
        </When>
        <Otherwise>
          <Choose>
            <When condition={selectedCustomFields.length < 3}>
              <Button
                variant="text"
                color="primary"
                onClick={openDropdown}
                className={cn({ [additionalClassName]: additionalClassName })}
                componentProps={{ 'data-testid': 'addCustomFieldForSorting' }}
              >
                {openerTitle}
              </Button>
            </When>
            <Otherwise>
              <div className="customFieldsSortInfo">
                <Icon size="md">
                  <Info />
                </Icon>
                <span className="text">{localization.SORT.maxCustomFieldsCountReached}</span>
              </div>
            </Otherwise>
          </Choose>

        </Otherwise>
      </Choose>
    </div>
  );
};

CustomFieldsSort.defaultProps = {
  openerTitle: 'Add custom field',
  customFields: [],
  hiddenFields: [],
  selectedCustomFields: [],
  addField: null,
  removeField: null,
  hideDisabled: false,
  autoFocus: false,
  disablePortal: false,
  dropdownPosition: 'auto',
  openDropdown: () => {},
  additionalClassName: '',
};
CustomFieldsSort.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.object),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  addField: PropTypes.func,
  removeField: PropTypes.func,
  hideDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disablePortal: PropTypes.bool,
  dropdownPosition: PropTypes.string,
  showCustomFieldsSelector: PropTypes.bool.isRequired,
  openDropdown: PropTypes.func,
  selectedCustomFields: PropTypes.arrayOf(PropTypes.object),
  openerTitle: PropTypes.string,
  additionalClassName: PropTypes.string,
};

export default CustomFieldsSort;
