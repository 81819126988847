import Q from 'q';
import { removeAutorization } from '../../../shared/utils';
import sdk from '../../../sdk';
import UiBlocker from '../../../services/UiBlocker';
import Logger from '../../../services/Logger';
import removeCurrentUserWebPushSubscription from './removeCurrentUserWebPushSubscription';

/**
 * Logouts current user and remove all session info from client.
 * NOTE: actually there is no need to store this info on client.
 * Backend holds all sessions for us.
 * @return {[type]} [description]
 */
const logout = async (user) => {
  const dfd = Q.defer();

  UiBlocker.block('Logging out of the app...');
  await removeCurrentUserWebPushSubscription(user);

  try {
    Logger.info('starting signout');
    const res = await sdk.users.logout();
    if (res.data.errors) {
      UiBlocker.unblock();
      Logger.info('signout errors');
      dfd.reject(res.data.errors);
    } else if (res.data.user) {
      Logger.log('User', 'Logout', res.data.user.email);

      /** clear user data(amplitude, sentry) */
      removeAutorization();
      Logger.unsetUser();

      dfd.resolve(res.data.user);
    } else {
      UiBlocker.unblock();
      Logger.info('Something went wrong');
      dfd.reject({ error: 'Something went wrong' });
    }
  } catch (error) {
    UiBlocker.unblock();
    Logger.info('Something went wrong');
    dfd.reject({ error: 'Something went wrong' });
  }

  return dfd.promise;
};

export default logout;
