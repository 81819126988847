import { STORAGE_LITERALS } from '@picsio/db/src/constants';

export default function prepareSelectedFileFolder(selectedItems) {
  let filesSize = 0;
  const selectedFolderPaths = [];
  const preparedItems = selectedItems.map((item) => {
    let sourcePath = item.path_display;
    if (item['.tag'] === 'file') {
      filesSize += item.size;
      const index = item.path_display.lastIndexOf('/');
      sourcePath = item.path_display.substring(0, index);
    } else if (item['.tag'] === 'folder') {
      selectedFolderPaths.push(item.path_display);
    }
    return {
      ...item,
      importFrom: {
        storageType: STORAGE_LITERALS.user_dbx,
        sourcePath: sourcePath || '/',
      },
    };
  });

  return { filesSize, selectedFolderPaths, preparedItems };
}
