import { showDialog } from '../../dialog';
import l18n from '../../../shared/strings';
import UiBlocker from '../../../services/UiBlocker';
import Logger from '../../../services/Logger';
import sdk from '../../../sdk';
import destroyWebsiteSettings from './destroyWebsiteSettings';
import createWebsite from './createWebsite';

const startCreatingWebsite = ({
  collectionId, website, subscriptionFeatures, manageBilling, imageData = undefined,
}) => async (dispatch) => {
  // flow
  // a. check domain for availability
  // a-a. domain is OK - apply changes and close dialog
  // a-b. domain is not set - show confirmation with message 'domain not set'
  // a-b-a. user clicked OK - apply changes and close dialog
  // a-b-b. user clicked cancel - close confirmation and return to main dialog
  // a-c. domain is points to us - show confirmation with message 'domain not points'
  // a-c-a. user clicked OK - apply changes and close dialog
  // a-c-b. user clicked cancel - close confirmation and return to main dialog
  // should check domain errors before
  try {
    await sdk.websites.checkDomain(website.alias);
  } catch (error) {
    // unrecoverable situation
    const { code } = error.response.data;
    if (code === 'noalias' || code === 'wrongalias') {
      throw new Error(error.response.data.msg);
    }
    // warnings
    if (code) {
      const messages = {
        norecord: l18n.WEBSITES.textNorecord,
        anotherip: l18n.WEBSITES.textAnotherip,
      };
      const msg = messages[code] || l18n.WEBSITES.textDomainCantVerified;

      const result = await new Promise((resolve) => {
        showDialog({
          title: l18n.WEBSITES.titleWarning,
          text: msg,
          textBtnOk: l18n.DIALOGS.btnOk,
          textBtnCancel: l18n.DIALOGS.btnCancel,
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });
      /** Cancelled */
      if (!result) return;
    }
  }

  try {
    UiBlocker.unblock();
    await createWebsite({
      imageData, collectionId, website, subscriptionFeatures, manageBilling,
    })(dispatch);
    if (imageData) {
      destroyWebsiteSettings();
    }
  } catch (error) {
    UiBlocker.unblock();
    Logger.error(new Error('Error create website'), { error }, [
      'CreateWebsiteFailed',
      (error?.message) || 'NoMessage',
    ]);
  }
};

export default startCreatingWebsite;
