import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCustomAddonsFromSubscription = createAsyncThunk(
  'billing/getCustomAddonsFromSubscription',
  async (_, { extra: { sdk } }) => {
    const { data: customAddonsFromSubscription } = await sdk.billing.getCustomAddonsFromSubscription();

    return customAddonsFromSubscription;
  },
);

/* eslint-disable no-param-reassign */
export const reducer = (builder) => {
  builder
    .addCase(
      getCustomAddonsFromSubscription.fulfilled,
      (state, { payload }) => {
        state.customAddonsFromSubscription = payload;
      },
    )
    .addCase(
      getCustomAddonsFromSubscription.rejected,
      (state) => {
        state.error = true;
      },
    );
};
/* eslint-enable no-param-reassign */
