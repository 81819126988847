/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { setField, move as moveHelper } from '../helpers/keywords';

export const move = createAsyncThunk(
  'keywords/move',
  async ({ _id, parentId }, {
    extra: {
      sdk, utils, showErrorDialog, Logger,
    },
  }) => {
    try {
      await sdk.keywords.move(_id, parentId);
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg');
      if (errorMessage) {
        showErrorDialog(errorMessage);
        Logger.warn('Can not move keyword:', errorMessage);
      } else {
        Logger.error(new Error('Can not move keyword'), { error, showDialog: true }, [
          'MoveKeywordFailed',
          error?.message || 'NoMessage',
        ]);
      }
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    move.pending,
    (state, { meta }) => {
      state.tree = setField(state.tree, meta.arg._id, 'isBusy', true);
    },
  )
  .addCase(
    move.fulfilled,
    (state, { meta }) => {
      const { all, keywords, favorites } = moveHelper(
        state.tree,
        state.all,
        meta.arg._id,
        meta.arg.parentId,
        state.sortType,
      );
      state.all = all;
      state.tree = { keywords, favorites };
    },
  )
  .addCase(
    move.rejected,
    (state, { meta, error }) => {
      state.error = error;
      state.tree = setField(state.tree, meta.arg._id, 'isBusy', false);
    },
  );
