import TYPES from '../../action-types';

import getDispatchTypeByAsyncStatus from './getDispatchTypeByAsyncStatus';

/**
 * Set "untrashing" field
 * @param {String[]} ids
 * @param {String} status - async job status [waiting, complete, failed]
 */
export const setUntrashing = (ids, status) => (dispatch) => {
  const type = getDispatchTypeByAsyncStatus(status);
  dispatch({
    type: TYPES.ASSETS.RESTORE_ASSETS[type],
    payload: { ids },
  });
};

export default setUntrashing;
