import CONSTANTS from '@picsio/db/src/constants';
import { WebIcon } from '@picsio/icons';

export default [
  {
    id: 'locale',
    text: 'Transcribing language',
    Icon: WebIcon,
    items: Object.keys(CONSTANTS.assets.transcribe.availableLanguages).map((id) => ({
      id,
      text: CONSTANTS.assets.transcribe.availableLanguages[id],
    })),
  },
];
