/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const setImageData = createAction('inboxes/setImageData');

export const reducer = (builder) => {
  builder.addCase(
    setImageData.type,
    (state, { payload }) => {
      const { _id, fieldName, fileUrl } = payload;
      state.inboxes.forEach((inbox) => {
        if (inbox._id === _id) inbox[fieldName] = fileUrl;
      });
    },
  );
};
