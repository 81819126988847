// Sort fields by order form localstorage config. New fields will be added to the end of the list.
const sortFieldsByConfigOrder = (fields, storageConfig) => {
  const { order, hidden } = storageConfig;
  const fieldsByCategory = fields.reduce(
    (acc, group) => {
      const field = group[0];
      const { id, title } = field;
      const key = id || title;
      const orderIndex = order.indexOf(key);
      const hiddenIndex = hidden.indexOf(key);

      if (orderIndex !== -1) {
        acc.byOrder[orderIndex] = group;
      } else if (hiddenIndex !== -1) {
        acc.hidden[hiddenIndex] = group;
      } else {
        acc.new.push(group);
      }
      return acc;
    },
    {
      byOrder: [],
      hidden: [],
      new: [],
    },
  );
  const sortedFields = [...fieldsByCategory.byOrder, ...fieldsByCategory.hidden, ...fieldsByCategory.new];
  // Filter undefined fields in case when this fields weren't present in previous config.
  const filteredByEmpty = sortedFields.filter((id) => !!id);

  return filteredByEmpty;
};

export default sortFieldsByConfigOrder;
