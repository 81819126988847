/* eslint-disable no-plusplus */
export default function outy(nodes, types, eventHandler) {
  const currentNodes = Array.isArray(nodes) ? nodes : [nodes];
  const currentTypes = Array.isArray(types) ? types : [types];

  function handleEvent(e) {
    for (let i = currentNodes.length; i--;) {
      if (currentNodes[i].contains(e.target)) return;
    }
    eventHandler(e);
  }

  for (let i = currentTypes.length; i--;) {
    document.addEventListener(currentTypes[i], handleEvent, { capture: true });
  }

  return {
    remove() {
      for (let i = currentTypes.length; i--;) {
        document.removeEventListener(currentTypes[i], handleEvent, { capture: true });
      }
    },
  };
}
