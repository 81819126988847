import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import picsioConfig from '../../../../../config';
import Logger from '../../services/Logger';
import {
  navigateToRoot,
  setSearchRoute,
  getSearchProps,
} from '../../helpers/history';
import * as mainActions from '../../store/actions/main';
import PlaceholderArchive from './PlaceholderArchive';
import PlaceholderKeywords from './PlaceholderKeywords';
import PlaceholderInboxes from './PlaceholderInboxes';
import PlaceholderLightboards from './PlaceholderLightboards';
import PlaceholderSavedSearches from './PlaceholderSavedSearches';
import PlaceholderOtherFilters from './PlaceholderOtherFilters';
import PlaceholderSelectedAssets from './PlaceholderSelectedAssets';
import l18n from '../../shared/strings';
import './placeholderEmpty.scss';
import UploadPlaceholder from '../UploadPlaceholder';

const PlaceholderEmptySearch = (props) => {
  const { external = false, externalLink = null } = props;
  const dispatch = useDispatch();
  const routerQuery = useSelector((state) => state.router.location.query);
  const pathName = useSelector((state) => state.router.location.pathname);
  const { openedTree } = useSelector((state) => state.main);
  const { activeSavedSearch } = useSelector((state) => state.savedSearches);
  const { activeCollections } = useSelector((state) => state.collections);
  const { collections } = useSelector((state) => state.collections);

  const checkForCustomFields = () => {
    const customField = Object.keys(routerQuery).find((filter) => filter.startsWith('meta'));
    return Boolean(customField);
  };

  const searchInRootCollection = useCallback(() => {
    const searchProps = getSearchProps();
    searchProps.collectionIds = collections?.my?._id;
    setSearchRoute(searchProps);
  }, [collections?.my?._id]);

  const checkForOtherFilters = () => {
    const queryWithCustomFields = checkForCustomFields();
    return (
      queryWithCustomFields
      || routerQuery.text
      || routerQuery.type
      || routerQuery.color
      || routerQuery.createdAt
      || routerQuery.flag
      || routerQuery.rating
      || routerQuery.updatedAt
    );
  };

  const isRootCollection = useMemo(() => {
    if (!collections) return true;
    return collections?.my?._id === routerQuery?.collectionIds;
  }, [collections, routerQuery?.collectionIds]);

  const goToLibrary = () => {
    Logger.log('User', 'GoToLibrary');
    navigateToRoot();
    if (openedTree !== 'collections') {
      dispatch(mainActions.changeTree('collections'));
    }
  };

  return (
    <div className="placeholderEmpty">
      <Choose>
        <When condition={routerQuery.keywords?.length}>
          <PlaceholderKeywords />
        </When>
        <When condition={routerQuery.inboxId}>
          <PlaceholderInboxes goToLibrary={goToLibrary} />
        </When>
        <When condition={routerQuery.lightboardId}>
          <PlaceholderLightboards
            lightboardId={routerQuery.lightboardId}
          />
        </When>
        <When condition={activeSavedSearch}>
          <PlaceholderSavedSearches
            name={activeSavedSearch.name}
            goToLibrary={goToLibrary}
          />
        </When>
        <When condition={routerQuery.archived}>
          <PlaceholderArchive />
        </When>
        <When condition={checkForOtherFilters()}>
          <PlaceholderOtherFilters
            collectionsName={activeCollections.map(
              (activeCollection) => activeCollection.name,
            )}
            isRootCollection={isRootCollection}
            handleNewSearch={searchInRootCollection}
            searchText={routerQuery.text}
          />
        </When>
        <When condition={external}>
          <div className="placeholderEmptyTitle">
            {l18n.SEARCH.emptyPlaceholder.cantOpenLink(externalLink)}
            <br />
            <If condition={picsioConfig.isMainApp}>
              {l18n.SEARCH.emptyPlaceholder.clickLink}
            </If>
          </div>
        </When>
        <When condition={pathName === '/selectedAssets'}>
          <PlaceholderSelectedAssets />
        </When>
        <Otherwise>
          <Choose>
            <When condition={
              picsioConfig.isMainApp
              && activeCollections.length === 1
              && !routerQuery.trashed
              && activeCollections[0]?.permissions?.upload
            }
            >
              <UploadPlaceholder
                withTitle
                allowExternal
                allowCloudStorages
                collectionId={routerQuery?.collectionIds}
              />
            </When>
            <Otherwise>
              <div className="placeholderEmptyTitle">
                {l18n.SEARCH.emptyPlaceholder.emptyCollections(activeCollections.map((activeCollection) => activeCollection.name))}
                <br />
              </div>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </div>
  );
};

PlaceholderEmptySearch.propTypes = {
  external: PropTypes.bool,
  externalLink: PropTypes.string,
};

export default PlaceholderEmptySearch;
