/**
 * Make search items
 * @param {Object[]} all
 * @param {string} searchQuery
 * @returns {Object} search items
 */
const makeSearchItems = (all, searchQuery) => {
  const items = all.filter(
    (node) => node.type === 'separator' || node.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return items;
};

export default makeSearchItems;
