import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import changeCountCollectionsAction from '../collections/changeCount';
import * as utils from '../../../shared/utils';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Remove not found assets
 * @param {string[]?} iDs - ids
 */
const removeNotFoundAssets = (iDs) => async (dispatch, getAll) => {
  let ids = iDs;
  const assetsStore = getAll().assets;
  if (ids === undefined) ids = assetsStore.selectedItems;

  function handleSuccess() {
    dispatch({
      type: TYPES.ASSETS.DELETE_ASSETS.COMPLETE,
      payload: { ids },
    });
    changeCountCollectionsAction(-ids.length)(dispatch);
  }

  UiBlocker.block();
  try {
    dispatch({ type: TYPES.ASSETS.DELETE_ASSETS.START, payload: { ids } });

    const { data: result } = await sdk.assets.delete(ids);
    if (result.queued) {
      Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
    } else {
      handleSuccess();
    }
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    if (errorStatus === 410) {
      /** Already deleted in GD -> status === 410 */
      handleSuccess();
    } else {
      dispatch({
        type: TYPES.ASSETS.DELETE_ASSETS.FAILED,
        payload: { ids },
        error,
      });
      Toast(localization.DETAILS.textCantRemoveAsset(ids.length), { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      Logger.error(new Error('Can not remove not found assets'), { error }, [
        'RemoveNotFoundAssetsFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  } finally {
    UiBlocker.unblock();
  }
};

export default removeNotFoundAssets;
