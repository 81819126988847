/**
 * Set field to savedSearch
 * @param {Object} tree
 * @param {string} id
 * @param {string} fieldName
 * @param {*} fieldValue
 * @returns {Object} new tree
 */
export default function setField(tree, id, fieldName, fieldValue) {
  const handleSavedSearch = (savedSearch) => {
    if (savedSearch.nodes && savedSearch.nodes.length > 0) {
      savedSearch.nodes = savedSearch.nodes.map(handleSavedSearch);
    }
    return savedSearch._id === id ? { ...savedSearch, [fieldName]: fieldValue } : savedSearch;
  };

  return {
    favorites: handleSavedSearch(tree.favorites),
    savedSearches: handleSavedSearch(tree.savedSearches),
  };
}
