import React, { useCallback } from 'react';
import { IconButton } from '@picsio/ui';
import { Close } from '@picsio/icons';
import { func, string } from 'prop-types';
import Tooltip from '../Tooltip';

export default function UrlItem({ url, onRemove = null }) {
  const handleRemove = useCallback(
    () => onRemove(url),
    [onRemove, url],
  );

  return (
    <li>
      <Tooltip content={url}>
        <span className="text">{url}</span>
      </Tooltip>
      <If condition={onRemove}>
        <Tooltip content="Remove">
          <IconButton onClick={handleRemove} size="md"><Close /></IconButton>
        </Tooltip>
      </If>
    </li>
  );
}

UrlItem.propTypes = {
  url: string.isRequired,
  onRemove: func,
};
