import Logger from '../../../services/Logger';
import { showDialog } from '../../../components/dialog';
import localization from '../../../shared/strings';

/**
 * Notify user about missing permissions
 * @param {string} text
 */
function showForbiddenDialog(text) {
  Logger.log('UI', 'ForbiddenDialog', text);
  showDialog({
    title: localization.DIALOGS.WARNING_EDIT_ASSET_COLLECTIONS.TITLE,
    text,
    textBtnCancel: null,
    textBtnOk: localization.DIALOGS.btnOk,
    onCancel: () => {},
    onOk: () => {},
  });
}

export default showForbiddenDialog;
