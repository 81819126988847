import { createSlice } from '@reduxjs/toolkit';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import TYPES from '../action-types';
import subscribe from '../actions/user/subscribe';
import changeCard from '../actions/user/changeCard';
import redeemCoupon from '../actions/user/redeemCoupon';
import buyAICredits from '../actions/user/buyAICredits';
import fetchApiKeys from '../actions/user/fetchApiKeys';
import createApiKey from '../actions/user/createApiKey';
import removeApiKey from '../actions/user/removeApiKey';
import updateUserCustomerTax from '../actions/user/updateUserCustomerTax';
import updateUserSearchSettings from '../actions/user/updateUserSearchSettings';
import addLiveChatIconSeen from '../actions/user/addLiveChatIconSeen';
import updateTeamAICount from '../actions/user/updateTeamAICount';
import addSubtitlesGenerated from '../actions/user/addSubtitlesGenerated';

/* eslint-disable no-param-reassign */
const updateUserPolicy = (state, key, value) => {
  const method = value ? _set : _unset;

  method(state.team.policies, key, value);
};

/* eslint-disable  no-param-reassign */
const userSortTypeUpdate = (state, action) => {
  const {
    payload: {
      collectionType, sortType, prevSortType = null, setBase, base,
    },
  } = action;
  const stateField = state[`${collectionType}SortType`];
  const currentSort = prevSortType || sortType;

  if (!stateField) {
    state[`${collectionType}SortType`] = currentSort;
  } else {
    stateField.type = currentSort.type;
    stateField.order = currentSort.order;
  }

  if (setBase && base && Object.keys(base).length > 0) state.team[`${collectionType}CustomSortBase`] = { ...base };
};

const getUserUpdate = (state, result) => {
  Object.keys(result).forEach((key) => {
    state[key] = result[key];
  });
  state.loading = false;
  state.error = null;
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    authorizedUsers: [],
    isPushNotificationsAvailable: null,
    isPushNotificationsGranted: null,
    achievements: {
      liveChatIconSeen: false,
    },
    picsioStorage: false,
    apiKeys: {
      isLoading: false,
      keys: [],
      removingKeys: [],
      addingCount: 0,
      error: null,
    },
  },
  reducers: {
    // Get current user
    getUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    getUserSuccess(state, action) {
      const { user } = action.payload;

      getUserUpdate(state, user);
    },
    getUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setAuthorizedUsers: (state, action) => {
      state.authorizedUsers = action.payload;
    },

    updateTeamValue(state, action) {
      const { key, value } = action.payload;
      state.team[key] = value;
    },

    updateOktaSSOValue(state, action) {
      const { key, value } = action.payload;
      state.team.settings.okta[key] = value;
    },

    updateUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateUserSuccess(state, action) {
      const { result } = action.payload;

      getUserUpdate(state, result);
    },
    updateUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    savePolicyStart(state) {
      state.loading = true;
      state.error = null;
    },
    savePolicySuccess(state, action) {
      const {
        result: { key, value },
      } = action.payload;

      updateUserPolicy(state, key, value);

      state.loading = false;
      state.error = null;
    },
    savePolicyFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    toggleSupportConsent(state, { payload }) {
      state.settings.supportConsentExpiresAt = payload;
    },
    updatePolicySSO(state, { payload }) {
      state.team.policies.sso = payload;
    },
    updateUserSortTypeStart: userSortTypeUpdate,
    updateUserSortTypeSuccess: userSortTypeUpdate,
    updateUserSortTypeFailure: userSortTypeUpdate,
  },
  extraReducers: {
    [TYPES.COLLECTIONS.SET_WEBSITE](state, { payload }) {
      if (payload.value) {
        state.subscriptionFeatures.websitesCount += 1;
        const count = state.subscriptionFeatures.websitesCount;
        const limit = state.subscriptionFeatures.websitesLimit;
        if (count > limit) {
          state.subscriptionFeatures.websitesLimit += 1;
          state.addonsFeatures.websitesLimit += 1;
        }
      } else {
        state.subscriptionFeatures.websitesCount -= 1;
      }
    },
    [TYPES.CUSTOM_FIELDS.REMOVE.COMPLETE](state, { payload }) {
      const { title } = payload;

      /** remove required custom field from the user role if needed */
      if (state.role?.requiredFields?.meta?.[title]) {
        _unset(state, `role.requiredFields.meta.${title}`);
      }
    },
    [subscribe.fulfilled](state, { payload }) {
      state.customer.subscription = payload.subscription;
      if (payload.subscriptionFeatures) {
        state.subscriptionFeatures = payload.subscriptionFeatures;
      }
    },
    [changeCard.fulfilled](state, { payload }) {
      state.customer = payload;
    },
    [redeemCoupon.fulfilled](state, { payload }) {
      state.customer = {
        ...state.customer,
        ...payload,
      };
    },
    [updateUserCustomerTax.fulfilled](state, { payload }) {
      const { customer } = state;

      state.customer = {
        ...customer,
        tax: payload,
      };
    },
    [updateUserSearchSettings.pending](state, { meta }) {
      if (state?.settings?.search?.searchIn) {
        state.settings.search.searchIn = meta.arg.settings.search.searchIn;
      } else {
        state.settings = meta.arg.settings;
      }
    },
    [updateTeamAICount.fulfilled](state, { payload }) {
      if (payload.aiCreditsUsed) {
        state.team.aiCreditsUsed += payload.aiCreditsUsed;
      }
      if (payload.aiCounters?.creditsUsed) {
        state.team.aiCounters.creditsUsed += payload.aiCounters.creditsUsed;
      }
      if (payload.aiCounters?.transcribingMinutesUsed) {
        state.team.aiCounters.transcribingMinutesUsed += payload.aiCounters.transcribingMinutesUsed;
      }
    },
    [buyAICredits.fulfilled](state, { payload }) {
      state.team.aiCreditsPaid = payload.aiCreditsPaid;
    },
    [addLiveChatIconSeen.fulfilled](state) {
      state.achievements.liveChatIconSeen = true;
    },
    [addLiveChatIconSeen.rejected](state) {
      state.achievements.liveChatIconSeen = true;
    },
    /** Api keys */
    [fetchApiKeys.pending](state) {
      state.apiKeys.isLoading = true;
      state.apiKeys.error = null;
    },
    [fetchApiKeys.fulfilled](state, { payload }) {
      state.apiKeys.isLoading = false;
      state.apiKeys.keys = payload;
      state.apiKeys.error = null;
    },
    [fetchApiKeys.rejected](state) {
      state.apiKeys.isLoading = false;
      state.apiKeys.error = 'Can not load api keys';
    },

    [createApiKey.pending](state) {
      state.apiKeys.addingCount += 1;
    },
    [createApiKey.fulfilled](state, { payload }) {
      state.apiKeys.addingCount -= 1;
      if (payload) state.apiKeys.keys.push(payload);
    },
    [createApiKey.rejected](state) {
      state.apiKeys.addingCount -= 1;
    },

    [removeApiKey.pending](state, { meta }) {
      state.apiKeys.removingKeys.push(meta.arg);
    },
    [removeApiKey.fulfilled](state, { payload }) {
      state.apiKeys.removingKeys = state.apiKeys.removingKeys.filter((id) => id !== payload.keyId);
      if (payload.success) {
        state.apiKeys.keys = state.apiKeys.keys.filter((key) => key._id !== payload.keyId);
      }
    },
    [removeApiKey.rejected](state, { meta }) {
      state.apiKeys.removingKeys = state.apiKeys.removingKeys.filter((id) => id !== meta.arg);
    },

    [addSubtitlesGenerated.fulfilled](state) {
      state.achievements.subtitlesGenerated = true;
    },
  },
});
/* eslint-enable no-param-reassign */

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;

export const {
  setAuthorizedUsers,
  updateTeamValue,
  getUserStart,
  getUserSuccess,
  getUserFailure,
  updateOktaSSOValue,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  savePolicyStart,
  savePolicySuccess,
  savePolicyFailure,
  toggleSupportConsent,
  updateUserSortTypeStart,
  updateUserSortTypeSuccess,
  updateUserSortTypeFailure,
  updatePolicySSO,
} = actions;

export default reducer;
