/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

import { setField } from '../helpers/keywords';

export const deleted = createAction('keywords/deleted');

export const reducer = (builder) => builder.addCase(
  deleted.type,
  (state, { payload: _id }) => {
    state.tree = setField(state.tree, _id, 'deletedByTeammate', true);
    state.all.forEach((keyword) => {
      if (keyword._id === _id) keyword.deletedByTeammate = true;
    });
  },
);
