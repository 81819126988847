/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { addToSelection as addToSelectionHelper } from '../helpers/keywords';

export const addToSelection = createAction('keywords/addToSelection');

export const reducer = (builder) => {
  builder.addCase(
    addToSelection.type,
    (state, { payload: _id }) => {
      state.selectedKeywords = addToSelectionHelper(state.all, state.selectedKeywords, _id);
    },
  );
};
