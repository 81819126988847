import { getRootCollectionId } from '../helpers/archive';
import { setSearchRoute } from '../../../helpers/history';
import setActiveCollectionId from './setActiveCollectionId';

const navigateToRoot = (getState, dispatch, archived = false) => {
  const rootCollectionId = getRootCollectionId(getState(), dispatch);
  const query = { collectionIds: rootCollectionId };
  if (archived) {
    query.archived = true;
  }

  setSearchRoute(query, { replace: true });

  if (archived) {
    dispatch(setActiveCollectionId(rootCollectionId));
  }
  return rootCollectionId;
};

export default navigateToRoot;
