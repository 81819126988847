import TYPES from '../../action-types';

/**
 * Add keyword to assets immediately
 * @param {string[]} ids
 * @param {Object} keyword
 */
const attachedKeyword = (ids, keyword, userId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.ATTACH_KEYWORD.COMPLETE,
    payload: {
      ids,
      keyword,
      notify: true,
      userId,
    },
  });
};

export default attachedKeyword;
