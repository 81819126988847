import localization from '../../../shared/strings';
import TYPES from '../../action-types';
import Toast from '../../../components/Toast';
import {
  reloadCurrentPage,
  reloadRoute,
} from '../../../helpers/history';
import sdk from '../../../sdk';

/**
 * Set sort type to collection
 * @param {string[]} collectionIds
 * @param {string} sortType
 */
const setSortType = (collectionIds, sortType, onlyLocal) => async (dispatch) => {
  if (onlyLocal) {
    dispatch({
      type: TYPES.COLLECTIONS.SET_SORT_TYPE.COMPLETE,
      payload: { collectionIds, sortType },
    });
  } else {
    try {
      dispatch({
        type: TYPES.COLLECTIONS.SET_SORT_TYPE.START,
      });
      await sdk.collections.setSort(sortType, collectionIds);
      dispatch({
        type: TYPES.COLLECTIONS.SET_SORT_TYPE.COMPLETE,
        payload: { collectionIds, sortType },
      });
      reloadRoute();
    } catch (err) {
      dispatch({
        type: TYPES.COLLECTIONS.SET_SORT_TYPE.FAILED,
      });
      Toast(localization.TAGSTREE.textCantChangeOrder, {
        autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
      });
      reloadRoute();
    }
  }
};

export default setSortType;
