/**
 * Make notification read
 * @param {Object[]} items
 * @param {Object[]} newComments
 * @param {Object[]} newRevisions
 * @param {string[]} ids
 * @returns {Object} items
 */
const setNotificationRead = (items, newComments, newRevisions, ids) => {
  const updatedItems = [];
  const updatedNewComments = { ...newComments };
  const updatedNewRevisions = { ...newRevisions };
  items.forEach((item) => {
    if (ids.includes(item._id)) {
      updatedItems.push({ ...item, read: true });
      if (item.type === 'asset.comment.added') {
        const assetId = item.data?.asset?._id;
        if (updatedNewComments[assetId]) {
          updatedNewComments[assetId] = updatedNewComments[assetId].filter((i) => i !== item._id);
        }
      }
      if (item.type === 'asset.revision.created') {
        const assetId = item.data?.asset?._id;
        if (updatedNewRevisions[assetId]) {
          updatedNewRevisions[assetId] = updatedNewRevisions[assetId].filter((i) => i !== item._id);
        }
      }
    } else {
      updatedItems.push(item);
    }
  });
  return { updatedItems, updatedNewComments, updatedNewRevisions };
};

export default setNotificationRead;
