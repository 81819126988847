import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';
import * as helpers from '../../helpers/collections';
import changePathAssetsAction from '../assets/changePath';
import setMovingAssetsAction from '../assets/setMoving';
import Toast from '../../../components/Toast';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';
import getChildren from './getChildren';

/**
 * Move collection
 * @param {string} collectionId
 * @param {string} targetCollectionId
 */
function moveCollection(
  collectionIdToMove,
  targetCollectionId,
  newPath,
  oldPath,
  collectionName,
) {
  return async (dispatch, getAll) => {
    const all = getAll();
    const store = all.collections;
    const assets = all.assets.items;
    const { collectionIds } = all.router.location.query;
    const assetIds = collectionIds === collectionIdToMove ? assets.map((a) => a._id) : [];
    const storeSearchCollections = store.search.collections;
    const storeCollections = store.collections;
    const targetCollection = helpers.findCollection(storeCollections, 'my', {
      _id: targetCollectionId,
    });
    const needsFetchChildren = !targetCollection.nodes && targetCollection.hasChild;

    async function doMove(force) {
      dispatch({
        type: TYPES.COLLECTIONS.MOVE.START,
        payload: {
          targetCollectionId,
        },
      });

      UiBlocker.block(localization.TAGSTREE.textMovingCollection);
      try {
        Logger.addBreadcrumb({ collectionIdToMove, targetCollectionId });
        const { data } = await sdk.collections.move(collectionIdToMove, targetCollectionId, force);

        if (data) {
          dispatch({
            type: TYPES.COLLECTIONS.MOVE.COMPLETE,
            payload: {
              targetCollectionId,
              collectionIdToMove,
              needsFetchChildren,
            },
          });

          if (storeSearchCollections) getChildren(collectionIdToMove)(dispatch);

          changePathAssetsAction(oldPath, newPath, collectionName)(dispatch);
          if (data.queued) {
            setMovingAssetsAction(assetIds, 'waiting')(dispatch);
            Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
          } else {
            Toast(localization.TAGSTREE.textMovedCollection, { type: 'success', closeButton: false });
          }
        }
      } catch (error) {
        const errorMessage = utils.getDataFromResponceError(error, 'msg');
        const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
        const errorStatus = utils.getStatusFromResponceError(error);
        let dialogTitle = 'Error';
        let dialogText = (errorMessage && localization.TAGSTREE.textUnableToMoveCollection(errorMessage))
          || localization.UNABLE_TO_MOVE_COLLECTION;
        let dialogBtnOk = localization.DIALOGS.btnOk;
        let dialogBtnCancel = null;
        let dialogOnOk = null;
        /** if collection is busy */
        if (errorStatus === 403) {
          dialogText = localization.NO_PERMISSION_TO_ACCESS;
        } else if (errorSubcode === 'MovingCollectionWithRestrictedAssetsError') {
          dialogTitle = localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TITLE;
          dialogText = localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TEXT;
          dialogBtnOk = localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_OK;
          dialogBtnCancel = localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_CANCEL;
          dialogOnOk = () => doMove(true);
        }
        Logger.error(new Error('Can not move collection'), { error }, [
          'MoveCollectionFailed',
          errorMessage || 'NoMessage',
        ]);
        if (errorSubcode === 'CollectionAlreadyExistsApiError') {
          Toast(localization.TOAST.WARNING_COLLECTION_ALREADY_EXISTS.TEXT, { type: 'error' });
        } else if (errorSubcode === 'HasCopyingAssetsError' || errorSubcode === 'HasMovingAssetsError') {
          Toast(localization.TOAST.WARNING_BUSY_COLLECTION.TEXT, { type: 'error' });
        } else if (errorSubcode === 'TargetCollectionInsideApiError') {
          Toast(localization.TargetCollectionInsideApiError, { type: 'error' });
        } else if (errorSubcode === 'MoveCollectionApiError') {
          Toast(localization.TOAST.CANNOT_MOVE_COLLECTION, { type: 'error' });
        } else {
          showDialog({
            title: dialogTitle,
            text: dialogText,
            textBtnOk: dialogBtnOk,
            textBtnCancel: dialogBtnCancel,
            onOk: dialogOnOk,
          });
        }
        dispatch({
          type: TYPES.COLLECTIONS.MOVE.FAILED,
          payload: {
            targetCollectionId,
          },
        });
      }
      UiBlocker.unblock();
    }

    doMove();
  };
}

export default moveCollection;
