import TYPES from '../../action-types';

/**
 * @param {string[]} ids - assets ids
 * @param {string} type - param type to highlight (e.g. "color")
 */
const removeHighlight = (ids, type) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.REMOVE_HIGHLIGHT,
    payload: { ids, type },
  });
};

export default removeHighlight;
