/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import * as mainActions from '../actions/main';
import { getSearchProps, setSearchRoute, navigate } from '../../helpers/history';
import { showDialog } from '../../components/dialog';
import { isHaveTeammatePermission } from '../helpers/user';
import * as utils from '../../shared/utils';
import localization from '../../shared/strings';

const handleGoToBilling = () => {
  navigate('/billing?tab=overview');
  ReactDOM.unmountComponentAtNode(document.querySelector('.wrapperDialog'));
};

export const sendFacesRecognize = createAsyncThunk(
  'faces/recognize',
  async (
    assetsIds,
    {
      extra: {
        sdk, Logger, Toast, utils, localization,
      },
      getState,
      dispatch,
    }) => {
    const { user, roles, teammates } = getState();
    const billingDenied = !isHaveTeammatePermission('manageBilling', user);
    const keywordingDenied = !isHaveTeammatePermission('manageKeywords', user);
    const teamManagingDenied = !isHaveTeammatePermission('manageTeam', user);

    const canNotBuyApiCalls = billingDenied || keywordingDenied || teamManagingDenied;
    const usersWithPermissionsToBuyApiCalls = utils.getUsersWithPermissionToBuyKeywords(user, teammates.items, roles.items);

    let data;
    try {
      ({ data } = await sdk.assets.sendFacesRecognize(assetsIds));
      if (!data.hasUnsupportedAssets) {
        Toast(localization.TOAST.SEND_TO_RECOGNIZE(assetsIds.length));
      } else {
        Toast(localization.DETAILS.textAssetsNotAllSupported, { autoClose: false });
      }
      Logger.log('Face', 'FaceRecogRecognizeLaunched');
    } catch (error) {
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      if (errorSubcode === 'NotEnoughPaidApiCallsError') {
        showDialog({
          title: localization.DETAILS.titleNotEnoughCredits,
          text: localization.DETAILS.textReachedAiCreditLimit(canNotBuyApiCalls, usersWithPermissionsToBuyApiCalls),
          textBtnCancel: null,
          textBtnOk: canNotBuyApiCalls ? localization.DIALOGS.btnOk : localization.DIALOGS.btnSubscribe,
          onOk: canNotBuyApiCalls ? Function.prototype : handleGoToBilling,
        });
      } else if (errorSubcode === 'AllAssetsNotSupportedForFaceRecognitionError') {
        showDialog({
          title: localization.DIALOGS.INCORRECT_EXTENSION_ERROR,
          text: localization.DIALOGS.GENERATING_FACES.text,
          textBtnOk: localization.DIALOGS.GENERATING_FACES.TEXT_OK,
          textBtnCancel: null,
        });
      }
      throw error;
    }
    try {
      if (assetsIds.length === 1) {
        const mainAction = bindActionCreators(mainActions, dispatch);
        const treeOpened = utils.LocalStorage.get('picsio.treeOpened');
        const { collections } = getState();
        const { _id: rootCollectionId } = collections.collections.my;
        const makeSearchRoute = () => {
          const { trashed, archived } = getSearchProps();
          const result = { trashed, archived };
          result.collectionIds = rootCollectionId;
          result.faceIds = data?.createdPersons?.map((face) => face._id);
          return result;
        };
        const countFaces = data?.createdPersons?.length;

        Toast(localization.TOAST.SEND_TO_RECOGNIZE_ONE(countFaces), {
          autoClose: false,
          closeButton: false,
          btnOkValue: localization.TOAST.SEND_TO_RECOGNIZE_ONE_BUTTON,
          onOk: () => {
            setSearchRoute(makeSearchRoute());
            if (treeOpened !== 'face') mainAction.changeTree('face');
          },
        });
        return { faces: data, assetId: assetsIds[0] };
      }
      return null;
    } catch (error) {
      Logger.error(new Error('Error faces recognize'), { error }, [
        'FacesRecognizeFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    sendFacesRecognize.pending,
    () => {
    },
  ).addCase(
    sendFacesRecognize.fulfilled,
    (state, { payload }) => {
      if (payload) {
        const allFaces = state.all;
        const newFaces = [];
        payload.faces.createdPersons?.forEach((item) => {
          const isFacedExist = allFaces.find((storeFaced) => storeFaced._id === item._id);
          if (!isFacedExist) {
            newFaces.push({ ...item, avatar: null });
          }
        });
        state.all = [...state.all, ...newFaces];
        state.tree = {
          ...state.tree,
          unnamed: {
            ...state.tree.unnamed,
            nodes: [
              ...state.tree.unnamed.nodes, ...newFaces,
            ],
          },
        };
      }
    },
  ).addCase(
    sendFacesRecognize.rejected,
    () => {}
    ,
  );
};
