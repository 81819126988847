import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import WithSkeletonTheme from '../../WithSkeletonTheme';
import s from './SkeletonList.module.scss';
import { ThemeProvider } from '../../../contexts/themeContext';

export default function SkeletonList({ count }) {
  const user = useSelector((state) => state.user);
  return (
    <ThemeProvider user={user}>
      <WithSkeletonTheme>
        {Array.from({ length: count }).map((_, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} className={s.SkeletonList}>
            <div className={s.item}>
              <Skeleton height={24} />
            </div>
          </div>
        ))}
      </WithSkeletonTheme>
    </ThemeProvider>
  );
}

SkeletonList.defaultProps = {
  count: 3,
};

SkeletonList.propTypes = {
  count: PropTypes.number,
};
