import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Fetch one asset from the server
 * @param {string} id
 * @returns Promise<AxiosResponse>
 */
export default function fetchOneAsset(id) {
  if (picsioConfig.isMainApp) return sdk.assets.getOne(id);
  return sdk.assets.getOnePublic(picsioConfig.apiBaseUrl, window.websiteConfig.alias, id);
}
