/* eslint-disable no-param-reassign */
import { ROOT_COLLECTION_PATH } from '@picsio/db/src/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _remove from 'lodash/remove';

import showAssetsLimitExceededDialogWithoutStore from '../../helpers/showAssetsLimitExceededDialogWithoutStore';
import { normalizeItems, normalizeDroppedFiles } from './helpers/normalizeItems';
import findLocalDuplicates from './helpers/findLocalDuplicates';
import ResolveDuplicatesDialog from '../../components/resolveDuplicatesDialog';
import { findCollection } from '../helpers/collections';
import { openImport, setMobileAdditionalScreenPanel } from '../actions/main';
import checkPermissionToUpload from './helpers/checkPermissionToUpload';
import calculateTotalSize from './helpers/calculateTotalSize';

export const addFiles = createAsyncThunk(
  'import/addFiles',
  async (payload, {
    extra: {
      sdk, Logger, showDialog, showDialogAsync, showErrorDialog, localization: l10n, utils,
    }, getState, dispatch,
  }) => {
    const {
      items, uploadCollectionId, isDropped = false, isFromCloud = false,
    } = payload;
    const {
      user, collections, lightboards, import: importStore, router,
    } = getState();
    const isUserOnS3 = user?.team?.storageType === 's3';
    const { subscriptionFeatures = {} } = user;
    const { assetsLimit, assetsCount } = subscriptionFeatures;

    if (assetsCount > assetsLimit) {
      showAssetsLimitExceededDialogWithoutStore(getState().user);
      return null;
    }

    /** Items normalization */
    const lightboardId = uploadCollectionId || router.location.query.lightboardId;
    const collectionIds = uploadCollectionId || router.location.query.collectionIds;
    let collectionId = Array.isArray(collectionIds) && collectionIds.length ? collectionIds[0] : collectionIds;
    if (!collectionId && !lightboardId) {
      /** If no collection id and no lightboard id -> upload to Root collection */
      collectionId = collections.collections?.my?._id;
    }

    const collection = collectionId && findCollection(collections.collections, 'my', { _id: collectionId });
    if (collection) {
      /** Check for upload permissions */
      const collectionPath = collection.path === 'root'
        ? ROOT_COLLECTION_PATH
        : `${ROOT_COLLECTION_PATH}${collection.path}${collection.name}`;
      const canUpload = checkPermissionToUpload(user.role, collectionPath);
      if (!canUpload) {
        showDialog({
          title: l10n.IMPORT.textUpload,
          text: l10n.IMPORT.textYouCannotUpload(collection.name),
        });
        return null;
      }
    }
    const lightboard = lightboardId && lightboards.lightboards.find(({ _id }) => _id === lightboardId);
    const rootCollectionName = collections.collections.my.name;

    let normalizedFiles;
    let corruptedFiles;
    let blackListedFiles;
    let hiddenFiles;
    let duplicateCollections;
    if (isDropped) {
      ({
        normalizedFiles, corruptedFiles, blackListedFiles, hiddenFiles, duplicateCollections,
      } = normalizeDroppedFiles(items, rootCollectionName, collection, lightboard, user.settings?.extensionsBlackList || user.team?.settings?.extensionsBlackList));
    } else {
      ({
        normalizedFiles, corruptedFiles, blackListedFiles, hiddenFiles, duplicateCollections,
      } = normalizeItems(items, null, rootCollectionName, collection, lightboard, user.settings?.extensionsBlackList || user.team?.settings?.extensionsBlackList));
    }

    /** Corrupted */
    if (corruptedFiles.length) {
      const { title, text } = l10n.IMPORT.corruptedFiles;
      showErrorDialog(text(corruptedFiles.map(({ name }) => name)), title(corruptedFiles.length));
    }
    /** Blacklisted */
    if (blackListedFiles.length > 0) {
      const { title, text } = l10n.IMPORT.blackListedFiles;
      showErrorDialog(text(blackListedFiles.map((f) => f.name)), title(blackListedFiles.length));
    }
    /** Hidden */
    if (hiddenFiles.length > 0) {
      const { title, text } = l10n.IMPORT.hiddenFiles;
      showErrorDialog(text(hiddenFiles.map((f) => f.name)), title(hiddenFiles.length));
    }

    if (duplicateCollections.length) {
      const { title, text } = l10n.IMPORT.duplicateCollections;
      showErrorDialog(text(duplicateCollections.map((f) => f.name)), title(duplicateCollections.length));
    }

    if (!normalizedFiles.length) return null;
    let filesToAdd = normalizedFiles;

    /** Activate panel */
    dispatch(openImport());
    if (window.innerWidth < 1024) dispatch(setMobileAdditionalScreenPanel('Upload'));

    /** Find local duplicates */
    const localDuplicates = findLocalDuplicates(importStore.items, filesToAdd);
    if (localDuplicates.length) {
      /** show warning */
      showDialog({
        title: l10n.DIALOGS.IMPORT_WARNING_ADDED_DUPLICATES.TITLE,
        text: l10n.DIALOGS.IMPORT_WARNING_ADDED_DUPLICATES.TEXT(localDuplicates),
        textBtnCancel: null,
        textBtnOk: l10n.DIALOGS.IMPORT_WARNING_ADDED_DUPLICATES.BTN_OK,
      });
      /** Remove local duplicates */
      _remove(
        filesToAdd,
        (item) => !!localDuplicates.find(
          (duplicate) => duplicate.name === item.path + item.name && duplicate.size === item.file.size,
        ),
      );
      /** If all is duplicates - exit */
      if (filesToAdd.length < 1) return null;
    }

    /** Find duplicates on server */
    let duplicates;
    try {
      /** Example dataToCheck
        * [{
        *  collectionPath: '/path/to collection/here',
        *  names: ['name1', 'name2'],
        * }, {
        *  lightboardPath: '→My First Lightboard',
        *  names: ['name1', 'name2'],
        * }]
        */
      const dataToCheck = filesToAdd
        .map(({ path, lightboard, name }) => {
          if (lightboard) {
            return { lightboardPath: lightboard.path, name };
          }
          const collectionPath = `/${path.split('/').slice(1, -1).join('/')}`;
          return { collectionPath, name };
        })
        .reduce((result, { lightboardPath, collectionPath, name }) => {
          let handled = false;
          if (collectionPath) {
            const index = result.findIndex((item) => item.collectionPath === collectionPath);
            if (index > -1) {
              result[index].names.push(name);
              handled = true;
            }
          }
          if (lightboardPath) {
            const index = result.findIndex((item) => item.lightboardPath === lightboardPath);
            if (index > -1) {
              result[index].names.push(name);
              handled = true;
            }
          }
          /** if not found in the result */
          if (!handled) {
            const data = { names: [name] };
            if (collectionPath) data.collectionPath = collectionPath;
            if (lightboardPath) data.lightboardPath = lightboardPath;
            result.push(data);
          }
          return result;
        }, []);

      const { data } = await sdk.assets.detectDuplicates(dataToCheck);
      duplicates = data;
    } catch (error) {
      /** if "Too many items to analyze" ( more than 10 000 ) */
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 413) {
        let { text } = l10n.IMPORT.toManyAssetsForAnalysis;
        let textBtnCancel = l10n.IMPORT.toManyAssetsForAnalysis.btnCancel;
        if (isUserOnS3) {
          text = l10n.IMPORT.toManyAssetsForAnalysis.textS3;
          textBtnCancel = l10n.IMPORT.toManyAssetsForAnalysis.btnCancelS3;
        }
        try {
          const { promise } = showDialogAsync({
            title: l10n.IMPORT.toManyAssetsForAnalysis.title,
            text,
            textBtnCancel,
            textBtnOk: isUserOnS3 ? null : l10n.IMPORT.toManyAssetsForAnalysis.btnOk,
            onOk: () => { },
            style: { maxWidth: 700 },
          });
          await promise;
        } catch (e) {
          /** User press "Cancel" -> just clear items */
          return null;
        }
      } else {
        Logger.error(
          new Error('Can not find duplicates on the server'),
          { error, showDialog: true },
          ['FindDuplicatesFailed', (error && error.message) || 'NoMessage'],
        );
      }
    }

    if (duplicates && duplicates.length) {
      /** Resolve duplicates */
      const dialog = new ResolveDuplicatesDialog(user);
      try {
        if (isFromCloud) {
          filesToAdd = await dialog.resolve(duplicates, filesToAdd, false, false);
        } else {
          filesToAdd = await dialog.resolve(duplicates, filesToAdd);
        }
      } catch (error) {
        /** User press "Cancel" */
        return null;
      }
    }

    _remove(filesToAdd, (file) => file.action === 'skipFile');
    return filesToAdd;
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      addFiles.pending,
      (state, { meta }) => {
        state.tmpItemsNumber += meta.arg.items.length;
      },
    )
    .addCase(
      addFiles.fulfilled,
      (state, { payload, meta }) => {
        state.tmpItemsNumber -= meta.arg.items.length;
        if (!payload) return;

        payload.forEach((item) => {
          const itemToAdd = {
            ...item,
            fields: {
              comment: null,
              title: null,
              description: null,
              keywords: [],
              assignees: [],
              flag: null,
              rating: null,
              color: null,
              customFields: (state.requiredFields.customFields || []).reduce((r, key, _, cfs) => {
                if (!cfs[key]) return r; // if required = false (old accounts, etc.)
                return { ...r, [key]: null };
              }, {}),
            },
          };
          if (state.items[item.path]) state.items[item.path].push(itemToAdd);
          else state.items[item.path] = [itemToAdd];
        });

        state.total = calculateTotalSize(state.items);
      },
    )
    .addCase(
      addFiles.rejected,
      (state, { error, meta }) => {
        state.tmpItemsNumber -= meta.arg.items.length;
      },
    );
};
