import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

/**
 * Change collection description
 * @param {string} collectionId
 * @param {string} description
 */
function changeCollectionDescription(collectionId, description) {
  return async (dispatch) => {
    dispatch({
      type: TYPES.COLLECTIONS.CHANGE_DESCRIPTION.START,
      payload: {
        collectionId,
      },
    });

    try {
      const { data: result } = await sdk.collections.setDescription(collectionId, description);

      if (result.success) {
        dispatch({
          type: TYPES.COLLECTIONS.CHANGE_DESCRIPTION.COMPLETE,
          payload: {
            collectionId,
            description,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.COLLECTIONS.CHANGE_DESCRIPTION.FAILED,
        payload: {
          collectionId,
        },
      });
      Logger.error(new Error('Can not change collection description'), { error }, [
        'ChangeCollectionDescriptionFailed',
        'NoMessage',
      ]);
      showDialog({
        title: 'Error',
        text: localization.UNABLE_TO_CHANGE_COLLECTION_DESCRIPTION,
        textBtnCancel: null,
      });
    }
  };
}

export default changeCollectionDescription;
