import CONSTANTS from '@picsio/db/src/constants';

import TYPES from '../../action-types';

/**
 * Set "trashing" field to "waiting"
 * @param {String[]} ids
 */
const setTrashing = (ids, fieldName = CONSTANTS.TRASHING_STATUS_FIELD_NAME) => (
  dispatch,
) => {
  dispatch({
    type: TYPES.ASSETS.DELETE_ASSETS.START,
    payload: { ids, fieldName },
  });
};

export default setTrashing;
