import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorDialog } from '../../components/dialog';
import updateUser from '../actions/user/updateUser';
import Toast from '../../components/Toast';
import l18n from '../../shared/strings';

const addAddon = createAsyncThunk(
  'billing/addAddon',
  async ({ featureName, featureTitle }, {
    getState, dispatch, rejectWithValue,
    extra: {
      sdk, Logger, UiBlocker, utils,
    },
  }) => {
    try {
      UiBlocker.block(l18n.BILLING.textPaymentFinishing);
      const { data: result } = await sdk.billing.addAddon(featureName);

      const { subscriptionFeatures = {}, addonsFeatures = {} } = getState().user;
      const copyAddonsFeatures = { ...addonsFeatures };
      const copySubscriptionFeatures = { ...subscriptionFeatures };
      copyAddonsFeatures[featureName] = true;
      copySubscriptionFeatures[featureName] = true;

      if (`${featureName}Limit` in copySubscriptionFeatures) {
        copySubscriptionFeatures[`${featureName}Limit`] = 9999999;
      }

      dispatch(updateUser({
        addonsFeatures: {
          ...copyAddonsFeatures,
        },
        subscriptionFeatures: {
          ...copySubscriptionFeatures,
        },
      }));
      Toast(l18n.TOAST.UPGRADE_ADDON_SUCCESS(featureTitle));
      return result;
    } catch (err) {
      const message = utils.getDataFromResponceError(err, 'msg');
      Logger.error(new Error('Error adding addon to subscription'), { err }, [
        `AddAddon${featureName}Failed`,
        message || 'NoMessage',
      ]);
      showErrorDialog(message || l18n.SERVER_ERROR.text);
      return rejectWithValue(err);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default addAddon;
