import picsioConfig from '../../../../../config';
import sdk from '../../sdk';
/**
 * Fetch revisions thumbmails for asset
 * @param {string} assetId
 * @returns Promise<AxiosResponse>
 */
export default function fetchRevisionsThumbnails(assetId) {
  if (picsioConfig.isMainApp) return sdk.assets.fetchRevisionsThumbnails(assetId);

  return sdk.assets.fetchRevisionsThumbnailsPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetId,
  );
}
