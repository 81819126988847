import React from 'react';
import PropTypes from 'prop-types';

const GraphTitle = ({
  title, button = null,
}) => (
  <div className="graphTitle">
    <span>
      <span>{title}</span>
    </span>
    {button}
  </div>
);

GraphTitle.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.element,
};

export default GraphTitle;
