import UiBlocker from '../../../services/UiBlocker';
import sdk from '../../../sdk';
import l18n from '../../../shared/strings';
import Toast from '../../../components/Toast';
import * as userActions from '../../reducers/user';
import * as utils from '../../../shared/utils';
import { reloadCurrentPage } from '../../../helpers/history';

const setNewPassword = (data) => async (dispatch) => {
  try {
    UiBlocker.block();
    const res = await sdk.users.setNewPassword(data);
    if (res?.data?.success) {
      Toast(l18n.SET_NEW_PASSWORD.success, { type: 'success' });
      dispatch(userActions.updateUserSuccess());
      return;
    }
    Toast(l18n.SET_NEW_PASSWORD.fail, { type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
  } catch (error) {
    const errorMessage = utils.getDataFromResponceError(error, 'msg');
    const errorStatus = utils.getStatusFromResponceError(error);
    if (errorStatus === 400) {
      dispatch(userActions.updateUserFailure(errorMessage));
    }
    return { error: errorMessage };
  } finally {
    UiBlocker.unblock();
  }
};

export default setNewPassword;
