/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { setField } from '../helpers/keywords';

export const updateUsedAt = createAction('keywords/updateUsedAt');

export const reducer = (builder) => {
  builder.addCase(
    updateUsedAt.type,
    (state, { payload: { _id, value } }) => {
      state.tree = setField(state.tree, _id, 'usedAt', value);
      state.all.forEach((keyword) => {
        if (keyword._id === _id) {
          keyword.usedAt = value;
        }
      });
    },
  );
};
