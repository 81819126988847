/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import initialState from './initialState';
import TYPES from '../action-types';

import { reducer as getRoles } from './getRoles';
import { reducer as addRole } from './addRole';
import { reducer as removeRole } from './removeRole';
import { reducer as updateRole } from './updateRole';
import { reducer as addRequiredField } from './addRequiredField';
import { reducer as removeRequiredField } from './removeRequiredField';
import { reducer as updateRequiredField } from './updateRequiredField';
import { reducer as updateMetadataOrder } from './updateMetadataOrder';
import { reducer as updateHiddenFields } from './updateHiddenFields';

const rolesReducer = createReducer(
  initialState,
  (builder) => {
    getRoles(builder);
    addRole(builder);
    removeRole(builder);
    updateRole(builder);
    addRequiredField(builder);
    removeRequiredField(builder);
    updateRequiredField(builder);
    updateMetadataOrder(builder);
    updateHiddenFields(builder);

    /** Remove required custom field from roles if needed */
    builder.addMatcher(
      (action) => action.type === TYPES.CUSTOM_FIELDS.REMOVE.COMPLETE,
      (state, { payload: { title } }) => {
        state.items.forEach((role) => {
          if (role?.requiredFields?.meta?.[title]) {
            delete role?.requiredFields?.meta?.[title];
          }
        });
      },
    );
  },
);

export default rolesReducer;
