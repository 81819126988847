import TYPES from '../../action-types';

/**
 * Remove keyword from assets immediately
 * @param {string[]} ids
 * @param {string} keywordID
 */
export const detachedKeywords = (ids, keywordsIds, userId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.DETACH_KEYWORD.COMPLETE,
    payload: {
      ids,
      keywordsIds,
      notify: true,
      userId,
    },
  });
};

export default detachedKeywords;
