import Logger from '../../../services/Logger';
import TYPES from '../../action-types';
import Toast from '../../../components/Toast';
import sdk from '../../../sdk';
import l18n from '../../../shared/strings';

/**
 * Add/remove favotites
 * @param {string} collectionID
 * @param {string} collectionName
 * @param {string} collectionPath
 * @param {boolean} value
 * @param {boolean} showToast
 */
function addToFavorites(collectionID, collectionName, collectionPath, value, showToast = true) {
  return async (dispatch) => {
    const path = collectionPath + collectionName;
    function handleError(error) {
      dispatch({
        type: TYPES.COLLECTIONS.FAVORITE.FAILED,
        payload: { collectionID },
      });
      Logger.error(new Error('Can not add collection to favorites'), { error }, [
        'AddCollectionToFavoritesFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }

    dispatch({
      type: TYPES.COLLECTIONS.FAVORITE.START,
      payload: { collectionID },
    });

    try {
      await sdk.collections.favorite(path, value).then(({ data: res }) => {
        if (res.ok) {
          let actionLog;
          let actionText;
          if (value) {
            actionText = 'added to';
            actionLog = 'CollectionsPanelAddToFav';
          } else {
            actionText = 'removed from';
            actionLog = 'CollectionsPanelRemoveFromFav';
          }

          Logger.log('User', actionLog, { collectionID });
          if (showToast) {
            Toast(l18n.TOAST.ADD_TO_FAVORITES_SUCCESS(collectionName, actionText), {
              type: 'success',
              btnOkValue: l18n.textBtnUndo,
              onOk: () => dispatch(addToFavorites(collectionID, collectionName, collectionPath, !value, false)),
            });
          }
          dispatch({
            type: TYPES.COLLECTIONS.FAVORITE.COMPLETE,
            payload: { collectionID, value },
          });
        } else {
          handleError(`Can't favorite ${collectionName}: ${res}`);
        }
      });
    } catch (error) {
      handleError(error);
    }
  };
}

export default addToFavorites;
