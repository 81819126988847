/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const setAvatars = createAction('faces/setAvatars');

export const reducer = (builder) => {
  builder.addCase(
    setAvatars.type,
    (state, { payload }) => {
      const faceWithAvatars = payload.eventData;
      if (faceWithAvatars) {
        state.all = state.all.map((face) => {
          for (let i = 0; i < faceWithAvatars.length; i++) {
            if (faceWithAvatars[i]._id === face._id) {
              return { ...face, avatar: faceWithAvatars[i].avatar };
            }
          }
          return face;
        });
        state.tree = {
          ...state.tree,
          allFaces: {
            ...state.tree.allFaces,
            nodes: state.tree.allFaces.nodes.map((namedFace) => {
              for (let i = 0; i < faceWithAvatars.length; i++) {
                if (faceWithAvatars[i]._id === namedFace._id) {
                  return {
                    ...namedFace,
                    avatar: faceWithAvatars[i].avatar,
                    isAvatarUrlReady: true,
                  };
                }
              }
              return namedFace;
            }),
          },
          unnamed: {
            ...state.tree.unnamed,
            nodes: state.tree.unnamed.nodes.map((unnamedFace) => {
              for (let i = 0; i < faceWithAvatars.length; i++) {
                if (faceWithAvatars[i]._id === unnamedFace._id) {
                  return {
                    ...unnamedFace,
                    avatar: faceWithAvatars[i].avatar,
                    isAvatarUrlReady: true,
                  };
                }
              }
              return unnamedFace;
            }),
          },
        };
      }
    },
  );
};
