import React from 'react'; // eslint-disable-line
import { useDispatch } from 'react-redux';
import {
  func,
  number,
  oneOf,
  string,
  arrayOf,
  any,
  bool,
} from 'prop-types';
import { resizePanel } from '../../store/actions/main';
import TranscriptPlaceholder from './components/TranscriptPlaceholder';
import TranscriptList from './components/TranscriptList';
import './styles.scss';

const TranscriptPanel = ({
  assetId,
  assetName,
  panelWidth,
  videoCurrentTime,
  setVideoPlayerCurrentTime,
  transcribing = 'skipped',
  transcript = [],
  transcriptPermission = false,
  activeRevisionID = null,
  isLoaded = false,
  manageTranscriptPermission = false,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="transcriptionsPanel" style={{ width: panelWidth }}>
      <div
        role="presentation"
        className="resizer"
        onMouseDown={(event) => dispatch(resizePanel(event, 'previewView.right'))}
      />
      <Choose>
        <When condition={transcript.length > 0}>
          <TranscriptList
            videoCurrentTime={videoCurrentTime}
            setVideoPlayerCurrentTime={setVideoPlayerCurrentTime}
            transcript={transcript}
            assetId={assetId}
            activeRevisionID={activeRevisionID}
            assetName={assetName}
            manageTranscriptPermission={manageTranscriptPermission}
          />
        </When>
        <Otherwise>
          <TranscriptPlaceholder
            transcribing={transcribing}
            assetId={assetId}
            transcriptPermission={transcriptPermission}
            assetName={assetName}
            activeRevisionID={activeRevisionID}
            isLoaded={isLoaded}
          />
        </Otherwise>
      </Choose>
    </div>
  );
};

TranscriptPanel.propTypes = {
  assetId: string.isRequired,
  assetName: string.isRequired,
  panelWidth: number.isRequired,
  setVideoPlayerCurrentTime: func.isRequired,
  videoCurrentTime: number.isRequired,
  transcribing: oneOf(['skipped', 'waiting', 'running', 'complete', 'failed', 'rejected']),
  transcript: arrayOf(any),
  transcriptPermission: bool,
  activeRevisionID: string,
  isLoaded: bool,
  manageTranscriptPermission: bool,
};

export default TranscriptPanel;
