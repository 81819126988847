import React, { forwardRef } from 'react';
import { IconButton, Button, Icon } from '@picsio/ui';
import { Close, Infobox } from '@picsio/icons';
import { func, shape, string } from 'prop-types';
import localization from '../../shared/strings';
import './styles.scss';

const BestMatchSearchHint = forwardRef((props, ref) => {
  const {
    onClose, text, styles = {}, onClick,
  } = props;

  return (
    <div
      className="bestMatchSearchHint"
      ref={ref}
      style={styles}
    >
      <div className="baseStyle">
        <Icon size="sm">
          <Infobox />
        </Icon>
        <span className="text">{text}</span>
      </div>
      <div className="baseStyle">
        <Button
          onClick={onClick}
          variant="contained"
          color="secondary"
          id="applyBestMatch"
        >
          {localization.BEST_MATCH_SEARCH.buttonText}
        </Button>
        <IconButton id="closeApplyBestMatch" buttonSize="lg" size="xl" onClick={onClose}>
          <Close />
        </IconButton>
      </div>
    </div>
  );
});

BestMatchSearchHint.propTypes = {
  onClose: func.isRequired,
  text: string.isRequired,
  styles: shape({}),
  onClick: func.isRequired,
};

export default BestMatchSearchHint;
