import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '../store';
import { removeFromCollection } from '../store/actions/assets';
import RemoveAssetCollectionDialog from '../components/RemoveAssetCollectionDialog';

export default function showRemoveAssetFromCollectionDialog(props) {
  const { assetId } = props;
  const asset = store.getState().assets.items.find((a) => a._id === assetId);
  if (!asset) return;

  const { tags = [] } = asset;
  if (tags.length === 1) {
    /** If the asset is in only one collection */
    const { path, _id } = tags[0];
    store.dispatch(removeFromCollection({ path: path.replace('/root/', ''), _id }, [assetId]));
    return;
  }

  const $wrapper = document.createElement('div');
  const root = createRoot($wrapper);
  $wrapper.classList.add('wrapperDialog');
  document.body.appendChild($wrapper);

  function destroy() {
    root.unmount();
    document.body.removeChild($wrapper);
  }

  root.render(
    <Provider store={store}>
      <RemoveAssetCollectionDialog destroy={destroy} {...props} />
    </Provider>,
  );
}
