import { createAsyncThunk } from '@reduxjs/toolkit';
import setTranscriptViewedByInitiator from './setTranscriptViewedByInitiator';
import { LocalStorage } from '../../../../shared/utils';
import Toast from '../../../../components/Toast';
import { navigate } from '../../../../helpers/history';
import l18n from '../../../../shared/strings';

/**
 * Add transcript
 * @param {Object} event
 */
const transcribingEvent = createAsyncThunk('asset/transcribingEvent', async (event, { getState, dispatch }) => {
  const assets = getState().assets.items;
  const userId = getState().user._id;

  if (event.type === 'asset.transcribing.failed' && (!event.initiator || !event.initiator?._id)) {
    event.initiator = !event.initiator ? {} : event.initiator;
    event.initiator._id = !event.initiator._id ? userId : event.initiator._id;
  }

  if (event.type === 'asset.transcribing.rejected') {
    Toast(l18n.TRANSCRIPT.TOASTS.textTranscribingHonSupported);
  }

  if (userId !== event.initiator._id) return event;

  /** This condition is needed to show the user an indicator of an unviewed transcript  */
  if (event.type === 'asset.transcribing.chunk_is_ready') {
    const currentUserId = getState().user._id;
    const currentAsset = assets.find((asset) => asset._id === event.data.asset._id);

    if (currentAsset) {
      const prevTranscript = currentAsset.transcripts ? currentAsset.transcripts.find((transcript) => transcript.revisionId === event.data.asset.revisionId) : [];

      /** The unviewed transcript indicator should appear only when the first chunk is received from the transcriber */
      const isFirstTranscriptChunk = currentAsset.transcripts ? prevTranscript.transcript.length === 0 : true;

      /** The unviewed transcript indicator should be visible only to the transcribing initiator */
      const isTranscriptInitiator = event.initiator._id === currentUserId;

      if (isFirstTranscriptChunk && isTranscriptInitiator) {
        const isActiveTranscriptPanel = LocalStorage.get('picsio.transcriptionspanelOpened');
        let value = false;

        if (isActiveTranscriptPanel) {
          value = true;
        }
        dispatch(setTranscriptViewedByInitiator({ assetId: event.data.asset._id, value }));
      }
    }
  }

  if (event.type === 'asset.transcribing.complete') {
    let assetsByIdLUT = {};

    if (event.data.assets) {
      assetsByIdLUT = event.data.assets.reduce((res, asset) => ({ ...res, [asset._id]: asset }), {});
    }
    if (event.data.asset) {
      assetsByIdLUT[event.data.asset._id] = event.data.asset;
    }

    /** This assets.forEach is needed to override the values set by the transcriber in the background  */
    assets.forEach((item) => {
      const transcribingAsset = assetsByIdLUT[item._id];
      if (!transcribingAsset) return item;
      if (item.transcriptViewedByInitiator) {
        dispatch(setTranscriptViewedByInitiator({ assetId: item._id, value: true }));
      }
      return null;
    });

    const assetsCompleted = Object.values(assetsByIdLUT);
    assetsCompleted.forEach((asset) => {
      Toast(l18n.TRANSCRIPT.TOASTS.transcribingComplete(asset.name, () => {
        navigate(`/preview/${asset._id}#transcription`);
      }));
    });
  }
  return event;
});

export default transcribingEvent;
