export default function prepareAssetFields(fileFields) {
  return Object.keys(fileFields).reduce((result, key) => {
    const value = fileFields[key];
    if (value === null) return result;
    /** handle keywords */
    if (key === 'keywords') {
      return {
        ...result,
        keywordsIds: value.map(({ _id }) => _id),
      };
    }
    /** handle assignees */
    if (key === 'assignees') {
      return {
        ...result,
        assigneeIds: value.map(({ _id }) => _id),
      };
    }
    /** handle custom fields */
    if (key === 'customFields') {
      /** fill selected custom fields */
      if (Object.keys(value).length) {
        return {
          ...result,
          selectedCustomFields: Object.keys(value).map((cfTitle) => ({
            title: cfTitle,
            value: value[cfTitle]?.value,
          })),
        };
      }
      return {
        ...result,
        selectedCustomFields: [],
      };
    }
    return { ...result, [key]: value };
  }, {});
}
