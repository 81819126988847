import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@picsio/ui';
import { Edit } from '@picsio/icons';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { LocalStorage } from '../../shared/utils';
import * as utils from '../../shared/utils';
import { isHaveTeammatePermission } from '../../store/helpers/user';
import Logger from '../../services/Logger';
import showEditFaceDialog from '../../helpers/showEditFaceDialog';
import ua from '../../ua';
import Tooltip from '../Tooltip';
import { showDialog } from '../dialog';
import l18n from '../../shared/strings';

import './style.scss';

const BoundingBoxAws = ({
  face = null,
  assetId,
  assetActions,
  onClickFace,
  selectedFace,
  flipX = 1,
  flipY = 1,
  rotation = 0,
}) => {
  const user = useSelector((state) => state.user);
  const renameItem = useCallback(
    (faceId) => {
      showEditFaceDialog({ faceId, assetId });
    },
    [assetId],
  );

  const handleRename = (e) => {
    e.stopPropagation();
    e.preventDefault();
    renameItem(face._id);
  };

  const handleBoundingBoxClick = () => {
    Logger.log('Face', 'FaceRecogBBoxClicked');
    const infoPanelIsOpened = LocalStorage.get('picsio.infopanelOpened');
    if (onClickFace && !ua.browser.isNotDesktop() && infoPanelIsOpened) {
      document.getElementById(`${face.boundingBox.width}${face.boundingBox.height}${face.boundingBox.left}${face.boundingBox.top}`).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      onClickFace(face.boundingBox);
    }
  };

  const removeBoundingBox = (currentAssetId, faceId, boundingBox) => {
    showDialog({
      title: l18n.DIALOGS.DELETE_FACE_REGION.TITLE,
      text: l18n.DIALOGS.DELETE_FACE_REGION.TEXT,
      textBtnOk: l18n.DIALOGS.DELETE_FACE_REGION.BTN_OK,
      textBtnCancel: l18n.DIALOGS.DELETE_FACE_REGION.BTN_CANCEL,
      onOk: () => {
        Logger.log('Face', 'FaceRecogBBoxDelete');
        assetActions.removeBoundingBox({
          assetId: currentAssetId, faceId, boundingBox, revision: face.revisionId,
        });
      },
    });
  };

  function handleRotation(boundginBox) {
    let tempBoundingBox = {};

    if (rotation === 0) {
      tempBoundingBox = { ...boundginBox };
      if (flipX === -1 && flipY === 1) {
        tempBoundingBox.right = tempBoundingBox.left;
        delete tempBoundingBox.left;
        return tempBoundingBox;
      }
      if (flipY === -1 && flipX === 1) {
        tempBoundingBox.bottom = tempBoundingBox.top;
        delete tempBoundingBox.top;
        return tempBoundingBox;
      }
      if (flipX === -1 && flipY === -1) {
        tempBoundingBox.right = tempBoundingBox.left;
        tempBoundingBox.bottom = tempBoundingBox.top;
        delete tempBoundingBox.left;
        delete tempBoundingBox.top;
        return tempBoundingBox;
      }
    }
    if (rotation === 90) {
      tempBoundingBox.width = boundginBox.height;
      tempBoundingBox.height = boundginBox.width;
      tempBoundingBox.right = boundginBox.top;
      tempBoundingBox.top = boundginBox.left;
      delete tempBoundingBox.bottom;
      delete tempBoundingBox.left;
      if (flipX === -1 && flipY === 1) {
        tempBoundingBox.bottom = tempBoundingBox.top;
        delete tempBoundingBox.top;
        return tempBoundingBox;
      }
      if (flipY === -1 && flipX === 1) {
        tempBoundingBox.left = tempBoundingBox.right;
        delete tempBoundingBox.right;
        return tempBoundingBox;
      }
      if (flipX === -1 && flipY === -1) {
        tempBoundingBox.bottom = tempBoundingBox.top;
        tempBoundingBox.left = tempBoundingBox.right;
        delete tempBoundingBox.top;
        delete tempBoundingBox.right;
        return tempBoundingBox;
      }
    }
    if (rotation === 180) {
      tempBoundingBox.width = boundginBox.width;
      tempBoundingBox.height = boundginBox.height;
      tempBoundingBox.bottom = boundginBox.top;
      tempBoundingBox.right = boundginBox.left;
      delete tempBoundingBox.left;
      delete tempBoundingBox.top;
      if (flipX === -1 && flipY === 1) {
        tempBoundingBox.left = tempBoundingBox.right;
        delete tempBoundingBox.right;
        return tempBoundingBox;
      }
      if (flipY === -1 && flipX === 1) {
        tempBoundingBox.top = tempBoundingBox.bottom;
        delete tempBoundingBox.bottom;
        return tempBoundingBox;
      }
      if (flipX === -1 && flipY === -1) {
        tempBoundingBox.left = tempBoundingBox.right;
        tempBoundingBox.top = tempBoundingBox.bottom;
        delete tempBoundingBox.right;
        delete tempBoundingBox.bottom;
        return tempBoundingBox;
      }
    }
    if (rotation === 270) {
      tempBoundingBox.width = boundginBox.height;
      tempBoundingBox.height = boundginBox.width;
      tempBoundingBox.left = boundginBox.top;
      tempBoundingBox.bottom = boundginBox.left;
      delete tempBoundingBox.top;
      delete tempBoundingBox.right;
      if (flipX === -1 && flipY === 1) {
        tempBoundingBox.top = tempBoundingBox.bottom;
        delete tempBoundingBox.bottom;
        return tempBoundingBox;
      }
      if (flipY === -1 && flipX === 1) {
        tempBoundingBox.right = tempBoundingBox.left;
        delete tempBoundingBox.left;
        return tempBoundingBox;
      }
      if (flipX === -1 && flipY === -1) {
        tempBoundingBox.right = tempBoundingBox.left;
        tempBoundingBox.top = tempBoundingBox.bottom;
        delete tempBoundingBox.left;
        delete tempBoundingBox.bottom;
        return tempBoundingBox;
      }
    }

    return tempBoundingBox;
  }

  const newBoundginBox = handleRotation(face.boundingBox, flipX, flipY, rotation);
  const canEditFace = useMemo(() => face.unnamed && isHaveTeammatePermission('managePersons', user), [face]);

  return (
    <div>
      <div
        className={cn('bounding-box-container', { active: utils.compareBoundingBoxes(selectedFace.boundingBox, face.boundingBox) })}
        key={face._id}
        onClick={handleBoundingBoxClick}
        style={{
          left: newBoundginBox.left ? `${newBoundginBox.left * 100}%` : '',
          top: newBoundginBox.top ? `${newBoundginBox.top * 100}%` : '',
          bottom: newBoundginBox.bottom ? `${newBoundginBox.bottom * 100}%` : '',
          right: newBoundginBox.right ? `${newBoundginBox.right * 100}%` : '',
          width: `${newBoundginBox && newBoundginBox.width * 100}%`,
          height: `${newBoundginBox && newBoundginBox.height * 100}%`,
        }}
      >

        <div
          className="title"
          onClick={canEditFace ? handleRename : handleBoundingBoxClick}
        >
          <span>{face.unnamed ? 'Who is this?' : face.name}</span>
          <If condition={canEditFace}>
            <Icon size="sm" className="editIcon">
              <Edit />
            </Icon>
          </If>
        </div>
        <Tooltip content="Delete face region">
          <span
            className={cn('close', { active: utils.compareBoundingBoxes(selectedFace.boundingBox, face.boundingBox) })}
            onClick={() => removeBoundingBox(assetId, face._id, face.boundingBox)}
          >
            x
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

BoundingBoxAws.propTypes = {
  face: PropTypes.shape({}),
  assetId: PropTypes.string.isRequired,
  assetActions: PropTypes.shape({
    removeBoundingBox: PropTypes.func,
  }).isRequired,
  onClickFace: PropTypes.func.isRequired,
  selectedFace: PropTypes.shape({}).isRequired,
  flipX: PropTypes.number,
  flipY: PropTypes.number,
  rotation: PropTypes.number,
};

export default BoundingBoxAws;
