import React from 'react';
import { getRestrictedAssets } from '../../../helpers/assets';
import { showDialog } from '../../dialog';
import l18n from '../../../shared/strings';
import UiBlocker from '../../../services/UiBlocker';
import Logger from '../../../services/Logger';
import startCreating from './startCreatingWebsite';
import Tag from '../../Tag';
import * as utils from '../../../shared/utils';

const publishWebsite = ({
  user, teammates, roles, collectionId, setIsSiteProcessing, website, imageData = undefined,
}) => async (dispatch) => {
  const { subscriptionFeatures } = user;
  const { manageBilling, restrictedDownload } = user.role.permissions;
  const { data: restrictedAssets } = await getRestrictedAssets(collectionId);

  if (restrictedAssets.images && restrictedAssets.images.length) {
    if (restrictedDownload) {
      Logger.log('UI', 'WebSiteCollectionDialogWithRestrictedAssets', {
        restrictedPermission: true,
      });
      UiBlocker.unblock();
      showDialog({
        title: l18n.DIALOGS.RESTRICTED_ASSET_DETECTED.TITLE,
        text: l18n.DIALOGS.RESTRICTED_ASSET_DETECTED.TEXT,
        textBtnOk: l18n.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_OK,
        textBtnCancel: l18n.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_CANCEL,
        onOk: () => {
          startCreating({
            imageData, collectionId, website, subscriptionFeatures, manageBilling,
          })(dispatch);
        },
        onCancel: () => {
          UiBlocker.unblock();
          setIsSiteProcessing(false);
        },
      });
    } else {
      const allAssetsNames = restrictedAssets.images.map((asset) => asset.name);
      let assetNamesShortList = [];

      const usersWithRoleManageTeam = utils.getTeamManagers(user, teammates, roles);
      if (allAssetsNames.length > 3) assetNamesShortList = allAssetsNames.slice(0, 3);
      if (allAssetsNames.length > 0) {
        const namesText = assetNamesShortList.length
          ? `${assetNamesShortList.join(', ')} and some more`
          : allAssetsNames.join(', ');

        const dialogHtml = (
          <div className="myTeamDialog">
            Asset{allAssetsNames.length > 1 && 's'} {namesText}{' '}
            {allAssetsNames.length > 1 ? 'are' : 'is'} restricted and cannot be shared. Please
            contact your team
            {Boolean(usersWithRoleManageTeam.length) && (
              <>
                {' '}
                manager{usersWithRoleManageTeam.length > 1 && 's'}{' '}
                {usersWithRoleManageTeam.slice(0, 5).map((teamManager) => (
                  <Tag
                    type="user"
                    avatar={teamManager.avatar}
                    key={teamManager._id}
                    text={teamManager.email}
                  />
                ))}{' '}
              </>
            )}
            for further assistance.
          </div>
        );

        UiBlocker.unblock();
        showDialog({
          title: l18n.WEBSITES.titleAttention,
          children: dialogHtml,
        });
        Logger.log('UI', 'WebSiteCollectionDialogWithRestrictedAssets', {
          restrictedPermission: false,
        });
        setIsSiteProcessing(false);
      }
    }
  } else {
    startCreating({
      imageData, collectionId, website, subscriptionFeatures, manageBilling,
    })(dispatch);
  }
};

export default publishWebsite;
