import React from 'react';
import {
  bool, func, array, string, oneOfType,
} from 'prop-types';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { DatePickerRange, Input, InputLabel } from '@picsio/ui';
import dayjs from 'dayjs';
import {
  datePickerPlaceholderWithTime, datePickerDateFormat, datePickerTimeFormat, isValidDate,
} from '../../../shared/dateLocale';
import localization from '../../../shared/strings';
import Logger from '../../../services/Logger';
import { Checkbox } from '../../../UIComponents';
import ua from '../../../ua';

import '../styles/restrict.scss';

const maxReasonLength = 450;

class Restrict extends React.Component {
  /** propTypes */
  static propTypes = {
    isVisible: bool,
    inProgress: bool,
    disabled: bool,
    selectedAssets: array,
    isRestricted: bool,
    reason: string,
    toggleVisibility: func,
    onChange: func,
    restrictStartAtPlaceholder: oneOfType([string, bool]),
    restrictExpiresAtPlaceholder: oneOfType([string, bool]),
    teamRestrictReason: string,
  };

  state = {
    initialReason: this.props.reason || null,
    reason: this.props.reason || null,
    reasonError: '',
  };

  isMobile = ua.browser.isNotDesktop() || ua.isMobileApp();

  static getDerivedStateFromProps(props, state) {
    if (props.reason !== state.initialReason) {
      return {
        reason: props.reason,
        initialReason: props.reason,
      };
    }

    return null;
  }

  handleChangeRestrictStatus = (value) => {
    const { reason } = this.state;
    const { expiresAt, startAt, teamRestrictReason } = this.props;
    const data = {
      isRestricted: value,
    };

    if (value) {
      if (
        reason !== localization.DETAILS.placeholderMultipleSelection
        && reason !== teamRestrictReason
        && reason !== localization.RESTRICT.RESTRICTED_REASON
      ) {
        data.reason = this.state.reason;
      }
      if (startAt) data.startAt = startAt;
      if (expiresAt) data.expiresAt = expiresAt;
    }

    Logger.log('User', 'RestrictChangeStatus', { assetIds: this.props.selectedAssets, data });
    this.props.onChange(this.props.selectedAssets, data);
  };

  handleTitleClick = () => this.props.toggleVisibility('detailsAssetRestrictVisibility');

  handleChangeReason = (event) => {
    const { value } = event.target;

    if (value.trim().length > maxReasonLength) {
      this.setState({ reasonError: localization.CUSTOMFIELDSSCHEMA.textErrorLengthLimit(maxReasonLength) });
    } else {
      if (this.state.reasonError) {
        this.setState({ reasonError: '' });
      }
      this.setState({ reason: value });
    }
  };

  handleBlurReason = (event) => {
    const { value } = event.target;
    if (value === this.state.initialReason) return;
    if (value.trim().length <= maxReasonLength) {
      Logger.log('User', 'RestrictChangeReason', { assetIds: this.props.selectedAssets });
      this.props.onChange(this.props.selectedAssets, {
        reason: value || null,
      });
    }
  };

  handleChangeDates = (key, value) => {
    const keyName = key === 'startAt' ? 'StartAt' : 'ExpiresAt';
    const data = {
      [key]: value,
    };

    if (value) {
      data.isRestricted = true;
    }

    Logger.log('User', `RestrictChange${keyName}`, { assetIds: this.props.selectedAssets, value });

    this.props.onChange(this.props.selectedAssets, data);
  };

  handleOnInputChangeStart = (date) => {
    let newDate = date;

    if (isValidDate(date)) {
      const tzoffset = date.getTimezoneOffset() * 60000;
      newDate = (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
    }
    const data = {
      startAt: newDate,
    };

    if (newDate) {
      data.isRestricted = true;
    }
    Logger.log('User', 'RestrictChangeStartAt', { assetIds: this.props.selectedAssets, newDate });

    this.props.onChange(this.props.selectedAssets, data);
  }

  handleOnInputChangeEnd = (date) => {
    let newDate = date;
    if (isValidDate(date)) {
      const tzoffset = date.getTimezoneOffset() * 60000;
      newDate = (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
    }
    const data = {
      expiresAt: newDate,
    };

    if (newDate) {
      data.isRestricted = true;
    }
    Logger.log('User', 'RestrictChangeExpiresAt', { assetIds: this.props.selectedAssets, newDate });

    this.props.onChange(this.props.selectedAssets, data);
  }

  renderDateFields = () => {
    let {
      expiresAt, startAt, restrictStartAtPlaceholder, restrictExpiresAtPlaceholder, disabled,
    } = this.props;
    let minTime; let startOfTheDay; let endOfTheDay; let minTimeExpiresAt;

    if (!this.isMobile) {
      startAt = startAt && dayjs(startAt).isValid() ? new Date(startAt) : null;
      expiresAt = expiresAt && dayjs(expiresAt).isValid() ? new Date(expiresAt) : null;

      startOfTheDay = new Date(dayjs().startOf('day'));
      endOfTheDay = new Date(dayjs().endOf('day'));
      minTime = startAt && dayjs(startAt).isAfter(endOfTheDay) ? startOfTheDay : new Date();
      minTimeExpiresAt = expiresAt && dayjs(expiresAt).isAfter(endOfTheDay) ? startOfTheDay : new Date();
    } else {
      startAt = startAt && dayjs(startAt).isValid() ? dayjs(startAt).format('YYYY-MM-DD') : '';
      expiresAt = expiresAt && dayjs(expiresAt).isValid() ? dayjs(expiresAt).format('YYYY-MM-DD') : '';
    }

    return (
      <div className="UIInput">
        <DatePickerRange
          startDate={startAt}
          endDate={expiresAt}
          onInputChangeStart={this.handleOnInputChangeStart}
          onInputChangeEnd={this.handleOnInputChangeEnd}
          datePickerDateFormat={`${datePickerDateFormat} ${datePickerTimeFormat}`}
          datePickerTimeFormat={datePickerTimeFormat}
          popperClassName="datepickerFixed"
          popperPlacement="bottom-start"
          popperProps={{ positionFixed: true }}
          startDateMinDate={new Date()}
          showTimeSelect
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '5px, 10px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'scrollParent',
            },
          }}
          datePickerOrder="column"
          labelStartDate="Start date"
          labelEndDate="Expires at"
          timeCaption="time"
          placeholderText={restrictStartAtPlaceholder || datePickerPlaceholderWithTime}
          endDateMinTime={minTimeExpiresAt}
          endDateMaxTime={endOfTheDay}
          startDateMinTime={minTime}
          startDateMaxTime={endOfTheDay}
          startDatePlaceholderText={restrictStartAtPlaceholder || datePickerPlaceholderWithTime}
          endDatePlaceholderText={restrictExpiresAtPlaceholder || datePickerPlaceholderWithTime}
          disabled={disabled}
        />
      </div>
    );
  };

  render() {
    const { props, state } = this;

    return (
      <div
        data-qa="details-component-restrict"
        className={cn('detailsPanel__item restrict', { act: props.isVisible, disabled: props.disabled })}
      >
        <div className="detailsPanel__title">
          <span className={cn('detailsPanel__title_text')} onClick={this.handleTitleClick}>
            {localization.DETAILS.textRestrict}
          </span>
          <div className="detailsPanel__title_buttons">
            {!props.disabled && (
              <Checkbox
                slide
                inProgress={props.inProgress}
                value={props.isRestricted}
                onChange={this.handleChangeRestrictStatus}
              />
            )}
          </div>
        </div>
        <CSSTransition in={props.isVisible} timeout={300} classNames="fade">
          <>
            <If condition={props.isVisible}>
              <div className="restrictSettings">
                <InputLabel>
                  {localization.RESTRICT.restrictReasonLabel}
                </InputLabel>
                <Input
                  component="textarea"
                  autoResize
                  minHeight={68}
                  maxHeight={148}
                  value={state.reason}
                  onChange={this.handleChangeReason}
                  onBlur={this.handleBlurReason}
                  disabled={props.disabled}
                  error={!!state.reasonError}
                />
                <If condition={!!state.reasonError}>
                  <p className="PicsioInputHelperText PicsioInputHelperText--error">
                    {state.reasonError}
                  </p>
                </If>
                {this.renderDateFields()}
              </div>
            </If>
          </>
        </CSSTransition>
      </div>
    );
  }
}
export default Restrict;
