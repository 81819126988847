import _isEmpty from 'lodash/isEmpty';
import l18n from '../../../shared/strings';
import { showDialog } from '../../../components/dialog';
import { isValidDate } from '../../../shared/utils';
import picsioConfig from '../../../../../../config';

function validateCustomField(value, type) {
  if (type === 'date') {
    if (!isValidDate(value)) {
      return false;
    }
  }
  if (value === undefined || value === null) {
    return false;
  }
  if ((typeof value === 'string' || typeof value === 'boolean') && !value) {
    return false;
  }
  if (typeof value === 'number') {
    if (type !== 'enum') {
      return value > 0;
    }
  }
  return true;
}

export default function validateFields({
  fields = {},
  requiredFields = {},
  editKeywordsPermission = true,
  importFrom,
}) {
  /** Don't validate importing files */
  if (importFrom) return { errors: {}, isValid: true, error: null };

  const errors = {};
  const {
    comment, title, description, keywords, assignees, flag, rating, color, customFields,
  } = fields;

  if (requiredFields.comments && !comment?.length) {
    errors.comment = l18n.IMPORT.textFieldIsRequired;
  }
  if (((picsioConfig.isInboxApp && requiredFields.titleAndDescription) || requiredFields.title) && !title?.length) {
    errors.title = l18n.IMPORT.textFieldIsRequired;
  }
  if (((picsioConfig.isInboxApp && requiredFields.titleAndDescription) || requiredFields.description) && !description?.length) {
    errors.description = l18n.IMPORT.textFieldIsRequired;
  }
  if (requiredFields.keywords && !keywords.length) {
    errors.keywords = l18n.IMPORT.textFieldIsRequired;
  }
  if (requiredFields.assignees && !assignees.length) {
    errors.assignees = l18n.IMPORT.textFieldIsRequired;
  }
  if (requiredFields.flag && !flag) {
    errors.flag = true;
    errors.assetMarks = l18n.IMPORT.textFieldIsRequired;
  }
  if (requiredFields.rating && !rating) {
    errors.rating = true;
    errors.assetMarks = l18n.IMPORT.textFieldIsRequired;
  }
  if (requiredFields.color && !color) {
    errors.color = true;
    errors.assetMarks = l18n.IMPORT.textFieldIsRequired;
  }
  if (requiredFields.customFields && requiredFields.customFields.length) {
    const customFieldsErrors = {};

    requiredFields.customFields.forEach((ttl) => {
      if (typeof customFields[ttl] === 'undefined' || customFields[ttl] === null) {
        customFieldsErrors[ttl] = l18n.IMPORT.textFieldIsRequired;
        return;
      }
      const { type, value } = customFields[ttl];
      const isValid = validateCustomField(value, type);

      if (!isValid) {
        customFieldsErrors[ttl] = l18n.IMPORT.textFieldIsRequired;
      }
    });

    if (!_isEmpty(customFieldsErrors)) {
      errors.customFields = customFieldsErrors;
    }
  }
  const isValid = _isEmpty(errors);
  const error = isValid ? null : {
    message: l18n.IMPORT.textRequiredFieldError,
  };

  if (errors.keywords && !editKeywordsPermission) {
    showDialog({
      title: l18n.DIALOGS.UPLOAD_KEYWORDS_PERMISSION.TITLE,
      text: l18n.DIALOGS.UPLOAD_KEYWORDS_PERMISSION.TEXT,
      textBtnCancel: null,
      textBtnOk: l18n.DIALOGS.UPLOAD_KEYWORDS_PERMISSION.OK_TEXT,
    });
  }

  return { errors, isValid, error };
}
