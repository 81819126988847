import Logger from '../../../services/Logger';
import TYPES from '../../action-types';
import sdk from '../../../sdk';

const attachWatermark = (overwrite = false, force = false, assetIds, _id) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.ASSETS.ATTACH_WATERMARK.START,
    });
    const { data: assetsWithWatermarks } = await sdk.assets.toggleWatermarks(
      assetIds,
      _id,
      overwrite,
      force,
    );
    const ids = assetsWithWatermarks.map((asset) => asset._id);
    dispatch({
      type: TYPES.ASSETS.ATTACH_WATERMARK.COMPLETE,
      payload: {
        ids,
        watermarkId: _id,
      },
    });
  } catch (error) {
    dispatch({
      type: TYPES.ASSETS.ATTACH_WATERMARK.FAIL,
    });
    Logger.error(new Error('Error attaching watermarks'), { error, showDialog: false }, [
      'AttachWatermarksFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};

export default attachWatermark;
