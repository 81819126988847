import { assets as ASSETS_CONSTANTS } from '@picsio/db/src/constants';
import sanitizeFilename from '../../../helpers/sanitizeFilename';
import { bytesToSize, generateId } from '../../../shared/utils';
import { findCollection } from '../../helpers/collections';

/**
 * Check black listed files
 * @param {string} name
 * @param {Array} userBlackList
 * @returns {boolean}
 */
export function checkBlackListedFiles(name, userBlackList) {
  let blackList = ASSETS_CONSTANTS.extensionsBlackList;
  if (userBlackList) {
    blackList = userBlackList.map(({ regex, options }) => new RegExp(regex, (options || '')));
  }
  return blackList.find((re) => !!re.test(name));
}

/**
 * Check black listed files
 * @param {string} name
 * @returns {boolean}
 */
export function checkHiddenFiles(name) {
  return !!ASSETS_CONSTANTS.reHiddenFile.test(name);
}

/**
 * Make items for store
 * @param {FileList | string[]} filesOrUrls
 * @param {string?} deepPath - if file inside dropped/choosen folder
 * @param {string} rootCollectionName
 * @param {*?} collection
 * @param {*?} lightboard
 * @param {*?} userBlackList
 * @returns {Object[]}
 */
export function normalizeItems(filesOrUrls, deepPath, rootCollectionName, collection, lightboard, userBlackList) {
  return [...filesOrUrls].reduce((result, fileOrUrl) => {
    const isURL = typeof fileOrUrl === 'string';
    let file = fileOrUrl;
    if (isURL) {
      /** if asset from url */
      file = new File(
        [fileOrUrl],
        sanitizeFilename(`${fileOrUrl}.picsio`),
        { type: ASSETS_CONSTANTS.mimeTypes?.PICSIO_EXTERNAL },
      );
    }
    if (!file.size && file['.tag'] !== 'folder') {
      /** if zero file size or file size is unknown */
      result.corruptedFiles.push(file);
    } else if (checkBlackListedFiles(file.name, userBlackList)) {
      result.blackListedFiles.push(file);
    } else if (checkHiddenFiles(file.name)) {
      result.hiddenFiles.push(file);
    } else {
      let path = '';
      if (collection) {
        if (collection.path === 'root') {
          path = `${collection.name}/${deepPath || ''}`;
        } else {
          path = `${rootCollectionName}${collection.path}${collection.name}/${deepPath || ''}`;
        }
      }
      if (lightboard) path = `Lightboards/${lightboard.path.substring(1)}/`;

      if (file['.tag'] === 'folder') {
        const parts = path.split('/');
        const slicedPath = parts.slice(1).join('/');
        const foundedCollection = findCollection({ collection }, null, { name: file.name, path: `/${slicedPath}` });

        if (foundedCollection !== undefined) {
          result.duplicateCollections.push(file);
          return result;
        }
      }

      result.normalizedFiles.push({
        id: generateId(),
        file,
        shortSize: bytesToSize(file.size),
        source: isURL ? fileOrUrl : undefined,
        collection,
        lightboard,
        deepPath: collection ? deepPath : undefined,
        name: file.name,
        path,
      });
    }
    return result;
  }, {
    normalizedFiles: [], corruptedFiles: [], blackListedFiles: [], hiddenFiles: [], duplicateCollections: [],
  });
}

/**
 * Normalize dropped files
 * @param {Array|Object} files
 * @param {string} rootCollectionName
 * @param {*} collection
 * @param {*} lightboard
 * @param {*?} userBlackList
 * @returns {Array}
 */
export function normalizeDroppedFiles(files, rootCollectionName, collection, lightboard, userBlackList) {
  const result = {
    normalizedFiles: [], corruptedFiles: [], blackListedFiles: [], hiddenFiles: [], duplicateCollections: [],
  };
  if (Array.isArray(files)) {
    files.forEach((item) => {
      const {
        normalizedFiles, corruptedFiles, blackListedFiles, hiddenFiles, duplicateCollections,
      } = normalizeDroppedFiles(item, rootCollectionName, collection, lightboard, userBlackList);
      result.normalizedFiles.push(...normalizedFiles);
      result.corruptedFiles.push(...corruptedFiles);
      result.blackListedFiles.push(...blackListedFiles);
      result.hiddenFiles.push(...hiddenFiles);
      result.duplicateCollections.push(...duplicateCollections);
    });
  } else {
    const {
      normalizedFiles, corruptedFiles, blackListedFiles, hiddenFiles, duplicateCollections,
    } = normalizeItems([files.file], files.collectionPath, rootCollectionName, collection, lightboard, userBlackList);
    result.normalizedFiles.push(...normalizedFiles);
    result.corruptedFiles.push(...corruptedFiles);
    result.blackListedFiles.push(...blackListedFiles);
    result.hiddenFiles.push(...hiddenFiles);
    result.duplicateCollections.push(...duplicateCollections);
  }
  return result;
}
