import { createAsyncThunk } from '@reduxjs/toolkit';

const removeFromAllCollections = createAsyncThunk(
  'assets/removeFromAllCollections',
  async (assetId, {
    extra: {
      sdk, utils, UiBlocker, localization: l10n, showDialogAsync, showErrorDialog, Logger,
    }, getState,
  }) => {
    try {
      Logger.log('User', 'RemoveAssetFromAllCollectionsShow');
      const asset = getState().assets.items.find(({ _id }) => _id === assetId);
      const { TITLE, TEXT, OK } = l10n.DIALOGS.REMOVE_FROM_ALL_COLLECTIONS;
      const { CANCEL } = l10n.DIALOGS;
      const { promise } = showDialogAsync({
        title: TITLE,
        children: TEXT(asset?.name || assetId),
        textBtnOk: OK,
        textBtnCancel: CANCEL,
      });
      try {
        await promise;
      } catch (e) {
        // User press cancel
        Logger.log('User', 'RemoveAssetFromAllCollectionsCancel');
        return {};
      }

      UiBlocker.block('Processing...');
      await sdk.assets.removeFromAllCollections(assetId);

      const { activeCollections } = getState().collections;
      Logger.log('User', 'RemoveAssetFromAllCollectionsSuccess');
      return {
        assetId,
        isRootActive: activeCollections?.length === 1 && activeCollections[0]?.path === 'root',
      };
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 403) {
        /* if no permissions */
        showErrorDialog(l10n.NO_PERMISSION_TO_ACCESS);
        return {};
      }
      Logger.error(new Error('Error remove asset from all collections'), { error }, [
        'RemoveAssetFromAllCollectionsFailed',
        (error && error.message) || 'NoMessage',
      ]);
      showErrorDialog(l10n.DIALOGS.REMOVE_FROM_ALL_COLLECTIONS.ERROR);
      throw error;
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default removeFromAllCollections;
