import _merge from 'lodash/merge';
import _isEmpty from 'lodash/isEmpty';
/**
 * Combine errors for files
 * @param {*[]} files
 * @returns {*}
 */
export default function groupErrors(files) {
  return files.reduce((result, file) => {
    if (!file.errors || _isEmpty(file.errors)) return result;

    _merge(result, file.errors);
    return result;
  }, {});
}
