/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { facesMakeTree, facesMakeSearchTree } from '../helpers/faces';

export const applySearch = createAction('faces/applySearch');

export const reducer = (builder) => {
  builder.addCase(
    applySearch.type,
    (state, { payload: value }) => {
      if (!value) {
        state.tree = facesMakeTree(state.all, state.sortType);
        return;
      }
      state.tree = facesMakeSearchTree(state.all, value, state.sortType);
    },
  );
};
