import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';

import checkMultipleChanges from './checkMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Set user orientation
 * @param {string[]} ids
 * @param {number} value.rotation - one of: 0 | 90 | 180 | 270
 * @param {boolean} value.flipX
 * @param {boolean} value.flipY
 */
const setUserOrientation = (ids, value) => (dispatch) => {
  checkMultipleChanges(ids.length, doChange);

  async function doChange() {
    try {
      dispatch({
        type: TYPES.ASSETS.SET_USER_ORIENTATION.START,
        payload: { ids, value },
      });

      await assetsHelpers.setUserOrientation(ids, value);

      dispatch({
        type: TYPES.ASSETS.SET_USER_ORIENTATION.COMPLETE,
        payload: { ids, value },
      });
    } catch (error) {
      Toast(localization.DETAILS.textCantUpdate('orientaion'), { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      dispatch({ type: TYPES.ASSETS.SET_USER_ORIENTATION.FAILED, error });
      Logger.error(new Error('Error set user orientation'), { error }, [
        'SetUserOrientationFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  }
};

export default setUserOrientation;
