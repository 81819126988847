/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { reducer as sendFacesRecognize } from './sendFacesToRecognize';
import { reducer as getFaces } from './getFaces';
import { reducer as createFace } from './createFace';
import { reducer as setAvatars } from './setAvatars';
import { reducer as getFacesByIds } from './getFacesByIds';
import { reducer as setActiveFaces } from './setActive';
import { reducer as favorite } from './favorite';
import { reducer as remove } from './remove';
import { reducer as rename } from './rename';
import { reducer as deleteUnnamed } from './deleteUnnamed';
import { reducer as applySearch } from './applySearch';
import { reducer as sortTypeFace } from './sortTypeFace';
import { reducer as addCustomFace } from '../actions/assets/addCustomFace';
import { reducer as applyFace } from '../actions/assets/applyFace';
import { reducer as merge } from './merge';
import { reducer as sendFacesForCollectionRecognize } from './sendFacesForCollectionRecognize';

const initialState = {
  all: [],
  tree: null,
  selectedFaces: [],
  activeFaces: [],
  sortType: { type: 'createdAt', order: 'asc' },
  searchQuery: '',
};

const facesReducer = createReducer(
  initialState,
  (builder) => {
    sendFacesRecognize(builder);
    getFaces(builder);
    createFace(builder);
    setAvatars(builder);
    getFacesByIds(builder);
    setActiveFaces(builder);
    favorite(builder);
    remove(builder);
    rename(builder);
    applySearch(builder);
    sortTypeFace(builder);
    addCustomFace(builder);
    applyFace(builder);
    deleteUnnamed(builder);
    merge(builder);
    sendFacesForCollectionRecognize(builder);
  },
);

export default facesReducer;
