import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import cn from 'classnames';
import { EmojiPickerToggler, Emoji, IconButton } from '@picsio/ui';
import {
  ReplyIcon,
  Delete,
  Link as LinkIcon,
  EyeOpen,
  EyeClosed,
  Edit,
} from '@picsio/ui/dist/icons';

import { CheckIcon } from '@picsio/icons';
import { Author } from '../../UserComponent';

import * as utils from '../../../shared/utils';
import localization from '../../../shared/strings';
import picsioConfig from '../../../../../../config';
import Tooltip from '../../Tooltip';

import CommentContent from './CommentContent';
import CommentOriginal from './CommentOriginal';
import CommentReaction from './CommentReaction';
import copyTextToClipboard from '../../../helpers/copyTextToClipboard';
import Textarea from '../HistoryViewBottom/Textarea';
import parseHTMLStringWithEmoji from '../HistoryViewBottom/utils/parseHTMLStringWithEmoji';
import { multilineNormalize, replaceMentions } from '../helper';
import unescapeHTML from '../../../shared/unescapeHTML';
import SkeletonItem from '../SkeletonItem';

// updated to React.Component from React.PureComponent by Alexey
// this.props.data.text could changed when teammate delete a comment
class Comment extends React.Component {
   state = {
     isOpenEditor: false,
     isLoading: false,
     isLinkCopied: false,
   };

  $textarea = React.createRef();

  componentDidUpdate(_, prevState) {
    const { isOpenEditor } = this.state;
    if (this.$textarea.current && prevState.isOpenEditor !== isOpenEditor) {
      const { data } = this.props;
      const content = this.getContent(data);
      this.$textarea.current.innerHTML = content;
      const range = document.createRange();
      range.selectNodeContents(this.$textarea.current);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  copyToClipboard = () => {
    const { data } = this.props;

    const { origin, pathname } = window.location;
    /** Copy to clipboard */
    const commentUrl = `${origin + pathname}#${data._id}`;
    this.setState({ isLinkCopied: true });
    copyTextToClipboard(commentUrl);
  };

  handleRemove = async () => {
    const { data, onRemove } = this.props;
    this.setState({ isLoading: true });
    await onRemove(data._id);
    this.setState({ isLoading: false });
  };

  handleClickText = (e) => {
    const { onEyeClick, index } = this.props;
    const { time } = e.target.dataset;

    if (time) {
      onEyeClick(index);
    }
  };

  getContent = (comment, isVideo = false) => {
    if (comment) {
      const {
        text, mentions, videoCurrentTime, videoCurrentTimeEnd,
      } = comment;
      let html = (text || '')
        .replace(utils.mentionPattern, (mentionString) => {
          const mentionID = mentionString.substring(1);
          const mention = mentions ? mentions.find((m) => m._id === mentionID) : null;

          if (mention) {
            return `<span class="itemHistoryList__main__text__mentionedUser" data-id="${mention._id}">@${mention.displayName}</span>`;
          }
          return mentionString;
        })
        .replace(/(:[\w\d\s/(/)+-]*:)/g, (matched) => {
          try {
            const emojiName = matched.replace(/:/g, '');
            const reactString = ReactDOMServer.renderToString(<Emoji name={emojiName} />);

            if (!reactString) {
              return matched;
            }
            return reactString;
          } catch (e) {
            return matched;
          }
        });

      if (isVideo) {
        if (videoCurrentTime !== undefined) {
          if (videoCurrentTimeEnd !== undefined) {
            let minutesStart = Math.floor(videoCurrentTime / 60);
            let secondsStart = Math.floor(videoCurrentTime % 60);
            if (minutesStart < 10) minutesStart = `0${minutesStart}`;
            if (secondsStart < 10) secondsStart = `0${secondsStart}`;

            let minutesEnd = Math.floor(videoCurrentTimeEnd / 60);
            let secondsEnd = Math.floor(videoCurrentTimeEnd % 60);
            if (minutesEnd < 10) minutesEnd = `0${minutesEnd}`;
            if (secondsEnd < 10) secondsEnd = `0${secondsEnd}`;
            html = `<span class="itemHistoryList__main__text__time" data-time="${videoCurrentTime}">${minutesStart}:${secondsStart}</span> - <span class="itemHistoryList__main__text__time" data-time="${videoCurrentTimeEnd}">${minutesEnd}:${secondsEnd}</span> ${html}`;
          } else {
            let minutes = Math.floor(videoCurrentTime / 60);
            let seconds = Math.floor(videoCurrentTime % 60);
            if (minutes < 10) minutes = `0${minutes}`;
            if (seconds < 10) seconds = `0${seconds}`;
            html = `<span class="itemHistoryList__main__text__time" data-time="${videoCurrentTime}">${minutes}:${seconds}</span> ${html}`;
          }
        }
      }

      return utils.sanitizeXSS(html, {});
    }
    return null;
  };

  handleClickOrigin = (commentId) => {
    const { listNode } = this.props;

    if (listNode) {
      const node = listNode.querySelector(`[id="${commentId}"]`);

      if (node) {
        node.classList.remove('highlight');
        const listRect = listNode.getBoundingClientRect();
        const nodeRect = node.getBoundingClientRect();
        const topDiff = nodeRect.top - listRect.top;
        const scrollTop = listNode.scrollTop + topDiff;

        listNode.scrollTop = scrollTop;
        node.classList.add('highlight');
      }
    }
  };

  onReply = () => {
    const { data, addReply } = this.props;
    const { _id, userDisplayName } = data;
    const content = this.getContent(data, this.props.isVideo);

    addReply({ _id, userDisplayName, content });
  };

  onSelectReaction = (emojiName) => {
    const { data, addReaction } = this.props;
    const { _id: commenId } = data;

    addReaction(commenId, emojiName);
  };

  getReactions = () => {
    const {
      data: { reactions },
    } = this.props;

    if (reactions && reactions.length) {
      const reactionsByEmoji = reactions.reduce((acc, { userId, displayName, value }) => {
        const user = { id: userId, name: displayName };

        if (acc[value]) {
          const { count, users } = acc[value];

          acc[value] = {
            count: count + 1,
            users: [...users, user],
          };
        } else {
          acc[value] = {
            users: [user],
            count: 1,
          };
        }
        return acc;
      }, {});
      return reactionsByEmoji;
    }
    return {};
  };

  handleToggleCommentEditor = () => {
    const { isOpenEditor } = this.state;
    const { onScrollToBottom, isLastElement } = this.props;
    this.setState({ isOpenEditor: !isOpenEditor });

    if (isLastElement) {
      this.setState({ isOpenEditor: !isOpenEditor }, onScrollToBottom);
    } else {
      this.setState({ isOpenEditor: !isOpenEditor });
    }
  }

  onEditComment = async () => {
    const { data, onEdit } = this.props;
    const { _id: commentId } = data;
    this.setState({ isLoading: true });

    let text = parseHTMLStringWithEmoji(
      this.$textarea.current.innerHTML || this.$textarea.current.value,
    );
    if (text === null) {
      this.setState({ isLoading: false, error: localization.HISTORY.textCommentImageIsRestricted });
      return;
    }

    if (typeof text === 'string' && text.length > 0) {
      const mentions = replaceMentions(text);
      text = mentions.text;
      text = multilineNormalize(text);
      text = unescapeHTML(text);
      text = utils.sanitizeXSS(text, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });

      await onEdit(text, commentId, mentions.mentions);
    }

    this.setState({ isLoading: false, error: '' });

    this.handleToggleCommentEditor();
  };

    pauseVideo = () => {
      const $video = this.props.getElVideo();

      if ($video) {
        $video.pause();
      }
    };

    onFocus = () => {
      this.pauseVideo();
    };

    onKeyDown = (event) => {
      const KEY_ENTER = 13;
      const { keyCode, shiftKey } = event;

      if (keyCode === KEY_ENTER && !shiftKey) {
        event.preventDefault();
        this.onEditComment();
      }
    };

    render() {
      const {
        isOpenEditor, isLoading, error, isLinkCopied,
      } = this.state;
      const {
        index,
        currentUserId,
        data,
        originalComment,
        onEyeClick,
        isActive,
        showMarkerIcon,
        isCommentsNotAllowed,
        isVideo,
      } = this.props;
      const {
        _id,
        userAvatar,
        userDisplayName,
        userId,
        markers,
        text,
        revNumber,
        approved,
        createdAt,
        updatedAt,
        isRemovable,
        highlightNotification,
        revisionID,
      } = data;

      const date = dayjs(createdAt).format('lll');
      const isRenderBtnDelete = picsioConfig.isMainApp
        && isRemovable === undefined && currentUserId === userId;
      const isCommentEnabled = picsioConfig.access ? picsioConfig.access.comment : true;
      const isReplyEnabled = !revNumber && isCommentEnabled;
      const content = this.getContent(data, isVideo);
      const originalContent = this.getContent(originalComment, isVideo);
      const reactions = this.getReactions();
      const reactionEmojis = Object.keys(reactions);
      const isEditable = picsioConfig.isMainApp && currentUserId === userId;

      return (
        <Choose>
          <When condition={isLoading}>
            <>
              <SkeletonItem />
            </>
          </When>
          <Otherwise>
            <div
              id={_id}
              className={cn('itemHistoryList', {
                act: isActive,
                highlight: _id === window.location.hash.substring(1),
                highlightFadeUp: highlightNotification,
              })}
            >
              <div className="itemHistoryList__root" />
              <div className="itemHistoryList__main">
                <Author avatar={userAvatar} name={userDisplayName} additional={date} />

              </div>
              <If condition={originalComment}>
                <CommentOriginal
                  {...originalComment}
                  content={originalContent}
                  onClick={this.handleClickOrigin}
                />
              </If>
              <Choose>
                <When condition={!isOpenEditor}>
                  <CommentContent
                    text={text}
                    markers={markers}
                    approved={approved}
                    revNumber={revNumber}
                    content={content}
                    onClickText={this.handleClickText}
                    index={index}
                    isActive={isActive}
                    onEyeClick={onEyeClick}
                  />
                  {createdAt !== updatedAt && <span key={_id} className="itemHistoryList__edited-text">{localization.HISTORY.textEdit}</span>}
                </When>
                <Otherwise>
                  <div className="itemHistoryList__container" style={{ position: 'relative' }}>
                    <Textarea
                      ref={this.$textarea}
                      onKeyDown={this.onKeyDown}
                      onFocus={this.onFocus}
                      isError={!!error}
                    />
                    <If condition={!!error}>
                      <p className="PicsioInputHelperText PicsioInputHelperText--error">
                        {error}
                      </p>
                    </If>
                    <div className="itemHistoryList__footer__buttons-container">
                      <button
                        type="button"
                        aria-label="Cancel button"
                        className="itemHistoryList__footer__button--cancel"
                        onClick={this.handleToggleCommentEditor}
                      >
                        {localization.HISTORY.textCancelButton}
                      </button>
                      <button
                        type="button"
                        aria-label="Save button"
                        className="itemHistoryList__footer__button--save"
                        onClick={this.onEditComment}
                      >
                        {localization.HISTORY.textSaveButton}
                      </button>
                    </div>
                  </div>
                </Otherwise>
              </Choose>
              <If condition={!isCommentsNotAllowed && !isOpenEditor}>
                <div className="itemHistoryList__footer">
                  <If condition={reactionEmojis.length && !revNumber}>
                    <div className="itemHistoryList__footer__reactions">
                      {Object.keys(reactions).map((emojiName) => {
                        const reaction = reactions[emojiName];

                        return (
                          <CommentReaction
                            key={emojiName}
                            currentUserId={currentUserId}
                            emojiName={emojiName}
                            onClick={this.onSelectReaction}
                            {...reaction}
                          />
                        );
                      })}
                    </div>
                  </If>
                  <div className="itemHistoryList__footer__buttons">
                    <If condition={isEditable && text !== '' && !revNumber}>
                      <Tooltip content="Edit" placement="top">
                        <IconButton onClick={this.handleToggleCommentEditor}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </If>
                    <If condition={isReplyEnabled}>
                      <Tooltip content="Add reaction" placement="top">
                        <span className="itemHistoryList__footer__emoji-button">
                          <EmojiPickerToggler
                            onSelect={this.onSelectReaction}
                            iconSize="md"
                            popperProps={{ persist: false, hide: true }}
                          />
                        </span>
                      </Tooltip>
                      <Tooltip content="Reply" placement="top">
                        <IconButton onClick={this.onReply}>
                          <ReplyIcon />
                        </IconButton>
                      </Tooltip>
                    </If>
                    <If condition={showMarkerIcon && markers.length > 0 && revisionID !== null}>
                      <Tooltip
                        content={
                          isActive
                            ? localization.HISTORY.textHideMarkers
                            : localization.HISTORY.textShowMarkers
                        }
                        placement="top"
                      >
                        <IconButton className="btnToggleMarkers" onClick={() => onEyeClick(index)}>
                          <Choose>
                            <When condition={isActive}>
                              <EyeOpen />
                            </When>
                            <Otherwise>
                              <EyeClosed />
                            </Otherwise>
                          </Choose>
                        </IconButton>
                      </Tooltip>
                    </If>
                    <Tooltip content={isLinkCopied ? localization.DETAILS.textLinkCopied : localization.HISTORY.textCopyLink} placement="top">
                      <IconButton onClick={this.copyToClipboard} size="lg">
                        <Choose>
                          <When condition={isLinkCopied}>
                            <CheckIcon className="checkIcon" />
                          </When>
                          <Otherwise>
                            <LinkIcon />
                          </Otherwise>
                        </Choose>
                      </IconButton>
                    </Tooltip>
                    <If condition={isRenderBtnDelete}>
                      <Tooltip content={localization.HISTORY.textDeleteComment} placement="top">
                        <IconButton onClick={this.handleRemove} size="lg">
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </If>
                  </div>
                </div>
              </If>
            </div>
          </Otherwise>
        </Choose>
      );
    }
}

const commentProps = {
  createdAt: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.object),
  listNode: PropTypes.any,
  data: PropTypes.any,
  originalComment: PropTypes.any,
};

Comment.propTypes = {
  listNode: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  currentUserId: PropTypes.string,
  data: PropTypes.shape(commentProps),
  originalComment: PropTypes.shape(commentProps),
  isVideo: PropTypes.bool,
  onEyeClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  addReply: PropTypes.func.isRequired,
  addReaction: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  showMarkerIcon: PropTypes.bool.isRequired,
  isCommentsNotAllowed: PropTypes.bool.isRequired,
  isLastElement: PropTypes.bool.isRequired,
  onScrollToBottom: PropTypes.func.isRequired,

};

export default Comment;
