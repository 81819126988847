export default {
  events: {
    'command a': null, // 'selectAll',
    'command i': null, // 'toggleImportPanel',
    'command c': null, // 'toggleTagsTree',
    'command m': null, // 'toggleInfoPanel',
    'command f': null, // 'toggleSearch',

    'command /': null, // open dialog hotkeys,

    up: null,
    down: null,
    home: null,
    end: null,
    pageup: null,
    pagedown: null,
    'command up': null,
    'command down': null,
    'alt up': null,
    'alt down': null,
  },
};
