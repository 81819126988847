import React from 'react';
import { bool, func, string } from 'prop-types';
import { Radio } from '@picsio/ui';

export default function RadioItem({
  value,
  label,
  checkedValue,
  disabled,
  onChange,
  labelDataTestId,
  ...restProps
}) {
  return (
    <div
      className={`radioListItem UIRadio ${disabled ? 'UIRadio--disabled' : ''}`}
      onClick={() => !disabled && onChange(value)}
      {...restProps}
    >
      <Radio
        checked={checkedValue === value}
        disabled={disabled}
        onChange={() => {
        }}
      />
      {label && (
        <span className="UIRadio__label" data-testId={labelDataTestId}>{label}</span>
      )}
    </div>
  );
}

RadioItem.defaultProps = {
  label: '',
  disabled: false,
  labelDataTestId: '',
};

RadioItem.propTypes = {
  value: string.isRequired,
  label: string,
  checkedValue: string.isRequired,
  disabled: bool,
  onChange: func.isRequired,
  labelDataTestId: string,
};
