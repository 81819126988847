/**
 * Create function that emits global upload progress event
 * @param {number} totalSize
 * @returns Function<void>
 */
export default function createUploadingProgressHelper(totalSize) {
  let uploadedData = {};
  /**
   * Function to notify about global upload progress
   * @param {string} id
   * @param {number} bytesUploaded
   * @returns {void}
   */
  return function setUploadingProgress(id, bytesUploaded) {
    uploadedData = {
      ...uploadedData, [id]: bytesUploaded,
    };
    const percantage = Math.floor((Object.values(uploadedData).reduce((acc, value) => acc + value, 0) * 100) / totalSize);
    window.dispatchEvent(
      new CustomEvent('import:uploading:progress', {
        detail: { percantage, ElParent: '#button-upload' },
      }),
    );
  };
}
