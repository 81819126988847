import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import ua from '../ua';
import sanitizeXSS from '../shared/sanitizeXSS';

export default function InputWithStriptags({
  name = '',
  value,
  placeholder = '',
  type = 'text',
  className = '',
  onChange = Function.prototype,
  onFocus = Function.prototype,
  onBlur = Function.prototype,
  onKeyDown = Function.prototype,
  onMouseDown = Function.prototype,
  onPaste = Function.prototype,
  dataName,
  defaultValue,
  disabled = false,
  customRef = React.createRef(),
  autoFocus = false,
  autoComplete = 'false',
  pattern,
  size,
  maxLength = 20000,
  readOnly,
  stripTagsEnable = true,
  dataTestId,
  inputStyles = {},
}) {
  if (!['number', 'string'].includes(typeof value)) value = undefined;

  if (type === 'date' && value) {
    // value = Date.create(value);
    if (!ua.browser.isNotDesktop()) {
      value = dayjs(value);
      const localTimezoneOffset = value.getTimezoneOffset() * 60 * 1000;
      value = new Date(value.getTime() + localTimezoneOffset).format('{yyyy}-{MM}-{dd}');
    }
  }

  const handleOnBlur = (event) => {
    const value = stripTagsEnable ? sanitizeXSS(event.currentTarget.value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }, true) : event.currentTarget.value;
    event.target.value = sanitizeXSS(event.target.value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }, true);
    onBlur(event, value);
  };

  useEffect(() => {
    if (autoFocus === true) {
      customRef.current && customRef.current.focus();
    }
  }, [autoFocus]);

  const handleOnChange = (event) => {
    event.target.value = sanitizeXSS(event.target.value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }, true);
    onChange(event, event.target.value);
  };

  return (
    <input
      style={inputStyles}
      name={name}
      type={type}
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      ref={customRef}
      onChange={handleOnChange}
      onFocus={(e) => onFocus(e, e.currentTarget.value)}
      onBlur={(e) => handleOnBlur(e)}
      onKeyDown={(e) => onKeyDown(e, e.currentTarget.value)}
      onMouseDown={(e) => onMouseDown(e)}
      onPaste={(e) => onPaste(e, e.currentTarget.value)}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      data-name={dataName}
      pattern={pattern}
      size={size}
      readOnly={readOnly}
      data-testid={dataTestId}
      maxLength={maxLength}
    />
  );
}
