import { updateFile } from '../updateFile';
import checkCollections from './checkCollections';
import pushCollections from '../../actions/collections/pushCollections';
import { remove } from '../remove';

const prepareFileIds = async ({ payload, importStore, dispatch }) => {
  let ids = payload;
  if (!ids) {
    ids = Object.values(importStore.items).reduce((result, group) => {
      result.push(...group.map(({ id }) => id));
      return result;
    }, []);

    await checkCollections({
      items: importStore.items,
      pushCollections: (c) => dispatch(pushCollections(c)),
      updateFile: (f) => dispatch(updateFile(f)),
      removeItems: (i) => dispatch(remove(i)),
    });
  }
  return ids;
};

export default prepareFileIds;
