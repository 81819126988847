import _sortBy from 'lodash/sortBy';

/**
 * Create customFields groups
 * @param {Object} items,
 * @param {String} title,
 */
export default function createGroups(items, title = 'Custom fields') {
  const newItems = items.map((item) => ({ ...item }));
  const customFieldsMap = {};
  /** fill customFieldsMap */
  // newItems.forEach(customField => (customFieldsMap[customField.title] = Object.clone(customField, true)));
  newItems.forEach((customField) => (customFieldsMap[customField.id || customField.title] = { ...customField }));

  const customFields = _sortBy(newItems, ['order']).map((customField) => customFieldsMap[customField.id || customField.title]);
  /** create groups */
  const groups = customFields.reduce((acc, value) => {
    if (value.type === 'separator') {
      acc.push([value]);
    } else {
      // if first customField is not of type 'separator' - we are creating fake group with title "Custom fields"
      if (!acc.length) {
        acc.push([{
          type: 'separator', title, visibility: 'visible', notRemovable: true,
        }]);
      }
      acc[acc.length - 1].push(value);
    }
    return acc;
  }, []);

  return groups;
}
