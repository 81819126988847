/**
 * Add notification
 * @param {Object[]} items
 * @param {Object} notification
 * @returns {Object} items
 */
const addNotification = (items, notification) => {
  let newItems = items;
  newItems = [notification, ...newItems];
  return newItems;
};

export default addNotification;
