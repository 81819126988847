import TYPES from '../../action-types';

/**
 * Reset asset highlight
 * @param {string[]} ids
 */
const resetHighlight = (ids) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.RESET_HIGHLIGHT,
    payload: {
      ids,
      keys: ['paramsForHighlight'],
      values: [[]],
    },
  });
};

export default resetHighlight;
