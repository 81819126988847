import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import { Search, Close } from '@picsio/icons';
import { Icon, InputAdornment, Input } from '@picsio/ui';
import Logger from '../../../../services/Logger';

function TranscriptSearch({
  placeholder = '', autoFocus = false, setSearchValue,
}) {
  const [currentValue, setValue] = useState('');

  const debounceSearch = _debounce((action, searchValue) => {
    action(searchValue);
    Logger.log('User', 'TranscriptSearchInTranscriptPanel', { text: searchValue });
  }, 300);

  const handleSearch = (e) => {
    const { value } = e.target;
    setValue(value);
    if (setSearchValue) {
      debounceSearch(setSearchValue, value);
    }
  };

  const handleResetSearch = () => {
    setValue('');
    setSearchValue('');
  };

  const handleKeyDown = (e) => {
    const keyEsc = 27;
    if (e.keyCode === keyEsc) handleResetSearch();
  };

  return (
    <div className="TranscriptSearch">
      <Input
        type="text"
        name="search"
        className="TranscriptSearch__input"
        placeholder={placeholder || ''}
        value={currentValue}
        onKeyDown={handleKeyDown}
        onChange={handleSearch}
        autoComplete="off"
        autoFocus={autoFocus}
        endAdornment={currentValue.length
          && (
            <InputAdornment className="TranscriptSearch__icon-close" position="end" onClick={handleResetSearch}>
              <Icon color="inherit" size="lg">
                <Close />
              </Icon>
            </InputAdornment>
          )}
        startAdornment={(
          <InputAdornment>
            <Icon color="inherit" size="lg">
              <Search />
            </Icon>
          </InputAdornment>
        )}
      />
    </div>
  );
}

TranscriptSearch.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default TranscriptSearch;
