/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { reducer as getPresets } from './getPresets';
import { reducer as getThemes } from './getThemes';
import { reducer as createPreset } from './createPreset';
import { reducer as createdPresetEvent } from './createdPresetEvent';
import { reducer as renamePreset } from './renamePreset';
import { reducer as renamedPresetEvent } from './renamedPresetEvent';
import { reducer as deletePreset } from './deletePreset';
import { reducer as deletedPresetEvent } from './deletedPresetEvent';

const initialState = {
  presets: null,
  themes: null,
  error: null,
};

const websiteTemplatesReducer = createReducer(
  initialState,
  (builder) => {
    getPresets(builder);

    getThemes(builder);

    createPreset(builder);

    createdPresetEvent(builder);

    renamePreset(builder);

    renamedPresetEvent(builder);

    deletePreset(builder);

    deletedPresetEvent(builder);
  },
);

export default websiteTemplatesReducer;
