/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';

import { makeTree } from '../helpers/keywords';

export const added = createAction('keywords/added');

export const reducer = (builder) => builder.addCase(
  added.type,
  (state, { payload: keywords }) => {
    const all = _uniqBy([...state.all, ...keywords], '_id');
    state.tree = makeTree(all, state.sortType);
    state.all = all;
  },
);
