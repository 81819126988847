import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Set description to assets
 * @param {string[]} assetIds
 * @param {string} value
 * @returns Promise<AxiosResponse>
 */
export default function setDescription(assetIds, value) {
  if (picsioConfig.isMainApp) return sdk.assets.setDescription(assetIds, value);

  return sdk.assets.setDescriptionPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    value,
  );
}
