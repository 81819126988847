import TYPES from '../../action-types';
import Logger from '../../../services/Logger';

/**
 * Select many asset(s)
 * @param {string} ids
 * @param {boolean} value
 */
const selectMany = (ids, value) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.SELECT_MANY,
    payload: { ids, value },
  });
  Logger.log('User', value ? 'ThumbnailSelect' : 'ThumbnailDeselect');
};

export default selectMany;
