/* eslint-disable import/prefer-default-export */
export const getDefaultFields = () => [
  {
    title: 'Name',
    name: 'name',
    value: true,
  },
  {
    title: 'Title',
    name: 'title',
    value: true,
  },
  {
    title: 'Description',
    name: 'description',
    value: true,
  },
  {
    title: 'Direct URL for embedding',
    name: 'publicUrl',
    value: true,
  },
  {
    title: 'Sharing link',
    name: 'sharingLink',
    value: true,
  },
  {
    title: 'Flag',
    name: 'flag',
    value: true,
  },
  {
    title: 'Rating',
    name: 'rating',
    value: true,
  },
  {
    title: 'Color',
    name: 'color',
    value: true,
  },
  {
    title: 'Collections',
    name: 'tags',
    value: true,
  },
  {
    title: 'Lightboards',
    name: 'lightboards',
    value: true,
  },
  {
    title: 'Keywords',
    name: 'keywords',
    value: true,
  },
  {
    title: 'Faces',
    name: 'faces',
    value: true,
  },
  {
    title: 'Linked assets',
    name: 'linkedAssets',
    value: true,
  },
  {
    title: 'File size',
    name: 'fileSize',
    value: true,
  },
  {
    title: 'Width',
    name: 'width',
    value: true,
  },
  {
    title: 'Height',
    name: 'height',
    value: true,
  },
  {
    title: 'Mime type',
    name: 'mimeType',
    value: true,
  },
  {
    title: 'Create date',
    name: 'createdAt',
    value: true,
  },
  {
    title: 'File metadata',
    name: 'meta',
    value: true,
  },
];
