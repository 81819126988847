import React, { useState, useEffect, Fragment } from 'react';
import {
  arrayOf,
  func,
  number,
  any,
  string,
  bool,
} from 'prop-types';
import DT from 'duration-time-conversion';
import TranscriptSearch from '../TranscriptSearch';
import TranscriptMenu from '../TranscriptMenu';
import { downloadSubFile } from '../../../../helpers/transcript';
import TranscriptItem from '../TranscriptItem';
import * as utils from '../../utils';
import Logger from '../../../../services/Logger';

function TranscriptList({
  videoCurrentTime, setVideoPlayerCurrentTime, transcript, assetId, activeRevisionID = null, assetName, manageTranscriptPermission,
}) {
  const [transcriptArr, setTranscriptArr] = useState([]);
  const [transcriptStr, setTranscriptStr] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isReaderView, setReaderView] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (isReaderView) {
      const result = transcript.map(({ text }) => text).join(' ');
      const searchResult = utils.searchValueInText(result, searchValue);
      setTranscriptStr(searchResult);
    } else {
      const searchResult = transcript.map((item) => ({
        start: item.start,
        end: item.end,
        text: utils.searchValueInText(item.text, searchValue),
      }));
      setTranscriptArr(searchResult);
    }
  }, [searchValue, isReaderView, transcript]);

  useEffect(() => {
    const index = transcriptArr.findIndex(
      (item) => DT.t2d(item.start) <= videoCurrentTime && DT.t2d(item.end) > videoCurrentTime,
    );
    if (index !== currentIndex) {
      setCurrentIndex(index);
    }
  }, [videoCurrentTime, transcriptArr, currentIndex]);

  return (
    <div className="TranscriptList">
      <div className="TranscriptList__search-bar">
        <TranscriptSearch
          placeholder="Search transcript"
          setSearchValue={setSearchValue}
        />
        <TranscriptMenu
          isDownloadable
          isReader
          isReaderView={isReaderView}
          setReaderView={() => {
            Logger.log('User', `TranscriptReaderMode${isReaderView ? 'Off' : 'On'}`);
            setReaderView(!isReaderView);
          }}
          downloadSub={() => {
            Logger.log('User', 'TranscriptSRTDownloaded');
            downloadSubFile(transcript, assetName, 'srt');
          }}
        />
      </div>

      <Choose>
        <When condition={!isReaderView}>
          <ul className="TranscriptList__list">
            {transcriptArr.map((subt, index) => (
              <Choose>
                <When condition={Array.isArray(subt.text)}>
                  <If condition={subt.text.length > 1}>
                    <TranscriptItem
                      key={subt.text + subt.start}
                      isActive={currentIndex === index}
                      setVideoPlayerCurrentTime={setVideoPlayerCurrentTime}
                      startTime={subt.start}
                      text={subt.text}
                      assetId={assetId}
                      activeRevisionID={activeRevisionID}
                      manageTranscriptPermission={manageTranscriptPermission}
                    />
                  </If>
                </When>
                <Otherwise>
                  <TranscriptItem
                    key={subt.text + subt.start}
                    isActive={currentIndex === index}
                    setVideoPlayerCurrentTime={setVideoPlayerCurrentTime}
                    startTime={subt.start}
                    text={subt.text}
                    assetId={assetId}
                    activeRevisionID={activeRevisionID}
                    manageTranscriptPermission={manageTranscriptPermission}
                  />
                </Otherwise>
              </Choose>
            ))}
          </ul>
        </When>
        <Otherwise>
          <div className="TranscriptList__reader">
            <p className="TranscriptList__reader-text">
              <Choose>
                <When condition={Array.isArray(transcriptStr)}>
                  {transcriptStr.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
                </When>
                <Otherwise>
                  {transcriptStr}
                </Otherwise>
              </Choose>
            </p>
          </div>
        </Otherwise>
      </Choose>

    </div>
  );
}

TranscriptList.propTypes = {
  setVideoPlayerCurrentTime: func.isRequired,
  videoCurrentTime: number.isRequired,
  transcript: arrayOf(any).isRequired,
  assetId: string.isRequired,
  activeRevisionID: string,
  assetName: string.isRequired,
  manageTranscriptPermission: bool.isRequired,
};

export default TranscriptList;
