/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import l18n from '../../shared/strings';

export const remove = createAsyncThunk(
  'faces/remove',
  async ({ id, force = false }, {
    extra: {
      sdk, Logger, utils, showErrorDialog, Toast,
    },
  }) => {
    try {
      await sdk.faces.remove(id, force);
      Toast(l18n.TOAST.PERSON_DELETED);
      return id;
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus !== 404) {
        const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not remove person';
        showErrorDialog(errorMessage);
        Logger.error(
          new Error('Error face removing'),
          { error },
          ['RemoveInboxFailed', errorMessage || 'NoMessage'],
        );
        throw error;
      } return id;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      remove.pending,
      (state, { meta }) => {
        state.all = state.all.map((face) => {
          if (face._id === meta.arg) {
            return { ...face, isBusy: true };
          } return face;
        });

        const face = state.all.find((face) => face._id === meta.arg.id);

        if (face.unnamed) {
          state.tree = {
            ...state.tree,
            unnamed: {
              ...state.tree.unnamed,
              nodes: state.tree.unnamed.nodes.map((node) => {
                if (node._id === face._id) {
                  return {
                    ...node, isBusy: true,
                  };
                } return node;
              }),
            },
          };
        } else {
          state.tree = {
            ...state.tree,
            allFaces: {
              ...state.tree.allFaces,
              nodes: state.tree.allFaces.nodes.map((node) => {
                if (node._id === face._id) {
                  return {
                    ...node, isBusy: true,
                  };
                } return node;
              }),
            },
          };
        }
        if (face.isFavorite) {
          state.tree = {
            ...state.tree,
            favorites: {
              ...state.tree.favorites,
              nodes: state.tree.favorites.nodes.map((node) => {
                if (node._id === face._id) {
                  return {
                    ...node, isBusy: true,
                  };
                } return node;
              }),
            },
          };
        }
      },
    )
    .addCase(
      remove.fulfilled,
      (state, { meta }) => {
        const face = state.all.find((face) => face._id === meta.arg.id);

        state.all = state.all.filter((face) => {
          if (face._id !== meta.arg.id) {
            return { ...face, isBusy: false };
          }
        });

        if (face.unnamed) {
          state.tree = {
            ...state.tree,
            unnamed: {
              ...state.tree.unnamed,
              nodes: state.tree.unnamed.nodes.filter((node) => {
                if (node._id !== face._id) {
                  return {
                    ...node, isBusy: false,
                  };
                }
              }),
            },
          };
        } else {
          state.tree = {
            ...state.tree,
            allFaces: {
              ...state.tree.allFaces,
              nodes: state.tree.allFaces.nodes.filter((node) => {
                if (node._id !== face._id) {
                  return {
                    ...node, isBusy: false,
                  };
                }
              }),
            },
          };
        }
        if (face.isFavorite) {
          state.tree = {
            ...state.tree,
            favorites: {
              ...state.tree.favorites,
              nodes: state.tree.favorites.nodes.filter((node) => {
                if (node._id !== face._id) {
                  return {
                    ...node, isBusy: false,
                  };
                }
              }),
            },
          };
        }
      },
    )
    .addCase(
      remove.rejected,
      (state, { meta, error }) => {
        state.all.forEach((face) => {
          if (face._id === meta.arg) delete face.isBusy;
        });
        state.error = error;
      },
    );
};
