import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import TYPES from '../../action-types';

const getWatermarks = () => async (dispatch) => {
  try {
    const { data: watermarks } = await sdk.watermarks.getAll();
    dispatch({
      type: TYPES.ASSETS.FETCH_WATERMARKS,
      payload: {
        watermarks,
      },
    });
  } catch (error) {
    Logger.error(new Error('Error fetching watermarks'), { error, showDialog: false }, [
      'GetWatermarksFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};

export default getWatermarks;
