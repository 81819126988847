import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSort } from '@picsio/icons';
import { permissions as dbPermissions } from '@picsio/db/src/constants';
import _debounce from 'lodash/debounce';

import Logger from '../../services/Logger';
import l10n from '../../shared/strings';
import SearchBar from '../SearchBar';
import defaultSorts from '../SearchBar/defaultSorts';
import { updateUserSortType } from '../../store/actions/user';
import { applySearch as applySearchAction } from '../../store/actions/collections';

const DEFAULT_SORT_TYPE = {
  type: 'name',
  order: 'asc',
};

const SORT_ITEMS = [
  ...defaultSorts,
  {
    id: 'custom',
    text: 'Custom',
    icon: <CustomSort />,
    disabled: false,
  },
];

const makeSortItems = (baseSort, hasPermission, items = SORT_ITEMS) => items.map((item) => {
  if (item.id !== 'custom') return item;

  let tooltip = null;
  const disabled = !baseSort;
  if (disabled) {
    tooltip = hasPermission
      ? 'Just drag any collection to make custom sort'
      : 'No custom sorting created. Contact your team admin to set it up.';
  }

  return {
    ...item,
    disabled,
    tooltip,
  };
});

export default function Search() {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.collections.search?.query || '');
  const openedTree = useSelector((state) => state.main.openedTree);
  const sortType = useSelector((state) => state.user.collectionsSortType || DEFAULT_SORT_TYPE);
  const customSortBase = useSelector((state) => state.user.team.collectionsCustomSortBase);
  const permissionForCustomSort = useSelector((state) => state.user.role.permissions[dbPermissions.manageCollectionsCustomSort]);

  const [items, setItems] = useState(makeSortItems(customSortBase, permissionForCustomSort));

  useEffect(() => {
    setItems((prevItems) => makeSortItems(customSortBase, permissionForCustomSort, prevItems));
  }, [customSortBase, permissionForCustomSort]);

  const applySearch = useCallback((...params) => {
    dispatch(applySearchAction(...params));
  }, [dispatch]);

  const setSort = useCallback((sort) => {
    if (items.find(({ id }) => sort.type === id)?.disabled) return;

    const newSortType = { ...sort };
    /** for CUSTOM sort -> order is only ASC */
    if (newSortType.type === 'custom') newSortType.order = 'asc';

    dispatch(updateUserSortType({
      collectionType: 'collections',
      sortType: newSortType,
    }));
    Logger.log('User', 'CollectionsPanelApplyCollectionSorting', {
      sortType: newSortType.type,
      order: newSortType.order,
    });
  }, [dispatch, items]);

  return (
    <SearchBar
      applySearch={_debounce(applySearch, 300)}
      placeholder={l10n.TAGSTREE.placeholderSearch}
      defaultValue={query}
      openedTree={openedTree}
      sortType={sortType}
      setSort={setSort}
      sortItems={items}
    />
  );
}
