/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deletePreset = createAsyncThunk(
  'websiteSettings/deletePreset',
  // eslint-disable-next-line consistent-return
  async (presetId, {
    extra: {
      Logger, sdk, utils, showErrorDialog,
    },
  }) => {
    try {
      await sdk.websiteSettingsPresets.delete(presetId);
      Logger.log('User', 'WebsitePresetDeleted', { preset_id: presetId });
      return presetId;
    } catch (error) {
      Logger.log('User', 'WebsitePresetsNotDeleted');
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not be delete preset';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error delete website preset'), { error }, [
        'DeleteWebsitePresetFailed',
        'Website presets can not be deleted from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      deletePreset.pending,
      (state, { meta }) => {
        state.presets.forEach((preset) => {
          if (preset._id === meta.arg) {
            preset.isBusy = true;
          }
        });
      },
    ).addCase(
      deletePreset.fulfilled,
      (state, { payload }) => {
        state.presets = state.presets.filter(({ _id }) => _id !== payload);
        state.error = null;
      },
    ).addCase(
      deletePreset.rejected,
      (state, { meta, error }) => {
        state.presets.forEach((preset) => {
          if (preset._id === meta.arg) delete preset.isBusy;
        });
        state.error = error;
      },
    );
};
