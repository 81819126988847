const getInitialSortType = (collectionSort = {}, websiteSort) => {
  const { type: collectionSortType, order: collectionSortOrder } = collectionSort;
  const { type: websiteSortType, order: websiteSortOrder } = websiteSort;
  const sortType = {};

  if (collectionSortType === 'custom') {
    sortType.type = collectionSortType;
  } else if (collectionSortType && collectionSortOrder) {
    sortType.type = collectionSortType;
    sortType.order = collectionSortOrder;
  } else {
    sortType.type = websiteSortType;
    sortType.order = websiteSortOrder;
  }

  return sortType;
};

export default getInitialSortType;
