import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Get qualities for video
 * @param {string} assetId
 * @param {string?} revisionId
 * @returns Promise<AxiosResponse>
 */
export default function fetchVideoQualities(assetId, revisionId = 'head') {
  if (picsioConfig.isMainApp) {
    return sdk.assets.fetchVideoQualities(assetId, revisionId);
  }
  return sdk.assets.fetchVideoQualitiesPublic(
    window.websiteConfig.alias,
    assetId,
    revisionId,
  );
}
