/**
 * Convert subtitle to VTT string
 * @param {array} sub
 */

function sub2vtt(sub) {
  return (
    `WEBVTT\n\n${
      sub
        .map((item, index) => `${index + 1}\n${item.start} --> ${item.end}\n${item.text}`)
        .join('\n\n')}`
  );
}

/**
 * Convert subtitle to SRT string
 * @param {array} sub
 */

function sub2srt(sub) {
  return sub
    .map((item, index) => `${index + 1}\n${item.start.replace('.', ',')} --> ${item.end.replace('.', ',')}\n${item.text}`)
    .join('\n\n');
}

/**
 * Create transcriptions file
 * @param {array} subtitle
 * @param {string} type
 */

const createSubFileLink = (subtitle, type) => {
  let text = '';
  switch (type) {
  case 'vtt':
    text = sub2vtt(subtitle);
    break;
  case 'srt':
    text = sub2srt(subtitle);
    break;
  default:
    break;
  }
  const url = URL.createObjectURL(new Blob([text]));
  return url;
};

export default createSubFileLink;
