const getWebsiteCustomFields = (customFieldsList, allowedFieldsList) => {
  const allowedFieldsTitles = allowedFieldsList.filter(({ show }) => show).map(({ title }) => title);
  return customFieldsList.reverse().reduce((result, field) => {
    if (allowedFieldsTitles.includes(field.title)) {
      result.unshift(field);
    }
    if (field.type === 'separator' && result.length && result[0].type !== 'separator') {
      result.unshift(field);
    }
    return result;
  }, []);
};

export default getWebsiteCustomFields;
