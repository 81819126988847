import * as userActions from '../../reducers/user';
import Logger from '../../../services/Logger';
import sdk from '../../../sdk';

export const deleteAvatar = () => async (dispatch) => {
  try {
    dispatch(userActions.updateUserStart({ updating: true }));
    await sdk.users.deleteAvatar();
    dispatch(userActions.updateUserSuccess({ result: { avatar: null } }));
    const customEvent = new CustomEvent('userAvatarSet', { detail: null });
    window.dispatchEvent(customEvent);
  } catch (err) {
    Logger.error(new Error('Can not delete user avatar'), { error: err }, [
      'UpdateUserFailed',
      (err && err.message) || 'Can not delete avatar',
    ]);
    dispatch(userActions.updateUserFailure());
  }
};

export default deleteAvatar;
