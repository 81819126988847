/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import normalizeRoles from '../helpers/roles/normalizeRoles';

export const getRoles = createAsyncThunk(
  'roles/get',
  async (_, { extra: { sdk } }) => {
    const { data } = await sdk.roles.getAll();
    return normalizeRoles(data);
  },
);

export const reducer = (builder) => builder.addCase(
  getRoles.pending,
  (state) => {
    state.loading = true;
    state.error = null;
  },
).addCase(
  getRoles.fulfilled,
  (state, { payload }) => {
    state.loading = false;
    state.items = payload;
  },
).addCase(
  getRoles.rejected,
  (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
);
