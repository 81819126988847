import TYPES from '../../action-types';
import sdk from '../../../sdk';

import getThumbnails from './getThumbnails';

/**
 * Get assets by ids
 * @param {string[]} ids - assets ids
 */
const getAssetsByIds = (ids) => async (dispatch, getAll) => {
  try {
    dispatch({ type: TYPES.ASSETS.GET_BY_IDS.START });
    const { data } = await sdk.assets.getMany(ids);

    const _ids = data.assets.map((asset) => asset._id);
    if (_ids.length) getThumbnails(_ids)(dispatch, getAll);

    dispatch({
      type: TYPES.ASSETS.GET_BY_IDS.COMPLETE,
      payload: {
        items: data.assets,
      },
    });
  } catch (error) {
    dispatch({ type: TYPES.ASSETS.GET_BY_IDS.FAILED, error });
  }
};

export default getAssetsByIds;
