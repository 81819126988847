import { createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Retry importing collection
 * @param {string} collectionId
 */
const retryImporting = createAsyncThunk(
  'collections/retryImporting',
  async (collectionId, {
    extra: {
      sdk, Logger, Toast, localization,
    },
  },
  ) => {
    try {
      await sdk.collections.retryImporting(collectionId);
    } catch (error) {
      Logger.error(new Error('Retry importing collection error'), { error }, [
        'RetryImportingCollectionFailed',
        (error && error.message) || 'NoMessage',
      ]);
      Toast(localization.TOAST.RETRY_IMPORT_COLLECTION_FAILED, {
        autoClose: false,
        closeButton: false,
        type: 'failed',
      });
    }
  },
);

export default retryImporting;
