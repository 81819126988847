import _isObject from 'lodash/isObject';
import picsioConfig from '../../../../config';
import CONSTANTS from '../shared/constants';

const fbEvents = {
  AppStarted: {
    name: 'Lead',
    isLabelAllowed: false,
  },
  SubscriptionChanged: {
    name: 'Subscribe',
    isLabelAllowed: true,
  },
};
const sendFBEvent = (action, label) => {
  const eventName = fbEvents[action].name;
  const eventLabel = fbEvents[action].isLabelAllowed && label;

  let url = `https://www.facebook.com/tr?id=${CONSTANTS.facebook.id}&ev=${eventName}`;
  if (eventLabel) url += `&cd[value]=${eventLabel}`;

  const pixel = new Image(1, 1);
  pixel.src = url;
};
/**
 * Emit Facebook Event
 * @param {string} action
 * @param {string} label
 */
const emitFBEvent = (action, label) => {
  if (!action) throw new Error('action is required');
  if (!fbEvents[action]) throw new Error(`action "${action}" is undefined`);

  sendFBEvent(action, label);
};

/**
 * Tracking Service
 * This service tracks user actions and push events to GoogleAnalytics
 */
const TrackingService = {
  push(category, action, label, value, nonInteraction) {
    // replace category with overrided value if setted before
    // need for using events with correct category from different plugins
    const eventLabel = _isObject(label) ? JSON.stringify(label) : label;

    const sendValue = typeof value === 'number' ? value : undefined;

    const getCLientID = () => {
      try {
        const tracker = window.ga.getAll()[0];
        return tracker.get('clientId');
      } catch (e) {
        console.warn('Can not get google Client ID', e);
        return undefined;
      }
    };

    if (picsioConfig.env === 'production') {
      const trackingParams = {
        event_category: category,
        event_label: `${eventLabel}`,
        value: sendValue,
        non_interaction: Boolean(nonInteraction),
        client_id: getCLientID(),
        api_secret: 'tQ_pHpAtRsCOumtf2UCCIg',
        measurement_id: 'G-DRL97KHPF7',
      };
      window.gtag('event', action, trackingParams);

      // throw event to Facebook
      if (Object.keys(fbEvents).includes(action)) emitFBEvent(action, eventLabel);
    }
  },
};

export default TrackingService;
