/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import setRequiredFieldErrors from './helpers/setRequiredFieldErrors';
import validateFields from './helpers/validateFields';

// The purpose of using createAsyncThunk here is to get returning value at the place of dispatching, and use this value immediately before store will be updated;
export const preUploadValidation = createAsyncThunk(
  'import/preUploadValidation',
  async (_, { getState }) => {
    let result = {};
    Object.values(getState().import.items).forEach((files) => {
      files.forEach((file) => {
        const { errors } = validateFields(
          {
            fields: file.fields,
            requiredFields: getState().import.requiredFields,
            editKeywordsPermission: file?.collection?.permissions?.editAssetKeywords,
            importFrom: file.file?.importFrom,
          },
        );
        result = {
          ...result, ...errors,
        };
      });
    });
    return !Object.values(result).filter(Boolean).length;
  },
);

export const reducer = (builder) => {
  builder.addCase(
    preUploadValidation.fulfilled,
    (state) => {
      state.isUploadSubmitted = true;
      setRequiredFieldErrors(state);
    },
  );
};
