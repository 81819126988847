import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from '@picsio/ui';
import { Subtitle } from '@picsio/icons';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';
import Tooltip from '../Tooltip';
import { navigate } from '../../helpers/history';

function Transcription({ assetId }) {
  const handleClickItem = (event) => {
    event.preventDefault();
    Logger.log('User', 'ThumbnailTranscriptClick');
    navigate(`preview/${assetId}#transcription`);
  };
  return (
    <Tooltip content={localization.CATALOG_ITEM.tooltipGoToTranscription} placement="top">
      <div
        className={cn('catalogItem__transcription')}
        onClick={handleClickItem}
        onKeyPress={handleClickItem}
        tabIndex={0}
        role="button"
      >
        <Icon size="md">
          <Subtitle />
        </Icon>
      </div>
    </Tooltip>
  );
}

Transcription.propTypes = {
  assetId: PropTypes.string.isRequired,
};

export default memo(Transcription);
