import { createRoot } from 'react-dom/client';
import Dialog from './DialogWrapper';
import Logger from '../../services/Logger';

export { default } from './DialogWrapper';

function logEvent(ttl, txt) {
  try {
    const title = typeof ttl === 'string' ? ttl : '[ unknown title ]';
    const text = typeof txt === 'string' ? txt : '[ text is not a string]';
    Logger.log('UI', 'DialogShow', { title, text });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Can not log event: ', e?.message || '[ error has no massage ]');
  }
}

/** Show dialog
 * @param {Object} data - data for Dialog
 */
export function showDialog(data) {
  logEvent(data?.title, data?.text);

  const $wrapper = document.querySelector('.wrapperDialog');
  const root = createRoot($wrapper);
  Dialog({
    parentEl: $wrapper,
    data,
    root,
  });
  const close = () => {
    if ($wrapper) root.unmount();
  };

  return close;
}

export function showDialogAsync(data) {
  logEvent(data?.title, data?.text);

  const $wrapper = document.querySelector('.wrapperDialog');
  const root = createRoot($wrapper);
  const close = () => {
    if ($wrapper) root.unmount();
  };

  return {
    close,
    promise: new Promise((resolve, reject) => {
      Dialog({
        parentEl: $wrapper,
        root,
        data: {
          ...data,
          onOk: async (...args) => {
            if (typeof data?.onOk === 'function') await data.onOk(...args);
            resolve(...args);
          },
          onCancel: () => {
            if (typeof data?.onCancel === 'function') data.onCancel();
            reject();
          },
        },
      });
    }),
  };
}

/** Show error dialog
 * @param {string} text
 * @param {string?} title
 * @returns {undefined}
 */
export function showErrorDialog(text, title = 'Error') {
  showDialog({ title, text, textBtnCancel: null });
}

/** Show alert dialog
 * @param {string} text
 * @param {string?} title
 * @returns {undefined}
 */
export function showInfoDialog(text, title = 'Info') {
  showDialog({ title, text, textBtnCancel: null });
}
