import React from 'react'; //eslint-disable-line
import { createRoot } from 'react-dom/client';
import Q from 'q';
import ErrorBoundary from '../ErrorBoundary';
import getThumbnailUrls from '../../helpers/getThumbnailUrls';

import Dialog from './views/index'; // eslint-disable-line
import { showErrorDialog } from '../dialog';
import l18n from '../../shared/strings';

/** Resolve duplicates dialog
 * @param {Function?} onCancel - on press "Cancel" button
 */
export default function (user, onCancel = () => { }) {
  const parentEl = document.querySelector('.wrapperDuplicatesDialog');

  // TODO: server should return duplicates list with links to local uploading files
  // client mights generate temporary cid to match files on server
  const resolve = function (duplicates, listFiles, showRenameOption = false, showAddRevision = true) {
    const dfd = Q.defer();

    const tempDuplicates = [...duplicates];
    const tempListFiles = [...listFiles];

    const resolveOne = (firstIteration = false) => {
      const root = createRoot(parentEl);
      const close = () => {
        if (parentEl) {
          root.unmount();
          if (onCancel) onCancel();
        }
      };
      const handleCancel = () => {
        dfd.reject({ status: 'CANCELED' });
      };

      const duplicate = tempDuplicates.shift();
      /**
       * On press "Ok"
       * @param {string} actionName
       * @param {boolean} applyToAll
       */
      const onOk = (actionName, applyToAll) => {
        /** duplicateItem -> asset that found on the server */
        const handler = (duplicateItem, handlingFirstIteration = false) => {
          /** find in the uploadItems */
          const originalItem = tempListFiles.find(
            (item) => {
              const itemCollectionPathSplitted = item?.path?.split('/');
              let itemCollectionPath;
              if (itemCollectionPathSplitted?.length) {
                itemCollectionPathSplitted.pop();
                itemCollectionPathSplitted.shift();
                itemCollectionPath = itemCollectionPathSplitted.join('/');
              }
              return (
                /** check by name */
                item.name.toLowerCase() === duplicateItem.name.toLowerCase()
                /** check by collections (if uploadItem deepPath exists) */
                && (!itemCollectionPath || (duplicateItem.tags || []).some(
                  ({ path }) => path.toLowerCase() === itemCollectionPath.toLowerCase(),
                ))
              );
            },
          );
          if (originalItem) {
            originalItem.action = actionName;
            originalItem.duplicatedModel = duplicateItem;
          } else {
            showErrorDialog(
              l18n.DIALOGS.ERROR_RESOLVE_DUPLICATE.TEXT(duplicateItem.name, duplicateItem._id),
            );
          }
          // remove (lodash) deletes duplicates with same ids, which will not work for move on s3,
          // because of the new logic of handling duplicates in clipboard there can be more than one duplicate with exact id
          // for more information about duplicates check findAndResolveDuplicates.js file

          // remove(tempDuplicates, (asset) => asset._id === duplicateItem._id);

          if (applyToAll && !handlingFirstIteration) {
            const tempDuplicatesIndex = tempDuplicates.findIndex((tempDuplicate) => tempDuplicate._id === duplicateItem._id);
            if (tempDuplicatesIndex > -1) {
              tempDuplicates.splice(tempDuplicatesIndex, 1);
            }
          }
        };

        handler(duplicate, firstIteration);
        if (applyToAll) {
          tempDuplicates
            .slice()
            .reverse()
            .forEach((duplicatedItem) => {
              handler(duplicatedItem, false);
            });
        }
        tempDuplicates.length ? resolveOne() : dfd.resolve(listFiles);
      };

      const hasExternalAssets = duplicates.some(({ source }) => !!source);
      root.render(
        <ErrorBoundary className="errorBoundaryOverlay">
          <Dialog
            fileInfo={duplicate}
            submit={onOk}
            close={close}
            onCancel={handleCancel}
            user={user}
            showRenameOption={showRenameOption}
            showAddRevision={showAddRevision}
            getThumbnailUrls={getThumbnailUrls.bind(null, [duplicate._id])}
            showApplyAll={duplicates.length > 0 && !hasExternalAssets}
          />
        </ErrorBoundary>,
      );
    };

    resolveOne(true);
    return dfd.promise;
  };

  return {
    resolve,
  };
}
