import TYPES from '../../action-types';

/**
 * Change path
 * @param {string} oldPath
 * @param {string} newPath
 */
const changePath = (oldPath, newPath, collectionName) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.CHANGE_PATH,
    payload: { oldPath, newPath, collectionName },
  });
};

export default changePath;
