/**
 * Merge notification
 * @param {Object[]} items
 * @param {string} mergedNotification
 * @returns {Object} items
 */
const mergeNotification = (items, mergedNotification) => {
  let newItems = items;
  newItems.splice(0, 1, mergedNotification);
  newItems = [...newItems];
  return newItems;
};

export default mergeNotification;
