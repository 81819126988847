import TYPES from '../../action-types';

/**
 * Change color on assets immediately
 * @param {string[]} ids
 * @param {string} value
 */
const changedColor = (ids, value, userId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.CHANGE_COLOR.COMPLETE,
    payload: {
      ids,
      keys: ['color'],
      values: [value],
      eventType: 'color',
      userId,
    },
  });
};

export default changedColor;
