/* eslint-disable no-param-reassign */
import { NEW_PATH_DELIMITER } from '@picsio/db/src/constants';
import { renamed, rename as renameAction } from '../keywords/actions';

export default function rename(builder) {
  builder.addMatcher(
    (action) => [renamed.type, renameAction.fulfilled.type].includes(action.type),
    (state, { payload }) => {
      if (!payload) return;

      state.items.forEach((item) => {
        if (item._id === payload._id) {
          const path = item.path.split(NEW_PATH_DELIMITER);
          path[path.length - 1] = payload.name;
          item.path = path.join(NEW_PATH_DELIMITER);
          item.name = payload.name;
        }
      });
    },
  );
}
