import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Set userOrientaion field to assets
 * @param {string[]} assetIds
 * @param {Object} value
 * @param {number} value.rotation - one of: 0 | 90 | 180 | 270
 * @param {boolean} value.flipX
 * @param {boolean} value.flipY
 * @returns Promise<AxiosResponse>
 */
export default function setUserOrientation(assetIds, value) {
  if (picsioConfig.isMainApp) return sdk.assets.setUserOrientation(assetIds, value);

  return sdk.assets.setUserOrientationPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    value,
  );
}
