function updateLine(transcript, startTime, text) {
  return transcript.map((line) => {
    if (line.start === startTime) {
      return {
        ...line,
        text,
      };
    }
    return line;
  });
}

/**
 * Updating the transcription line at start time
 * @param {Object} assets,
 * @param {Object} payload,
 * @param {string} payload.assetId
 * @param {string} payload.revisionId
 * @param {string} payload.startTime
 * @param {string} payload.text
 * @returns {Array}
 */
export default function updateTranscriptLine(assets, payload) {
  const {
    assetId, revisionId, startTime, text,
  } = payload;

  const updatedAssets = assets.map((asset) => {
    if (asset._id === assetId) {
      const updatedTranscripts = asset.transcripts.map((currentTranscript) => {
        if (currentTranscript.revisionId === revisionId) {
          const updatedTranscript = updateLine(currentTranscript.transcript, startTime, text);
          return {
            ...currentTranscript,
            transcript: updatedTranscript,
          };
        }
        return currentTranscript;
      });

      return {
        ...asset,
        transcripts: updatedTranscripts,
      };
    }
    return asset;
  });

  return updatedAssets;
}
