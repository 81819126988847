import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Interweave } from 'interweave';
import localization from '../../../shared/strings';
import Tooltip from '../../Tooltip';

const CommentContent = (props) => {
  const {
    markers,
    text,
    approved,
    revNumber,
    content,
    onClickText,
    index,
    onEyeClick,
    isActive,
  } = props;

  let approveText;
  let approveClass;
  if (approved === false) {
    approveText = localization.HISTORY.disapproved;
    approveClass = 'disapproved';
  } else {
    approveText = localization.HISTORY.approved;
    approveClass = 'approved';
  }
  const handleOnEyeClick = () => {
    onEyeClick(index);
  };
  return (
    <div className="itemHistoryList__main__text">
      {markers.length > 0 && (
        <span className="markersComment">
          {localization.HISTORY.textAdded}
          <Tooltip
            content={
              isActive
                ? localization.HISTORY.textHideMarkers
                : localization.HISTORY.textShowMarkers
            }
            placement="top"
          >
            <span
              onClick={handleOnEyeClick}
              role="presentation"
            >
              <span
                className={cn({ opacity: !isActive })}
              >&nbsp;{localization.HISTORY.textVisualMark}
              </span>
              {markers.map(({ number }) => (
                <mark className={cn({ opacity: !isActive })} key={number}>{number}</mark>
              ))}
            </span>
          </Tooltip>
        </span>
      )}
      {text === '' && revNumber && (
        <div className="approveStatus">
          {approveText && <span className={approveClass}>{approveText}</span>}
          {revNumber && <span className="revision">&nbsp;{localization.HISTORY.textRevision} {revNumber}</span>}
        </div>
      )}
      <div onClick={onClickText}>
        {/* already sanitized in the upper level */}
        <Interweave content={content} />
      </div>
    </div>
  );
};

CommentContent.defaultProps = {
  markers: [],
  text: '',
  approved: null,
  revNumber: null,
  content: '',
  onClickText: null,
  index: null,
  onEyeClick: null,
  isActive: false,
};
CommentContent.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
    }),
  ),
  text: PropTypes.string,
  approved: PropTypes.bool,
  revNumber: PropTypes.number,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClickText: PropTypes.func,
  index: PropTypes.number,
  onEyeClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default CommentContent;
