import TYPES from '../../action-types';

/**
 * Change upload revision progress
 * @param {string} assetID
 * @param {number} value
 */
const changeUploadRevisionProgress = (assetID, value) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.CHANGE_UPLOAD_REVISION_PROGRESS,
    payload: {
      assetID,
      value,
    },
  });
};

export default changeUploadRevisionProgress;
