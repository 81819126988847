import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from '../../store';
import Logger from '../../services/Logger';
import WebsitePresetsDialog from './WebsitePresetsDialog';
import { getPresets } from '../../store/websiteSettings/actions';
import UiBlocker from '../../services/UiBlocker';
import l18n from '../../shared/strings';
import { navigate } from '../../helpers/history';

const websiteSettingsActions = bindActionCreators({ getPresets }, store.dispatch);

export default async function renderWebsitePresetsDialog({ collectionId, websitePresets }) {
  Logger.log('UI', 'ShowWebsitePresetsDialog', `${collectionId}`);

  const dialogRoot = document.getElementById('dialogRoot');
  const wrapperDialog = document.createElement('div');
  wrapperDialog.id = 'wrapperWebsitePresetsDialog';
  dialogRoot.appendChild(wrapperDialog);

  const parentEl = document.getElementById('wrapperWebsitePresetsDialog');

  let presets = websitePresets;
  const root = createRoot(parentEl);

  if (!presets) {
    UiBlocker.block(l18n.DIALOGS.PUBLISH_WEBSITE.SPINNER);
    try {
      const { payload } = await websiteSettingsActions.getPresets();
      presets = payload;
    } catch (error) {
      Logger.error(new Error('Error render website presets dialogs'), { error }, [
        'ErrorRenderWebsitePresetsDialogFailed',
        'Website presets dialog can not be render.',
      ]);
    } finally {
      UiBlocker.unblock();
    }
  }

  const handleCloseDialog = () => {
    Logger.log('User', 'WebsitePresetsDialogCancel');
    if (parentEl) root.unmount();
    dialogRoot.removeChild(wrapperDialog);
  };

  if (presets && presets.length !== 0) {
    root.render(
      <Provider store={store}>
        <WebsitePresetsDialog
          onClose={handleCloseDialog}
          collectionId={collectionId}
          presets={presets}
        />
      </Provider>,
    );
  } else {
    navigate(`/websites/${collectionId}?tab=main`);
  }
}
