import Logger from '../../../services/Logger';
import { showDialog } from '../../../components/dialog';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';

const CONFIRM_CHANGES_SIZE = 1;
const CONFIRM_CHANGES_MASS_SIZE = 50000;

/**
 * Notify user about changes more than CONFIRM_CHANGES_SIZE assets
 * @param {number} assetsLength
 * @param {Function} onOk
 * @param {Function?} onCancel
 */
function checkMultipleChanges(assetsLength, onOk, onCancel = Function.prototype) {
  const isDialogHidden = utils.getCookie('picsio.multipleChangesDialogHidden') || false;
  if (assetsLength > CONFIRM_CHANGES_MASS_SIZE) {
    Logger.log('UI', 'MassChangesInfoDialog');
    showDialog({
      title: localization.DETAILS.titleWarning,
      text: localization.DETAILS.textAreYouRealySure,
      textBtnCancel: null,
      textBtnOk: localization.DIALOGS.btnOk,
      onOk: () => {},
      onCancel,
    });
  } else if (assetsLength > CONFIRM_CHANGES_SIZE && !isDialogHidden) {
    Logger.log('UI', 'MultipleChangesConfirmDialog');

    showDialog({
      title: localization.DETAILS.titleConfirm,
      text: localization.DETAILS.textAreYouSure(assetsLength),
      textBtnCancel: localization.DIALOGS.btnCancel,
      textBtnOk: localization.DIALOGS.btnOk,
      checkbox: {
        label: localization.DETAILS.labelCheckboxDontShow,
      },
      onOk: (data) => {
        Logger.log('User', 'MultipleChangesConfirmDialogOk', data.checkbox);
        utils.setCookie('picsio.multipleChangesDialogHidden', data.checkbox);
        onOk();
      },
      onCancel: () => {
        Logger.log('User', 'MultipleChangesConfirmDialogCancel');
        onCancel();
      },
    });
  } else {
    onOk();
  }
}

export default checkMultipleChanges;
