import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import dayjs from 'dayjs';
import { DatePicker } from '@picsio/ui';
import ua from '../../ua';

import Icon from '../Icon';
import {
  Input, Textarea, Checkbox,
} from '../../UIComponents';
import InputDateRange from '../../UIComponents/inputDateRange';
import { datePickerPlaceholder, datePickerDateFormat } from '../../shared/dateLocale';
import Tooltip from '../Tooltip';

import { MultiSelect, Enum } from '../CustomFields';
import useCustomFieldSize from '../../helpers/components/hooks/useCustomFieldSize';
import { DIFFERENT_VALUES } from '../../store/import/helpers/groupFields';

const CustomField = (props) => {
  const {
    order,
    title,
    type,
    options,
    value,
    onChange,
    onRemove,
    disabled,
    multiple,
    isInputDateRange,
    showInput,
    required,
    error,
    position,
    selectPopperStyle = {},
    placeholder = '',
    highlightChanged,
  } = props;
  const { getInputSize, saveInputSize } = useCustomFieldSize(title);
  const isError = !!error;
  const iconClick = useCallback(
    () => !required && onRemove({ title }),
    [onRemove, title, required],
  );
  const iconTooltip = required ? 'Required fields' : null;
  const iconRender = useMemo(() => {
    /** Do not render "remove" button for inbox app */
    if (window.inbox && !required) return null;
    return (
      <Tooltip content={iconTooltip} placement="top">
        <i
          className={cn('removeCustomField', {
            inline: type === 'boolean' || !showInput,
            required,
          })}
          onClick={iconClick}
          role="presentation"
        >
          <Choose>
            <When condition={required}>
              <span>
                *
              </span>
            </When>
            <Otherwise>
              <Icon name="close" />
            </Otherwise>
          </Choose>
        </i>
      </Tooltip>
    );
  }, [iconClick, iconTooltip, required, showInput, type]);

  const handleOnChange = (date) => {
    if (ua.browser.isNotDesktop() || ua.isMobileApp()) {
      onChange(order, dayjs(date).isValid() ? dayjs(date).utc().format('YYYY-MM-DD') : date);
    } else {
      onChange(order, dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : date);
    }
  };

  const getProperDate = () => {
    const val = value === DIFFERENT_VALUES ? '' : value;
    if (ua.browser.isNotDesktop() || ua.isMobileApp()) {
      return dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD') : val;
    }
    return val ? dayjs(val).toDate() : '';
  };

  return (
    <div className="itemSearchFilters">
      <div className="labelItemSearchFilters">{title}</div>
      <If condition={showInput}>
        <div className="contentItemSearchFilters">
          {type === 'input' && (
            <Textarea
              value={value || ''}
              onChange={(e, val) => onChange(order, val)}
              disabled={disabled}
              placeholder={placeholder}
              error={isError}
              height={getInputSize()}
              defaultHeight={0}
              onResize={saveInputSize}
              changed={highlightChanged && value !== ''}
            />
          )}
          {type === 'int' && (
            <div>
              <Input
                value={value === DIFFERENT_VALUES || value === null ? '' : value}
                type="number"
                placeholder={placeholder}
                onChange={(e, val) => onChange(order, val)}
                disabled={disabled}
                error={isError}
                className={highlightChanged && value !== '' ? 'changed' : ''}
              />
            </div>
          )}
          {type === 'enum' && (
            <Choose>
              <When condition={multiple}>
                <MultiSelect
                  className="customSelect"
                  value={value === DIFFERENT_VALUES ? null : value}
                  options={options}
                  onChange={(selected, selectFunc, config) => onChange(order, selected, config)}
                  disabled={disabled}
                  placement="top"
                  error={isError}
                  multipleAttach
                  required={required}
                  position={position}
                  placeholder={placeholder}
                />
              </When>
              <Otherwise>
                <Enum
                  error={isError}
                  menuPlacement="top"
                  value={value === DIFFERENT_VALUES ? null : value}
                  customField={{ options, title: '' }}
                  onChange={(val) => onChange(order, val)}
                  selectPopperStyle={selectPopperStyle}
                  placeholder={placeholder}
                  changed={highlightChanged && value !== ''}
                />
              </Otherwise>
            </Choose>
          )}
          {type === 'date'
            && (
              <>
                <If condition={isInputDateRange}>
                  <InputDateRange
                    value={(value === DIFFERENT_VALUES ? '' : value) || 'any'}
                    onChange={(val) => onChange(order, val)}
                    disabled={disabled}
                  />
                </If>
                <If condition={!isInputDateRange}>
                  <DatePicker
                    isError={isError}
                    selected={getProperDate()}
                    onChange={handleOnChange}
                    popperClassName="some-custom-class"
                    popperPlacement="top-start"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay
                        // in viewport(even when input is scrolled out of view)
                        boundariesElement: 'scrollParent',
                      },
                    }}
                    datePickerPlaceholder={placeholder || datePickerPlaceholder}
                    dateFormat={datePickerDateFormat}
                    disabled={disabled}
                  />
                </If>
              </>
            )}
          {type === 'boolean' && (
            <Checkbox
              onChange={() => onChange(order, !value)}
              value={value === DIFFERENT_VALUES ? null : !!value}
              error={isError}
              disabled={disabled}
            />
          )}
          {(required || onRemove) && iconRender}
        </div>
      </If>
      <If condition={!showInput}>
        {iconRender}
      </If>
    </div>
  );
};

CustomField.defaultProps = {
  order: null,
  type: null,
  value: '',
  onChange: null,
  options: [],
  isInputDateRange: false,
  showInput: true,
  required: false,
  onRemove: null,
  disabled: false,
  multiple: false,
  error: null,
  selectPopperStyle: {},
  position: 'bottom',
  placeholder: '',
  highlightChanged: true,
};
CustomField.propTypes = {
  order: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  isInputDateRange: PropTypes.bool,
  showInput: PropTypes.bool,
  required: PropTypes.bool,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  error: PropTypes.string,
  selectPopperStyle: PropTypes.objectOf(PropTypes.func),
  position: PropTypes.string,
  placeholder: PropTypes.string,
  highlightChanged: PropTypes.bool,
};

export default CustomField;
