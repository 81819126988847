/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';
import setRequiredFieldErrors from './helpers/setRequiredFieldErrors';
import getFilesByIds from './helpers/getFilesByIds';
import groupFields from './helpers/groupFields';

export const setRequiredFields = createAction('import/setRequiredFields');

export const reducer = (builder) => {
  builder.addCase(
    setRequiredFields.type,
    (state, { payload: { role = {}, features = {} } }) => {
      const { comments: commentsAllowed, customFields: customFieldsAllowed } = features;
      const requiredFields = { ...role.requiredFields } || {};
      const meta = requiredFields.meta || {};

      /** Update required fields if user has old naming */
      if (requiredFields['title+description']) {
        delete requiredFields['title+description'];
        if (!('title' in requiredFields)) requiredFields.title = true;
        if (!('description' in requiredFields)) requiredFields.description = true;
      }

      /** Required custom fields */
      let requiredCustomFields = [];
      if (Object.keys(meta).length && customFieldsAllowed) {
        requiredCustomFields = Object.keys(meta).reduce((r, title) => {
          const value = meta[title];
          if (value) return [...r, title];
          return r;
        }, []);
      }

      /** set required fields */
      state.requiredFields = {
        comments: Boolean(requiredFields.comment && commentsAllowed),
        titleAndDescription: Boolean(requiredFields.title || requiredFields.description),
        title: Boolean(requiredFields.title),
        description: Boolean(requiredFields.description),
        keywords: Boolean(requiredFields.keywords),
        assignees: Boolean(requiredFields.assignees),
        flag: Boolean(requiredFields.flag),
        rating: Boolean(requiredFields.rating),
        color: Boolean(requiredFields.color),
        assetMarks: Boolean(requiredFields.flag || requiredFields.rating || requiredFields.color),
        customFields: requiredCustomFields,
      };

      /** validate files in the store */
      setRequiredFieldErrors(state);

      /** Update customFields on items */
      Object.values(state.items).forEach((group) => {
        group.forEach((file) => {
          const cfs = _cloneDeep(file.fields.customFields);
          const titles = Object.keys(cfs);
          titles.forEach((title) => {
            const value = cfs[title];
            /** remove custom field if it is empty and removed from required custom fields */
            if (value === null && !requiredCustomFields.includes(title)) delete cfs[title];
          });

          requiredCustomFields.forEach((title) => {
            /** If custom field is required but not already added -> add it */
            if (!titles.includes(title)) {
              cfs[title] = null;
            }
          });
          file.fields.customFields = cfs;
        });
      });

      const selectedItems = getFilesByIds(state.items, state.selected);
      state.fields = groupFields(selectedItems);
    },
  );
};
