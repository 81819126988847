import createSubFileLink from './createSubFileLink';

/**
 * Download transcriptions file
 * @param {array} subtitle
 * @param {string} type
 */

export default function downloadSubFile(subtitle, assetName, type) {
  const url = createSubFileLink(subtitle, type);
  const name = `${assetName}.${type}`;
  const elink = document.createElement('a');
  elink.style.display = 'none';
  elink.href = url;
  elink.download = name;
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
}
