/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';
import { sort } from '../helpers/keywords';

export const getByIds = createAsyncThunk(
  'keywordsList/getByIds',
  async (ids, {
    extra: {
      sdk, Logger, Toast, utils, localization: l18n,
    }, getState,
  }) => {
    try {
      const { notFetchedById } = getState().keywordsList;
      /** do not fetch keywords that already fetched with error (e.g. not found) */
      const idsToFetch = ids.filter((id) => !notFetchedById.includes(id));
      if (!idsToFetch.length) return null;

      const { data: keywords } = await sdk.keywords.getByIds(idsToFetch);

      return keywords;
    } catch (error) {
      Toast(l18n.KEYWORDS.errorLoadingSomeKeywords, { autoClose: false, type: 'error' });
      const connection = utils.getNavigatorConnectionInfo();
      Logger.error(new Error('Can not get keywords by IDS'), { error }, [
        'GetKeywordsByIdsFailed',
        { errorMessage: (error && error.message) || 'NoMessage', connection, ids },
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getByIds.fulfilled,
      (state, { payload: keywords }) => {
        if (!keywords) return;

        state.items = sort(_uniqBy([...state.items, ...keywords], '_id'), {
          type: 'name',
          order: 'asc',
        });
      },
    ).addCase(
      getByIds.rejected,
      (state, { meta }) => {
        state.notFetchedById.push(...meta.arg);
      },
    );
};
