/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_PATH_DELIMITER } from '@picsio/db/src/constants';
import TYPES from '../action-types';

import { updateKeywords, removeKeywords } from '../helpers/keywords';
import { PAGE_SIZE, search } from './search';
import { getSearchProps, reloadCurrentPage, setSearchRoute } from '../../helpers/history';

export const LIMIT_KEYWORDS_TO_DELETE = 1000;
export const deleteKeywords = createAsyncThunk(
  'keywords/delete',
  async (payload, {
    extra: {
      sdk, localization, Toast, Logger, showDialogAsync,
    }, getState, dispatch,
  }) => {
    const { selectedKeywords, all } = getState().keywords;
    const ids = payload?.length ? payload : selectedKeywords;

    if (!ids.length) return {};

    /** Show limit dialog */
    if (ids.length > LIMIT_KEYWORDS_TO_DELETE) {
      try {
        Logger.log('UI', 'LimitDeleteKW', { count: ids.length });
        const { TITLE, TEXT, CANCEL } = localization.DIALOGS.KEYWORDS_DELETE_LIMIT;
        const { promise } = showDialogAsync({
          title: TITLE(ids.length),
          text: TEXT(LIMIT_KEYWORDS_TO_DELETE),
          textBtnCancel: CANCEL,
          textBtnOk: null,
        });
        await promise;
        return {};
      } catch (error) {
      /** user press "Cancel" */
        Logger.log('User', 'ConfirmDeleteKWNo');
        return {};
      }
    }

    /** Show confirmation dialog */
    try {
      Logger.log('UI', 'ConfirmDeleteKW', { ids });
      const {
        TITLE, TEXT, OK, CANCEL,
      } = localization.DIALOGS.KEYWORDS_DELETE;
      const name = ids.length === 1
        ? (all.find((kw) => kw._id === ids[0]) || {}).path?.split(NEW_PATH_DELIMITER).pop()
        : null;
      const deletedKeywordsAsParent = all.reduce((acc, keyword) => {
        if (ids.includes(keyword._id)) {
          const isParent = all.some((item) => item.path.includes(keyword.path) && item._id !== keyword._id);
          if (isParent) {
            acc.push(keyword.name);
            return acc;
          }
        }
        return acc;
      }, []);
      const { promise } = showDialogAsync({
        title: TITLE(ids.length, ids.length === all.length),
        text: TEXT(name, ids.length, deletedKeywordsAsParent.length),
        textBtnCancel: CANCEL,
        textBtnOk: OK,
      });
      await promise;
    } catch (error) {
      /** user press "Cancel" */
      Logger.log('User', 'ConfirmDeleteKWNo');
      return {};
    }

    try {
      Logger.log('User', 'ConfirmDeleteKWYes', { keywordsIds: ids });
      const { data } = await sdk.keywords.delete(ids);
      const { removedKeywordIds } = data;

      const storeAssets = getState().assets.items || [];
      const assetsWithKeyword = storeAssets.filter((asset) => {
        const keywords = asset.keywords || [];
        return keywords.find((kw) => removedKeywordIds.includes(kw._id));
      });
      const assetsIds = assetsWithKeyword.map((asset) => asset._id);
      if (assetsIds.length) {
        dispatch({
          type: TYPES.ASSETS.DETACH_KEYWORD.COMPLETE,
          payload: {
            ids: assetsIds,
            keywordsIds: removedKeywordIds,
            userId: getState().user._id,
          },
        });
      }

      /** Remove deleted keywords from search params to update search results and avoid warnings */
      const searchParams = getSearchProps();
      if (searchParams.keywords && searchParams.keywords.some((keyword) => ids.includes(keyword))) {
        searchParams.keywords = searchParams.keywords.filter((keyword) => !ids.includes(keyword));
        setSearchRoute(searchParams, { shouldReplace: true });
      }

      /** Load next page of keywords if deleted more than half of the page */
      if (ids.length >= PAGE_SIZE / 2) dispatch(search({ nextPage: true }));

      return { ids };
    } catch (error) {
      Toast(localization.KEYWORDSTREE.errorKeywordsDeleting(), {
        autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
      });
      Logger.error(new Error('Can not delete keywords'), { error, ids }, [
        'DeleteKeywordsFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    deleteKeywords.pending,
    (state, { meta }) => {
      const ids = meta.arg?.length ? meta.arg : state.selectedKeywords;
      state.tree = updateKeywords(state.tree, ids, { isBusy: true });
    },
  )
  .addCase(
    deleteKeywords.fulfilled,
    (state, { payload, meta }) => {
      const { ids = [] } = payload;
      if (ids.length) {
        const { all, favorites, keywords } = removeKeywords(state.tree, state.all, ids);

        state.all = all;
        state.tree = { favorites, keywords };
        state.selectedKeywords = [];
      }
      if (meta.arg?.length) {
        state.tree = updateKeywords(state.tree, meta.arg, { isBusy: false });
      }
    },
  )
  .addCase(
    deleteKeywords.rejected,
    (state, { meta, error }) => {
      const ids = meta.arg?.length ? meta.arg : state.selectedKeywords;
      state.error = error;
      state.tree = updateKeywords(state.tree, ids, { isBusy: false });
    },
  );
