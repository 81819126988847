import PermissionsChecker from '@picsio/db/src/helpers/PermissionsChecker';
import { permissions as PERMISSIONS } from '@picsio/db/src/constants';
/**
 * Check upload permission to certain collection
 * @param {Object} role
 * @param {string} collectionPath
 * @returns {boolean}
 */
export default function checkPermissionToUpload(role, collectionPath) {
  const { upload } = PERMISSIONS;
  const permissionsChecker = new PermissionsChecker(role);
  return permissionsChecker.checkPermissionByPath(upload, collectionPath);
}
