import { createAsyncThunk } from '@reduxjs/toolkit';
import l18n from '../../../shared/strings';
import { reloadCurrentPage } from '../../../helpers/history';

const updateUserSearchSettings = createAsyncThunk(
  'user/updateSearchSettings',
  async (data, { extra }) => {
    try {
      await extra.sdk.users.update(data);
    } catch (error) {
      extra.Logger.error(new Error('Can not update user search settings'), { error }, [
        'UpdateUserFailed',
        error?.message || 'NoMessage',
      ]);
      extra.Toast(l18n.TOAST.UPDATE_USER_SEARCH_SETTINGS, { type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
    }
  },
);

export default updateUserSearchSettings;
