import picsioConfig from '../../../../../config';
import sdk from '../../sdk';
/**
 * Fetch video preview for asset
 * @param {string} assetId
 * @returns Promise<AxiosResponse>
 */
export default function fetchVideoThumbnail(assetId) {
  if (picsioConfig.isMainApp) return sdk.assets.fetchVideoThumbnail(assetId);

  return sdk.assets.fetchVideoThumbnailPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetId,
  );
}
