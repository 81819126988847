import TYPES from '../../action-types';

/**
 * unAssign user to assets immediately
 * @param {string[]} ids
 * @param {string} value
 */
const unAssignedUser = (ids, assigneeId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.UNASSIGN_USER.COMPLETE,
    payload: { assigneeId, ids, notify: true },
  });
};

export default unAssignedUser;
