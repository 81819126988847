import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Set title to assets
 * @param {string[]} assetIds
 * @param {string} value
 * @returns Promise<AxiosResponse>
 */
export default function setTitle(assetIds, value) {
  if (picsioConfig.isMainApp) return sdk.assets.setTitle(assetIds, value);

  return sdk.assets.setTitlePublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    value,
  );
}
