import React, {
  useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@picsio/ui';
import localization from '../../../shared/strings';
import getChildren from '../../../store/actions/collections/getChildren';
import showSelectFromTreeDialog from '../../../helpers/showSelectFromTreeDialog';
import Tag from '../../Tag';
import Logger from '../../../services/Logger';
import './styles.scss';

const CollectionsFilter = (props) => {
  const {
    onChange, removeCollection, selectedCollections,
  } = props;
  const [treeDropdownOpen, setTreeDropdownOpen] = useState(false);
  const collectionsStore = useSelector((state) => state.collections);
  const dispatch = useDispatch();

  const selectCollection = useCallback((collectionItems) => {
    setTreeDropdownOpen(!treeDropdownOpen);
    onChange(collectionItems);
  }, [onChange, treeDropdownOpen]);

  const addCollection = useCallback(() => {
    Logger.log('User', 'AdvancedSearchAddCollectionClicked');
    showSelectFromTreeDialog({
      title: localization.CHOOSE_COLLECTION,
      onOk: selectCollection,
      treeListItems: [collectionsStore?.collections?.my],
      onLoadChildren: (item) => dispatch(getChildren(item._id)),
      textBtnOk: localization.DIALOGS.btnOk,
      textBtnCancel: localization.DIALOGS.btnCancel,
      openedItems: selectedCollections?.map((selectedCollection) => selectedCollection._id),
      canCreateCollection: false,
      checkedItems: selectedCollections || [collectionsStore?.collections?.my],
      multipleSelection: true,
      warningText: localization.COLLECTION_SELECTION_EXCEEDED,
    });
  }, [collectionsStore?.collections?.my, dispatch, selectCollection, selectedCollections]);

  return (
    <div className="container">
      {selectedCollections?.map((selectedCollectionItem) => (
        <Tag
          type="collection"
          text={selectedCollectionItem.name.length > 50 ? `${selectedCollectionItem.name.slice(0, 50)}...` : selectedCollectionItem.name}
          onClose={() => removeCollection(selectedCollectionItem)}
        />
      ))}
      <Button
        buttonSize="default"
        color="primary"
        component="button"
        className="addCollectionButton"
        onClick={addCollection}
      >
        {localization.SEARCH.addCollectionButtonText}
      </Button>
    </div>
  );
};

CollectionsFilter.defaultProps = {
  selectedCollection: {
    name: '',
    id: '',
  },
};

CollectionsFilter.propTypes = {
  selectedCollection: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default CollectionsFilter;
