import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';

import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Change stars on assets
 * @param {string[]} ids
 * @param {number} value - must be from 0 to 5
 */
const changeRating = (ids, value) => async (dispatch, getAll) => {
  try {
    await asyncCheckMultipleChanges(ids.length);
  } catch (error) {
    /** User cancelled operation */
    return;
  }

  try {
    dispatch({
      type: TYPES.ASSETS.CHANGE_STARS.START,
    });

    await assetsHelpers.setRating(ids, value);

    dispatch({
      type: TYPES.ASSETS.CHANGE_STARS.COMPLETE,
      payload: {
        ids,
        keys: ['rating'],
        values: [value],
        userId: getAll().user._id,
      },
    });
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : localization.DETAILS.textCantUpdate('rating');
    Toast(errorMessage, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
    Logger.error(new Error('Error change rating'), { error }, [
      'ChangeRatingFailed',
      (error && error.message) || 'NoMessage',
    ]);
    dispatch({ type: TYPES.ASSETS.CHANGE_STARS.FAILED, error });
  }
};

export default changeRating;
