import _find from 'lodash/find';

/**
 * Get the active plan from the subscription plans.
 *
 * @param {Array} subscriptionPlans - The array of subscription plans.
 * @param {Array} billingPlans - The array of billing plans.
 * @returns {Object} - The active plan object.
 */
const getActivePlan = (subscriptionPlans, billingPlans) => {
  const activePlan = subscriptionPlans.reduce((acc, item) => {
    const { plan } = item;
    const {
      id, product, metadata, amount, interval,
    } = plan;
    const isBasePlan = metadata.type === 'regular';

    const billingPlan = _find(billingPlans, { planId: product }) || {};
    const { name } = billingPlan;

    let fullName = plan.name;
    if (name) {
      fullName = `${name} ${interval === 'month' ? '(Monthly)' : '(Yearly)'}`;
    }

    if (isBasePlan) {
      return {
        ...acc,
        plan: {
          id,
          product,
          name,
          fullName,
          metadata,
          interval,
          amount,
        },
      };
    }
    return acc;
  }, {});

  return activePlan;
};

export default getActivePlan;
