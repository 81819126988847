import picsioConfig from '../../../../../../config';
import Logger from '../../../services/Logger';

/**
 * For logged user sends restore password email.
 * @param success - [description]
 * @param failed - [description]
 */
const requestResetPassword = (email) => async () => {
  // "plus" sign needs to decode, because it has a semantic meaning in query string
  // https://stackoverflow.com/a/6855723/10263359
  const decodedEmail = email.replace('+', '%2B');
  const url = `${picsioConfig.apiBaseUrl}/requestResetEmail?email=${decodedEmail}`;
  // open support in new blank window
  window.open(url);
  Logger.log('User', 'ResetPassword', email);
};

export default requestResetPassword;
