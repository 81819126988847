import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Icon, Tooltip } from '@picsio/ui';
import { CheckIcon, Copy } from '@picsio/icons';
import styled from 'styled-components';
import Logger from '../../services/Logger';
import { Input } from '../../UIComponents';
import copyTextToClipboard from '../../helpers/copyTextToClipboard';
import makeAlias from '../../helpers/makeAlias';
import localization from '../../shared/strings';

const Website = (props) => {
  const { isOpen = true, toggleCollapseVisibility, website } = props;
  const [isCopied, setCopied] = useState(false);
  const { customGalleryTitle, logoUrl, alias } = website;

  const copyToClipboard = (e) => {
    Logger.log('User', 'CollectionInfoWebsiteCopyLink');
    // eslint-disable-next-line no-new
    const websiteURL = makeAlias(e.currentTarget.dataset.value);
    setCopied(true);
    copyTextToClipboard(websiteURL);
  };

  return (
    <Collapse
      fontSize="md"
      isOpen={isOpen}
      onClick={() => {
        toggleCollapseVisibility('website');
      }}
      title="Website"
      transition
    >
      <div className="PicsioCollapse__content--inner">
        <If condition={customGalleryTitle}>
          <div className="InfoPanel__Row">
            <span className="InfoPanel__Row--label">Title:</span>
            {customGalleryTitle}
          </div>
        </If>
        <If condition={logoUrl}>
          <div className="InfoPanel__Row">
            <span className="InfoPanel__Row--label">Logo:</span>
            <StyledWebsiteLogo src={logoUrl} alt="" />
          </div>
        </If>
      </div>
      <div className="fieldCopyTo">
        <Input value={alias} className="fieldCopyToUrl" />
        <Tooltip
          placement="top"
          content={isCopied ? localization.DETAILS.textLinkCopied : localization.DETAILS.textCopyLink}
        >
          <div
            className="picsioDefBtn picsioLinkForShare fieldCopyToBtn"
            data-value={alias}
            onClick={copyToClipboard}
          >
            <Icon>
              <Choose>
                <When condition={isCopied}>
                  <CheckIcon className="checkIcon" />
                </When>
                <Otherwise>
                  <Copy />
                </Otherwise>
              </Choose>
            </Icon>
          </div>
        </Tooltip>
      </div>
    </Collapse>
  );
};

Website.propTypes = {
  isOpen: PropTypes.bool,
  website: PropTypes.shape({
    customGalleryTitle: PropTypes.string,
    logoUrl: PropTypes.string,
    alias: PropTypes.string.isRequired,
  }).isRequired,
  toggleCollapseVisibility: PropTypes.func.isRequired,
};

export default memo(Website);

const StyledWebsiteLogo = styled.img`
  max-width: 200px;
  display: block;
  margin: 10px 0 22px;
`;
