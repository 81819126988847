import React from 'react';
import {
  bool, string, func, number, oneOfType,
  arrayOf, objectOf, any,
} from 'prop-types';
import dayjs from 'dayjs';
import cn from 'classnames';
import { ASYNC_JOB_STATUS_WAITING, ASYNC_JOB_STATUS_RUNNING } from '@picsio/db/src/constants';
import ua from '../../../../ua';

import picsioConfig from '../../../../../../../config';
import * as utils from '../../../../shared/utils';
import Logger from '../../../../services/Logger';
import { getCustomVideoUrl } from '../../../../helpers/assets';
import ToolbarPreviewLeft from '../../../toolbars/ToolbarPreviewLeft';

import getDownloadUrl from '../../../../helpers/getDownloadUrl';
import buildStreamProxyUrl from '../../../../helpers/assets/buildStreamProxyUrl';
import Marker from '../Marker';
import Player from './Player';
import Placeholder from '../Placeholder';
import { createSubFileLink } from '../../../../helpers/transcript';

const MP4 = 'video/mp4';

class Video extends React.Component {
  static isMainApp = picsioConfig.isMainApp;

  $theMediaFile = React.createRef();

  $videoWrapper = React.createRef();

  $markersWrapper = React.createRef();

  $player = React.createRef();

  state = {
    markersContainerStyles: { width: '100%', height: '100%' },
    videoData: null,
    diffVideoData: null,
    revisionCommentsWithMarkers: [],
    revisionsUploadedCount: 0,
  };

  componentDidMount() {
    this.renderVideo();
    window.addEventListener('resize', this.updateMarkersWrapper);
    window.addEventListener('preview:ui:resize', this.updateMarkersWrapper, false);
    window.addEventListener('revision:added', this.onAddRevision, false);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    /** Asset changed, revision or diff */
    if (
      props.model._id !== prevProps.model._id
      || props.revisionID !== prevProps.revisionID
      || props.diffID !== prevProps.diffID
    ) {
      this.renderVideo();
    }

    /**  Subtitle updates after transcribing and subtitle editing  */
    if (props.transcript !== prevProps.transcript) {
      const { transcript, transcriptLang } = this.props;
      this.updateTranscriptData(transcript, transcriptLang);
    }

    if (props.listenToClick) {
      document.addEventListener('mousedown', this.setMarker);
    } else {
      document.removeEventListener('mousedown', this.setMarker);
    }

    if (
      prevProps.commentsWithMarkers !== props.commentsWithMarkers
      || prevProps.revisionID !== props.revisionID
    ) {
      this.renderMarkers(
        props.commentsWithMarkers,
        props.headRevisionId,
        props.revisionID,
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('revision:added', this.onAddRevision, false);
    window.removeEventListener('resize', this.updateMarkersWrapper);
    window.removeEventListener('preview:ui:resize', this.updateMarkersWrapper, false);

    document.removeEventListener('mousedown', this.setMarker);
  }

  onAddRevision = () => {
    this.setState((prevState) => (
      { revisionsUploadedCount: prevState.revisionsUploadedCount + 1 }
    ));
    this.renderVideo();
  };

  /**
   * Mouse down on tmp marker
   * @param {MouseEvent} event
   * @param {Number} index
   */
  onMarkerMouseDown(e, index) {
    e.stopPropagation();

    const { tmpMarkers, modifyTmpMarkers } = this.props;
    const marker = tmpMarkers[index];

    if (marker) {
      const mousemoveHandler = (event) => {
        event.stopPropagation();
        const clientX = event.pageX;
        const clientY = event.pageY;
        const videoRect = this.$markersWrapper.current.getBoundingClientRect();

        /** if pointer outside the image - do nothing */
        if (
          !(
            clientX > videoRect.left
            && clientX < videoRect.left + videoRect.width
            && clientY > videoRect.top
            && clientY < videoRect.top + videoRect.height
          )
        ) {
          return;
        }

        const left = (clientX - videoRect.left) / videoRect.width;
        const top = (clientY - videoRect.top) / videoRect.height;

        /** if rectangle selection - move 2 points */
        if (marker.x2 !== undefined && marker.y2 !== undefined) {
          /** handle x and x2 */
          const newX2 = marker.x2 + left - marker.x;
          if (newX2 <= 1) {
            marker.x = left;
            marker.x2 = newX2;
          } else {
            marker.x = left + 1 - newX2;
            marker.x2 = 1;
          }

          /** handle y and y2 */
          const newY2 = marker.y2 + top - marker.y;
          if (newY2 <= 1) {
            marker.y2 = newY2;
            marker.y = top;
          } else {
            marker.y = top + 1 - newY2;
            marker.y2 = 1;
          }
        } else {
          /** if single marker */
          marker.x = left;
          marker.y = top;
        }

        modifyTmpMarkers(tmpMarkers);
      };
      const mouseupHandler = () => {
        document.removeEventListener('mousemove', mousemoveHandler);
        document.removeEventListener('mouseup', mouseupHandler);
      };

      document.addEventListener('mousemove', mousemoveHandler);
      document.addEventListener('mouseup', mouseupHandler);
    }
  }

  /**
   * Set marker from event click
   * @param {MouseEvent} event
   * @param {Number} event.clientX
   * @param {Number} event.clientY
   */
  // eslint-disable-next-line consistent-return
  setMarker = ({ clientX, clientY }) => {
    const videoRect = this.$markersWrapper.current.getBoundingClientRect();

    /* if pointer outside the video - do nothing */
    if (
      !(
        clientX > videoRect.left
         && clientX < videoRect.left + videoRect.width
         && clientY > videoRect.top
         && clientY < videoRect.top + videoRect.height
      )
    ) {
      return this.props.addMarker(null);
    }

    const left = (clientX - videoRect.left) / videoRect.width;
    const top = (clientY - videoRect.top) / videoRect.height;

    const markerData = {
      x: left,
      y: top,
      // createdAt: Date.now(),
      createdAt: dayjs(),
    };

    /** save marker */
    this.props.addMarker(markerData);
    document.removeEventListener('click', this.setMarker);
  };

  /**
   * Mouse down on tmp marker resize
   * @param {MouseEvent} e
   * @param {Number} index
   */
  onMarkerMouseDownResize = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const { tmpMarkers } = this.props;
    const marker = tmpMarkers[index];

    if (marker) {
      const mousemoveHandler = (e) => {
        e.stopPropagation();
        const clientX = e.pageX;
        const clientY = e.pageY;
        const videoRect = this.$markersWrapper.current.getBoundingClientRect();

        /* if pointer outside the image - do nothing */
        if (
          !(
            clientX > videoRect.left
            && clientX < videoRect.left + videoRect.width
            && clientY > videoRect.top
            && clientY < videoRect.top + videoRect.height
          )
        ) {
          return;
        }

        const left = (clientX - videoRect.left) / videoRect.width;
        const top = (clientY - videoRect.top) / videoRect.height;

        marker.x2 = marker.x > left ? marker.x : left;
        marker.y2 = marker.y > top ? marker.y : top;

        this.props.modifyTmpMarkers(tmpMarkers);
      };
      const mouseupHandler = () => {
        document.removeEventListener('mousemove', mousemoveHandler);
        document.removeEventListener('mouseup', mouseupHandler);
      };

      document.addEventListener('mousemove', mousemoveHandler);
      document.addEventListener('mouseup', mouseupHandler);
    }
  };

  getMarkerContainerStyles() {
    const markersContainerStyles = { width: '100%', height: '100%' };
    const $videoWrapper = this.$videoWrapper.current;

    if (!$videoWrapper) return markersContainerStyles;
    const $video = $videoWrapper.querySelector('video');
    if (!$video || !$video.videoHeight || !$video.videoWidth) return markersContainerStyles;

    const probablyWidth = ($video.videoWidth * $videoWrapper.offsetHeight) / $video.videoHeight;

    if (probablyWidth < $videoWrapper.offsetWidth) {
      markersContainerStyles.width = probablyWidth;
      markersContainerStyles.height = $videoWrapper.offsetHeight;
      markersContainerStyles.left = ($videoWrapper.offsetWidth - probablyWidth) / 2;
    } else {
      const height = ($video.videoHeight * $videoWrapper.offsetWidth) / $video.videoWidth;
      markersContainerStyles.width = $videoWrapper.offsetWidth;
      markersContainerStyles.height = height;
      markersContainerStyles.top = ($videoWrapper.offsetHeight - height) / 2;
    }
    return markersContainerStyles;
  }

  getUserId() {
    const { props } = this;
    return picsioConfig.isMainApp ? props.teamId : window.websiteConfig.userId;
  }

  updateMarkersWrapper = () => {
    /**   wait until right toolbar is closed/opened */
    setTimeout(() => {
      this.setState({
        markersContainerStyles: this.getMarkerContainerStyles(),
      });
    }, 0);
  };

  buildRevisionStreamProxyUrl = async (
    {
      _id, customVideo, storageId, mimeType, storageType,
    },
    userId,
    revisionId,
  ) => {
    if (customVideo) {
      const { data } = await getCustomVideoUrl(_id, revisionId);
      return data;
    }
    if (mimeType !== 'video/mp4') return;
    if (storageType !== 'gd') {
      /** no need head revision id for s3 */
      if (revisionId === 'head') revisionId = undefined;
      return getDownloadUrl({ assetId: _id, revisionId, allowDownloadByGS: false });
    }

    const { data } = await buildStreamProxyUrl(_id, storageId, revisionId);
    return data;
  };

  getOriginalSrc = async ({ _id, mimeType, customVideo }) => {
    if (!picsioConfig.isMainApp) return null;
    try {
      if (customVideo && mimeType !== MP4) {
        const { data } = await getCustomVideoUrl(_id);
        return data;
      }
      if (mimeType !== 'video/mp4') return null;
      return await getDownloadUrl({ assetId: _id, allowDownloadByGS: false });
    } catch (err) {
      return null;
    }
  };

  makeVideoData = async (asset, {
    src, name, revisionId, isCompare,
  } = {}) => {
    const {
      _id,
      storageId,
      fileExtension,
      mimeType,
      customVideo,
    } = asset;

    let transcriptLink;
    let transcriptLang;
    let transcript;

    if (this.props?.transcript && !revisionId) {
      transcript = this.props.transcript;
      transcriptLang = this.props.transcriptLang;
      transcriptLink = createSubFileLink(transcript, 'vtt');
    }

    if (isCompare && asset?.transcripts) {
      transcript = asset?.transcripts[asset?.transcripts.length - 1].transcript;
      transcriptLang = asset?.transcripts[asset?.transcripts.length - 1].language;
      transcriptLink = createSubFileLink(transcript, 'vtt');
    }

    if (revisionId && asset?.transcripts) {
      asset?.transcripts.forEach((item) => {
        if (item.revisionId === revisionId) {
          transcript = item.transcript;
          transcriptLang = item.language;
          transcriptLink = createSubFileLink(transcript, 'vtt');
        }
      });
    }

    const videoSrc = src
      || (await this.getOriginalSrc(asset))
      || (await this.buildRevisionStreamProxyUrl(asset, this.getUserId(), 'head'));
    return {
      _id,
      storageId,
      fileExtension,
      mimeType: customVideo ? MP4 : mimeType,
      src: videoSrc,
      name: name || asset.name,
      transcript: transcript || null,
      transcriptLang: transcriptLang || null,
      transcriptLink: transcriptLink || null,
    };
  };

   renderVideo = async () => {
     const {
       model,
       diffVideo,
       diffID,
       revisionID,
       activeRevisionNumber,
       diffRevisionNumber,
     } = this.props;
     let currentVideoSrc;
     /** Data for render */
     let data;

     /**  if revision is visible now, change src */
     const userId = this.getUserId();
     if (revisionID) {
       currentVideoSrc = await this.buildRevisionStreamProxyUrl(model, userId, revisionID);
     }

     if (diffVideo) {
       let diffSrc = null;
       let currentSrc = null;

       /** In Safari video compare not working with original link */
       if (ua.browser.isSafari()) {
         currentSrc = await this.buildRevisionStreamProxyUrl(model, userId, 'head');
         diffSrc = await this.buildRevisionStreamProxyUrl(diffVideo, userId, 'head');
       }

       const currentVideoData = await this.makeVideoData(model, { isCompare: true, src: currentSrc });
       const diffVideoData = await this.makeVideoData(diffVideo, { isCompare: true, src: diffSrc });

       data = [currentVideoData, diffVideoData];
     } else if (diffID) {
       const currentVideoData = await this.makeVideoData(model, {
         src: currentVideoSrc,
         name: `Revision ${activeRevisionNumber}`,
       });
       const diffIDSrc = await this.buildRevisionStreamProxyUrl(model, userId, diffID);
       const diffVideoData = await this.makeVideoData(model, {
         src: diffIDSrc,
         name: `Revision ${diffRevisionNumber}`,
         revisionId: diffID,
       });

       data = [currentVideoData, diffVideoData];
     } else {
       data = await this.makeVideoData(model, { src: currentVideoSrc });
     }

     if (Array.isArray(data)) {
       this.setState(
         { videoData: { ...data[0], timestamp: Date.now() }, diffVideoData: data[1], error: null },
         this.updateMarkersWrapper,
       );
       return data[0];
     } else {
       this.setState(
         { videoData: { ...data, timestamp: Date.now() }, diffVideoData: null, error: null },
         this.updateMarkersWrapper,
       );
       return data;
     }
   };

  updateTranscriptData = (transcript, transcriptLang) => {
    let transcriptLink;

    if (transcript) {
      transcriptLink = createSubFileLink(transcript, 'vtt');
    }

    this.setState((prevState) => ({
      videoData: {
        ...prevState.videoData,
        transcript,
        transcriptLang,
        transcriptLink,
      },
    }));
  }

  handleCanPlay = (error) => {
    if (error) {
      const { model } = this.props;
      // const isThumbnailing = model.updatedAt && Date.create(model.updatedAt).hoursAgo() === 0;
      const isThumbnailing = model.updatedAt && dayjs(model.updatedAt).diff(dayjs(), 'hour') === 0;

      const state = { isThumbnailing };
      if (!isThumbnailing && !ua.isMobileApp()) {
        const message = error.message || 'Can not play video';
        Logger.warn(new Error('Player: can not load video data'), { error, showDialog: true }, [
          'LoadVideoFailed',
          { assetId: model._id, message },
        ]);

        state.error = message;
      } else if (ua.isMobileApp()) {
        const message = error.message || 'Can not play video';
        Logger.warn(
          new Error(
            `Player: can not load video data in mobile ${utils.capitalizeFirstLetter(
              ua.getPlatform(),
            )}App`,
          ),
          { error, showDialog: true },
          ['LoadVideoInMobileAppFailed', { assetId: model._id, message }],
        );
      }
      this.setState(state);
    } else {
      this.setState({
        markersContainerStyles: this.getMarkerContainerStyles(),
      });
    }
    this.props.handleLoadedVideo();
  };

    getSnapshot = () => {
      this.$player.current.handleClickScreenshot();
    };

    createCustomThumbnail = () => {
      this.$player.current.handleClickCustomThumbnail();
    };

    cropVideo = () => {
      this.$player.current.toggleCrop();
    };

    renderMarkers(commentsWithMarkers, headRevisionId, activeRevisionId) {
      const revisionId = activeRevisionId || headRevisionId;
      const revisionCommentsWithMarkers = commentsWithMarkers.filter(
        ({ revisionID }) => revisionID === revisionId,
      );
      this.setState({ revisionCommentsWithMarkers });
    }

    render() {
      const { state, props } = this;
      const {
        markers,
        tmpMarkers,
        removeMarker,
        addRevision,
        handleDownload,
        moveToTrash,
        model,
        setCommentTimeRange,
        isCheckedAttachTime,
        showMarkersTrack,
      } = props;

      const isTranscoding = [ASYNC_JOB_STATUS_RUNNING, ASYNC_JOB_STATUS_WAITING].includes(
        model.transcoding,
      );

      if (state.error || (isTranscoding && model.mimeType !== MP4)) {
        return (
          <Placeholder
            model={model}
            isTranscoding={isTranscoding}
            handleDownload={handleDownload}
            moveToTrash={moveToTrash}
            addRevision={addRevision}
          />
        );
      }
      const isRestricted = utils.isAssetRestricted(props.model.restrictSettings);

      return (
        <div className="innerContainerMediaFile">
          {picsioConfig.isMainApp && !props.diffVideo && (
            <ToolbarPreviewLeft
              assetId={model._id}
              addRevision={addRevision}
              download={handleDownload}
              moveToTrash={moveToTrash}
              permissions={model.permissions}
              isRestricted={isRestricted}
              isRemoveForever={props.isRemoveForever}
              getSnapshot={this.getSnapshot}
              createCustomThumbnail={this.createCustomThumbnail}
              cropVideo={this.cropVideo}
              isVideoPlayer
              isArchived={model.archived}
            />
          )}
          <div className="theMediaFile" ref={this.$theMediaFile}>
            <div
              className="markersContainer"
              style={state.markersContainerStyles}
              ref={this.$markersWrapper}
            >
              {/* Comment markers */}
              {markers.length > 0 && showMarkersTrack
              && markers.map(
                (markersItem) => markersItem.markers.map(
                  (marker, index) => <Marker key={marker.number || index} marker={marker} />,
                ),
              )}
              {/* Tmp Markers */}
              {tmpMarkers.length > 0
              && tmpMarkers.map((marker, index) => (
                <Marker
                  key={marker.number}
                  marker={marker}
                  onRemove={() => removeMarker(index)}
                  onMouseDown={(event) => this.onMarkerMouseDown(event, index)}
                  onMouseDownResize={(event) => this.onMarkerMouseDownResize(event, index)}
                />
              ))}
            </div>
            <div
              className={cn('wrapperBeautyVideo', { compareVideos: state.diffVideoData })}
              ref={this.$videoWrapper}
            >
              <If condition={state.videoData}>
                <Player
                  ref={this.$player}
                  data={state.videoData}
                  diffData={state.diffVideoData}
                  revisionId={props.revisionID}
                  setCustomThumbnail={props.setCustomThumbnail}
                  revisionsUploadedCount={state.revisionsUploadedCount}
                  revisionCommentsWithMarkers={state.revisionCommentsWithMarkers}
                  markers={markers}
                  container={this.$theMediaFile.current}
                  onCanPlay={this.handleCanPlay}
                  toggleMarkers={props.toggleMarkers}
                  subscriptionFeatures={props.subscriptionFeatures}
                  reload={this.renderVideo}
                  setCommentTimeRange={setCommentTimeRange}
                  isCheckedAttachTime={isCheckedAttachTime}
                  commentsRange={props.commentsRange}
                  setVideoCurrentTime={props.setVideoCurrentTime ? props.setVideoCurrentTime : null}
                  poster={(props.isHeadRevisionId || props.headRevisionId === props.revisionID) && props.model.thumbnail}
                  diffPoster={props.diffVideo && props.diffVideo.thumbnail}
                  handleTranscriptionsPanel={props.handleTranscriptionsPanel}
                  showMarkersTrack={props.showMarkersTrack}
                  isShowHistoryPanel={props.isShowHistoryPanel}
                />
              </If>

            </div>
          </div>
        </div>
      );
    }
}

/** prop types */
Video.propTypes = {
  handleDownload: oneOfType([func, bool]),
  addRevision: oneOfType([func, bool]),
  moveToTrash: oneOfType([func, bool]),
  listenToClick: bool,
  handleLoadedVideo: func,
  setCommentTimeRange: func,
  addMarker: func,
  modifyTmpMarkers: func,
  removeMarker: func,
  setCustomThumbnail: func,
  toggleMarkers: func,
  setVideoCurrentTime: func,
  commentsRange: arrayOf(number),
  markers: arrayOf(any),
  tmpMarkers: arrayOf(any),
  commentsWithMarkers: arrayOf(any),
  transcript: arrayOf(objectOf(string)),
  subscriptionFeatures: objectOf(any),
  model: objectOf(any).isRequired,
  diffVideo: objectOf(any),
  revisionID: string,
  activeRevisionNumber: number,
  diffRevisionNumber: number,
  diffID: string, // diff storageId
  transcriptLang: string,
  headRevisionId: string,
  teamId: string,
  isCheckedAttachTime: bool,
  isRemoveForever: bool,
  handleTranscriptionsPanel: func,
  showMarkersTrack: bool,
};

/** default props */
Video.defaultProps = {
  handleLoadedVideo: Function.prototype,
  setCommentTimeRange: Function.prototype,
  addMarker: Function.prototype,
  modifyTmpMarkers: Function.prototype,
  removeMarker: Function.prototype,
  setCustomThumbnail: Function.prototype,
  toggleMarkers: Function.prototype,
  setVideoCurrentTime: Function.prototype,
  handleTranscriptionsPanel: Function.prototype,
  commentsRange: [0, 0],
  markers: [],
  tmpMarkers: [],
  commentsWithMarkers: [],
  transcript: null,
  subscriptionFeatures: {},
  revisionID: null,
  activeRevisionNumber: null,
  diffRevisionNumber: null,
  diffVideo: null,
  diffID: null,
  transcriptLang: null,
  headRevisionId: null,
  teamId: null,
  isCheckedAttachTime: null,
  isRemoveForever: null,
  showMarkersTrack: false,
};

export default Video;
