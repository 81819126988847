import Logger from '../../../services/Logger';
import isTwoFactorEnabled from '../../../helpers/isTwoFactorEnabled';

const initAmplitude = (user) => {
  const {
    parent,
    role,
    subscriptionFeatures,
    team = {},
    utmTracking = {},
    personalTwoFactorEnabled,
    twoFactorConfigured,
    teamTwoFactorMode,
    twoFactorMode,
  } = user;
  const { permissions } = role;

  Logger.clearUserProperties();

  const teamName = (team.policies && team.policies.teamName) || team.displayName;
  const roleName = !parent ? 'Team owner' : role.name;
  let roleGroup = {};
  roleGroup.role = !parent ? 'Team owner' : 'Teammate';
  const twoFactorEnabled = isTwoFactorEnabled(teamTwoFactorMode);

  const roleGroupsNames = {
    editCustomFieldsSchema: 'manageСustomFieldsSchema',
    manageArchive: 'manageArchive',
    manageAssetRestrictSettings: 'manageRestrictedAssets',
    manageBilling: 'manageBilling',
    manageInboxes: 'manageInboxes',
    manageIntegrations: 'manageIntegrations',
    manageKeywords: 'manageKeywords',
    manageLightboards: 'manageLightboards',
    manageStorage: 'manageStorage',
    manageTeam: 'manageTeam',
    manageTeamSavedSearches: 'manageSavedSearches',
    sync: 'manageSync',
  };

  roleGroup = Object.keys(permissions).reduce((acc, key) => {
    if (roleGroupsNames[key]) {
      roleGroup[roleGroupsNames[key]] = permissions[key];
    }
    return acc;
  }, roleGroup);

  const { picsioStorage, trialEnds } = team;
  let { storageType } = team;
  if (picsioStorage) {
    storageType = 'PicsioStorage';
  }

  const {
    assetsCount,
    autosyncInterval,
    collectionsCount,
    keywordsCount,
    teammatesCount,
    websitesCount,
    planName,
    planId,
  } = subscriptionFeatures;

  const userProperties = {
    trialEnds,
    team: team.email,
    teamName,
    roleName,
    storageType,
    assetsCount,
    collectionsCount,
    keywordsCount,
    teammatesCount,
    websitesCount,
    autosyncInterval,
    planName: planId || planName,
    ...roleGroup,
    ...utmTracking,
  };

  if (personalTwoFactorEnabled !== undefined) {
    userProperties.personalTwoFactorEnabled = personalTwoFactorEnabled;
  }
  if (twoFactorConfigured !== undefined) {
    userProperties.twoFactorConfigured = twoFactorConfigured;
  }
  if (twoFactorEnabled !== undefined) {
    userProperties.twoFactorEnabled = twoFactorEnabled;
  }
  if (twoFactorMode !== undefined) {
    userProperties.twoFactorMode = twoFactorMode;
  }

  Logger.setUserProperties(userProperties);
};

export default initAmplitude;
