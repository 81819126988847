import TYPES from '../../action-types';

import getDispatchTypeByAsyncStatus from './getDispatchTypeByAsyncStatus';

/**
 * Set moving asset status
 * @param {string[]} ids
 * @param {string} status
 */
const setMoving = (ids, status) => (dispatch) => {
  const type = getDispatchTypeByAsyncStatus(status);
  dispatch({
    type: TYPES.ASSETS.MOVING_ASSETS[type],
    payload: { ids },
  });
};

export default setMoving;
