import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Get qualities for video
 * @param {string} assetId
 * @param {string} storageId
 * @param {string?} revisionId
 * @returns Promise<AxiosResponse>
 */
export default function buildStreamProxyUrl(assetId, storageId, revisionId = 'head') {
  if (picsioConfig.isMainApp) {
    return sdk.assets.buildStreamProxyUrl(
      assetId,
      storageId,
      revisionId,
    );
  }

  return sdk.assets.buildStreamProxyUrlPublic(
    window.websiteConfig.alias,
    assetId,
    storageId,
    revisionId,
  );
}
