import TYPES from '../../action-types';

/**
 * Collection removed by someboby
 * @param {string} collectionID
 */
const collectionRemoved = (collectionID) => (dispatch) => dispatch({
  type: TYPES.COLLECTIONS.REMOVE.COMPLETE,
  payload: { collectionID },
});

export default collectionRemoved;
