/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateHiddenFields = createAsyncThunk(
  'roles/updateHiddenFields',
  async ({ roleId, hiddenFields }, {
    extra: { sdk, Toast, localization: l10n },
  }) => {
    try {
      await sdk.roles.updateHiddenFields(roleId, hiddenFields);
      return { roleId, hiddenFields };
    } catch (error) {
      Toast(l10n.TOAST.hiddenFields.update, { autoClose: false, type: 'error' });
      throw error;
    }
  },
);

export const reducer = (builder) => builder.addCase(
  updateHiddenFields.fulfilled,
  (state, { payload: { roleId, hiddenFields } }) => {
    const idx = state.items.findIndex(({ _id }) => roleId === _id);
    state.items[idx].hiddenFields = hiddenFields;
  },
);
