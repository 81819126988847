export default function shortenString(str, maxLength = 80) {
  if (str.length <= maxLength) {
    return str;
  }

  const partLength = Math.floor((maxLength - 3) / 2);
  const start = str.substring(0, partLength);
  const end = str.substring(str.length - partLength);

  return `${start}...${end}`;
}
