/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const removeCustomField = createAction('import/removeCustomField');

export const reducer = (builder) => {
  builder.addCase(
    removeCustomField.type,
    (state, { payload: title }) => {
      /** Remove from added custom fields */
      state.addedCustomFields = state.addedCustomFields.filter((cf) => cf.title !== title);
      /** Remove custom field from selected files */
      Object.values(state.items).forEach((group) => {
        group.forEach((item) => {
          if (state.selected.includes(item.id)) {
            if (item.fields.customFields[title]) delete item.fields.customFields[title];
          }
        });
      });
    },
  );
};
