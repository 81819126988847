import React, { useCallback, useRef, useState } from 'react'; // eslint-disable-line
import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import _uniqBy from 'lodash/uniqBy';
import _debounce from 'lodash/debounce';
import { useMount } from 'react-use';

import { create } from '../../store/keywords/actions';
import { getPage, getByIds, add } from '../../store/keywordsList/actions';
import Dropdown from '../dropdown';
import sdk from '../../sdk';
import Toast from '../Toast/index';

function asyncDebounce(f, wait) {
  const debounced = _debounce((resolve, reject, args) => {
    f(...args).then(resolve).catch(reject);
  }, wait);
  return (...args) => new Promise((resolve, reject) => {
    debounced(resolve, reject, args);
  });
}

const normalizeItems = ({ _id, name }) => ({ _id, title: name });

const PAGE_SIZE = 100;
async function search(query, from = 0, cancelToken) {
  const { data } = await sdk.keywords.search({
    query,
    returnFavorites: false,
    returnChildren: false,
    from,
    size: PAGE_SIZE,
    sortType: 'relevance',
    sortOrder: 'asc',
  }, cancelToken);
  return data;
}
const debouncedSearch = asyncDebounce(search, 500);

const KeywordsDropdown = (props) => {
  const { checkedItems, onCheckedHandler } = props;
  const dispatch = useDispatch();
  const cache = useRef({
    isSearching: false,
    query: '',
    searchItems: [],
    page: 0,
  });
  const inProgress = useSelector((state) => !state.keywordsList.isLoaded);
  const isFull = useSelector((state) => state.keywordsList.isFull);
  const items = useSelector(
    (state) => state.keywordsList.items.map(normalizeItems),
  );
  const [hasNextPage, setHasPage] = useState(!isFull);

  useMount(() => {
    if (checkedItems.length) {
      const keywordIdsNotInStore = checkedItems.map((item) => {
        if (items.findIndex(({ _id }) => item._id === _id) < 0) return item._id;
        return null;
      }).filter(Boolean);
      if (keywordIdsNotInStore.length) dispatch(getByIds(keywordIdsNotInStore));
    }
  });

  const createHandler = useCallback(
    (name) => dispatch(create({ name, multi: true })),
    [dispatch],
  );

  const filterFunc = useCallback(async (_query) => {
    /** get value after last comma */
    const query = (_query.match(/[^,]*[^,]$/i) || [''])[0].trim();
    if (!query) {
      cache.current = {
        query,
        isSearching: false,
        searchItems: [],
        page: 0,
        cancelToken: null,
      };
      setHasPage(!isFull);
      return items;
    }

    const isQueryChanged = cache.current.query !== query;

    /** Cancel previous request */
    if (cache.current.cancelToken) cache.current.cancelToken.cancel();

    cache.current.query = query;
    cache.current.isSearching = true;
    cache.current.cancelToken = axios.CancelToken.source();
    const from = isQueryChanged ? 0 : (cache.current.page + 1) * PAGE_SIZE;
    let searchResult;
    try {
      searchResult = await debouncedSearch(query, from, cache.current.cancelToken.token);
    } catch (error) {
      cache.current.cancelToken = null;
      /** if request is cancelled -> do nothing */
      if (axios.isCancel(error)) return [];

      Toast('Search keywords error', { type: 'error' });
      searchResult = [];
    }
    cache.current = {
      query,
      isSearching: false,
      searchItems: from === 0 ? searchResult : _uniqBy([...cache.current.searchItems, ...searchResult], '_id'),
      page: isQueryChanged ? 0 : cache.current.page + 1,
      cancelToken: null,
    };
    setHasPage(searchResult.length >= PAGE_SIZE);
    return cache.current.searchItems.map(normalizeItems);
  }, [items, isFull]);

  const loadNextPage = useCallback(() => {
    if (cache.current.query) {
      if (!cache.current.isSearching) filterFunc(cache.current.query);
      return;
    }
    if (!isFull && !inProgress) dispatch(getPage());
  }, [dispatch, inProgress, isFull, filterFunc]);

  const handleCheck = useCallback((itemOrItems) => {
    if (cache.current.searchItems.length) {
      /** add to the store from search if selected items searched */
      /** in title may be several keyword names separated by comma: "keyword 1, keyword two" */
      const titles = Array.isArray(itemOrItems) ? itemOrItems.map(({ title }) => title) : [itemOrItems.title];
      const names = titles.reduce((result, title) => {
        if (title.includes(',')) {
          const namesFromTitle = title.split(',').map((v) => v.trim().toLowerCase()).filter(Boolean);
          return [...result, ...namesFromTitle];
        }
        return [...result, title.toLowerCase()];
      }, []);

      const keywordsFromSearch = cache.current.searchItems.filter(({ name }) => names.includes(name.toLowerCase()));
      if (keywordsFromSearch.length) dispatch(add(keywordsFromSearch));
    }

    onCheckedHandler(itemOrItems);
  }, [dispatch, onCheckedHandler]);

  const { canCreate } = props;
  return (
    <Dropdown
      {...props}
      onCheckedHandler={handleCheck}
      visibleItemsLength={8}
      type="keyword"
      items={items}
      loadNextPage={loadNextPage}
      hasNextPage={hasNextPage}
      inProgress={inProgress || cache.current.isSearching}
      filterFunc={filterFunc}
      createHandler={canCreate ? createHandler : null}
    />
  );
};
KeywordsDropdown.defaultProps = {
  canCreate: false,
  checkedItems: [],
};
KeywordsDropdown.propTypes = {
  canCreate: bool,
  checkedItems: arrayOf(shape({
    _id: string,
  })),
  onCheckedHandler: func.isRequired,
};

export default KeywordsDropdown;
