import React, { memo, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ua from '../ua';

const ReactSelect = (props) => {
  const {
    label = '',
    options,
    value = null || [],
    onChange,
    disabled = false,
    ...rest
  } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="react-select">
      <If condition={label}>
        <div className="react-select__label">{label}</div>
      </If>
      <Select
        menuIsOpen={isOpen}
        isFocused={isOpen}
        openMenuOnFocus
        openMenuOnClick
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        onFocus={() => setOpen(true)}
        closeMenuOnSelect
        blurInputOnSelect
        className={cn('react-select-container', { isTablet: ua.browser.isTablet() })}
        classNamePrefix="react-select"
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <span className="react-select__indicator" />,
        }}
        inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
        menuPlacement="auto"
        {...rest}
      />
    </div>
  );
};

ReactSelect.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
};

export default memo(ReactSelect);
