import { createAction } from '@reduxjs/toolkit';

export const selectPlan = createAction('billing/selectPlan');

/* eslint-disable no-param-reassign */
export const reducer = (builder) => {
  builder.addCase(
    selectPlan.type,
    (state, { payload }) => {
      state.data.plans.forEach((plan) => {
        if (plan.planId === payload) {
          plan.selectedPlan = true;
        } else {
          plan.selectedPlan = false;
        }
      });
    },
  );
};
/* eslint-enable no-param-reassign */
