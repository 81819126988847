import React from 'react';
import { InfoBlock } from '@picsio/ui';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { navigate, reloadCurrentPage } from '../../../helpers/history';
import {
  changeCount as changeCountCollectionsAction,
} from '../collections';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';
import sortFailed from '../../helpers/assets/sortFailed';

/**
 * Move assets to trash
 * @param {string[]?} iDs - ids
 */
const trashAssets = (iDs) => (dispatch, getAll) => {
  let ids = iDs;
  const assetsStore = getAll().assets;
  if (ids === undefined) ids = assetsStore.selectedItems;
  const assetName = ids.length === 1 && (assetsStore.items.find(({ _id }) => _id === ids[0]) || {}).name;

  Logger.log('UI', 'MoveAssetsToTrashDialog', ids.length);
  const { MOVE_TO_TRASH } = localization.DIALOGS;
  showDialog({
    title: MOVE_TO_TRASH.TITLE,
    children: (
      <>
        {MOVE_TO_TRASH.TEXT(ids.length, assetName)}
        <br />
        <InfoBlock>{MOVE_TO_TRASH.INFO_BLOCK(ids.length)}</InfoBlock>
      </>),
    textBtnOk: MOVE_TO_TRASH.BTN_OK,
    onCancel: () => {
      Logger.log('User', 'MoveAssetsToTrashDialogCancel');
    },
    async onOk() {
      Logger.log('User', 'MoveAssetsToTrashDialogOk', ids.length);
      try {
        dispatch({ type: TYPES.ASSETS.DELETE_ASSETS.START, payload: { ids } });
        // throw new Error('You have a limited access to this file in Google Drive,
        // which means you can\'t trash this asset in Picsio either. ');
        UiBlocker.block();
        const { data } = await sdk.assets.trash(ids);
        const {
          queued, successful = [], failed = [], failedAssets = [],
        } = data;

        dispatch({
          type: TYPES.ASSETS.DELETE_ASSETS.COMPLETE,
          payload: { ids: successful, queued },
        });

        if (failed.length) {
          const { failedGDArr, failedOtherArr } = sortFailed(failedAssets, 'MissingCanTrashGoogleDriveCapabilityError');

          if (failedGDArr.length) {
            const hideDialog = showDialog({
              title: localization.DIALOGS.ERROR_TRASH_DIALOG.TITLE,
              children: ids.length > 1
                ? localization.DIALOGS.ERROR_TRASH_DIALOG.TEXT_ASSET_BULK_OPERATION(
                  successful.length,
                  failedGDArr.length,
                  () => {
                    navigate('/storage');
                    hideDialog();
                  },
                )
                : localization.DIALOGS.ERROR_TRASH_DIALOG.TEXT_ASSET_ONE_OPERATION(
                  () => {
                    navigate('/storage');
                    hideDialog();
                  },
                ),
              textBtnCancel: null,
            });
          }

          if (failedOtherArr.length) {
            Toast(
              localization.DETAILS.textCantDeleteAsset(failedOtherArr.length),
              {
                autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
              },
            );
          }

          dispatch({
            type: TYPES.ASSETS.DELETE_ASSETS.FAILED,
            payload: { ids: failed },
          });
        }

        if (queued) {
          Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
        } else {
          changeCountCollectionsAction(-successful.length)(dispatch);
        }
      } catch (error) {
        dispatch({
          type: TYPES.ASSETS.DELETE_ASSETS.FAILED,
          payload: { ids },
          error,
        });
        Toast(localization.DETAILS.textCantRemoveAsset(ids.length), { autoClose: false, type: 'error' });
        Logger.error(new Error('Can not delete assets'), { error }, [
          'DeleteAssetsFailed',
          (error && error.message) || 'NoMessage',
        ]);
      } finally {
        UiBlocker.unblock();
      }
    },
  });
};

export default trashAssets;
