import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { navigate, reloadCurrentPage } from '../../../helpers/history';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

/**
 * @param {string} id
 * @param {string} name
 */
const rename = (id, name) => async (dispatch) => {
  dispatch({ type: TYPES.ASSETS.RENAME.START, payload: { id } });
  try {
    await sdk.assets.rename(id, name);
    dispatch({
      type: TYPES.ASSETS.RENAME.COMPLETE,
      payload: {
        id,
        name,
      },
    });
  } catch (error) {
    dispatch({
      type: TYPES.ASSETS.RENAME.FAILED,
      error,
    });
    const errorStatus = utils.getStatusFromResponceError(error);
    const subcode = utils.getDataFromResponceError(error, 'subcode');
    const { UNABLE_TO_RENAME_ASSET, ERROR_RENAME_ASSET } = localization.DIALOGS;

    if (subcode === 'MissingCanRenameGoogleDriveCapabilityError') {
      const hideDialog = showDialog({
        title: ERROR_RENAME_ASSET.TITLE,
        children: ERROR_RENAME_ASSET.TEXT(() => {
          navigate('/storage');
          hideDialog();
        }),
        textBtnCancel: null,
        async onOk() {
          Logger.log('User', 'Rename faile');
        },
      });
    } else if (errorStatus === 400) {
      showDialog({
        title: UNABLE_TO_RENAME_ASSET.TITLE,
        text: UNABLE_TO_RENAME_ASSET.TEXT,
        textBtnCancel: null,
        async onOk() {
          Logger.log('User', 'Rename faile');
        },
      });
    } else {
      Toast(localization.DETAILS.textCantUpdateAssetName, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
    }
    Logger.error(new Error('Error rename asset'), { error }, [
      'RenameAssetFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};

export default rename;
