/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const addCustomField = createAction('import/addCustomField');

export const reducer = (builder) => {
  builder.addCase(
    addCustomField.type,
    (state, { payload: customField }) => {
      state.addedCustomFields.push(customField);
    },
  );
};
