/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import getFilesByIds from './helpers/getFilesByIds';
import validateFields from './helpers/validateFields';
import groupErrors from './helpers/groupErrors';

export const changeField = createAction('import/changeField');

export const reducer = (builder) => {
  builder.addCase(
    changeField.type,
    (state, { payload: { title, value } }) => {
      if (title === 'keywords' || title === 'assignees') return;

      _set(state.fields, title, value);
      const selectedFiles = getFilesByIds(state.items, state.selected);
      selectedFiles.forEach((file) => {
        _set(file.fields, title, value);
        if (state.isUploadSubmitted || !_isEmpty(file.errors || {})) {
          const { isValid, errors, error } = validateFields({ fields: file.fields, requiredFields: state.requiredFields, importFrom: file.file?.importFrom });
          file.errors = errors;
          file.error = error;
          file.isValid = isValid;
        }
      });
      state.errors = groupErrors(selectedFiles);
    },
  );
};
