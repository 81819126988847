import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Store from '../../index';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';

import checkMultipleChanges from './checkMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Change custom field
 * @param {string[]} ids
 * @param {string} title
 * @param {string} type
 * @param {string?} visibility
 * @param {*} value
 * @param {Function} onCancel
 */
const changeCustomField = ({
  ids,
  title,
  type,
  visibility,
  value,
  onCancel,
  multiple = false,
  isAttach,
}) => (dispatch) => {
  checkMultipleChanges(ids.length, doChange, onCancel);

  async function doChange() {
    try {
      dispatch({
        type: TYPES.ASSETS.CHANGE_CUSTOM_FIELD.START,
        payload: {
          title,
        },
      });

      if (multiple) {
        if (isAttach) {
          await sdk.assets.attachMultipleCustomField(ids, title, type, value);

          dispatch({
            type: TYPES.ASSETS.CHANGE_MULTIPLE_CUSTOM_FIELD.ATTACH,
            payload: {
              ids,
              title,
              value,
              userId: Store.getState().user._id,
            },
          });
        } else {
          await sdk.assets.detachMultipleCustomField(ids, title, type, value);

          dispatch({
            type: TYPES.ASSETS.CHANGE_MULTIPLE_CUSTOM_FIELD.DETACH,
            payload: {
              ids,
              title,
              value,
              userId: Store.getState().user._id,
            },
          });
        }
      } else {
        let valueToSend = value;
        /** to clear value from CF type enum -> need to send empty string */
        if (type === 'enum' && !value) valueToSend = '';
        await sdk.assets.patchCustomField(ids, title, type, visibility, valueToSend);

        dispatch({
          type: TYPES.ASSETS.CHANGE_CUSTOM_FIELD.COMPLETE,
          payload: {
            ids,
            title,
            value,
            userId: Store.getState().user._id,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.ASSETS.CHANGE_CUSTOM_FIELD.FAILED,
        payload: {
          title,
        },
        error,
      });

      const errorStatus = utils.getStatusFromResponceError(error);
      const errorMessage = errorStatus === 403
        ? localization.NO_PERMISSION_TO_ACCESS
        : utils.getDataFromResponceError(error, 'msg');
      if (errorMessage) {
        Toast(errorMessage, { autoClose: false, type: 'error' });
      } else {
        Toast(localization.DETAILS.textCantUpdate(title), { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      }
      Logger.error(new Error('Error change custom field'), { error }, [
        'ChangeCustomFieldFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  }
};

export default changeCustomField;
