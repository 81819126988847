/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createFace = createAsyncThunk(
  'faces/addFaces',
  async ({
    name, eventData,
  }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  },
  ) => {
    try {
      // here we have two possible ways to get data. One is from events(when user clicks recognize), second when user customly adds face
      if (eventData) {
        return {
          eventData,
        };
      }
      const { data } = await sdk.faces.create(name);
      return [data];
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not create face';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error face creation'),
        { error },
        ['CreateFaceFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    createFace.fulfilled,
    (state, { payload }) => {
      const allFaces = state.all;
      const newFaces = [];
      // here we have two possible ways to get data. One is from events(when user clicks recognize), second when user customly adds face
      const data = payload?.eventData || payload;
      data.forEach((item) => {
        const isFacedExist = allFaces.find((storeFaced) => storeFaced._id === item._id);
        if (!isFacedExist) {
          newFaces.push(item);
        }
      });
      state.all = [...state.all, ...newFaces];
      state.isBusy = false;
      if (payload.eventData) { // if it's an unknown face
        state.tree = {
          ...state.tree,
          unnamed: {
            ...state.tree.unnamed,
            nodes: [
              ...state.tree.unnamed.nodes, ...newFaces,
            ],
          },
        };
      } else { // if it is a knonw face
        state.tree = {
          ...state.tree,
          allFaces: {
            ...state.tree.allFaces,
            nodes: [
              ...state.tree.allFaces.nodes, ...newFaces,
            ],
          },
        };
      }
    },
  );
};
