import React from 'react';
/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateUserSortType } from '../user';

const DEFAULT_SORT_TYPE = {
  type: 'name',
  order: 'asc',
};

export default createAsyncThunk(
  'collections/sort',
  async ({
    moveId, receiveId, position, parentIds,
  }, {
    getState, dispatch, extra: {
      sdk, Logger, utils, showErrorDialog, showDialogAsync, localization: l10n,
    },
  }) => {
    try {
      const { user, collections } = getState();
      let needToChangeSort = false;

      const currentSortType = user.collectionsSortType || DEFAULT_SORT_TYPE;
      const baseCustomSort = user.team.collectionsCustomSortBase;

      /** set order on NOT custom sort type */
      if (currentSortType.type !== 'custom') {
        try {
          const title = baseCustomSort ? 'New custom sorting' : 'Custom sorting';
          const children = baseCustomSort ? (
            <>
              <p>You are about to apply new custom sorting to entire library.</p>
              <p>Current custom sorting will be updated with new settings.</p>
            </>
          ) : (
            <>
              <p>You are about to apply custom sorting to collections.</p>
              <p>This sorting will be available to entire team from now on.</p>
            </>
          );
          const { promise } = showDialogAsync({ title, children, textBtnOk: 'Save' });
          await promise;
          Logger.log('User', 'CollectionsPanelSaveCustomCollectionSorting', { saved: true });
        } catch (_) {
          /** User just pressed "Cancel" */
          Logger.log('User', 'CollectionsPanelSaveCustomCollectionSorting', { saved: false });
          return null;
        }
        needToChangeSort = true;
      }

      const mainCollections = { ...collections.collections.my };
      const lastParentId = parentIds[parentIds.length - 1];

      const orderedLevel = [];

      const handleNodes = (nodes) => {
        let movedIndex = nodes.findIndex(({ _id }) => _id === moveId);
        if (movedIndex < 0) movedIndex = 0;

        const newNodes = [...nodes];
        const [removed] = newNodes.splice(movedIndex, 1);

        let receiverIndex = newNodes.findIndex(({ _id }) => _id === receiveId);
        if (position === 'bottom') receiverIndex += 1;
        if (receiverIndex < 0) receiverIndex = 0;

        newNodes.splice(receiverIndex, 0, removed);
        newNodes.forEach((node, index) => {
          node.order = index; /** Change order on collection */

          orderedLevel.push({
            _id: node._id,
            order: index,
          });
        });
        return newNodes;
      };

      const handleLvl = (node) => {
        /** last parent */
        if (node._id === lastParentId) {
          return { ...node, nodes: handleNodes(node.nodes) };
        }
        /** parent */
        if (parentIds.includes(node._id)) return { ...node, nodes: node.nodes.map(handleLvl) };
        return node;
      };

      const result = handleLvl(mainCollections);
      await sdk.collections.setOrder(orderedLevel);

      if (needToChangeSort) {
        dispatch(updateUserSortType({
          collectionType: 'collections',
          sortType: { type: 'custom', order: 'asc' },
          setBase: true,
        }));
      }

      return result;
    } catch (error) {
      const { title, text } = l10n.SERVER_ERROR;
      const msg = utils.getDataFromResponceError(error, 'msg');

      showErrorDialog(msg || text, msg ? l10n.ERROR : title);
      Logger.error(new Error('Can not set collections order'), { error }, [
        'SetCustomCollectionsOrderFailed',
        { errorMessage: msg },
      ]);
      throw error;
    }
  },
);
