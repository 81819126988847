/**
 * Find already added to downloadList files
 * @param {array} addedFiles
 * @param {array} filesToAdd
 * @returns {Object[]} duplicated file names
 */
function findDuplicates(addedFiles, filesToAdd) {
  return filesToAdd.reduce((acc, item) => {
    const index = addedFiles.findIndex((file) => file._id === item._id);
    if (index !== -1) {
      acc.push({ _id: item._id, name: item.name });
    }
    return acc;
  }, []);
}

export default findDuplicates;
