import {
  element, string, number, oneOfType, func, array, bool,
} from 'prop-types';
import React, {
  forwardRef, memo, useCallback, Fragment,
} from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import ua from '../../ua';
import './tooltip.scss';

const isMobile = ua.browser.isNotDesktop();

// https://github.com/mohsinulhaq/react-popper-tooltip/blob/12607878d87d34e40f37e62e5bbd764f17e72dde/README.md#examples
const Tooltip = forwardRef(({
  content = null,
  children = null,
  placement = 'auto',
  delayShow = 300,
  interactive = false,
  delayHide = 0,
  trigger = 'hover',
  extraClassName = '',
}, ref) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger,
    closeOnOutsideClick: true,
    delayShow,
    delayHide,
    placement,
    interactive,
  });

  const setRef = (node) => {
    // eslint-disable-next-line no-param-reassign
    if (ref && typeof ref === 'object') ref.current = node;
    setTriggerRef(node);
  };

  const handleClick = useCallback((...args) => {
    if (children.props.onClick) children.props.onClick(...args);
  }, [children]);

  if (!content || isMobile) return children;

  return (
    <>
      <Choose>
        <When condition={children?.props?.disabled}>
          <span className="tooltipWrapper" ref={setRef}>
            {React.cloneElement(children, {
              onClick: handleClick,
            })}
          </span>
        </When>
        <Otherwise>
          {React.cloneElement(children, {
            ref: setRef,
            onClick: handleClick,
          })}
        </Otherwise>
      </Choose>
      <If condition={visible}>
        {ReactDOM.createPortal(
          <div ref={setTooltipRef} {...getTooltipProps({ className: `tooltip-container ${extraClassName}` })}>
            <Choose>
              <When condition={typeof content === 'function'}>{content()}</When>
              <When condition={Array.isArray(content)}>
                {content.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
              </When>
              <Otherwise>{content}</Otherwise>
            </Choose>
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </div>,
          document.body,
        )}
      </If>
    </>
  );
});

Tooltip.propTypes = {
  content: oneOfType([string, func]),
  children: oneOfType([element, array, string]),
  placement: string,
  delayShow: number,
  interactive: bool,
  delayHide: number,
  trigger: string,
  extraClassName: string,
};

export default memo(Tooltip);
