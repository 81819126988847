import TYPES from '../../action-types';

/**
 * @param {string} id
 * @param {string} revisionId
 * @param {Object} proxyFileStorageId
 */
function setPdfProxies(id, revisionId, proxyFileStorageId) {
  return (dispatch) => {
    dispatch({
      type: TYPES.ASSETS.SET_PDF_PROXIES,
      payload: {
        id,
        revisionId,
        proxyFileStorageId,
      },
    });
  };
}

export default setPdfProxies;
