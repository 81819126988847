export default {
  COLLECTIONS: {
    FETCH: {
      START: 'collections.fetch.start',
      COMPLETE: 'collections.fetch.complete',
      FAILED: 'collections.fetch.failed',
    },
    FETCH_CHILDREN: {
      START: 'collections.fetch_children.start',
      COMPLETE: 'collections.fetch_children.complete',
      FAILED: 'collections.fetch_children.failed',
    },
    ARCHIVE: {
      START: 'collections.archive.start',
      COMPLETE: 'collections.archive.complete',
      FAILED: 'collections.archive.failed',
    },
    RENAME: {
      START: 'collections.rename.start',
      COMPLETE: 'collections.rename.complete',
      FAILED: 'collections.rename.failed',
    },
    ADD: {
      START: 'collections.add.start',
      COMPLETE: 'collections.add.complete',
      FAILED: 'collections.add.failed',
    },
    REMOVE: {
      START: 'collections.remove.start',
      COMPLETE: 'collections.remove.complete',
      FAILED: 'collections.remove.failed',
      INPROGRESS: 'collections.remove.inprogress',
      UNDO: 'collections.remove.undo',
    },
    MOVE: {
      START: 'collections.move.start',
      COMPLETE: 'collections.move.complete',
      FAILED: 'collections.move.failed',
    },
    FAVORITE: {
      START: 'collections.favorite.start',
      COMPLETE: 'collections.favorite.complete',
      FAILED: 'collections.favorite.failed',
    },
    SEARCH: {
      SET: 'collections.search.set',
      START: 'collections.search.start',
      COMPLETE: 'collections.search.complete',
      FAILED: 'collections.search.failed',
    },
    CHANGE_COLOR: {
      START: 'collections.change_color.start',
      COMPLETE: 'collections.change_color.complete',
      FAILED: 'collections.change_color.failed',
    },
    CHANGE_DESCRIPTION: {
      START: 'collections.change_description.start',
      COMPLETE: 'collections.change_description.complete',
      FAILED: 'collections.change_description.failed',
    },
    PUSH: 'collections.push',
    CHANGE_ASSETS_COUNT: 'collections.changeAssetsCount',
    SET_ACTIVE: 'collections.setActive',
    SET_SORT_TYPE: {
      START: 'collections.setSortType.start',
      COMPLETE: 'collections.setSortType.complete',
      FAILED: 'collections.setSortType.failed',
    },
    SET_WEBSITE: 'collections.setWebsite',
    RECURSIVE_SEARCH_TOGGLE: 'collections.recursiveSearchToggle',
    RECURSIVE_SEARCH_SET: 'collections.recursiveSearchSet',
    SET_ACTIVE_HAS_CHILD: 'collections.setActiveHasChild',
    UPDATE_ROOT_COLLECTION_NAME: 'collections.updateRootCollectionName',
    IMPORTING: {
      COMPLETE: 'collections.importing.complete',
      FAILED: 'collections.importing.failed',
    },
  },

  KEYWORDS: {
    FETCH: {
      START: 'keywords.fetch.start',
      COMPLETE: 'keywords.fetch.complete',
      FAILED: 'keywords.fetch.failed',
    },
    ADD: {
      START: 'keywords.add.start',
      COMPLETE: 'keywords.add.complete',
      FAILED: 'keywords.add.failed',
    },
    RENAME: {
      START: 'keywords.rename.start',
      COMPLETE: 'keywords.rename.complete',
      FAILED: 'keywords.rename.failed',
    },
    FAVORITE: {
      START: 'keywords.favorite.start',
      COMPLETE: 'keywords.favorite.complete',
      FAILED: 'keywords.favorite.failed',
    },
    REMOVE: {
      START: 'keywords.remove.start',
      COMPLETE: 'keywords.remove.complete',
      FAILED: 'keywords.remove.failed',
      INPROGRESS: 'keywords.remove.inprogress',
    },
    MOVE: {
      START: 'keywords.move.start',
      COMPLETE: 'keywords.move.complete',
      FAILED: 'keywords.move.failed',
    },
    SEARCH: 'keywords.search',
    SET_ACTIVE: 'keywords.setActive',
    ADD_MULTIPLE: 'keywords.addMultiple',
    SORT: 'keywords.sort',
    UPDATE_USED_AT: 'keywords.updateUsedAt',
    UPDATE_COUNT: 'keywords.count',
  },

  FACES: {
    SET_ACTIVE: 'faces.setActive',
  },

  LIGHTBOARDS: {
    FETCH: {
      START: 'lightboards.fetch.start',
      COMPLETE: 'lightboards.fetch.complete',
      FAILED: 'lightboards.fetch.failed',
    },
    ADD: {
      START: 'lightboards.add.start',
      COMPLETE: 'lightboards.add.complete',
      FAILED: 'lightboards.add.failed',
    },
    RENAME: {
      START: 'lightboards.rename.start',
      COMPLETE: 'lightboards.rename.complete',
      FAILED: 'lightboards.rename.failed',
    },
    REMOVE: {
      START: 'lightboards.remove.start',
      COMPLETE: 'lightboards.remove.complete',
      FAILED: 'lightboards.remove.failed',
    },
    UPDATE_SORT_TYPE: {
      START: 'lightboards.updateSortType.start',
      COMPLETE: 'lightboards.updateSortType.complete',
      FAILED: 'lightboards.updateSortType.failed',
    },
    SEARCH: 'lightboards.search',
    SET_ACTIVE: 'lightboards.setActive',
    SET_SORT_TYPE: 'lightboards.setSortType',
  },

  CUSTOM_FIELDS: {
    FETCH: {
      START: 'customFields.fetch.start',
      COMPLETE: 'customFields.fetch.complete',
      FAILED: 'customFields.fetch.failed',
    },
    ADD: {
      START: 'customFields.add.start',
      COMPLETE: 'customFields.add.complete',
      FAILED: 'customFields.add.failed',
    },
    REMOVE: {
      START: 'customFields.remove.start',
      COMPLETE: 'customFields.remove.complete',
      FAILED: 'customFields.remove.failed',
    },
    UPDATE: {
      START: 'customFields.update.start',
      COMPLETE: 'customFields.update.complete',
      FAILED: 'customFields.update.failed',
    },
    UPDATE_ONE: {
      START: 'customFields.updateOne.start',
      COMPLETE: 'customFields.updateOne.complete',
      CANCELLED: 'customFields.updateOne.cancelled',
      FAILED: 'customFields.updateOne.failed',
    },
    IMPORT: {
      START: 'customFields.import.start',
      COMPLETE: 'customFields.import.complete',
      FAILED: 'customFields.import.failed',
    },
    MOVE: {
      START: 'customFields.move.start',
      COMPLETE: 'customFields.move.complete',
      FAILED: 'customFields.move.failed',
    },
    SEARCH: 'customFields.search',
    REMOVE_ERROR: 'customFields.removeError',
  },

  SAVEDSEARCHES: {
    UPDATE_FIELD: 'savedSearches.update',
    FETCH: {
      START: 'savedSearches.fetch.start',
      COMPLETE: 'savedSearches.fetch.complete',
      FAILED: 'savedSearches.fetch.failed',
    },
    ADD: {
      START: 'savedSearches.add.start',
      COMPLETE: 'savedSearches.add.complete',
      FAILED: 'savedSearches.add.failed',
    },
    REMOVE: {
      START: 'savedSearches.remove.start',
      COMPLETE: 'savedSearches.remove.complete',
      FAILED: 'savedSearches.remove.failed',
    },
    FAVORITE: {
      START: 'savedSearches.favorite.start',
      COMPLETE: 'savedSearches.favorite.complete',
      FAILED: 'savedSearches.favorite.failed',
    },
    SEARCH: 'savedSearches.search',
    SET_ACTIVE: 'savedSearches.setActive',
  },

  MAIN: {
    CHANGE_TREE: 'main.changeTree',
    RESIZE_PANEL: 'main.resizePanel',
    SET_PANEL_SIZE: 'main.setPanelSize',
    RECALCULATE_PANEL_SIZE: 'main.recalculatePanelSize',
    CHANGE_CATALOG_VIEW_MODE: 'main.changeCatalogViewMode',
    CHANGE_CATALOG_VIEW_MODE_SIZE: 'main.changeCatalogViewItemSize',
    OPEN_DETAILS: 'main.openDetails',
    CLOSE_DETAILS: 'main.closeDetails',
    OPEN_IMPORT: 'main.openImport',
    CLOSE_IMPORT: 'main.closeImport',
    SET_BACKLIGHT: 'main.setBacklight',
    OPEN_DOWNLOADLIST: 'main.openDownloadList',
    CLOSE_DOWNLOADLIST: 'main.closeDownloadList',
    SET_IS_WEBP_SUPPORTED: 'main.setIsWebpSupported',
    SET_MAP_VIEWPORT: 'main.setMapViewport',
    SET_ASSET_SCALE: 'main.setAssetScale',
    SET_MOBILE_MAIN_SCREEN: 'main.setMobileMainScreenPanel',
    SET_MOBILE_ADDITIONAL_SCREEN: 'main.setMobileAdditionalScreenPanel',
  },

  ASSETS: {
    FETCH: {
      START: 'assets.fetch.start',
      COMPLETE: 'assets.fetch.complete',
      FAILED: 'assets.fetch.failed',
    },
    FETCH_TMP: {
      START: 'assets.fetchTmp.start',
      COMPLETE: 'assets.fetchTmp.complete',
      FAILED: 'assets.fetchTmp.failed',
    },
    GET_SELECTED_ASSETS: {
      COMPLETE: 'assets.getSelectedAssets.complete',
      FAILED: 'assets.getSelectedAssets.failed',
    },
    GET_BY_IDS: {
      START: 'assets.getByIds.start',
      COMPLETE: 'assets.getByIds.complete',
      FAILED: 'assets.getByIds.failed',
    },
    CREATE_COLLECTION: {
      COMPLETE: 'assets.createCollectionFromSelectedAssets.complete',
    },
    SET_THUMBNAILS: 'assets.setThumbnails',
    SET_CUSTOM_THUMBNAIL: 'assets.setCustomThumbnail',
    SET_CUSTOM_THUMBNAILS: 'assets.setCustomThumbnails',
    SET_PDF_PROXIES: 'assets.setPdfProxies',
    SET_TMP_ITEM: 'assets.setTmpItem',
    ADD_HIGHLIGHT: 'assets.addHighlight',
    REMOVE_HIGHLIGHT: 'assets.removeHighlight',
    REMOVE_TMP_ITEMS: 'assets.removeTmpItems',
    SELECT_ALL: {
      START: 'assets.selectAll.start',
      COMPLETE: 'assets.selectAll.complete',
      FAILED: 'assets.selectAll.failed',
    },
    ADD_COLLECTION: {
      START: 'assets.addCollection.start',
      COMPLETE: 'assets.addCollection.complete',
      FAILED: 'assets.addCollection.failed',
    },
    REMOVE_COLLECTION: {
      START: 'assets.removeCollection.start',
      COMPLETE: 'assets.removeCollection.complete',
      FAILED: 'assets.removeCollection.failed',
    },
    REMOVED_COLLECTION: 'assets.removedCollection',
    ADD_LIGHTBOARD: {
      START: 'assets.addLightboard.start',
      COMPLETE: 'assets.addLightboard.complete',
      FAILED: 'assets.addLightboard.failed',
    },
    REMOVE_LIGHTBOARD: {
      START: 'assets.removeLightboard.start',
      COMPLETE: 'assets.removeLightboard.complete',
      FAILED: 'assets.removeLightboard.failed',
    },
    UPDATE_APPROVE: 'assets.updateApprove',
    ATTACH_KEYWORD: {
      START: 'assets.attachKeyword.start',
      COMPLETE: 'assets.attachKeyword.complete',
      FAILED: 'assets.attachKeyword.failed',
    },
    DETACH_KEYWORD: {
      START: 'assets.detachKeyword.start',
      COMPLETE: 'assets.detachKeyword.complete',
      FAILED: 'assets.detachKeyword.failed',
    },
    FACES_RECOGNIZE: {
      START: 'assets.facesRecognize.start',
      COMPLETE: 'assets.facesRecognize.complete',
      FAILED: 'assets.facesRecognize.failed',
    },
    RENAME_KEYWORD: 'assets.renameKeyword',
    RENAME_COLLECTION: 'assets.renameCollection',
    DELETE_COLLECTION: 'assets.deleteCollection',
    CHANGE_PATH: 'assets.changePath',
    UPDATE_LIGHTBOARD: 'assets.updateLightboard',
    DELETE_LIGHTBOARD: 'assets.deleteLightboard',
    REMOVE_ALL_KEYWORDS: 'assets.removeAllKeywords',
    MERGE_KEYWORDS: 'assets.mergeKeywords',
    REVERT_REVISION: 'assets.revertRevision',
    SET_USER_ORIENTATION: {
      START: 'assets.setUserOrientaion.start',
      COMPLETE: 'assets.setUserOrientaion.complete',
      FAILED: 'assets.setUserOrientaion.failed',
    },
    GENERATE_KEYWORDS: {
      START: 'assets.generateKeywords.start',
      COMPLETE: 'assets.generateKeywords.complete',
      FAILED: 'assets.generateKeywords.failed',
    },
    GENERATE_FACES: {
      START: 'assets.generateFaces.start',
      COMPLETE: 'assets.generateFaces.complete',
      FAILED: 'assets.generateFaces.failed',
    },
    ASSIGN_USER: {
      START: 'assets.assignUser.start',
      COMPLETE: 'assets.assignUser.complete',
      FAILED: 'assets.assignUser.failed',
    },
    UNASSIGN_USER: {
      START: 'assets.unAssignUser.start',
      COMPLETE: 'assets.unAssignUser.complete',
      FAILED: 'assets.unAssignUser.failed',
    },
    CHANGE_FLAG: {
      START: 'assets.changeFlag.start',
      COMPLETE: 'assets.changeFlag.complete',
      FAILED: 'assets.changeFlag.failed',
    },
    CHANGE_STARS: {
      START: 'assets.changeStars.start',
      COMPLETE: 'assets.changeStars.complete',
      FAILED: 'assets.changeStars.failed',
    },
    CHANGE_COLOR: {
      START: 'assets.changeColor.start',
      COMPLETE: 'assets.changeColor.complete',
      FAILED: 'assets.changeColor.failed',
    },
    RENAME: {
      START: 'assets.rename.start',
      COMPLETE: 'assets.rename.complete',
      FAILED: 'assets.rename.failed',
    },
    CHANGE_TITLE: {
      START: 'assets.changeTitle.start',
      COMPLETE: 'assets.changeTitle.complete',
      FAILED: 'assets.changeTitle.failed',
    },
    CHANGE_DESCRIPTION: {
      START: 'assets.changeDescription.start',
      COMPLETE: 'assets.changeDescription.complete',
      FAILED: 'assets.changeDescription.failed',
    },
    CHANGE_CUSTOM_FIELD: {
      START: 'assets.changeCustomField.start',
      COMPLETE: 'assets.changeCustomField.complete',
      FAILED: 'assets.changeCustomField.failed',
    },
    CHANGE_MULTIPLE_CUSTOM_FIELD: {
      ATTACH: 'assets.changeCustomField.attach',
      DETACH: 'assets.changeCustomField.detach',
    },
    SHARE: {
      START: 'assets.share.start',
      COMPLETE: 'assets.share.complete',
      FAILED: 'assets.share.failed',
    },
    RESTRICT: {
      START: 'assets.restrict.start',
      COMPLETE: 'assets.restrict.complete',
      FAILED: 'assets.restrict.failed',
    },
    ADD_REVISION: {
      START: 'assets.addRevision.start',
      COMPLETE: 'assets.addRevision.complete',
      FAILED: 'assets.addRevision.failed',
    },
    DELETE_ASSETS: {
      START: 'assets.delete.start',
      COMPLETE: 'assets.delete.complete',
      FAILED: 'assets.delete.failed',
    },
    COPY_ASSETS: {
      START: 'assets.copy.start',
      COMPLETE: 'assets.copy.complete',
      FAILED: 'assets.copy.failed',
    },
    MOVING_ASSETS: {
      START: 'assets.moving.start',
      COMPLETE: 'assets.moving.complete',
      FAILED: 'assets.moving.failed',
    },
    RESTORE_ASSETS: {
      START: 'assets.restore.start',
      COMPLETE: 'assets.restore.complete',
      FAILED: 'assets.restore.failed',
    },
    DESELECT_ALL: 'assets.deselectAll',
    SELECT: 'assets.select',
    SELECT_MANY: 'assets.selectMany',
    REORDER: 'assets.reorder',
    CHANGE_UPLOAD_REVISION_PROGRESS: 'assets.changeUploadRevisionProgress',
    RESET_HIGHLIGHT: 'assets.resetHighlight',
    UPDATE_FIELDS_ONE_ASSET: 'assets.updateFields',
    UPDATE_FIELDS_MANY_ASSETS: 'assets.updateFieldsManyAssets',
    REMOVE_MODIFIED_FIELD: {
      START: 'assets.removeModifiedField.start',
      COMPLETE: 'assets.removeModifiedField.complete',
      FAILED: 'assets.removeModifiedField.failed',
    },
    RERUN_PARSING: {
      START: 'assets.rerunParsing.start',
      COMPLETE: 'assets.rerunParsing.complete',
      FAILED: 'assets.rerunParsing.failed',
    },
    FETCH_PAGES: {
      START: 'assets.fetchPages.start',
      COMPLETE: 'assets.fetchPages.complete',
      FAILED: 'assets.fetchPages.failed',
    },
    FETCH_REVISIONS_THUMBNAILS: {
      START: 'assets.fetchRevisionsThumbnails.start',
      COMPLETE: 'assets.fetchRevisionsThumbnails.complete',
      FAILED: 'assets.fetchRevisionsThumbnails.failed',
    },
    FETCH_WATERMARKS: 'watermarks.fetch',
    DELETE_WATERMARKS: 'watermarks.delete',
    CHANGE_WATERMARK: 'watermarks.change',
    SET_DEFAULT_WATERMARK: 'watermark.default',
    ATTACH_WATERMARK: {
      START: 'watermarks.attach.start',
      COMPLETE: 'watermarks.attach.complete',
      FAIL: 'watermarks.attach.fail',
    },
    ATTACH_COMMENT: 'assets.attachComment',
    EDIT_COMMENT: 'assets.editComment',
    CRAWLING_EVENT: 'assets.crawlingEvent',
    IMPORTING: {
      START: 'assets.importing.start',
      COMPLETE: 'assets.importing.complete',
      FAILED: 'assets.importing.failed',
    },
  },
  NOTIFICATIONS: {
    FETCH: {
      START: 'notifications.fetch.start',
      COMPLETE: 'notifications.fetch.complete',
      FAILED: 'notifications.fetch.failed',
    },
    ADD: 'notifications.add',
    MERGE: 'notifications.merge',
    ADD_CHANGED_TAGS_IDS: 'notifications.addChangedTagsIds',
    CLEAR: {
      START: 'notifications.clear.start',
      COMPLETE: 'notifications.clear.complete',
      FAILED: 'notifications.clear.failed',
    },
    MARK_AS_READ: {
      START: 'notifications.markAsRead.start',
      COMPLETE: 'notifications.markAsRead.complete',
      FAILED: 'notifications.markAsRead.failed',
    },
    GET_THUMBNAILS: {
      START: 'notifications.getThumbnails.start',
      COMPLETE: 'notifications.getThumbnails.complete',
      FAILED: 'notifications.getThumbnails.failed',
    },
    CLEAR_CHANGED_TAGS_IDS: 'notifications.clearChangedTagsIds',
    FETCH_JOBS: {
      START: 'notifications.fetchJobs.start',
      COMPLETE: 'notifications.fetchJobs.complete',
      FAILED: 'notifications.fetchJobs.failed',
    },
    INCREASE_UNREAD_PARAM: 'notifications.increaseUnreadParam',
  },

  DOWNLOAD_LIST: {
    ADD: 'download_list.add',
    REMOVE: 'download_list.remove',
    UPDATE: 'download_list.update',
    UPDATE_TOTAL: 'download_list.updateTotal',
    RESET_TOTAL: 'download_list.resetTotal',
    ZIPPER_UPDATE: 'download_list.zipperUpdate',
  },
};
