/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { search } from './search';

export const setSort = createAsyncThunk(
  'keywords/setSort',
  async (payload, { getState, dispatch }) => {
    const { type, order } = getState().keywords.sortType;
    const newSort = { type: payload.type, order: payload.order };

    /** get keywords if sort is changed AND no instruction about prevent fetch
     <preventFetch> is used when get sortType from user's object on load */
    if ((type !== newSort.type || order !== newSort.order) && !payload.preventFetch) {
      dispatch(search({ sort: newSort }));
    }

    return newSort;
  },
);

export const reducer = (builder) => {
  builder.addCase(
    setSort.fulfilled,
    (state, { payload }) => {
      const { type, order } = state.sortType;
      if (type !== payload.type || order !== payload.order) {
        state.sortType = payload;
      }
    },
  );
};
