/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { sort } from '../helpers/faces';

export const sortTypeFace = createAction('faces/sortTypeFace');

export const reducer = (builder) => {
  builder.addCase(
    sortTypeFace.type,
    (state, { payload: type }) => {
      state.sortType = type;
      if (state.tree) {
        // state.tree = { ...state.tree, nodes: sort(state.tree.nodes, type) };
        state.tree.favorites = { ...state.tree.favorites, nodes: sort(state.tree.favorites.nodes, type) };
        state.tree.allFaces = { ...state.tree.allFaces, nodes: sort(state.tree.allFaces.nodes, type) };
        state.tree.unnamed = { ...state.tree.unnamed, nodes: sort(state.tree.unnamed.nodes, type) };
      }
    },
  );
};
