/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../../../../sdk';
import Logger from '../../../../services/Logger';

const editTranscript = createAsyncThunk(
  'asset/editTranscript',
  async (
    {
      assetId,
      startTime,
      text,
      revisionId,
    }, { rejectWithValue },
  ) => {
    try {
      await sdk.assets.editTranscript(assetId, startTime, text, revisionId);
      Logger.log('User', 'TranscriptEditSaved');
      return {
        assetId, text, startTime, revisionId,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default editTranscript;
