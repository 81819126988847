export default function createTemmateOptions(teammates) {
  const teammateOptions = teammates.reduce((acc, teammate) => {
    const options = [...acc];
    const option = {
      value: teammate._id,
      avatar: teammate.avatar,
      label: teammate.displayName,
    };
    options.push(option);
    return options;
  }, [
    {
      value: 'any',
      avatar: null,
      label: 'Any User',
    },
    {
      value: 'allTeammates',
      avatar: null,
      label: 'Any Teammate',
    },
    {
      value: 'webUsers',
      avatar: null,
      label: 'Web Users',
    },
  ]);

  return teammateOptions;
}
