import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Set flag to assets
 * @param {string[]} assetIds
 * @param {string} value - must be one of "flagged | unflagged | rejected"
 * @returns Promise<AxiosResponse>
 */
export default function setFlag(assetIds, value) {
  if (picsioConfig.isMainApp) return sdk.assets.setFlag(assetIds, value);

  return sdk.assets.setFlagPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    value,
  );
}
