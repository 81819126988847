import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import { reloadCurrentPage } from '../../../helpers/history';

const updateWatermark = (data) => async (dispatch, getAll) => {
  const {
    name, description, type, file, text, isDefault, size, opacity, position, _id,
  } = data;
  const form = new FormData();
  form.append('name', name);
  if (description) {
    form.append('description', description);
  }
  form.append('type', type);
  if (type === 'image' && file && file?.name) {
    form.append('file', file, file.name);
  }
  form.append('text', text);
  form.append('isDefault', isDefault);
  form.append('size', size);
  form.append('opacity', opacity);
  form.append('position', position);
  form.append('_id', _id);
  const { items } = getAll().assets;
  try {
    UiBlocker.block();
    const { data: res } = await sdk.watermarks.update(_id, form, false);
    const { success } = res;
    if (success) {
      const itemIds = items.filter(
        (item) => item.watermarkId === _id,
      )
        .map((item) => item._id);
      dispatch({
        type: TYPES.ASSETS.CHANGE_WATERMARK,
        payload: {
          watermarkId: _id,
          itemIds,
          keys: Object.keys(data),
          values: Object.values(data),
        },
      });
    }
    UiBlocker.unblock();
  } catch (error) {
    UiBlocker.unblock();
    const errorStatus = utils.getStatusFromResponceError(error);
    const { data: message } = error.response;
    if (errorStatus === 412) {
      showDialog({
        title: localization.WATERMARKS.titleUpdateWatermark,
        text: localization.WATERMARKS.textUpdateWatermark(message.assetsCount),
        textBtnOk: localization.WATERMARKS.btnOkWatermark,
        textBtnCancel: localization.DIALOGS.btnCancel,
        async onOk() {
          try {
            UiBlocker.block();
            const { data: res } = await sdk.watermarks.update(_id, form, true);
            const { success } = res;
            if (success) {
              const itemIds = items.filter(
                (item) => item.watermarkId === _id,
              )
                .map((item) => item._id);
              dispatch({
                type: TYPES.ASSETS.CHANGE_WATERMARK,
                payload: {
                  watermarkId: _id,
                  itemIds,
                  keys: Object.keys(data),
                  values: Object.values(data),
                },
              });
            }
            UiBlocker.unblock();
          } catch (err) {
            UiBlocker.unblock();
            Toast(localization.WATERMARKS.errorDeletingWatermark, { type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
            Logger.error(new Error('Error updating watermark'), { err, showDialog: false }, [
              'ErrorUpdatingWatermark',
              (err && err.message) || 'NoMessage',
            ]);
          }
        },
        onCancel() {
          UiBlocker.unblock();
        },
      });
    }
  }
};

export default updateWatermark;
