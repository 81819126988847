import * as userActions from '../../reducers/user';
import { showUploadFileErrorDialog } from '../../../helpers/fileUploader';
import sdk from '../../../sdk';
import Logger from '../../../services/Logger';

const uploadAvatar = (file) => async (dispatch) => {
  if (typeof file !== 'object') return;
  try {
    dispatch(userActions.updateUserStart({ updating: true }));
    const { promise } = sdk.users.uploadAvatar(file);
    const { data } = await promise;
    const { url } = data;
    dispatch(userActions.updateUserSuccess({ result: { avatar: url } }));
    const customEvent = new CustomEvent('userAvatarSet', { detail: url });
    window.dispatchEvent(customEvent);
  } catch (err) {
    showUploadFileErrorDialog(err);
    Logger.error(new Error('Can not upload user avatar'), { error: err, fileName: file.name }, [
      'UpdateUserFailed',
      (err && err.message) || 'Can not upload avatar',
    ]);
    dispatch(userActions.updateUserFailure());
  }
};

export default uploadAvatar;
