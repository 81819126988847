import { createAsyncThunk } from '@reduxjs/toolkit';

const facesGenerated = createAsyncThunk(
  'asset/facesGenerated',
  (assets, {
    extra: {
      Logger, utils, showErrorDialog,
    },
  }) => {
    try {
      return { assets };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not apply face on asset';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error applying face on asset'),
        { error },
        ['ApplyFaceOnAssetFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export default facesGenerated;
