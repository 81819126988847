import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Icon } from '@picsio/ui';
import { WebsiteCustom } from '@picsio/icons';
import PropTypes from 'prop-types';
import s from './PresetsList.module.scss';
import PresetsItem from '../../Websites/Presets/PresetsItem';
import PresetSkeleton from '../../Websites/Presets/PresetSkeleton';
import l18n from '../../../shared/strings';

const PresetsList = ({ checkedPreset = null, onCheckPreset }) => {
  const themes = useSelector((state) => state.websiteSettings.themes);
  const presets = useSelector((state) => state.websiteSettings.presets);

  const resetCheckedPreset = useCallback(() => {
    onCheckPreset(null);
  }, [onCheckPreset]);

  return (
    <ul className={s.PresetsList}>
      <Choose>
        <When condition={presets && themes}>
          <li className={cn(s.PresetsItem, !checkedPreset && s.active)} onClick={resetCheckedPreset} role="presentation" data-testid="btnCreateCustomSettings">
            <div className={s.defaultImage}>
              <Icon size="xxxl">
                <WebsiteCustom />
              </Icon>
            </div>
            <div className={s.titleContainer}>
              <div className={s.title}>{l18n.DIALOGS.PUBLISH_WEBSITE.DEFAULT_PRESET}</div>
            </div>
          </li>
          {presets.map((preset) => {
            const currentTheme = themes.find((theme) => theme.key === preset.settings.template);
            return (
              <PresetsItem
                key={preset._id}
                isActive={checkedPreset && checkedPreset._id === preset._id}
                image={currentTheme?.img}
                name={preset?.name}
                preset={preset}
                onApplyPreset={onCheckPreset}
              />
            );
          })}
        </When>
        <Otherwise>
          <PresetSkeleton />
          <PresetSkeleton />
        </Otherwise>
      </Choose>

    </ul>
  );
};

PresetsList.propTypes = {
  checkedPreset: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
    }),
    PropTypes.instanceOf(null),
  ]),
  onCheckPreset: PropTypes.func.isRequired,
};

export default memo(PresetsList);
