const collectionIdsAreEqual = (activeCollections, collectionIds) => {
  let collectionIdsArray = [];
  if (!Array.isArray(collectionIds)) {
    collectionIdsArray = [collectionIds];
  } else {
    collectionIdsArray = [...collectionIds];
  }
  if (activeCollections.length !== collectionIdsArray.length) {
    return false;
  }
  for (let i = 0; i < activeCollections.length; i++) {
    if (collectionIdsArray[i] !== activeCollections[i]._id) {
      return false;
    }
  }
  return true;
};

export default collectionIdsAreEqual;
