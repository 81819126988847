import { Preferences } from '@capacitor/preferences';

class MobileStorage {
  storage = Preferences;

  async set(key, value) {
    await this.storage.set({ key, value });
  }

  async get(key) {
    const { value } = await this.storage.get({ key });
    return value;
  }

  async remove(key) {
    await this.storage.remove({ key });
  }
}

export default new MobileStorage();
