import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import picsioConfig from '../../../../../../config';
import localization from '../../../shared/strings';

import Mentions from './Mentions';

const HistoryTextarea = forwardRef(({ onKeyDown, onBlur, onFocus, isError }, ref) => {
  const errorClassName = isError ? 'PicsioInput--error' : '';
  const textareaRender = (
    <div
      ref={ref}
      className={`commentTextarea ${errorClassName}`}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      placeholder={localization.HISTORY.textPlaceholderAddComment}
      role="textbox"
      aria-label="Add comment"
      tabIndex={0}
      contentEditable
      onFocus={onFocus}
    />
  );

  return (
    <Choose>
      <When condition={picsioConfig.isMainApp}>
        <Mentions>
          {textareaRender}
        </Mentions>
      </When>
      <Otherwise>
        {textareaRender}
      </Otherwise>
    </Choose>
  );
});

HistoryTextarea.defaultProps = {
  onKeyDown: () => {},
  onBlur: () => {},
  onFocus: () => {},
  isError: false,
};

HistoryTextarea.propTypes = {
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isError: PropTypes.bool,
};

export default HistoryTextarea;
