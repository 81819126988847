import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { reloadCurrentPage } from '../../../helpers/history';

const setDefaultWatermark = (_id) => async (dispatch) => {
  try {
    UiBlocker.block();
    const { data } = await sdk.watermarks.setDefault(_id);
    const { success } = data;
    if (success) {
      dispatch({
        type: TYPES.ASSETS.SET_DEFAULT_WATERMARK,
        payload: {
          watermarkId: _id,
        },
      });
    } else {
      Toast(localization.WATERMARKS.errorMakeWatermarkDefault, { type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      throw Error();
    }
    UiBlocker.unblock();
  } catch (error) {
    UiBlocker.unblock();
    Logger.error(new Error('Error setting watermark default'), { error, showDialog: false }, [
      'SetDefaultWatermarkFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};

export default setDefaultWatermark;
