/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getThemes = createAsyncThunk(
  'websiteSettings/getThemes',
  // eslint-disable-next-line consistent-return
  async (_, {
    extra: {
      Logger, sdk, utils, showErrorDialog,
    },
  }) => {
    try {
      const { data } = await sdk.websites.fetchTemplates();
      Logger.log('UI', 'WebsiteThemesLoaded');
      return data;
    } catch (error) {
      Logger.log('UI', 'WebsiteThemesNotLoaded');
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not be loaded themes';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error website themes fetching'), { error }, [
        'FetchWebsiteThemesFailed',
        'Website themes can not be fetched from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getThemes.pending,
      (state) => {
        state.error = null;
      },
    ).addCase(
      getThemes.fulfilled,
      (state, { payload }) => {
        state.themes = payload;
        state.error = null;
      },
    ).addCase(
      getThemes.rejected,
      (state, { error }) => {
        state.themes = null;
        state.error = error;
      },
    );
};
