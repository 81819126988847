import TYPES from '../../action-types';

/**
 * Collection removed by notification
 * @param {string} collectionID
 */
const collectionRemoving = (collectionID) => (dispatch) => dispatch({
  type: TYPES.COLLECTIONS.REMOVE.INPROGRESS,
  payload: { collectionID },
});

export default collectionRemoving;
