/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { navigate } from '../../../../helpers/history';
import Toast from '../../../../components/Toast';
import * as utils from '../../../../shared/utils';
import { showDialog } from '../../../../components/dialog';
import localization from '../../../../shared/strings';
import sdk from '../../../../sdk';
import { isHaveTeammatePermission } from '../../../helpers/user';

const sendAssetsToTranscribe = createAsyncThunk(
  'asset/sendToTranscribe',
  async (
    { assetIds, language }, { rejectWithValue, getState },
  ) => {
    const { user, roles, teammates } = getState();
    const billingDenied = !isHaveTeammatePermission('manageBilling', user);
    const keywordingDenied = !isHaveTeammatePermission('manageKeywords', user);
    const teamManagingDenied = !isHaveTeammatePermission('manageTeam', user);

    const canNotBuyApiCalls = billingDenied || keywordingDenied || teamManagingDenied;
    const usersWithPermissionsToBuyApiCalls = utils.getUsersWithPermissionToBuyKeywords(user, teammates.items, roles.items);

    try {
      await sdk.assets.transcribeSpeechToText(assetIds, language);
      if (assetIds.length >= 2) {
        Toast(localization.TRANSCRIPT.TOASTS.textTranscribeIPanelInProgress(assetIds.length), { closeButton: false });
      } else {
        Toast(localization.TRANSCRIPT.TOASTS.textTranscribeTPanelInProgress, { closeButton: false });
      }
      return { assetIds };
    } catch (error) {
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      if (errorSubcode === 'FeatureNotAvailableForPlanError') {
        showDialog({
          title: localization.TRANSCRIPT.DIALOGS.titleUpgradePlan,
          text: localization.TRANSCRIPT.DIALOGS.textUpgradePlan,
          textBtnOk: localization.TRANSCRIPT.DIALOGS.btnUpgradePlan,
          onOk() {
            navigate('/billing?tab=overview');
          },
          textBtnCancel: null,
        });
      } else if (errorSubcode === 'NotEnoughPaidApiCallsError') {
        showDialog({
          title: localization.TRANSCRIPT.DIALOGS.titleNotEnoughAICredits,
          text: localization.TRANSCRIPT.DIALOGS.textNotEnoughAICredits(canNotBuyApiCalls, usersWithPermissionsToBuyApiCalls),
          textBtnOk: canNotBuyApiCalls ? localization.DIALOGS.btnOk : localization.TRANSCRIPT.DIALOGS.btnNotEnoughAICredits,
          onOk() {
            canNotBuyApiCalls ? Function.prototype() : navigate('/billing?tab=overview');
          },
          textBtnCancel: null,
        });
      }
      return rejectWithValue(error);
    }
  },
);

export default sendAssetsToTranscribe;
