import dayjs from 'dayjs';
import normalizeUserAvatarSrc from './normalizeUserAvatarSrc';
import getStatus from './getStatus';
import localization from '../../../shared/strings';

/**
 * Normalize user
 * @param {Object},
 */
export default function normalizeUser(user, roles) {
  const { parent } = user;
  const teammateRoleId = parent && parent.teammateRoleId;

  let roleName = null;
  let isRoleError = null;
  if (teammateRoleId) {
    const role = roles.find((role) => role._id === teammateRoleId);
    roleName = role && role.name;
    isRoleError = role && role.error;
  }

  return {
    _id: user._id,
    displayName: user.displayName,
    email: user.email.toLowerCase(),
    avatarOriginal: user.avatar || '',
    avatar: normalizeUserAvatarSrc(user.avatar, 'large'),
    isOwner: !roleName,
    parent,
    roleName: roleName || localization.TEAMMATES.textTeamOwner,
    isRoleError,
    roleId: teammateRoleId,
    isActive: user.act,
    status: !roleName ? 'owner' : getStatus(user),
    slackUserId: user.slackUserId || '',
    facebookUrl: user.facebookUrl || '',
    phone: user.phone || '',
    position: user.position || '',
    about: user.about || '',
    twoFactorConfigured: user.twoFactorConfigured || false,
    visitedAt:
			user.parent && user.parent.confirmed === true && user.visitedAt ? dayjs().from(user.visitedAt) : localization.TEAMMATES.textNoAnswer,
    personalTwoFactorEnabled: user.personalTwoFactorEnabled || false,
    online: user.online,
  };
}
