import * as utils from '../../shared/utils';
import store from '../../store';
import l10n from '../../shared/strings';
import { isSelectedAssetsRestricted } from '../../shared/utils';
import DialogRadios from '../dialogRadios';

const MOVE_COOKIE_KEY = 'picsio.moveOrCopyDialogVisible';

/**
 * Get action for dropped assets (MOVE or COPY)
 * @param {string} collectionName
 * @param {boolean} isInboxActive
 * @param {boolean} altKey
 * @returns {Promise<string|null>}
 */
// eslint-disable-next-line import/prefer-default-export
export async function getActionForDroppedAssets(collectionName, isInboxActive, altKey, numberOfAssets, isLightboard = false) {
  if (isInboxActive) return 'MOVE';

  const cookieValue = utils.getCookie(MOVE_COOKIE_KEY);
  if (typeof cookieValue === 'string') return cookieValue;

  return new Promise((resolve) => {
    // eslint-disable-next-line no-new
    new DialogRadios({
      title: l10n.DIALOGS.MOVE_ASSETS_DIALOG.TITLE,
      text: l10n.DIALOGS.MOVE_ASSETS_DIALOG.TEXT(numberOfAssets),
      items: [
        {
          label: l10n.DIALOGS.MOVE_ASSETS_DIALOG.COPY_RADIO.LABEL(collectionName),
          value: 'COPY',
          checked: !altKey,
          description: l10n.DIALOGS.MOVE_ASSETS_DIALOG.COPY_RADIO.DESCRIPTION,
        },
        {
          label: l10n.DIALOGS.MOVE_ASSETS_DIALOG.MOVE_RADIO.LABEL(collectionName),
          value: 'MOVE',
          checked: altKey,
          description: l10n.DIALOGS.MOVE_ASSETS_DIALOG.MOVE_RADIO.DESCRIPTION,
        },
      ],
      checkbox: {
        value: false,
        label: l10n.DIALOGS.MOVE_ASSETS_DIALOG.CHECKBOX_LABEL,
        onChange: () => { },
      },
      cancelName: l10n.DIALOGS.MOVE_ASSETS_DIALOG.CANCEL_TEXT,
      okName: l10n.DIALOGS.MOVE_ASSETS_DIALOG.OK_TEXT,
      onOk: (value, checkboxValue) => {
        if (checkboxValue) utils.setCookie(MOVE_COOKIE_KEY, value);
        resolve(value);
      },
      onCancel: resolve,
      onClose: resolve,
    });
  });
}

export function checkRestrictedAssets() {
  const { assets, user } = store.getState();
  const { items, selectedItems } = assets;
  const selectedAssets = items.filter(({ _id }) => selectedItems.includes(_id));

  return {
    selectedAssetIds: selectedItems,
    hasRestrictedAndNoPermission:
      isSelectedAssetsRestricted(selectedAssets)
      && user?.role?.permissions?.restrictedMoveOrDelete === false,
  };
}

export function findLevel(nodes, id) {
  let result = [];
  function loop(collection, _, items) {
    if (collection._id === id) {
      result = items.map(({ _id }) => _id);
      return false;
    }
    if (collection.nodes) collection.nodes.every(loop);
    return true;
  }

  if (nodes) nodes.every(loop);

  return result;
}
