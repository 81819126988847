/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const renamedPresetEvent = createAction('websiteSettings/renamedPresetEvent');

export const reducer = (builder) => {
  builder.addCase(
    renamedPresetEvent.type,
    (state, { payload: { presetId, newName } }) => {
      state.presets.forEach((preset) => {
        if (preset._id === presetId) {
          preset.name = newName;
        }
      });
    },
  );
};
