import { createAsyncThunk } from '@reduxjs/toolkit';

import { back } from '../../helpers/history';
import { showDialog } from '../../components/dialog';
import l18n from '../../shared/strings';

export const downgrade = createAsyncThunk(
  'billing/downgrade',
  async (payload, { rejectWithValue, extra: { sdk, Logger } }) => {
    const {
      currentPlanName, newPlanName,
    } = payload;

    try {
      await sdk.billing.downgrade(currentPlanName, newPlanName);
      // eslint-disable-next-line no-nested-ternary
      const action = 'plan downgrade';
      const text = `We’ve received your ${action} request. Our Support Team will contact you shortly.`;

      Logger.log('UI', 'BillingDowngradeWarningSuccessDialog', { userDialogueMessage: text });
      showDialog({
        title: l18n.DIALOGS.BILLING_DOWNGRADE.TITLE,
        text: l18n.DIALOGS.BILLING_DOWNGRADE.TEXT(action),
        textBtnCancel: null,
        onOk: () => {
          Logger.log('User', 'BillingDowngradeWarningSuccessDialogOk');
          back('/search');
        },
        onCancel: () => Logger.log('User', 'BillingDowngradeWarningSuccessDialogCancel'),
      });

      return true;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/* eslint-disable no-param-reassign */
export const reducer = (builder) => {
  builder
    .addCase(
      downgrade.pending,
      (state) => {
        state.downgrading = true;
      },
    )
    .addCase(
      downgrade.fulfilled,
      (state) => {
        state.downgrading = false;
      },
    )
    .addCase(
      downgrade.rejected,
      (state) => {
        state.error = true;
      },
    );
};
/* eslint-enable no-param-reassign */
