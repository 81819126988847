import { NEW_PATH_DELIMITER } from '@picsio/db/src/constants';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';

/**
 * Make SavedSearches tree from list
 * @param {Object[]} savedSearchesTree
 * @returns {Object}
 */
export default function makeTree(items, userId) {
  const list = items.map((svdSearch) => ({ ...svdSearch }));
  const tree = {
    favorites: {
      name: localization.SAVEDSEARCHESTREE.textFavorites,
      path: 'favorites',
      root: true,
      isOpen:
				utils.getCookie('picsio.savedSearchedTree.favorites.open') === null
				|| utils.getCookie('picsio.savedSearchedTree.favorites.open'),
      nodes: [],
    },
    savedSearches: {
      name: localization.SAVEDSEARCHESTREE.textSavedSearches,
      path: 'savedSearches',
      root: true,
      isOpen:
				utils.getCookie('picsio.savedSearchedTree.savedSearches.open') === null
				|| utils.getCookie('picsio.savedSearchedTree.savedSearches.open'),
      nodes: [],
    },
  };

  // turning plain savedSearch array to tree
  const savedSearchesTree = [];
  const mapSavedSearches = { savedSearchesTree };

  list.forEach((svdSearch) => {
    const pathArr = svdSearch?.path?.split(NEW_PATH_DELIMITER);
    if (!pathArr) {
      mapSavedSearches.savedSearchesTree.push({
        ...svdSearch,
      });
      return;
    }

    pathArr?.shift();
    const pathArrLength = pathArr.length;

    pathArr?.reduce((acc, name, i) => {
      if (!acc[name]) {
        acc[name] = { savedSearchesTree: [] };
        // this check to make sure that data of the saved search goes only to the child
        if (pathArrLength - 1 === i) {
          acc.savedSearchesTree.push({
            ...svdSearch,
            name,
            buttons: pathArrLength <= 1,
          });
        } else {
          acc.savedSearchesTree.push({
            _id: `tree${svdSearch._id}`,
            name,
            buttons: pathArrLength <= 1,
            nodes: acc[name].savedSearchesTree,
          });
        }
      }
      return acc[name];
    }, mapSavedSearches);
  });

  tree.savedSearches.nodes = savedSearchesTree;
  tree.favorites.nodes = list.filter(
    (node) => node.favorites !== undefined && node.favorites.length > 0 && node.favorites.includes(userId),
  );

  return tree;
}
