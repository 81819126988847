import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import sdk from '../../../sdk';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import Logger from '../../../services/Logger';

import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';
/**
 * unAssign user from assets
 * @param {string} assigneeId
 * @param {string[]?} ids - assets ids
 */
const unAssignUser = (assigneeId, ids) => async (dispatch, getAll) => {
  const selectedItems = ids || getAll().assets.selectedItems;
  try {
    await asyncCheckMultipleChanges(ids.length);
  } catch (error) {
    /** User cancelled operation */
    return;
  }

  try {
    dispatch({ type: TYPES.ASSETS.UNASSIGN_USER.START });

    /** wait for response */
    await sdk.assets.unassign(assigneeId, selectedItems);

    dispatch({
      type: TYPES.ASSETS.UNASSIGN_USER.COMPLETE,
      payload: { assigneeId, ids },
    });
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : utils.getDataFromResponceError(error, 'msg');
    if (errorMessage) {
      Toast(errorMessage, { autoClose: false, type: 'error' });
    } else {
      Toast(localization.DETAILS.textCantUpdateAssignees, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
    }
    Logger.error(new Error('Error unassing user'), { error }, [
      'UnassignUserFailed',
      (error && error.message) || 'NoMessage',
    ]);
    dispatch({ type: TYPES.ASSETS.UNASSIGN_USER.FAILED, error });
  }
};

export default unAssignUser;
