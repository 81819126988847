import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Collapse, Button } from '@picsio/ui';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Tag from '../Tag';
import { normalizeUserAvatarSrc } from '../../store/helpers/teammates';
import syncFolder from '../../store/actions/collections/syncFolder';
import picsioConfig from '../../../../../config';
import { checkUserAccess } from '../../store/helpers/user';
import l18n from '../../shared/strings';

const MainInfo = (props) => {
  const {
    isOpen = true, toggleCollapseVisibility, teammates, collection,
  } = props;
  const dispatch = useDispatch();
  const [disableSyncButton, setDisableSyncButton] = useState(false);
  const user = useSelector((state) => state.user);

  const {
    createdBy, createdAt, updatedAt, syncedAt,
  } = collection;
  // teammate can be deleted, so we can't find info about this user
  const isCreatorTeammate = teammates.find((teammate) => teammate._id === createdBy);

  const handleFolderSync = useCallback(async (event) => {
    setDisableSyncButton(true);
    event.stopPropagation();
    await dispatch(syncFolder({ collectionId: collection._id }));
    setDisableSyncButton(false);
  }, [collection._id, dispatch]);

  return (
    <Collapse
      fontSize="md"
      isOpen={isOpen}
      onClick={() => {
        toggleCollapseVisibility('maininfo');
      }}
      title={l18n.DETAILS.textMainInfo}
      transition
    >
      <div className="PicsioCollapse__content--inner">
        <If condition={createdBy && isCreatorTeammate}>
          <div className="InfoPanel__Row">
            <span className="InfoPanel__Row--label" />
            <StyledTag
              type="user"
              avatar={normalizeUserAvatarSrc(
                teammates.find((user) => user._id === createdBy).avatarOriginal,
                'small',
                true,
              )}
              text={teammates.find((user) => user._id === createdBy).displayName}
            />
          </div>
        </If>
        <div className="InfoPanel__Row">
          <span className="InfoPanel__Row--label">{l18n.DETAILS.textCreatedAt}</span>
          {dayjs(createdAt).format('ll')}
        </div>
        <div className="InfoPanel__Row">
          <span className="InfoPanel__Row--label">{l18n.DETAILS.textUpdatedAt}</span>
          {dayjs(updatedAt).format('ll')}
        </div>
        <If condition={syncedAt}>
          <div className="InfoPanel__Row">
            <span className="InfoPanel__Row--label">{l18n.DETAILS.textSyncedAt}</span>
            {dayjs(syncedAt).format('ll')}
          </div>
        </If>
        <If condition={picsioConfig.isMainApp
        && collection.path !== 'root'
        && checkUserAccess('permissions', 'sync', null, user)
        && (user.team.storageType === 'gd' || !user.team.picsioStorage)}
        >
          <Button
            onClick={handleFolderSync}
            variant="contained"
            color="primary"
            size="md"
            disabled={disableSyncButton}
          >
            {l18n.DETAILS.textSyncButton}
          </Button>
        </If>
      </div>
    </Collapse>
  );
};

MainInfo.propTypes = {
  isOpen: PropTypes.bool,
  teammates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      avatar: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ).isRequired,
  collection: PropTypes.shape({
    createdBy: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    syncedAt: PropTypes.string,
    path: PropTypes.string,
    _id: PropTypes.string,
  }).isRequired,
  toggleCollapseVisibility: PropTypes.func.isRequired,
};

export default memo(MainInfo);

const StyledTag = styled(Tag)`
  margin: 0;
`;
