import _isEqual from 'lodash/isEqual';
import * as userActions from '../../reducers/user';
import {
  checkIsKeywordsActionsAllowed,
  checkIsSyncAllowed,
} from '../../helpers/user';
import Logger from '../../../services/Logger';
import { setRequiredFields } from '../../import/actions';
import sdk from '../../../sdk';

const updateUser = (data, setImmediately = true) => async (dispatch, getState) => {
  if (setImmediately) {
    const { user } = getState();
    const result = { ...data };
    const { role } = data;
    if (role) {
      result.isSyncAllowed = checkIsSyncAllowed(role, null, getState);
      result.isKeywordsActionsAllowed = checkIsKeywordsActionsAllowed(
        role.permissions,
        user.team.policies,
      );

      const oldRequiredFields = user?.role?.requiredFields;
      const newRequiredFields = role?.requiredFields;
      if (!_isEqual(oldRequiredFields, newRequiredFields)) {
        dispatch(setRequiredFields({
          role,
          features: user.subscriptionFeatures,
        }));
      }
    }

    dispatch(userActions.updateUserSuccess({ result }));
    return;
  }
  try {
    dispatch(userActions.updateUserStart({ updating: true }));
    const { data: response } = await sdk.users.update(data);
    if (response) {
      dispatch(userActions.updateUserSuccess({ result: data }));
    } else {
      dispatch(userActions.updateUserFailure());
    }
  } catch (err) {
    Logger.error(new Error('Can not update user'), { error: err, data }, [
      'UpdateUserFailed',
      (err && err.message) || 'NoMessage',
    ]);
    dispatch(userActions.updateUserFailure());
  }
};

export default updateUser;
