const getStorageType = (user) => {
  const isUserOnS3 = () => user?.team?.storageType === 's3';
  const isUserOnPicsio = () => user?.team?.picsioStorage;
  if (isUserOnS3()) {
    if (isUserOnPicsio()) {
      return { storageType: 'picsio' };
    }
    return { storageType: 's3' };
  }
  return { storageType: 'gd' };
};

export default getStorageType;
