import TYPES from '../../action-types';
import addHighlight from './addHighlight';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';

/**
 * Add assets to collection immediately
 * @param {Object} params
 * @param {string[]} params.ids - assets ids
 * @param {string} _id - collection id
 * @param {string} params.path - collection path
 */

const addedToCollection = ({ ids, _id, path, showToast }) => (dispatch, getAll) => {
  addHighlight(ids, 'collections')(dispatch);
  const { collectionIds: activeCollectionID } = getAll().router.location.query;
  const { user } = getAll();

  dispatch({
    type: TYPES.ASSETS.ADD_COLLECTION.COMPLETE,
    payload: {
      ids,
      collectionID: _id,
      collectionPath: path,
      isTeamDrive: false,
      activeCollectionID,
    },
  });

  if (showToast) {
    let pathWithoutRoot = path;
    if (pathWithoutRoot.includes('root')) {
      pathWithoutRoot = pathWithoutRoot.replace('/root/', '');
    }
    Toast(
      localization.COLLECTIONS.textFilesAddedToCollection(
        ids.length,
        utils.decodeSlash(pathWithoutRoot),
      ),
      { type: 'success', closeButton: false },
    );
  }
};

export default addedToCollection;
