/**
 * Calculate total files size in groups
 * @param {*} groups
 * @returns {number}
 */
const calculateBytesTotalSize = (groups) => Object.values(groups)
  .reduce((total, group) => {
    const groupTotal = group.reduce((gt, i) => gt + (i.file?.size || 0), 0);
    return total + groupTotal;
  }, 0);

export default calculateBytesTotalSize;
