import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../../../sdk';

const addSubtitlesGenerated = createAsyncThunk(
  'user/addSubtitlesGenerated',
  (_, { getState }) => {
    const { subtitlesGenerated } = getState().user.achievements;
    if (!subtitlesGenerated) {
      return sdk.users.setAchievement('subtitlesGenerated');
    }
    return null;
  },
);

export default addSubtitlesGenerated;
