/**
 * Calculate styles for items in dropbox file folder list and make flat array from groups object
 * @param {*} groups
 * @returns
 */
export default function dropboxVirtualizedList(groups) {
  const itemHeight = 25;
  const styles = [0]; /** predefined position for first item (collection path) */
  const flatGroups = [];

  const handleFileItem = (item) => {
    const top = styles[styles.length - 1];
    const nextTop = top + itemHeight;

    styles.push(nextTop);
    // eslint-disable-next-line no-param-reassign
    flatGroups.push({ ...item, top });
  };

  groups.forEach(handleFileItem);

  const wrapperHeight = styles[styles.length - 1];

  return { wrapperHeight, flatGroups, styles };
}
