/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const deletedPresetEvent = createAction('websiteSettings/');

export const reducer = (builder) => {
  builder.addCase(
    deletedPresetEvent.type,
    (state, { payload }) => {
      state.presets = state.presets.filter(({ _id }) => _id !== payload);
    },
  );
};
