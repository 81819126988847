import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import * as assetsHelpers from '../../../helpers/assets';
import findAndResolveDuplicates from '../../helpers/assets/findAndResolveDuplicates';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { showDialog, showErrorDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

import checkMultipleChanges from './checkMultipleChanges';

const FINISH_IN_BACKGROUND_SIZE = 100;

/**
 * Add assets to lightboard
 * @param {string} lightboardID
 * @param {string} lightboardPath
 * @param {Boolean} isMove
 * @param {string[]?} assetIDs - assets ids
 */
const addToLightboard = ({
  lightboardID, lightboardPath, assetIDs, isMove = false,
}) => (
  dispatch,
  getAll,
) => {
  const { user, assets } = getAll();
  const ids = assetIDs || assets.selectedItems;
  const { items } = assets;
  const { team } = user;
  const isUsedS3Storage = team.storageType === 's3';
  const spinnerText = localization.LIGHTBOARDSTREE.textAttachingToLightboard;
  checkMultipleChanges(ids.length, doAdd, undefined);

  async function doAdd() {
    let idsToChange = ids;
    let actions;
    if (isUsedS3Storage && isMove) {
      /** Check duplicated filenames */
      try {
        let assets;
        if (items.length < ids.length) {
          const { data: res } = await assetsHelpers.selectAll({ name: 1 });
          assets = res.images;
        } else {
          assets = items;
        }
        const assetsToChange = assets.filter((a) => ids.includes(a._id));
        const resolveResult = await findAndResolveDuplicates({
          assets: assetsToChange,
          lightboardPath,
          user,
        });
        idsToChange = resolveResult.assets.map((a) => a._id);
        actions = resolveResult.actions;
      } catch (error) {
        /** User press "Cancel" */
        if (error?.status === 'CANCELED') return;
        /** if "Too many items to analyze" ( more than 10 000 ) */
        const errorStatus = utils.getStatusFromResponceError(error);
        if (errorStatus === 413) {
          const { TITLE, TEXT, CANCEL_TEXT } = localization.DIALOGS.LIMIT_CHECK_DUPLICATES;
          showDialog({
            title: TITLE,
            text: TEXT,
            textBtnCancel: CANCEL_TEXT,
            textBtnOk: null,
            style: { maxWidth: 600 },
          });
        } else {
          Logger.error(
            new Error('Can not find duplicates on the server [Move to lightboard]'),
            { error, showDialog: true },
            ['FindDuplicatesFailed', (error && error.message) || 'NoMessage'],
          );
        }
        /** IF CAN NOT RESOLVE DUPLICATES -> EXIT */
        return;
      }
      /** do nothing if all assets skipped */
      if (idsToChange.length < 1) return;
    }
    UiBlocker.block(spinnerText);
    try {
      dispatch({ type: TYPES.ASSETS.ADD_LIGHTBOARD.START });
      await sdk.assets.attachToLightboard(idsToChange, lightboardID, isMove, actions);

      dispatch({
        type: TYPES.ASSETS.ADD_LIGHTBOARD.COMPLETE,
        payload: {
          assetIDs: idsToChange,
          lightboardID,
          lightboardPath,
          isMove,
          isTeamDrive: false,
          userId: user._id,
        },
      });

      if (idsToChange.length > FINISH_IN_BACKGROUND_SIZE) {
        Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
      } else {
        Toast(
          localization.COLLECTIONS.textFilesAddedToLightboard(
            decodeURIComponent(lightboardPath.split('→').pop()),
          ), { type: 'success' },
        );
      }
    } catch (error) {
      let text = localization.COLLECTIONS.textFilesNotAddedToLightboard(
        lightboardPath.split('→').pop(),
      );
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 409) {
        /* if lightboard folder not found */
        text = localization.COLLECTIONS.textLightboardFolderNotFound;
      } else if (errorStatus === 403) {
        /* if no permissions */
        text = localization.NO_PERMISSION_TO_ACCESS;
      } else {
        Logger.error(new Error('Can not attach asset to lightboard'), { error }, [
          'AttachAssetToLightboardFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }

      showErrorDialog(text);
      dispatch({
        type: TYPES.ASSETS.ADD_LIGHTBOARD.FAILED,
        error,
      });
    }
    UiBlocker.unblock();
  }
};

export default addToLightboard;
