import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Icon, IconButton } from '@picsio/ui';
import { Folder, FolderNotFilled, Close } from '@picsio/icons';

import { bool, func, string } from 'prop-types';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';

const Collection = ({
  onRemove, path, _id, isActive,
}) => {
  const preparedPath = useMemo(() => path.replace('/root/', ''), [path]);
  const name = useMemo(() => preparedPath.split('/').pop(), [preparedPath]);
  const handleRemove = useCallback(() => {
    onRemove(preparedPath, _id);
  }, [preparedPath, _id, onRemove]);

  const tooltipText = useMemo(
    () => (isActive ? `${preparedPath} (current location)` : preparedPath),
    [isActive, preparedPath],
  );

  return (
    <li className={cn({ [styles.active]: isActive })}>
      <div className={styles.nameHolder}>
        <Icon color="primary" size="lg">
          <Choose>
            <When condition={isActive}>
              <Folder />
            </When>
            <Otherwise>
              <FolderNotFilled />
            </Otherwise>
          </Choose>
        </Icon>
        <Tooltip content={tooltipText} placement="top">
          <div className={styles.name}>{name}</div>
        </Tooltip>
      </div>
      <Tooltip content="Remove">
        <IconButton onClick={handleRemove} size="lg" className={styles.removeButton}><Close /></IconButton>
      </Tooltip>
    </li>
  );
};
Collection.defaultProps = {
  onRemove: () => { },
  isActive: false,
};

Collection.propTypes = {
  path: string.isRequired,
  _id: string.isRequired,
  onRemove: func,
  isActive: bool,
};

export default Collection;
