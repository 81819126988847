import React from 'react'; // eslint-disable-line
import './styles.scss';

const Spinner = () => (
  <div className="picsioSpinner">
    <div className="innerPicsioSpinner">
      <div className="spinner transcriptSpinner">
        <div />
        <span />
      </div>
    </div>
  </div>
);

export default Spinner;
