import React, {
  memo, useState, Fragment, useEffect, useRef, useCallback,
} from 'react';
import cn from 'classnames';
import { Input, IconButton } from '@picsio/ui';
import { Edit } from '@picsio/icons';
import {
  func,
  bool,
  string,
  oneOfType,
  array,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import DT from 'duration-time-conversion';
import { parseTime } from '../../../../shared/utils';
import { editTranscript } from '../../../../store/actions/assets';
import Logger from '../../../../services/Logger';

function TranscriptItem({
  isActive, setVideoPlayerCurrentTime, startTime, text, assetId, activeRevisionID = null, manageTranscriptPermission,
}) {
  const [isEditMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentValue, setValue] = useState(text);
  const dispatch = useDispatch();
  const itemRef = useRef();

  useEffect(() => {
    if (isActive) {
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [isActive]);

  const handleChange = (e) => {
    const { value } = e.currentTarget;
    setValue(value);
  };

  const handleKeyDown = async (e) => {
    const keyEsc = 27;
    const keyEnter = 13;

    if (e.keyCode === keyEsc) {
      setValue(text);
      setEditMode(false);
    }
    if ((e.keyCode === keyEnter) && (text !== e.target.value)) {
      setEditMode(false);
      setLoading(true);
      await dispatch(editTranscript({
        assetId, startTime, text: e.target.value, revisionId: activeRevisionID,
      }));
      setLoading(false);
    }
  };

  const handleBlur = async (e) => {
    setEditMode(false);
    if (text !== e.target.value) {
      setLoading(true);
      await dispatch(editTranscript({
        assetId, startTime, text: e.target.value, revisionId: activeRevisionID,
      }));
      setLoading(false);
    }
  };

  const handleEditPencil = useCallback(() => {
    Logger.log('User', 'TranscriptEditPencilClicked');
    setEditMode(true);
  }, []);

  const startTimeSeconds = DT.t2d(startTime) + DT.t2d('00:00.100');

  return (
    <li
      className={cn('TranscriptItem', { isActive, isLoading })}
      onClick={(e) => {
        if (e.target.closest('#btnEditSubtitle')) return;
        setVideoPlayerCurrentTime(startTimeSeconds);
      }}
      role="presentation"
      ref={itemRef}
    >
      <Choose>
        <When condition={isEditMode}>
          <Input
            type="text"
            name="search"
            className="TranscriptItem__inputEdit"
            value={currentValue}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            autoFocus
          />
        </When>
        <Otherwise>
          <div className="TranscriptItem__content">
            <span className="TranscriptItem__time">{parseTime(startTimeSeconds)}</span>
            <Choose>
              <When condition={Array.isArray(text)}>
                <span className="TranscriptItem__text">
                  {text.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
                </span>
              </When>
              <Otherwise>
                <span className="TranscriptItem__text">{text}</span>
              </Otherwise>
            </Choose>
          </div>
          <If condition={manageTranscriptPermission}>
            <IconButton
              id="btnEditSubtitle"
              onClick={handleEditPencil}
              size="lg"
              componentProps={{
                'data-testid': 'btnEditSubtitle',
              }}
            >
              <Edit />
            </IconButton>
          </If>
        </Otherwise>
      </Choose>
    </li>
  );
}

TranscriptItem.propTypes = {
  isActive: bool.isRequired,
  startTime: string.isRequired,
  setVideoPlayerCurrentTime: func.isRequired,
  text: oneOfType([string, array]).isRequired,
  assetId: string.isRequired,
  activeRevisionID: string,
  manageTranscriptPermission: bool.isRequired,
};

export default memo(TranscriptItem);
