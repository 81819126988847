import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES from '../../action-types';

/**
 * Select asset(s)
 * @param {string} path
 * @param {string} copyPath
 * @param {boolean} copyChildren
 * @param {string} parentID
 */
const copyCollection = createAsyncThunk(
  'collections.copyCollection',
  async (
    {
      copyingCollectionName, copyChildren, parentID, copyingCollectionId,
    }, {
      dispatch,
      extra: {
        sdk, Logger, showErrorDialog, UiBlocker, localization, utils, Toast,
      },
    },
  ) => {
    try {
      UiBlocker.block('Copying collections...');
      const { data: collection } = await sdk.collections.copyCollection({
        copyingCollectionId,
        parentId: parentID,
        copyChildren,
      });
      collection.path = `${collection.path}${collection.name}`;
      dispatch({
        type: TYPES.COLLECTIONS.ADD.COMPLETE,
        payload: {
          parentID,
          collection,
        },
      });
      Toast(localization.TOAST.COLLECTION_COPIED(copyingCollectionName), { type: 'success' });
    } catch (error) {
      dispatch({
        type: TYPES.COLLECTIONS.ADD.FAILED,
        error,
      });
      Logger.error(new Error('Collection copy error'), { error }, [
        'CopyColletcionFailed',
        (error && error.message) || 'NoMessage',
      ]);
      const errorStatusCode = utils.getStatusFromResponceError(error);
      const errorMsg = utils.getDataFromResponceError(error);

      if (errorStatusCode === 409 && errorMsg.subcode === 'CopyingCollectionChildrenLimitExceeded') {
        showErrorDialog(
          localization.DIALOGS.COPY_COLLECTION_ERRORS.LIMIT_EXCEEDED.TEXT,
          localization.DIALOGS.COPY_COLLECTION_ERRORS.LIMIT_EXCEEDED.TITLE,
        );
      } else {
        showErrorDialog(
          localization.DIALOGS.COPY_COLLECTION_ERRORS.SOMETHING_WENT_WRONG.TEXT,
          localization.DIALOGS.COPY_COLLECTION_ERRORS.SOMETHING_WENT_WRONG.TITLE,
        );
      }
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default copyCollection;
