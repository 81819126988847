import { createAsyncThunk } from '@reduxjs/toolkit';

import { showErrorDialog } from '../../../components/dialog';

const redeemCoupon = createAsyncThunk(
  'billing/redeemCoupon',
  async (
    coupon,
    {
      getState, rejectWithValue, extra: {
        sdk, UiBlocker, Toast, localization, Logger,
      },
    },
  ) => {
    try {
      UiBlocker.block(localization.BILLING.textCouponApplying);

      if (coupon === '15FPOFF' || coupon === '10FPOFF' || coupon === '5FPOFF') {
        const { user } = getState();
        const { isTeammate, team } = user;
        const currentUser = isTeammate ? team : user;
        const {
          createdAt,
          subscriptionFeatures: { planId },
        } = currentUser;
        const today = new Date();
        const startDate = -today.daysAgo(createdAt) < 30;

        if (planId !== 'trial' && !startDate) {
          throw new Error('coupon is expired');
        }
      }
      const { data: result } = await sdk.billing.redeemCoupon(coupon);

      Logger.log('UI', 'SettingsBillingAddCouponSuccess');
      Toast(localization.BILLING.textCouponSuccess(coupon), { type: 'success' });

      return result;
    } catch (e) {
      if (e.message === 'coupon is expired') {
        const userDialogueMessage = `${localization.BILLING.textCoupon} ${coupon} ${localization.BILLING.textCouponCant}`;
        Logger.log('UI', 'SettingsBillingAddCouponFailedDialog', { userDialogueMessage });
        showErrorDialog(userDialogueMessage);
      } else {
        const userDialogueMessage = `${localization.BILLING.textCoupon} ${coupon} ${localization.BILLING.textCouponCant2}`;
        Logger.log('UI', 'SettingsBillingAddCouponFailedDialog', { userDialogueMessage });
        showErrorDialog(userDialogueMessage);
      }
      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default redeemCoupon;
