const isCollection = (query) => !!query?.collectionIds || !!query?.tagId;
const isLightboard = (query) => !!query?.lightboardId;
const isInbox = (query) => !!query?.inboxId;
const isTrashed = (newSearch, oldSearch) => {
  if ((newSearch?.trashed && oldSearch?.trashed) || (!newSearch?.trashed && !oldSearch?.trashed)) return true;
  return false;
};
const isArchived = (newSearch, oldSearch) => {
  if ((newSearch?.archived && oldSearch?.archived) || (!newSearch?.archived && !oldSearch?.archived)) return true;
  return false;
};

/**
 * Checks if the new tree is different type of entity.
 * Collections, faces and keywords are the same entity since the last two are just filters above collections
 * @param {object} newSearch
 * @param {object} oldSearch
 * @returns {boolean}
* */

export default function isDifferentEntity(newSearch, oldSearch) {
  if (!newSearch || !oldSearch) return false;

  const newSearchQuery = newSearch?.query;
  const oldSearchQuery = oldSearch?.query;

  if (isCollection(newSearchQuery) && isCollection(oldSearchQuery)) {
    if (!isTrashed(newSearchQuery, oldSearchQuery)) {
      return true;
    } if (!isArchived(newSearchQuery, oldSearchQuery)) {
      return true;
    } return false;
  }
  if (isLightboard(newSearchQuery) && isLightboard(oldSearchQuery)) return false;
  if (isInbox(newSearchQuery) && isInbox(oldSearchQuery)) return false;
  return true;
}
