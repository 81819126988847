import * as userActions from '../../reducers/user';
import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';

const updateCustomerName = (name) => async (dispatch, getState) => {
  try {
    const { data: result } = await sdk.users.updateCustomerName(name);
    dispatch(
      userActions.updateUserSuccess(
        { result: { customer: { ...getState().user.customer, name } } },
      ),
    );

    return result;
  } catch (error) {
    const errorMessage = utils.getDataFromResponceError(error, 'msg');
    return { error: errorMessage };
  }
};

export default updateCustomerName;
