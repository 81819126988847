import React, { useCallback, useRef, useState } from 'react';
import { useMount } from 'react-use';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { IconButton } from '@picsio/ui';
import { Dragndrop, Eye, EyeClosed } from '@picsio/icons';
import Tooltip from '../../../Tooltip/Tooltip';

const DetailsPanelEditFieldView = (props) => {
  const {
    id, title, isChecked, toggleField, collapsed, collapseGroup, type, hasChildren,
  } = props;

  const [tooltip, setTooltip] = useState(null);

  const $title = useRef();
  const $titleText = useRef();

  const handleChangeVisibility = useCallback(() => toggleField(isChecked, id || title, type), [id, isChecked, title, toggleField, type]);
  const handleCollapseGroup = useCallback(
    () => collapseGroup(id),
    [id, collapseGroup],
  );

  useMount(() => {
    const titleWidth = $title.current && $title.current.getBoundingClientRect().width;
    const titleTextWidth = $titleText.current && $titleText.current.getBoundingClientRect().width;
    if (titleTextWidth > titleWidth) {
      setTooltip(title);
    }
  });

  const isSeparator = type === 'separator';

  return (
    <li
      data-qa={`edit-details-component-${id}`}
      className={clsx('detailsPanelEdit__field', {
        root: isSeparator,
      })}
      tabIndex={0}
    >
      <div className="detailsPanelEdit__field__section">
        <If condition={isSeparator}>
          <Dragndrop fontSize={18} className="detailsPanelEdit__field__dnd" />
        </If>
      </div>
      <div className="detailsPanelEdit__field__section">
        <If condition={isSeparator && hasChildren}>
          <IconButton size="xl" onClick={handleCollapseGroup}>
            <span className={clsx('btnCollapseGroup', { act: collapsed })} />
          </IconButton>
        </If>
      </div>
      <Tooltip
        placement="top"
        extraClassName="tooltip-full-width"
        content={tooltip}
      >
        <div
          className={clsx('detailsPanelEdit__field__text')}
          ref={$title}
        >
          <span ref={$titleText}>
            {title}
          </span>
        </div>
      </Tooltip>
      <IconButton onClick={handleChangeVisibility} size="lg">
        { isChecked ? <Eye /> : <EyeClosed /> }
      </IconButton>
    </li>
  );
};

DetailsPanelEditFieldView.defaultProps = {
  toggleField: Function.prototype,
  collapseGroup: Function.prototype,
  collapsed: false,
  hasChildren: false,
};
DetailsPanelEditFieldView.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  toggleField: PropTypes.func,
  collapsed: PropTypes.bool,
  collapseGroup: PropTypes.func,
  type: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool,
};

export default DetailsPanelEditFieldView;
