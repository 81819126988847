import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tag from '../../Tag';

const FacesChips = (props) => {
  const { faces = [], handleOnClick = null } = props;
  const allFaces = useSelector((state) => state.faces.all);
  const currentFaces = allFaces?.filter((face) => {
    for (let i = 0; i < faces.length; i++) {
      if (faces[i]._id === face._id) {
        return face;
      }
    }
  });
  return (
    <div className="InfoPanel__Row">
      {currentFaces.map((face) => {
        let avatar = null;
        if (face.isAvatarUrlReady) {
          avatar = `/faces/${face._id}/avatar`;
        }
        return (
          <Tag
            onClick={(e) => handleOnClick(face._id)}
            type="user"
            key={face._id}
            avatar={face.avatar || avatar}
            text={face.name}
          />
        );
      })}
    </div>
  );
};

FacesChips.propTypes = {
  faces: PropTypes.arrayOf(PropTypes.any),
  handleOnClick: PropTypes.func,
};

export default FacesChips;
