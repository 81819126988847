/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';
import { sort } from '../helpers/keywords';

let isLoading = false;
export const PAGE_SIZE = 100;
export const getPage = createAsyncThunk(
  'keywordsList/getPage',
  async (payload, {
    extra: {
      sdk, Logger, Toast, utils, localization: l18n,
    }, getState,
  }) => {
    try {
      /** don't load next page if previous is not already loaded */
      if (isLoading) return null;

      const page = typeof payload?.page === 'number' ? payload.page : getState().keywordsList.page;
      const params = {
        from: page * PAGE_SIZE,
        size: PAGE_SIZE,
        sortType: 'name',
        sortOrder: 'asc',
      };

      isLoading = true;
      const { data } = await sdk.keywords.search(params);
      isLoading = false;

      return data;
    } catch (error) {
      isLoading = false;
      Logger.log('UI', 'ToastKeywordsForDropdownNotLoaded');
      Toast(l18n.KEYWORDS.errorLoading, { autoClose: false, type: 'error' });
      const connection = utils.getNavigatorConnectionInfo();
      Logger.error(new Error('Can not get keywords'), { error }, [
        'GetKeywordsFailed',
        { errorMessage: (error && error.message) || 'NoMessage', connection },
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getPage.pending,
      (state, { meta }) => {
        state.isLoaded = false;

        /** if reset keywords */
        if (meta?.arg?.page === 0) {
          state.isFull = false;
          state.items = [];
        }
      },
    ).addCase(
      getPage.fulfilled,
      (state, { payload: keywords, meta }) => {
        /** if no keywords - do not update state */
        if (!keywords) return;

        state.items = sort(_uniqBy([...state.items, ...keywords], '_id'), {
          type: 'name',
          order: 'asc',
        });
        state.page = typeof meta?.arg?.page === 'number' ? meta.arg.page : state.page + 1;

        state.isLoaded = true;
        state.error = null;
        if (keywords.length < PAGE_SIZE) state.isFull = true;
      },
    ).addCase(
      getPage.rejected,
      (state, { error }) => {
        state.error = error;
        state.isLoaded = true;
      },
    );
};
