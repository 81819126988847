/**
 * Generating a data transcript to update an asset
 * @param {Array} items,
 * @param {Object} data,
 * @param {string} eventType
 * @param {string} initiatorId
 * @returns {Array}
 */
export default function fillTranscribingData(items, data, eventType, initiatorId) {
  let assetsByIdLUT = {};

  if (data.assets) {
    assetsByIdLUT = data.assets.reduce((res, asset) => ({ ...res, [asset._id]: asset }), {});
  }
  if (data.asset) {
    assetsByIdLUT[data.asset._id] = data.asset;
  }

  return items.map((item) => {
    const transcribingAsset = assetsByIdLUT[item._id];

    if (!transcribingAsset) return item;

    let updatedTranscripts = [];
    const newTranscript = {
      transcript: transcribingAsset.transcript,
      language: transcribingAsset.language,
      revisionId: transcribingAsset.revisionId,
    };

    if (item.transcripts) {
      const isCurrentTranscript = item.transcripts.findIndex((transcript) => transcript.revisionId === transcribingAsset.revisionId);

      if (isCurrentTranscript !== -1) {
      /**  Updating a transcript for a revision that already exists */
        updatedTranscripts = item.transcripts.map((transcript) => {
          if (transcript.revisionId === transcribingAsset.revisionId) {
            const copyTranscript = { ...transcript };

            copyTranscript.transcript = transcribingAsset.transcript;
            copyTranscript.language = transcribingAsset.language;
            return copyTranscript;
          }
          return transcript;
        });
      } else {
      /** Adding a transcript for a new revision */
        updatedTranscripts = [...item.transcripts, newTranscript];
      }
    } else {
      /**  Adding a transcript for the first revision */
      updatedTranscripts.push(newTranscript);
    }

    switch (eventType) {
    case 'asset.transcribing.complete': {
      return {
        ...item,
        transcribingParams: {
          initiatorId,
        },
        transcripts: updatedTranscripts,
        transcribing: 'complete',
        transcriptViewedByInitiator: !!item.transcriptViewedByInitiator,
      };
    }
    case 'asset.transcribing.chunk_is_ready': {
      return {
        ...item,
        transcribingParams: {
          initiatorId,
        },
        transcripts: updatedTranscripts,
      };
    }
    case 'asset.transcribing.failed': {
      return {
        ...item,
        transcribing: 'failed',
        transcribingError: transcribingAsset?.transcribingError || null,
        transcribingErrorCode: transcribingAsset?.transcribingErrorCode || null,
      };
    }
    default:
      return item;
    }
  });
}
