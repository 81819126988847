/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { facesMakeTree } from '../helpers/faces';

export const favorite = createAsyncThunk(
  'faces/favorite',
  async (
    id,
    {
      extra: {
        sdk, Logger,
      },
    }) => {
    try {
      await sdk.faces.favorite(id);
      return id;
    } catch (error) {
      Logger.error(new Error('Error get faces'), { error }, [
        'GetFacesFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    favorite.pending,
    () => {
    },
  ).addCase(
    favorite.fulfilled,
    (state, { payload: id }) => {
      const newFaces = state.all.map((face) => {
        if (face._id === id) return { ...face, isFavorite: !face.isFavorite };
        return face;
      });
      state.all = newFaces;
      state.tree = facesMakeTree(newFaces, state.sortType);
    },
  ).addCase(
    favorite.rejected,
    () => {
    },
  );
};
