import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, IconButton } from '@picsio/ui';
import { Star, Close } from '@picsio/icons';

const StarRating = function ({
  onChange = () => {},
  stars = [5, 4, 3, 2, 1],
  value = 0,
  disabled = false,
  className = '',
  highlightAnimationReset = () => {},
  highlight,
  error,
  deselectStars = null,
}) {
  return (
    <div
      className={cn('defaultRatingList', { [className]: className, isError: error })}
      onAnimationEnd={() => highlightAnimationReset('rating')}
    >
      <div className="innerDefaultRatingList">
        <ul>
          {stars.map((starValue, index) => (
            <li
              key={index}
              className={cn({
                act: value === starValue,
                highlightBlink: highlight,
              })}
              onClick={() => !disabled && onChange(starValue)}
            >
              <Icon className="icon-svg">
                <Star className="star" />
              </Icon>
            </li>
          ))}

        </ul>
      </div>
      <If condition={deselectStars}>
        <IconButton size="lg" className="deselectStars" onClick={deselectStars}>
          <Close />
        </IconButton>
      </If>
    </div>
  );
};

StarRating.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  stars: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.number,
  disabled: PropTypes.bool,
  highlightAnimationReset: PropTypes.func,
  deselectStars: PropTypes.func,
};

export default StarRating;
