import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalizeProducts } from '../helpers/billing';
import getActivePlan from '../helpers/billing/getActivePlan';

export const fetchProducts = createAsyncThunk(
  'billing/fetchProducts',
  async (payload, { getState, extra: { sdk } }) => {
    const { data: products } = await sdk.billing.getProducts(payload);
    const picsioStorage = getState().user?.team?.picsioStorage;
    const subscriptionPlans = getState().user?.customer?.subscription?.items?.data || [];
    const subscriptionAddons = getState().user?.addonsFeatures || {};
    const subscriptionFeatures = getState().user?.subscriptionFeatures || {};
    const { plan } = getActivePlan(subscriptionPlans, products);
    const activePlanId = plan?.id;

    return normalizeProducts({
      products, subscriptionAddons, subscriptionFeatures, activePlanId, picsioStorage,
    });
  },
);

/* eslint-disable no-param-reassign */
export const reducer = (builder) => {
  builder
    .addCase(
      fetchProducts.pending,
      (state) => {
        state.loading = true;
        state.data = {
          plans: [],
        };
      },
    )
    .addCase(
      fetchProducts.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      },
    )
    .addCase(
      fetchProducts.rejected,
      (state) => {
        state.error = true;
      },
    );
};
/* eslint-enable no-param-reassign */
