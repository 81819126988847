import TYPES from '../../action-types';

/**
 * Push collections to store (created by sockets, import)
 * @param {Object[]} collections
 */
const pushCollections = (collections, replace) => (dispatch) => dispatch({
  type: TYPES.COLLECTIONS.PUSH,
  payload: { collections, replace },
});

export default pushCollections;
