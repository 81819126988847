/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { addManyToSelection } from '../helpers/keywords';

export const setSelected = createAction('keywords/setSelected');

export const reducer = (builder) => {
  builder.addCase(
    setSelected.type,
    (state, { payload }) => {
      state.selectedKeywords = addManyToSelection(state.all, payload);
    },
  );
};
