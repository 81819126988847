import sdk from '../../../sdk';
import TYPES from '../../action-types';
import * as utils from '../../../shared/utils';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import { showDialog, showErrorDialog } from '../../../components/dialog';
import UiBlocker from '../../../services/UiBlocker';
import Toast from '../../../components/Toast';
import { reloadCurrentPage } from '../../../helpers/history';

const FINISH_IN_BACKGROUND_SIZE = 100;
const CONFIRMATION_KEY = 'picsio.removeFromCollectionConfirmDialog';

/**
 * Remove assets from collection
 * @param {Object} collection
 * @param {string[]} ids
 * @param {boolean?} withoutConfirmation
 */
const removeFromCollection = (collection, ids) => (
  dispatch,
  getAll,
) => {
  const selectedItems = ids || getAll().assets.selectedItems;
  const isDialogHidden = utils.LocalStorage.get(CONFIRMATION_KEY) || false;
  const asset = selectedItems.length === 1 && getAll().assets.items.find(({ _id }) => _id === selectedItems[0]);

  Logger.log('UI', 'RemoveAssetsFromCollectionDialog');

  async function doRemove() {
    try {
      const collectionID = collection._id;

      UiBlocker.block(localization.TAGSTREE.textDetachingCollection);
      dispatch({ type: TYPES.ASSETS.REMOVE_COLLECTION.START });

      const { data: res } = await sdk.assets.removeFromCollection(selectedItems, collectionID);
      const failed = (res && res.failed) || [];
      const failedIds = failed.map(({ _id }) => _id);
      const removedAssetIds = selectedItems.filter((id) => !failedIds.includes(id));

      if (failed.length) {
        Toast(
          localization.DETAILS.textDetachFromCollectionSomeAssetsFailed(
            {
              count: failed.length,
              collection: collection.path,
            },
            {
              autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
            },
          ),
        );
      } else if (selectedItems.length > FINISH_IN_BACKGROUND_SIZE) {
        Toast(localization.DETAILS.textFinishInBg);
      } else {
        Toast(localization.DETAILS.textAssetsRemovedFromCollection({count: selectedItems.length, collectionName: collection.path }), { type: 'success' });
      }
      dispatch({
        type: TYPES.ASSETS.REMOVE_COLLECTION.COMPLETE,
        payload: { selectedItems: removedAssetIds, collectionID },
      });
    } catch (error) {
      let text = localization.DETAILS.textDetachFromCollectionSomeAssetsFailed({
        count: '',
        collection: collection.path,
      });
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 403) {
        /* if no permissions */
        text = localization.NO_PERMISSION_TO_ACCESS;
      } else {
        Logger.error(new Error('Error detach some assets from collection'), { error }, [
          'DetachAssetsFromCollectionFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }
      showErrorDialog(text);
      dispatch({
        type: TYPES.ASSETS.REMOVE_COLLECTION.FAILED,
        error,
      });
    }

    UiBlocker.unblock();
  }

  if ((asset?.tags || []).length === 1) {
    /** Show confirmation dialog if action for one asset and user try to remove last collection */
    showDialog({
      title: localization.DETAILS.titleRemoving,
      children: localization.DETAILS.textRemoveFilesFromLastCollection(asset.name),
      textBtnCancel: localization.DIALOGS.btnCancel,
      textBtnOk: localization.DIALOGS.btnConfirm,
      onOk: doRemove,
    });
  } else if (isDialogHidden) {
    /** Confirmation dialog is hidden */
    doRemove();
  } else {
    /** Show confirmation dialog */
    showDialog({
      title: localization.DETAILS.titleRemoving,
      children: localization.DETAILS.textRemoveFilesFrom(asset?.name || selectedItems.length, collection.path),
      textBtnCancel: localization.DIALOGS.btnCancel,
      textBtnOk: localization.DIALOGS.btnRemove,
      checkbox: {
        label: localization.DETAILS.labelCheckboxDontShow,
        onChange: (newValue) => {
          Logger.log('User', 'DialogConfirmRemoveFromCollectionDontShowChanged', { checked: newValue });
          utils.LocalStorage.set(CONFIRMATION_KEY, newValue);
        },
      },
      onOk: doRemove,
    });
  }
};

export default removeFromCollection;
