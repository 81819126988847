import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import * as rolesActions from '../../roles/actions';
import TYPES from '../../action-types';
import deleteCollectionAssetsAction from '../assets/deleteCollection';
import Toast from '../../../components/Toast';
import { showErrorDialog } from '../../../components/dialog';
import {
  navigateToRoot,
} from '../../../helpers/history';
import sdk from '../../../sdk';
import DialogRadios from '../../../components/dialogRadios';

/**
 * Remove collection
 * @param {Object} collection
 */
function removeCollection(collection) {
  return (dispatch, getState) => {
    function handleError(error) {
      let errorText = localization.UNABLE_TO_REMOVE_COLLECTION;
      const errorTitle = 'Error';
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorSubcode === 'GDinsufficientPermissionsError') {
        errorText = localization.UNABLE_TO_REMOVE_COLLECTION_NO_GD_PERMISSIONS;
      }
      if (errorStatus === 403) {
        errorText = localization.NO_PERMISSION_TO_ACCESS;
        showErrorDialog(errorText, errorTitle);
      } else if (errorSubcode === 'HasCopyingAssetsError' || errorSubcode === 'HasMovingAssetsError') {
        Toast(localization.TOAST.WARNING_BUSY_COLLECTION.TEXT, { type: 'error' });
      }

      dispatch({
        type: TYPES.COLLECTIONS.REMOVE.FAILED,
        payload: { collectionID: collection._id },
      });
      Logger.error(new Error('Can not remove collection'), { error }, [
        'RemoveCollectionFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }

    /**
     * Remove from server
     * @param {boolean} removeAssets
     */
    async function doRemove(removeOption) {
      UiBlocker.block(localization.TAGSTREE.textRemovingCollection);
      try {
        await sdk.collections.delete(collection._id, removeOption).then(({ data: response }) => {
          if (response.removed) {
            // remove collection from roles
            getState().roles.items.forEach((role) => {
              const lengthAllowedCollections = (role.allowedCollections || []).length;
              const filteredAllowedCollections = (role.allowedCollections || []).filter(
                (allowedCollection) => allowedCollection._id !== collection._id,
              );
              if (lengthAllowedCollections !== filteredAllowedCollections.length) {
                const updatedRole = { ...role };
                updatedRole.allowedCollections = filteredAllowedCollections;
                dispatch(rolesActions.updateRole({ role: updatedRole }));
              }
            });

            dispatch({
              type: TYPES.COLLECTIONS.REMOVE.COMPLETE,
              payload: { collectionID: collection._id },
            });

            const { collectionIds } = getState().router.location.query;
            if (collection._id === collectionIds) {
              navigateToRoot();
            } else {
              deleteCollectionAssetsAction(collection._id)(dispatch);
            }
          } else {
            handleError(response.msg || localization.UNABLE_TO_REMOVE_COLLECTION);
          }
        });
      } catch (error) {
        handleError(error);
      }
      UiBlocker.unblock();
    }

    /** @TODO check websites on the server // 30.05.2018 */
    const html = localization.TAGSTREE.textRemoveCollectionAndSite(
      utils.decodeSlash(collection.name),
      collection.website && `https://${collection.website.alias}`,
    );

    const radio = [
      {
        label: localization.TAGSTREE.removeSelectedOnlyRadioLabel(utils.decodeSlash(collection.name)),
        description: localization.TAGSTREE.removeSelectedOnlyRadioLabelDescription,
        value: 'selectedOnly',
        checked: true,
        dataTestId: 'option-delete-assets-placed-in-collection',
      },
      {
        label: localization.TAGSTREE.removeSelectedAndAttachedRadioLabel,
        description: localization.TAGSTREE.removeSelectedAndAttachedRadioLabelDescription,
        value: 'selectedAndAttached',
        dataTestId: 'option-delete-assets-placed-and-attached-to-collection',
      },
    ];

    Logger.log('UI', 'RemoveCollectionDialog');
    new DialogRadios({
      title: localization.TAGSTREE.textRemoveCollection,
      text: html,
      items: radio,
      onOk: (checkedRadioItem) => {
        dispatch({
          type: TYPES.COLLECTIONS.REMOVE.START,
          payload: { collectionID: collection._id },
        });
        Logger.log('User', 'RemoveCollectionDialogOk', { removeCollectionOption: localization.TAGSTREE.removeCollectionAmplitudeData[checkedRadioItem] });

        Toast(localization.TOAST.COLLECTION_REMOVED(collection.name), {
          type: 'success',
          closeButton: false,
          btnOkValue: localization.textBtnUndo,
          undo: true,
          onOk: () => {
            dispatch({
              type: TYPES.COLLECTIONS.REMOVE.UNDO,
              payload: { collectionID: collection._id },
            });
          },
          onClose: () => doRemove(checkedRadioItem),
        });
      },
      onCancel: () => {
        Logger.log('User', 'RemoveCollectionDialogCancel');
      },
      cancelName: localization.DIALOGS.btnCancel,
      okName: localization.DIALOGS.btnDelete,
      bottomHelpLinkUrl: localization.TAGSTREE.removeCollectionHelpLinkUrl,
      bottomHelpLinkTitle: localization.TAGSTREE.removeCollectionHelpLinkTitle,
    });
  };
}

export default removeCollection;
