import TYPES from '../../action-types';

/**
 * Delete lightboard
 * @param {string} id
 */
const deleteLightboard = (id) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.DELETE_LIGHTBOARD,
    payload: { id },
  });
};

export default deleteLightboard;
