import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Fetch custom video url for the asset
 * @param {string} assetId
 * @param {string?} revisionId
 * @returns Promise<AxiosResponse>
 */
export default function getCustomVideoUrl(assetId, revisionId) {
  if (picsioConfig.isMainApp) {
    return sdk.assets.buildCustomVideoLink(
      assetId,
      revisionId,
      window.useProxy,
    );
  }

  return sdk.assets.buildCustomVideoLinkPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetId,
    revisionId,
  );
}
