/* istanbul ignore file */
import { combineReducers } from 'redux';
import main from './main';
import collections from './collections';
import keywords from '../keywords';
import keywordsList from '../keywordsList';
import assets from './assets';
import lightboards from './lightboards';
import savedSearches from './savedSearches';
import customFields from './customFields';
import notifications from './notifications';
import downloadList from './downloadList';
import user from './user';
import roles from '../roles';
import teammates from './teammates';
import inboxes from '../inboxes';
import websiteSettings from '../websiteSettings';
import faces from '../faces';
import billing from '../billing';
import archive from './archive';
import importReducer from '../import';

const createRootReducer = (routerReducer) => (
  combineReducers({
    router: routerReducer,
    assets,
    main,
    collections,
    keywords,
    keywordsList,
    lightboards,
    savedSearches,
    customFields,
    downloadList,
    notifications,
    user,
    roles,
    teammates,
    inboxes,
    faces,
    billing,
    archive,
    import: importReducer,
    websiteSettings,
  })

);

export default createRootReducer;
