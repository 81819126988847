import TYPES from '../../action-types';

/**
 * Update lightboard
 * @param {string} id
 * @param {Object} data - data to update, e.g. { path: newPath}
 */
const updateLightboard = (id, data) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.UPDATE_LIGHTBOARD,
    payload: { id, data },
  });
};

export default updateLightboard;
