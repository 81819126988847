import { createAsyncThunk } from '@reduxjs/toolkit';

const removeBoundingBox = createAsyncThunk(
  'asset/removeBoundingBox',
  async ({
    assetId,
    faceId,
    boundingBox,
    revision,
  }, {
    extra: {
      sdk, Logger, utils, showErrorDialog,
    },
    getState,
  }) => {
    try {
      const revisionId = revision || getState().assets?.items?.find(({ _id }) => _id === assetId)?.headRevisionId;
      await sdk.assets.removeBoundingBox(assetId, faceId, boundingBox, revisionId, true);
      return { assetId, faceId, boundingBox };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not remove boundingBox';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error boundingBox removing'),
        { error },
        ['RemoveBoundingBoxFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export default removeBoundingBox;
