/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import getAssets from '../../actions/assets/getAssets';
import { closeImport } from '../../actions/main';

import initialState from '../initialState';
import saveNamesToLS from '../helpers/saveNamesToLS';

import startFileUploadQueue from './startFileUploadQueue';
import sendEventToIntercom from '../../../services/IntercomEventService';

export const startUpload = createAsyncThunk(
  'import/startUpload',
  async (payload, {
    extra: {
      Logger, Toast, localization: l10n,
    }, getState, dispatch,
  }) => {
    await startFileUploadQueue({
      getState, dispatch, payload,
    });

    const { uploadErrorsCount } = getState().import;
    if (uploadErrorsCount > 0) throw new Error(`Has upload errors: ${uploadErrorsCount}`);
    let filesCount = 0;
    let foldersCount = 0;

    const actualState = getState().import;
    const collectionIdsSet = Object.values(actualState.items)
      .flat()
      .reduce((res, { file, collection, lightboard }) => {
        if (collection?._id) res.add(collection._id);
        if (lightboard?._id) res.add(lightboard._id);
        if (file['.tag'] === 'folder') {
          foldersCount += 1;
        } else {
          filesCount += 1;
        }
        return res;
      }, new Set());
    const collectionIds = Array.from(collectionIdsSet);
    const { total } = actualState;

    Logger.log('App', 'UploadFinished', { filesCount, foldersCount });
    sendEventToIntercom('Assets Uploaded');

    window.dispatchEvent(
      new CustomEvent('import:uploading:progress', { detail: { percantage: 0, ElParent: '#button-upload' } }),
    );

    const { collectionIds: cIds, lightboardId } = getState().router.location.query;
    let idsInQuery = cIds;
    let delay;
    if (typeof cIds === 'string') idsInQuery = [cIds];
    if (
      (idsInQuery && collectionIds.some((id) => idsInQuery.includes(id))) || (lightboardId && collectionIds.includes(lightboardId))
    ) {
      delay = 2000;
      dispatch(getAssets(true, delay));
    }
    dispatch(closeImport());
    Toast(
      l10n.IMPORT.textFilesUploaded({
        filesCount,
        foldersCount,
        totalSize: total,
        collections: Object.keys(actualState.items).join(', '),
      }),
      { type: 'success', closeButton: false, delay },
    );

    return null;
  },
);

export const reducer = (builder) => {
  builder.addCase(
    startUpload.pending,
    (state) => {
      state.inProgress = true;
      state.selected = [];
    },
  ).addCase(
    startUpload.fulfilled,
    (state) => {
      state.isUploadSubmitted = false;
      state.inProgress = false;
      const fieldsToKeep = {
        requiredFields: state.requiredFields,
        addedCustomFields: state.addedCustomFields,
      };
      Object.assign(state, initialState, fieldsToKeep);
      saveNamesToLS(state.items);
    },
  ).addCase(
    startUpload.rejected,
    (state) => {
      state.inProgress = false;
    },
  );
};
