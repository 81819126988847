const eventsWithThumbnails = [
  'asset.comment.added',
  'asset.comment.deleted',
  'asset.created',
  'assets.trashed',
  'asset.renamed',
  'asset.copied',
  'assets.untrashing.complete',
  'asset.revision.approved',
  'asset.revision.created',
  'asset.revision.disapproved',
  'asset.revision.reverted',
  'assets.assigned',
  'inbox.asset.created',
  'asset.transcribing.complete',
];

export default eventsWithThumbnails;
