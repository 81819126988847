import React, { useCallback } from 'react';
import { func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import addSubtitlesGenerated from '../../../../../store/actions/user/addSubtitlesGenerated';
import * as utils from '../../../../../shared/utils';
import l18n from '../../../../../shared/strings';

import './styles.scss';

const TooltipSubtitle = ({ handleTranscriptionsPanel }) => {
  const dispatch = useDispatch();
  const isSubtitlesGenerated = useSelector((state) => state.user.achievements.subtitlesGenerated);

  const handleClickTooltip = useCallback((e) => {
    e.preventDefault();
    dispatch(addSubtitlesGenerated());
    const isOpenTranscriptPanel = utils.LocalStorage.get('picsio.transcriptionspanelOpened');
    if (!isOpenTranscriptPanel) {
      handleTranscriptionsPanel();
    }
  }, [dispatch, handleTranscriptionsPanel]);

  return (
    <If condition={!isSubtitlesGenerated}>
      <div
        className="tooltip"
        onClick={handleClickTooltip}
        role="presentation"
      >
        {l18n.PREVIEW_VIEW.generateSubtitles}
      </div>
    </If>

  );
};

TooltipSubtitle.propTypes = {
  handleTranscriptionsPanel: func,
};

TooltipSubtitle.defaultProps = {
  handleTranscriptionsPanel: Function.prototype,
};

export default TooltipSubtitle;
