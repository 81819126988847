import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonItem = ({ width = 160 }) => (
  <div className="dropdown-item is-skeleton">
    <Skeleton width={width} />
  </div>
);

export default SkeletonItem;
