import eventsWithThumbnails from './eventsWithThumbnails';

/**
 * Set notification asset thumnail loading
 * @param {Object[]} items
 * @param {string} id
 * @param {string} key
 * @param {string} value
 * @returns {Object} items
 */
const setThumbnailsField = (items, ids, keyName, value) => items.map((item) => {
  let itemToModify = null;
  if (item.data) {
    const storageId = (item.data.asset && (item.data.asset.storageId || item.data.asset.googleId))
        || (item.data.assets && item.data.assets.length && (item.data.assets[0].storageId || item.data.assets[0].googleId));

    itemToModify = eventsWithThumbnails.includes(item.type) ? ids.includes(storageId) : undefined;
  }
  return itemToModify ? { ...item, thumbnail: { [keyName]: value } } : item;
});

export default setThumbnailsField;
