/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getById = createAsyncThunk(
  'keywords/getById',
  async (id, {
    extra: {
      sdk, Logger, Toast, utils, localization: l18n,
    },
  }) => {
    try {
      const { data: keywords } = await sdk.keywords.getByIds([id]);

      return { count: keywords[0].count, id };
    } catch (error) {
      Toast(l18n.KEYWORDS.errorLoadingSomeKeywords, { autoClose: false, type: 'error' });
      const connection = utils.getNavigatorConnectionInfo();
      Logger.error(new Error('Can not get keywords by ID'), { error }, [
        'GetKeywordsByIdFailed',
        { errorMessage: (error && error.message) || 'NoMessage', connection, id },
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getById.fulfilled,
      (state, { payload: { count, id } }) => {
        const handleKeyword = (keyword) => {
          if (keyword.nodes && keyword.nodes.length > 0) {
            keyword.nodes = keyword.nodes.map(handleKeyword);
          }
          return keyword._id === id ? { ...keyword, count } : keyword;
        };

        state.tree.favorites = handleKeyword(state.tree.favorites);
        state.tree.keywords = handleKeyword(state.tree.keywords);

        state.all.forEach((keyword) => {
          if (keyword._id === id) {
            keyword.count = count;
          }
        });
      },
    ).addCase(
      getById.rejected,
      (state, { meta }) => {
        state.notFetchedById.push(...meta.arg);
      },
    );
};
