import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import ReactDOM from 'react-dom';
import AppRouter from 'AppRouter';
import { ToastContainer } from 'react-toastify';
import app from './app';
import store, { reduxHistoryStore } from './store';
import picsioConfig from '../../../config';

import '../css/public-sharing.scss';

picsioConfig.access = window.websiteConfig;

(async () => {
  await app.startProofingApp();
  const container = document.querySelector('.wrapperPicsioApp');
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <HistoryRouter history={reduxHistoryStore}>
        <AppRouter />
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
          pauseOnFocusLoss
          draggable
        />
      </HistoryRouter>
    </Provider>,
  );
})();
// (async () => {
//   await app.startProofingApp();

//   ReactDOM.render(
//     <Provider store={store}>
//       <HistoryRouter history={reduxHistoryStore}>
//         <AppRouter />
//         <ToastContainer
//           position="bottom-right"
//           autoClose={10000}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnHover
//           pauseOnFocusLoss
//           draggable
//         />
//       </HistoryRouter>
//     </Provider>,
//     document.querySelector('.wrapperPicsioApp'),
//   );
// })();

window.mapsCallback = () => {
  window.dispatchEvent(new Event('gMapsInited'));
};
