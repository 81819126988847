import TYPES from '../../action-types';

const keywordsGenerated = (keywordedAssets) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.GENERATE_KEYWORDS.COMPLETE,
    payload: { keywordedAssets },
  });
};

export default keywordsGenerated;
