import { createAsyncThunk } from '@reduxjs/toolkit';

const descriptionGenerated = createAsyncThunk(
  'asset/descriptionGenerated',
  async ({
    describedAssets,
  }) => {
    return { describedAssets };
  },
);

export default descriptionGenerated;
