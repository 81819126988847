import React, { memo, useCallback } from 'react';
import { InputControlLabel } from '@picsio/ui';
import {
  bool, func, number, string,
} from 'prop-types';

const Field = ({
  title, index, name, value, onChange,
}) => {
  const handleChange = useCallback(() => onChange(index, !value), [onChange, index, value]);

  return (
    <li key={name}>
      <InputControlLabel
        control="checkbox"
        label={title}
        value={value}
        onChange={handleChange}
      />
    </li>
  );
};

Field.propTypes = {
  title: string,
  index: number,
  name: string,
  value: bool,
  onChange: func,
};

Field.defaultProps = {
  title: '',
  index: 0,
  name: '',
  value: false,
  onChange: () => {},
};

export default memo(Field);
