import prepareSearchQuery from './prepareSearchQuery';
import sdk from '../../sdk';

export default function selectAll(assetProjection = {}, collectionIds) {
  if (collectionIds) {
    return sdk.assets.search({
      onlyIds: false,
      collectionIds,
      size: 'all',
      responseSchema: {
        full: 1,
        images: {
          _id: 1,
          importing: 1,
          ...assetProjection,
        },
        total: 1,
      },
    });
  } return sdk.assets.search({
    onlyIds: false,
    size: 'all',
    ...prepareSearchQuery(),
    responseSchema: {
      full: 1,
      images: {
        _id: 1,
        importing: 1,
        ...assetProjection,
      },
      total: 1,
    },
  });
}
