import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@picsio/ui';
import { Interweave } from 'interweave';
import Input from '../../../UIComponents/input'; // eslint-disable-line
import Textarea from '../../../UIComponents/textarea';
import Checkbox from '../../../UIComponents/checkbox';
import Swipeable from '../../Swipeable';
import localization from '../../../shared/strings';
import Icon from '../../Icon';
import sanitizeXSS from '../../../shared/sanitizeXSS';
import RadioItem from '../../RadioItem';

export default class Dialog extends React.Component {
  constructor(props) {
    super(props);

    const {
      icon, text, input, textarea, checkbox, radio, checkedRadioItem, disableOk, children,
    } = props.data;

    if (!textarea && !input && !text && !children) {
      throw new Error('at least "text" or "children" is required for Alert');
    }

    if ((textarea || input) && icon) {
      throw new Error('"icon" is denied for prompts');
    }

    const state = { errors: {} };
    if (input) {
      state.input = input.value;
      if (input.validate) state.errors.input = !input.validate(input.value);
    }
    if (textarea) {
      state.textarea = textarea.value;
      if (textarea.validate) state.errors.textarea = !textarea.validate(textarea.value);
    }
    if (checkbox) state.checkbox = checkbox.value || false;
    if (radio) state.checkedRadioItem = checkedRadioItem || radio[0]?.value;
    if (disableOk) state.isOkDisabled = disableOk(state);

    this.state = state;
  }

  componentDidMount() {
    this.ElInput && this.ElInput.focus();

    document.addEventListener('keydown', this.keyDownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler);
  }

  onChangeInput = (e, value) => {
    const { input, disableOk } = this.props.data;

    this.setState({ input: value }, () => {
      const { state } = this;
      if (input.validate) {
        const validateResult = input.validate(value);
        state.errors = {
          ...this.state.errors,
          input: typeof validateResult === 'string' ? validateResult : !validateResult,
        };
        this.setState({ errors: state.errors });
      }

      if (disableOk) this.setState({ isOkDisabled: disableOk(state) });
    });

    if (input.onChange) input.onChange(e, value);
  };

  onChangeTextarea = (e, value) => {
    const { textarea, disableOk } = this.props.data;

    this.setState({ textarea: value }, () => {
      const { state } = this;
      if (textarea.validate) {
        state.errors = {
          ...this.state.errors,
          textarea: !textarea.validate(value),
        };
        this.setState({ errors: state.errors });
      }
      if (disableOk) this.setState({ isOkDisabled: disableOk(state) });
    });

    if (textarea.onChange) textarea.onChange(e, value);
  };

  onChangeCheckbox = (value) => {
    const { checkbox, disableOk } = this.props.data;

    this.setState({ checkbox: value }, () => {
      disableOk && this.setState({ isOkDisabled: disableOk(this.state) });
    });

    checkbox.onChange && checkbox.onChange(value);
  };

  onChangeRadio = (value) => {
    const { radio, disableOk } = this.props.data;

    this.setState({ checkedRadioItem: value }, () => {
      disableOk && this.setState({ isOkDisabled: disableOk(this.state) });
    });

    radio.onChange && radio.onChange(value);
  };

  onOk = () => {
    const { onOk } = this.props.data;

    if (!this.state.isOkDisabled) {
      this.destroy();

      onOk && onOk(this.state);
    }
  };

  onCancel = () => {
    const { onCancel } = this.props.data;

    this.destroy();

    onCancel && onCancel(this.state);
  };

  onClose = () => {
    const { onClose } = this.props.data;

    this.destroy();

    onClose && onClose(this.state);
  };

  destroy = () => {
    const { root } = this.props;
    root.unmount();
  };

  /** Keydown handler
   * @param {KeyboardEvent} event
   */
  keyDownHandler = (event) => {
    switch (event.keyCode) {
    // Enter
    case 13: {
      const inputIsntFocused = !['textarea'].includes(
        document.activeElement.tagName.toLowerCase(),
      );
      if (inputIsntFocused) {
        event.preventDefault();
        event.stopPropagation();
        this.onOk();
      }
      break;
    }
    // Esc
    case 27:
      event.preventDefault();
      event.stopPropagation();
      this.onCancel();
      break;
    default:
      break;
    }
  };

  handleSwipeUp = (eventData) => {
    const { absY, dir, velocity } = eventData;
    if (absY > 50 && dir === 'Up' && velocity > 0.7) {
      const { onClose } = this.props.data;
      if (onClose) {
        this.onClose();
      } else {
        this.onCancel();
      }
    }
  };

  render() {
    const {
      title = 'Undefined',
      className = '',
      icon,
      text,
      input,
      textarea,
      checkbox,
      radio,
      style,
      textBtnCancel = localization.DIALOGS.btnCancel,
      textBtnOk = localization.DIALOGS.btnOk,
      onClose,
      children,
      id,
    } = this.props.data;

    return (
      <Swipeable className="SwipeableDialog" onSwipedUp={this.handleSwipeUp}>
        <div className={`simpleDialog ${className}`}>
          <div className="simpleDialogUnderlayer" />
          <div className="simpleDialogBox" style={style && style}>
            <div className="simpleDialogHeader">
              <span className="simpleDialogTitle">{title}</span>
              <If condition={textBtnCancel !== null || textBtnOk !== null}>
                <span
                  className="simpleDialogBtnCross"
                  onClick={onClose ? this.onClose : this.onCancel}
                >
                  <Icon name="close" />
                </span>
              </If>
            </div>
            <div className="simpleDialogContent">
              <If condition={icon}>
                <div className="simpleDialogIcon">
                  <Icon name={icon} />
                </div>
              </If>
              <div className="simpleDialogContentInner">
                <If condition={children}>
                  <div className="simpleDialogDescription">{children}</div>
                </If>
                <If condition={text}>
                  <div className="simpleDialogDescription">
                    {/* some data comes in format of string ex "<span>text</span>" */}
                    <If condition={typeof text === 'string'}>
                      <Interweave content={sanitizeXSS(text)} />
                    </If>
                    {/* some data comes in format of pure elements ex <span>text</span>
                    NOTE without quotes
                     */}
                    <If condition={typeof text !== 'string'}>
                      {text}
                    </If>
                  </div>
                </If>
                <If condition={input}>
                  <Input
                    {...input}
                    value={this.state.input || ''}
                    onChange={this.onChangeInput}
                    error={this.state.errors?.input}
                    customRef={(el) => {
                      this.ElInput = el;
                    }}
                  />
                </If>
                <If condition={textarea}>
                  <Textarea
                    {...textarea}
                    value={this.state.textarea}
                    error={this.state.errors?.textarea}
                    onChange={this.onChangeTextarea}
                  />
                </If>
                <If condition={checkbox}>
                  <Checkbox
                    {...checkbox}
                    value={this.state.checkbox}
                    onChange={this.onChangeCheckbox}
                  />
                </If>
                <If condition={radio}>
                  {
                    radio.map(({ value, label, dataTestId }) => {
                      return (
                        <RadioItem
                          key={value}
                          data-testId={dataTestId}
                          value={value}
                          label={label}
                          checkedValue={this.state.checkedRadioItem}
                          onChange={this.onChangeRadio}
                        />
                      );
                    })
                  }
                </If>
              </div>
            </div>
            <If condition={textBtnCancel !== null || textBtnOk !== null}>
              <div className="simpleDialogFooter">
                <If condition={textBtnCancel !== null}>
                  <Button
                    color="secondary"
                    size="md"
                    variant="contained"
                    onClick={this.onCancel}
                  >
                    {textBtnCancel}
                  </Button>
                </If>
                <If condition={textBtnOk !== null}>
                  <Button
                    color="primary"
                    id={id}
                    size="md"
                    variant="contained"
                    disabled={this.state.isOkDisabled}
                    onClick={this.onOk}
                  >
                    {textBtnOk}
                  </Button>
                </If>
              </div>
            </If>
          </div>
        </div>
      </Swipeable>
    );
  }
}

/** Prop types */
Dialog.propTypes = {
  data: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    input: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.func,
    }),
    textarea: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      validate: PropTypes.func,
    }),
    checkbox: PropTypes.object,
    radio: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
    checkedRadioItem: PropTypes.string,
    style: PropTypes.object,
    textBtnCancel: PropTypes.string, // to hide button "Cancel" use null
    textBtnOk: PropTypes.string, // to hide button "Ok" use null
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    disableOk: PropTypes.func,
    id: PropTypes.string, // needs to pass ID for Intercom init
  }),
  parentEl: PropTypes.instanceOf(Element),
};
