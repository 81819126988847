import { Time, VideoSpeed, VideoQuality } from '@picsio/icons';

export default [
  {
    id: 'speed',
    text: 'Speed',
    Icon: VideoSpeed,
    items: [
      {
        id: 0.25,
        text: '0.25',
      },
      {
        id: 0.5,
        text: '0.5',
      },
      {
        id: 0.75,
        text: '0.75',
      },
      {
        id: 1,
        text: 'Normal',
      },
      {
        id: 1.25,
        text: '1.25',
      },
      {
        id: 1.5,
        text: '1.5',
      },
      {
        id: 1.75,
        text: '1.75',
      },
      {
        id: 2,
        text: '2',
      },
    ],
  },
  {
    id: 'quality',
    text: 'Quality',
    Icon: VideoQuality,
    items: [],
  },
  {
    id: 'timeView',
    text: 'Time view',
    Icon: Time,
    items: [
      {
        id: 'Standard',
        text: 'Standard',
      }, {
        id: 'Timecode',
        text: 'Timecode',
      },
    ],
  },
];
