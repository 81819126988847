import { createAsyncThunk } from '@reduxjs/toolkit';

import incrementCount from '../collections/incrementCount';

import { back, isRoutePreview } from '../../../helpers/history';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';

import addCollections from './addCollections';
import showError from './showError';

const archiveAssets = createAsyncThunk(
  'archive/archiveAssets',
  async (payload, {
    extra: {
      UiBlocker, utils, sdk, localization, Toast,
    }, rejectWithValue, dispatch, getState,
  }) => {
    const { ids, reason } = payload;

    try {
      UiBlocker.block('Processing...');
      const { data: res } = await sdk.assets.archive(ids, reason);

      // if user archive asset from preview page, we move user back to search
      if (isRoutePreview()) {
        back();
      }
      const { showArchived } = getState().router.location.query;
      const { user } = getState();
      // if user activated "showArchived" option on search panel, we don't hide archived assets
      const hide = !!showArchived;

      if (res.collections.length > 0) {
        dispatch(addCollections({ ...res, user: getState().user }));
      }

      dispatch(incrementCount(ids.length));

      Toast(localization.TOAST.ASSETS_ARCHIVED(ids.length), { type: 'success', closeButton: false });

      return {
        ids,
        reason,
        hide,
      };
    } catch (e) {
      showError(e, Toast, utils, localization);
      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default archiveAssets;
