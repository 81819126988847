import TYPES from '../../action-types';

import getThumbnails from './getThumbnails';

/**
 * Revert asset revision
 * @param {string} assetId
 * @param {string} revisionIdToRevert
 * @param {string} newRevisionId
 */
function revertRevision(assetId, revisionIdToRevert, newRevisionId) {
  return (dispatch, getState) => {
    const asset = getState().assets.items.find((a) => a._id === assetId);
    if (asset) {
      dispatch({
        type: TYPES.ASSETS.REVERT_REVISION,
        payload: {
          assetId,
          revisionIdToRevert,
          newRevisionId,
          userId: getState().user._id,
        },
      });
      getThumbnails([asset._id])(dispatch, getState);
    }
  };
}

export default revertRevision;
