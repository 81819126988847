/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { setField, rename as renameHelper } from '../helpers/keywords';

export const rename = createAsyncThunk(
  'keywords/rename',
  async ({ _id, name }, {
    extra: {
      sdk, utils, localization, Toast, Logger,
    },
  }) => {
    try {
      await sdk.keywords.rename(_id, name);
      return { _id, name };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg');
      const subcode = utils.getDataFromResponceError(error, 'subcode');
      if (subcode === 'KeywordAlreadyInUseApiError') {
        Toast(localization.KEYWORDS.alreadyExists, { autoClose: false });
        return null; /** no need to throw the error */
      } if (errorMessage) {
        Toast(errorMessage, { autoClose: false });
      } else {
        Toast(localization.COLLECTIONS.textCantUpdateKeyword, { autoClose: false });
      }
      Logger.error(new Error('Can not rename keyword'), { error }, [
        'RenameKeywordFailed',
        error?.message || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    rename.pending,
    (state, { meta }) => {
      state.tree = setField(state.tree, meta.arg._id, 'isRenaming', true);
    },
  )
  .addCase(
    rename.fulfilled,
    (state, { payload, meta }) => {
      if (!payload) {
        state.tree = setField(state.tree, meta.arg._id, 'isRenaming', false);
        return;
      }

      const { all, favorites, keywords } = renameHelper(
        state.tree,
        state.all,
        payload._id,
        payload.name,
        state.sortType,
      );
      state.all = all;
      state.tree = { favorites, keywords };
    },
  )
  .addCase(
    rename.rejected,
    (state, { meta }) => {
      state.tree = setField(state.tree, meta.arg._id, 'isRenaming', false);
    },
  );
