import React from 'react';
import { createRoot } from 'react-dom/client';
import ExportCsvDialog from '../components/ExportCsvDialog';

/** Show Export to CSV dialog */
export default function showExportCsvDialog(props) {
  const $wrapper = document.createElement('div');
  $wrapper.classList.add('wrapperDialog');
  $wrapper.classList.add('exportDialogWrapper');
  document.body.appendChild($wrapper);
  const root = createRoot($wrapper);

  function destroy() {
    root.unmount($wrapper);
    document.body.removeChild($wrapper);
  }
  root.render(<ExportCsvDialog destroy={destroy} {...props} />);
}
