import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../../../../sdk';
import Logger from '../../../../services/Logger';

const setTranscriptViewedByInitiator = createAsyncThunk(
  'asset/setTranscriptViewedByInitiator',
  async ({ assetId, value }, { rejectWithValue }) => {
    try {
      await sdk.assets.setTranscriptViewedByInitiator(assetId, value);
      return { assetId, value };
    } catch (error) {
      Logger.log(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export default setTranscriptViewedByInitiator;
