/**
 * Create transcriptions language options
 * @param {array} languageList
 * @param {string} text
 */

const createLanguageOptions = (languageList, text = '') => {
  const normalizedOptions = Object.keys(languageList).map((lang) => ({
    text: `${text} ${languageList[lang]}`.trim(),
    value: lang,
  })).sort((a, b) => (a.text > b.text ? 1 : -1));

  return normalizedOptions;
};

export default createLanguageOptions;
