import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import WithSkeletonTheme from '../WithSkeletonTheme';
import DropdownTreeIcon from './DropdownTreeIcon';

const WIDTH = 120;

export default function SkeletonItem({
  itemPaddingLeft, level, iconSpecial, count,
}) {
  return (
    <WithSkeletonTheme>
      {Array.from({ length: count }).map((_, idx) => (
        /* eslint-disable-next-line */
        <div key={idx} className="row" style={{ paddingLeft: itemPaddingLeft * (level + 1) }}>
          <span className="iconSubject">
            <DropdownTreeIcon iconSpecial={iconSpecial} />
          </span>
          <span className="dropdownTreeItemName">
            <Skeleton width={WIDTH + (40 * (idx + 1))} height={16} />
          </span>
        </div>
      ))}
    </WithSkeletonTheme>
  );
}

SkeletonItem.defaultProps = {
  count: 3,
  iconSpecial: null,
  itemPaddingLeft: 15,
  level: 0,
};

SkeletonItem.propTypes = {
  count: PropTypes.number,
  iconSpecial: PropTypes.string,
  itemPaddingLeft: PropTypes.number,
  level: PropTypes.number,
};
