import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import picsioConfig from '../../../../../../config';

import * as collectionHelpers from '../../helpers/collections';
import TYPES from '../../action-types';
import { navigateToRoot } from '../../../helpers/history';

import { showDialog } from '../../../components/dialog';
import getThumbnails from './getThumbnails';
import checkForCollectionsSortingsEquality from '../../../helpers/checkForCollectionsSortingsEquality';

/**
 * Get assets
 * @param {boolean} isNewRoute
 * @param {number?} delay - delay before request, e.g. updateByNew -> 2000
 */
const getAssets = (isNewRoute, delay, geoData) => (dispatch, getState) => {
  function handleError(error, extraData) {
    UiBlocker.unblock();

    let errorText = localization.CATALOG_VIEW.cantLoadData;
    const errorStatus = utils.getStatusFromResponceError(error);
    if (errorStatus === 404) {
      errorText = localization.CATALOG_VIEW.collectionNotFound;
    }
    if (errorStatus === 400) {
      errorText = localization.CATALOG_VIEW.errorCheckSearchQuery;
    }

    const originalError = (error && error.response) ? {
      data: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
    } : error;

    const errToJson = (error && error.toJSON && typeof error.toJSON === 'function') ? error.toJSON() : JSON.stringify(error, null, 2);
    const errToString = error && error.toString && typeof error.toString === 'function' && error.toString();

    if (!error || errorStatus !== 400) {
      /** Don't send error if status === 400 -> incorrect search query */
      const connection = utils.getNavigatorConnectionInfo();
      Logger.error(new Error('Error fetching assets'), { error, showDialog: true }, [
        'GetAssetsFailed',
        {
          errorMessage: errorText || 'NoMessage', userDialogueMessage: errorText, connection, originalError, errToJson, errToString, extraData,
        },
      ]);
    }
    showDialog({
      title:
        errorStatus === 400
          ? localization.CATALOG_VIEW.errorCheckSearchQueryTitle
          : localization.CATALOG_VIEW.textError,
      text: errorText,
      textBtnCancel: null,
      onOk: navigateToRoot,
      onCancel: navigateToRoot,
    });

    dispatch({
      type: TYPES.ASSETS.FETCH.FAILED,
      error,
    });
  }

  function getIds() {
    const store = getState();
    /** Get current ids from search query */
    const {
      collectionIds, lightboardId, keywords, inboxId,
    } = store.router.location.query;
    /** If no ids in the url -> get them from the store */
    let collectionIdsArray = collectionIds;
    if (typeof collectionIds === 'string') {
      collectionIdsArray = [collectionIds];
    }
    const ids = {
      collectionIds: collectionIdsArray || store.collections.activeCollections?.map((activeCollection) => activeCollection._id),
      lightboardId: lightboardId || store.lightboards.activeLightboard?._id,
      keywordId: (keywords && keywords[0]) || store.keywords.activeKeywords[0] || null,
      inboxId: inboxId || store.inboxes.activeInboxID,
    };

    return ids;
  }

  async function doRequest(from) {
    try {
      const {
        collectionIds, lightboardId, keywordId, inboxId,
      } = getIds();
      const defaultSort = { type: 'uploadTime', order: 'desc' };
      const { isProofing } = picsioConfig;
      let sort = isProofing ? window.websiteConfig.sortType || defaultSort : defaultSort;
      if (collectionIds) {
        // we can't use getState().collections.activeCollection
        // because it updates after getAssets action
        const foundCollections = await collectionHelpers.forceFindTagWithTagId({
          collections: getState().collections.collections,
          search: getState().collections.search,
          collectionIds,
        });

        const collections = foundCollections.filter((collection) => !!collection);

        const [collection] = collections;

        if (collection) {
          let equalSortings = true;
          if (!isProofing) {
            equalSortings = checkForCollectionsSortingsEquality(collections.map((activeCollection) => {
              if (!activeCollection.sortType) return { ...activeCollection, sortType: defaultSort };
              return activeCollection;
            }));
          }
          if (collectionIds.length > 1 && !equalSortings) {
            sort = isProofing ? window.websiteConfig.sortType || defaultSort : defaultSort;
          } else {
            sort = isProofing ? window.websiteConfig.sortType || collection.sortType : collection.sortType;
          }
        }
      }

      if (inboxId) {
        const { user, inboxes } = getState();
        const inbox = inboxes.inboxes.find(({ _id }) => _id === inboxId);
        if (inbox?.sortType) {
          const sortType = inbox.sortType.find(({ userId }) => user._id === userId);
          if (sortType) sort = sortType;
        }
      }

      if (lightboardId) {
        // we can't use getState().lightboards.activeLightboard
        // because it updates after getAssets action
        const { lightboards } = getState().lightboards;
        const activeLightboard = lightboards.find((lb) => lb._id === lightboardId);

        if (activeLightboard) {
          sort = activeLightboard.sortType;
        }
      }
      const assets = getState().assets.items;
      const { data: response } = await assetsHelpers.search(from, sort, geoData, assets);
      const {
        full, images, total, geo, suggestedSort,
      } = response;

      // Check if user is searching something by text request and found nothing
      const { text } = getState().router.location.query;
      if (total === 0 && text) {
        Logger.log('UI', 'SearchNothingFound', { text });
      }

      const {
        collectionIds: currentCollectionIds,
        lightboardId: currentLightboardId,
        keywordId: currentKeywordId,
        inboxId: currentInboxId,
      } = getIds();
      /**
       * If params changed
       * (for example: fast switching betwen collections, slow internet connection)
       * do not dispatch event to store (other fetch already started)
       */
      if (
        (collectionIds && currentCollectionIds && collectionIds.length !== currentCollectionIds.length)
        || (lightboardId && currentLightboardId && lightboardId !== currentLightboardId)
        || (keywordId && currentKeywordId && keywordId !== currentKeywordId)
        || (inboxId && currentInboxId && inboxId !== currentInboxId)
      ) {
        return;
      }

      if (images) {
        /** Get thumbnails for mages if no custom thumbnail */
        const ids = images.map((asset) => asset._id);
        if (ids.length) getThumbnails(ids)(dispatch, getState);

        dispatch({
          type: TYPES.ASSETS.FETCH.COMPLETE,
          payload: {
            isNewRoute,
            full,
            total,
            items: images,
            suggestedSort,
            geo,
          },
        });
        UiBlocker.unblock();
      } else {
        handleError(response, 'NO IMAGES IN RESPONSE');
      }
    } catch (error) {
      handleError(error);
    }
  }

  const from = isNewRoute || geoData ? 0 : getState().assets.items.length;
  dispatch({
    type: TYPES.ASSETS.FETCH.START,
    payload: { blockUI: isNewRoute },
  });

  if (delay) {
    UiBlocker.block('Processing files...');
    setTimeout(() => doRequest(from), delay);
  } else {
    doRequest(from);
  }
};

export default getAssets;
