import React, { /* useState, */ useCallback, memo } from 'react';
// import { useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import AiButtons from '../AiButtons';
// import {
//   keywordsRelevancePercentagePicsio,
//   keywordsRelevancePercentageGoogle,
//   keywordingLocalesPicsio,
// } from '../../../teammates/configs';
import l10n from '../../../../shared/strings';

// import getAiSettings from './aiSettings';

const Buttons = ({ onSubmit, inProgress, disabled }) => {
  /** AI keywording settings commented as for now */
  // const defaultSettings = useSelector((state) => {
  //   const provider = state.user.team?.policies?.visionProvider || 'google';
  //   const isGoogleProvider = provider === 'google';
  //   return {
  //     visionProvider: provider,
  //     locale: isGoogleProvider ? state.user.team?.policies?.keywordingLocale || 'en'
  //       : keywordingLocalesPicsio[0].value,
  //     relevance: isGoogleProvider ? Number(state.user.team?.policies?.keywordsRelevance) || undefined
  //       : keywordsRelevancePercentagePicsio[0].value,
  //   };
  // });

  // const [value, setValue] = useState(defaultSettings);

  // const handleChange = useCallback((menuId, itemId) => {
  //   setValue((prevValue) => {
  //     /** If changed engine */
  //     if (menuId === 'visionProvider') {
  //       if (itemId === 'llava') {
  //         /** Settions for Picsio engine */
  //         return {
  //           visionProvider: itemId,
  //           locale: keywordingLocalesPicsio[0].value,
  //           relevance: keywordsRelevancePercentagePicsio[0].value,
  //         };
  //       }
  //       /** Settions for Google engine */
  //       return {
  //         visionProvider: itemId,
  //         locale: defaultSettings.locale,
  //         relevance: keywordsRelevancePercentageGoogle.find(({ value: v }) => v === defaultSettings.relevance)
  //           ? defaultSettings.relevance : keywordsRelevancePercentageGoogle[0].value,
  //       };
  //     }
  //     /** If changed lang or relevance */
  //     return {
  //       ...prevValue,
  //       [menuId]: itemId,
  //     };
  //   });
  // }, [defaultSettings]);

  const handleGenerate = useCallback(() => {
    onSubmit(/** value */);
  }, [onSubmit]);

  return (
    <AiButtons
      inProgress={inProgress}
      onSubmit={handleGenerate}
      menuControls={null /* getAiSettings(value.visionProvider) */}
      menuValue={null /* value */}
      onChangeMenu={null /* handleChange */}
      buttonText={inProgress ? l10n.DETAILS.textTagging : l10n.DETAILS.textTagWithAI}
      buttonTestId="generateKeywords"
      disabled={disabled}
    />
  );
};
Buttons.defaultProps = {
  inProgress: false,
  disabled: false,
};

Buttons.propTypes = {
  onSubmit: func.isRequired,
  inProgress: bool,
  disabled: bool,
};

export default memo(Buttons);
