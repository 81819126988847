import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _cloneDeep from 'lodash/cloneDeep';
import * as userActions from '../../reducers/user';
import Logger from '../../../services/Logger';
import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';

const savePolicy = (data, setImmediately = true) => async (dispatch, getState) => {
  if (setImmediately) {
    dispatch(userActions.savePolicySuccess({ result: data }));
    return;
  }
  try {
    const { user } = getState();
    const { policies } = user.team;
    const { key } = data;
    const prevValue = _get(policies, key, false);
    const prevData = {
      key,
      value: prevValue,
    };

    dispatch(userActions.savePolicyStart({ updating: true }));
    dispatch(userActions.savePolicySuccess({ result: data }));

    const { data: response } = await sdk.users.savePolicy(data);

    if (!response) {
      dispatch(userActions.savePolicySuccess({ result: prevData }));
      dispatch(userActions.savePolicyFailure());
    } else {
      const newPolicies = _cloneDeep(policies);
      if (data.value) _set(newPolicies, key, data.value);
      else _unset(newPolicies, key, data.value);
    }
  } catch (err) {
    const connection = utils.getNavigatorConnectionInfo();
    Logger.error(new Error('Can not update user'), { error: err, data }, [
      'savePolicyFailed',
      { errorMessage: (err && err.message) || 'NoMessage', connection },
    ]);
    dispatch(userActions.savePolicyFailure());
  }
};

export default savePolicy;
