/* eslint-disable no-param-reassign */
import validateFields from './validateFields';
import groupErrors from './groupErrors';

export default function setRequiredFieldErrors(state) {
  Object.values(state.items).forEach((files) => {
    files.forEach((file) => {
      const { isValid, errors, error } = validateFields({ fields: file.fields, requiredFields: state.requiredFields, importFrom: file.file?.importFrom });
      file.isValid = isValid;
      file.errors = isValid ? undefined : errors;
      file.error = isValid ? undefined : error;
    });
  });

  state.errors = groupErrors(Object.values(state.items).flat());
}
