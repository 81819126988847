import axios from 'axios';
import picsioConfig from '../../../../../config';

/**
 * Notify team about assets limit
 * @param {string} inboxId
 * @returns Promise<AxiosResponse>
 */
export default function notifyAboutAssetsLimit(inboxId) {
  return axios.put(`${picsioConfig.inboxesBaseUrl}/notifyAboutAssetsLimit`, { inboxId });
}
