import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

const UNKNOWN = 'unknown';

/**
 * Send downloaded notification
 * @param {Array} assets
 * @returns {Promise}
 */
export default function sendDownloadedNotification(assets) {
  const groupedAssets = assets.reduce((acc, item) => {
    // exclude archives and assets with convertation
    if (item.name && item._id && !item.pollUrl) {
      /** need to know collection which download from for Proofing */
      const downloadFrom = item.downloadFrom || window.websiteConfig?.tag?._id || UNKNOWN;
      if (!acc[downloadFrom]) acc[downloadFrom] = [];
      acc[downloadFrom].push({
        name: item.name,
        _id: item._id,
      });
    }
    return acc;
  }, {});

  // can be, when we was downloading archives
  if (!Object.keys(groupedAssets).length) return Promise.resolve();

  /** @TODO - for what ??? */
  const headers = { 'Picsio-API-Token': 'XPhOfbce8lGLXNnfCAAzE9mp3IesIT0P' };

  if (picsioConfig.isMainApp) {
    const assetsToSend = Object.values(groupedAssets).reduce((a, items) => [...a, ...items], []);
    return sdk.assets.downloaded(assetsToSend, headers);
  }

  return Promise.all(Object.keys(groupedAssets).map((downloadFrom) => {
    const params = {
      assets: groupedAssets[downloadFrom],
      headers,
    };
    /** if we know collection id */
    if (downloadFrom !== UNKNOWN) params.downloadFrom = downloadFrom;

    return sdk.assets.downloadedPublic(
      picsioConfig.apiBaseUrl,
      window.websiteConfig.alias,
      params,
    );
  }));
}
