import TYPES from '../../action-types';

/**
 * Change total assets count
 * @param {number} count
 */
const changeCount = (count) => (dispatch) => dispatch({
  type: TYPES.COLLECTIONS.CHANGE_ASSETS_COUNT,
  payload: { count },
});

export default changeCount;
