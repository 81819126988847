import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Set color to assets
 * @param {string[]} assetIds
 * @param {string} value - must be one of "red | yellow | green | blue | purple | nocolor"
 * @returns Promise<AxiosResponse>
 */
export default function setColor(assetIds, value) {
  if (picsioConfig.isMainApp) return sdk.assets.setColor(assetIds, value);

  return sdk.assets.setColorPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    value,
  );
}
