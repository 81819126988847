import Store from '../../index';
import * as assetsHelpers from '../../../helpers/assets';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import {
  updateCount as updateCountAction,
} from '../../keywords/actions';
import Logger from '../../../services/Logger';
import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Remove keyword from assets
 * @param {string} keywordName
 * @param {string[]?} ids - assets IDs
 */
const detachKeyword = (keywordID, ids) => async (dispatch, getAll) => {
  const selectedItems = ids || getAll().assets.selectedItems;
  try {
    await asyncCheckMultipleChanges(selectedItems.length);
  } catch (error) {
    /** User cancelled operation */
    return false;
  }

  try {
    dispatch({ type: TYPES.ASSETS.DETACH_KEYWORD.START });

    /** wait for response */
    await assetsHelpers.detachKeyword(selectedItems, keywordID);
    dispatch({
      type: TYPES.ASSETS.DETACH_KEYWORD.COMPLETE,
      payload: {
        ids,
        keywordsIds: [keywordID],
        userId: Store.getState().user._id,
      },
    });
    dispatch(updateCountAction({ _id: keywordID, value: -selectedItems.length }));
    return true;
  } catch (error) {
    Logger.error(new Error('Can not attach keyword to asset'), { error });
    const errorStatus = utils.getStatusFromResponceError(error);
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : utils.getDataFromResponceError(error, 'msg');
    if (errorMessage) {
      Toast(errorMessage, { autoClose: false, type: 'error' });
    } else {
      Toast(localization.DETAILS.textCantUpdateKeywords, {
        autoClose: false,
        type: 'error',
        onOk: reloadCurrentPage,
        btnOkValue: localization.HISTORY.textBtnRefresh,
      });
    }
    dispatch({ type: TYPES.ASSETS.DETACH_KEYWORD.FAILED, error });
    return false;
  }
};

export default detachKeyword;
