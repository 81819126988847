import sdk from '../../../sdk';

/** Get Google Storage Ids for file to upload, and update it, if needed
 * @param {*} item
 * @param {*} user
 * @param {Function?} updateFn - function to update lightboard id in the user Store
 * @returns {Promise<string[]>}
 */
export default async function getGoogleFolderIDs({ collection, lightboard }, user, updateFn) {
  if (collection) {
    return [collection.storageId];
  }
  if (lightboard) {
    let storageId = (user.isTeammate ? user.team : user).lightboardsFolderId;
    if (!storageId) {
      ({ data: storageId } = await sdk.lightboards.getLightboardsFolderId());
      if (updateFn) updateFn(storageId);
    }
    return [storageId];
  }
  return [];
}
