/**
 * Remove savedSearches
 * @param {Object} tree
 * @param {Object[]} all
 * @param {string} id
 * @returns {Object}
 */
export default function removeSavedSearches(tree, all, id) {
  const result = {
    all: [...all],
    favorites: { ...tree.favorites },
    savedSearches: { ...tree.savedSearches },
  };

  const item = all.find((el) => el._id === id);
  const handle = (ss) => {
    if (ss.nodes && ss.nodes.length > 0) {
      const filteredNodes = ss.nodes.filter((node) => node._id !== id);
      return {
        ...ss,
        nodes: filteredNodes.map(handle),
      };
    }
    return ss;
  };

  if (item) {
    result.all = result.all.filter((node) => node._id !== id);
    result.savedSearches = handle(result.savedSearches);
    result.favorites = handle(result.favorites);
  }

  return result;
}
