import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import { reloadCurrentPage } from '../../../helpers/history';

const FINISH_IN_BACKGROUND_SIZE = 100;

/**
 * Remove assets from lightboard
 * @param {Object} lightboard
 * @param {string} lightboard._id
 * @param {string} lightboard.path
 * @param {string[]?} _ids
 */
const removeFromLightboard = ({ _id, path }, _ids) => (dispatch, getAll) => {
  const { items, selectedItems } = getAll().assets;
  const ids = _ids || selectedItems;

  /** Prepare text for dialog */
  const selected = items.filter((item) => ids.includes(item._id));
  const assetsToModify = selected.filter((asset) => (asset.lightboards || []).some((lb) => lb._id === _id));
  const assetsToDelete = assetsToModify.filter((asset) => {
    if (asset.tags && asset.tags.length) return false;
    if (asset.lightboards && asset.lightboards.length > 1) return false;
    return true;
  });

  const text = assetsToDelete.length
    ? localization.DETAILS.textRemoveFilesFromLightboardAndDelete
    : localization.DETAILS.textRemoveFilesFrom(
      ids.length === 1 && assetsToModify?.length === 1 ? assetsToModify[0].name : ids.length,
      path.split('→').pop(),
      'lightboard',
    );

  /** Show dialog */
  Logger.log('UI', 'RemoveFromLightboardConfirmDialog');
  showDialog({
    title: localization.DETAILS.titleRemovingFromLightboard,
    children: text,
    textBtnCancel: localization.DIALOGS.btnCancel,
    textBtnOk: localization.DIALOGS.btnRemove,
    onOk: doRemove,
  });

  async function doRemove() {
    UiBlocker.block();
    try {
      dispatch({ type: TYPES.ASSETS.REMOVE_LIGHTBOARD.START });

      const { data: res } = await sdk.assets.removeFromLightboard(ids, _id);
      const failed = (res && res.failed) || [];
      const failedIds = failed.map((asset) => asset._id);
      /** completed */
      const removedAssetIds = ids.filter((id) => !failedIds.includes(id));

      if (failed && failed.length) {
        Toast(
          localization.DETAILS.textDetachFromLightboardSomeAssetsFailed({
            count: failed.length,
            lightboard: path.slice(1),
          }),
          {
            autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
          },
        );
      } else if (ids.length > FINISH_IN_BACKGROUND_SIZE) {
        Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
      }
      dispatch({
        type: TYPES.ASSETS.REMOVE_LIGHTBOARD.COMPLETE,
        payload: {
          ids: removedAssetIds,
          lightboardId: _id,
        },
      });
    } catch (error) {
      const textDetach = localization.DETAILS.textDetachFromLightboardSomeAssetsFailed({
        count: '',
        lightboard: path.slice(1),
      });
      Toast(textDetach, { autoClose: false });
      Logger.error(new Error('Can not remove assets from lightboard'), { error }, [
        'RemoveFromLightboardFailed',
        (error && error.message) || 'NoMessage',
      ]);
      dispatch({
        type: TYPES.ASSETS.REMOVE_LIGHTBOARD.FAILED,
        error,
      });
    }
    UiBlocker.unblock();
  }
};

export default removeFromLightboard;
