import sdk from '../../sdk';
import { getDataFromResponceError } from '../../shared/utils';
import l18n from '../../shared/strings';
import Toast from '../../components/Toast/index';
import { reloadCurrentPage } from '../history';

export default function editComment(assetId, data) {
  try {
    return sdk.assets.editComment(assetId, data);
  } catch (error) {
    const subcode = getDataFromResponceError(error, 'subcode');

    if (subcode === 'AssetNotFoundApiError') {
      Toast(l18n.HISTORY.textAssetNotFound, { closeButton: false, type: 'error' });
    } else if (subcode === 'EditCommentApiError') {
      Toast(l18n.HISTORY.textEditCommentApiError, { closeButton: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
    } else {
      Toast(l18n.HISTORY.textCantUpdateComment, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
    }
    throw error;
  }
}
