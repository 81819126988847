import React from 'react';
import PropTypes from 'prop-types';

export default function Spinner(props) {
  const { spinnerTitle = null, uploadRevisionProgress = null } = props;

  return (
    <div className="picsioSpinner partial" style={{ zIndex: 7 }}>
      <div className="innerPicsioSpinner">
        <div className="spinner">
          <div />
          <span />
        </div>
        <If condition={spinnerTitle != null}>
          <span className="titleSpinner show">
            {spinnerTitle}
            {uploadRevisionProgress && (
              <span className="spinnerProgress">{`${uploadRevisionProgress}%`}</span>
            )}
          </span>
        </If>
      </div>
    </div>
  );
}

Spinner.propTypes = {
  spinnerTitle: PropTypes.string,
  uploadRevisionProgress: PropTypes.number,
};
