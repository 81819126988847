/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { removeFromSelection as removeFromSelectionHelper } from '../helpers/keywords';

export const removeFromSelection = createAction('keywords/removeFromSelection');

export const reducer = (builder) => {
  builder.addCase(
    removeFromSelection.type,
    (state, { payload: _id }) => {
      state.selectedKeywords = removeFromSelectionHelper(state.all, state.selectedKeywords, _id);
    },
  );
};
