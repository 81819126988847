/* eslint-disable no-param-reassign */
import { deleted, deleteKeywords, merge } from '../keywords/actions';

export default function remove(builder) {
  builder.addMatcher(
    (action) => [deleted.type, deleteKeywords.fulfilled.type, merge.fulfilled.type].includes(action.type),
    (state, { payload }) => {
      /** can be array of ids or single id */
      const ids = typeof payload === 'string' ? [payload] : payload.ids || [];
      if (ids.length) {
        state.items = state.items.filter((item) => {
          if (ids.includes(item._id)) return null;
          return item;
        }).filter(Boolean);
      }
    },
  );
}
