import { createAsyncThunk } from '@reduxjs/toolkit';

import { findCollectionById } from '../helpers/archive';
import * as helpers from '../../helpers/collections';

const setActiveCollection = createAsyncThunk(
  'archive/setActiveCollection',
  async (collectionId, { rejectWithValue, dispatch, getState }) => {
    const currectActiveCollection = getState().archive.activeCollection;
    if (currectActiveCollection?._id === collectionId) return;

    try {
      if (collectionId === null) {
        if (currectActiveCollection === null) return;
        dispatch({ type: 'archive/setActiveCollection', payload: { collection: null } });
        return;
      }

      let collection = findCollectionById(getState().archive.collections, collectionId);

      let collectionIds = collectionId;
      if (typeof collectionId === 'string') {
        collectionIds = [collectionId];
      }

      if (!collection) {
        collection = await helpers.forceFindTagWithTagId({
          collections: getState().collections.collections,
          search: getState().collections.search,
          collectionIds,
        });
        collection = collection.filter((collectionValue) => !!collectionValue);
      }

      dispatch({ type: 'archive/setActiveCollection', payload: { collection } });

      return { collection };
    } catch (e) {
      return rejectWithValue(e);
    } finally {};
  },
);

export default setActiveCollection;
