/* eslint-disable no-param-reassign */
import PQueue, { AbortError } from 'p-queue';
import { STORAGE_LITERALS } from '@picsio/db/src/constants';
import getFilesByIds from '../helpers/getFilesByIds';
import { updateFile } from '../updateFile';
import { updateTeamValue } from '../../reducers/user';
import createRevisionData from './createRevisionData';
import createAssetData from './createAssetData';
import uploadFile from '../helpers/uploadFile';
import saveNamesToLS from '../helpers/saveNamesToLS';
import saveAsset from './saveAsset';
import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import { showDialog } from '../../../components/dialog';
import createUploadingProgressHelper from './createUploadingProgressHelper';
import calculateBytesTotalSize from './calculateBytesTotalSize';
import prepareFileIds from './prepareFileIds';
import { findCollection } from '../../helpers/collections';
import sdk from '../../../sdk';
import l10n from '../../../shared/strings';
import Toast from '../../../components/Toast';
import pushCollections from '../../actions/collections/pushCollections';

const startFileUploadQueue = async ({ getState, dispatch, payload }) => {
  const { collections, import: importStore, user } = getState();
  const ids = await prepareFileIds({ payload, importStore, dispatch });
  const rootCollectionId = collections.collections?.my?._id;
  const isUserOnS3 = user.team.storageType === 's3';
  const { disableParallelUpload } = user.settings || {};
  const queue = new PQueue({ concurrency: disableParallelUpload ? 1 : 5 });
  const totalSize = calculateBytesTotalSize(importStore.items);
  const setUploadingProgress = createUploadingProgressHelper(totalSize);
  const uploadId = Date.now();

  saveNamesToLS(importStore.items);

  ids.forEach(async (id) => {
    const controller = new AbortController();

    try {
      const res = await queue.add(
        async ({ signal }) => {
          const { items } = getState().import;
          const [item] = getFilesByIds(items, [id]);
          const isFromCloud = item.file?.importFrom?.storageType === STORAGE_LITERALS.user_dbx;
          let asset;

          if (!item || signal.aborted) return null;

          const {
            file, action, duplicatedModel, fields,
          } = item;
          const isUploadRevision = action === 'addRevision';

          dispatch(
            updateFile({
              id,
              fields: {
                controller,
                error: undefined,
              },
            }),
          );

          if (!isFromCloud) {
            const {
              isError,
              isCancelled,
              ...storageResponse
            } = await uploadFile({
              item,
              signal,
              uploadToS3: isUserOnS3,
              Logger,
              showDialog,
              utils,
              user: getState().user,
              updateLightboardStorageId: (lightboardsStorageId) => {
                dispatch(
                  updateTeamValue('lightboardsFolderId', lightboardsStorageId),
                );
              },
              setProgress: (percentage, bytesUploaded) => {
                setUploadingProgress(id, bytesUploaded);
                dispatch(
                  updateFile({
                    id,
                    fields: {
                      progress: percentage,
                      bytesUploaded,
                    },
                  }),
                );
              },
            });

            if (isCancelled) return null;

            if (isError) {
              const error = storageResponse.reason
                ? storageResponse
                : {
                  code: 408,
                  reason: 'networkError',
                  message: 'Network Error',
                };
              dispatch(
                updateFile({
                  id,
                  fields: {
                    progress: 0,
                    bytesUploaded: 0,
                    controller: null,
                    error,
                  },
                }),
              );
              setUploadingProgress(id, 0);

              Logger.info('Error upload asset to Storage: ', error);
              return null;
            }

            dispatch(
              updateFile({
                id,
                fields: {
                  progress: 100,
                  bytesUploaded: file.size,
                  controller: null,
                },
              }),
            );
            setUploadingProgress(id, file.size);

            const assetData = isUploadRevision
              ? createRevisionData(storageResponse)
              : createAssetData({
                storageResponse,
                uploadItem: item,
                rootCollectionId,
                isUploadToS3: isUserOnS3,
                uploadId,
              });

            try {
              asset = await saveAsset({
                item,
                assetData,
                action,
                fields,
                duplicatedModel,
                isUploadRevision,
                storageResponse,
              });
            } catch (fileError) {
              dispatch(
                updateFile({
                  id,
                  fields: {
                    progress: 0,
                    bytesUploaded: 0,
                    error: fileError,
                  },
                }),
              );
              setUploadingProgress(id, 0);
              return null;
            }
          } else if (item.file['.tag'] === 'folder') {
            const parts = item.path.split('/');
            const result = parts.slice(1).join('/');
            try {
              const foundedCollection = findCollection({ collection: item.collection }, null, { name: item.file.name, path: `/${result}` });
              if (foundedCollection === undefined) {
                const { data: newCollection } = await sdk.collections.create({ tag: { name: item.file.name }, path: result, importFrom: item.file.importFrom });
                dispatch(pushCollections([newCollection]));
              }
              return null;
            } catch (error) {
              const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
              if (errorSubcode === 'UnsafeCharactersError') {
                Toast(l10n.IMPORT.unsafeCharactersError(item.file.name), { autoClose: false, type: 'error' });
              } else if (errorSubcode === 'CollectionAlreadyExistsInArchiveApiError') {
                Toast(
                  l10n.IMPORT.collectionAlreadyExistsInArchiveApiError(item.file.name),
                  { autoClose: false, type: 'error' },
                );
              }
              return null;
            }
          } else {
            try {
              asset = await saveAsset({
                item,
                assetData: createAssetData(
                  {
                    storageResponse: {},
                    uploadItem: item,
                    rootCollectionId,
                    isUploadToS3: isUserOnS3,
                    uploadId,
                  },
                ),
                action,
                fields,
                duplicatedModel,
                isUploadRevision,
              });
            } catch (fileError) {
              dispatch(
                updateFile({
                  id,
                  fields: {
                    progress: 0,
                    bytesUploaded: 0,
                    error: fileError,
                  },
                }),
              );
              setUploadingProgress(id, 0);
              return null;
            }
          }

          dispatch(
            updateFile({
              id,
              fields: {
                complete: true,
                _id: asset._id || duplicatedModel._id,
                revisionId: asset.headRevisionId,
              },
            }),
          );

          return asset;
        },
        { signal: controller.signal },
      );
      return res;
    } catch (error) {
      if (!(error instanceof AbortError)) throw error;
      return null;
    } finally {
      saveNamesToLS(getState().import.items);
    }
  });

  await queue.onIdle();
};

export default startFileUploadQueue;
