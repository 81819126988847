import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import { navigate, reloadCurrentPage } from '../../../helpers/history';

/**
 * Change sharing settings
 * @param {string} id - asset id
 * @param {string} key
 * @param {*} value
 */
const changeShare = (id, key, value) => async (dispatch, getAll) => {
  const asset = getAll().assets.items.find((item) => item._id === id);

  if (asset) {
    try {
      dispatch({ type: TYPES.ASSETS.SHARE.START });

      const { data: response } = await sdk.assets.setSingleSharingSettings(id, {
        [key]: value,
      });
      const { singleSharingSettings } = response.asset;
      const data = {
        ...singleSharingSettings,
        isProtected: !!singleSharingSettings.isProtected,
      };

      if (key === 'isShared') {
        data.isShared = value;
      }

      dispatch({
        type: TYPES.ASSETS.SHARE.COMPLETE,
        payload: {
          assetId: id,
          data,
        },
      });
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      const subcode = utils.getDataFromResponceError(error, 'subcode');
      const { ERROR_PLAN_LIMIT } = localization.DIALOGS;

      if (errorStatus === 409 && subcode === 'AssetSharingLimitExceededError') {
        showDialog({
          title: ERROR_PLAN_LIMIT.TITLE,
          text: ERROR_PLAN_LIMIT.TEXT,
          textBtnOk: ERROR_PLAN_LIMIT.OK_TEXT,
          textBtnCancel: null,
          async onOk() {
            navigate('/billing?tab=overview');
          },
        });
      } else {
        Toast(localization.DETAILS.textCantSetSingleAssetSettings, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      }

      dispatch({ type: TYPES.ASSETS.SHARE.FAILED, error });
      Logger.error(new Error('Can not set single sharing settings'), { error }, [
        'ShareAssetFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  }
};
export default changeShare;
