import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sanitizeXSS from '../shared/sanitizeXSS';


export default function TextareaWithStriptags({
  name = '',
  value,
  placeholder = '',
  className = '',
  onChange = Function.prototype,
  onFocus = Function.prototype,
  onBlur = Function.prototype,
  onKeyDown = Function.prototype,
  defaultValue,
  disabled = false,
  customRef = { current: null },
  autoFocus = false,
  autoComplete = 'false',
  style = {},
  stripTagsEnable = true,
  canWriteTags = false,
}) {
  const [textareaValue, setTextareaValue] = useState(value || null);

  useEffect(() => {
    if (value !== textareaValue) {
      setTextareaValue(value);
    }
  }, [value, textareaValue]);

  const handleOnChange = (e) => {
    let sanitizedValue;
    if (!canWriteTags) {
      sanitizedValue = sanitizeXSS(
        e.currentTarget.value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }, true,
      );
    } else {
      sanitizedValue = stripTagsEnable ? sanitizeXSS(
        e.currentTarget.value, null, true,
      ) : e.currentTarget.value;
    }
    setTextareaValue(sanitizedValue);
    onChange(e, sanitizedValue);
  };

  const handleOnBlur = (e) => {
    const sanitizedValue = stripTagsEnable
      ? sanitizeXSS(e.currentTarget.value, null, true) : e.currentTarget.value;
    setTextareaValue(sanitizedValue);
    onBlur(e, sanitizedValue);
  };

  return (
    <textarea
      name={name}
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={textareaValue || value}
      disabled={disabled}
      ref={customRef}
      onChange={handleOnChange}
      onFocus={(e) => onFocus(e, e.currentTarget.value)}
      onBlur={handleOnBlur}
      onKeyDown={(e) => onKeyDown(e, e.currentTarget.value)}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      style={style}
    />
  );
}

TextareaWithStriptags.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  customRef: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.any }), PropTypes.func]),
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  stripTagsEnable: PropTypes.bool,
  canWriteTags: PropTypes.bool,

};
