import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Face } from '@picsio/icons';
import { Icon } from '@picsio/ui';
import {
  func, string, number, bool, shape,
} from 'prop-types';
import Dropdown from '../../dropdown';
import { sortFaces } from '../../../helpers/faces';

const FaceDropdown = (props) => {
  const {
    isFocused = false,
    faceOnAsset = {},
    faceDropdownOnClick = null,
    applyFace = null,
    assetId,
    tmpBoundingBox = null,
    addCustomFace = null,
    removeTmpBoundingBox = null,
    index,
    managePersons = false,
    imageRevisionID = '',
    removeBoundingBox = null,
  } = props;
  const [checkedItems, setCheckedItems] = useState([]);
  const [newFaceIsClicked, setNewFaceIsClicked] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const allFaces = useSelector((state) => state.faces.all);
  const modifiedFaces = allFaces.map((face) => ({ ...face, title: face.name, url: face.avatar || (face.isAvatarUrlReady && `/faces/${face._id}/avatar`) })); // for dropdown we need title and url
  const currentFace = modifiedFaces?.filter((face) => face._id === faceOnAsset._id); // if already face was selected before
  const sortedFaces = sortFaces(modifiedFaces);

  useEffect(() => {
    setInProgress(false);
    setCheckedItems(currentFace);
  }, [faceOnAsset]);

  const selectNewFace = (item) => {
    if (!newFaceIsClicked) {
      if (!tmpBoundingBox) {
      // apply existing face on existing bb
        applyFace({
          assetId,
          currentFaceId: faceOnAsset._id,
          newFaceId: item._id || item[0]._id,
          boundingBox: faceOnAsset.boundingBox,
          name: item.name || item[0].name,
          revisionId: imageRevisionID,
          isNewFace: false,
          unnamed: false,
        });
      } else {
      // create new face and bb
        addCustomFace({
          assetId,
          faceId: item._id || item[0]._id,
          boundingBox: {
            width: tmpBoundingBox?.boundingBox?.width,
            height: tmpBoundingBox?.boundingBox?.height,
            left: tmpBoundingBox?.boundingBox?.x,
            top: tmpBoundingBox?.boundingBox?.y,
          },
          revisionId: imageRevisionID,
          name: item.name || item[0].name,
          isNewFace: false,
        });
        removeTmpBoundingBox(index);
      }
    } else if (tmpBoundingBox) {
      removeTmpBoundingBox(index);
    }
    setNewFaceIsClicked(false);
    setCheckedItems(item.length ? item : [item]);
  };

  const handleCreateNewFace = (name) => {
    setInProgress(true);
    if (!tmpBoundingBox) {
      applyFace({
        assetId,
        currentFaceId: faceOnAsset._id,
        boundingBox: faceOnAsset.boundingBox,
        name,
        revisionId: imageRevisionID,
        isNewFace: true,
        unnamed: false,
      });
    } else {
      addCustomFace({
        assetId,
        boundingBox: {
          width: tmpBoundingBox?.boundingBox?.width,
          height: tmpBoundingBox?.boundingBox?.height,
          left: tmpBoundingBox?.boundingBox?.x,
          top: tmpBoundingBox?.boundingBox?.y,
        },
        name,
        revisionId: imageRevisionID,
        isNewFace: true,
      });
    }
    setNewFaceIsClicked(true);
  };

  const handleClickOnDropdown = () => {
    faceDropdownOnClick(faceOnAsset.boundingBox);
  };

  const handleUncheck = (faceItem) => {
    removeBoundingBox(assetId, faceItem._id, faceOnAsset.boundingBox);
  };

  return (
    <div style={{ marginBottom: '4px', border: isFocused ? '1px solid #3C86DA' : null, width: '100%' }}>
      <Dropdown
        items={sortedFaces}
        checkedItems={checkedItems}
        type="face"
        onCheckedHandler={selectNewFace}
        onDropdownOpenClickHandler={handleClickOnDropdown}
        createHandler={managePersons ? handleCreateNewFace : null}
        isOnlyCreate
        icon={() => <Icon><Face /></Icon>}
        singleChose
        filterText={allFaces.length > 0 ? 'Search persons' : 'Type person name'}
        autoFocus
        facesDropdown
        inProgress={inProgress}
        onUncheckedHandler={handleUncheck}
      />
    </div>
  );
};

FaceDropdown.propTypes = {
  assetId: string.isRequired,
  index: number.isRequired,
  isFocused: bool,
  faceOnAsset: shape({}),
  faceDropdownOnClick: func,
  applyFace: func,
  tmpBoundingBox: shape({}),
  addCustomFace: func,
  removeTmpBoundingBox: func,
  managePersons: bool,
  imageRevisionID: bool,
  removeBoundingBox: func,
};

export default FaceDropdown;
