import UiBlocker from '../../services/UiBlocker';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';
import { showDialog } from '../../components/dialog';
import * as utils from '../../shared/utils';
import renderDownloadDialog from '../../components/DownloadDialog/renderDownloadDialog';
import getCollectionAssets from '../assets/getCollectionAssets';
import store from '../../store';

const LIMIT_ASSETS_COUNT = 300;

/**
 * download collection
 * @param {string} collectionId - collectionId to download
 */
async function downloadCollection(collectionId, rolePermissions, archived = false) {
  const { user } = store.getState();

  try {
    UiBlocker.block(localization.SPINNERS.CHECKING_DOWNLOAD_PERMISSIONS);
    const result = await getCollectionAssets(collectionId, archived);
    const assets = result.data.images || [];

    /** collection is empty: show dialog nothing to download */
    if (!assets.length) {
      UiBlocker.unblock();
      Logger.log('UI', 'DownloadCollectionEmptyDialog');
      const { TITLE, TEXT } = localization.DIALOGS.DOWNLOAD_COLLECTION_EMPTY;
      showDialog({
        title: TITLE,
        text: TEXT,
        textBtnCancel: null,
      });

      return;
    }

    /** check for restricted assets and user permissions */
    const restrictedAssets = [];
    const assetsNotAllowedForDownload = [];
    const assetsAllowedForDownload = [];
    assets.forEach((asset) => {
      if (
        rolePermissions.restrictedDownload === false
        && asset.restrictSettings
        && utils.isAssetRestricted(asset.restrictSettings)
      ) {
        restrictedAssets.push(asset);
      } else if (!asset.permissions.downloadFiles) {
        assetsNotAllowedForDownload.push(asset);
      } else {
        assetsAllowedForDownload.push(asset);
      }
    });

    UiBlocker.unblock();

    /** if nothing to download */
    if (!assetsAllowedForDownload.length) {
      Logger.log('UI', 'DownloadCollectionRestrictedDialog');
      const { TITLE, TEXT } = localization.DIALOGS.DOWNLOAD_COLLECTION_RESTRICTED;
      showDialog({
        title: TITLE,
        text: TEXT,
        textBtnCancel: null,
      });
      return;
    }

    const doDownload = () => {
      /** check files count */
      const countLimit = user.settings?.zipperMaxAssetsLimit || user.team?.settings?.zipperMaxAssetsLimit || window.websiteConfig?.user?.settings?.zipperMaxAssetsLimit || LIMIT_ASSETS_COUNT;
      if (assetsAllowedForDownload.length > countLimit) {
        Logger.log('UI', 'TooManyFilesForDownloadDialog', `${assetsAllowedForDownload.length}`);
        const { TITLE, TEXT } = localization.DIALOGS.DOWNLOAD_ASSETS_QUANTITY_LIMITATIONS;
        showDialog({
          title: TITLE,
          text: TEXT(countLimit),
          textBtnCancel: null,
        });
        return;
      }

      assetsAllowedForDownload[0].collectionId = collectionId;
      renderDownloadDialog(assetsAllowedForDownload, { collectionId, loadedItems: [] });
    };

    if (restrictedAssets.length || assetsNotAllowedForDownload.length) {
      Logger.log('UI', 'DownloadCollectionLimitedDialog', {
        restrictedAssets: restrictedAssets.length,
        notAllowed: assetsNotAllowedForDownload.length,
      });
      const {
        TITLE,
        TEXT,
        OK_TEXT,
        CANCEL_TEXT,
      } = localization.DIALOGS.DOWNLOAD_COLLECTION_LIMITED;
      showDialog({
        title: TITLE,
        text: TEXT,
        textBtnOk: OK_TEXT,
        textBtnCancel: CANCEL_TEXT,
        onOk() {
          Logger.log('User', 'DownloadCollectionLimitedDialogOk');
          doDownload();
        },
        onClose() {
          Logger.log('User', 'DownloadCollectionLimitedDialogCancel');
        },
      });

      return;
    }

    doDownload();
  } catch (error) {
    UiBlocker.unblock();
  }
}

export default downloadCollection;
