import picsioConfig from '../../../../../config';

function preparePermissions(permissions, archived) {
  const result = {
    fileNameShow: picsioConfig.isMainApp || picsioConfig.access.fileNameShow,
    fileNameEditable: picsioConfig.isMainApp && permissions.editAssetFilename,
    titleOrDescriptionShow:
    picsioConfig.isMainApp || picsioConfig.access.titleShow || picsioConfig.access.descriptionShow,
    titleEditable: picsioConfig.isMainApp ? permissions.editAssetTitle : picsioConfig.access.titleEditable,
    titleShow: picsioConfig.isMainApp || picsioConfig.access.titleShow,
    descriptionEditable: picsioConfig.isMainApp
      ? permissions.editAssetDescription
      : picsioConfig.access.descriptionEditable,
    descriptionShow: picsioConfig.isMainApp || picsioConfig.access.descriptionShow,
    keywordsCanCreate: picsioConfig.isMainApp,
    keywordsEditable: picsioConfig.isMainApp && permissions.editAssetKeywords,
    upload: picsioConfig.isMainApp && permissions.upload,
    keywordsAutogeneration:
      picsioConfig.isMainApp && permissions.editAssetKeywords && permissions.autogenerateKeywords,
    descriptionAutogeneration:
      picsioConfig.isMainApp && permissions.editAssetDescription && permissions.autogenerateDescriptions,
    autogenerateTranscript: picsioConfig.isMainApp && permissions.autogenerateTranscript,
    assetAssigneesEditable: picsioConfig.isMainApp && permissions.editAssetAssignees,
    linkedAssetsEditable: picsioConfig.isMainApp && permissions.editAssetLinkedAssets,
    collectionsShow: picsioConfig.isMainApp,
    collectionsEditable: picsioConfig.isMainApp && permissions.editAssetCollections,
    lightboardsShow: picsioConfig.isMainApp,
    lightboardsEditable: picsioConfig.isMainApp,
    flagEditable: picsioConfig.isMainApp ? permissions.editAssetMarks : picsioConfig.access.flag,
    flagShow: picsioConfig.isMainApp || picsioConfig.access.flagShow,
    ratingEditable: picsioConfig.isMainApp ? permissions.editAssetMarks : picsioConfig.access.rating,
    ratingShow: picsioConfig.isMainApp || picsioConfig.access.ratingShow,
    colorEditable: picsioConfig.isMainApp ? permissions.editAssetMarks : picsioConfig.access.color,
    colorShow: picsioConfig.isMainApp || picsioConfig.access.colorShow,
    customFieldsShow: picsioConfig.isMainApp || picsioConfig.access.customFieldsShow,
    customFieldsEditable: picsioConfig.isMainApp && permissions.editAssetMetadata,
    flagOrColorOrRatingShow: picsioConfig.isMainApp
      || picsioConfig.access.flagShow
      || picsioConfig.access.ratingShow
      || picsioConfig.access.colorShow,
    assetsIsDownloadable: picsioConfig.isMainApp
      && (archived
        ? permissions.downloadArchive && permissions.downloadFiles
        : permissions.downloadFiles),
    assetsIsRemovable: picsioConfig.isMainApp
      && (archived ? permissions.deleteArchive && permissions.deleteAssets : permissions.deleteAssets),
    isAllowedSAS: picsioConfig.isMainApp && permissions.websites,
    isRestrictEditable: picsioConfig.isMainApp && permissions.manageAssetRestrictSettings,
    restrictedIsDownloadableOrShareable: picsioConfig.isMainApp && permissions.restrictedDownload,
    restrictedMetadataEditable: picsioConfig.isMainApp && permissions.restrictedChangeMetadata,
    restrictedIsAttachableOrRemovable: picsioConfig.isMainApp && permissions.restrictedMoveOrDelete,
  };
  return result;
}

export default preparePermissions;
