import { createAsyncThunk } from '@reduxjs/toolkit';

import { showErrorDialog } from '../../../components/dialog';

import {
  showValidateCardForm,
} from '../../helpers/billing';

const changeCard = createAsyncThunk(
  'billing/changeCard',
  async (
    payload,
    {
      getState, rejectWithValue, extra: {
        sdk, UiBlocker, Toast, localization, Logger,
      },
    },
  ) => {
    const { user } = getState();

    const stripePlan = {
      id: 'validatecard',
      name: !payload ? localization.BILLING.textCardChange : localization.BILLING.textCardAdd,
      allowRememberMe: false,
      amount: 0,
      panelLabel: localization.BILLING.textValidate,
    };

    try {
      UiBlocker.block(localization.BILLING.textCardDetailsLoading);
      Logger.log('UI', 'SettingsBillingCardAddDialog');
      const card = await showValidateCardForm({ user, plan: stripePlan });
      if (!card) {
        throw new Error('card is undefined');
      }

      UiBlocker.block(localization.BILLING.textCardSaving);

      const { data } = await sdk.billing.changeCard(card);
      const { customer, errors, msg } = data;
      if (errors) {
        throw new Error(msg);
      }

      Logger.log('UI', 'SettingsBillingCardAdded');
      Toast(localization.BILLING.textCardChanged, { type: 'success' });

      return customer;
    } catch (e) {
      const userDialogueMessage = localization.BILLING.textCardCantCheck;
      Logger.log('UI', 'SettingsBillingCantCheckCardDialog', {
        userDialogueMessage,
      });
      showErrorDialog(userDialogueMessage);

      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default changeCard;
