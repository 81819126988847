import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import l18n from '../../../shared/strings';

const validateAlias = async (websiteAlias, websiteId) => {
  try {
    const { data } = await sdk.websites.validateAlias(websiteAlias, websiteId);
    if (!data.free) return l18n.WEBSITES.textUrlInUse;
    return true;
  } catch (error) {
    Logger.error(new Error('Can not validate website alias'), { error }, [
      'ManageWebsiteFailed',
      error?.message || 'Can not validate alias',
    ]);
    return false;
  }
};

export default validateAlias;
