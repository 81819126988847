import * as userActions from '../../reducers/user';
import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';

const updateSupportConsent = () => async (dispatch) => {
  try {
    const { data } = await sdk.users.toggleSupportConsent();
    const { supportConsentExpiresAt } = data;

    dispatch(userActions.toggleSupportConsent(supportConsentExpiresAt));

    return supportConsentExpiresAt;
  } catch (error) {
    const errorMessage = utils.getDataFromResponceError(error, 'msg');
    return { error: errorMessage };
  }
};

export default updateSupportConsent;
