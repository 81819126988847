import sdk from '../../sdk';
import * as utils from '../../shared/utils';
import Logger from '../../services/Logger';
import l18n from '../../shared/strings';
import TYPES from '../action-types';
import Toast from '../../components/Toast';
import { showDialog, showErrorDialog } from '../../components/dialog';

/**
 * Get savedSearches
 */
const customId = 'getSavedSearchesFailed';
export function getSavedSearches() {
  return async (dispatch, getState) => {
    dispatch({ type: TYPES.SAVEDSEARCHES.FETCH.START });
    const userId = getState().user._id;

    try {
      const { data: savedSearches } = await sdk.savedSearches.getAll();
      dispatch({
        type: TYPES.SAVEDSEARCHES.FETCH.COMPLETE,
        payload: { savedSearches, userId },
      });
    } catch (error) {
      Logger.log('UI', 'ToastSavedSearchesNotLoaded');
      Toast(
        'Saved searches has not loaded. Try refreshing the page to see them.',
        {
          toastId: customId,
          autoClose: false,
          type: 'error',
        },
      );
      dispatch({
        type: TYPES.SAVEDSEARCHES.FETCH.FAILED,
        error,
      });
      Logger.error(new Error('Can not get saved searches'), { error }, [
        'GetSavedSearchesFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  };
}

/**
 * Add savedSearch
 * @param {string} name
 * @param {Object} data
 * @param {boolean} shared
 * @param {boolean} showToast
 */
export function add(name, data, shared, showToast = true) {
  return async (dispatch) => {
    try {
      dispatch({ type: TYPES.SAVEDSEARCHES.ADD.START });
      const { data: newItem } = await sdk.savedSearches.create(name, data, shared);
      if (newItem) {
        if (showToast) {
          Toast(l18n.TAGSTREE.alertSearchAdded(name), {
            type: 'success',
            btnOkValue: l18n.textBtnUndo,
            // eslint-disable-next-line no-use-before-define
            onOk: () => dispatch(removeWithoutDialog(newItem._id, name, false)),
          });
        }
        newItem.nodes = [];

        dispatch({
          type: TYPES.SAVEDSEARCHES.ADD.COMPLETE,
          payload: { newItem },
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.SAVEDSEARCHES.ADD.FAILED,
        error,
      });
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 403) {
        showErrorDialog(l18n.NO_PERMISSION_TO_ACCESS);
      } else {
        Logger.error(new Error('Can not add saved searches'), { error }, [
          'AddSavedSearchesFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }
    }
  };
}

/**
 * Delete savedSearch without confirm dialog
 * @param {string} id
 * @param {string} name
 * @param {boolean} showToast
 */
export function removeWithoutDialog(id, name, showToast = true) {
  return async (dispatch, getState) => {
    Logger.log('UI', 'ConfirmDeleteSavedSearch');
    dispatch({
      type: TYPES.SAVEDSEARCHES.REMOVE.START,
      payload: { id },
    });
    Logger.log('User', 'ConfirmDeleteSavedSearchYes', { savedSearcheId: id });
    try {
      const { data: result } = await sdk.savedSearches.delete(id);
      if (result.deleted) {
        if (showToast) {
          const nodes = getState().savedSearches.tree.savedSearches.nodes;
          const node = nodes.find(({ _id }) => _id === id);
          Toast(l18n.TAGSTREE.textHasBeenRemoved(name), {
            type: 'success',
            btnOkValue: l18n.textBtnUndo,
            onOk: () => dispatch(add(name, node.data, !!node.teamId, false)),
          });
        }
        dispatch({
          type: TYPES.SAVEDSEARCHES.REMOVE.COMPLETE,
          payload: { result, id },
        });
      }
      return;
    } catch (error) {
      dispatch({
        type: TYPES.SAVEDSEARCHES.REMOVE.FAILED,
        payload: { id },
        error,
      });
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 403) {
        showErrorDialog(l18n.NO_PERMISSION_TO_ACCESS);
      } else {
        Logger.error(new Error('Can not remove saved searches'), { error }, [
          'RemoveSavedSearchesFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }
    }
  };
}

/**
 * Delete savedSearch
 * @param {string} id
 * @param {string} name
 */
export function remove(id, name) {
  return async (dispatch, getState) => {
    Logger.log('UI', 'ConfirmDeleteSavedSearch');
    showDialog({
      title: l18n.TAGSTREE.titleRemoveSavedSearch,
      text: l18n.TAGSTREE.textYouAreAboutRemoveSavedSearch(name),
      textBtnOk: l18n.DIALOGS.btnDelete,
      textBtnCancel: l18n.DIALOGS.btnCancel,
      async onOk() {
        removeWithoutDialog(id, name)(dispatch, getState);
      },
      onCancel: () => Logger.log('User', 'ConfirmDeleteSavedSearchNo'),
    });
  };
}

/**
 * Favorite savedSearch
 * @param {string} id
 * @param {string} name
 * @param {boolean} value
 * @param {boolean} showToast
 */
export function favorite(id, name, value, showToast = true) {
  return async (dispatch, getState) => {
    const userId = getState().user._id;
    try {
      dispatch({
        type: TYPES.SAVEDSEARCHES.FAVORITE.START,
        payload: { id },
      });
      const { data: result } = await sdk.savedSearches.favorite(id, value);

      if (result.success) {
        if (showToast) {
          Toast(l18n.TOAST.SAVED_SEARCHES_FAVORITE(name, value), {
            type: 'success',
            btnOkValue: l18n.textBtnUndo,
            onOk: () => dispatch(favorite(id, name, !value, false)),
          });
        }
        dispatch({
          type: TYPES.SAVEDSEARCHES.FAVORITE.COMPLETE,
          payload: { id, value, userId },
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.SAVEDSEARCHES.FAVORITE.FAILED,
        payload: { id },
        error,
      });
      Logger.error(new Error('Can not add saved searches to favorites'), { error }, [
        'AddSavedSearchesToFavoritesFailed',
        (error && error.message) || 'NoMessage',
      ]);
    }
  };
}

/**
 * Apply search
 * @param {string} value
 */
export function applySearch(value) {
  return (dispatch, getState) => {
    const userId = getState().user._id;
    dispatch({
      type: TYPES.SAVEDSEARCHES.SEARCH,
      payload: { value, userId },
    });
  };
}

/**
 * Set active savedSearch
 * @param {string} id
 */
export function setActive(id) {
  return (dispatch, getState) => {
    if (id === null) {
      dispatch({
        type: TYPES.SAVEDSEARCHES.SET_ACTIVE,
        payload: { activeSavedSearch: null },
      });
      return;
    }

    const savedSearches = getState().savedSearches.all;
    const activeSavedSearch = savedSearches?.find((i) => i._id === id);
    dispatch({
      type: TYPES.SAVEDSEARCHES.SET_ACTIVE,
      payload: { activeSavedSearch },
    });
  };
}

/**
 * Update savedSearch
 * @param {string} id
 * @param {object} data
 */
export function updateSavedSearch(id, data) {
  return (dispatch) => {
    if (id === null) {
      return;
    }

    dispatch({
      type: TYPES.SAVEDSEARCHES.UPDATE_FIELD,
      payload: { id, ...data },
    });
  };
}
