import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, IconButton } from '@picsio/ui';
import { isMobile } from 'react-device-detect';
import {
  FlagEmpty, Flag, FlagRejected, Close,
} from '@picsio/icons';

import localization from '../shared/strings';
import Tooltip from '../components/Tooltip';

const Flags = (props) => {
  const {
    value = [],
    onChange = () => null,
    className = '',
    highlight = false,
    error = false,
    deselectFlags = null,
  } = props;
  const iconSize = isMobile ? 'xl' : 'lg';

  return (
    <div
      className={cn('defaultFlagList', { [className]: className, isError: error })}
      onAnimationEnd={() => props.highlightAnimationReset('flag')}
    >
      <ul>
        <Tooltip content={localization.DETAILS.textRejectedFiles} placement="top">
          <li
            className={cn({
              act: value.indexOf('rejected') !== -1,
              highlightBlink: highlight,
            })}
            onClick={() => onChange('rejected')}
          >
            <Icon size={iconSize}>
              <FlagRejected />
            </Icon>
          </li>
        </Tooltip>
        <Tooltip content={localization.DETAILS.textUnflaggedFiles} placement="top">
          <li
            className={cn({
              act: value.indexOf('unflagged') !== -1 || value.indexOf(null) !== -1,
              highlightBlink: highlight,
            })}
            onClick={() => onChange('unflagged')}
          >
            <Icon size={iconSize}>
              <FlagEmpty />
            </Icon>
          </li>
        </Tooltip>
        <Tooltip content={localization.DETAILS.textFlaggedFiles} placement="top">
          <li
            className={cn({
              act: value.includes('flagged') && value !== 'unflagged',
              highlightBlink: highlight,
            })}
            onClick={() => onChange('flagged')}
          >
            <Icon size={iconSize}>
              <Flag />
            </Icon>
          </li>
        </Tooltip>
        <If condition={deselectFlags}>
          <Tooltip content={localization.DETAILS.textDeselectFlags} placement="top">
            <li className="deselectFlags">
              <IconButton size={iconSize} onClick={deselectFlags}>
                <Close />
              </IconButton>
            </li>
          </Tooltip>
        </If>
      </ul>
    </div>
  );
};

Flags.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  highlight: PropTypes.bool,
  error: PropTypes.bool,
  deselectFlags: PropTypes.func,
};

export default Flags;
