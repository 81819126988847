import React, { useCallback, useEffect, useState } from 'react';
import getUrls from 'get-urls';
import {
  Button, Icon, Input, InputLabel,
} from '@picsio/ui';
import { CloseIcon } from '@picsio/icons';
import { func, string } from 'prop-types';
import Logger from '../../services/Logger';
import UrlItem from './UrlItem';
import l10n from '../../shared/strings';

import './styles.scss';

const {
  TITLE, TOTAL, LABEL, PLACEHOLDER, OK, CANCEL,
} = l10n.DIALOGS.ADD_ASSETS_FROM_URLS;

const TEXTAREA_ID = 'add-from-url-text';

export default function AddFromUrlDialog({ onClose = () => {}, uploadCollectionId = '' }) {
  const [text, setText] = useState('');
  const [urls, setUrls] = useState([]);

  const handleClose = useCallback(
    () => {
      Logger.log('User', 'AddFromUrlCancel');
      onClose();
    },
    [onClose],
  );

  /** did mount */
  useEffect(() => {
    const listener = ({ keyCode }) => {
      /** on press ESC */
      if (keyCode === 27) handleClose();
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target }) => {
    const { value } = target;
    /** @type {Set<string>} */
    const parsedUrls = getUrls(value, { requireSchemeOrWww: true });
    setText(value);
    setUrls(Array.from(parsedUrls));
  };

  const handleSubmit = useCallback(
    () => {
      if (!urls.length) return;

      Logger.log('User', 'AddFromUrlSubmit', { urls: urls.length });
      /** add Urls */
      window.dispatchEvent(new CustomEvent('importPanel:add', { detail: { files: urls, uploadCollectionId } }));

      onClose();
    },
    [urls, uploadCollectionId, onClose],
  );

  const handleRemoveUrl = useCallback(
    (url) => setUrls(urls.filter((item) => item !== url)),
    [urls, setUrls],
  );

  return (
    <div className="simpleDialog addUrlsDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox">
        <div className="simpleDialogHeader">
          <h2 className="simpleDialogTitle">{TITLE}</h2>
          <button
            className="simpleDialogBtnCross"
            onClick={handleClose}
            type="button"
          >
            <Icon size="sm"><CloseIcon /></Icon>
          </button>
        </div>
        <div className="simpleDialogContent">
          <div className="simpleDialogContentInner">
            <If condition={urls.length > 0}>
              <p>{TOTAL}: {urls.length}</p>
              <ul className="PicsioUrlsList">
                {urls.map((url) => <UrlItem key={url} onRemove={handleRemoveUrl} url={url} />)}
              </ul>
            </If>
            <InputLabel className="PicsioUrlsListLabel" htmlFor={TEXTAREA_ID}>{LABEL}</InputLabel>
            <Input
              id={TEXTAREA_ID}
              component="textarea"
              inputProps={{ rows: 10 }}
              placeholder={PLACEHOLDER}
              multiline
              onChange={handleChange}
              value={text}
              autoFocus
            />
          </div>
        </div>
        <div className="simpleDialogFooter">
          <Button
            color="secondary"
            size="md"
            variant="contained"
            onClick={handleClose}
          >
            {CANCEL}
          </Button>
          <Button
            color="primary"
            size="md"
            variant="contained"
            disabled={urls.length < 1}
            onClick={handleSubmit}
          >
            {OK}
          </Button>
        </div>
      </div>
    </div>

  );
}

AddFromUrlDialog.propTypes = {
  onClose: func,
  uploadCollectionId: string,
};
