import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../components/Icon';

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.onChoose = this.onChoose.bind(this);
    this.onBodyClick = this.onBodyClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onBodyClick() {
    this.setState({
      isOpen: false,
    });

    document.removeEventListener('click', this.onBodyClick);
  }

  onChoose(e, value) {
    e.stopPropagation();
    const { onChange } = this.props;
    this.setState({
      isOpen: false,
    });

    document.removeEventListener('click', this.onBodyClick);
    onChange(e, value);
  }

  handleChange(event) {
    const { onChange } = this.props;
    onChange(event, event.target.value);
  }

  toggleVisibility(e) {
    e.stopPropagation();
    const { state } = this;

    this.setState((prevState) => ({ isOpen: !prevState.isOpen }), () => {
      if (state.isOpen) {
        document.addEventListener('click', this.onBodyClick);
      } else {
        document.removeEventListener('click', this.onBodyClick);
      }
    });
  }

  render() {
    let { value } = this.props;
    const {
      options, className, icon, disabled, changed, label, name,
    } = this.props;

    // if no options => return
    if (options.length === 0) return null;

    value = value && options.some((n) => n.value === value) ? value : options[0].value;
    return (
      <div className={cn('customSelect', { [className]: className, disabled, customSelectWithIcon: icon })}>
        {label && <div className="customSelect__label">{label}</div>}
        {icon && (
          <span className="customSelectIcon">
            <Icon name={cn({ [icon]: icon })} />
          </span>
        )}
        <select className={cn('customSelect__select', { changed })} value={value} onChange={this.handleChange} name={name}>
          {options.map((option) => (
            <option value={option.value} key={option.value} disabled={option.disabled}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

Select.defaultProps = {
  label: null,
  value: '',
  disabled: false,
  className: '',
  icon: null,
  changed: false,
  name: '',
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  changed: PropTypes.bool,
  name: PropTypes.string,
};

export default Select;
