import React, {
  memo, forwardRef, useMemo, useCallback,
} from 'react';
import cn from 'classnames';
import { FolderIcon, Close as CloseIcon } from '@picsio/ui/dist/icons';
import {
  bool, string, func, oneOf, number as typeNumber,
} from 'prop-types';
import { Avatar, IconButton } from '@picsio/ui';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

import './style.scss';

const CURSOR_POINTER = { cursor: 'pointer' };
const classnamesList = {
  collection: 'tagCollection',
  lightboard: 'tagLightboard',
  inbox: 'tagCollection',
  keyword: 'tagKeyword',
  user: 'tagUser',
  face: 'tagFace',
};

const Tag = forwardRef((props, ref) => {
  const {
    text,
    avatar,
    className,
    onClick,
    onClose,
    tooltipText,
    tooltipTextClose,
    type,
    isCollection,
    isRole,
    showCloseOnHover,
    singleChose,
    isSelected,
    number,
    showNumber,
    onClickNumber,
    tooltipTextNumber,
  } = props;

  const style = onClick ? CURSOR_POINTER : null;
  const typeClass = classnamesList[type];

  const handleClick = (event) => {
    if (!isRole) {
      event.persist();
      if (onClick) {
        onClick(event);
      }
    }
  };

  const cls = useMemo(() => cn({
    tag: !isCollection,
    roleTag: isCollection,
    noCursor: isRole,
    noBgColor: singleChose,
    isSelected: Boolean(isSelected),
    [className]: Boolean(className),
    [typeClass]: Boolean(typeClass),
  }), [isCollection, isRole, singleChose, className, typeClass, isSelected]);

  const handleClickNumber = useCallback((e) => {
    if (typeof onClickNumber === 'function') onClickNumber(e);
  }, [onClickNumber]);

  if (!text) return null;

  return (
    <div
      ref={ref}
      className={cls}
      style={!isRole ? style : null}
      onClick={handleClick}
      role="presentation"
    >
      <If condition={isCollection}>
        <FolderIcon size="xxl" className="folderIcon" />
      </If>
      <If condition={showNumber && typeof number === 'number'}>
        <Tooltip placement="top" content={tooltipTextNumber}>
          <span
            role="button"
            tabIndex={0}
            className={cn('tagNumber', { interactive: typeof onClickNumber === 'function' })}
            onClick={handleClickNumber}
            onKeyPress={handleClickNumber}
          >
            {number}
          </span>
        </Tooltip>
      </If>
      <If condition={avatar || type === 'user'}>
        <Avatar src={avatar} alt={text} size={16} className="tagAvatar" />
      </If>
      <If condition={text}>
        <Tooltip placement="top" content={tooltipText}>
          <span className="tagTitleWrapper">
            <span className="tagTitle">{text}</span>
          </span>
        </Tooltip>
      </If>
      <If condition={typeof onClose === 'function'}>
        <Choose>
          <When condition={showCloseOnHover}>
            <Tooltip placement="top" content={tooltipTextClose}>
              <span className="tagRemove" onClick={onClose} role="presentation">
                <Icon name="close" />
              </span>
            </Tooltip>
          </When>
          <Otherwise>
            <Tooltip placement="top" content={tooltipTextClose}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Otherwise>
        </Choose>
      </If>
    </div>
  );
});

Tag.defaultProps = {
  avatar: null,
  className: null,
  onClick: null,
  onClose: null,
  tooltipText: null,
  tooltipTextClose: null,
  type: 'keyword',
  isCollection: false,
  isRole: false,
  singleChose: false,
  showCloseOnHover: false,
  isSelected: false,
  number: null,
  showNumber: true,
  onClickNumber: null,
  tooltipTextNumber: null,
};

Tag.propTypes = {
  text: string.isRequired,
  avatar: string,
  className: string,
  onClick: func,
  onClose: func,
  tooltipText: string,
  tooltipTextClose: string,
  type: oneOf(['user', 'collection', 'lightboard', 'inbox', 'keyword', 'face']),
  isCollection: bool,
  isRole: bool,
  showCloseOnHover: bool,
  singleChose: bool,
  isSelected: bool,
  number: typeNumber,
  showNumber: bool,
  onClickNumber: func,
  tooltipTextNumber: string,
};

export default memo(Tag);
