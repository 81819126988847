import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '../store';
import AssetsLimitExceededDialog from '../components/AssetsLimitExceededDialog';

/** Show assets limit exceeded dialog */
export default function showAssetsLimitExceededDialog(props) {
  const $wrapper = document.createElement('div');
  $wrapper.classList.add('wrapperDialog');
  const $container = document.querySelector('#dialogRoot') || document.body;
  $container.appendChild($wrapper);
  const root = createRoot($wrapper);

  function destroy() {
    root.unmount();
    $container.removeChild($wrapper);
  }
  root.render(
    <AssetsLimitExceededDialog destroy={destroy} {...props} user={store.getState().user} />,
  );
}
