import TYPES from '../../action-types';

/**
 * Deleted files immediately
 * @param {String[]} ids
 */
const deletedAssets = (ids) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.DELETE_ASSETS.COMPLETE,
    payload: { ids },
  });
};

export default deletedAssets;
