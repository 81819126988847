import React from 'react';
import { Transcript, Question } from '@picsio/icons';
import {
  string,
  oneOf,
  bool,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANTS from '@picsio/db/src/constants';
import { DropdownButton, Button, Icon } from '@picsio/ui';
import { createLanguageOptions } from '../../../../helpers/transcript';
import Spinner from '../Spinner';
import { sendAssetsToTranscribe } from '../../../../store/actions/assets';
import l18n from '../../../../shared/strings';
import ua from '../../../../ua';
import Logger from '../../../../services/Logger';
import { navigate } from '../../../../helpers/history';
import helpersLink from '../../../Billing/Overview/Features/constants/helpersLink';
import { isHaveTeammatePermission } from '../../../../store/helpers/user';

const languageOptions = createLanguageOptions(
  CONSTANTS.assets.transcribe.availableLanguages,
  l18n.TRANSCRIPT.dropdownOptionText,
);

function TranscriptPlaceholder({
  assetId, assetName, transcribing = 'skipped', transcriptPermission = false, activeRevisionID = null, isLoaded = false,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user || {});
  const defaultLanguage = useSelector((state) => state.user.team?.policies?.transcribingLanguage || 'en');
  const isAllowedTranscribe = useSelector((state) => state.user.subscriptionFeatures.transcribe);
  const isManageBillingAllow = isHaveTeammatePermission('manageBilling', user);

  const handleTranscribe = (language) => {
    Logger.log('User', 'TranscriptTPanelTranscribeClicked', { assetId, assetName, language });
    dispatch(sendAssetsToTranscribe({ assetIds: [assetId], language }));
  };

  const handleClickUpgradePlan = () => {
    Logger.log('User', 'TranscriptUpgradePlanClicked');
    navigate('/billing?tab=overview');
  };
  return (
    <div className="TranscriptPlaceholder">

      <div className={`Placeholder ${!isAllowedTranscribe ? 'notUpgrade' : ''}`}>

        <h3 className="Placeholder__title">{l18n.TRANSCRIPT.placeholderTitle}</h3>
        <Choose>
          <When condition={!isAllowedTranscribe}>
            <Transcript width="80" height="80" className="Placeholder__icon" />
          </When>
          <When condition={
            transcribing === 'waiting'
            || transcribing === 'running'
            || activeRevisionID === null
            || !isLoaded

          }
          >
            <Spinner />
          </When>
          <Otherwise>
            <Transcript width="80" height="80" className="Placeholder__icon" />
          </Otherwise>
        </Choose>
        <p className="Placeholder__description">{l18n.TRANSCRIPT.placeholderDecsription}</p>
        <If condition={
          !ua.browser.isNotDesktop()
          && transcribing !== 'waiting'
          && transcribing !== 'running'
          && transcriptPermission
          && activeRevisionID !== null
          && isLoaded
          && isAllowedTranscribe
        }
        >
          <DropdownButton
            buttonText={l18n.TRANSCRIPT.placeholderButton}
            dataTestId="btnPreviewChangeTranscriptLang"
            onClick={handleTranscribe}
            closeOnSelect
            options={languageOptions}
            defaultValue={defaultLanguage}
            arrow={false}
            outsideClickListener
            displayDropdownValue
          />
        </If>
      </div>
      <If condition={!isAllowedTranscribe}>
        <div className="Placeholder__upgradePlanContainer">
          <a href={helpersLink.transcribe} target="_blank" rel="noreferrer" className="Placeholder__helperLink">
            <Icon color="primary" size="sm"><Question /></Icon>
            <span className="Placeholder__helperText">{l18n.UPGRADE_PLAN_BLOCK.transcript.helperText}</span>
          </a>
          <If condition={!ua.isMobileApp()}>
            <Button
              id="upgradeTranscribe"
              onClick={handleClickUpgradePlan}
              variant="contained"
              size="md"
              color="primary"
              disabled={!isManageBillingAllow}
            >
              {l18n.BILLING.textUpgradePlan}
            </Button>
          </If>
        </div>
      </If>

    </div>
  );
}

TranscriptPlaceholder.propTypes = {
  assetId: string.isRequired,
  assetName: string.isRequired,
  transcribing: oneOf(['skipped', 'waiting', 'running', 'complete', 'failed', 'rejected']),
  transcriptPermission: bool,
  activeRevisionID: string,
  isLoaded: bool,
};

export default TranscriptPlaceholder;
