import React, { useEffect } from 'react';
import {
  Route, Routes, useLocation,
} from 'react-router-dom';
import { usePrevious } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import picsioConfig from '../../../../config';
import {
  isRouteSearch, navigateToRoot, replace, constructQueryString, isRoutePreview,
} from '../helpers/history';

import AppProofing from '../components/AppProofing';
import ScreenPreview from '../components/previewView';
import { setActiveCollection } from '../store/actions/collections';
import { activeCollectionSelector } from '../store/selectors/collections';
import getAssets from '../store/actions/assets/getAssets';
import deselectAll from '../store/actions/assets/deselectAll';
import incorrectCollectionIdsLength from './helpers/incorrectCollectionIdsLength';
import collectionIdsAreEqual from './helpers/collectionIdsAreEqual';

function useRouteListener() {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeCollections = useSelector((state) => activeCollectionSelector(state));
  const router = useSelector((state) => state.router);
  const { collections } = useSelector((state) => state.collections);
  const { isFetching } = collections?.my || false;
  const prevSearchQuery = usePrevious(router.location);

  // Get assets
  useEffect(
    () => {
      if (!isRouteSearch()) return;
      const currentSearch = router.entries[0];
      const prevSearch = router.entries.find((item, index) => {
        const isNotCurrent = !!index;
        return isNotCurrent && isRouteSearch(item.pathname);
      });
      const isBackToPrevSearch = !!prevSearch && prevSearch.search === currentSearch.search;
      if (!isBackToPrevSearch) {
        dispatch(getAssets(true));
      }
    },
    [location, dispatch, router],
  );

  useEffect(() => {
    const {
      collectionIds, tagId, tagIds, collectionId,
    } = router.location.query;

    if (!collectionIds && !tagId && !tagIds && !collectionId) return;

    const { rootCollectionId } = window;
    let queryItems = {
      collectionIds: collectionIds || rootCollectionId,
    };
    // backwards compatibility for tagId & collectionIds
    if (tagId && !collectionIds) {
      queryItems = { ...router.location.query, collectionIds: tagId };
    }

    // backwards compatibility for collectionId & collectionIds
    if (collectionId && !collectionIds) {
      queryItems = { ...router.location.query, collectionIds: collectionId };
    }

    // backwards compatibility for tagIds & collectionIds
    if (tagIds && !collectionIds) {
      queryItems = { ...router.location.query, collectionIds: tagIds };
    }

    if (collectionIds) {
      queryItems = { ...router.location.query, collectionIds };
    }

    const queryString = constructQueryString(queryItems, {});
    replace({
      pathname: '/search',
      search: queryString,
    });
  }, []);

  useEffect(() => {
    function handleSearchRouteChange(shouldNotDispatchSwitchOffEvent) {
      const { collectionIds } = router.location.query;
      if (collectionIds) {
        if (incorrectCollectionIdsLength(collectionIds)) {
          navigateToRoot();
          return;
        }

        // Set active collection. Used by Search placeholder
        if (picsioConfig.access.searchShow || picsioConfig.access.tagsTreeShow) {
          if (collectionIdsAreEqual(activeCollections, collectionIds)) return;
          if (!isFetching) {
            dispatch(setActiveCollection(collectionIds));
          }
        }
      }

      dispatch(deselectAll({ shouldNotDispatchSwitchOffEvent }));
    }
    if (router.location !== prevSearchQuery || !collectionIdsAreEqual(activeCollections, router.location.query.collectionIds)) {
      const shouldNotDispatchSwitchOffEvent = isRoutePreview();
      handleSearchRouteChange(shouldNotDispatchSwitchOffEvent);
    }
  }, [isFetching, location, router, dispatch, prevSearchQuery, activeCollections]);
}

export default () => {
  useRouteListener();

  return (
    <AppProofing>
      <Routes>
        <Route path="/:pathname/preview/:id" Component={ScreenPreview} />
        <Route path="*" element={null} />
      </Routes>
    </AppProofing>
  );
};
