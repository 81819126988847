import React, { useState, useEffect, useRef } from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { IconButton, Icon } from '@picsio/ui';
import { ListFolder } from '@picsio/icons';
import { outy } from '../../shared/utils';
import Logger from '../../services/Logger';
import Tooltip from '../Tooltip';
import './style.scss';

let outsideClickRef = null;

export function DropdownTreeOpener({
  hideOnClickOutside, children, tooltip, toggleDropdownTree, isPermission,
}) {
  const [showDrop, setShowDrop] = useState(false);
  const dropRef = useRef();
  useEffect(() => {
    if (!hideOnClickOutside) return;
    if (showDrop) {
      outsideClickRef = outy(dropRef.current, ['click'], handleOutsideClick);
    } else if (outsideClickRef) outsideClickRef.remove();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrop]);

  const handleButtonClick = () => {
    Logger.log('User', 'DropdownTreeOpenerClick');
    setShowDrop(!showDrop);
    toggleDropdownTree(!showDrop);
    if (outsideClickRef) outsideClickRef.remove();
  };

  const handleOutsideClick = () => {
    setShowDrop(!showDrop);
    toggleDropdownTree(!showDrop);
    if (outsideClickRef) outsideClickRef.remove();
  };

  return (
    <span className="dropdownTreeHolder" ref={dropRef}>
      <If condition={isPermission}>
        <Tooltip content={tooltip} placement="top">
          <button
            type="button"
            onClick={handleButtonClick}
            className="addButtonCollection"
          >
            <span>+</span> Add collection
          </button>
        </Tooltip>
      </If>
      <If condition={!isPermission}>
        <Tooltip content={tooltip} placement="top">
          <IconButton
            className="dropdownTreeOpener"
            buttonSize="default"
            color="default"
            component="button"
            onClick={handleButtonClick}
            size="md"
          >
            <Icon><ListFolder /></Icon>
          </IconButton>
        </Tooltip>
      </If>
      <CSSTransition in={showDrop} timeout={300} classNames="fade">
        <>{showDrop && children}</>
      </CSSTransition>
    </span>
  );
}

DropdownTreeOpener.defaultProps = {
  toggleDropdownTree: () => {},
  isPermission: false,
  tooltip: '',
  hideOnClickOutside: false,
};

DropdownTreeOpener.propTypes = {
  children: object,
  isPermission: bool,
  toggleDropdownTree: func,
  tooltip: string,
  hideOnClickOutside: bool,
};

export default DropdownTreeOpener;
