/* eslint-disable no-use-before-define */
import showAssetsLimitExceededDialogWithoutStore from '../../../helpers/showAssetsLimitExceededDialogWithoutStore';
import GDriveStorage from '../../../storage/GDriveStorage';
import S3Storage from '../../../storage/S3Storage';
import getGoogleFolderIDs from './getGoogleFolerIds';
import l10n from '../../../shared/strings';

export default async function uploadFile({
  item,
  signal,
  uploadToS3,
  Logger,
  utils,
  showDialog,
  user,
  updateLightboardStorageId,
  setProgress,
}) {
  let request = null;
  let isCancelled = false;
  const {
    file, collection, lightboard, action, duplicatedModel,
  } = item;

  const handleAbort = () => {
    if (request) request.abort();
    isCancelled = true;
  };
  const handleProgress = ({ percentage, xhr }) => {
    request = xhr;
    const bytesUploaded = (file.size * percentage) / 100;
    setProgress(percentage, bytesUploaded);
  };

  signal.addEventListener('abort', handleAbort);

  const isUploadRevision = action === 'addRevision';
  let response;

  if (uploadToS3) {
    /** S3 */
    try {
      response = await S3Storage.uploadFile({
        file,
        collectionId: collection?._id,
        lightboardId: lightboard?._id,
        onUploadProgress: handleProgress,
        id: isUploadRevision ? duplicatedModel._id : null,
      });
    } catch (error) {
      if (isCancelled) return ({ isCancelled });

      let message = l10n.IMPORT.textCantSaveImageToGD;
      let code = 403;

      const { response: res } = error;
      if (res?.data) {
        code = res.status;
        message = res.data.msg || message;
      }
      if (code === 402) {
        showDialog({
          title: l10n.DIALOGS.NO_SIZE_LEFT.TITLE,
          text: l10n.DIALOGS.NO_SIZE_LEFT.TEXT,
        });
      }
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      if (errorSubcode === 'AssetsLimitExceededError' || message.startsWith('Assets limit')) {
        showAssetsLimitExceededDialogWithoutStore(user);
      }
      const connection = utils.getNavigatorConnectionInfo();
      Logger.error(new Error(message), { error }, [
        'ImportBadResponseFromS3',
        {
          errorMessage: message,
          userDialogueMessage: code === 402 ? l10n.DIALOGS.NO_SIZE_LEFT.TEXT : null,
          connection,
        },
      ]);
      /** Return error */
      response = {
        isError: true,
        code,
        reason: 'badResponse',
        message,
      };
    } finally {
      request = null;
    }
  } else {
    /** Google Drive */
    const config = {};
    if (action === 'replaceFile') {
      config.assetIdToReplace = duplicatedModel._id;
    } else {
      config.folderIds = await getGoogleFolderIDs(item, user, updateLightboardStorageId);
    }
    if (isUploadRevision) config.fileId = duplicatedModel._id;

    let numberOfAttempts = 0;
    response = await new Promise((resolve) => {
      function handleError(res) {
        /** if normal google response */
        if (res.error && res.error.code) {
          const { code, errors, message } = res.error;
          const { reason } = errors[0];
          const errorData = {
            code,
            reason,
            message,
          };

          if (code === 401 && numberOfAttempts < 2) {
            upload();
          } else if (
            (code === 429
              || code === 500
              || (code === 403 && (reason === 'userRateLimitExceeded' || reason === 'rateLimitExceeded')))
            && numberOfAttempts < 11
          ) {
            /** Exponential backoff */
            setTimeout(upload, 500 * numberOfAttempts);
          } else {
            resolve({
              ...errorData,
              isError: true,
            });
          }
        } else if (!window.navigator.onLine) {
          /** if user offline */
          resolve({
            code: 403,
            reason: 'slowConnection',
            message: 'No internet',
            isError: true,
          });
        } else if (res.reason === 'slowConnection' || res.reason === 'xhrAborted') {
          if (numberOfAttempts < 3 && !isCancelled) {
            /** Exponential backoff */
            setTimeout(upload, 500 * numberOfAttempts);
          } else {
            /** if slow connection or xhr aborted */
            resolve({
              ...res,
              isError: true,
            });
          }
          if (isCancelled) {
            resolve({
              ...res,
              isCancelled,
            });
          }
        } else if (res?.cause?.response?.data?.subcode === 'MissingCanAddChildrenGoogleDriveCapabilityError') {
          const { subcode, msg } = res.cause.response.data;
          resolve({
            code: 409,
            reason: subcode,
            message: msg,
            isError: true,
          });
        } else {
          const { message } = res;
          if (message.startsWith('Assets limit')) {
            showAssetsLimitExceededDialogWithoutStore(user);
          }
          const errorMessageForUser = message || l10n.IMPORT.textCantSaveImageToGD;
          const connection = utils.getNavigatorConnectionInfo();
          Logger.error(new Error(message || 'Import: bad response from GD'), { error: res }, [
            'ImportBadResponseFromGD', { userDialogueMessage: errorMessageForUser, connection },
          ]);
          resolve({
            code: 403,
            reason: 'badResponse',
            message: errorMessageForUser,
            isError: true,
          });
        }
      }

      function upload() {
        numberOfAttempts += 1;
        setProgress(0, 0);
        GDriveStorage.uploadFile(file, config, user)
          .progress(handleProgress)
          .then(resolve)
          .catch(handleError);
      }
      upload();
    });

    request = null;
  }

  return response;
}
