import React, { useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch } from 'react-redux';
import {
  arrayOf, func, bool, string, shape, any,
} from 'prop-types';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Icon, IconButton } from '@picsio/ui';
import {
  Face, Eye, EyeClosed, DrawFace, Edit,
} from '@picsio/icons';
import * as notificationsActions from '../../../store/actions/notifications';
import * as utils from '../../../shared/utils';
import UpgradePlan from '../../UpgradePlan';
import localization from '../../../shared/strings';
import Logger from '../../../services/Logger';
import FaceDropdown from './FaceDropdown';
import FacesChips from './FacesChips';
import * as UtilsCollections from '../../../store/utils/collections';
import { setSearchRoute, navigate } from '../../../helpers/history';
import { showDialog } from '../../dialog';
import ua from '../../../ua';
import Tooltip from '../../Tooltip';
import showEditFaceDialog from '../../../helpers/showEditFaceDialog';
import AiCreditsBadge from '../../AiCreditsBadge';
import AiButtons from './AiButtons';
import Placeholder from '../../DetailsBlock/Placeholder';
import ProcessingItem from './ProcessingItem';

function Faces({
  detailsPanelVisibility = {},
  toggleVisibility = () => {},
  blockName = '',
  blockTitle = '',
  disabled = false,
  sendFaces = () => {},
  applyFace = null,
  assets,
  facesInAsset = [],
  inProgress = false,
  selectedFace = {},
  selectedAssetsIds,
  faceDropdownOnClick = null,
  createFace = null,
  addBoungingBox = null,
  tmpBoundingBoxes = [],
  addCustomFace = null,
  removeTmpBoundginBox = null,
  permissions = {},
  faceRecognitionIsAvailable = true,
  panelName = '',
  onClickOnFacesEye = null,
  facesAreShown = false,
  imageRevisionID = '',
  changeTree = null,
  openedTree = '',
  assetActions = null,
}) {
  const dispatch = useDispatch();
  const isVisible = () => detailsPanelVisibility[blockName];

  const sendAssetsToFacesRecognition = () => {
    if (selectedAssetsIds.length) {
      sendFaces(selectedAssetsIds);
      setTimeout(() => {
        dispatch(notificationsActions.fetchJobsStatus());
      }, 5000);
    }
  };
  const onKeywordClickHandler = (faceId) => {
    if (changeTree && openedTree !== 'face') {
      changeTree('face');
    }
    setSearchRoute({ collectionIds: UtilsCollections.getRootId(), faceIds: faceId });
  };

  const renameItem = useCallback(
    (faceId) => {
      showEditFaceDialog({ faceId });
    },
    [],
  );

  const onClickAddCustomBoundingBox = (e) => {
    const { clientX, clientY } = e;
    e.stopPropagation();
    e.preventDefault();

    const $customBoundingBox = document.createElement('div');
    $customBoundingBox.classList.add('customBoundingBox');
    $customBoundingBox.style.left = `${clientX}px`;
    $customBoundingBox.style.top = `${clientY}px`;

    const root = createRoot($customBoundingBox);
    root.render(
      <Icon id="button-face">
        <Face />
      </Icon>,
    );

    document.body.appendChild($customBoundingBox);

    const onMove = ({ clientX: x, clientY: y }) => {
      $customBoundingBox.style.left = `${x}px`;
      $customBoundingBox.style.top = `${y}px`;
    };

    const onKeyDown = (event) => {
      if (event.keyCode === 27) {
        /** Handle press Esc */
        onClick();
      }
    };

    const onClick = () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('keydown', onKeyDown);
      document.body.removeChild($customBoundingBox);
    };

    document.addEventListener('click', onClick);
    document.addEventListener('mousemove', onMove);
    document.addEventListener('keydown', onKeyDown);
    addBoungingBox();
    Logger.log('Face', 'FaceRecogBBoxAddManual');
  };

  const removeBoundingBox = (currentAssetId, faceId, boundingBox) => {
    showDialog({
      title: localization.DIALOGS.DELETE_FACE_REGION.TITLE,
      text: localization.DIALOGS.DELETE_FACE_REGION.TEXT,
      textBtnOk: localization.DIALOGS.DELETE_FACE_REGION.BTN_OK,
      textBtnCancel: localization.DIALOGS.DELETE_FACE_REGION.BTN_CANCEL,
      onOk: () => {
        Logger.log('Face', 'FaceRecogBBoxDelete');
        assetActions.removeBoundingBox({
          assetId: currentAssetId, faceId, boundingBox, revision: imageRevisionID,
        });
      },
    });
  };

  const filteredFacesByRev = useCallback(() => {
    if (imageRevisionID?.length === 0) {
      // sometimes asset may not have revisions field,
      // so we will took the last face.revisionId in faces as lastRevisionId
      const revisions = assets[0]?.revisions;
      const lastRev = revisions?.length ? revisions[revisions.length - 1] : facesInAsset[facesInAsset?.length - 1];
      return facesInAsset?.filter((face) => face?.revisionId === lastRev?.revisionId);
    }
    return facesInAsset?.filter((face) => face?.revisionId === imageRevisionID);
  }, [assets, facesInAsset, imageRevisionID]);

  const handleClickUpgradePlan = () => {
    Logger.log('User', 'FacesUpgradePlanClicked');
    navigate('/billing?tab=overview');
  };

  return (
    <>
      <div
        data-qa="details-component-faces"
        className={cn('detailsPanel__item', { act: isVisible(), disabled })}
      >
        <div className="detailsPanel__title">
          <span
            className="detailsPanel__title_text"
            role="button"
            tabIndex={0}
            onKeyPress={() => toggleVisibility(blockName)}
            onClick={() => toggleVisibility(blockName)}
          >
            {blockTitle}
            <If condition={isVisible()}>
              <AiCreditsBadge />
            </If>
            <If condition={!faceRecognitionIsAvailable}>
              <UpgradePlan onClick={handleClickUpgradePlan} tooltip={localization.UPGRADE_PLAN.tooltipPlanLimitations} />
            </If>
          </span>
          <div className="detailsPanel__title_actions">
            <If condition={panelName === 'previewView.right'}>
              <div data-testid="faceShow">
                <IconButton size="md" onClick={onClickOnFacesEye} className={cn({ notUpgrade: !faceRecognitionIsAvailable })}>
                  <If condition={facesAreShown}>
                    <Eye />
                  </If>
                  <If condition={!facesAreShown}>
                    <EyeClosed />
                  </If>
                </IconButton>
              </div>
            </If>
            <If condition={permissions.manageAssetPersons && panelName === 'previewView.right' && !ua.browser.isNotDesktop()}>
              <span className="drawFace">
                <Tooltip content={localization.DETAILS.tooltipCreateBoundingBox} placement="top">
                  <IconButton
                    className={cn({ notUpgrade: !faceRecognitionIsAvailable })}
                    onClick={onClickAddCustomBoundingBox}
                    size="md"
                    componentProps={{
                      'data-testid': 'faceCustomBox',
                    }}
                    disabled={disabled}
                  >
                    <DrawFace />
                  </IconButton>
                </Tooltip>
              </span>
            </If>
          </div>
        </div>
        <CSSTransition in={isVisible()} timeout={300} classNames={cn('fade', { notUpgrade: !faceRecognitionIsAvailable })}>
          <>
            <If condition={isVisible()}>
              <If condition={assets.length === 1 && (!permissions.manageAssetPersons || panelName === 'right')}>
                <Choose>
                  <When condition={assets[0]?.faces?.length > 0}>
                    <div className="PicsioCollapse__content--inner">
                      <FacesChips faces={filteredFacesByRev()} handleOnClick={onKeywordClickHandler} />
                    </div>
                  </When>
                  <Otherwise>
                    <Placeholder>
                      <Choose>
                        <When condition={inProgress}>
                          <ProcessingItem item={{
                            status: 'running',
                            name: 'faceRecognizing',
                            icon: <Face />,
                          }}
                          />
                        </When>
                        <Otherwise>
                          {permissions.autogeneratePersons
                            ? localization.DETAILS.placeholderFaces
                            : localization.DETAILS.placeholderFacesWithoutPermission}
                        </Otherwise>
                      </Choose>
                    </Placeholder>
                  </Otherwise>
                </Choose>
              </If>
            </If>

            <If condition={
              assets[0]?.faces?.length > 0
              && permissions.manageAssetPersons
              && panelName === 'previewView.right'
              && isVisible()
            }
            >
              {
                assets[0]?.faces?.map((face) => (
                  <If condition={face.revisionId === imageRevisionID}>
                    <div
                      key={`${face.boundingBox.width}${face.boundingBox.height}${face.boundingBox.left}${face.boundingBox.top}`}
                      id={`${face.boundingBox.width}${face.boundingBox.height}${face.boundingBox.left}${face.boundingBox.top}`}
                      className="editionFace"
                    >
                      <FaceDropdown
                        isFocused={utils.compareBoundingBoxes(face.boundingBox, selectedFace.boundingBox)}
                        faceOnAsset={face}
                        faceDropdownOnClick={faceDropdownOnClick}
                        applyFace={applyFace}
                        assetId={assets[0]._id}
                        createFace={createFace}
                        addCustomFace={addCustomFace}
                        imageRevisionID={imageRevisionID}
                        managePersons={permissions.managePersons}
                        removeBoundingBox={removeBoundingBox}
                      />
                      <IconButton size="md" onClick={() => renameItem(face._id)}>
                        <Edit />
                      </IconButton>
                    </div>
                  </If>
                ))
              }
            </If>
            <If condition={
              !assets[0]?.faces?.length
              && !tmpBoundingBoxes.length
              && permissions.manageAssetPersons
              && panelName === 'previewView.right'
              && isVisible()
            }
            >
              <Placeholder>
                <Choose>
                  <When condition={inProgress}>
                    <ProcessingItem item={{
                      status: 'running',
                      name: 'faceRecognizing',
                      icon: <Face />,
                    }}
                    />
                  </When>
                  <Otherwise>
                    {permissions.autogeneratePersons
                      ? localization.DETAILS.placeholderFaces
                      : localization.DETAILS.placeholderFacesWithoutPermission}
                  </Otherwise>
                </Choose>
              </Placeholder>
            </If>
            <If condition={
              tmpBoundingBoxes.length > 0
              && permissions.manageAssetPersons
              && panelName === 'previewView.right'
              && isVisible()
            }
            >
              {
                tmpBoundingBoxes.map((tmpBoundingBox, index) => (
                  <div key={tmpBoundingBox?.tempId} id={tmpBoundingBox.tempId}>
                    <FaceDropdown
                      index={index}
                      removeTmpBoundingBox={removeTmpBoundginBox}
                      isFocused={tmpBoundingBox.tempId === selectedFace.tempId}
                      tmpBoundingBox={tmpBoundingBox}
                      faceDropdownOnClick={faceDropdownOnClick}
                      applyFace={applyFace}
                      assetId={assets[0]._id}
                      createFace={createFace}
                      addCustomFace={addCustomFace}
                      tmpBoundingBoxId={tmpBoundingBox._id}
                      imageRevisionID={imageRevisionID}
                      managePersons={permissions.managePersons}
                    />
                  </div>
                ))
              }
            </If>
            <If condition={permissions.autogeneratePersons && isVisible()}>
              <AiButtons
                buttonText={inProgress ? localization.DETAILS.textRecognizingFace : localization.DETAILS.textRecognizeFace}
                onSubmit={sendAssetsToFacesRecognition}
                inProgress={inProgress}
                buttonTestId="faceRecognize"
                disabled={!faceRecognitionIsAvailable}
              />
            </If>
          </>
        </CSSTransition>
      </div>
    </>
  );
}

Faces.propTypes = {
  detailsPanelVisibility: shape({}),
  toggleVisibility: func,
  blockName: string,
  blockTitle: string,
  sendFaces: func,
  permissions: shape({}),
  disabled: bool,
  team: shape({}).isRequired,
  assets: arrayOf(shape({})).isRequired,
  selectedFace: shape({}),
  faceDropdownOnClick: func,
  createFace: func,
  addBoungingBox: func,
  tmpBoundingBoxes: arrayOf(any),
  addCustomFace: func,
  removeTmpBoundginBox: func,
  applyFace: func,
  onClickOnFacesEye: func,
  facesAreShown: bool,
  faceRecognitionIsAvailable: bool,
  imageRevisionID: string,
  panelName: string,
  changeTree: func,
  openedTree: string,
  assetActions: shape({}),
  selectedAssetsIds: arrayOf(string).isRequired,
  inProgress: bool,
  facesInAsset: arrayOf(any),
};

export default Faces;
