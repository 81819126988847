import React, { useState } from 'react';
import { Button, Icon } from '@picsio/ui';
import { Question } from '@picsio/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import s from './UpgradePlanBlock.module.scss';
import l18n from '../../shared/strings';
import addAddon from '../../store/billing/addAddon';
import { navigate } from '../../helpers/history';
import Logger from '../../services/Logger';
import WithSkeletonTheme from '../WithSkeletonTheme';
import { showDialog } from '../dialog';
import ua from '../../ua';
export default function UpgradePlanBlock({
  title,
  description,
  helperText,
  helperLink,
  featureName = 'upgradePlanButton',
  imageSrc = null,
  maxWidth = 700,
  redirectToBilling = false,
  amplitudeEventName = 'UpgradePlanBlockClicked',
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const billingPermission = useSelector((state) => state.user.role.permissions.manageBilling);
  const subscriptionFeatures = useSelector((state) => state.user.subscriptionFeatures);
  const isTrialUser = subscriptionFeatures?.planId === 'trial';

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleAddAddon = () => {
    showDialog({
      title: l18n.UPGRADE_PLAN_BLOCK.WARNING_ADDON_UPGRADE.TITLE,
      icon: 'notification',
      textBtnOk: l18n.UPGRADE_PLAN_BLOCK.WARNING_ADDON_UPGRADE.OK,
      textBtnCancel: l18n.UPGRADE_PLAN_BLOCK.WARNING_ADDON_UPGRADE.CANCEL,
      text: l18n.UPGRADE_PLAN_BLOCK.WARNING_ADDON_UPGRADE.TEXT(title),
      onOk: () => {
        Logger.log('User', amplitudeEventName || `${featureName}UpgradePlanClicked`);
        dispatch(addAddon({ featureName, featureTitle: title }));
      },
    });
  };

  const handleRedirectToBilling = () => {
    Logger.log('User', amplitudeEventName || `${featureName}UpgradePlanClicked`);
    navigate('/billing?tab=overview');
  };

  return (
    <div className={cn(s.container, !imageSrc && s.withoutImage)} style={{ maxWidth: `${maxWidth}px` }}>
      <If condition={imageSrc}>
        <div className={s.imageContainer}>
          <If condition={isLoading}>
            <div className={s.skeletonContainer}>
              <WithSkeletonTheme>
                <Skeleton width={300} height={180} />
              </WithSkeletonTheme>
            </div>
          </If>
          <img src={imageSrc} alt={`upgrade ${featureName}`} width="300" height="180" onLoad={handleImageLoad} />
        </div>
      </If>
      <div className={s.textContent}>
        <h2 className={s.title}>{title}</h2>
        <p className={s.description}>{description}</p>
        <If condition={helperText}>
          <a href={helperLink} target="_blank" rel="noreferrer" className={s.helperLink}>
            <Icon color="primary" size="sm"><Question /></Icon>
            <span className={s.helperText}>{helperText}</span>
          </a>
        </If>
        <If condition={!ua.isMobileApp()}>
          <Button
            id={featureName}
            onClick={redirectToBilling || isTrialUser ? handleRedirectToBilling : handleAddAddon}
            variant="contained"
            size="md"
            color="primary"
            disabled={!billingPermission}
          >
            {redirectToBilling || isTrialUser ? l18n.BILLING.textUpgradePlan : l18n.BILLING.textAddToPlan}
          </Button>
        </If>
      </div>
    </div>
  );
}

UpgradePlanBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  helperLink: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  featureName: PropTypes.string,
  imageSrc: PropTypes.node,
  maxWidth: PropTypes.number,
  redirectToBilling: PropTypes.bool,
  amplitudeEventName: PropTypes.string,
};
