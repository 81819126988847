import TYPES from '../../action-types';

/**
 * Rename keyword
 * @param {string} id
 * @param {string} newName
 */
const renameKeyword = (id, newName) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.RENAME_KEYWORD,
    payload: { id, newName },
  });
};

export default renameKeyword;
