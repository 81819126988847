import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import TYPES from '../../action-types';

/**
 * Get asset revisions thumbnails
 * @param {string} assetId
 * @param {array} revisions
 */
const getRevisionsThumbnails = (assetId) => async (dispatch) => {
  dispatch({ type: TYPES.ASSETS.FETCH_REVISIONS_THUMBNAILS.START });

  try {
    const { data: revisionsThumbnails } = await assetsHelpers.fetchRevisionsThumbnails(assetId);

    dispatch({
      type: TYPES.ASSETS.FETCH_REVISIONS_THUMBNAILS.COMPLETE,
      payload: {
        id: assetId,
        revisionsThumbnails,
      },
    });
  } catch (error) {
    dispatch({ type: TYPES.ASSETS.FETCH_REVISIONS_THUMBNAILS.FAILED, error });
    Logger.error(new Error('Error fetching asset revisions thumbnails'), { error, showDialog: false }, [
      'GetAssetRevisionsThumbnailsFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};

export default getRevisionsThumbnails;
