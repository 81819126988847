import prepareSearchQuery from './prepareSearchQuery';
import sdk from '../../sdk';

export default function getAssetsBySchemaForSearchQuery(schema) {
  return sdk.assets.search({
    onlyIds: true,
    ...prepareSearchQuery(),
    responseSchema: {
      images: {
        ...schema,
      },
    },
  });
}
