import React, { memo, useCallback } from 'react';
import { InputControlLabel } from '@picsio/ui';
import { useDispatch, useSelector } from 'react-redux';
import { recursiveSearchToggle } from '../../store/actions/collections';
import l10n from '../../shared/strings';

const RecursiveSearchControl = () => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.collections.notRecursiveSearch);
  const handleChange = useCallback(() => {
    dispatch(recursiveSearchToggle(!value));
  }, [dispatch, value]);

  return (
    <div className="folderTreeCheckbox">
      <InputControlLabel
        value={value}
        control="switch"
        onChange={handleChange}
        label={l10n.RECURSIVE_SEARCH.labelOnTreeDontShow}
      />
    </div>
  );
};

export default memo(RecursiveSearchControl);
