import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactSelect } from '../../UIComponents';

class Enum extends React.Component {
  // Using props in an initial state it's an anti-pattern
  // https://medium.com/@justintulk/react-anti-patterns-props-in-initial-state-28687846cc2e
  state = {
    value: null,
    options: [],
  };

  componentDidMount() {
    const { customField } = this.props;

    if (customField) {
      this.setOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setOptions();
    }
  }

  setOptions = () => {
    const { value, customField } = this.props;
    const validValue = typeof value === 'number' ? value.toString() : value;
    let options = [{ label: 'Select value', value: 'select' }];

    if (Array.isArray(customField.options)) {
      options = options.concat(customField.options.map((key) => ({ label: key, value: key })));
    }
    this.setState({
      /** if value is null or empty string -> no option selected */
      value: value === null || value === '' ? null : { label: validValue, value: validValue },
      options,
    });
  };

  handleChange = ({ value }) => {
    const { customField = { title: '', options: [] }, onChange } = this.props;
    const validValue = value === 'select' ? null : value;
    this.setState({
      value: validValue === null ? validValue : { label: validValue, value: validValue },
    });
    onChange(validValue, customField);
  };

  render() {
    const { value, options } = this.state;
    const {
      customField: { title = '' },
      disabled = false,
      selectPopperStyle = {},
      menuPlacement = 'auto',
      placeholder = 'Select value',
      fromSearch = false,
      changed = false,
      error = null,
    } = this.props;
    return (
      <div data-qa={`custom-field-${title}`} className={cn('picsioInputText customFieldValue', { changed: fromSearch && changed, disabled, error })}>
        <If condition={title.length > 0}>
          <span className="labelInputText">{title}</span>
        </If>
        <ReactSelect
          options={options}
          value={value}
          disabled={disabled}
          onChange={this.handleChange}
          styles={selectPopperStyle}
          menuPlacement={menuPlacement}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

Enum.defaultProps = {
  value: '',
  customField: {
    title: '',
    options: [],
  },
  disabled: false,
  selectPopperStyle: {},
  menuPlacement: 'auto',
  placeholder: 'Select value',
  fromSearch: false,
  changed: false,
  error: null,
};
Enum.propTypes = {
  value: PropTypes.string,
  customField: PropTypes.shape({
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectPopperStyle: PropTypes.objectOf(PropTypes.any),
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.string,
  fromSearch: PropTypes.bool,
  changed: PropTypes.bool,
  error: PropTypes.string,
};

export default Enum;
