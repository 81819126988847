import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Fetch one asset from the server
 * @param {string[]} assetIds
 * @returns Promise<AxiosResponse>
 */
export default function fetchThumbnails(assetIds) {
  if (picsioConfig.isMainApp) return sdk.assets.fetchThumbnails(assetIds);

  return sdk.assets.fetchThumbnailsPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
  );
}
