import TYPES from '../../action-types';

/**
 * Delete collection - triggered when collection removed (e.g. from tree)
 * @param {string} id
 */
const deleteCollection = (id) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.DELETE_COLLECTION,
    payload: { id },
  });
};

export default deleteCollection;
