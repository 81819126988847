import initSdk from '@picsio/sdk';
import config from '../../../../config';
import Logger from '../services/Logger';
import mobileTokenStorage from '../helpers/MobileTokenStorage';

// Value will be injected by Webpack DefinePlugin constant from Webpack
// eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
const isMobileApp = __IS_MOBILE__;

const options = {
  appId: config.appId,
  appHeader: 'MainApp',
  saveAuth: false,
  host: config.apiBaseUrl,
  Logger,
};

if (isMobileApp) {
  options.appId = config.mobileAppId;
  options.appHeader = 'MobileApp';
  options.saveAuth = true;
  options.tokenStorage = mobileTokenStorage;
}

const sdk = initSdk(options);

export default sdk;
