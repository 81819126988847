const incorrectCollectionIdsLength = (collectionIds) => {
  let collectionIdsArray = [];
  if (!Array.isArray(collectionIds)) {
    collectionIdsArray = [collectionIds];
  } else {
    collectionIdsArray = [...collectionIds];
  }
  return collectionIdsArray.every((collectionId) => collectionId.length !== 24);
};

export default incorrectCollectionIdsLength;
