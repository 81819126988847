import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES from '../../action-types';
import picsioConfig from '../../../../../../config';
import setAggregatedData from '../../helpers/assets/setAggregatedData';
import * as assetsHelpers from '../../../helpers/assets';
import addItemToAggregatedData from '../../helpers/assets/addItemToAggregatedData';
import getAssetsFromStoreByIds from '../../helpers/assets/getAssetsFromStoreByIds';
import hasAllAssets from '../../helpers/assets/hasAllAssets';

/**
 * Select asset(s)
 * @param {string} id
 * @param {boolean} value
 * @param {boolean} isRange
 * @param {boolean} isOnlyOne
 */
const select = createAsyncThunk(
  'assets.select',
  async ({
    id, value, isRange, isOnlyOne,
  }, {
    dispatch,
    getState,
    extra: {
      sdk, Logger, showErrorDialog, UiBlocker, localization,
    },
  }) => {
    const {
      assets, router, user, customFields,
    } = getState();
    const { selectedItems, items, lastClicked } = assets;
    // we need 'rolePermissions=null' for Proofing. We don't need to check assets permissions there.
    const { permissions: rolePermissions = null } = user?.role || {};
    const asset = assets.items.find((item) => item._id === id);

    let aggregatedData;
    let newItems;
    let permissionsOnDeselect;

    let selectedAssetsIds;

    if (value) {
      selectedAssetsIds = [...assets.selectedItems, id];
    } else {
      selectedAssetsIds = assets.selectedItems.filter((selectedAssetId) => selectedAssetId !== id);
    }

    if (!selectedAssetsIds.length || (selectedItems && isOnlyOne)) {
      dispatch({
        type: TYPES.ASSETS.DESELECT_ALL,
        payload: {
          routeIsSelectedAssets: router.location.pathname === '/selectedAssets',
        },
      });
    }

    /* if there is not all items in store AND deselect asset */
    if ((!hasAllAssets(selectedAssetsIds, assets.items) && !value) || (selectedAssetsIds.length === 2 && !value && picsioConfig.isMainApp)) {
      try {
        UiBlocker.block();
        const permissionsNames = [...Object.keys(items[0].permissions), 'isDownloadable', 'canBeCompared'];
        const { data: assetsBatchPermissions } = await sdk.assets.getPermissions(
          [...selectedAssetsIds], permissionsNames,
        );
        permissionsOnDeselect = { ...assetsBatchPermissions };
      } catch (err) {
        Logger.error(new Error('Can not get permissions on selected assets'), { error: err }, [
          'GetAssetsPermissions',
          (err && err.message) || 'NoMessage',
        ]);
        showErrorDialog(localization.CATALOG_VIEW.cantLoadData);
      } finally {
        UiBlocker.unblock();
      }
    } /** the logic for the case when we have everything is handled reducer with helpers.getPermissions */

    /* if add to selection */
    if (value) {
      if (isOnlyOne) { // options + click
        aggregatedData = addItemToAggregatedData(
          asset,
          {},
          user,
          assets.selectedItems.length,
          customFields.items,
        );
      } else if (isRange && lastClicked) { // shift + click
        const range = [
          items.findIndex((item) => item._id === lastClicked),
          items.findIndex((item) => item._id === id),
        ].sort((a, b) => a - b);
        range[1] += 1;
        const assetsInRange = items.slice(...range);
        aggregatedData = assets.selectedAssetsAggregatedData;
        assetsInRange.forEach((assetInRange) => {
          aggregatedData = addItemToAggregatedData(
            assetInRange,
            aggregatedData,
            user,
            assets.selectedItems.length,
            customFields.items,
          );
        });
      } else {
        aggregatedData = addItemToAggregatedData(
          asset,
          assets.selectedAssetsAggregatedData,
          user,
          assets.selectedItems.length,
          customFields.items,
        );
      }
    } else if (selectedAssetsIds.length <= assets.items.length && hasAllAssets(selectedAssetsIds, assets.items)) {
      /* if remove from selection and we have all necessary data to make new aggregatedData */
      const selectedAssetsFromStore = getAssetsFromStoreByIds(selectedAssetsIds, assets.items);
      aggregatedData = setAggregatedData(
        selectedAssetsFromStore,
        assets.aggregatedData,
        user,
        customFields.items,
      );

      if (router.location.pathname === '/selectedAssets') {
        newItems = assets.items.filter((item) => item._id !== id);
      }
    } else {
      /* if user deselected and we do not have all necessary data */
      aggregatedData = await assetsHelpers.getAggregatedData(selectedAssetsIds, user);
    }
    aggregatedData = { ...aggregatedData };

    dispatch({
      type: TYPES.ASSETS.SELECT,
      payload: {
        id,
        value,
        isRange,
        rolePermissions,
        aggregatedData,
        newItems,
        permissionsOnDeselect,
      },
    });
    if (selectedItems.length === 1 && !value) {
      window.dispatchEvent(new Event('selection:switched:off'));
    } else {
      window.dispatchEvent(new Event('selection:switched:on'));
    }
    if (selectedItems.length !== 0 && value) {
      Logger.log('User', 'ThumbnailSelectMultiple', {
        amount: selectedItems.length + 1,
      });
    } else {
      Logger.log('User', value ? 'ThumbnailSelect' : 'ThumbnailDeselect', {
        assetId: id,
      });
    }
  },
);

export default select;
