/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import localization from '../../shared/strings';
import { showErrorDialog } from '../../components/dialog';

export const merge = createAsyncThunk(
  'faces/merge',
  async (
    { faceId, targetPersonId },
    {
      extra: {
        sdk, Logger,
      },
    }) => {
    try {
      await sdk.faces.merge(faceId, targetPersonId);
      return faceId;
    } catch (error) {
      Logger.error(new Error('Error merge faces'), { error }, [
        'MergeFacesFailed',
        (error && error.message) || 'NoMessage',
      ]);
      showErrorDialog(localization.FACESTREE.errorDialog);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    merge.pending,
    (state) => {
      state.isBusy = true;
    },
  ).addCase(
    merge.fulfilled,
    (state, { payload: faceId }) => {
      const face = state.all.find((face) => face._id === faceId);

      state.all = state.all.filter((face) => face._id !== faceId);
      if (face?.unnamed) {
        state.tree = {
          ...state.tree,
          unnamed: {
            ...state.tree.unnamed,
            nodes: state.tree.unnamed.nodes.filter((node) => node._id !== face._id),
          },
        };
      } else {
        state.tree = {
          ...state.tree,
          allFaces: {
            ...state.tree.allFaces,
            nodes: state.tree.allFaces?.nodes.filter((node) => node._id !== face._id),
            unnamed: false,
          },
        };
      }
      if (face?.isFavorite) {
        state.tree = {
          ...state.tree,
          favorites: {
            ...state.tree.favorites,
            nodes: state.tree.favorites.nodes.filter((node) => node._id !== face._id),
            unnamed: false,
          },
        };
      }
      state.isBusy = false;
    },
  ).addCase(
    merge.rejected,
    (state) => {
      state.isBusy = false;
    },
  );
};
