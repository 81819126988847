import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import DetailsPanelEditField from './DetailsPanelEditField';

const DetailsPanelEditList = forwardRef((props, ref) => {
  const {
    groups, hidden, toggleField, listId, permissions,
  } = props;

  const [collapsedGroupsTitles, setCollapsedGroupsTitles] = useState([]);

  const handleCollapseGroup = (separatorTitle) => {
    if (collapsedGroupsTitles.includes(separatorTitle)) {
      setCollapsedGroupsTitles((prev) => prev.filter((item) => item !== separatorTitle));
    } else {
      setCollapsedGroupsTitles((prev) => [...prev, separatorTitle]);
    }
  };

  return (
    <ul ref={ref} className={`detailsPanelEdit__list__${listId}`}>
      {groups.map((group, index) => {
        const field = group[0];
        const {
          id, title, type, permission,
        } = field;
        if (permission && !permissions?.[permission]) return null;

        return (
          <DetailsPanelEditField
            key={id || title}
            index={index}
            id={id || title}
            title={title}
            toggleField={toggleField}
            type={type}
            group={group}
            hidden={hidden}
            collapsedGroupsTitles={collapsedGroupsTitles}
            collapseGroup={handleCollapseGroup}
            // props for SortableElement
            collection={listId}
          />
        );
      })}
    </ul>
  );
});

DetailsPanelEditList.defaultProps = {
  groups: [],
  hidden: [],
};
DetailsPanelEditList.propTypes = {
  listId: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        permission: PropTypes.string,
      }),
    ),
  ),
  hidden: PropTypes.arrayOf(PropTypes.string),
  toggleField: PropTypes.func.isRequired,
};

export default DetailsPanelEditList;
