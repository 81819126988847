import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';
import {
  navigateToRoot, setSearchRoute,
} from '../../../helpers/history';

/**
 * Toggle recursive search
 */
function recursiveSearchToggle(value) {
  return (dispatch, getState) => {
    utils.LocalStorage.set('picsio.recursiveSearch', !value);

    dispatch({
      type: TYPES.COLLECTIONS.RECURSIVE_SEARCH_TOGGLE,
      payload: { value },
    });

    // we need to use setSearchRoute, not reloadRoute, because setSearchRoute
    // uses constructQueryString to add 'recursive=false'
    // TODO: rewrite picsioNavigator to pass parameter 'recursive=false' directly
    const { collectionIds, archived } = getState().router.location.query;
    if (collectionIds) {
      const query = { collectionIds, archived };
      setSearchRoute(query);
    } else {
      // when we select lightboard and then turn off Recursive search, we don't have collectionIds, so we needs to navigateToRoot
      navigateToRoot();
    }
    if (value) {
      Logger.log('User', 'CollectionsPanelDontShowRecursiveSetOn');
    } else {
      Logger.log('User', 'CollectionsPanelDontShowRecursiveSetOff');
    }
  };
}

export default recursiveSearchToggle;
