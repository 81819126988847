import React from 'react';
import {
  func, arrayOf, number, string, bool, array,
} from 'prop-types';
import cn from 'classnames';
import ModifiedField from './ModifiedField';
import { Flags, Colors, StarRating } from '../../../UIComponents';
import Logger from '../../../services/Logger';

class AssetMarks extends React.Component {
  setFlag = (flag) => {
    if (this.props.logEvents) {
      Logger.log('User', `${this.props.eventPrefix}ChangeFlag`, { flag, selectedAssetIds: this.props.selectedAssets });
    }
    if (this.props.flagEditable === true) {
      this.props.changeFlag(this.props.selectedAssets, flag);
    }
  };

  setColor = (color) => {
    if (this.props.logEvents) {
      Logger.log('User', `${this.props.eventPrefix}ChangeColor`, { color, selectedAssetIds: this.props.selectedAssets });
    }
    if (this.props.colorEditable === true) {
      this.props.changeColor(this.props.selectedAssets, color);
    }
  };

  setRating = (rating) => {
    if (this.props.logEvents) {
      Logger.log('User', `${this.props.eventPrefix}ChangeRating`, {
        rating,
        selectedAssetIds: this.props.selectedAssets,
      });
    }
    if (this.props.ratingEditable === true) {
      if (this.props.rating === rating) {
        this.props.changeRating(this.props.selectedAssets, 0);
      } else {
        this.props.changeRating(this.props.selectedAssets, rating);
      }
    }
  };

  render() {
    const {
      highlight,
      flagShow,
      colorShow,
      ratingShow,
      order,
      modifiedFlag,
      modifiedColor,
      modifiedRating,
      highlightAnimationReset,
      flagEditable,
      disabled,
      flagError,
      colorEditable,
      colorError,
      ratingEditable,
      ratingError,
    } = this.props;

    let { color, rating, flag } = this.props;
    if (!color) color = 'nocolor';
    if (rating === null) rating = 0;
    if (!flag) flag = 'unflagged';

    if (!flagShow && !colorShow && !ratingShow) return null;

    return (
      <div className="assetMarks">
        {
          order.map((id) => {
            if (id === 'flag' && flagShow) {
              return (
                <div key={id} className={cn('markWithModifiedField', { markWithModifiedFieldWithIcon: Boolean(modifiedFlag) })}>
                  <Flags
                    value={flag}
                    onChange={this.setFlag}
                    highlightAnimationReset={highlightAnimationReset}
                    highlight={highlight.includes('flag')}
                    className={flagEditable !== true || disabled ? 'disabled' : ''}
                    error={Boolean(flagError)}
                  />
                  {modifiedFlag && <ModifiedField field={modifiedFlag} />}
                </div>
              );
            }
            if (id === 'color' && colorShow) {
              return (
                <div key={id} className={cn('markWithModifiedField', { markWithModifiedFieldWithIcon: Boolean(modifiedColor) })}>
                  <Colors
                    value={color}
                    onChange={this.setColor}
                    highlightAnimationReset={highlightAnimationReset}
                    highlight={highlight.includes('color')}
                    className={colorEditable !== true || disabled ? 'disabled' : ''}
                    error={Boolean(colorError)}
                  />
                  {modifiedColor && <ModifiedField field={modifiedColor} />}
                </div>
              );
            }

            if (id === 'rating' && ratingShow) {
              return (
                <div key={id} className={cn('markWithModifiedField', { markWithModifiedFieldWithIcon: Boolean(modifiedRating) })}>
                  <StarRating
                    value={rating}
                    onChange={this.setRating}
                    highlightAnimationReset={highlightAnimationReset}
                    highlight={highlight.includes('rating')}
                    className={ratingEditable !== true || disabled ? 'disabled' : ''}
                    error={Boolean(ratingError)}
                  />
                  {modifiedRating && <ModifiedField field={modifiedRating} />}
                </div>
              );
            }
            return null;
          })
        }
      </div>
    );
  }
}
AssetMarks.defaultProps = {
  logEvents: true,
  eventPrefix: '',
  order: ['flag', 'color', 'rating'],
  highlight: [],
};

AssetMarks.propTypes = {
  logEvents: bool,
  highlight: arrayOf(string),
  color: string,
  eventPrefix: string,
  changeColor: func,
  rating: number,
  changeRating: func,
  flag: string,
  changeFlag: func,
  flagShow: bool,
  flagEditable: bool,
  colorShow: bool,
  colorEditable: bool,
  ratingShow: bool,
  ratingEditable: bool,
  selectedAssets: arrayOf(string),
  disabled: bool,
  order: array,
};

export default AssetMarks;
