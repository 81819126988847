import TYPES from '../../action-types';

/**
 * Update asset field
 * @param {string} assetID
 * @param {array} fields
 * @param {array} values
 */
const updateFields = (assetID, fields, values) => (dispatch, getAll) => {
  const { items } = getAll().assets;
  /** dispatch only if assets is loaded to the store */
  if (items.find((asset) => asset._id === assetID)) {
    dispatch({
      type: TYPES.ASSETS.UPDATE_FIELDS_ONE_ASSET,
      payload: {
        assetID,
        fields,
        values,
      },
    });
  }
};

export default updateFields;
