import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Logger from '../../services/Logger';
import { navigate } from '../../helpers/history';
import localization from '../../shared/strings';
import Dropdown from '../dropdown';
import Opener from '../Opener';
import UpgradePlan from '../UpgradePlan';

import './styles.scss';

const extraFields = [{
  title: 'Map',
}];
const defaultFields = [];

const CustomFieldsSelector = (props) => {
  const {
    title = 'Add custom field',
    label = 'Custom field',
    className = '',
    selectedFields = defaultFields,
    hiddenFields = defaultFields,
    addField = null,
    removeField = null,
    hideDisabled = false,
    readOnly = false,
    disabled = false,
    autoFocus = false,
    eventName = '',
    disablePortal = false,
    noCustomFields = true,
    inSearch = false,
    'data-testid': dataTestId = null,
    limitedByPlan = false,
  } = props;
  const customFields = useSelector((state) => state.customFields.items);

  const [fields, setFields] = useState({
    all: [],
    writable: [],
  });
  const items = hideDisabled ? fields.writable : fields.all;
  const localizedLabel = localization.SEARCH.text[label] || '';

  useEffect(() => {
    const newFields = customFields
      .concat(extraFields)
      .reduce((acc, item) => {
        const { type, title: itemTitle, writable } = item;

        if (type !== 'separator' && !hiddenFields.includes(itemTitle)) {
          const newItem = {
            ...item,
            _id: itemTitle,
          };

          acc.all.push(newItem);

          if (writable !== false) {
            acc.writable.push(newItem);
          }
        }
        return acc;
      }, {
        all: [],
        writable: [],
      });

    setFields(newFields);
  }, [customFields, hiddenFields]);

  const handleClickUpgrade = useCallback(() => {
    Logger.log('User', 'CustomFieldsUpgradePlanClicked');
    navigate('/billing?tab=overview');
  }, []);

  return (
    <div className="customFieldsSelectorWrapper">
      <If condition={limitedByPlan}>
        <UpgradePlan onClick={handleClickUpgrade} />
      </If>
      <Opener
        openerText={title}
        disabled={disabled}
        hideOpenerWhenOpen
        eventName={eventName}
        noCustomFields={noCustomFields}
        inSearch={inSearch}
        data-testid={dataTestId}
      >
        <div className={cn('itemSearchFilters', className)}>
          <div className="labelItemSearchFilters">{localizedLabel}</div>
          <div className="contentItemSearchFilters">
            <Dropdown
              filterText={localization.DROPDOWN.chooseCustomFields}
              checkedItems={selectedFields}
              items={items}
              onCheckedHandler={addField}
              onUncheckedHandler={removeField}
              createHandler={null}
              readOnly={readOnly}
              disabled={disabled}
              autoFocus={autoFocus}
              type="default"
              disablePortal={disablePortal}
            />
          </div>
        </div>
      </Opener>
    </div>
  );
};

CustomFieldsSelector.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  selectedFields: PropTypes.arrayOf(PropTypes.object),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  addField: PropTypes.func,
  removeField: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  hideDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disablePortal: PropTypes.bool,
  eventName: PropTypes.string,
  noCustomFields: PropTypes.bool,
  inSearch: PropTypes.bool,
  'data-testid': PropTypes.string,
  limitedByPlan: PropTypes.bool,
};

export default CustomFieldsSelector;
