/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { sort, updateCount as updateCountHelper } from '../helpers/keywords';

export const updateCount = createAction('keywords/updateCount');

export const reducer = (builder) => {
  builder.addCase(
    updateCount.type,
    (state, { payload: { _id, value } }) => {
      state.tree = updateCountHelper(state.tree, _id, value);
      /** if sort by count */
      if (state.sortType.type === 'count') {
        state.tree = {
          favorites: { ...state.tree.favorites, nodes: sort(state.tree.favorites.nodes, state.sortType) },
          keywords: { ...state.tree.keywords, nodes: sort(state.tree.keywords.nodes, state.sortType) },
        };
      }
      state.all.forEach((keyword) => {
        if (keyword._id === _id) {
          keyword.count = (keyword.count || 0) + value;
        }
      });
    },
  );
};
