/* eslint-disable no-param-reassign */
import _uniqBy from 'lodash/uniqBy';
import { createAsyncThunk } from '@reduxjs/toolkit';
import CONSTANTS from '@picsio/db/src/constants';

import { addKeyword, makeTree } from '../helpers/keywords';
import { reloadCurrentPage } from '../../helpers/history';

export const create = createAsyncThunk(
  'keywords/create',
  async ({ name, parentId, multi }, {
    extra: {
      sdk, utils, localization, Toast, Logger,
    },
  }) => {
    try {
      const trimmedName = name.trim();
      const parentIdforServer = ['favorites', 'keywords'].includes(parentId) ? null : parentId;
      /** <multi> is for parse multiple keyword names from "name" separated by comma */
      if (multi) {
        const { data } = await sdk.keywords.create(trimmedName, parentIdforServer, true);
        const keywords = Array.isArray(data) ? data.filter((keyword) => keyword.isNew) : [];
        if (data.isNew) keywords.push(data);
        return { keywords, parentId };
      }
      const { data: keyword } = await sdk.keywords.create(trimmedName, parentIdforServer);
      if (!keyword.name) keyword.name = keyword.path.split(CONSTANTS.NEW_PATH_DELIMITER).pop();

      return {
        keywords: [keyword],
        parentId,
      };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg');
      const subcode = utils.getDataFromResponceError(error, 'subcode');
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 403) {
        Toast(localization.NO_PERMISSION_TO_ACCESS, { autoClose: false, type: 'error' });
        return {}; /** no need to throw the error */
      } if (subcode === 'KeywordAlreadyInUseApiError') {
        Toast(localization.KEYWORDS.alreadyExists, { autoClose: false, type: 'error' });
        return {}; /** no need to throw the error */
      } if (errorMessage) {
        Toast(errorMessage, { autoClose: false });
      } else {
        Toast(localization.KEYWORDS.alertCantAddKeyword, {
          autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh,
        });
      }
      Logger.error(new Error('Can not add keywords'), { error }, [
        'AddKeywordsFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    create.pending,
    (state) => {
      state.isBusy = true;
    },
  )
  .addCase(
    create.fulfilled,
    (state, { payload: { keywords = [], parentId } }) => {
      const all = _uniqBy([...state.all, ...keywords], '_id');
      if (keywords.length === 1) {
        state.tree = addKeyword(state.tree, parentId, keywords[0], state.sortType);
      } else {
        state.tree = makeTree(all, state.sortType);
      }
      state.all = all;
      state.isBusy = false;
    },
  )
  .addCase(
    create.rejected,
    (state) => {
      state.isBusy = false;
      state.tree = makeTree(state.all, state.sortType);
    },
  );
