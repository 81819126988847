import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { IconButton } from '@picsio/ui';
import { Close } from '@picsio/icons';
import localization from '../shared/strings';
import Tooltip from '../components/Tooltip';

const colors = [
  {
    name: 'red',
    value: '#ff0000',
    tooltip: localization.DETAILS.textLabeledRed,
  },
  {
    name: 'yellow',
    value: '#ffcc00',
    tooltip: localization.DETAILS.textLabeledYellow,
  },
  {
    name: 'green',
    value: '#66cc00',
    tooltip: localization.DETAILS.textLabeledGreen,
  },
  {
    name: 'blue',
    value: '#3300ff',
    tooltip: localization.DETAILS.textLabeledBlue,
  },
  {
    name: 'purple',
    value: '#9900ff',
    tooltip: localization.DETAILS.textLabeledPurple,
  },
  {
    name: 'nocolor',
    value: null,
    tooltip: localization.DETAILS.textNoColorLabel,
  },
];

const Colors = (props) => {
  const {
    value = [],
    onChange = () => null,
    className = '',
    highlight = false,
    error = false,
    disabled = false,
    deselectColors = null,
  } = props;

  return (
    <div
      className={cn('defaultColorList', className, { isError: error, disabled })}
      onAnimationEnd={() => props.highlightAnimationReset('color')}
    >
      <ul
        className={cn({
          highlightBlink: highlight,
        })}
      >
        {colors.map((color) => (
          <Tooltip key={color.name} content={color.tooltip} placement="top">
            <li
              onClick={() => onChange(color.name)}
              className={
                value.indexOf(color.name) !== -1 || value.indexOf(color.value) !== -1 ? 'act' : ''
              }
            >
              <span
                className={`filterLabelColor ${!color.value ? 'filterUnlabeledColor' : ''}`}
                style={{ background: color.value || '' }}
              />
            </li>
          </Tooltip>
        ))}
        <If condition={deselectColors}>
          <Tooltip content={localization.DETAILS.textDeselectColors} placement="top">
            <li
              className="deselectColors"
            >
              <IconButton size="lg" onClick={deselectColors}>
                <Close />
              </IconButton>
            </li>
          </Tooltip>
        </If>
      </ul>
    </div>
  );
};

Colors.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  highlight: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  highlightAnimationReset: PropTypes.func,
  deselectColors: PropTypes.func,
};

export default Colors;
