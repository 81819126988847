import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import { showDialog } from '../../../components/dialog';
import l10n from '../../../shared/strings';
import prepareAssetFields from '../helpers/prepareAssetFields';

const saveAsset = async ({
  item, assetData, action, fields, duplicatedModel, isUploadRevision, storageResponse,
}) => {
  const additionalFields = prepareAssetFields(fields);
  let asset;

  try {
    if (isUploadRevision) {
      await sdk.assets.createRevision(duplicatedModel._id, assetData, additionalFields);
      asset = storageResponse;
    } else {
      const assetIdToReplace = action === 'replaceFile' && duplicatedModel?._id;
      const { data } = await sdk.assets.create(
        assetData,
        { ...additionalFields, assetIdToReplace: assetIdToReplace || undefined },
      );
      asset = data;
    }
  } catch (error) {
    Logger.info('Can not save asset to db: ', error);
    const errorMessage = utils.getDataFromResponceError(error, 'msg');
    const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
    const errorStatus = utils.getStatusFromResponceError(error);
    let fileError = {
      code: error.status || errorStatus,
      reason: 'cantSaveToDB',
      message: error.response ? `${errorSubcode}: ${errorMessage}` : error,
    };
    if (errorSubcode === 'CreateAssetLimitsApiError') {
      fileError = {
        code: 403,
        reason: 'subscriptionLimits',
        message: error.response ? `${errorSubcode}: ${errorMessage}` : error,
      };
      showDialog({
        title: l10n.DIALOGS.ASSETS_LIMIT.TITLE,
        text: l10n.DIALOGS.ASSETS_LIMIT.TEXT,
        textBtnCancel: null,
      });
    } else if (!window.navigator.onLine) {
      fileError = {
        code: 403,
        reason: 'slowConnection',
        message: 'No internet',
      };
    }
    Logger.error(new Error('Import: can not save asset on the server'), {
      error,
      item: { ...item },
      isRevision: isUploadRevision,
    });

    throw fileError;
  }

  return asset;
};

export default saveAsset;
