import TYPES from '../../action-types';

/** Set teamName */
function setTeamName(name) {
  return (dispatch, getState) => {
    const { teamName } = getState().user.team.policies;
    dispatch({
      type: TYPES.COLLECTIONS.UPDATE_ROOT_COLLECTION_NAME,
      payload: {
        teamName: name || teamName,
      },
    });
  };
}

export default setTeamName;
