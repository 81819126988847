import { createAsyncThunk } from '@reduxjs/toolkit';

const searchArchivedCollections = createAsyncThunk(
  'archive/searchArchivedCollections',
  async (query, { extra: { sdk }, rejectWithValue }) => {
    try {
      const { data } = await sdk.collections.search(query, { archived: true });

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export default searchArchivedCollections;
