import React from 'react';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from '../ErrorBoundary';
import Dialog from './views/index';

export default function DialogWrapper({ data, parentEl, root }) {
  const $wrapper = document.querySelector('.wrapperDialog');
  let dialogRoot = root;
  if (!root) {
    dialogRoot = createRoot($wrapper);
  }

  dialogRoot.render(
    <ErrorBoundary className="errorBoundaryOverlay">
      <Dialog data={data || {}} parentEl={parentEl} root={dialogRoot} />
    </ErrorBoundary>,
  );

  return {};
}
