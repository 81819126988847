/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';

export const updateFile = createAction('import/updateFile');

export const reducer = (builder) => {
  builder.addCase(
    updateFile.type,
    (state, { payload: { id, fields } }) => {
      Object.values(state.items).forEach((files) => {
        files.forEach((item) => {
          if (item.id === id) {
            if (fields.error && !item.error) {
              /** If set error */
              state.uploadErrorsCount += 1;
            }
            if (!fields.error && item.error) {
              /** If unset error (retry upload) */
              state.uploadErrorsCount -= 1;
            }
            Object.assign(item, fields);
          }
        });
      });
    },
  );
};
