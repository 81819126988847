import { createAsyncThunk } from '@reduxjs/toolkit';

const applyFace = createAsyncThunk(
  'asset/applyFace',
  async ({
    assetId,
    currentFaceId,
    newFaceId,
    boundingBox,
    name,
    revisionId,
    isNewFace,
    unnamed,
  }, {
    extra: {
      sdk, Logger, utils, showErrorDialog,
    },
  }) => {
    try {
      if (!isNewFace) {
        await sdk.assets.applyFace(assetId, {
          currentFaceId, newFaceId, boundingBox,
        });
        return {
          boundingBox, name, assetId, revisionId, newFaceId, isNewFace, unnamed,
        };
      }
      const { data } = await sdk.assets.applyFace(assetId, { currentFaceId, name, boundingBox });
      return {
        ...data, isNewFace, revisionId, assetId, boundingBox,
      };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not apply face on asset';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error applying face on asset'),
        { error },
        ['ApplyFaceOnAssetFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export default applyFace;

export const reducer = (builder) => {
  // update face tree if this is the newFace
  builder.addCase(
    applyFace.fulfilled,
    (state, { payload }) => {
      if (payload.isNewFace) {
        const newFaces = [];
        const data = payload?.eventData || payload;
        const isFacedExist = state.all.find((storeFaced) => storeFaced._id === data._id);
        if (!isFacedExist) {
          newFaces.push(data);
        }
        state.all = [...state.all, ...newFaces];
        state.tree = {
          ...state.tree,
          allFaces: {
            ...state.tree.allFaces,
            nodes: [
              ...state.tree.allFaces.nodes, ...newFaces,
            ],
          },
        };
      }
    },
  );
};
