import React, { useCallback, useState, useMemo } from 'react';
import {
  array, arrayOf, object, func, bool, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { IconButton, Icon } from '@picsio/ui';
import {
  TrashIcon, Copy, RegularX, SelectAll, ControlledVocabulary, AiKeywords, Label,
} from '@picsio/icons';
import DetailsBlock from '../../../DetailsBlock';
import DetailsPlaceholder from '../../../DetailsBlock/Placeholder';
import KeywordsDropdown from '../../../keywordsDropdown';
import Tooltip from '../../../Tooltip';
import sendEventToIntercom from '../../../../services/IntercomEventService';
import l10n from '../../../../shared/strings';
import { setSearchRoute, navigate } from '../../../../helpers/history';
import Logger from '../../../../services/Logger';
import * as UtilsCollections from '../../../../store/utils/collections';
import TagsGroup from '../../../TagsGroup';
import copyTextToClipboard from '../../../../helpers/copyTextToClipboard';
import { getKeywordNameFromPath } from '../../../../store/helpers/keywords';
import Buttons from './Buttons';

const KEYWORDER = '500000000000000000000006';
const FACER = '500000000000000000000023';
const METADATER = '500000000000000000000002';
const CRAWLER = '500000000000000000000026';

function Keywords({
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
  isMainApp,
  attach,
  detach,
  detachMany,
  changeTree,
  generate,
  keywords,
  selectedAssetsIds,
  permissions,
  disabled,
  inProgress,
  generationIsInProgress,
  highlight,
  highlightAnimationReset,
  modifiedField,
  openedTree,
  team,
  keywordingnIsAvailable,
}) {
  const isCreatableKeywords = useSelector((state) => {
    const isAddKeywordsOutsideVocabulary = state.user.role.permissions.addKeywordsOutsideVocabulary;
    const { isKeywordsActionsAllowed } = state.user;
    return isAddKeywordsOutsideVocabulary || isKeywordsActionsAllowed;
  });

  const isOpened = useMemo(
    () => !!detailsPanelVisibility[blockName],
    [detailsPanelVisibility, blockName],
  );
  const toggleOpen = useCallback(
    () => toggleVisibility(blockName),
    [blockName, toggleVisibility],
  );
  const isCVEnabled = useMemo(() => team.policies.useKeywordsControlledVocabulary, [team]);

  const [selected, setSelected] = useState([]);

  const handleSelect = useCallback((ids, newOnly = false) => {
    /** New only -> for click with ALT key pressed */
    if (newOnly) {
      setSelected(ids);
      return;
    }

    setSelected((prev) => [...(new Set([...prev, ...ids]))]);
  }, [setSelected]);

  const handleDeSelect = useCallback((ids) => {
    setSelected((prev) => prev.filter((id) => !ids.includes(id)));
  }, [setSelected]);

  const handleAttachKeyword = useCallback((keyword, logEvent = true) => {
    /** keyword can be array, when user attach it with comma (,) */
    const isArray = Array.isArray(keyword);
    const name = isArray ? keyword.map(({ title }) => title).join(',') : keyword.title;

    if (logEvent) {
      Logger.log('User', 'KeywordsAdd', {
        keyword: isArray ? keyword.map(({ _id, title }) => `${_id || '<NEW>'} | ${title}`).join(' ; ') : `${keyword._id || '<NEW>'} | ${keyword.title}`,
        assetCount: selectedAssetsIds.length,
        keywordCount: isArray ? keyword.length : 1,
        assetId: selectedAssetsIds.join(', '),
      });
    }
    attach(name, selectedAssetsIds);
  }, [attach, selectedAssetsIds]);

  const handleClickNumber = useCallback((keyword) => {
    const { _id, title } = keyword;
    Logger.log('User', 'KeywordsAddToEntireSelection', {
      assetCount: selectedAssetsIds.length,
      keyword: `${_id} | ${title}`,
    });
    handleAttachKeyword(keyword, false);
  }, [handleAttachKeyword, selectedAssetsIds.length]);

  const handleDetachKeyword = useCallback(async (keywordOrIds, logEvent = true) => {
    /** Detach group */
    if (Array.isArray(keywordOrIds)) {
      if (logEvent) {
        Logger.log('User', 'InfoPanelKeywordsDetachMany', { keywordOrIds, selectedAssetsIds });
      }
      const isDetached = await detachMany(keywordOrIds, selectedAssetsIds);
      /** clear selected if detach successful */
      if (isDetached) setSelected((prev) => prev.filter((_id) => !keywordOrIds.includes(_id)));
      return;
    }

    /** Detach single keyword */
    if (logEvent) {
      Logger.log('User', 'KeywordsRemoveSingle', {
        source: 'dropdown',
        keyword: `${keywordOrIds._id} | ${keywordOrIds.title}`,
        assetCount: selectedAssetsIds.length,
        assetId: selectedAssetsIds.join(', '),
      });
    }
    const isDetached = await detach(keywordOrIds._id, selectedAssetsIds);
    /** clear selected if detach successful */
    if (isDetached) setSelected((prev) => prev.filter((_id) => _id !== keywordOrIds._id));
  }, [detach, detachMany, selectedAssetsIds]);

  const selectedInDropdown = useMemo(
    () => keywords.map((keyword) => {
      if (!keyword.numberOfAssets || keyword.numberOfAssets === selectedAssetsIds.length) return keyword;
      return null;
    }).filter(Boolean),
    [keywords, selectedAssetsIds],
  );

  const handleDetachSelected = useCallback(async () => {
    if (!isOpened) toggleOpen();
    const keywordIds = [...selected];
    if (keywordIds.length === keywords.length) {
      Logger.log('User', 'KeywordsRemoveAll', {
        source: 'Action Bar',
        keywordCount: keywordIds.length,
        assetCount: selectedAssetsIds.length,
        assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
      });
    } else {
      Logger.log('User', 'KeywordsRemoveSelected', {
        keywordCount: keywordIds.length,
        assetCount: selectedAssetsIds.length,
        keyword: keywords.filter(({ _id }) => keywordIds.includes(_id)).map(({ _id, path }) => `${_id} | ${getKeywordNameFromPath(path)}`).join(' ; '),
        assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
      });
    }
    const isDetached = await detachMany(keywordIds, selectedAssetsIds);
    /** clear selected if detach successful */
    if (isDetached) setSelected([]);
  }, [isOpened, toggleOpen, selected, keywords, detachMany, selectedAssetsIds]);

  const handleClickKeyword = useCallback((keywordId) => {
    /** Navigate to keyword */
    if (changeTree && openedTree !== 'keywords') changeTree('keywords');
    setSearchRoute({
      collectionIds: UtilsCollections.getRootId(),
      keywords: keywordId,
    });
  }, [changeTree, openedTree]);

  const generateKeywords = useCallback(({ locale, relevance, visionProvider } = {}) => {
    if (isMainApp && selectedAssetsIds.length) {
      generate({
        assetIds: selectedAssetsIds,
        locale,
        relevance,
        visionProvider,
      });
    }

    sendEventToIntercom('keywords generated');
  }, [generate, selectedAssetsIds, isMainApp]);

  const actions = useMemo(() => {
    const result = [];
    if (!keywords?.length) return result;

    /** copy to clipboard */
    result.push(
      <Tooltip key="copy" content={selected.length ? 'Copy selected' : 'Copy all'} placement="top">
        <IconButton
          onClick={() => {
            if (!isOpened) toggleOpen();
            const filtered = keywords.filter(({ _id }) => (selected.length ? selected.includes(_id) : true));
            const names = filtered.map(({ path }) => getKeywordNameFromPath(path));
            Logger.log('User', 'KeywordsCopy', {
              source: selected.length === keywords.length || selected.length === 0 ? 'All' : 'Selected',
              keywordCount: filtered.length,
              assetCount: selectedAssetsIds.length,
              assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
              keyword: filtered.map(({ _id, path }) => `${_id} | ${getKeywordNameFromPath(path)}`).join(' ; '),
            });
            copyTextToClipboard(names.join(', '), 'Keywords copied to clipboard');
          }}
          componentProps={{ 'data-testid': 'copySelectedKeywords' }}
        >
          <Copy />
        </IconButton>
      </Tooltip>,
    );

    if (selected.length) {
      /** remove selected */
      result.push(
        <Tooltip key="remove" content="Remove selected" placement="top">
          <IconButton
            onClick={handleDetachSelected}
            componentProps={{ 'data-testid': 'removeSelectedKeywords' }}
          >
            <TrashIcon />
          </IconButton>
        </Tooltip>,
      );
      /** deselect all */
      result.push(
        <Tooltip key="deselect_all" content="Deselect all" placement="top">
          <IconButton
            onClick={() => {
              if (!isOpened) toggleOpen();
              Logger.log('User', 'KeywordsDeselectAll', {
                assetCount: selectedAssetsIds.length,
                assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
                keywordCount: selected.length,
                keyword: keywords.filter(({ _id }) => selected.includes(_id)).map(({ _id, path }) => `${_id} | ${getKeywordNameFromPath(path)}`).join(' ; '),
              });
              setSelected([]);
            }}
            componentProps={{ 'data-testid': 'deselectAllKeywords' }}
          >
            <RegularX />
          </IconButton>
        </Tooltip>,
      );
    } else {
      /** select all */
      result.push(
        <Tooltip key="select_all" content="Select all" placement="top">
          <IconButton
            onClick={() => {
              if (!isOpened) toggleOpen();
              Logger.log('User', 'KeywordsSelect', {
                source: 'Action Bar',
                keywordCount: keywords.length,
                keyword: keywords.map(({ _id, path }) => `${_id} | ${getKeywordNameFromPath(path)}`).join(' ; '),
                assetCount: selectedAssetsIds.length,
                assetId: selectedAssetsIds.length === 1 ? selectedAssetsIds[0] : undefined,
              });
              setSelected(keywords.map((k) => k._id));
            }}
            componentProps={{ 'data-testid': 'selectAllKeywords' }}
          >
            <SelectAll />
          </IconButton>
        </Tooltip>,
      );
    }
    return result;
  }, [handleDetachSelected, isOpened, keywords, selected, selectedAssetsIds, toggleOpen]);

  const titleActions = useMemo(() => {
    const result = [];
    if (isCVEnabled) {
      const handleClick = (event) => {
        event.stopPropagation();
        navigate('/teammates?tab=ai');
      };
      result.push(
        <Tooltip key="CV" content="Controlled Vocabulary is enabled" placement="top">
          <IconButton onClick={handleClick} color="primary">
            <ControlledVocabulary />
          </IconButton>
        </Tooltip>,
      );
    }
    return result;
  }, [isCVEnabled]);

  const dropdownIcon = useCallback(() => {
    if (!isCVEnabled) return null;
    return <Icon><ControlledVocabulary /></Icon>;
  }, [isCVEnabled]);

  const placeholder = useMemo(
    () => (
      <DetailsPlaceholder>
        {l10n.DETAILS.placeholderKeywords(selectedAssetsIds.length)}
      </DetailsPlaceholder>
    ),
    [selectedAssetsIds.length]
  );

  const groups = useMemo(() => {
    const addName = (k) => ({ ...k, name: getKeywordNameFromPath(k.path) });
    const result = {
      ai: {
        title: 'AI keywords',
        Icon: AiKeywords,
        items: [],
      },
      file: {
        title: 'Embedded keywords',
        Icon: Label,
        items: [],
      },
      team: {
        title: 'Team keywords',
        Icon: Label,
        items: [],
      },
    };
    if (selectedAssetsIds.length > 1) {
      /** if selected several assets we have only one group */
      result.team.items = keywords.map(addName);
      return result;
    }

    return keywords.reduce((acc, keyword) => {
      const { attachedBy } = keyword;
      if ([KEYWORDER, FACER].includes(attachedBy)) {
        acc.ai.items.push({ ...keyword, name: getKeywordNameFromPath(keyword.path) });
      } else if ([METADATER, CRAWLER].includes(attachedBy)) {
        acc.file.items.push({ ...keyword, name: getKeywordNameFromPath(keyword.path) });
      } else {
        acc.team.items.push({ ...keyword, name: getKeywordNameFromPath(keyword.path) });
      }
      return acc;
    }, result);
  }, [keywords, selectedAssetsIds]);

  return (
    <DetailsBlock
      dataQa="keywords"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
      showUpgradePlan={!keywordingnIsAvailable}
      actions={actions}
      titleActions={titleActions}
      showAI
    >
      <If condition={!keywords?.length}>
        {placeholder}
      </If>
      <If condition={keywords?.length}>
        <Choose>
          <When condition={selectedAssetsIds.length === 1}>
            {Object.values(groups).map((group) => (
              <If condition={!!group.items.length} key={group.title}>
                <TagsGroup
                  title={group.title}
                  Icon={group.Icon}
                  items={group.items}
                  selected={selected}
                  onRemove={handleDetachKeyword}
                  onSelect={handleSelect}
                  onDeSelect={handleDeSelect}
                  onClick={handleClickKeyword}
                  onClickNumber={handleClickNumber}
                  showNumbers={false}
                  selectedAssetsIds={selectedAssetsIds}
                  testId={`${group.title} group`}
                />
              </If>
            ))}
          </When>
          <Otherwise>
            {Object.values(groups).map((group) => (
              <If condition={!!group.items.length} key={group.title}>
                <TagsGroup
                  Icon={group.Icon}
                  items={group.items}
                  selected={selected}
                  onRemove={handleDetachKeyword}
                  onSelect={handleSelect}
                  onDeSelect={handleDeSelect}
                  onClick={handleClickKeyword}
                  onClickNumber={handleClickNumber}
                  showNumbers
                  selectedAssetsIds={selectedAssetsIds}
                />
              </If>
            ))}

          </Otherwise>
        </Choose>
      </If>
      <KeywordsDropdown
        placeholder={l10n.DROPDOWN.placeholderKeywords}
        placeholderIcon="emptyKeywords"
        icon={dropdownIcon}
        filterText={isCVEnabled ? l10n.DROPDOWN.chooseKeywordsCV : l10n.DROPDOWN.chooseKeywords}
        createText={l10n.DROPDOWN.createKeyword}
        createPlaceholderText={l10n.DROPDOWN.placeholderKeywordsCreate}
        checkedItems={selectedInDropdown}
        onCheckedHandler={handleAttachKeyword}
        onUncheckedHandler={handleDetachKeyword}
        canCreate={permissions.keywordsCanCreate && isCreatableKeywords}
        highlight={highlight}
        highlightAnimationReset={highlightAnimationReset}
        highlightAnimationResetName="keyword"
        readOnly={!permissions.keywordsEditable === true || disabled}
        inProgress={inProgress}
        modifiedField={modifiedField}
        showChips={false}
        showAddButton
      />
      <If condition={isMainApp && permissions.keywordsEditable && permissions.keywordsAutogeneration}>
        <Buttons
          onSubmit={generateKeywords}
          inProgress={generationIsInProgress}
          disabled={!keywordingnIsAvailable}
        />
      </If>
    </DetailsBlock>
  );
}

Keywords.defaultProps = {
  detailsPanelVisibility: {},
  toggleVisibility: Function.prototype,
  blockName: '',
  blockTitle: '',
  isMainApp: true,
  attach: Function.prototype,
  generate: Function.prototype,
  detach: Function.prototype,
  detachMany: Function.prototype,
  changeTree: Function.prototype,
  keywords: [],
  permissions: {},
  highlight: [],
  highlightAnimationReset: Function.prototype,
  modifiedField: null,
  inProgress: false,
  generationIsInProgress: false,
  disabled: false,
  keywordingnIsAvailable: false,
};

Keywords.propTypes = {
  detailsPanelVisibility: object,
  toggleVisibility: func,
  blockName: string,
  blockTitle: string,
  isMainApp: bool,
  attach: func,
  detach: func,
  detachMany: func,
  generate: func,
  keywords: array,
  selectedAssetsIds: arrayOf(string).isRequired,
  permissions: object,
  disabled: bool,
  inProgress: bool,
  generationIsInProgress: bool,
  highlight: arrayOf(string),
  highlightAnimationReset: func,
  changeTree: func,
  modifiedField: object,
  openedTree: string,
  team: object,
  keywordingnIsAvailable: bool,
};

export default Keywords;
