import _remove from 'lodash/remove';

import sdk from '../../../sdk';
import * as UtilsCollections from '../../utils/collections';
import * as UtilsLightboards from '../../utils/lightboards';
import TYPES from '../../action-types';
import * as utils from '../../../shared/utils';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import { showDialog } from '../../../components/dialog';

/**
 * Reorder assets
 * @param {number} indexInsertBefore
 */
const reorder = (indexInsertBefore) => async (dispatch, getAll) => {
  const { items, selectedItems } = getAll().assets;
  const { collectionIds, lightboardId } = getAll().router.location.query;
  const draggedModels = items.filter((item) => selectedItems.includes(item._id));

  const before = items.slice(0, indexInsertBefore);
  const after = items.slice(indexInsertBefore);
  _remove(before, (item) => ~draggedModels.indexOf(item));
  _remove(after, (item) => ~draggedModels.indexOf(item));

  const newItems = before.concat(draggedModels).concat(after);
  const doReorder = async (overwrite) => {
    await sdk.assets.reorder(
      newItems.map((item, index) => ({ _id: item._id, index })),
      collectionIds,
      lightboardId,
      overwrite,
    );
    collectionIds && UtilsCollections.setSortType({ type: 'custom' }, true);
    lightboardId && UtilsLightboards.setSortType({ type: 'custom' }, true);
  };

  const showCurrentCustomOrder = () => {
    collectionIds && UtilsCollections.setSortType({ type: 'custom' });
    lightboardId && UtilsLightboards.setSortType({ type: 'custom' });
  };

  try {
    dispatch({
      type: TYPES.ASSETS.REORDER,
      payload: { items: newItems },
    });
    await doReorder();
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    if (errorStatus === 409) {
      Logger.log('UI', 'ChangeOrderAssetsToCustomDialog');
      showDialog({
        title: localization.DIALOGS.WARNING_CHANGE_ASSETS_ORDER.TITLE,
        text: localization.DIALOGS.WARNING_CHANGE_ASSETS_ORDER.TEXT,
        textBtnCancel: localization.DIALOGS.WARNING_CHANGE_ASSETS_ORDER.CANCEL_TEXT,
        textBtnOk: localization.DIALOGS.WARNING_CHANGE_ASSETS_ORDER.OK_TEXT,
        onOk: () => doReorder(true),
        onCancel: showCurrentCustomOrder,
      });
    } else {
      Logger.error(new Error('Can not reorder images'), { error, showDialog: true }, [
        'showWriteToSupportDialog',
        (error && error.message) || 'NoMessage',
      ]);
    }
  }
};

export default reorder;
