import React, {
  memo, useMemo, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  DotsVertical,
  Download,
} from '@picsio/icons';
import cn from 'classnames';
import {
  Menu, IconButton, Switch,
} from '@picsio/ui';
import TranscribeMenuItem from './TranscribeMenuItem';

const TranscriptMenu = ({
  isDownloadable, isReader, isReaderView, setReaderView, downloadSub,
}) => {
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen((prevValue) => !prevValue), []);

  const handleClickMenu = useCallback((action) => {
    if (typeof action === 'function') {
      action();
    }
    toggleMenu();
  }, [toggleMenu]);

  const controls = useMemo(() => {
    const result = [];

    if (isDownloadable) {
      result.push({
        id: 'menuDownload',
        text: 'Download SRT',
        action: downloadSub,
        icon: <Download />,
      });

      if (isReader) {
        result.push({
          id: 'readerView',
          text: 'Reader view',
          action: setReaderView,
          icon: <Switch checked={isReaderView} onChange={() => {}} />,
        });
      }
    }

    return result;
  }, [isDownloadable, isReader, isReaderView, setReaderView, downloadSub]);

  return (
    <If condition={controls.length}>
      <IconButton
        ref={ref}
        buttonSize="default"
        className={cn('TranscriptMenu__button', { isActive: isMenuOpen })}
        color="default"
        component="button"
        disabled={false}
        id="TranscriptMenuOpener"
        onClick={toggleMenu}
        size="lg"
      >
        <DotsVertical />
      </IconButton>
      <Menu
        target={ref}
        arrow
        padding="s"
        placement="bottom-end"
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        outsideClickListener
      >
        {controls.map((control) => (
          <TranscribeMenuItem key={control.id} {...control} onClick={handleClickMenu} />
        ))}
      </Menu>
    </If>
  );
};

TranscriptMenu.propTypes = {
  isDownloadable: PropTypes.bool.isRequired,
  isReader: PropTypes.bool.isRequired,
  isReaderView: PropTypes.bool.isRequired,
  setReaderView: PropTypes.func.isRequired,
  downloadSub: PropTypes.func.isRequired,
};

export default memo(TranscriptMenu);
