import _replace from 'lodash/replace';
import _lowerFirst from 'lodash/lowerFirst';
import _find from 'lodash/find';
import l18n from '../../../shared/strings';
import Logger from '../../../services/Logger';

const normalizeAddonOptions = (options, defaultFeatureValue) => {
  let normalizedOptions = options;
  if (options) {
    normalizedOptions = options.map((option) => {
      if (option.value < Number(defaultFeatureValue)) {
        return {
          ...option,
          text: `${option.text} ${l18n.BILLING.textMaxGBOptionLabel}`,
          disabled: true,
        };
      }
      return {
        ...option,
        disabled: false,
      };
    });
  }
  return normalizedOptions;
};

const getAddonEnumType = ({
  addon, subscriptionAddons, metadata, subscriptionFeatures,
}) => {
  const { featureName } = addon.metadata;
  const includedSize = Number(metadata[featureName]);

  try {
    const uiOptions = JSON.parse(addon.metadata['ui:options']);

    let defaultFeatureValue = subscriptionAddons[featureName] || 0;
    let currentOption = _find(uiOptions, { value: Number(defaultFeatureValue) });

    /**
     * START:
     * Determines the minimum number of GB storages depending on
     * the subscriptionFeatures.totalSize value for the selected plan
     * */
    if ((featureName === 'maxGB') && !subscriptionAddons[featureName]) {
      const totalSizeBytes = subscriptionFeatures.totalSize;
      const totalSizeGB = (totalSizeBytes / (1024 * 1024 * 1024)).toFixed(2);
      currentOption = uiOptions.find((option) => option.value > (totalSizeGB - includedSize));
      defaultFeatureValue = currentOption?.value || 0;
    }
    /** END */

    return {
      ...addon,
      extraAmount: currentOption?.price || uiOptions[0]?.price || 0,
      extraValue: Number(defaultFeatureValue),
      metadata: {
        ...addon.metadata,
        [featureName]: Number(defaultFeatureValue) + includedSize,
        'ui:options': normalizeAddonOptions(uiOptions, defaultFeatureValue),
      },
    };
  } catch (err) {
    Logger.error(new Error('Cannot parsing JSON ui:options'), { error: err });
    return {
      ...addon,
      'ui:options': [{ text: '', value: '', price: '' }],
    };
  }
};

const getAddonNumberType = ({
  addon, subscriptionAddons, metadata, subscriptionFeatures,
}) => {
  const { featureName } = addon.metadata;

  const countName = featureName.replace('Limit', 'Count');
  const additionalLimit = Number(subscriptionAddons[featureName]);

  const includedLimit = Number(metadata[featureName]);

  const subscriptionFeaturesCount = subscriptionFeatures[countName] || 0;
  let minAddonCount = includedLimit - subscriptionFeaturesCount < 0 ? subscriptionFeaturesCount - includedLimit : 0;
  if (featureName.includes('teammates')) {
    const teammatesCount = subscriptionFeatures.teammatesCountIncludingPending;
    minAddonCount = includedLimit - teammatesCount < 0
      ? teammatesCount - includedLimit
      : 0;
  }
  minAddonCount = additionalLimit || minAddonCount; /** additionalLimit for active plan, minAddonCount - other inactive plans */

  const extraAmount = minAddonCount !== 0 ? minAddonCount * addon.amount : 0;
  const extraValue = minAddonCount; /** extraValue for active plan */

  return {
    ...addon,
    extraAmount,
    extraValue,
    metadata: {
      ...addon.metadata,
      [featureName]: minAddonCount + includedLimit,
    },
  };
};

const getAddonBooleanType = ({ addon, subscriptionAddons }) => {
  const { featureName } = addon.metadata;
  const defaultFeatureValue = subscriptionAddons[featureName] || 'false';
  return {
    ...addon,
    extraAmount: defaultFeatureValue !== 'false' ? addon.amount : 0,
    extraValue: addon?.isPlaceholder ? 'true' : defaultFeatureValue,
    metadata: {
      ...addon.metadata,
      [featureName]: addon?.isPlaceholder ? 'true' : defaultFeatureValue,
    },
  };
};

const getPlanAddons = ({
  addons, subscriptionAddons, metadata, subscriptionFeatures,
}) => addons.map((addon) => {
  if (addon.metadata['ui:featureType'] === 'enum') {
    return getAddonEnumType({
      addon, subscriptionAddons, metadata, subscriptionFeatures,
    });
  }
  if (addon.metadata['ui:featureType'] === 'number') {
    return getAddonNumberType({
      addon, subscriptionAddons, metadata, subscriptionFeatures,
    });
  }
  if (addon.metadata['ui:featureType'] === 'boolean') {
    return getAddonBooleanType({ addon, subscriptionAddons });
  }
  if (addon.metadata.type === 'tiered_addon') {
    const { featureName } = addon.metadata;
    const tierPaid = addon.tiers.find((tier) => tier.id === subscriptionAddons[featureName]?.id);
    const defaultFeatureValue = tierPaid ? `${subscriptionAddons[featureName]?.id}===true` : 'false';
    return {
      ...addon,
      metadata: {
        ...addon.metadata,
        [featureName]: addon?.isPlaceholder ? 'true' : defaultFeatureValue,
      },
    };
  }
  return addon;
});

const getPlans = ({
  productPlans, subscriptionAddons, subscriptionFeatures, activePlanId,
}) => productPlans.reduce((result, plan) => {
  const {
    id, nickname, interval, amount, metadata, discountedAmount, addons,
  } = plan;
  const isActivePlan = activePlanId === id;

  const { externalStorage, 'ui:offer': additionalDescription } = metadata;

  return {
    ...result,
    [interval]: {
      id,
      nickname,
      interval,
      amount,
      discountedAmount,
      metadata,
      addons: addons ? getPlanAddons({
        addons, subscriptionAddons: isActivePlan ? subscriptionAddons : {}, metadata, subscriptionFeatures,
      }) : [],
      externalStorage,
      additionalDescription,
    },
  };
}, {});

function normalizeProducts({
  products, subscriptionAddons, subscriptionFeatures, activePlanId, picsioStorage = false,
}) {
  const plans = products.reduce((acc, product) => {
    const {
      id: planId, name: productName, metadata, plans: productPlans,
    } = product;

    if (productName.startsWith('Pay As You Go')) return acc;

    const name = _replace(productName, ' 2019', '');
    const id = _lowerFirst(name);
    const description = picsioStorage ? `${productPlans[0].metadata['ui:description']}<br>${productPlans[0].metadata['ui:descriptionStorage']}` : productPlans[0].metadata['ui:description'];

    const item = {
      planId,
      id,
      selectedPlan: false,
      name,
      metadata,
      description,
      plans: getPlans({
        productPlans, subscriptionAddons, subscriptionFeatures, activePlanId,
      }),
    };
    return [...acc, item];
  }, []);

  return {
    plans,
  };
}

export default normalizeProducts;
