import { bindActionCreators } from 'redux';

import UiBlocker from '../../services/UiBlocker';
import store from '../../store';
import * as actions from '../../store/actions/downloadList';
import localization from '../../shared/strings';
import { showErrorDialog } from '../../components/dialog';
import getZipUrls from '../assets/getZipUrls';

const downloadListActions = bindActionCreators({ ...actions }, store.dispatch);

async function downloadAssetWithWatermark(data, storageType, assets) {
  let urls;
  try {
    UiBlocker.block('Preparing');
    const { data: res } = await getZipUrls(data, storageType);
    urls = res;
  } catch (err) {
    showErrorDialog(localization.DOWNLOADDIALOG.errorCantCreateArchive);
  }
  UiBlocker.unblock();

  if (urls) {
    const pollUrl = urls.zipUrl.replace(/\.zip$/, '.json');

    if (data.isArchive) {
      downloadListActions.addToDownloadList([
        {
          name: urls.zipUrl.replace(/(.*|\/)\//, ''),
          mimeType: 'application/zip',
          url: urls.zipUrl,
          pollUrl,
          jobHash: urls.jobHash,
        },
      ]);
    } else {
      const listFiles = assets.map((asset) => ({
        _id: asset._id,
        name: urls.fileUrls[asset._id].replace(/(.*|\/)\//, ''),
        mimeType: 'original',
        url: urls.fileUrls[asset._id],
        pollUrl,
        jobHash: urls.jobHash,
      }));

      downloadListActions.addToDownloadList(listFiles);
    }
  }
}

export default downloadAssetWithWatermark;
