import React from 'react'; // eslint-disable-line
import {
  bool, func, array, string, object,
} from 'prop-types';

/** Store */
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from '../../store';
import { getFaces } from '../../store/faces/actions';

import Dropdown from '../dropdown';

let allFacesCached = [];
let items = [];
const FacesDropdown = (props) => {
  const { allFaces } = props;
  /** update items if faces changed */
  if (allFacesCached !== allFaces) {
    allFacesCached = allFaces;
    if (allFaces?.length) {
      items = allFaces.map((face) => ({ ...face, title: face.name, url: face.avatar || (face.isAvatarUrlReady && `/faces/${face._id}/avatar`) }));
    }
  }
  const newProps = {
    ...props,
    items,
    inProgress: props.inProgress || props.isBusy,
    createHandler: props.canCreate ? props.actions.add : null,
  };
  return <Dropdown visibleItemsLength={8} type="face" {...newProps} />;
};

FacesDropdown.propTypes = {
  hideHeading: bool,
  disabled: bool,
  canCreate: bool,
  checkedItems: array,
  onCheckedHandler: func,
  onUncheckedHandler: func,
  icon: string,
  placeholder: string,
  placeholderIcon: string,
  filterText: string,
  additionalClass: string,
  createText: string,
  createPlaceholderText: string,
  onItemClickHandler: func,
  onBlur: func,
  isOnlyCreate: bool,
  inProgress: bool,
  modifiedField: object,
};

const ConnectedDropdown = connect(
  (state) => ({
    allFaces: state.faces.all,
    isItemsLoaded: state.faces.isLoaded,
    isBusy: state.faces.isBusy,
  }),
  (dispatch) => ({ actions: bindActionCreators({ getFaces }, dispatch) }),
)(FacesDropdown);

export default (props) => (
  <Provider store={store}>
    <ConnectedDropdown {...props} />
  </Provider>
);
