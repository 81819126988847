import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import {
  object, bool, string, func, oneOfType,
} from 'prop-types';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Button } from '@picsio/ui';
import { outy } from '../../shared/utils';
import Logger from '../../services/Logger';
import Tooltip from '../Tooltip';
import ua from '../../ua';

const Opener = ({
  openerText,
  hideOpenerWhenOpen,
  hideOnClickOutside,
  children,
  tooltip,
  parentHandler,
  eventName,
  disabled,
  additionalClassName,
  noCustomFields,
  inSearch,
  ...otherProps
}) => {
  const outsideClickRef = useRef();
  const [showDrop, setShowDrop] = useState(false);
  const [showCustomFieldsTitle, setShowCustomFieldsTitle] = useState(true);
  const dropRef = useRef();

  useEffect(() => {
    if (!hideOnClickOutside) return;
    if (showDrop) {
      outsideClickRef.current = outy(dropRef.current, ['click'], handleOutsideClick);
    } else if (outsideClickRef.current) {
      outsideClickRef.current.remove();
    }
  }, [showDrop]);

  const buttonProps = useMemo(
    () => (otherProps['data-testid'] ? { 'data-testid': otherProps['data-testid'] } : {}),
    [otherProps],
  );

  const handleButtonClick = () => {
    Logger.log('User', eventName || 'OpenerClick');
    setShowDrop(!showDrop);
    setShowCustomFieldsTitle(!showCustomFieldsTitle);
    parentHandler(!showDrop);
  };

  const handleOutsideClick = () => {
    setTimeout(() => {
      setShowDrop(!showDrop);
      setShowCustomFieldsTitle(!showCustomFieldsTitle);
      parentHandler(!showDrop);
    }, 0);
  };

  const OpenerLink = (
    <Tooltip content={tooltip} placement="top">
      <If condition={showCustomFieldsTitle && noCustomFields && inSearch && !ua.browser.isNotDesktop()}>
        <div className="customFieldsTitle">Custom fields</div>
      </If>
      <Button
        variant="text"
        color="primary"
        onClick={handleButtonClick}
        disabled={disabled}
        className={cn({ [additionalClassName]: additionalClassName, removeLeftMargin: showCustomFieldsTitle && noCustomFields && inSearch })}
        componentProps={buttonProps}
      >
        {openerText}
      </Button>
    </Tooltip>
  );

  const ChildElementWithRef = () => {
    const childElement = React.Children.only(children);

    return React.cloneElement(childElement, { ref: dropRef });
  };

  return (
    <>
      <Choose>
        <When condition={hideOpenerWhenOpen}>
          <CSSTransition in={!showDrop} timeout={300} classNames="fade">
            <><If condition={!showDrop}>{OpenerLink}</If></>
          </CSSTransition>
        </When>
        <Otherwise>{OpenerLink}</Otherwise>
      </Choose>

      <CSSTransition in={showDrop} timeout={300} classNames="fade">
        <><If condition={showDrop}>{ChildElementWithRef()}</If></>
      </CSSTransition>
    </>
  );
};

Opener.defaultProps = {
  hideOpenerWhenOpen: false,
  hideOnClickOutside: true,
  tooltip: null,
  disabled: false,
  eventName: '',
  additionalClassName: '',
  parentHandler: () => { },
  noCustomFields: true,
  inSearch: false,
  'data-testid': null,
};

Opener.propTypes = {
  openerText: string,
  children: object,
  disabled: bool,
  hideOpenerWhenOpen: bool,
  hideOnClickOutside: bool,
  tooltip: oneOfType([string, bool]),
  parentHandler: func,
  eventName: string,
  additionalClassName: string,
  noCustomFields: bool,
  inSearch: bool,
  'data-testid': string,
};

export default Opener;
