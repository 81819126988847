import TYPES from '../../action-types';

/**
 * Change stars on assets immediately
 * @param {string[]} ids
 * @param {string} value
 */
const changedRating = (ids, value, userId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.CHANGE_STARS.COMPLETE,
    payload: {
      ids,
      keys: ['rating'],
      values: [value],
      eventType: 'rating',
      userId,
    },
  });
};

export default changedRating;
