import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';
import renameCollectionAssetsAction from '../assets/renameCollection';
import setMovingAssetsAction from '../assets/setMoving';
import Toast from '../../../components/Toast';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

/**
 * Rename collection
 * @param {Object} collection
 * @param {string} newName
 */
function renameCollection(collection, newName) {
  return async (dispatch, getAll) => {
    const assets = getAll().assets.items;
    const { collectionIds } = getAll().router.location.query;
    const assetIds = collectionIds === collection._id ? assets.map((a) => a._id) : [];
    function handleError(error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      const dialogTitle = 'Error';
      let dialogText = localization.UNABLE_TO_RENAME_COLLECTION;
      if (errorStatus === 403) {
        dialogText = localization.NO_PERMISSION_TO_ACCESS;
      }

      dispatch({
        type: TYPES.COLLECTIONS.RENAME.FAILED,
        payload: {
          collectionID: collection._id,
          error: localization.UNABLE_TO_RENAME_COLLECTION,
        },
        error,
      });
      if (error) {
        Logger.error(new Error('Can not rename collection'), { error }, [
          'RenameCollectionFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }
      if (errorSubcode === 'CollectionAlreadyExistsApiError') {
        return Toast(localization.TOAST.WARNING_COLLECTION_ALREADY_EXISTS.TEXT, { type: 'error' });
      } if (errorSubcode === 'HasCopyingAssetsError' || errorSubcode === 'HasMovingAssetsError') {
        /** if collection is busy */
        return Toast(localization.TOAST.WARNING_BUSY_COLLECTION.TEXT, { type: 'error' });
      }
      return showDialog({
        title: dialogTitle,
        text: dialogText,
        textBtnCancel: null,
      });
    }

    dispatch({
      type: TYPES.COLLECTIONS.RENAME.START,
      payload: { collectionID: collection._id },
    });

    try {
      const { data } = await sdk.collections.rename(collection._id, newName);

      if (data.tag && data.tag.name !== undefined) {
        dispatch({
          type: TYPES.COLLECTIONS.RENAME.COMPLETE,
          payload: { collectionID: collection._id, newName },
        });
        renameCollectionAssetsAction(collection._id, newName)(dispatch);
        if (data.queued) {
          setMovingAssetsAction(assetIds, 'waiting')(dispatch);
          Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
        }
      } else {
        handleError();
      }
    } catch (err) {
      handleError(err);
    }
  };
}

export default renameCollection;
