import { createAsyncThunk } from '@reduxjs/toolkit';
import { reloadApp, reloadPage } from '../../../helpers/history';
import { showDialog, showErrorDialog } from '../../../components/dialog';

import {
  showValidateCardForm,
} from '../../helpers/billing';

const subscribe = createAsyncThunk(
  'billing/subscribe',
  async (
    payload,
    {
      getState, rejectWithValue, extra: {
        sdk, utils, localization, UiBlocker, Logger,
      },
    },
  ) => {
    Logger.log('User', 'SettingsBillingChangeSubscribe');
    const { plan, totalAccount, ...data } = payload;
    data.addons = totalAccount.addons;
    const {
      name: planName, amount,
    } = plan;
    const { sum } = totalAccount;
    const stripePlan = {
      panelLabel: localization.BILLING.checkoutSubscribe,
      name: planName,
      amount,
    };
    const { user } = getState();
    const {
      customer: { card },
    } = user;

    try {
      UiBlocker.block(localization.BILLING.textPaymentLoadingDetails);

      if (!card) {
        const { id: tokenId } = await showValidateCardForm({
          user,
          plan: stripePlan,
          amount: sum * 100,
        });

        if (tokenId) {
          data.tokenId = tokenId;
        } else {
          throw new Error('tokenId is undefined');
        }
      }

      UiBlocker.block(localization.BILLING.textPaymentFinishing);

      const { data: subscription } = await sdk.billing.subscribe(data);

      Logger.log('User', 'SubscriptionChanged', planName);

      const userDialogueMessage = localization.BILLING.textTextSubcriptionUpdated;
      Logger.log('UI', 'ChangeSubscribeSuccessDialog', { userDialogueMessage });
      showDialog({
        title: localization.BILLING.textTitleThanks,
        text: userDialogueMessage,
        textBtnCancel: null,
        textBtnOk: localization.BILLING.textBtnGoToLibrary,
        onOk: () => reloadApp(),
        onCancel: () => reloadPage('/billing?tab=overview'),
      });

      UiBlocker.block(localization.BILLING.textDetailsSaving);

      const result = {
        subscription,
      };

      return result;
    } catch (err) {
      const errorSubcode = utils.getDataFromResponceError(err, 'subcode');
      const errorMessage = utils.getDataFromResponceError(err, 'msg');
      const errorReason = utils.getDataFromResponceError(err, 'reason');
      let userDialogueMessage = '';
      if (errorSubcode === 'CardError') {
        Logger.log('User', 'ChangeSubscribeFailedDialog', { userDialogueMessage });
        showErrorDialog(localization.BILLING.stripeCardErrors[errorReason] || localization.BILLING.stripeCardErrors.default, errorMessage);
      }
      if (errorSubcode === 'NextPlanLimitsError') {
        userDialogueMessage = localization.BILLING.DIALOG_LIMITS_EXCEEDED.text;
        const dialogData = {
          title: localization.BILLING.DIALOG_LIMITS_EXCEEDED.title,
          text: userDialogueMessage,
          textBtnCancel: localization.DIALOGS.btnOk,
          textBtnOk: null,
        };
        if (user?.subscriptionFeatures?.chatSupport) {
          dialogData.textBtnCancel = localization.DIALOGS.btnCancel;
          dialogData.textBtnOk = localization.BILLING.DIALOG_LIMITS_EXCEEDED.btnOk;
          dialogData.onOk = () => window.dispatchEvent(new Event('toolbar:ui:liveSupport'));
          dialogData.id = 'itemliveSupport';
        }
        Logger.log('User', 'ChangeSubscribeFailedDialog', { userDialogueMessage });
        showDialog(dialogData);
      }
      if (err.message === 'tokenId is undefined') {
        userDialogueMessage = localization.BILLING.textCardCantCheck;
        Logger.log('User', 'ChangeSubscribeFailedDialog', { userDialogueMessage });
        showErrorDialog(userDialogueMessage);
      }
      Logger.error(new Error('Subscribe to plan failed'), { error: err }, [
        'SubscribeBillingPlanFailed',
        { errorMessage: (err && err.message) || 'NoMessage', userDialogueMessage },
      ]);
      return rejectWithValue(err);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default subscribe;
