/**
 * Sort faces
 * @param {array} faces
 */

export default function sortFaces(faces) {
  return faces.sort((a, b) => {
    if (a.name !== 'Unnamed face' && b.name === 'Unnamed face') {
      return -1;
    }
    if (a.name === 'Unnamed face' && b.name !== 'Unnamed face') {
      return 1;
    }
    return 0;
  });
}
