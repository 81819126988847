import TYPES from '../../action-types';

/**
 * Remove all keywords - upload new dictionary
 */
const removeAllKeywords = () => (dispatch) => {
  dispatch({ type: TYPES.ASSETS.REMOVE_ALL_KEYWORDS });
};

export default removeAllKeywords;
