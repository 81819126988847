import React, { useState, useCallback, useEffect } from 'react';
import { TreeCollapse, Tree } from '@picsio/ui';
import { useSelector } from 'react-redux';
import {
  any, arrayOf, func, objectOf, string,
} from 'prop-types';
import { LocalStorage } from '../../shared/utils';

const KEY_IN_LOCAL_STORAGE = 'picsio.tagsTree.favorites.open';

let tempExpanded = [];

export default function FavoritesTree({
  renderNode, selected, onNodeSelect, titleProps,
}) {
  const isLoaded = useSelector((state) => state.collections.isLoaded);
  const favorites = useSelector((state) => state.collections.search?.collections?.favorites || state.collections.collections?.favorites);
  const [expanded, setExpanded] = useState(tempExpanded);
  const [isOpen, setOpen] = useState(LocalStorage.get(KEY_IN_LOCAL_STORAGE) === null ? true : LocalStorage.get(KEY_IN_LOCAL_STORAGE));

  useEffect(() => {
    if (!isLoaded) setExpanded([]);
  }, [isLoaded]);

  const toggleOpen = useCallback(() => {
    setOpen((prev) => {
      LocalStorage.set(KEY_IN_LOCAL_STORAGE, !prev);
      return !prev;
    });
  }, [setOpen]);

  const handleToggleChild = useCallback((collectionId) => {
    if (expanded.includes(collectionId)) setExpanded((prev) => prev.filter((id) => id !== collectionId));
    else setExpanded((prev) => [...prev, collectionId]);
  }, [expanded, setExpanded]);

  useEffect(() => {
    tempExpanded = [...expanded];
  }, [expanded]);

  /** if no favorites */
  if (!favorites?.nodes?.length) return null;

  /** className added for QA */
  return (
    <TreeCollapse
      className="collectionsFavorites"
      title={favorites.name || 'Loading...'}
      isOpen={isOpen}
      {...titleProps}
      selected={selected.includes(favorites._id)}
      onClick={toggleOpen}
    >
      <Tree
        nodes={favorites.nodes || []}
        renderNode={renderNode}
        onNodeToggle={handleToggleChild}
        onNodeSelect={onNodeSelect}
        controlled
        expanded={expanded}
        selected={selected}
      />
    </TreeCollapse>

  );
}

FavoritesTree.propTypes = {
  renderNode: func.isRequired,
  titleProps: objectOf(any),
  selected: arrayOf(string),
  onNodeSelect: func,
};
