import React from 'react';
/** Store */
import { Provider, connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { bindActionCreators } from 'redux';
import CONSTANTS from '@picsio/db/src/constants';
import picsioConfig from '../../../../../config';
import store from '../../store';
import WithSkeletonTheme from '../WithSkeletonTheme';
import { findCollection, getParent } from '../../store/helpers/collections';
import * as collectionsActions from '../../store/actions/collections';
import * as keywordsListActions from '../../store/keywordsList/actions';
import BreadcrumbsItem from './BreadcrumbsItem';
import { findCollectionsPathToId } from '../../store/actions/helpers/archive';
import { setSearchRoute, isRouteSearch } from '../../helpers/history';

const TYPES = {
  TAGS: 'collectionIds',
  LIGHTBOARDS: 'lightboardId',
  INBOXES: 'inboxId',
  KEYWORDS: 'keywords',
  ARCHIVE: 'archive',
};

class Breadcrumbs extends React.Component {
  state = {
    type: TYPES.TAGS,
    items: [],
  };

  componentDidMount() {
    this.handleRouteChange();
  }

  componentDidUpdate(prevProps) {
    if (isRouteSearch() && this.props.location.key !== prevProps.location.key) {
      this.handleRouteChange();
    }
    const { query: searchQuery } = this.props.location;

    const {
      archived, collectionIds, keywords,
    } = searchQuery;

    if (keywords
      && collectionIds
      && this.props.keywordsList.length > 0
      && prevProps.keywordsList !== this.props.keywordsList
      && prevProps.treeKeywords !== this.props.treeKeywords
    ) {
      this.handleRouteChange();
      return;
    }
    // typeof collectionIds === 'string' means that we have selected only one collection
    // otherwise collectionIds will be array of strings
    if (
      (collectionIds && !archived && typeof collectionIds === 'string')
      && this.props.collectionsIsLoaded
      && (
        prevProps.collectionsMainTree.my.nodes !== this.props.collectionsMainTree.my.nodes
        || prevProps.collectionsMainTree.my.name !== this.props.collectionsMainTree.my.name
      )
    ) {
      // on Proofing we don't have collectionIds on load, so we need to get it from collections.my._id
      const collectionId = collectionIds || this.props.collectionsMainTree.my?._id;
      this.buildByCollections(collectionId, this.props.collectionsMainTree);
      return;
    }

    if (collectionIds && archived && prevProps.archive.collections !== this.props.archive.collections) {
      this.buildByArchive(collectionIds);
    }
  }

  handleRouteChange = async () => {
    const {
      location, collectionsMainTree, keywordsList, treeKeywords,
    } = this.props;
    const { query: searchQuery } = location;
    const rootId = collectionsMainTree?.my?._id;
    const {
      collectionIds, lightboardId, inboxId, keywords, archived,
    } = searchQuery;

    if (!this.rootCollectionID) {
      this.rootCollectionID = collectionsMainTree && rootId;
    }

    if (collectionIds && collectionIds === this.rootCollectionID && keywords && Array.isArray(keywords)) {
      return this.buildByKeywords(keywordsList, treeKeywords, keywords[0]);
    }
    if (lightboardId) return this.buildByLightboards(lightboardId);
    if (inboxId) return this.buildByInboxes(inboxId);
    // typeof collectionIds === 'string' means that we have selected only one collection
    // otherwise collectionIds will be array of strings
    if (collectionIds && !archived && typeof collectionIds === 'string') return this.buildByCollections(collectionIds, collectionsMainTree);
    if (collectionIds && archived) {
      return this.buildByArchive(collectionIds);
    }

    if ((!collectionIds && !inboxId && !lightboardId) || (Array.isArray(collectionIds) && collectionIds.length > 1)) {
      return this.buildByCollections(rootId, collectionsMainTree);
    }

    if (picsioConfig.isProofing) {
      return this.buildByProofing();
    }
  };

  buildByProofing = () => {
    const rootId = this.props.collectionsMainTree?.my?._id;
    if (!this.rootCollectionID) {
      this.rootCollectionID = rootId;
    }
    this.buildByCollections(rootId, this.props.collectionsMainTree);
  }

  buildByArchive = (collectionId) => {
    const {
      archive: { collections },
    } = this.props;
    const { list } = findCollectionsPathToId(collections, collectionId);
    let items = list.map(({ _id, name }) => ({ _id, title: name }));
    if (!items.length) {
      items = [{
        _id: this.props.archive.collections[0]?._id,
        title: 'Archive',
      }];
    }
    this.setState({ type: TYPES.ARCHIVE, items });
  };

  buildByCollections = (currentCollectionId, collections) => {
    const list = [];
    let collection = findCollection(collections, 'my', { _id: currentCollectionId });
    if (collections && collections.my && !collection) {
      this.props.collectionsActions.getChildren(collections.my._id, { currentCollectionId });
      return;
    }
    while (collection) {
      list.unshift(collection);
      collection = getParent(collections, 'my', { _id: collection._id });
    }

    if (list.length > 0) {
      // name shouldn't be here, use path instead. API will stop pass name in future
      const items = list.map((item) => ({ title: item.name, _id: item._id }));
      this.setState({ type: TYPES.TAGS, items });
    }
  };

  buildByLightboards = (lightboardId) => {
    const lightboardTree = this.props.lightboards.lightboards;
    const lightboard = lightboardTree.find((lb) => lb._id === lightboardId);
    const items = [
      {
        _id: null,
        title: 'Lightboards',
      },
      {
        _id: lightboardId,
        title: lightboard.path.split(CONSTANTS.NEW_PATH_DELIMITER).pop(),
      },
    ];
    this.setState({ items, type: TYPES.LIGHTBOARDS });
  };

  buildByInboxes = (inboxId) => {
    const inboxesTree = this.props.inboxes.inboxes;
    const inbox = inboxesTree.find((lb) => lb._id === inboxId);
    const items = [
      {
        _id: null,
        title: 'Inboxes',
      },
      {
        _id: inboxId,
        title: inbox.name,
      },
    ];
    this.setState({ items, type: TYPES.INBOXES });
  };

  buildByKeywords = async (keywordsList, treeKeywords, keywordID) => {
    const items = [{ _id: null, title: 'Keywords' }];
    let keyword = keywordsList.find(({ _id }) => _id === keywordID) || treeKeywords.find(({ _id }) => _id === keywordID);
    if (!keyword) {
      const { payload } = this.props.keywordsListActions.getByIds([keywordID]);
      [keyword] = (payload || [{}]);
    }
    if (keyword?.path) {
      items.push(...keyword.path
        .split(CONSTANTS.NEW_PATH_DELIMITER)
        .filter(Boolean)
        .map((name) => ({ _id: null, title: name })));
    }
    this.setState({ items, type: TYPES.KEYWORDS });
  };

  /**
   * Click on item
   * @param {string} id
   */
  handleItemClick = (id) => {
    if (!id) return;
    const { type } = this.state;

    switch (type) {
    case TYPES.TAGS:
    case TYPES.LIGHTBOARDS:
      setSearchRoute({ [type]: id });
      break;

    case TYPES.KEYWORDS:
      setSearchRoute({ collectionIds: this.rootCollectionID, [type]: id });
      break;

    case TYPES.ARCHIVE:
      setSearchRoute({ collectionIds: id, archived: true });
      break;

    default:
      break;
    }
  };

  render() {
    const { state } = this;
    return (
      <div className="breadCrumbs" data-testid="breadcrumbs">
        <Choose>
          <When condition={state.items.length > 0}>
            <ul>
              {state.items.map((item) => (
                <BreadcrumbsItem key={item._id || item.title} onClick={this.handleItemClick} {...item} />
              ))}
            </ul>
          </When>
          <Otherwise>
            <WithSkeletonTheme>
              <ul>
                <li><span><Skeleton width={98} /></span></li>
                <li><span><Skeleton width={70} /></span></li>
              </ul>
            </WithSkeletonTheme>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

const ConnectedBreadCrumbs = connect(
  (state) => ({
    location: state.router.location,
    treeKeywords: state.keywords.all,
    keywordsList: state.keywordsList.items,
    collectionsMainTree: state.collections.collections,
    collectionsIsLoaded: state.collections.isLoaded,
    lightboards: state.lightboards,
    inboxes: state.inboxes,
    archive: state.archive,
  }),
  (dispatch) => ({
    collectionsActions: bindActionCreators(collectionsActions, dispatch),
    keywordsListActions: bindActionCreators(keywordsListActions, dispatch),
  }),
)(Breadcrumbs);

export default () => (
  <Provider store={store}>
    <ConnectedBreadCrumbs />
  </Provider>
);
