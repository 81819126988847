import eventsWithThumbnails from './eventsWithThumbnails';

/**
 * Set notifications asset thumnail
 * @param {Object[]} items
 * @param {Object[]} thumbnails
 * @returns {Object} items
 */
const setThumbnails = (items, thumbnails) => items.map((item, index) => {
  let thumbnail = null;
  if (item.data) {
    let _id;
    // this check needs when we merge events and we need to set thumb of the first asset
    if (index === 0 && thumbnails.length === 1 && item.data.assets) {
      if (item.data.assets.some((asset) => asset._id === thumbnails[0]._id)) {
        _id = thumbnails[0]._id;
      }
    } else {
      _id = (item.data.asset && item.data.asset._id) || (item.data.assets && item.data.assets.length && item.data.assets[0]._id);
    }

    thumbnail = eventsWithThumbnails.includes(item.type)
      ? thumbnails.find((thumb) => (thumb._id === _id ? thumb : false))
      : undefined;
  }
  return thumbnail
    ? {
      ...item,
      thumbnail: { url: thumbnail.thumbnailLink, isLoading: false, trashed: thumbnail.trashed },
    }
    : item;
});

export default setThumbnails;
