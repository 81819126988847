/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createPreset = createAsyncThunk(
  'websiteSettings/createPreset',
  async ({
    name, settings, imageData, isPublished, updateWebsiteSettings,
  }, {
    extra: {
      UiBlocker, sdk, utils, Logger, showErrorDialog,
    },
  }) => {
    try {
      UiBlocker.block('Saving preset...');
      const { data } = await sdk.websiteSettingsPresets.create(name, settings);
      Logger.log('User', 'WebsitePresetCreated', { preset_name: data.name, preset_id: data._id });
      if (isPublished) {
        if (settings?.avatarUrl) {
          const { data: { url } } = await sdk.websiteSettingsPresets.uploadAvatar(data._id, settings?.avatarUrl);
          data.settings.avatarUrl = url;
        }
        if (settings?.backgroundLoginPageUrl) {
          const { data: { url } } = await sdk.websiteSettingsPresets.uploadBackground(data._id, settings?.backgroundLoginPageUrl);
          data.settings.backgroundLoginPageUrl = url;
        }
        if (settings?.thumbnailUrl) {
          const { data: { url } } = await sdk.websiteSettingsPresets.uploadThumbnail(data._id, settings?.thumbnailUrl);
          data.settings.thumbnailUrl = url;
        }
        if (settings?.logoUrl) {
          const { data: { url } } = await sdk.websiteSettingsPresets.uploadLogo(data._id, settings?.logoUrl);
          data.settings.logoUrl = url;
        }
      } else if (imageData) {
        await Promise.all(Object.keys(imageData).map(async (imageName) => {
          const { file } = imageData[imageName];
          if (imageName === 'avatarUrl') {
            const { data: { url } } = await sdk.websiteSettingsPresets.uploadAvatar(data._id, file).promise;
            data.settings.avatarUrl = url;
          }
          if (imageName === 'backgroundLoginPageUrl') {
            const { data: { url } } = await sdk.websiteSettingsPresets.uploadBackground(data._id, file).promise;
            data.settings.backgroundLoginPageUrl = url;
          }
          if (imageName === 'thumbnailUrl') {
            const { data: { url } } = await sdk.websiteSettingsPresets.uploadThumbnail(data._id, file).promise;
            data.settings.thumbnailUrl = url;
          }
          if (imageName === 'logoUrl') {
            const { data: { url } } = await sdk.websiteSettingsPresets.uploadLogo(data._id, file).promise;
            data.settings.logoUrl = url;
          }
        }));
        updateWebsiteSettings(data.settings);
      }
      return data;
    } catch (error) {
      Logger.log('User', 'WebsitePresetNotCreated');
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not be create preset';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error preset creating'), { error }, [
        'CreateWebsitePresetFailed',
        errorMessage || 'NoMessage',
      ]);
      throw error;
    } finally {
      UiBlocker.unblock();
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      createPreset.fulfilled,
      (state, { payload }) => {
        state.presets.unshift(payload);
        state.error = null;
      },
    )
    .addCase(
      createPreset.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
