/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { facesMakeTree } from '../helpers/faces';

export const getFaces = createAsyncThunk(
  'faces/getFaces',
  // eslint-disable-next-line consistent-return
  async (_, {
    extra: {
      Logger, sdk,
    },
  }) => {
    try {
      const { data: faces } = await sdk.faces.getAll();
      return {
        faces,
      };
    } catch (error) {
      Logger.error(new Error('Error faces fetching'), { error }, [
        'FetchFacesFailed',
        'Faces can not be fetched from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getFaces.pending,
      (state) => {
        state.isLoaded = false;
        state.isLoading = true;
        state.error = null;
        state.tree = null;
      },
    ).addCase(
      getFaces.fulfilled,
      (state, { payload: { faces } }) => {
        const fixedFavorite = faces.map((face) => ({ ...face, isFavorite: face.isFavorite, isAvatarUrlReady: true }));
        state.all = fixedFavorite;
        state.isLoaded = true;
        state.isLoading = false;
        state.error = null;
        state.tree = facesMakeTree(fixedFavorite, state.sortType);
      },
    ).addCase(
      getFaces.rejected,
      (state, { error }) => {
        state.all = [];
        state.isLoaded = false;
        state.isLoading = false;
        state.error = error;
      },
    );
};
