import React, {
  useState, useCallback, useMemo, useEffect, useRef,
} from 'react';
import _union from 'lodash/union';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { Tree } from '@picsio/ui';
import { useSelector } from 'react-redux';
import {
  any, arrayOf, func, shape, string,
} from 'prop-types';
import { useMount } from 'react-use';
import { LocalStorage } from '../../shared/utils';
import { findCollection, getParent } from '../../store/helpers/collections';
import picsioConfig from '../../../../../config';

const KEY_IN_LOCAL_STORAGE = 'picsio.tagsTree.my.open';
const unfoldAllCollections = window.websiteConfig?.unfoldAllCollections;
const { isProofing } = picsioConfig;

const getInitialExpanded = ((data) => {
  /** Unfold all collections on proofing if unfoldAllCollections is true */
  if (isProofing && unfoldAllCollections) {
    const result = [data._id];
    const getChildrenNodeIds = (nodes) => {
      nodes.forEach((node) => {
        if (node.nodes && node.nodes.length) {
          result.push(node._id);
          getChildrenNodeIds(node.nodes);
        }
      });
    };
    getChildrenNodeIds(data.nodes);
    return result;
  }
  /** Open My Collections by default */
  if (data?._id && (LocalStorage.get(KEY_IN_LOCAL_STORAGE) || LocalStorage.get(KEY_IN_LOCAL_STORAGE) === null)) return [data._id];
  return [];
});

let tempExpanded = [];

export default function MainTree({
  data, renderNode, selected = [], onNodeSelect = () => {},
}) {
  const prevIsLoaded = useRef(false);
  const prevIsFetching = useRef(false);
  const prevChildrenLength = useRef(0);
  const prevIdsFromQuery = useRef([]);
  const isLoaded = useSelector((state) => state.collections.isLoaded);
  const [expanded, setExpanded] = useState(tempExpanded);
  const search = useSelector((state) => state.collections.search?.collections?.my);
  const idsFromQuery = useSelector((state) => state.router.location?.query?.collectionIds);

  const getExpandedIds = useCallback((ids) => {
    const tree = { my: data };
    const expandedIds = [];
    const foundCollections = ids.map((collectionId) => findCollection(tree, 'my', { _id: collectionId }));

    foundCollections.forEach((foundCollection) => {
      while (foundCollection) {
        if (foundCollection.nodes?.length) expandedIds.push(foundCollection._id);
        foundCollection = getParent(tree, 'my', { _id: foundCollection._id }); // eslint-disable-line
      }
    });

    return expandedIds;
  }, [data]);

  const checkOpenedCollections = useCallback((_ids) => {
    const ids = Array.isArray(_ids) ? _ids : [_ids];
    const parentsIds = getExpandedIds(ids);
    setExpanded((prevExpanded) => _union(prevExpanded, parentsIds));
  }, [getExpandedIds]);

  useEffect(() => {
    /** Update expanded if changed loaded state OR just loaded children for root collection OR changed selected collections */
    if (isLoaded && !data.isFetching && (
      !prevIsLoaded.current
      || prevIsFetching.current
      || (!prevChildrenLength.current && data?.nodes?.length)
      || !_isEqual(prevIdsFromQuery.current, idsFromQuery)
    )) {
      const ex = expanded?.length ? [...expanded] : getInitialExpanded(data);
      /** If expanded just root */
      if (ex.length === 1) {
        const ids = _uniq([
          ...ex,
          ...(Array.isArray(idsFromQuery) ? [...idsFromQuery] : [idsFromQuery]),
        ].filter(Boolean));
        checkOpenedCollections(ids);
      } else {
        setExpanded(ex);
      }
    }

    /** After reload data -> clear expanded, e.g. change sort */
    if (!isLoaded) setExpanded([]);

    prevIsLoaded.current = isLoaded;
    prevIsFetching.current = data.isFetching;
    prevChildrenLength.current = data?.nodes?.length || 0;
    prevIdsFromQuery.current = idsFromQuery;
  }, [isLoaded, setExpanded, data, checkOpenedCollections, idsFromQuery, expanded]);

  const listener = (e) => checkOpenedCollections(e.detail);

  useMount(() => {
    window.addEventListener('expandCollectionPathTree', listener, false);
    return () => window.removeEventListener('expandCollectionPathTree', listener);
  });

  const handleToggleChild = useCallback((collectionId) => {
    if (expanded.includes(collectionId)) {
      setExpanded((prev) => prev.filter((id) => id !== collectionId));
      if (collectionId === data?._id && selected?.length === 1) LocalStorage.set(KEY_IN_LOCAL_STORAGE, false);
    } else {
      setExpanded((prev) => [...prev, collectionId]);
      if (collectionId === data?._id && selected?.length === 1) LocalStorage.set(KEY_IN_LOCAL_STORAGE, true);
    }
  }, [data?._id, expanded, setExpanded, selected]);

  useEffect(() => {
    tempExpanded = [...expanded];
  }, [expanded]);

  const treeData = useMemo(() => {
    if (search) return [search];
    return [data];
  }, [data, search]);

  return (
    <Tree
      nodes={treeData}
      renderNode={renderNode}
      selected={selected}
      onNodeSelect={onNodeSelect}
      onNodeToggle={handleToggleChild}
      controlled
      expanded={expanded}
    />
  );
}

MainTree.propTypes = {
  renderNode: func.isRequired,
  data: shape({
    name: string,
    nodes: arrayOf(any),
  }).isRequired,
  selected: arrayOf(string),
  onNodeSelect: func,
};
