import actionSetSortType from '../actions/collections/setSortType';
import store from '../index';
import sdk from '../../sdk';
import { findCollection } from '../helpers/collections';

const getState = () => store.getState().collections;

/**
 * Set sortType field to collection
 * @param {string} sortType
 * @param {boolean} onlyLocal
 */
export const setSortType = (sortType, onlyLocal) => {
  const { collectionIds } = store.getState().router.location.query;
  let collectionIdsArray = [];
  if (typeof collectionIds === 'string') {
    collectionIdsArray = [collectionIds];
  } else {
    collectionIdsArray = collectionIds;
  }
  actionSetSortType(
    collectionIdsArray || sortType.id,
    sortType,
    onlyLocal,
  )(store.dispatch);
};

/** Find root tag
 * @returns {Promise}
 */
export const forceFindRootTag = async () => {
  const found = findCollection(getState().collections, null, { path: 'root' });
  if (found) return found;

  const { data } = await sdk.collections.getRoot();
  return data;
};

/**
 * Get id of root tag
 * @returns {string} tag id
 */
export const getRootId = () => findCollection(getState().collections, null, { path: 'root' })._id;

export const isRootActive = () => store.getState().router.location.query.collectionIds === getRootId();
