import React, { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import {
  any, arrayOf, bool, func, shape, string,
} from 'prop-types';

import {
  Date, Input, Int, Boolean, Enum, MultiSelect,
} from '../../../CustomFields';
import l10n from '../../../../shared/strings';
import ModifiedField from '../ModifiedField';

function Field({
  data, disabled, modified, value, onChange, inProgress,
}) {
  const handleChangeBoolean = useCallback((newValue) => {
    onChange(newValue, data);
  }, [data, onChange]);

  const handleChangeMultiSelect = useCallback((selected, onCancel, settings) => {
    onChange(selected, data, onCancel, settings);
  }, [data, onChange]);

  const placeholder = useMemo(
    () => {
      if (disabled) return '';
      if (value === null) return l10n.DETAILS.placeholderMultipleSelection;
      return l10n.DETAILS.placeholderCustomTextField;
    },
    [value, disabled],
  );

  if (data.type === 'boolean') {
    return (
      <div
        key={data.title}
        className={cn('customFieldBooleanWithModifiedField', {
          customFieldWithIcon: modified,
        })}
      >
        <Boolean
          value={value}
          title={data.title}
          disabled={disabled || inProgress}
          onChange={handleChangeBoolean}
        />
        {!!modified && <ModifiedField field={modified} />}
      </div>
    );
  }

  if (data.type === 'date') {
    return (
      <div
        key={data.title}
        className={cn({ customFieldWithIcon: modified })}
      >
        <Date
          value={value}
          customField={data}
          disabled={disabled || inProgress}
          onChange={onChange}
          placeholder={value === null ? placeholder : undefined}
        />
        {!!modified && <ModifiedField field={modified} />}
      </div>
    );
  }

  if (data.type === 'int') {
    return (
      <div
        key={data.title}
        className={cn({ customFieldWithIcon: modified })}
      >
        <Int
          customField={data}
          onChange={onChange}
          disabled={disabled || inProgress}
          value={value === null ? '' : value}
          textPlaceholder={placeholder}
          pattern={data.pattern}
          patternDescription={data.patternDescription}
        />
        {!!modified && <ModifiedField field={modified} />}
      </div>
    );
  }

  if (data.type === 'input') {
    return (
      <div
        key={data.title}
        className={cn('customInputField', { customFieldWithIcon: modified })}
      >
        <Input
          customField={data}
          disabled={disabled || inProgress}
          value={value === null ? '' : value}
          onChange={onChange}
          textPlaceholder={placeholder}
          patternDescription={data.patternDescription}
        />
        {!!modified && <ModifiedField field={modified} />}
      </div>
    );
  }

  if (data.type === 'enum') {
    if (data.multiple) {
      return (
        <MultiSelect
          key={data.title}
          title={data.title}
          value={value}
          options={data.options}
          onChange={handleChangeMultiSelect}
          modifiedField={data}
          disabled={disabled}
          inProgress={inProgress}
          showDeletedOptions
        />
      );
    }

    return (
      <div
        key={data.title}
        className={cn('customFieldSelectWithModifiedField', { customFieldWithIcon: modified })}
      >
        <Enum
          value={value}
          disabled={disabled || inProgress}
          customField={data}
          onChange={onChange}
          placeholder={value === null ? placeholder : undefined}
        />
        {!!modified && <ModifiedField field={modified} />}
      </div>
    );
  }

  return null;
}

Field.defaultProps = {
  disabled: false,
  modified: undefined,
  onChange: Function.prototype,
  value: undefined,
  inProgress: false,
};

Field.propTypes = {
  data: shape({
    visibility: string,
    title: string,
    type: string,
    options: arrayOf(string),
    pattern: string,
    patternDescription: string,
    writable: bool,
  }).isRequired,
  value: any,
  inProgress: bool,
  disabled: bool,
  modified: shape({
    name: string,
    updatedAt: string,
    userId: string,
    value: string,
  }),
  onChange: func,
};

export default memo(Field);
