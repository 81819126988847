function getNegativePermissions(permissions) {
  const negativePermissions = { editingDisabled: true };
  for (const property in permissions) {
    if (property.includes('Show')) {
      negativePermissions[property] = permissions[property];
    } else {
      negativePermissions[property] = false;
    }
  }
  return negativePermissions;
}

export default getNegativePermissions;
