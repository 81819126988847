import CONSTANTS from '@picsio/db/src/constants';

import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import UiBlocker from '../../../services/UiBlocker';
import { navigate, reloadCurrentPage } from '../../../helpers/history';
import sortFailed from '../../helpers/assets/sortFailed';

/** Delete assets without trash */
const deleteAssets = (ids, deleteWithoutTrash) => async (dispatch) => {
  function handleSuccess(assets) {
    dispatch({
      type: TYPES.ASSETS.DELETE_ASSETS.COMPLETE,
      payload: { ids: assets },
    });
  }
  showDialog({
    title: localization.DIALOGS.DELETE_ASSETS.TITLE(deleteWithoutTrash, ids.length),
    text: localization.DIALOGS.DELETE_ASSETS.TEXT(deleteWithoutTrash, ids.length),
    onCancel: () => {
      Logger.log('User', 'DeleteAssetsFromTrashDialogCancel');
    },
    async onOk() {
      Logger.log('User', 'DeleteAssetsFromTrashDialogOk', ids.length);
      try {
        dispatch({
          type: TYPES.ASSETS.DELETE_ASSETS.START,
          payload: { ids, fieldName: CONSTANTS.DELETING_STATUS_FIELD_NAME },
        });
        UiBlocker.block();

        const { data: result } = await sdk.assets.delete(ids);

        const {
          queued, successful = [], failed = [], failedAssets = [],
        } = result;

        if (failed.length) {
          const { failedGDArr, failedOtherArr } = sortFailed(failedAssets, 'MissingCanDeleteGoogleDriveCapabilityError');

          if (failedGDArr.length) {
            const hideDialog = showDialog({
              title: localization.DIALOGS.ERROR_DELETE_DIALOG.TITLE,
              children: ids.length > 1
                ? localization.DIALOGS.ERROR_DELETE_DIALOG.TEXT_ASSET_BULK_OPERATION(
                  successful.length, failed.length, () => {
                    navigate('/storage');
                    hideDialog();
                  },
                )
                : localization.DIALOGS.ERROR_DELETE_DIALOG.TEXT_ASSET_ONE_OPERATION(
                  () => {
                    navigate('/storage');
                    hideDialog();
                  },
                ),
              textBtnCancel: null,
            });
          }

          if (failedOtherArr.length) {
            Toast(
              localization.DETAILS.textCantDeleteAsset(failedOtherArr.length),
              { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh },
            );
          }

          dispatch({
            type: TYPES.ASSETS.DELETE_ASSETS.FAILED,
            payload: { ids: failed },
          });
        }

        if (queued) {
          Toast(localization.DETAILS.textFinishInBg, { autoClose: false });
        } else {
          handleSuccess(successful);
        }
      } catch (error) {
        const errorStatus = utils.getStatusFromResponceError(error);
        if (errorStatus === 410) {
          /** Already deleted in GD -> status === 410 */
          handleSuccess(ids);
        } else {
          dispatch({
            type: TYPES.ASSETS.DELETE_ASSETS.FAILED,
            payload: { ids },
            error,
          });
          Toast(localization.DETAILS.textCantDeleteAsset(ids.length), { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
          Logger.error(new Error('Can not delete from trash'), { error, assetIds: ids }, [
            'DeleteAssetFromTrashFailed',
            (error && error.message) || 'NoMessage',
          ]);
        }
      }
      UiBlocker.unblock();
    },
  });
};

export default deleteAssets;
