import picsioConfig from '../../../../../config';
import sdk from '../../sdk';
import prepareSearchQuery from './prepareSearchQuery';
import { isRoutePreview } from '../history';

const PAGE_SIZE = 50;

function searchMain(from, sort, geoData, assets) {
  const data = {
    ...prepareSearchQuery(),
    size: geoData ? 'all' : PAGE_SIZE,
    from,
    sort,
  };

  if (isRoutePreview()) {
    const assetId = window.location.pathname.split('/').pop();
    const asset = assets.find((a) => a._id === assetId);
    if (asset && asset.trashed) data.trashed = true;
  }
  if (geoData) {
    data.geoData = true;
    data.onlyIds = true;
    data.zoom = Number(data.zoom);
  }

  return sdk.assets.search(data);
}

async function searchPublic(from, sort) {
  const query = prepareSearchQuery();
  query.collectionIds = query.collectionIds?.length ? query.collectionIds : picsioConfig.access.tag._id;
  try {
    return await sdk.assets.searchPublic(
      picsioConfig.apiBaseUrl,
      window.websiteConfig.alias,
      {
        size: PAGE_SIZE,
        from,
        sort,
        ...query,
      },
    );
  } catch (error) {
    if (error.status === 401) {
      window.location.replace(
        `/websites/signin?alias=${window.websiteConfig.alias}`,
      );
    }
    throw error; // actually we should write something meaningful to user here
  }
}

export default function search(...args) {
  if (picsioConfig.isMainApp) return searchMain(...args);
  return searchPublic(...args);
}
