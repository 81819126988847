/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import l18n from '../../shared/strings';
import updateUser from '../actions/user/updateUser';
import Toast from '../../components/Toast';

export const create = createAsyncThunk(
  'inboxes/create',
  async ({ name, force = false }, {
    extra: {
      sdk, utils, Logger, showErrorDialog,
    },
    getState,
    dispatch,
  }) => {
    try {
      const { data } = await sdk.inboxes.create(name, force);
      const { subscriptionFeatures, addonsFeatures = {}, _id } = getState().user;
      Logger.log('UI', '_inbox_inbox_created', { inbox_name: data.name, created_by: _id });
      const copyAddonsFeatures = { ...addonsFeatures };
      const copySubscriptionFeatures = { ...subscriptionFeatures };
      if (force) {
        copyAddonsFeatures.inboxesLimit = addonsFeatures?.inboxesLimit ? copyAddonsFeatures.inboxesLimit + 1 : 1;
        copySubscriptionFeatures.inboxesLimit += 1;
        Toast(l18n.TOAST.UPGRADE_ADDON_SUCCESS('1 inbox'));
      }
      dispatch(updateUser({
        addonsFeatures: {
          ...copyAddonsFeatures,
        },
        subscriptionFeatures: {
          ...copySubscriptionFeatures,
          inboxesCount: subscriptionFeatures.inboxesCount + 1,
        },
      }));
      return data;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not create inbox';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error inbox creating'), { error }, [
        'CreateInboxFailed',
        errorMessage || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      create.pending,
      (state, { meta }) => {
        state.nameCreatingInbox = meta.arg.name;
      },
    )
    .addCase(
      create.fulfilled,
      (state, { payload }) => {
        Object.keys(payload.fields).forEach((key) => {
          /** Set titles to default fields */
          if (!Array.isArray(payload.fields[key])) {
            payload.fields[key].title = l18n.INBOXSETTINGS.DEFAULT_FIELDS[key];
          }
        });
        state.inboxes.push(payload);
        state.nameCreatingInbox = null;
        state.error = null;
      },
    )
    .addCase(
      create.rejected,
      (state, { error }) => {
        state.nameCreatingInbox = null;
        state.error = error;
      },
    );
};
