/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';
import { sort, getKeywordNameFromPath } from '../helpers/keywords';
import { added, create } from '../keywords/actions';

export const add = createAction('keywordsList/add');

export const reducer = (builder) => {
  builder.addMatcher(
    (action) => [add.type, added.type, create.fulfilled.type].includes(action.type),
    (state, { payload }) => {
      /** create action returns object with field "keywords" */
      const keywords = (Array.isArray(payload) ? payload : payload.keywords).map((kw) => {
        if (kw.name) return kw;
        /** Create action may not return names for keywords */
        return { ...kw, name: getKeywordNameFromPath(kw.path) };
      });
      if (keywords?.length) {
        state.items = sort(_uniqBy([...state.items, ...keywords], '_id'), {
          type: 'name',
          order: 'asc',
        });
      }
    },
  );
};
