import TYPES from '../../action-types';

/**
 * @param {string} id
 * @param {string} revisionId
 * @param {string} url
 * @param {Object} thumbnail
 * @param {Object[]?} pages
 * @param {Object?} imageSizes
 */
function setCustomThumbnail(id, revisionId, url, thumbnail, pages, imageSizes) {
  return (dispatch) => {
    dispatch({
      type: TYPES.ASSETS.SET_CUSTOM_THUMBNAIL,
      payload: {
        id,
        revisionId,
        url,
        pages,
        imageSizes,
        thumbnail,
      },
    });
  };
}

export default setCustomThumbnail;
