import TYPES from '../../action-types';
/**
 * Attach comment to asset
 * @param {object} comment - new comment to asset
 * @param {string} assetId - commented asset id
 */
const attachCommentToAsset = (comment, assetId) => async (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.ATTACH_COMMENT,
    payload: {
      comment,
      assetId,
    },
  });
};

export default attachCommentToAsset;
