import localization from '../../../shared/strings';

export default function getStatus(user) {
  const STATUS = {
    invited: localization.TEAMMATES.statusInvited,
    requested: localization.TEAMMATES.statusRequested,
    rejected: localization.TEAMMATES.statusRejected,
    accepted: localization.TEAMMATES.statusAccepted,
    reinvited: localization.TEAMMATES.statusReinvited,
  };

  if (user.parent.reinvited) return STATUS.reinvited;
  if (user.parent.confirmedByTeam === false) return STATUS.requested;
  if (user.parent.confirmed === false) return STATUS.rejected;
  if (user.parent.confirmed) return STATUS.accepted;

  return STATUS.invited;
}
