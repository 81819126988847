import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';
import * as helpers from '../../helpers/collections';
import {
  reloadApp, setSearchRoute,
} from '../../../helpers/history';
import picsioConfig from '../../../../../../config';
import Toast from '../../../components/Toast';
import sdk from '../../../sdk';

/**
 * Get children for collection
 * @param {string} collectionID
 * @param {string?} currentCollectionId
 */
function getChildren(collectionID, { currentCollectionId } = {}) {
  return async (dispatch, getState) => {
    dispatch({
      type: TYPES.COLLECTIONS.FETCH_CHILDREN.START,
      payload: { collectionID },
    });

    try {
      let children;
      if (picsioConfig.isMainApp) {
        const { data } = await sdk.collections.getChildren(
          collectionID, { targetCollectionId: currentCollectionId },
        );
        children = data;
      } else {
        const { data } = await sdk.collections.getChildrenPublic(
          collectionID,
          picsioConfig.apiBaseUrl,
          window.websiteConfig.alias,
          { targetCollectionId: currentCollectionId },
        );
        children = data;
      }
      const nodes = children.map(helpers.normalizeCollection);

      dispatch({
        type: TYPES.COLLECTIONS.FETCH_CHILDREN.COMPLETE,
        payload: {
          collectionID,
          nodes,
        },
      });
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 404 && currentCollectionId) {
        reloadApp();
        return;
      }

      if (currentCollectionId) {
        const searchQuery = getState && getState().router.location.query;
        setSearchRoute({ ...searchQuery, collectionIds: collectionID });
        getChildren(collectionID)(dispatch);
      }
      Logger.error(new Error('Can not get collections children'), { error });
      Toast(
        'Unable to load collection list. Contact support to resolve the issue.',
        { autoClose: false, type: 'error' },
      );
      dispatch({
        type: TYPES.COLLECTIONS.FETCH_CHILDREN.FAILED,
        error,
      });
    }
  };
}

export default getChildren;
