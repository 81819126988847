import React, {
  useState, useCallback, useEffect,
} from 'react';
import CONSTANTS from '@picsio/db/src/constants';
import {
  shape, string, any, func,
} from 'prop-types';
import { Button } from '@picsio/ui';
import { OpenIn } from '@picsio/icons';
import { usePrevious } from 'react-use';
import l18n from '../../../../shared/strings';
import Logger from '../../../../services/Logger';
import parseIframe from '../../../../helpers/parseIframeAttributes';
import Wrapper from './Wrapper';

import './styles.scss';

function isAllowedIframe(url) {
  if (!url) return false;
  const { hostname } = new URL(url);
  return CONSTANTS
    .assets
    .crawlerExceptionDomains
    .embed
    .some((domain) => hostname.endsWith(domain));
}

export default function External({ asset, moveToTrash }) {
  const {
    crawledData = {}, source, name, thumbnail = {}, _id, crawling,
  } = asset;
  const [previewError, setError] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const prevId = usePrevious(_id);

  const handleLoad = useCallback(() => setLoaded(true), [setLoaded]);
  const handleError = useCallback(() => {
    Logger.log('UI', 'LoadExternalAssetFailed', _id);
    setError(true);
  }, [setError, _id]);

  /** listen to change asset._id */
  useEffect(() => {
    if (_id !== prevId) setLoaded(false);
  }, [_id, setLoaded, prevId]);

  const handleClickOpen = useCallback(() => {
    Logger.log('User', 'OpenExternalAssetInNewTab', _id);
    window.open(source, '_blank');
  }, [_id, source]);

  const renderPlaceholder = useCallback(() => (
    <div className="placeholderExternalDeny">
      {crawling === 'waiting' ? l18n.PREVIEW_VIEW.externalCrawlingDataText() : l18n.PREVIEW_VIEW.externalNoPreviewText()}
      <Button
        color="primary"
        size="md"
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<OpenIn />}
      >
        {l18n.PREVIEW_VIEW.externalNoPreviewBtnText}
      </Button>
    </div>
  ), [handleClickOpen]);

  /** render Error */
  if (previewError) {
    return (
      <Wrapper bg={thumbnail?.default} asset={asset} moveToTrash={moveToTrash}>
        {renderPlaceholder()}
      </Wrapper>
    );
  }

  /** Render image */
  if ((crawledData?.contentType || '').startsWith('image/')) {
    return (
      <Wrapper asset={asset} isLoading={!isLoaded} moveToTrash={moveToTrash}>
        <img
          src={crawledData.url}
          alt={crawledData.title}
          onLoad={handleLoad}
          onAbort={handleError}
          onInvalid={handleError}
          onError={handleError}
        />
      </Wrapper>
    );
  }

  if ((crawledData?.contentType || '').includes('application/pdf')) {
    /** Render PDF in iframe */
    return (
      <Wrapper
        isLoading={!isLoaded}
        bg={thumbnail?.default}
        asset={asset}
        moveToTrash={moveToTrash}
      >
        <iframe
          src={crawledData?.url || source}
          frameBorder={0}
          width="100%"
          height="100%"
          title={crawledData?.title || name}
          onAbort={handleError}
          onInvalid={handleError}
          onError={handleError}
          onLoad={handleLoad}
        />
      </Wrapper>
    );
  }

  /** render iframe from metadata */
  if (crawledData?.iframe && isAllowedIframe(source)) {
    const attributes = parseIframe(crawledData.iframe);
    return (
      <Wrapper
        isLoading={!isLoaded}
        bg={thumbnail?.default}
        asset={asset}
        moveToTrash={moveToTrash}
      >
        <iframe
          src={attributes?.src}
          frameBorder={attributes?.frameBorder}
          width="100%"
          height="100%"
          allow={attributes?.allow}
          feature={attributes?.feature}
          title={attributes?.title || name}
          allowFullScreen
          onLoad={handleLoad}
          onAbort={handleError}
          onInvalid={handleError}
          onError={handleError}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper isLoading={false} bg={thumbnail?.default} asset={asset} moveToTrash={moveToTrash}>
      {renderPlaceholder()}
    </Wrapper>
  );
}

External.defaultProps = {
  asset: {
    _id: 'unknown',
    name: 'unknown',
    thumbnail: {},
    crawledData: {},
  },
  moveToTrash: null,
};

External.propTypes = {
  asset: shape({
    _id: string,
    name: string,
    thumbnail: shape(any),
    crawledData: shape(any),
    source: string,
    crawling: string,
  }),
  moveToTrash: func,
};
