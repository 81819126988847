import React from 'react';
import { Interweave } from 'interweave';
import Icon from '../components/Icon';
import sanitizeXSS from '../shared/sanitizeXSS';

export default class Radio extends React.Component {
  render() {
    const {
      label, value = false, disabled = false, onChange = Function.prototype, icon, ...restProps
    } = this.props;
    const className = `UIRadio ${value ? 'UIRadio--checked' : ''} ${disabled ? 'UIRadio--disabled' : ''}`;

    return (
      <div
        className={`${className}`}
        onClick={(e) => {
          !disabled && onChange(e, !value);
        }}
        { ...restProps }
      >
        <span className="UIRadio__input" />
        {label && (
          <>
            {icon && (
              <span className="UIRadio__icon">
                <Icon name={icon} />
              </span>
            )}
            <span className="UIRadio__label">
              <Interweave content={sanitizeXSS(label)} />
            </span>
          </>
        )}
      </div>
    );
  }
}
