import TYPES from '../../action-types';

import addHighlight from './addHighlight';

/**
 * Removed assets from collection immediately
 * @param {array} ids
 */
const removedFromCollection = (params) => (dispatch) => {
  addHighlight(params.ids, 'collections')(dispatch);
  dispatch({
    type: TYPES.ASSETS.REMOVED_COLLECTION,
    payload: {
      collectionID: params._id,
      selectedIds: params.ids,
      itemsRemoved: params.ids.length,
    },
  });
};

export default removedFromCollection;
