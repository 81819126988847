import _isEqual from 'lodash/isEqual';
import Logger from '../../services/Logger';
import { showDialog } from '../dialog';
import { LocalStorage } from '../../shared/utils';
import l10n from '../../shared/strings';

async function readEntries(reader) {
  let result = [];
  await new Promise((resolve) => {
    const read = (entries) => {
      if (entries.length) {
        result = [...result, ...entries];
        reader.readEntries(read);
      } else {
        resolve();
      }
    };
    reader.readEntries(read);
  });
  return result;
}

/**
 * Get files from dropped items
 * @param {Object} item
 * @param {string} path
 * @returns {Promise}
 */
export async function getFilesFromEntry(item, path = '') {
  /** don't handle hidden files and folders */
  if (item.name.startsWith('.')) return [];

  if (item.isFile) {
    // Get file
    return new Promise((resolve, reject) => {
      item.file(
        (file) => resolve({ file, collectionPath: path }),
        reject,
      );
    });
  }
  if (item.isDirectory) {
    // Get directory contents
    const dirReader = item.createReader();
    const entries = await readEntries(dirReader);
    return Promise.all(entries.map(
      (entry) => getFilesFromEntry(entry, `${path + item.name}/`),
    ));
  }
  return [];
}

/**
 * Show dialog when try to add files and queue exists
 */
export function showBusyDialog() {
  Logger.log('UI', 'ImportBusyDialog');
  showDialog({
    title: l10n.DIALOGS.UPLOAD_QUEUE_BUSY.TITLE,
    text: l10n.DIALOGS.UPLOAD_QUEUE_BUSY.TEXT,
    textBtnCancel: l10n.DIALOGS.btnOk,
    textBtnOk: null,
  });
}

/** Show dialog with list of files that not uploaded */
export function checkPrevQueue() {
  const notUploadedFiles = LocalStorage.get('uploadQueue') || [];
  if (notUploadedFiles.length) {
    LocalStorage.remove('uploadQueue');
    showDialog({
      title: l10n.IMPORT.restoreUpload.title,
      children: l10n.IMPORT.restoreUpload.list(notUploadedFiles),
      textBtnOk: null,
      textBtnCancel: l10n.IMPORT.restoreUpload.btnCancel,
    });
  }
}

/** Show warning for user if upload queue isn't empty */
export function unloadListener(event) {
  const notUploadedFiels = LocalStorage.get('uploadQueue') || [];
  // eslint-disable-next-line no-param-reassign
  if (notUploadedFiels.length) event.returnValue = 'Changes you made may not be saved.';
}

export function isTheSameMetadataUsed(items) {
  const files = Object.values(items).flat();
  const firstFields = (files[0] || {}).fields;
  return files.every((file) => _isEqual(file?.fields, firstFields));
}
