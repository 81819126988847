import React from 'react';
import Skeleton from 'react-loading-skeleton';
import WithSkeletonTheme from '../../../WithSkeletonTheme';

const PresetSkeleton = () => (
  <WithSkeletonTheme>
    <Skeleton height={208} />
  </WithSkeletonTheme>

);

export default PresetSkeleton;
