import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../../../sdk';

const updateUserCustomerTax = createAsyncThunk(
  'user/updateUserCustomerTax',
  async (payload) => {
    const { data } = await sdk.users.updateCustomerTax(payload);
    return data;
  },
);

export default updateUserCustomerTax;
