import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { showDialog } from '../../../components/dialog';
import { reloadCurrentPage } from '../../../helpers/history';

const deleteWatermark = (watermarkId) => async (dispatch) => {
  try {
    UiBlocker.block();
    const { data } = await sdk.watermarks.delete(watermarkId, false);
    const { success } = data;
    if (success) {
      dispatch({
        type: TYPES.ASSETS.DELETE_WATERMARKS,
        payload: {
          watermarkId,
        },
      });
    }
    UiBlocker.unblock();
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    const { data } = error.response;
    if (errorStatus === 412) {
      showDialog({
        title: localization.WATERMARKS.titleDeleteWatermark,
        text: localization.WATERMARKS.textDeleteWatermark(data.assetsCount),
        textBtnOk: localization.WATERMARKS.btnOkRemoveWatermark,
        textBtnCancel: localization.DIALOGS.btnCancel,
        async onOk() {
          try {
            UiBlocker.block();
            const { data: res } = await sdk.watermarks.delete(watermarkId, true);
            const { success } = res;
            if (success) {
              dispatch({
                type: TYPES.ASSETS.DELETE_WATERMARKS,
                payload: {
                  watermarkId,
                },
              });
            }
            UiBlocker.unblock();
          } catch (err) {
            Toast(localization.WATERMARKS.errorDeletingWatermark, { type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
            Logger.error(new Error('Error deleting watermark'), { err, showDialog: false }, [
              'ErrorDeletingWatermark',
              (err && err.message) || 'NoMessage',
            ]);
          }
        },
        onCancel() {},
      });
    }
    UiBlocker.unblock();
  }
};

export default deleteWatermark;
