import mobileStorage from '../shared/MobileStorage';

class MobileTokenStorage {
  accessTokenTitle = '_';

  refreshTokenTitle = '__';

  getAccessToken() {
    return mobileStorage.get(this.accessTokenTitle) || '';
  }

  setAccessToken(token) {
    return mobileStorage.set(this.accessTokenTitle, token);
  }

  getRefreshToken() {
    return mobileStorage.get(this.refreshTokenTitle) || '';
  }

  setRefreshToken(token) {
    return mobileStorage.set(this.refreshTokenTitle, token);
  }

  async clearTokens() {
    mobileStorage.remove(this.accessTokenTitle);
    mobileStorage.remove(this.refreshTokenTitle);
  }
}

export default new MobileTokenStorage();
