/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import groupFields from './helpers/groupFields';
import groupErrors from './helpers/groupErrors';
import getFilesByIds from './helpers/getFilesByIds';
import groupPermissions from './helpers/groupPermissions';

export const removeFromSelection = createAction('import/removeFromSelection');

export const reducer = (builder) => {
  builder.addCase(
    removeFromSelection.type,
    (state, { payload: ids }) => {
      state.selected = state.selected.filter((id) => !ids.includes(id));
      const selectedItems = getFilesByIds(state.items, state.selected);
      state.fields = groupFields(selectedItems);
      state.errors = groupErrors(selectedItems);
      state.permissions = groupPermissions(selectedItems);
    },
  );
};
