import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Set rating to assets
 * @param {string[]} assetIds
 * @param {number} value - must be from 0 to 5
 * @returns Promise<AxiosResponse>
 */
export default function setRating(assetIds, value) {
  if (picsioConfig.isMainApp) return sdk.assets.setRating(assetIds, value);

  return sdk.assets.setRatingPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    value,
  );
}
