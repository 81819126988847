import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@picsio/ui';
import { Interweave } from 'interweave';
import localization from '../../shared/strings';
import { DropdownTreeWithStore } from '../DropdownTree';
import Icon from '../Icon';
import sanitizeXSS from '../../shared/sanitizeXSS';
import RadioItem from '../RadioItem';

const KEY_ENTER = 13;
const KEY_ESC = 27;

class SelectFromTreeDialog extends React.Component {
  state = {
    checkedItems: [],
    checkedRadioItem: this.props.defaultRadioItemValue,
  };

  componentDidMount() {
    if (this.props?.checkedItems?.length) {
      this.setState({ checkedItems: this.props.checkedItems });
    }
    document.addEventListener('keydown', this.keyListener);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyListener);
  }

  /** @param {KeyboardEvent} event */
  keyListener = (event) => {
    switch (event.keyCode) {
    case KEY_ENTER:
      return this.handleOk();
    case KEY_ESC:
      return this.handleClose();
    }
  };

  handleClose = () => {
    this.props.onClose();
    this.props.destroy();
  };

  handleOk = () => {
    const showTree = this.props.showTreePermanent || (this.state.checkedRadioItem === this.props.showTreeValue);
    if (showTree && this.state.checkedItems.length === 0) return;

    this.props.onOk(this.state.checkedItems);
    this.props.destroy();
  };

  handleMultipleSelection = (collectionItem, isChecked) => {
    // deselect
    if (isChecked) {
      if (this.state.checkedItems.length > 1) {
        const items = this.state.checkedItems.filter((checkedItem) => checkedItem._id !== collectionItem._id);
        this.setState({ checkedItems: items });
      }
      return;
    }
    this.setState((prevState) => ({ checkedItems: [...prevState.checkedItems, collectionItem] }));
  }

  handleClick = (collectionItem, isChecked) => {
    if (this.props.multipleSelection) {
      this.handleMultipleSelection(collectionItem, isChecked);
    } else {
      this.setState({ checkedItems: [collectionItem] });
    }
  };

  handleChangeRadio = (value) => {
    this.setState({ checkedRadioItem: value });
  };

  render() {
    const { props, state } = this;
    const showTree = props.showTreePermanent || (state.checkedRadioItem === props.showTreeValue);
    return (
      <div className="simpleDialog moveCollectionDialog">
        <div className="simpleDialogUnderlayer" />
        <div className="simpleDialogBox" style={props.style}>
          <div className="simpleDialogHeader">
            <span className="simpleDialogTitle">
              <Choose>
                <When condition={typeof props.title === 'function'}>
                  {props.title(props.collectionToMove.name)}
                </When>
                <Otherwise>{props.title}</Otherwise>
              </Choose>
            </span>
            <span className="simpleDialogBtnCross" onClick={this.handleClose}>
              <Icon name="close" />
            </span>
          </div>
          <div className="simpleDialogContent">
            <div className="simpleDialogContentInner">
              <If condition={props.text}>
                <div className="simpleDialogDescription">
                  {/* some data comes in format of string ex "<span>text</span>" */}
                  <If condition={typeof props.text === 'string'}>
                    <Interweave content={sanitizeXSS(props.text)} />
                  </If>
                  {/* some data comes in format of pure elements' ex <span>text</span>
                    NOTE without quotes
                     */}
                  <If condition={typeof props.text !== 'string'}>
                    {props.text}
                  </If>
                </div>
              </If>

              <If condition={props.radioItems}>
                {
                  props.radioItems.map(({ value, label }) => {
                    return (
                      <RadioItem
                        key={value}
                        value={value}
                        label={label}
                        checkedValue={state.checkedRadioItem}
                        onChange={this.handleChangeRadio}
                      />
                    );
                  })
                }
              </If>

              <If condition={props.radioItems && showTree}>
                <div className="divider mb-14" />
              </If>

              <If condition={showTree}>
                <DropdownTreeWithStore
                  checkedItems={state.checkedItems.map((item) => item._id)}
                  openedItems={props.openedItems}
                  treeListItems={props.treeListItems || []}
                  onClick={this.handleClick}
                  iconSpecial={props.iconSpecial}
                  collectionToMove={props.collectionToMove}
                  type={props.type}
                  disabledItems={props.disabledItems}
                  canCreateCollection={props.canCreateCollection}
                  multipleSelection={props.multipleSelection}
                />
              </If>
            </div>
          </div>
          <div className="simpleDialogFooter">
            <If condition={props.multipleSelection && state.checkedItems.length >= 10}>
              <span className="warningText">
                {props.warningText}
              </span>
            </If>
            <If condition={props.textBtnCancel !== null}>
              <Button
                color="secondary"
                size="md"
                variant="contained"
                onClick={this.handleClose}
              >
                {props.textBtnCancel}
              </Button>
            </If>
            <If condition={props.textBtnOk !== null}>
              <Button
                color="primary"
                size="md"
                variant="contained"
                disabled={showTree && state.checkedItems.length === 0}
                onClick={this.handleOk}
              >
                {props.textBtnOk}
              </Button>
            </If>
          </div>
        </div>
      </div>
    );
  }
}

/** Prop types */
SelectFromTreeDialog.propTypes = {
  treeListItems: PropTypes.array,
  onLoadChildren: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  textBtnOk: PropTypes.string,
  textBtnCancel: PropTypes.string,
  onClose: PropTypes.func,
  onOk: PropTypes.func.isRequired,
  collectionToMove: PropTypes.object,
  iconSpecial: PropTypes.string,
  disabledItems: PropTypes.arrayOf(PropTypes.string),
  multipleSelection: PropTypes.bool,
  warningText: PropTypes.string,
  text: PropTypes.string,
  radioItems: PropTypes.arrayOf(PropTypes.object),
  defaultRadioItemValue: PropTypes.string,
  showTreeValue: PropTypes.string,
  showTreePermanent: PropTypes.bool,
};

/** Default props */
SelectFromTreeDialog.defaultProps = {
  title: '',
  style: {},
  onClose: () => {},
  textBtnOk: localization.DIALOGS.btnOk,
  textBtnCancel: localization.DIALOGS.btnCancel,
  collectionToMove: {},
  iconSpecial: 'folder',
  disabledItems: null,
  multipleSelection: false,
  warningText: '',
  text: '',
  showTreePermanent: true,
};

export default SelectFromTreeDialog;
