import picsioConfig from '../../../../../config';
import sdk from '../../sdk';
import { getDataFromResponceError } from '../../shared/utils';
import l18n from '../../shared/strings';
import Toast from '../../components/Toast/index';
import { reloadCurrentPage } from '../history';

/**
 * Add comment for the asset
 * @param {string} assetId
 * @param {*} data
 * @returns Promise<AxiosResponse>
 */
export default function addComment(assetId, data) {
  try {
    if (picsioConfig.isMainApp) return sdk.assets.addComment(assetId, data);

    return sdk.assets.addCommentPublic(
      picsioConfig.apiBaseUrl,
      window.websiteConfig.alias,
      assetId,
      data,
    );
  } catch (error) {
    const subcode = getDataFromResponceError(error, 'subcode');

    if (subcode === 'AssetNotFoundApiError') {
      Toast(l18n.HISTORY.textAssetNotFound, { closeButton: false, type: 'error' });
    } else if (subcode === 'CreateCommentApiError') {
      Toast(l18n.HISTORY.textCreateCommentApiError, { closeButton: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
    } else {
      Toast(l18n.HISTORY.textCantAddComment, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: l18n.HISTORY.textBtnRefresh });
    }
    throw error;
  }
}
