import ResolveDuplicatesDialog from '../../../components/resolveDuplicatesDialog';
import UiBlocker from '../../../services/UiBlocker';
import sdk from '../../../sdk';

/**
 * Find duplicates on the server and resolve
 */
export default async function findAndResolveDuplicates({
  assets, collectionPath, lightboardPath, user,
}) {
  let duplicates = [];
  let filteredAssets;
  const assetNamesToRestore = assets.map((a) => a.name);

  UiBlocker.block();
  /** example dataToCheck
   * [{
   *  collectionPath: '/path/to collection/here',
   *  names: ['name1', 'name2'],
   * }]
   * or [{
   *  lightboardPath: '→My First Lightboard',
   *  names: ['name1', 'name2'],
   * }]
   */
  const dataToCheck = { names: assetNamesToRestore };

  /** example result
   * {
   *  duplicatesInSelectedAssets: [{name: '123', ...}, {name: '234', ...}, {name: '234', ...}],
   *  nameCountInSelectedAssets: { '123': 1, '234': 2 },
   * }
   */
  const getDuplicatesInSelectedAssets = () => assets.reduce((result, asset, _, assetsArr) => {
    assetsArr.forEach((item) => {
      if (item._id !== asset._id && item.name === asset.name) {
        result.duplicatesInSelectedAssets.push(asset);
        if (result.nameCountInSelectedAssets[asset.name]) {
          // eslint-disable-next-line no-param-reassign
          result.nameCountInSelectedAssets[asset.name] += 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          result.nameCountInSelectedAssets[asset.name] = 1;
        }
      }
    });
    return result;
  }, {
    duplicatesInSelectedAssets: [],
    nameCountInSelectedAssets: {},
  });

  if (collectionPath) dataToCheck.collectionPath = collectionPath;
  if (lightboardPath) dataToCheck.lightboardPath = lightboardPath;
  try {
    const { data } = await sdk.assets.detectDuplicates([dataToCheck]);
    duplicates = [...data];
    UiBlocker.unblock();
  } catch (error) {
    UiBlocker.unblock();
    throw error;
  }

  const { duplicatesInSelectedAssets, nameCountInSelectedAssets } = getDuplicatesInSelectedAssets();

  if (duplicates.length > 0 && duplicatesInSelectedAssets.length === 0) {
    /** Resolve duplicates */
    const dialog = new ResolveDuplicatesDialog(user);
    const resolvedAssets = await dialog.resolve(duplicates, assets, true, false);
    filteredAssets = resolvedAssets.filter((asset) => asset.action !== 'skipFile');
  } else if (duplicates?.length === 0 && duplicatesInSelectedAssets.length > 0) {
    /** when the folder is empty but we have duplicates in selected assets */
    const dialog = new ResolveDuplicatesDialog(user);
    const resolvedAssets = await dialog.resolve(duplicatesInSelectedAssets, assets, true, false);
    filteredAssets = resolvedAssets.filter((asset) => asset.action !== 'skipFile');
  } else if (duplicates?.length > 0 && duplicatesInSelectedAssets.length > 0) {
    /** when we have duplicates in selected assets and the name(s) are already exist in the collection */
    const allDuplicates = duplicates.reduce((result, duplicate) => {
      /*
       * for every local duplicates (that are in the clipboard) we need to add duplicates that are found
       * on the server
       * i.e:
       * [{name: '123', ...[from server]}, {name: '1234', ...[from server]}] - server duplicates
       * [{name: '123', ...}, {name: '123', ...}, {name: '1234', ...}, {name: '1234', ...}] - local duplicates
       * result: [{name: '123', ...[from server]}, {name: '123', ...[from server]}, {name: '1234', ...[from server]}, {name: '1234', ...[from server]}]
      */
      const serverDuplicatesForSelectedAsset = new Array(nameCountInSelectedAssets[duplicate.name]).fill(duplicate);
      return [...result, ...serverDuplicatesForSelectedAsset];
    }, []);

    const dialog = new ResolveDuplicatesDialog(user);
    const resolvedAssets = await dialog.resolve(allDuplicates, assets, true, false);
    filteredAssets = resolvedAssets.filter((asset) => asset.action !== 'skipFile');
  }
  const resolvedAssets = filteredAssets || assets;

  /** fill actions */
  const actions = resolvedAssets.reduce((act, asset) => {
    if (asset.action === 'replaceFile') {
      act[asset._id] = {
        action: 'replace',
        assetIdToReplace: asset.duplicatedModel._id,
      };
    }
    if (asset.action === 'renameFile') {
      act[asset._id] = { action: 'rename' };
    }
    return act;
  }, {});

  return {
    assets: resolvedAssets,
    actions: Object.keys(actions).length ? actions : undefined,
  };
}
