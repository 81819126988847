import TYPES from '../../action-types';

/**
 * Assign user to assets immediately
 * @param {string[]} ids
 * @param {string} value
 */
const assignedUser = (ids, assigneeId) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.ASSIGN_USER.COMPLETE,
    payload: { assigneeId, ids, notify: true },
  });
};

export default assignedUser;
