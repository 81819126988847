/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { any } from 'prop-types';
import { resizePanel } from '../../store/actions/main';

export default function TreeResizer({ children = '' }) {
  const dispatch = useDispatch();
  const width = useSelector((state) => state.main.panelsWidth.catalogView.left);

  const handleMouseDown = useCallback((event) => {
    dispatch(resizePanel(event, 'left'));
  }, [dispatch]);

  const widthStyle = useMemo(() => ({ width }), [width]);

  return (
    <div className="folderTreeView" style={widthStyle}>
      <div className="treeResizer" onMouseDown={handleMouseDown} role="presentation" />
      {children}
    </div>
  );
}

TreeResizer.propTypes = {
  children: any,
};
