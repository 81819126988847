import React from 'react';
import { createRoot } from 'react-dom/client';
import SelectFromTreeDialog from '../components/SelectFromTreeDialog';

export default function showSelectFromTreeDialog(props) {
  const $wrapper = document.createElement('div');
  $wrapper.classList.add('wrapperDialog');
  $wrapper.classList.add('wrapperMoveDialog');
  document.body.appendChild($wrapper);
  const root = createRoot($wrapper);

  function destroy() {
    root.unmount();
    document.body.removeChild($wrapper);
  }

  root.render(<SelectFromTreeDialog destroy={destroy} {...props} />);
}
