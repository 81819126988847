/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import _remove from 'lodash/remove';
import getFilesByIds from './helpers/getFilesByIds';
import calculateTotalSize from './helpers/calculateTotalSize';

export const remove = createAction('import/remove');

export const reducer = (builder) => {
  builder.addCase(
    remove.type,
    (state, { payload: ids }) => {
      const queueInProgress = state.inProgress;
      const files = getFilesByIds(state.items, ids);
      files.forEach(({ isValid, error, controller }) => {
        /** if upload started - abort request */
        if (controller) controller.abort();
        /** if the file has upload error */
        if (isValid && error) state.uploadErrorsCount -= 1;
      });
      Object.keys(state.items).forEach((path) => {
        _remove(
          state.items[path],
          ({ id, complete, progress }) => {
            /** Don't remove: if file isn't in ids */
            if (!ids.includes(id)) return false;
            /** Don't remove: if file completed AND the queue already in progress */
            if (complete && queueInProgress) return false;
            /** Don't remove: if file upload progress more then 98% */
            if ((progress || 0) > 98 && queueInProgress) return false;

            return true;
          },
        );
        if (!state.items[path].length) delete state.items[path];
      });
      _remove(state.selected, ((id) => ids.includes(id)));
      state.total = calculateTotalSize(state.items);
    },
  );
};
