import {
  any, bool, func, string,
} from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Icon, Button, InfoBlock, InputControlLabel,
} from '@picsio/ui';
import { CloseIcon } from '@picsio/icons';
import { useDispatch, useSelector } from 'react-redux';
import l10n from '../../shared/strings';
import { LocalStorage } from '../../shared/utils';
import Collection from './Collection';
import { removeFromCollection, removeFromAllCollections } from '../../store/actions/assets';
import Logger from '../../services/Logger';
import styles from './styles.module.scss';

const CHECKBOX_KEY = 'picsio.DontShowListCollectionsToRemoveFrom';

const RemoveAssetCollectionDialog = ({
  destroy, style, title, textBtnCancel, assetId, allowToRemoveAll,
}) => {
  const dispatch = useDispatch();
  const activeCollections = useSelector((state) => state.collections.activeCollections);
  const asset = useSelector((state) => state.assets.items.find(({ _id }) => assetId === _id));
  const [checkboxValue, setCheckboxValue] = useState(Boolean(LocalStorage.get(CHECKBOX_KEY)));

  const handleClose = useCallback(() => { destroy(); }, [destroy]);
  const handleRemoveAll = useCallback(() => {
    dispatch(removeFromAllCollections(assetId));
    destroy();
  }, [assetId, destroy, dispatch]);

  const handleChangeCheckbox = useCallback(
    () => {
      setCheckboxValue((prev) => {
        Logger.log('User', 'DialogRemoveFromCollectionDontShowChanged', { checked: !prev });
        LocalStorage.set(CHECKBOX_KEY, !prev);
        return !prev;
      });
    },
    [setCheckboxValue],
  );

  const handleRemoveOne = useCallback((path, _id) => {
    Logger.log('User', 'DialogRemoveFromCollection', { assetId, collectionId: _id });
    dispatch(removeFromCollection({ path, _id }, [assetId]));
    /** If remove last collection */
    if ((asset?.tags || []).length === 1) destroy();
  }, [dispatch, assetId, asset, destroy]);

  const activeCollectionId = useMemo(
    () => activeCollections?.length === 1 && activeCollections[0]._id,
    [activeCollections],
  );

  const activeCollection = useMemo(
    () => (asset?.tags || []).find(({ _id }) => _id === activeCollectionId),
    [activeCollectionId, asset],
  );

  /** Did mount */
  useEffect(() => {
    if (checkboxValue && activeCollection) {
      handleRemoveOne(activeCollection.path.replace('/root/', ''), activeCollection._id);
      destroy();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** If asset removed from the store, e.g. removed from current collection */
  useEffect(() => {
    if (!asset) destroy();
  }, [asset, destroy]);

  return (
    <div className="simpleDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox" style={style}>
        <div className="simpleDialogHeader">
          <span className="simpleDialogTitle">
            {title}
          </span>
          <span
            className="simpleDialogBtnCross"
            role="button"
            tabIndex={0}
            onKeyPress={handleClose}
            onClick={handleClose}
          >
            <Icon><CloseIcon /></Icon>
          </span>
        </div>
        <div className="simpleDialogContent">
          <div className="simpleDialogContentInner">
            {l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.TEXT(asset?.name)}
            <br />
            <br />
            <Choose>
              <When condition={asset?.tags?.length}>
                <ul className={styles.list}>
                  <If condition={activeCollection}>
                    <Collection
                      isActive
                      path={activeCollection.path}
                      _id={activeCollection._id}
                      onRemove={handleRemoveOne}
                    />
                  </If>
                  {asset?.tags.map((c) => {
                    if (c._id === activeCollection?._id) return null;
                    return (
                      <Collection
                        key={c._id}
                        path={c.path}
                        _id={c._id}
                        onRemove={handleRemoveOne}
                      />
                    );
                  })}
                </ul>
              </When>
              <Otherwise>
                <p>{l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.TEXT_NO_COLLECTIONS}</p>
              </Otherwise>
            </Choose>
            <br />
            <InfoBlock>
              {l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.INFO_BLOCK}
            </InfoBlock>
            <If condition={activeCollection}>
              <>
                <br />
                <InputControlLabel
                  control="checkbox"
                  label={l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.CHECKBOX}
                  value={checkboxValue}
                  onChange={handleChangeCheckbox}
                />
              </>
            </If>
          </div>
        </div>
        <div className="simpleDialogFooter">
          <If condition={textBtnCancel}>
            <Button
              color="secondary"
              size="md"
              variant="contained"
              tabIndex={0}
              onClick={handleClose}
            >
              {textBtnCancel}
            </Button>
          </If>
          <If condition={false && asset?.tags?.length && allowToRemoveAll /** hidden in first implementation */}>
            <Button
              color="primary"
              size="md"
              variant="contained"
              tabIndex={0}
              onClick={handleRemoveAll}
            >
              {l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.OK}
            </Button>
          </If>
        </div>
      </div>
    </div>
  );
};

RemoveAssetCollectionDialog.defaultProps = {
  title: l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.TITLE,
  textBtnCancel: l10n.DIALOGS.REMOVE_FROM_COLLECTIONS.CANCEL,
  allowToRemoveAll: true,
  style: {},
};

RemoveAssetCollectionDialog.propTypes = {
  destroy: func.isRequired,
  assetId: string.isRequired,
  title: string,
  textBtnCancel: string,
  style: any,
  allowToRemoveAll: bool,
};

export default RemoveAssetCollectionDialog;
