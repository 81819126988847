import { createAsyncThunk } from '@reduxjs/toolkit';

import showError from './showError';

const fetchArchivedCollections = createAsyncThunk(
  'archive/fetchArchivedCollections',
  async (payload, { extra: { sdk, Toast, utils, localization }, rejectWithValue }) => {
    const { collectionId, currentCollectionId } = payload;

    try {
      const { data: children } = await sdk.collections.getChildren(collectionId, {
        currentCollectionId,
        archived: true
      });

      return { ...payload, children };
    } catch (e) {
      showError(e, Toast, utils, localization);
      return rejectWithValue(e);
    }
  },
);

export default fetchArchivedCollections;
