import TYPES from '../../action-types';

/**
 * Rename collection
 * @param {string} id
 * @param {string} newName
 */
const renameCollection = (id, newName) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.RENAME_COLLECTION,
    payload: { id, newName },
  });
};

export default renameCollection;
