import picsioConfig from '../../../../../config';
import sdk from '../../sdk';
/**
 * Fetch revisions thumbmails for asset
 * @param {string} assetId
 * @returns Promise<AxiosResponse>
 */
export default function fetchPagesThumbnails(assetId, revisionId) {
  if (picsioConfig.isMainApp) return sdk.assets.fetchPagesThumbnails(assetId, revisionId);

  return sdk.assets.fetchPagesThumbnailsPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetId,
    revisionId,
  );
}
