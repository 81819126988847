/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import { Avatar } from '@picsio/ui';
import Tag from '../../Tag';

const DropDownCreator = (props) => {
  const {
    item, checked, highlighted, style, inProgress,
  } = props;

  const { newItems, onClick } = item;

  const leftEllipsis = (node) => {
    const childred = node.props.children;
    const itemsRenderedLength = childred[childred.length - 1].length;

    return <span>{`and ${newItems.length - itemsRenderedLength} more`}</span>;
  };
  return (
    <div
      className={cn('dropdown-item', {
        active: checked,
        highlighted,
        face: item.type === 'face',
        inProgress: item.type === 'face' && inProgress,
      })}
      onClick={onClick}
      style={style}
      data-testid={`dropdown-item-${item.value || item.type}`}
    >
      <If condition={item.type !== 'face'}>
        <div className="dropdown-item-text">
          <TruncateMarkup lines={1} lineHeight="24px" ellipsis={leftEllipsis}>
            <div className="dropdown-creator">
              Create{' '}
              {newItems.map((i) => (
                <TruncateMarkup.Atom key={`creator${i}`}>
                  <Tag type={item.type} text={i} />
                </TruncateMarkup.Atom>
              ))}
            </div>
          </TruncateMarkup>
        </div>
      </If>
      <If condition={item.type === 'face'}>
        <Avatar size={30} />
        <div className="dropdown-item-text">
          Create new person {item.value}
        </div>
      </If>
    </div>
  );
};

DropDownCreator.defaultProps = {
  inProgress: false,
  style: {},
};

DropDownCreator.propTypes = {
  item: PropTypes.shape({
    newItems: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  highlighted: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool,
  style: PropTypes.any,
};

export default memo(DropDownCreator);
