import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { DatePickerRange } from '@picsio/ui';
import Select from './select';
import localization from '../shared/strings';

import ua from '../ua';
import { datePickerDateFormat } from '../shared/dateLocale';

const { INPUT_DATE_RANGE } = localization;

const defaultOptions = [
  {
    text: INPUT_DATE_RANGE.any,
    value: 'any',
  },
  {
    text: INPUT_DATE_RANGE.today,
    value: 'today',
  },
  {
    text: INPUT_DATE_RANGE.yesterday,
    value: 'yesterday',
  },
  {
    text: INPUT_DATE_RANGE.lastweek,
    value: 'lastweek',
  },
  {
    text: INPUT_DATE_RANGE.lastmonth,
    value: 'lastmonth',
  },
  {
    text: INPUT_DATE_RANGE.last90days,
    value: 'last90days',
  },
  {
    text: INPUT_DATE_RANGE.custom,
    value: 'custom',
  },
];

class InputDateRange extends React.Component {
	isMobile = ua.browser.isNotDesktop() || ua.isMobileApp();

	constructor(props) {
	  super(props);

	  const isCustom = !defaultOptions.some((item) => item.value === props.value);
	  const valueArr = props.value.split('_');

	  this.state = {
	    isCustom,
	    startDate: isCustom && valueArr.length > 1 && valueArr[0] ? +valueArr[0] : this.isMobile ? '' : null,
	    endDate: isCustom && valueArr.length > 1 && valueArr[1] ? +valueArr[1] : this.isMobile ? '' : null,
	  };
	}

	static getDerivedStateFromProps(nextProps, prevState) {
	  if (nextProps.value !== prevState.value) {
	    const isCustom = !defaultOptions.some((item) => item.value === nextProps.value);
	    const valueArr = nextProps.value.split('_');
	    const isMobile = ua.browser.isNotDesktop();

	    return {
	      value: nextProps.value,
	      isCustom,
	      startDate: isCustom && valueArr.length > 1 && valueArr[0] ? +valueArr[0] : isMobile ? '' : null,
	      endDate: isCustom && valueArr.length > 1 && valueArr[1] ? +valueArr[1] : isMobile ? '' : null,
	    };
	  }

	  return null;
	}

	onChange = (e, value) => {
	  if (value === 'custom') {
	    this.onInputChangeStart();
	  } else {
	    this.setState({ isCustom: false });
	    this.props.onChange(value);
	  }
	};

	onInputChange = (name, value) => {
	  let { startDate, endDate } = this.state;

	  if (name === 'startDate') {
	    startDate = value;
	  }
	  if (name === 'endDate') {
	    endDate = value;
	  }
	  const start = startDate ? new Date(startDate).getTime() : '';
	  let end = endDate ? new Date(endDate).getTime() : '';
	  if (name === 'endDate' && endDate) {
	    // to include endDate we add whole day milliseconds
	    end += 24 * 60 * 60 * 1000;
	  }
	  const newValue = `${start}_${end}`;
	  this.setState({ startDate, endDate });
	  this.props.onChange(newValue);
	};

	checkForDate = (startDate, endDate) => {
		let startDateTemp = startDate;
		let endDateTemp = endDate;
		if (isNaN(startDate)) {
			startDateTemp = '';
		}
		if (isNaN(endDate)) {
			endDateTemp = '';
		}

		return `${startDateTemp}_${endDateTemp}`;
	}

	onInputChangeStart = (value) => {
		let { startDate, endDate } = this.state;
		startDate = value;
		const start = startDate ? new Date(startDate).getTime() : '';

		const end = endDate ? new Date(endDate).getTime() : '';
	  const newValue = this.checkForDate(start, end);
	  this.setState({ startDate, endDate });
	  this.props.onChange(newValue);
	}

	onInputChangeEnd = (value) => {
		let { startDate, endDate } = this.state;
		endDate = value;

	  const start = startDate ? new Date(startDate).getTime() : '';
		let end = endDate ? new Date(endDate).getTime() : '';
		// to include endDate we add whole day milliseconds
		if (endDate) {
			end += 24 * 60 * 60 * 1000;
		}
	  const newValue = this.checkForDate(start, end);
	  this.setState({ startDate, endDate });
	  this.props.onChange(newValue);
	}

	render() {
	  const { value, disabled } = this.props;
	  let { isCustom, startDate, endDate } = this.state;
	  startDate && (startDate = new Date(startDate));
	  endDate && (endDate = new Date(endDate));
	  // to show correct value in calendar we subtract 1 day that was added before
	  endDate && (endDate = new Date(endDate.setDate(endDate.getDate() - 1)));
		if (this.isMobile) {
			startDate = startDate && dayjs(startDate).utc().format('YYYY-MM-DD');
			endDate = endDate && dayjs(endDate).utc().format('YYYY-MM-DD');
		}

		return (
	    <div className="picsioInputDateRange">
	      <Select
	        value={isCustom ? 'custom' : value}
	        options={defaultOptions}
	        onChange={this.onChange}
	        disabled={disabled}
					changed={value !== 'any'}
  			/>
				<If condition={isCustom}>
					<DatePickerRange
						startDate={startDate}
						endDate={endDate}
						onInputChangeStart={this.onInputChangeStart}
						onInputChangeEnd={this.onInputChangeEnd}
						datePickerDateFormat={datePickerDateFormat}
						popperClassName="datepickerFixed"
						popperPlacement="bottom-start"
						popperProps={{ positionFixed: true }}
						popperModifiers={{
							offset: {
								enabled: true,
								offset: '5px, 10px',
							},
							preventOverflow: {
								enabled: true,
								escapeWithReference: false,
								boundariesElement: 'scrollParent',
							},
						}}
					/>
				</If>
  		</div>
	  );
	}
}

InputDateRange.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

InputDateRange.defaultProps = {
  disabled: false,
};

export default InputDateRange;
