import sdk from '../../sdk';

/**
 * Get restricted assets for collection
 * @param {string} collectionId
 * @returns Promise<AxiosResponse>
 */
export default function getRestrictedAssets(collectionId) {
  return sdk.assets.search({
    showRestricted: true,
    collectionIds: collectionId,
  });
}
