/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import defaultState from './defaultState';
import { reducer as search } from './search';
import { reducer as setSort } from './setSort';
import { reducer as create } from './create';
import { reducer as added } from './added';
import { reducer as renamed } from './renamed';
import { reducer as deleted } from './deleted';
import { reducer as rename } from './rename';
import { reducer as favorite } from './favorite';
import { reducer as move } from './move';
import { reducer as setActive } from './setActive';
import { reducer as merge } from './merge';
import { reducer as updateUsedAt } from './updateUsedAt';
import { reducer as updateCount } from './updateCount';
import { reducer as setSelected } from './setSelected';
import { reducer as addToSelection } from './addToSelection';
import { reducer as removeFromSelection } from './removeFromSelection';
import { reducer as deleteKeywords } from './delete';
import { reducer as generateForCollection } from './generateForCollection';
import { reducer as getById } from './getById';
import TYPES from '../action-types';

const keywordsReducer = createReducer(
  defaultState,
  (builder) => {
    search(builder);
    create(builder);
    setSort(builder);
    added(builder);
    renamed(builder);
    deleted(builder);
    rename(builder);
    favorite(builder);
    move(builder);
    setActive(builder);
    merge(builder);
    updateUsedAt(builder);
    updateCount(builder);
    setSelected(builder);
    addToSelection(builder);
    removeFromSelection(builder);
    deleteKeywords(builder);
    generateForCollection(builder);
    getById(builder);
    builder.addMatcher((action) => (
      action.type === TYPES.ASSETS.GENERATE_KEYWORDS.COMPLETE
    ), (state) => {
      state.isBusy = false;
    });
  },
);

export default keywordsReducer;
