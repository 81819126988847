import { ROOT_COLLECTION_PATH, PATH_DELIMITER } from '@picsio/db/src/constants';
import { findCollection } from '../../helpers/collections';
import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import { showDialog } from '../../../components/dialog';
import UiBlocker from '../../../services/UiBlocker';
import l10n from '../../../shared/strings';
import Toast from '../../../components/Toast';

export default async function checkCollections({
  items, pushCollections, updateFile, removeItems,
}) {
  /** @type {string[]} */
  const processedDeepPathes = [];
  const createCollectionRequests = [];
  const fillCollectionRequests = (path, collection, deepPath) => {
    /** if current deepPath already processed - EXIT */
    if (processedDeepPathes.includes(deepPath)) return;

    processedDeepPathes.push(deepPath);
    const deepPathArray = deepPath.slice(0, -1).split('/');
    const name = deepPathArray.splice(-1, 1)[0];
    /** if files structure has collections without files (only folders) */
    if (deepPathArray.length) {
      fillCollectionRequests(null, collection, `${deepPathArray.join('/')}/`);
    }
    const fullPath = collection.path === 'root'
      ? `/${deepPathArray.length > 0 ? `${deepPathArray.join('/')}/` : ''}`
      : `${collection.path}${collection.name}/${deepPathArray.length > 0 ? `${deepPathArray.join('/')}/` : ''}`;
    const data = { tag: { name } };
    /** if path not root */
    if (fullPath !== '/') data.path = fullPath;

    createCollectionRequests.push(async () => {
      try {
        UiBlocker.block(`Creating collection ${name}`);
        let findedCollection = findCollection({ collection }, null, { name, path: fullPath });
        if (findedCollection === undefined) {
          const { data: newCollection } = await sdk.collections.create(data);
          findedCollection = newCollection;
        }
        return { path, collection: findedCollection };
      } catch (error) {
        const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
        if (errorSubcode === 'UnsafeCharactersError') {
          Toast(l10n.IMPORT.unsafeCharactersError(data?.tag?.name), { autoClose: false, type: 'error' });
        } else if (errorSubcode === 'CollectionAlreadyExistsInArchiveApiError') {
          Toast(
            l10n.IMPORT.collectionAlreadyExistsInArchiveApiError(data?.tag?.name),
            { autoClose: false, type: 'error' },
          );
        }
        return { path, error };
      } finally {
        UiBlocker.unblock();
      }
    });
  };

  Object.keys(items)
    /** sort items by deepPath */
    .sort((pathA, pathB) => {
      const itemA = items[pathA][0];
      const itemB = items[pathB][0];
      if (itemA.deepPath && itemB.deepPath) {
        const deepCountA = itemA.deepPath.split('/').length;
        const deepCountB = itemB.deepPath.split('/').length;
        return deepCountA - deepCountB;
      }
      if (!itemA.deepPath && !itemB.deepPath) {
        return 0;
      }
      if (!itemB.deepPath) {
        return 1;
      }
      return -1;
    })
    /** get collection.storageId for every path */
    .forEach((path) => {
      const firstItem = items[path][0];
      if (firstItem.deepPath) {
        const { collection, deepPath } = firstItem;
        fillCollectionRequests(path, collection, deepPath);
      }
    });

  if (createCollectionRequests.length > 0) {
    /** create collections one by one */
    const createdCollections = await (async () => {
      const result = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < createCollectionRequests.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        result[i] = await createCollectionRequests[i]();
      }
      return result;
    })();

    createdCollections.forEach((item) => {
      if (items[item.path] && item.collection && item.collection.storageId) {
        items[item.path].forEach((file) => {
          const pathArr = item.collection.path.split(PATH_DELIMITER);
          const name = pathArr.pop();
          const path = `${pathArr.join(PATH_DELIMITER).replace(ROOT_COLLECTION_PATH, '')}${PATH_DELIMITER}`;
          const collection = {
            ...item.collection,
            name,
            path,
          };
          delete collection.user;
          delete collection.permissions;
          updateFile({
            id: file.id,
            fields: {
              collection,
              deepPath: undefined,
            },
          });
        });
      }
      /** push collection to store */
      if (item.collection) pushCollections([item.collection]);
    });

    const createCollectionErrors = createdCollections.filter((item) => !!item.error);
    if (createCollectionErrors.length) {
      Logger.error(new Error('Can not create collections'), { errors: createCollectionErrors }, [
        'ImportCantCreateCollectionsDialog',
        { errors: createCollectionErrors },
      ]);
      return new Promise((resolve) => {
        showDialog({
          title: l10n.IMPORT.cantCreateCollections.title,
          text: l10n.IMPORT.cantCreateCollections.text.replace(
            '{$list}',
            createCollectionErrors.map((item) => `<li>${item.path}</li>`).join(''),
          ),
          onCancel: () => {
            createCollectionErrors.forEach((item) => {
              removeItems(items[item.path].map(({ id }) => id));
            });
          },
          onOk: () => {
            resolve(checkCollections());
          },
          textBtnCancel: l10n.IMPORT.cantCreateCollections.btnCancel,
          textBtnOk: l10n.IMPORT.cantCreateCollections.btnOk,
        });
      });
    }
  }
  return null;
}
