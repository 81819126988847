import { createAsyncThunk } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { navigate } from '../../helpers/history';
import { showDialog } from '../../components/dialog';
import { isHaveTeammatePermission } from '../helpers/user';

const handleGoToBilling = () => {
  navigate('/billing?tab=overview');
  ReactDOM.unmountComponentAtNode(document.querySelector('.wrapperDialog'));
};

export const generateForCollection = createAsyncThunk(
  'keywords/generateForCollection',
  async (
    collectionId,
    {
      extra: {
        sdk, Logger, Toast, utils, localization,
      }, getState,
    }) => {
    const { user, roles, teammates } = getState();
    const billingDenied = !isHaveTeammatePermission('manageBilling', user);
    const keywordingDenied = !isHaveTeammatePermission('manageKeywords', user);
    const teamManagingDenied = !isHaveTeammatePermission('manageTeam', user);

    const canNotBuyApiCalls = billingDenied || keywordingDenied || teamManagingDenied;
    const usersWithPermissionsToBuyApiCalls = utils.getUsersWithPermissionToBuyKeywords(user, teammates.items, roles.items);

    let data;
    try {
      ({ data } = await sdk.keywords.generateForCollection(collectionId));
      Toast(localization.DETAILS.textKeywordsGeneration, { autoClose: false });
      if (data.hasUnsupportedAssets) {
        Toast(localization.DETAILS.textAssetsNotAllSupported, { autoClose: false });
      }
      Logger.log('Keywords', 'GenerateKeywordsLaunched');
    } catch (error) {
      Logger.error(new Error('Error keywords generation'), { error }, [
        'KeywordsGenerationFailed',
        (error && error.message) || 'NoMessage',
      ]);
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      if (errorSubcode === 'NotEnoughPaidApiCallsError') {
        showDialog({
          title: localization.DETAILS.titleNotEnoughCredits,
          text: localization.DETAILS.textReachedAiCreditLimit(canNotBuyApiCalls, usersWithPermissionsToBuyApiCalls),
          textBtnCancel: null,
          textBtnOk: canNotBuyApiCalls ? localization.DIALOGS.btnOk : localization.DIALOGS.btnSubscribe,
          onOk: canNotBuyApiCalls ? Function.prototype : handleGoToBilling,
        });
      }

      if (errorSubcode === 'AssetsNotFoundApiError' || errorSubcode === 'AllAssetsNotSupportedForKeywordingError') {
        showDialog({
          title: localization.DIALOGS.KEYWORDS_GENERATION_ASSETS_NOT_FOUND.TITLE,
          text: localization.DIALOGS.KEYWORDS_GENERATION_ASSETS_NOT_FOUND.TEXT(),
          textBtnCancel: null,
          textBtnOk: localization.DIALOGS.KEYWORDS_GENERATION_ASSETS_NOT_FOUND.BTN_OK,
        });
      }
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    generateForCollection.pending,
    (state) => ({
      ...state,
      isBusy: true,
    }),
  ).addCase(
    generateForCollection.rejected,
    (state) => ({
      ...state,
      isBusy: false,
    }),
  );
};
