export default {
  isLoaded: false,
  isPageLoading: false,
  isBusy: false,
  isFull: false,
  page: 1,
  searchPage: 1,
  tree: null,
  all: [],
  activeKeywords: [],
  selectedKeywords: [],
  searchQuery: '',
  sortType: { type: 'createdAt', order: 'asc' },
  error: null,
};
