/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPresets = createAsyncThunk(
  'websiteSettings/getPresets',
  // eslint-disable-next-line consistent-return
  async (_, {
    extra: {
      Logger, sdk, utils, showErrorDialog,
    },
  }) => {
    try {
      const { data: presets } = await sdk.websiteSettingsPresets.getAll();
      Logger.log('UI', 'WebsitePresetsLoaded');
      return presets;
    } catch (error) {
      Logger.log('UI', 'WebsitePresetsNotLoaded');
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not be fetched preset';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error website presets fetching'), { error }, [
        'FetchWebsitePresetsFailed',
        'Website presets can not be fetched from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getPresets.pending,
      (state) => {
        state.error = null;
      },
    ).addCase(
      getPresets.fulfilled,
      (state, { payload }) => {
        state.presets = payload;
        state.error = null;
      },
    ).addCase(
      getPresets.rejected,
      (state, { error }) => {
        state.presets = null;
        state.error = error;
      },
    );
};
