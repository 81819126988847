import React from 'react';
import {
  shape, number, string, func, bool, oneOfType,
} from 'prop-types';
import cn from 'classnames';
import { Interweave } from 'interweave';
import { Icon } from '@picsio/ui';
import { Face, Edit } from '@picsio/icons';
import * as utils from '../../../shared/utils';
import Tooltip from '../../Tooltip';
import localization from '../../../shared/strings';

const mentionPattern = /\B@[a-z0-9_-]+/gi;

class Marker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isResized: false,
    };
  }

  componentDidMount() {
    const key = !this.props.isFaceRecognition ? 'picsio.assets.pulledToResizedMarker' : 'picsio.assets.pulledToResizedFace';
    const isResized = utils.LocalStorage.get(key);
    if (isResized) this.setState({ isResized: true });
  }

  onMouseDownResize = (e) => {
    this.props.onMouseDownResize(e);
    const key = !this.props.isFaceRecognition ? 'picsio.assets.pulledToResizedMarker' : 'picsio.assets.pulledToResizedFace';
    utils.LocalStorage.set(key, true);
    this.setState({ isResized: true });
  }

  onFaceRecognition = () => {
    this.props.handleClickOnEdit(this.props.tmpBoundingBox.tempId);
    document.getElementById(this.props.tmpBoundingBox.tempId).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    setTimeout(() => document.getElementById(this.props.tmpBoundingBox.tempId).querySelector('.dropdown-opener').click(), 300);
  }

  render() {
    const {
      marker,
      rotation = 0,
      flipX = 1,
      flipY = 1,
      onRemove,
      onMouseDown,
      onMouseDownResize,
      onAreaMouseDown,
      isFaceRecognition = false,
      showTextContent = true,
      imageRect, // image pixels
      clientTop, // marker pixels
      clientLeft, // marker pixels
    } = this.props;

    const { isResized } = this.state;

    let newWidth = 100;
    let newHeight = 100;

    // this is for bounding boxes in small pictures, if size of an image is smaller than 100px, we need to change 100 to max width of an image
    if (isFaceRecognition) {
      const boundingBoxRightSide = clientLeft + 100;
      const boundingBoxBottomSide = clientTop + 100;
      const bottom = imageRect?.bottom;
      const right = imageRect?.right;

      if (boundingBoxRightSide > right) {
        newWidth = right - clientLeft - 2;
      }
      if (boundingBoxBottomSide > bottom) {
        newHeight = bottom - clientTop - 2;
      }
    }

    const __html = marker.text
      && marker.text.replace(mentionPattern, (mentionString) => {
        const mentionID = mentionString.substring(1);
        const mention = marker.mentions
          ? marker.mentions.find((mention) => mention._id === mentionID)
          : null;
        if (mention) {
          return `<span class="itemHistoryList__main__text__mentionedUser">@${mention.displayName}</span>`;
        }
        return mentionString;
      });

    const point = handleRotation(marker.x, marker.y, flipX, flipY, rotation);

    let point2;
    if (marker.x2 !== undefined && marker.y2 !== undefined) {
      point2 = handleRotation(marker.x2, marker.y2, flipX, flipY, rotation);
    }

    return (
      <div className={cn({ active: point2, tmpMarker: onRemove })}>
        <div
          className={cn('poi', { draggable: onAreaMouseDown || onMouseDown, isFaceRecognition })}
          onMouseDown={onAreaMouseDown}
          style={{
            left: `${
              (point2 && point2.offsetX < point.offsetX ? point2.offsetX : point.offsetX) * 100
            }%`,
            top: `${
              (point2 && point2.offsetY < point.offsetY ? point2.offsetY : point.offsetY) * 100
            }%`,
            right: point2
              ? `${
                (point2.offsetX > point.offsetX ? 1 - point2.offsetX : 1 - point.offsetX) * 100
              }%` : 'auto',
            bottom: point2
              ? `${
                (point2.offsetY > point.offsetY ? 1 - point2.offsetY : 1 - point.offsetY) * 100
              }%` : 'auto',
            width: isFaceRecognition && !point2 ? `${newWidth}px` : 'unset',
            height: isFaceRecognition && !point2 ? `${newHeight}px` : 'unset',
          }}
        >
          {onMouseDownResize && (
            <div
              role="presentation"
              className={cn('poiResizer', { faceRecogInitial: isFaceRecognition && !point2 })}
              onMouseDown={this.onMouseDownResize}
            >
              <div className={cn('poiResizerArrow', { isFaceRecognition })} />
              <If condition={!isResized}>
                <div className={cn('poiResizerText', { isFaceRecognition })}>
                  {localization.HISTORY.textPullToResize}
                </div>
              </If>
            </div>
          )}
          <If condition={isFaceRecognition}>
            <div
              className="tempBoundingBoxTextContainer"
              onClick={this.onFaceRecognition}
            >
              <div className="text">
                {localization.MARKERS.selectPerson}
              </div>
            </div>
          </If>
        </div>
        <div
          className="itemHistoryMarker"
          style={{
            left: `${point.offsetX * 100}%`,
            top: `${point.offsetY * 100}%`,
          }}
        >

          <div className={cn('circleHistoryMarker', { isFaceRecognition })} onMouseDown={onMouseDown}>
            <If condition={!isFaceRecognition}>
              {marker.number}
            </If>
            <If condition={isFaceRecognition}>
              <Icon id="button-face">
                <Face />
              </Icon>
            </If>
          </div>

          {showTextContent && (marker.userName || marker.text) && (
            <div
              className="commentHistoryMarker"
              style={{
                top: point.offsetY < 0.5 ? '50%' : 'auto',
                bottom: point.offsetY >= 0.5 ? '50%' : 'auto',
                left: point.offsetX < 0.5 ? '50%' : 'auto',
                right: point.offsetX >= 0.5 ? '50%' : 'auto',
              }}
            >
              <div className="authorHistoryMarker">{marker.userName}</div>
              <div className="textHistoryMarker">
                <Interweave content={__html} />
              </div>
            </div>
          )}
          {onRemove && (
            <Tooltip content={isFaceRecognition ? localization.MARKERS.removeBoundingBoxFrameTooltip : localization.MARKERS.removeMarkerTooltip} placement="top">
              <i className="removeHistoryMarker" onClick={onRemove}>
                +
              </i>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}

Marker.propTypes = {
  marker: shape({
    number: oneOfType([number, string]),
    x: number,
    y: number,
    x2: number,
    y2: number,
    userName: string,
    text: string,
  }).isRequired,
  showTextContent: bool,
  rotation: number,
  flipX: number,
  flipY: number,
  onRemove: func,
  onMouseDown: func,
  onMouseDownResize: func,
  onAreaMouseDown: func,
  isFaceRecognition: bool,
  imageRect: shape({
    bottom: number,
    right: number,
  }),
  clientTop: number,
  clientLeft: number,
};

Marker.defaultProps = {
  imageRect: null,
  clientTop: null,
  clientLeft: null,
};

export default Marker;

function handleRotation(x, y, flipX, flipY, rotation) {
  let offsetX = x;
  let offsetY = y;

  if (rotation === 90) {
    offsetX = 1 - y;
    offsetY = x;
  }
  if (rotation === 180) {
    offsetX = 1 - x;
    offsetY = 1 - y;
  }
  if (rotation === 270) {
    offsetX = y;
    offsetY = 1 - x;
  }
  /* handle flip */
  if (flipX === -1) offsetX = 1 - offsetX;
  if (flipY === -1) offsetY = 1 - offsetY;

  return { offsetX, offsetY };
}
