import React, { memo, useMemo } from 'react';
import { Button } from '@picsio/ui';
import { AiEngine } from '@picsio/icons';
import {
  arrayOf, bool, element, func, number, objectOf, oneOfType, shape, string,
} from 'prop-types';

import Tooltip from '../../../Tooltip';
import TwoLevelMenu from '../../../TwoLevelMenu';

import styles from './styles.module.scss';

const AiButtons = ({
  inProgress, buttonText, onSubmit, menuControls, menuValue, onChangeMenu, tooltipText, buttonTestId, disabled,
}) => {
  const startIcon = useMemo(() => {
    if (inProgress) return <span className="indicator" />;
    return <AiEngine />;
  }, [inProgress]);

  return (
    <div className={styles.aiButtons}>
      <Tooltip content={tooltipText} placement="top">
        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          startIcon={startIcon}
          disabled={inProgress || disabled}
          size="md"
          componentProps={{ 'data-testid': buttonTestId }}
        >
          {buttonText}
        </Button>
      </Tooltip>
      <If condition={menuControls}>
        <TwoLevelMenu
          className={styles.aiButtonsMenu}
          controls={menuControls}
          value={menuValue}
          onChange={onChangeMenu}
          disabled={inProgress}
          testId="aiSettings"
        />
      </If>
    </div>
  );
};

AiButtons.defaultProps = {
  inProgress: false,
  menuControls: null,
  buttonText: 'Generate',
  menuValue: {},
  onChangeMenu: Function.prototype,
  tooltipText: null,
  buttonTestId: null,
  disabled: false,
};

AiButtons.propTypes = {
  onSubmit: func.isRequired,
  inProgress: bool,
  buttonText: string,
  menuControls: arrayOf(shape({
    id: oneOfType([string, number]),
    text: string,
    Icon: oneOfType([func, element]),
    items: arrayOf(shape({
      id: oneOfType([string, number]),
      text: string,
    })),
  })),
  menuValue: objectOf(oneOfType([string, number])),
  onChangeMenu: func,
  tooltipText: string,
  buttonTestId: string,
  disabled: bool,
};

export default memo(AiButtons);
