import TYPES from '../../action-types';

/**
 * Change collection color immediately
 * @param {string} collectionId
 * @param {string} color
 */
function changedCollectionColor(collectionId, color) {
  return async (dispatch) => {
    dispatch({
      type: TYPES.COLLECTIONS.CHANGE_COLOR.COMPLETE,
      payload: {
        collectionId,
        color,
      },
    });
  };
}

export default changedCollectionColor;
