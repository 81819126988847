import ReactDOM from 'react-dom';

import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import { isHaveTeammatePermission } from '../../helpers/user';
import { navigate } from '../../../helpers/history';
import Logger from '../../../services/Logger';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

/**
 * Generate keywords for assets
 * @param {string[]} assetIds
 */
const generateKeywords = ({
  assetIds, locale, relevance, visionProvider,
}) => async (dispatch, getAll) => {
  const { user, roles, teammates } = getAll();
  const { customer, team, addonsFeatures = {} } = user;
  const { aiCreditsPaid, aiCreditsUsed } = team;
  const { subscription, balance } = customer;
  let pricePer1000Keywords = 2000;
  if (subscription && subscription.metadata && subscription.metadata.pricePer1000Keywords) {
    pricePer1000Keywords = subscription.metadata.pricePer1000Keywords;
  }
  const userBalance = balance || 0;

  const assetsLengthAllowedToKeywordingByBalance = Math.floor((Math.abs(userBalance) / pricePer1000Keywords) * 1000) || 0;
  const billingDenied = !isHaveTeammatePermission('manageBilling', user);
  const keywordingDenied = !isHaveTeammatePermission('manageKeywords', user);
  const teamManagingDenied = !isHaveTeammatePermission('manageTeam', user);

  const canNotBuyApiCalls = billingDenied || keywordingDenied || teamManagingDenied;

  const handleGoToBilling = () => {
    navigate('/billing?tab=overview');
    ReactDOM.unmountComponentAtNode(document.querySelector('.wrapperDialog'));
  };

  const usersWithPermissionsToBuyApiCalls = utils.getUsersWithPermissionToBuyKeywords(user, teammates.items, roles.items);

  let totalAssetsAllowedForKeywording = assetsLengthAllowedToKeywordingByBalance + aiCreditsPaid - aiCreditsUsed;
  // if user clicks fast, assetsKeyworded can more aiCreditsPaid and we receive 'minus' value
  if (totalAssetsAllowedForKeywording < 0) totalAssetsAllowedForKeywording = 0;
  Logger.log('User', 'infoPanelKeywordsAutogenerate', {
    assetsLength: assetIds.length,
    allowedAssets: totalAssetsAllowedForKeywording,
  });

  if ((totalAssetsAllowedForKeywording === 0 || totalAssetsAllowedForKeywording < assetIds.length) && !addonsFeatures?.aiKit) {
    let html = null;
    let textBtnOk;
    let textBtnCancel;
    let onOk = canNotBuyApiCalls
      ? Function.prototype
      : () => navigate('/billing?tab=overview');
    if (totalAssetsAllowedForKeywording === 0) {
      html = localization.DETAILS.textReachedAutokeywordingLimit(
        canNotBuyApiCalls,
        usersWithPermissionsToBuyApiCalls,
      );
      if (!canNotBuyApiCalls) {
        textBtnOk = localization.DIALOGS.btnSubscribe;
        textBtnCancel = null;
        onOk = handleGoToBilling;
      }
    } else {
      html = localization.DETAILS.textCantAutokeywordingForSelectedAssets(
        () => handleGoToBilling(),
        canNotBuyApiCalls,
        usersWithPermissionsToBuyApiCalls,
        totalAssetsAllowedForKeywording,
      );
      if (!canNotBuyApiCalls) {
        textBtnOk = localization.DIALOGS.btnSubscribe;
        textBtnCancel = null;
        onOk = handleGoToBilling;
      }
    }

    return showDialog({
      title: localization.DETAILS.titleNotEnoughCredits,
      children: html,
      textBtnOk,
      textBtnCancel,
      onOk,
    });
  }

  try {
    dispatch({
      type: TYPES.ASSETS.GENERATE_KEYWORDS.START,
      payload: { assetsIds: assetIds },
    });

    const { data } = await sdk.keywords.generate(assetIds, locale, relevance, visionProvider);

    Toast(localization.DETAILS.textKeywordsGeneration, { autoClose: false });
    if (data.hasUnsupportedAssets) {
      Toast(localization.DETAILS.textAssetsNotAllSupported, { autoClose: false });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ASSETS.GENERATE_KEYWORDS.FAILED,
      payload: { assetsIds: assetIds },
      error,
    });

    let title;
    let text;
    let html;
    let disableOk;
    let textBtnCancel;
    let textBtnOk;
    let onOk = Function.prototype;

    if (utils.getDataFromResponceError(error, 'error') === 'NOT_ENOUGH_CREDITS') {
      title = localization.DETAILS.titleNotEnoughCredits;
      html = localization.DETAILS.textReachedAutokeywordingLimit(
        canNotBuyApiCalls,
        usersWithPermissionsToBuyApiCalls,
      );
      if (!canNotBuyApiCalls) {
        textBtnOk = localization.DIALOGS.btnSubscribe;
        textBtnCancel = null;
        onOk = handleGoToBilling;
      }
    } else if (utils.getDataFromResponceError(error, 'subcode') === 'AllAssetsNotSupportedForKeywordingError') {
      title = localization.DIALOGS.INCORRECT_EXTENSION_ERROR;
      text = localization.DIALOGS.GENERATING_KEYWORDS.textError;
      textBtnCancel = null;
    } else {
      title = localization.SOMETHING_WENT_WRONG.TITLE;
      text = localization.SOMETHING_WENT_WRONG.TEXT;
      disableOk = true;
    }

    showDialog({
      title,
      children: html,
      text,
      textBtnCancel,
      textBtnOk,
      onOk,
      dialogConfig: {
        disableCancel: false,
        disableOk,
        okName: localization.DETAILS.textOpenBilling,
      },
    });
    Logger.error(new Error('Error generate keywords'), { error }, [
      'GenerateKeywordsFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};

export default generateKeywords;
