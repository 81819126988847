import TYPES from '../../action-types';

/**
 * Set website
 * @param {string} collectionID
 * @param {Object} value
 */
const setWebsite = (collectionID, value, notify) => (dispatch) => dispatch({
  type: TYPES.COLLECTIONS.SET_WEBSITE,
  payload: { collectionID, value, notify },
});

export default setWebsite;
