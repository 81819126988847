import normalizeUser from './normalizeUser';

/**
 * Normalize users
 * @param {Object},
 */
export default function normalizeUsers(users, roles) {
  const newUsers = users.map((user) => normalizeUser(user, roles));
  return newUsers;
}
