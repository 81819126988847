import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

export default async function addReactionToComment(
  assetId,
  commentId,
  { userId, value, guestName },
) {
  if (picsioConfig.isMainApp) {
    await sdk.assets.addReactionToComment(assetId, commentId, { userId, value });
  } else {
    await sdk.assets.addReactionToCommentPublic(
      picsioConfig.apiBaseUrl,
      window.websiteConfig.alias,
      assetId,
      commentId,
      { userId, value, guestName },
    );
  }

  return {
    user: {
      _id: userId,
    },
    guestName,
    value,
  };
}
