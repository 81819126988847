/* eslint-disable no-param-reassign */
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';
import { createAction } from '@reduxjs/toolkit';
import getFilesByIds from './helpers/getFilesByIds';
import validateFields from './helpers/validateFields';
import groupErrors from './helpers/groupErrors';

/** e.g. keywords, assignees */
export const addToListField = createAction('import/addToListField');

export const reducer = (builder) => {
  builder.addCase(
    addToListField.type,
    (state, { payload: { title, value } }) => {
      /** update fields */
      state.fields[title] = _uniqBy([...state.fields[title], ...value], '_id');

      /** update field on selected files */
      const selectedFiles = getFilesByIds(state.items, state.selected);
      selectedFiles.forEach((file) => {
        file.fields[title] = _uniqBy([...file.fields[title], ...value], '_id');
        if (state.isUploadSubmitted || !_isEmpty(file.errors || {})) {
          const { isValid, errors, error } = validateFields({ fields: file.fields, requiredFields: state.requiredFields, importFrom: file.file?.importFrom });
          file.errors = errors;
          file.error = error;
          file.isValid = isValid;
        }
      });
      state.errors = groupErrors(selectedFiles);
    },
  );
};
