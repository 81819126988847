import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';
import { showErrorDialog } from '../../dialog';
import l18n from '../../../shared/strings';

const uploadImagesForWebsite = async ({ websiteId, settings }) => {
  const uploadedImages = {};
  try {
    if (settings?.avatarUrl && settings?.avatarUrl !== 'imagePicked') {
      const { data: { url } } = await sdk.websites.uploadAvatar(websiteId, settings.avatarUrl);
      uploadedImages.avatarUrl = url;
    }
    if (settings?.backgroundLoginPageUrl && settings?.backgroundLoginPageUrl !== 'imagePicked') {
      const { data: { url } } = await sdk.websites.uploadBackground(websiteId, settings.backgroundLoginPageUrl);
      uploadedImages.backgroundLoginPageUrl = url;
    }
    if (settings?.thumbnailUrl && settings?.thumbnailUrl !== 'imagePicked') {
      const { data: { url } } = await sdk.websites.uploadThumbnail(websiteId, settings.thumbnailUrl);
      uploadedImages.thumbnailUrl = url;
    }
    if (settings?.logoUrl && settings?.logoUrl !== 'imagePicked') {
      const { data: { url } } = await sdk.websites.uploadLogo(websiteId, settings.logoUrl);
      uploadedImages.logoUrl = url;
    }
  } catch (error) {
    const errorMessage = utils.getDataFromResponceError(error, 'msg')
    || l18n.WEBSITES.textWebsiteSomeImagesUnsuccessful;
    showErrorDialog(errorMessage);
  }

  return uploadedImages;
};

export default uploadImagesForWebsite;
