import { createAsyncThunk } from '@reduxjs/toolkit';

import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import { showDialog } from '../../../components/dialog';
import sdk from '../../../sdk';

/**
 * Sync folder
 * @param {string} collectionId
 * @param {string} strategy
 */
const syncFolder = createAsyncThunk(
  'collections/syncFolder',
  async (payload) => {
    try {
      await sdk.collections.syncFolder(payload.collectionId, payload.strategy);
      showDialog({
        title: localization.FOLDER_SYNC.syncHasStartedTitle,
        text: localization.FOLDER_SYNC.syncHasStartedText,
        textBtnCancel: null,
      });
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 409) {
        showDialog({
          title: localization.FOLDER_SYNC.syncAlreadyRunningErrorTitle,
          text: localization.FOLDER_SYNC.syncAlreadyRunningErrorText,
          textBtnCancel: null,
        });
      }
      Logger.error(new Error('Can not sync folder'), { error }, [
        'SyncFolderFailed',
      ]);
    }
  },
);

export default syncFolder;
