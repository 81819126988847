import * as utils from '../../../shared/utils';
import sort from './sort';

/**
 * Make search tree
 * @param {Object[]} all
 * @param {string} searchQuery
 * @param {string} sortType
 * @returns {Object} search tree
 */
const facesMakeSearchTree = (all, searchQuery, sortType) => {
  const faces = all.filter((node) => node.name
    .toLowerCase()
    .includes(searchQuery.toLowerCase()));
  return {
    favorites: {
      title: 'Favorites',
      root: true,
      _id: 'favorites',
      isOpen:
        utils.LocalStorage.get('picsio.facesTree.favorites.open') === null
        || utils.LocalStorage.get('picsio.facesTree.favorites.open'),
      nodes: sort(faces.filter((node) => !!node.isFavorite), sortType),
    },
    allFaces: {
      title: 'All persons',
      root: true,
      _id: 'allFaces',
      isOpen:
        utils.LocalStorage.get('picsio.facesTree.allFaces.open') === null
        || utils.LocalStorage.get('picsio.facesTree.allFaces.open'),
      nodes: sort(faces.filter((node) => !node.unnamed), sortType),
    },
    unnamed: {
      title: 'Unnamed faces',
      root: true,
      _id: 'unnamed',
      isOpen:
        utils.LocalStorage.get('picsio.facesTree.unnamed.open') === null || utils.LocalStorage.get('picsio.facesTree.unnamed.open'),
      nodes: sort(faces.filter((node) => !!node.unnamed), sortType),
    },
  };
};

export default facesMakeSearchTree;
