/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';
import groupFields from './helpers/groupFields';
import groupErrors from './helpers/groupErrors';
import getFilesByIds from './helpers/getFilesByIds';
import groupPermissions from './helpers/groupPermissions';

export const addToSelection = createAction('import/addToSelection');

export const reducer = (builder) => {
  builder.addCase(
    addToSelection.type,
    (state, { payload: ids }) => {
      state.selected = _uniq([...state.selected, ...ids]);
      const selectedItems = getFilesByIds(state.items, state.selected);
      state.fields = groupFields(selectedItems);
      state.errors = groupErrors(selectedItems);
      state.permissions = groupPermissions(selectedItems);
    },
  );
};
