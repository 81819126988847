import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import TYPES from '../../action-types';
import { isRoutePreview } from '../../../helpers/history';

/**
 * Re-run parsing
 * @param {string} jobName
 */
const reRunParsing = (jobName) => async (dispatch, getAll) => {
  let ids = getAll().assets.selectedItems;
  Logger.log('User', `Rerun${utils.capitalizeFirstLetter(jobName)}`, ids.length);

  if (ids.length === 0) {
    if (isRoutePreview()) {
      const previewID = window.location.pathname.split('/').pop();
      ids = [previewID];
    }
  }

  try {
    dispatch({
      type: TYPES.ASSETS.RERUN_PARSING.START,
      payload: {
        ids,
        jobName,
      },
    });

    await sdk.assets.rerunJob(ids, jobName);

    dispatch({
      type: TYPES.ASSETS.RERUN_PARSING.COMPLETE,
      payload: {
        ids,
        jobName,
      },
    });
  } catch (error) {
    Logger.error(new Error(`Error re-run ${jobName}`), { error }, [
      `Rerun${utils.capitalizeFirstLetter(jobName)}Failed`,
      (error && error.message) || 'NoMessage',
    ]);
    dispatch({ type: TYPES.ASSETS.RERUN_PARSING.FAILED, error });
  }
};

export default reRunParsing;
