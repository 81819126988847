import { createAsyncThunk } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { isHaveTeammatePermission } from '../../helpers/user';
import { navigate } from '../../../helpers/history';
import { showDialog } from '../../../components/dialog';
import DialogRadios from '../../../components/dialogRadios';
import descriptionGenerationStarted from './descriptionGenerationStarted';

const GENERATE_DESCRIPTION_COOKIE_KEY = 'picsio.generateDescriptionDialogVisible';

const generateDescription = createAsyncThunk(
  'asset/generateDescription',
  async ({
    assetsIds,
  }, {
    extra: {
      sdk, Logger, utils, Toast, localization,
    }, dispatch, getState,
  }) => {
    const { user, roles, teammates } = getState();
    const { customer, team, addonsFeatures = {} } = user;
    const { aiCreditsPaid, aiCreditsUsed } = team;
    const { subscription, balance } = customer;
    let pricePer1000Descriptions = 1000;
    if (subscription && subscription.metadata && subscription.metadata.pricePer1000Descriptions) {
      pricePer1000Descriptions = subscription.metadata.pricePer1000Descriptions;
    }
    const userBalance = balance || 0;

    const assetsLengthAllowedToDescribingByBalance = Math.floor((Math.abs(userBalance) / pricePer1000Descriptions) * 1000) || 0;
    const billingDenied = !isHaveTeammatePermission('manageBilling', user);
    const teamManagingDenied = !isHaveTeammatePermission('manageTeam', user);

    const canNotBuyApiCalls = billingDenied || teamManagingDenied;

    const handleGoToBilling = () => {
      navigate('/billing?tab=overview');
      ReactDOM.unmountComponentAtNode(document.querySelector('.wrapperDialog'));
    };

    const usersWithPermissionsToBuyApiCalls = utils.getUsersWithPermissionToBuyKeywords(user, teammates.items, roles.items);

    let totalAssetsAllowedForDescribing = assetsLengthAllowedToDescribingByBalance + aiCreditsPaid - aiCreditsUsed;
    // if user clicks fast, assetsKeyworded can more aiCreditsPaid and we receive 'minus' value
    if (totalAssetsAllowedForDescribing < 0) totalAssetsAllowedForDescribing = 0;
    Logger.log('User', 'infoPanelDescriptionAutogenerate', {
      assetsLength: assetsIds.length,
      allowedAssets: totalAssetsAllowedForDescribing,
    });

    if ((totalAssetsAllowedForDescribing === 0 || totalAssetsAllowedForDescribing < assetsIds.length) && !addonsFeatures?.aiKit) {
      let html;
      let textBtnOk;
      let textBtnCancel;
      let onOk = canNotBuyApiCalls
        ? Function.prototype
        : () => navigate('/billing?tab=overview');
      if (totalAssetsAllowedForDescribing === 0) {
        html = localization.DETAILS.textReachedAutokeywordingLimit(
          canNotBuyApiCalls,
          usersWithPermissionsToBuyApiCalls,
        );
        if (!canNotBuyApiCalls) {
          textBtnOk = localization.DIALOGS.btnSubscribe;
          textBtnCancel = null;
          onOk = handleGoToBilling;
        }
      } else {
        html = localization.DETAILS.textCantDescribingForSelectedAssets(
          () => handleGoToBilling(),
          canNotBuyApiCalls,
          usersWithPermissionsToBuyApiCalls,
          totalAssetsAllowedForDescribing,
        );
        if (!canNotBuyApiCalls) {
          textBtnOk = localization.DIALOGS.btnSubscribe;
          textBtnCancel = null;
          onOk = handleGoToBilling;
        }
      }

      return showDialog({
        title: localization.DETAILS.titleNotEnoughCredits,
        children: html,
        textBtnOk,
        textBtnCancel,
        onOk,
      });
    }

    async function generate(updateDescriptionOption) {
      try {
        dispatch(descriptionGenerationStarted(assetsIds));
        const { data } = await sdk.assets.generateDescription(assetsIds, updateDescriptionOption);

        Toast(localization.DETAILS.toastTextDescriptionGeneration, { autoClose: false });
        if (data.hasUnsupportedAssets) {
          Toast(localization.DETAILS.textAssetsNotAllSupported, { autoClose: false });
        }
      } catch (error) {
        let title;
        let text;
        let html;
        let disableOk;
        let textBtnCancel;
        let textBtnOk;
        let onOk = Function.prototype;

        if (utils.getDataFromResponceError(error, 'error') === 'NOT_ENOUGH_CREDITS') {
          title = localization.DETAILS.titleNotEnoughCredits;
          html = localization.DETAILS.textReachedAutokeywordingLimit(
            canNotBuyApiCalls,
            usersWithPermissionsToBuyApiCalls,
          );
          if (!canNotBuyApiCalls) {
            textBtnOk = localization.DIALOGS.btnSubscribe;
            textBtnCancel = null;
            onOk = handleGoToBilling;
          }
        } else if (utils.getDataFromResponceError(error, 'subcode') === 'AllAssetsNotSupportedForDescribingError') {
          title = localization.DIALOGS.INCORRECT_EXTENSION_ERROR;
          text = localization.DIALOGS.GENERATING_DESCRIPTION_UNSUPPORTED.textError;
          textBtnCancel = null;
        } else {
          title = localization.SOMETHING_WENT_WRONG.TITLE;
          text = localization.SOMETHING_WENT_WRONG.TEXT;
          disableOk = true;
        }

        showDialog({
          title,
          children: html,
          text,
          textBtnCancel,
          textBtnOk,
          onOk,
          dialogConfig: {
            disableCancel: false,
            disableOk,
            okName: localization.DETAILS.textOpenBilling,
          },
        });
        Logger.error(new Error('Error generate description'), { error }, [
          'GenerateDescriptionFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }
    }

    const selectedAssetsWithDescription = getState().assets.items.filter((asset) => assetsIds.some((id) => id === asset._id && !!asset.description));

    if (selectedAssetsWithDescription.length) {
      const cookieValue = utils.getCookie(GENERATE_DESCRIPTION_COOKIE_KEY);

      if (typeof cookieValue === 'string') {
        await generate(cookieValue);
      } else {
        // eslint-disable-next-line no-new
        new DialogRadios({
          title: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.title,
          text: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.text,
          items: [
            {
              label: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.margeDescriptionLabel,
              value: 'merge',
              checked: true,
              description: '',
            },
            {
              label: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.replaceDescriptionLabel,
              value: 'replace',
              checked: false,
              description: '',
            },
          ],
          cancelName: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.cancelName,
          okName: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.okName,
          onOk: (value, checkboxValue) => {
            if (checkboxValue) utils.setCookie(GENERATE_DESCRIPTION_COOKIE_KEY, value);
            generate(value);
          },
          checkbox: {
            value: false,
            label: localization.DIALOGS.GENERATING_DESCRIPTION_CHOOSE_OPTION.checkboxLabel,
            onChange: () => { },
          },
        });
      }
    } else {
      await generate('replace');
    }
  },
);

export default generateDescription;
