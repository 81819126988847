/* eslint-disable no-param-reassign */
import _uniqBy from 'lodash/uniqBy';
import _cloneDeep from 'lodash/cloneDeep';
import _uniq from 'lodash/uniq';

export const DIFFERENT_VALUES = Symbol('DIFFERENT VALUES');

export default function groupFields(files) {
  return files.reduce((result, file, index) => {
    const { fields } = file;
    if (index === 0) return _cloneDeep(fields);

    Object.keys(result).forEach((key) => {
      const value = result[key];
      /** regular fields */
      if (['string', 'number', 'boolean'].includes(typeof value) || value === null) {
        if (value !== fields[key]) result[key] = DIFFERENT_VALUES;
      }
      /** keywords and assignees => collect all values */
      if (Array.isArray(value)) {
        result[key] = _uniqBy([...result[key], ...fields[key]], '_id');
      }
      /** Custom fields */
      if (key === 'customFields') {
        Object.keys(value).forEach((cfTitle) => {
          /** if no need to change */
          if (result[key][cfTitle] === fields[key][cfTitle]) return;

          if (result[key][cfTitle]?.value !== fields[key][cfTitle]?.value) {
            if (result[key][cfTitle]?.type === 'enum' && result[key][cfTitle]?.multiple) {
              result[key][cfTitle] = {
                value: _uniq(((result[key][cfTitle]?.value || '') + (fields[key][cfTitle]?.value || '')).split(',')).join(','),
                type: 'enum',
              };
            } else {
              result[key][cfTitle] = {
                value: DIFFERENT_VALUES,
                type: result[key][cfTitle]?.type,
              };
            }
          }
        });
      }
    });

    return result;
  }, {});
}
