/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import {
  bool, string, func, oneOfType, objectOf, any,
} from 'prop-types';
import cn from 'classnames';
import ToolbarPreviewLeft from '../../toolbars/ToolbarPreviewLeft';
import * as utils from '../../../shared/utils';
import getDownloadUrl from '../../../helpers/getDownloadUrl';
import Logger from '../../../services/Logger';
import Spinner from './Spinner';
import buildStreamProxyUrl from '../../../helpers/assets/buildStreamProxyUrl'; // eslint-disable-line

class Audio extends React.Component {
  $audio = React.createRef();

  state = {
    assetId: null,
    src: null,
    isLoaded: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.asset._id !== state.assetId) {
      return {
        assetId: props.asset._id,
        isLoaded: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { asset } = this.props;
    this.$audio.current.addEventListener('timeupdate', this.handleTimeUpdate);
    window.addEventListener('revision:added', this.reloadView, false);
    this.updateSrc(asset._id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { assetId } = this.state;

    if (prevState.assetId !== assetId) {
      this.updateSrc(assetId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('revision:added', this.reloadView, false);
    this.$audio.current.removeEventListener('timeupdate', this.handleTimeUpdate);
  }

  getUserId() {
    const { isMainApp, teamId } = this.props;
    return isMainApp ? teamId : window.websiteConfig.userId;
  }

  updateSrc = async (assetId, useProxy = false) => {
    const { isMainApp, asset, revisionID } = this.props;
    const isGDstorageFileOnWebsite = !isMainApp && asset.storageType === 'gd';
    let src;
    try {
      if (isGDstorageFileOnWebsite || useProxy) {
        const { data } = await buildStreamProxyUrl(asset._id, asset.storageId, revisionID || 'head');
        src = data;
      } else {
        src = await getDownloadUrl({ assetId, allowDownloadByGS: false, forPreviewDownload: true });
      }
    } catch (err) {
      Logger.error(new Error('Can not get download url [Audio]'), {
        error: err,
        assetId,
      });
    }
    if (src !== this.state.src) {
      this.setState({ src }, this.reloadView);
    }
  };

  reloadView = () => {
    /* reload audio */
    if (this.$audio.current) {
      this.$audio.current.src = this.state.src;
      this.$audio.current.load();
    }
  };

  onLoad = () => this.setState({ isLoaded: true });

  handleTimeUpdate = (event) => {
    const { currentTime } = event.target;
    const { props } = this;

    if (event.target === this.$audio.current) {
      if (props.setAudioCurrentTime) {
        props.setAudioCurrentTime(currentTime);
      }
    }
  };

  handleError = () => {
    this.updateSrc(this.props.asset._id, true);
  }

  /** @param {number} time - in seconds */
  setCurrentTime = (time, setPause = false) => {
    const { props } = this;
    if (props.setAudioCurrentTime) {
      props.setAudioCurrentTime(time);
    }
    this.$audio.current.currentTime = time;
    if (setPause && !this.$audio.current.paused) this.$video.current.pause();
  };

  render() {
    const {
      props, state, $audio, onLoad,
    } = this;
    const { src, isLoaded } = state;
    const {
      asset, isMainApp, addRevision, handleDownload, moveToTrash, isRemoveForever,
    } = props;
    const isRestricted = utils.isAssetRestricted(asset.restrictSettings);

    return (
      <div className="innerContainerMediaFile">
        {isMainApp && (
          <ToolbarPreviewLeft
            assetId={asset._id}
            addRevision={addRevision}
            download={handleDownload}
            moveToTrash={moveToTrash}
            permissions={asset.permissions}
            isRestricted={isRestricted}
            isRemoveForever={isRemoveForever}
            isArchived={asset.archived}
          />
        )}
        <div className={cn('theMediaFile', 'theMediaFileAudio')}>
          <audio controls preload="auto" onCanPlay={onLoad} onLoadedMetadata={onLoad} ref={$audio} onError={this.handleError}>
            <source src={src} type={asset.mimeType} />
          </audio>
          {/* spinner */}
          {!isLoaded && <Spinner title="Loading audio..." />}
        </div>
      </div>
    );
  }
}

export default Audio;

Audio.defaultProps = {
  revisionID: null,
  addRevision: false,
  handleDownload: Function.prototype,
  moveToTrash: false,
  teamId: undefined,
  isRemoveForever: false,
  setAudioCurrentTime: Function.prototype,
};

/** Prop types */
Audio.propTypes = {
  asset: objectOf(any).isRequired,
  isMainApp: bool.isRequired,
  revisionID: string,
  addRevision: oneOfType([bool, func]),
  handleDownload: func,
  moveToTrash: oneOfType([bool, func]),
  teamId: oneOfType([string]),
  isRemoveForever: bool,
  setAudioCurrentTime: func,
};
