import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Attach keyword to assets
 * @param {string[]} assetIds
 * @param {string} keywordName
 * @returns Promise<AxiosResponse>
 */
export default function attachKeyword(assetIds, keywordName) {
  if (picsioConfig.isMainApp) return sdk.keywords.attach(assetIds, keywordName);

  return sdk.keywords.attachPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    keywordName,
  );
}
