import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DotsVertical } from '@picsio/ui/dist/icons';

import {
  IconButton, Menu, MenuItem, MenuItemText,
} from '@picsio/ui';
import { Close, Eye, EyeClosed } from '@picsio/icons';
import cn from 'classnames';
import localization from '../../../../shared/strings';
import Logger from '../../../../services/Logger';
import { LocalStorage } from '../../../../shared/utils';

const menuItems = [
  {
    id: 'resetDefaultView',
    text: localization.DETAILS.textResetToDefaultView,
  },
];

const DetailsPanelEditHeader = ({ scrollWidth, checkedFieldsSize, onSelect, toggleEditPanel, isNotEmptyList, resetToDefault }) => {
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => setMenuOpen((prevValue) => !prevValue), []);

  const menuItemsHandlers = {
    resetDefaultView: () => {
      LocalStorage.remove('picsio.editWidgetsConfig');
      Logger.log('User', 'CustomizeViewResetToDefault');
      resetToDefault(true);
    },
  };

  return (
    <div className="detailsPanelEdit__header">
      <div className="detailsPanelEdit__headerControls">
        <IconButton onClick={toggleEditPanel} size="xl" className="detailsPanelEdit__closeIcon">
          <Close />
        </IconButton>
        <h4>Customize view</h4>
        <IconButton
          ref={ref}
          buttonSize="default"
          className={cn({ isActive: isMenuOpen })}
          color="default"
          component="button"
          disabled={false}
          id="assetMenuOpener"
          onClick={toggleMenu}
          size="lg"
        >
          <DotsVertical />
        </IconButton>
        <Menu
          target={ref}
          arrow
          padding="s"
          placement="bottom-end"
          isOpen={isMenuOpen}
          onClose={toggleMenu}
          outsideClickListener
        >
          {menuItems.map((control) => {
            const {
              id, text,
            } = control;

            return (
              <MenuItem
                key={id}
                id={id}
                onClick={() => {
                  if (menuItemsHandlers[id]) {
                    menuItemsHandlers[id]();
                  }
                  toggleMenu();
                }}
                className="menuItemDefault"
              >
                <MenuItemText primary={text} />
              </MenuItem>
            );
          })}
        </Menu>
      </div>
      <If condition={isNotEmptyList}>
        <div className="detailsPanelEdit__headerTitle" style={{ marginRight: scrollWidth || 0 }}>
          <div>Metadata fields</div>
          <IconButton onClick={onSelect} size="lg">
            { checkedFieldsSize ? <Eye /> : <EyeClosed /> }
          </IconButton>
        </div>
      </If>
    </div>
  );
};

DetailsPanelEditHeader.propTypes = {
  toggleEditPanel: PropTypes.func.isRequired,
};

export default memo(DetailsPanelEditHeader);
