import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { Input, IconButton } from '@picsio/ui';
import { Ok, CloseIcon } from '@picsio/icons';
import {
  string, arrayOf, func, number, shape,
} from 'prop-types';

const NEW_COLLECTION = 'New collection';

const AddItemForm = ({
  defaultValue, item, forbiddenNames, onSubmit, onCancel, indent,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const $input = useRef();
  const isMounted = useRef(true);

  const validate = useCallback((newValue) => {
    const hasError = !newValue || forbiddenNames.includes(newValue.toLowerCase().trim());
    if (hasError && !error) setError(true);
    if (!hasError && error) setError(false);
    setValue(newValue);
  }, [error, setError, setValue, forbiddenNames]);

  /** Did mount */
  useEffect(() => {
    if (!defaultValue) {
      let newValue = NEW_COLLECTION;
      let num = 2;
      while (forbiddenNames.includes(newValue.toLowerCase()) && num < 501) {
        newValue = `${NEW_COLLECTION} ${num}`;
        num += 1;
      }
      validate(newValue);
      setTimeout(() => {
        if ($input.current) $input.current.firstChild.select();
      }, 100);
    } else {
      validate(defaultValue);
    }

    return () => {
      isMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((e) => {
    validate(e.currentTarget.value);
  }, [validate]);

  const handleSubmit = useCallback(() => {
    if (error) return;
    onSubmit(item, value);
  }, [item, onSubmit, value, error]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error) onCancel();
        handleSubmit();
      }
    }, 200);
  }, [error, handleSubmit, onCancel]);

  const handleKeydown = useCallback((e) => {
    e.stopPropagation();
    switch (e.keyCode) {
    /** Esc */
    case 27:
      onCancel();
      break;
      /** Enter */
    case 13:
      handleSubmit();
      break;
    default:
      break;
    }
  }, [handleSubmit, onCancel]);

  return (
    <div className="dropdownTreeItem">
      <div className="row" style={{ paddingLeft: indent }}>
        <div className="dropdownTreeAddForm">
          <Input
            value={value}
            ref={$input}
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeydown}
            error={error}
          />
          <div className="dropdownTreeFormButtons leftShadow">
            <IconButton onClick={onCancel} size="xs"><CloseIcon /></IconButton>
            <IconButton onClick={handleSubmit} disabled={error} size="xs"><Ok /></IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

AddItemForm.defaultProps = {
  indent: 0,
  onSubmit: Function.prototype,
  onCancel: Function.prototype,
  forbiddenNames: [],
  defaultValue: '',
};

AddItemForm.propTypes = {
  defaultValue: string,
  item: shape({
    _id: string,
    name: string,
    path: string,
  }).isRequired,
  forbiddenNames: arrayOf(string),
  onSubmit: func,
  onCancel: func,
  indent: number,
};

export default AddItemForm;
