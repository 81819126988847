import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { createReduxHistoryContext } from 'redux-first-history';
import * as utils from '../shared/utils';
import localization from '../shared/strings';
import UiBlocker from '../services/UiBlocker';
import Spinner from '../components/spinner';
import appHistory from '../helpers/history';
import Logger from '../services/Logger';
import { showErrorDialog, showDialogAsync, showDialog } from '../components/dialog';

import config from '../../../../config';
import reducers from './reducers';
import middlewares from './middlewares';
import Toast from '../components/Toast';

import sdk from '../sdk';

export const thunkExtra = {
  sdk,
  utils,
  localization,
  UiBlocker,
  Toast,
  Spinner,
  Logger,
  showDialog,
  showErrorDialog,
  showDialogAsync,
};

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: appHistory,
  showHistoryAction: true,
});

const store = configureStore({
  reducer: reducers(() => routerReducer(appHistory)),
  middleware: [thunk.withExtraArgument(thunkExtra), ...middlewares].concat(routerMiddleware),
  devTools: config.env === 'development',
});

export const reduxHistoryStore = createReduxHistory(store);
export default store;
