import TYPES from '../../action-types';

/**
 * @param {Array} assets
 */
function thumbnailingComplete(assets = []) {
  return (dispatch) => {
    dispatch({
      type: TYPES.ASSETS.SET_CUSTOM_THUMBNAILS,
      payload: { assets },
    });
  };
}

export default thumbnailingComplete;
