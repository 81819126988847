/**
 * This helper allow us to get all teammates with some roleId
 * @param {Object},
 */
export default function getTeammatesWithRole(roleId, teammates) {
  return teammates.reduce((acc, teammate) => {
    const { parent } = teammate;
    const teammateRoleId = parent && parent.teammateRoleId;

    if (teammateRoleId === roleId) {
      acc.push({
        _id: teammate._id,
        displayName: teammate.displayName,
        email: teammate.email.toLowerCase(),
        avatar: teammate.avatar,
      });
    }
    return acc;
  }, []);
}
