import sanitize from 'sanitize-filename';

function replacement(unsafeChar) {
  if (unsafeChar === '/') return '-';
  return '_';
}

/**
 * Sanitize a string to be safe for use as a filename
 * @param {string} name
 * @returns {string}
 */
export default function sanitizeFilename(name) {
  let nameTemp = name;
  if (nameTemp.startsWith('https')) {
    [, nameTemp] = nameTemp.split('https://');
  } else if (nameTemp.startsWith('http')) {
    [, nameTemp] = nameTemp.split('http://');
  }
  nameTemp = nameTemp.replace(/(%|\[|\])/g, '-');
  return sanitize(nameTemp, { replacement });
}
