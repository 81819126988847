import {
  arrayOf, bool, element, oneOfType, shape, string, func, any,
} from 'prop-types';
import React, { useMemo } from 'react';
import picsioConfig from '../../../../../../../config';
import { isAssetRestricted } from '../../../../shared/utils';
import ToolbarPreviewLeft from '../../../toolbars/ToolbarPreviewLeft';
import Spinner from '../Spinner';

export default function Wrapper({
  bg = '', isLoading = false, children, asset, moveToTrash,
}) {
  const style = useMemo(() => ({ backgroundImage: `url("${bg}")` }), [bg]);
  return (
    <>
      <If condition={picsioConfig.isMainApp}>
        <ToolbarPreviewLeft
          assetId={asset._id}
          moveToTrash={moveToTrash}
          permissions={asset.permissions}
          isRestricted={isAssetRestricted(asset.restrictSettings)}
        />
      </If>
      <div className="external-preview" style={style}>
        <If condition={isLoading}>
          <Spinner text="Loading..." />
        </If>
        {children}
      </div>
    </>
  );
}

Wrapper.defaultProps = {
  bg: '',
  isLoading: false,
  children: null,
};

Wrapper.propTypes = {
  bg: string,
  isLoading: bool,
  asset: shape({
    _id: string,
    permissions: any,
    restrictSettings: any,
  }).isRequired,
  moveToTrash: func.isRequired,
  children: oneOfType([element, string, arrayOf(element), arrayOf(string)]),
};
