import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Detach keyword from assets
 * @param {string[]} assetIds
 * @param {string} keywordId
 * @returns Promise<AxiosResponse>
 */
export default function detachKeyword(assetIds, keywordId) {
  if (picsioConfig.isMainApp) return sdk.keywords.detach(assetIds, keywordId);

  return sdk.keywords.detachPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetIds,
    keywordId,
  );
}
