import React from 'react';
import './styles.scss';
import { EyeClosed } from '@picsio/icons';

const DetailPanelEmpty = ({ text }) => {
  return (
    <div className="detailPanelEmpty">
      <EyeClosed className="detailPanelEmpty__icon" />
      <div className="detailPanelEmpty__text">
        { text }
      </div>
    </div>
  );
}

export default DetailPanelEmpty;
