import React from 'react';
import { string, func } from 'prop-types';
import { IconButton } from '@picsio/ui';
import { CloseIcon, Warning } from '@picsio/icons';

import './styles.scss';

const PreviewWarning = ({ text, onClose }) => {
  return (
    <div className="previewWarning">
      <div className="previewWarning__container">
        <Warning className="previewWarning__warningIcon" />
        <div className="previewWarning__text">
          { text }
        </div>
        <IconButton className="previewWarning__closeIcon" size="lg" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

PreviewWarning.defaultProps = {
  text: '',
  onClose: Function.prototype,
};

PreviewWarning.propTypes = {
  text: string,
  onClose: func,
};

export default PreviewWarning;
