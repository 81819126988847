import { createAsyncThunk } from '@reduxjs/toolkit';
import { showDialog } from '../../../components/dialog';
import l18n from '../../../shared/strings';

const removeApiKey = createAsyncThunk(
  'user/removeApiKey',
  async (keyId, { extra: { sdk, Logger, Toast } }) => {
    try {
      try {
        await new Promise((resolve, reject) => {
          const { title, text, textBtnOk } = l18n.API_KEYS.removeDialog;
          showDialog({
            title,
            text,
            onOk: resolve,
            onCancel: reject,
            textBtnOk,
          });
        });
      } catch (error) {
        /** Cancelled */
        return { keyId, success: false };
      }

      await sdk.users.removeApiKey(keyId);
      return { keyId, success: true };
    } catch (error) {
      Logger.error(new Error('Can not remove api key'), { error }, [
        'RemoveUserApiKeyFailed',
        error?.message || 'NoMessage',
      ]);
      Toast(l18n.TOAST.API_KEY.REMOVE, { error: true });
      throw error;
    }
  },
);

export default removeApiKey;
