import React from 'react';
import picsioConfig from '../../../../../config';
import localization from '../../shared/strings';

const PlaceholderSelectedAssets = () => (
  <div className="placeholderEmpty">
    <div className="placeholderEmptyTitle">
      {localization.SELECTED_ASSETS_EMPTY.TITLE()}
      <If condition={picsioConfig.isMainApp}>
        {localization.SELECTED_ASSETS_EMPTY.TEXT}
      </If>
    </div>
  </div>
);

export default PlaceholderSelectedAssets;
