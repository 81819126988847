import { createAsyncThunk } from '@reduxjs/toolkit';
import * as assetsHelpers from '../../../helpers/assets';
import TYPES from '../../action-types';
import hasAllAssets from '../../helpers/assets/hasAllAssets';
import picsioConfig from '../../../../../../config';

const deselectAll = createAsyncThunk(
  'asset.deselectAll',
  async (args, {
    dispatch, getState,
    extra: {
      sdk, Logger, utils, showErrorDialog, UiBlocker,
    },
  }) => {
    const { assets: assetsStore, router, user: userStore } = getState();
    const { permissions: rolePermissions = null } = userStore.role || {};

    if (picsioConfig.isMainApp) {
      /** we have all the data, which means we have selected from only one collection */
      if (
        args?.deselectAllFromClipboard
        || (assetsStore.items.length >= assetsStore.selectedItems.length
          && hasAllAssets(assetsStore.selectedItems, assetsStore.items)
        )) {
        return dispatch({
          type: TYPES.ASSETS.DESELECT_ALL,
          payload: {
            routeIsSelectedAssets: router.location.pathname === '/selectedAssets',
          },
        });
      }
      try {
        UiBlocker.block();
        /** get all assets ids of current url if there is not enough data in store */
        let selected;
        if (assetsStore.items.length === assetsStore.total) {
          selected = assetsStore.items;
        } else {
          const { data } = await assetsHelpers.selectAll();
          selected = data.images;
        }

        /** remove from store selectedItems every asset of current url */
        const filteredSelectedItems = assetsStore.selectedItems.filter(
          (selectedItemId) => selected.findIndex(({ _id }) => _id === selectedItemId) === -1,
        );

        /** when All assets are deselect */
        if (!filteredSelectedItems.length) {
          return dispatch({
            type: TYPES.ASSETS.DESELECT_ALL,
            payload: {
              routeIsSelectedAssets: router.location.pathname === '/selectedAssets',
            },
          });
        }

        /** when nothing to deselect */
        const isAlreadyDeselected = filteredSelectedItems.length === assetsStore.selectedItems.length;

        if (isAlreadyDeselected) {
          return;
        }

        /** get new aggregatedData */
        const aggregatedData = await assetsHelpers.getAggregatedData(
          filteredSelectedItems, userStore,
        );

        /** get permissions for the actions toolbar */
        const permissionsNames = Object.keys(assetsStore.items[0].permissions);
        const { data: assetsBatchPermissions } = await sdk.assets.getPermissions(
          filteredSelectedItems, permissionsNames,
        );
        return dispatch({
          type: TYPES.ASSETS.DESELECT_ALL,
          payload: {
            routeIsSelectedAssets: router.location.pathname === '/selectedAssets',
            selectedItems: filteredSelectedItems,
            aggregatedData,
            assetsBatchPermissions,
            rolePermissions,
          },
        });
      } catch (error) {
        const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not deselect all assets from selection';
        showErrorDialog(errorMessage);
        Logger.error(
          new Error('Error deselect all'),
          { error },
          ['DeselectAllFailed', errorMessage || 'NoMessage'],
        );
        throw error;
      } finally {
        UiBlocker.unblock();
      }
    } else {
      dispatch({
        type: TYPES.ASSETS.DESELECT_ALL,
        payload: {},
      });

      if (!args?.shouldNotDispatchSwitchOffEvent) {
        window.dispatchEvent(new Event('selection:switched:off'));
      }
    }
  },
);

export default deselectAll;
