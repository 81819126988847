import React, { useCallback, useState } from 'react';
import { InputControlLabel } from '@picsio/ui';
import { number } from 'prop-types';

import { showDialogAsync } from '../../../components/dialog';
import { LocalStorage } from '../../../shared/utils';
import l10n from '../../../shared/strings';

const LS_KEY = 'picsio.hideDetachingSeveralKeywordsWarning';
const { TITLE, TEXT, CHECKBOX } = l10n.DIALOGS.DETACH_SEVERAL_KEYWORDS;

const Content = ({ keywordCount, assetCount }) => {
  const [value, setValue] = useState(LocalStorage.get(LS_KEY) || false);

  const handleChange = useCallback(() => {
    setValue((prev) => {
      LocalStorage.set(LS_KEY, !prev);
      return !prev;
    });
  }, [setValue]);

  return (
    <>
      <div>{TEXT(keywordCount, assetCount)}</div>
      <br />
      <InputControlLabel
        control="checkbox"
        label={CHECKBOX}
        value={value}
        onChange={handleChange}
      />
    </>
  );
};

Content.propTypes = {
  keywordCount: number.isRequired,
  assetCount: number.isRequired,
};

export default async function showWarningDetachingSeveralKeywords(keywordCount, assetCount) {
  try {
    const hideDialog = LocalStorage.get(LS_KEY) || false;
    if (!hideDialog) {
      const { promise } = showDialogAsync({
        title: TITLE,
        children: <Content keywordCount={keywordCount} assetCount={assetCount} />,
      });
      await promise;
    }
    return true;
  } catch (e) {
    /** User has cancelled the operation */
    return false;
  }
}
