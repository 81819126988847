import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@picsio/ui';
import cn from 'classnames';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import ListItem from '../ListItem';
import virtualizedList from '../utils/dropboxVirtualizedList';
import SkeletonList from '../SkeletonList';
import s from './List.module.scss';
import i18n from '../../../shared/strings';

export default function List({
  list, selectedItems, onSelect, onClickFolder, isLoading,
}) {
  const [styles, setStyles] = useState([]);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [flatGroups, setFlatGroups] = useState([]);
  const [topItemIndex, setTopItemIndex] = useState(0);
  const [bottomItemIndex, setBottomItemIndex] = useState(100);
  const { subscriptionFeatures = {} } = useSelector((state) => state.user);
  const { planId } = subscriptionFeatures;
  const isTrialUser = planId === 'trial';
  const filteredList = list.filter((item) => item['.tag'] === 'file' || (item['.tag'] === 'folder' && !isTrialUser));

  useEffect(() => {
    const { flatGroups: groups, wrapperHeight: wrapper, styles: style } = virtualizedList(list);
    setStyles(style);
    setWrapperHeight(wrapper);
    setFlatGroups(groups);
  }, [list]);

  const toggleSelectionAll = () => {
    if (selectedItems.length === filteredList.length) {
      onSelect([]);
      return;
    }
    onSelect(filteredList);
  };

  const toggleSelectionItem = (item) => {
    const filteredItems = selectedItems.filter(({ id }) => id !== item.id);
    if (filteredItems.length === selectedItems.length) {
      onSelect([...selectedItems, item]);
      return;
    }
    onSelect(filteredItems);
  };

  const closestValue = (array, value) => {
    let result;
    let lastDelta;

    array.some((item) => {
      const delta = Math.abs(value - item);
      if (delta >= lastDelta) {
        return true;
      }
      result = item;
      lastDelta = delta;
      return false;
    });
    return result;
  };

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    const holderHeight = event.target.offsetHeight;
    const topItem = closestValue(styles, scrollTop);
    const bottomItem = closestValue(styles, holderHeight + scrollTop);
    setTopItemIndex(styles.findIndex((item) => item === topItem));
    setBottomItemIndex(styles.findIndex((item) => item === bottomItem));
  };

  const handleClickFolder = (path) => {
    onClickFolder(path);
  };

  return (
    <div className={s.List}>
      <div className={cn(s.header, !list.length && s.disabled)}>
        <div className={s.leftGroupLabel}>
          <div className={s.checkbox}>
            <Checkbox
              checked={selectedItems.length === filteredList.length && selectedItems.length + filteredList.length !== 0}
              onChange={toggleSelectionAll}
              inputProps={{ 'data-testid': 'dropboxChooserCheckboxAll' }}
            />
          </div>
          <div className={s.label}>
            {i18n.DROPBOX_CHOOSER.labelName}{' '}
            <span className={s.counter}>{`(${pluralize('item', selectedItems.length, true)} selected) `}</span>
          </div>
        </div>
        <div className={s.rightGroupLabel}>
          <div className={s.label}>{i18n.DROPBOX_CHOOSER.labelSize}</div>
          <div className={s.label}>{i18n.DROPBOX_CHOOSER.labelLastModified}</div>
        </div>
      </div>
      <div className={cn(s.listWrapper, (!wrapperHeight && list.length) && s.listItemsWrapperHidden)} onScroll={handleScroll}>
        <Choose>
          <When condition={list.length && !isLoading}>
            <ul className={cn(s.list)} style={{ height: wrapperHeight }}>
              {flatGroups.slice(topItemIndex, bottomItemIndex + 1).map((item, index) => (
                <ListItem
                  className={index % 2 === 0 ? s.even : ''}
                  key={item.id}
                  selectedItems={selectedItems}
                  data={item}
                  top={item.top}
                  onSelect={toggleSelectionItem}
                  onClickFolder={handleClickFolder}
                  isTrialUser={isTrialUser}
                />
              ))}
            </ul>
          </When>
          <Otherwise>
            <Choose>
              <When condition={isLoading}>
                <SkeletonList count={10} />
              </When>
              <Otherwise>
                <div className={s.emptyList}>
                  {i18n.DROPBOX_CHOOSER.textFolderEmptyState}
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>

      </div>
    </div>
  );
}

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickFolder: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
