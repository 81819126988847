import sdk from '../../../sdk';
import localization from '../../../shared/strings';
import Logger from '../../../services/Logger';
import { showDialog } from '../../../components/dialog';

const showDestructiveSyncErrorDialog = (code, websites, getState, isPartial = false) => {
  if (code === 'WebsitesExistSyncError') {
    let html = '';
    if (websites && websites.length) {
      const websiteToOrderedListHtml = (website) => `<li>${website.path.replace(
        '/root',
        getState().collections.collections.my.name || 'My team library',
      )}</li>`;
      html = `<ol>${websites.map(websiteToOrderedListHtml).join('')}</ol>`;
    }
    Logger.log('UI', 'SyncWebsitesDetectedDialogShow');
    showDialog({
      title: localization.SYNC.textTitleDestructiveSync,
      textBtnCancel: null,
      textBtnOk: localization.DIALOGS.btnConfirm,
      onClose: () => {
        Logger.log('User', 'SyncWebsitesDetectedDialogCancel');
      },
      onOk: () => {
        Logger.log('User', 'SyncWebsitesDetectedDialogOk');
      },
      onCancel: () => {},
      text: localization.SYNC.textTextSyncDestructiveWebsitesTemplate(html),
    });
  }

  if (code === 'DestructiveSyncError') {
    Logger.log('UI', 'SyncDestructiveDialogShow');
    showDialog({
      title: localization.SYNC.textTitleDestructiveSync,
      textBtnOk: localization.DIALOGS.btnConfirm,
      textBtnCancel: localization.DIALOGS.btnCancel,
      onClose: () => {
        if (isPartial) Logger.log('User', 'PartialSyncDestructiveDialogCancel');
        else Logger.log('User', 'SyncDestructiveDialogCancel');
      },
      onOk: () => {
        if (isPartial) {
          Logger.log('User', 'PartialSyncDestructiveDialogOk');
          sdk.sync.restartPartialSync(true);
        } else {
          Logger.log('User', 'SyncDestructiveDialogOk');
          sdk.sync.start(true);
        }
      },
      onCancel: () => {
        if (isPartial) Logger.log('User', 'PartialSyncDestructiveDialogCancel');
        else Logger.log('User', 'SyncDestructiveDialogCancel');
      },
      text: localization.SYNC.textTextSyncCancelledTemplate,
    });
  }
};

export default showDestructiveSyncErrorDialog;
