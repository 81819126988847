import React, { useCallback, memo } from 'react';
import {
  MenuItem, MenuItemText,
} from '@picsio/ui';
import PropTypes from 'prop-types';

const PresetMenuItem = ({
  id, action, text, onClick,
}) => {
  const handleClick = useCallback((e) => {
    onClick(e, action);
  }, [onClick, action]);

  return (
    <MenuItem
      id={id}
      onClick={handleClick}
      className="menuItemDefault"
    >
      <MenuItemText primary={text} />
    </MenuItem>
  );
};

PresetMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(PresetMenuItem);
