import { createReducer } from '@reduxjs/toolkit';

import defaultState from './defaultState';
import { reducer as getPage } from './getPage';
import { reducer as getByIds } from './getByIds';
import { reducer as add } from './add';
import remove from './remove';
import rename from './rename';

const keywordsListReducer = createReducer(
  defaultState,
  (builder) => {
    getPage(builder);
    getByIds(builder);
    add(builder);
    remove(builder);
    rename(builder);
  },
);

export default keywordsListReducer;
