import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon } from '@picsio/ui';
import { Folder, AddFileImport } from '@picsio/icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { Interweave } from 'interweave';
import * as utils from '../../../shared/utils';
import s from './ListItem.module.scss';
import shortenString from '../utils/shortenString';
import Tooltip from '../../Tooltip';
import l18n from '../../../shared/strings';

export default function ListItem({
  className = '',
  selectedItems = [],
  data = {},
  top,
  onSelect,
  onClickFolder,
  isTrialUser,
}) {
  const isItemSelected = selectedItems.some((item) => item.id === data.id);
  const isFolder = data['.tag'] === 'folder';
  const disableCheckFolder = isTrialUser && isFolder;

  const handleToggleSelect = () => {
    onSelect(data);
  };

  const handleOpenFolder = () => {
    onClickFolder(data.path_lower);
  };

  return (
    <div className={cn(s.ListItem, className)} style={{ top }}>
      <div className={s.leftGroupLabel}>
        <Tooltip
          content={(
            <If condition={disableCheckFolder}>
              <Interweave content={l18n.DROPBOX_CHOOSER.tooltipForDisabledFolder} />
            </If>
          )}
          key={disableCheckFolder ? 'disabled' : 'enabled'}
        >
          <div className={s.checkbox}>

            <Checkbox
              checked={isItemSelected}
              onChange={handleToggleSelect}
              inputProps={{
                'data-testid': `${data.name}`,
              }}
              disabled={disableCheckFolder}
            />
          </div>
        </Tooltip>
        <div
          className={cn(s.label, isFolder && s.isFolder)}
          onClick={isFolder ? handleOpenFolder : null}
          role="presentation"
        >
          <Choose>
            <When condition={isFolder}>
              <Icon size="md" className={s.icon}>
                <Folder />
              </Icon>
            </When>
            <Otherwise>
              <Icon size="md" className={s.icon}>
                <AddFileImport />
              </Icon>
            </Otherwise>
          </Choose>
          <span className={s.name}>{shortenString(data.name)}</span>
        </div>
      </div>
      <div className={s.rightGroupLabel}>
        <If condition={data.size}>
          <div className={s.label}>{utils.bytesToSize(data.size)}</div>
        </If>
        <If condition={data.client_modified}>
          <div className={cn(s.label, s.date)}>
            {dayjs(data.client_modified).format('DD MMM YYYY')}
          </div>
        </If>
      </div>
    </div>
  );
}

ListItem.propTypes = {
  className: PropTypes.string,
  isTrialUser: PropTypes.bool.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickFolder: PropTypes.func.isRequired,
};
