import React, {
  memo, useState, useCallback, useRef,
} from 'react';
import cn from 'classnames';
import {
  Menu, IconButton, MenuItem, MenuItemIcon, MenuItemText, Tooltip,
} from '@picsio/ui';
import {
  CaptureVideoScreen,
  CreateVideoThumb,
} from '@picsio/icons';
import { CSSTransition } from 'react-transition-group';
import {
  func, bool,
} from 'prop-types';
import picsioConfig from '../../../../../../../../config';
import s from './PlayerThumbnailMenu.module.scss';

const PlayerThumbnailMenu = (props) => {
  const {
    inProgress,
    onTakeScreenshot,
    onCreateCustomThumbnail,
  } = props;

  const isMainApp = picsioConfig.isMainApp;
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => setMenuOpen((prevValue) => !prevValue), []);

  const handleTakeScreenShot = useCallback(() => {
    onTakeScreenshot();
    toggleMenu();
  }, [onTakeScreenshot, toggleMenu]);

  const handleCreateCustomThumbnail = useCallback(() => {
    onCreateCustomThumbnail();
    toggleMenu();
  }, [onCreateCustomThumbnail, toggleMenu]);

  return (
    <>
      <Tooltip content="Snapshot & Custom thumbnail" placement="top" popperProps={{ disablePortal: true, strategy: 'fixed' }}>
        <div>
          <IconButton
            ref={ref}
            buttonSize="default"
            className={cn('playerThumbnailMenu', { isActive: isMenuOpen, inProgress })}
            color="default"
            component="button"
            disabled={false}
            id="thumbnailMenuOpener"
            onClick={toggleMenu}
            size="xxxl"
          >
            <CreateVideoThumb />
          </IconButton>
        </div>
      </Tooltip>
      <CSSTransition
        unmountOnExit
        in={isMenuOpen}
        timeout={300}
        classNames="fade"
      >
        <Menu
          target={ref}
          arrow={false}
          padding="s"
          placement="top"
          isOpen={isMenuOpen}
          onClose={toggleMenu}
          outsideClickListener
          disablePortal
          PopperProps={{ strategy: 'fixed' }}
        >
          {/* Snapshot */}
          <MenuItem
            id="btnCaptureVideoScreen"
            onClick={handleTakeScreenShot}
            className={s.menuItem}
          >
            <MenuItemIcon size="xxl">
              <CaptureVideoScreen />
            </MenuItemIcon>
            <MenuItemText className={s.menuItemText} primary="Take a snapshot (S)" />
          </MenuItem>

          {/* Custom thumbnail */}
          {isMainApp && (
            <MenuItem
              id="btnCreateVideoThumbnail"
              onClick={handleCreateCustomThumbnail}
              className={s.menuItem}
            >
              <MenuItemIcon size="xxl">
                <CreateVideoThumb />
              </MenuItemIcon>
              <MenuItemText className={s.menuItemText} primary="Create custom thumbnail (T)" />
            </MenuItem>
          )}
        </Menu>
      </CSSTransition>
    </>
  );
};

PlayerThumbnailMenu.defaultProps = {
  inProgress: false,
};

PlayerThumbnailMenu.propTypes = {
  inProgress: bool,
  onTakeScreenshot: func.isRequired,
  onCreateCustomThumbnail: func.isRequired,

};

export default memo(PlayerThumbnailMenu);
