/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';
import { facesMakeTree } from '../helpers/faces';
import l18n from '../../shared/strings';

export const rename = createAsyncThunk(
  'faces/rename',
  async ({
    id, name, description,
  }, {
    extra: {
      sdk, Logger, showErrorDialog, utils, Toast,
    },
  }) => {
    try {
      await sdk.faces.rename(id, name, description);
      Toast(l18n.TOAST.PERSON_RENAMED);
      return {
        id, name, description,
      };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not rename face';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error face renaming'),
        { error },
        ['RenameFaceFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      rename.pending,
      (state, { meta }) => {
        const { id } = meta.arg;
        state.all.forEach((face) => {
          if (face._id === id) face.isBusy = true;
        });
      },
    )
    .addCase(
      rename.fulfilled,
      (state, { payload }) => {
        const updatedFaces = state.all.map((face) => {
          if (face._id === payload.id) {
            return {
              ...face,
              name: payload.name,
              description: payload.description,
              isBusy: false,
              unnamed: false,
              isAvatarUrlReady: true,
            };
          }
          return face;
        });
        const sortedFaces = _sortBy(updatedFaces, ['name']);
        state.all = sortedFaces;
        state.tree = facesMakeTree(state.all, state.sortType);
      },
    )
    .addCase(
      rename.rejected,
      (state, { meta, error }) => {
        state.all.forEach((face) => {
          if (face._id === meta.arg.id) {
            delete face.isBusy;
          }
        });
        state.error = error;
      },
    );
};
