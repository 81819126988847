import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTargetCollection } from '../helpers/archive';
import decrementCount from '../collections/decrementCount';
import addCollections from './addCollections';
import navigateToRoot from './navigateToRoot';
import showError from './showError';
import TYPES from '../../action-types';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';

const archiveCollection = createAsyncThunk(
  'archive/archiveCollection',
  async (payload, {
    extra: {
      sdk, utils, localization, UiBlocker, Toast,
    }, rejectWithValue, dispatch, getState,
  }) => {
    const { collectionId, reason } = payload;
    const { user } = getState();

    try {
      UiBlocker.block('Processing...');

      const { data: res } = await sdk.collections.archive(collectionId, reason);
      const { collections } = res;
      const targetCollection = getTargetCollection(collections, collectionId);
      const { name } = targetCollection;

      dispatch(addCollections({ ...res, user: getState().user }));
      dispatch({
        type: TYPES.COLLECTIONS.ARCHIVE.COMPLETE,
        payload: {
          isArchived: true,
        },
      });
      dispatch(decrementCount(res.assetsCount));

      navigateToRoot(getState);
      Toast(localization.TOAST.COLLECTION_ARCHIVED(name), { type: 'success', closeButton: false });

      return res;
    } catch (e) {
      showError(e, Toast, utils, localization);
      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default archiveCollection;
