import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import UiBlocker from '../../../services/UiBlocker';
import TYPES from '../../action-types';

const createWatermark = (name) => async (dispatch, getAll) => {
  const { watermarks } = getAll().assets;
  try {
    UiBlocker.block();
    const { data: res } = await sdk.watermarks.create(name);
    watermarks.push(res);
    dispatch({
      type: TYPES.ASSETS.FETCH_WATERMARKS,
      payload: {
        watermarks,
      },
    });
    UiBlocker.unblock();
  } catch (error) {
    Logger.error(new Error('Error creating watermarks'), { error, showDialog: false }, [
      'CreateWatermarkFailed',
      (error && error.message) || 'NoMessage',
    ]);
    UiBlocker.unblock();
  }
};

export default createWatermark;
