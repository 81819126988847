import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showDialog } from '../../../components/dialog';
import sanitizeXSS from '../../../shared/sanitizeXSS';
import l18n from '../../../shared/strings';
import { getDataFromResponceError } from '../../../shared/utils';
import copyTextToClipboard from '../../../helpers/copyTextToClipboard';

const createApiKey = createAsyncThunk(
  'user/createApiKey',
  async (_, { extra: { sdk, Logger, Toast } }) => {
    try {
      const { textarea: description } = await new Promise((resolve) => {
        const { title, textBtnOk } = l18n.API_KEYS.createDialog;
        showDialog({
          title,
          textarea: { value: '', autoFocus: true },
          onOk: resolve,
          onCancel: () => resolve({}),
          textBtnOk,
        });
      });

      /** Cancel */
      if (description === undefined) return null;

      const { data } = await sdk.users.createApiKey(sanitizeXSS(description.trim()));
      const {
        title, text, textBtnOk, textBtnCancel,
      } = l18n.API_KEYS.saveDialog;
      showDialog({
        title,
        style: { maxWidth: 700 },
        children: (
          <>
            {text}
            <code>{data.apiKey}</code>
          </>
        ),
        onOk: () => copyTextToClipboard(data.apiKey, l18n.DETAILS.apiKeyCopied),
        textBtnOk,
        textBtnCancel,
      });
      return data;
    } catch (error) {
      const subcode = getDataFromResponceError(error, 'subcode');
      if (subcode === 'LimitApiKeysPerUserExceededApiError') {
        Logger.log('User limit api keys exceeded');
        Toast(l18n.API_KEYS.limitExceeded);
        return null;
      }
      Logger.error(new Error('Can not create api key'), { error }, [
        'CreateUserApiKeyFailed',
        error?.message || 'NoMessage',
      ]);
      Toast(l18n.TOAST.API_KEY.CREATE, { autoClose: false, type: 'error' });
      throw error;
    }
  },
);

export default createApiKey;
