import sdk from '../../sdk';

/**
 * @param {string} collectionId
 * @param {boolean?} archived
 * @returns Promise<AxiosResponse>
 */
export default function getCollectionAssets(collectionId, archived = false) {
  return sdk.assets.search({
    size: 'all',
    archived,
    responseSchema: {
      full: 1,
      images: {
        _id: 1,
        googleId: 1,
        storageId: 1,
        name: 1,
        mimeType: 1,
        restrictSettings: 1,
        permissions: { downloadFiles: 1 },
        fileSize: 1,
      },
      total: 1,
    },
    collectionIds: collectionId,
  });
}
