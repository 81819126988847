import TYPES from '../../action-types';

/**
 * Rename collection
 * @param {Object} collection
 * @param {string} newName
 */
const renamedCollection = (collection) => (dispatch) => dispatch({
  type: TYPES.COLLECTIONS.RENAME.COMPLETE,
  payload: { collectionID: collection._id, newName: collection.name, notify: true },
});

export default renamedCollection;
