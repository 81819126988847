import React from 'react';
import {
  bool, string, func, shape,
} from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from '@picsio/ui';
import _isEqual from 'lodash/isEqual';

import ua from '../../ua';
import { datePickerPlaceholder, datePickerDateFormat, isValidDate } from '../../shared/dateLocale';

class InputDate extends React.Component {
  /** @type {boolean} */
  isMobile = ua.browser.isNotDesktop() || ua.isMobileApp();

  state = { value: '' };

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      this.setState({ value });
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value });
    }
  }

  handleChange = (newDate) => {
    const { value: prevValue, customField, onChange } = this.props;
    if (!_isEqual(prevValue, newDate)) {
      let date = newDate || '';
      if (isValidDate(newDate)) {
        date = dayjs(newDate).format('YYYY-MM-DD');
        date = new Date(date).toISOString();
      } else {
        date = '';
      }
      this.setState({ value: date });
      onChange(date, customField, () => this.setState(prevValue));
    }
  };

  render() {
    const { value } = this.state;
    const { customField, disabled = false, placeholder = datePickerPlaceholder } = this.props;
    const title = customField.displayTitle || customField.title;
    const expiresDate = value && dayjs(value).utc().format('YYYY-MM-DD');
    return (
      <div data-qa={`custom-field-${title}`} key={title} className="picsioInputText customFieldValue">
        <span className="labelInputText">{title}</span>
        <DatePicker
          selected={expiresDate}
          datePickerPlaceholder={placeholder}
          dateFormat={datePickerDateFormat}
          onChange={this.handleChange}
          disabledKeyboardNavigation
          disabled={disabled}
          popperPlacement="bottom-start"
          todayButton="Today"
        />
      </div>
    );
  }
}

InputDate.propTypes = {
  disabled: bool,
  customField: shape({
    title: string,
    displayTitle: string,
  }).isRequired,
  onChange: func,
  value: string,
  placeholder: string,
};

export default InputDate;
