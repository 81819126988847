/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const renamePreset = createAsyncThunk(
  'websiteSettings/renamePreset',
  // eslint-disable-next-line consistent-return
  async ({ presetId, newName }, {
    extra: {
      Logger, sdk, utils, showErrorDialog,
    },
  }) => {
    try {
      await sdk.websiteSettingsPresets.rename(presetId, newName);
      Logger.log('User', 'WebsitePresetRenamed', { prest_name: newName, created_by: presetId });
      return { presetId, newName };
    } catch (error) {
      Logger.log('User', 'WebsitePresetNotRenamed');
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not be rename preset';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error rename website preset'), { error }, [
        'RenameWebsitePresetFailed',
        'Website preset can not be renamed.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      renamePreset.pending,
      (state, { meta }) => {
        state.presets.forEach((preset) => {
          if (preset._id === meta.arg.presetId) {
            preset.isBusy = true;
          }
        });
      },
    ).addCase(
      renamePreset.fulfilled,
      (state, { payload: { presetId, newName } }) => {
        state.presets.forEach((preset) => {
          if (preset._id === presetId) {
            preset.name = newName;
            delete preset.isBusy;
          }
        });

        state.error = null;
      },
    ).addCase(
      renamePreset.rejected,
      (state, { meta, error }) => {
        state.presets.forEach((preset) => {
          if (preset._id === meta.arg.presetId) delete preset.isBusy;
        });
        state.error = error;
      },
    );
};
