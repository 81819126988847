/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_PATH_DELIMITER } from '@picsio/db/src/constants';
import TYPES from '../action-types';

import { updateKeywords, removeKeywords } from '../helpers/keywords';
import { reloadCurrentPage } from '../../helpers/history';

async function doMerge({
  sdk, force = false, targetId, ids, getState, dispatch, allKeywords,
}) {
  const { data: mergedKeywords } = await sdk.keywords.merge(targetId, ids, force);

  // remove merged keywords from assets and replace by new one
  if (mergedKeywords?.length) {
    const removedKeywordIds = mergedKeywords.map(({ _id }) => _id);
    const storeAssets = getState().assets.items || [];
    const assetsWithKeyword = storeAssets.filter((asset) => {
      const keywords = asset.keywords || [];
      return keywords.find((kw) => removedKeywordIds.includes(kw._id));
    });
    const assetsIds = assetsWithKeyword.map((asset) => asset._id);
    const targetKeyword = allKeywords.find((kw) => targetId === kw._id);
    if (assetsIds.length) {
      dispatch({
        type: TYPES.ASSETS.MERGE_KEYWORDS,
        payload: {
          ids: assetsIds,
          keywordsIds: removedKeywordIds,
          targetKeyword,
          userId: getState().user._id,
        },
      });
    }
    return { ids };
  }
  return {};
}

export const merge = createAsyncThunk(
  'keywords/merge',
  async ({ targetId, idsToMerge }, {
    extra: {
      sdk, localization, utils, Toast, Logger, showDialogAsync,
    }, getState, dispatch,
  }) => {
    const { all: allKeywords, selectedKeywords } = getState().keywords;
    let ids = idsToMerge?.length ? idsToMerge : selectedKeywords;

    Logger.log('UI', 'ConfirmMergeKWS', { ids });

    if (!ids.length) return {};

    try {
      /** ask user for confirmation */
      const {
        TITLE, TEXT, OK, CANCEL,
      } = localization.DIALOGS.KEYWORDS_MERGE_CONFIRM;
      const { promise } = showDialogAsync({
        title: TITLE(ids.length),
        text: TEXT(ids.length),
        textBtnCancel: CANCEL,
        textBtnOk: OK,
      });
      await promise;
      Logger.log('User', 'ConfirmMergeKWSYes', { keywordsIds: ids });
    } catch (error) {
      /** user press "Cancel" */
      Logger.log('User', 'ConfirmMergeKWSNo');
      return {};
    }

    try {
      /** try to merge */
      return await doMerge({
        sdk, getState, dispatch, targetId, ids, allKeywords,
      });
    } catch (error) {
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');

      if (errorSubcode === 'KeywordsNotInRootError') {
        /** if all keywords aren't in ROOT */
        const keywords = utils.getDataFromResponceError(error, 'keywords');
        if (keywords?.length === ids.length) {
          const { TITLE, TEXT, OK } = localization.DIALOGS.KEYWORDS_MERGE_ONLY_ROOT;

          showDialogAsync({
            title: TITLE(ids.length),
            text: TEXT,
            textBtnCancel: null,
            textBtnOk: OK,
            onOk: null,
          });

          throw error;
        }

        try {
          const nonReplacedKewords = allKeywords.map((kw) => {
            if (keywords.includes(kw._id)) {
              return {
                _id: kw._id,
                name: kw.name,
                path: kw.path,
              };
            }
            return null;
          }).filter(Boolean);

          const keywordsToOrderedListHtml = ({ path }) => `<li>${path.split(NEW_PATH_DELIMITER).pop()}</li>`;
          const html = `<ol>${nonReplacedKewords.map(keywordsToOrderedListHtml).join('')}</ol>`;
          /** ask about partial keywords merging */
          const { promise } = showDialogAsync({
            title: localization.DIALOGS.KEYWORDS_NOT_IN_ROOT.TITLE(ids.length),
            text: localization.DIALOGS.KEYWORDS_NOT_IN_ROOT.TEXT(html),
            textBtnCancel: localization.DIALOGS.KEYWORDS_NOT_IN_ROOT.CANCEL,
            textBtnOk: localization.DIALOGS.KEYWORDS_NOT_IN_ROOT.OK,
          });
          await promise;
        } catch (error2) {
          /** user press "Cancel" */
          Logger.log('User', 'ConfirmMergeKWSNo');
          return {};
        }

        try {
          /** uncheck keywords that not will be merged */
          ids = ids.filter((id) => !keywords.includes(id));
          Logger.log('User', 'ConfirmMergeKWSYes', { keywordsIds: ids });
          /** try to merge one more time (with FORCE flag) */
          return await doMerge({
            sdk, getState, dispatch, targetId, ids, allKeywords, force: true,
          });
        } catch (error3) {
          /** just skip to handle error below */
        }
      }

      Toast(localization.KEYWORDSTREE.errorKeywordMerging(), { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
      Logger.error(new Error('Can not merge keywords'), { error, ids }, [
        'MergeKeywordsFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    merge.pending,
    (state, { meta }) => {
      const ids = meta.arg.idsToMerge?.length ? meta.arg.idsToMerge : state.selectedKeywords;
      state.tree = updateKeywords(state.tree, ids, { isBusy: true });
    },
  )
  .addCase(
    merge.fulfilled,
    (state, { payload, meta }) => {
      const idsToUnsetBusyStatus = meta.arg.idsToMerge?.length ? meta.arg.idsToMerge : state.selectedKeywords;
      const { ids = [] } = payload;
      const {
        all,
        favorites: favoritesRemoved,
        keywords: keywordsRemoved,
      } = removeKeywords(state.tree, state.all, ids);
      /** Remove `busy` status on children keywords, that been selected */
      const {
        favorites,
        keywords,
      } = updateKeywords(
        { favorites: favoritesRemoved, keywords: keywordsRemoved },
        idsToUnsetBusyStatus,
        { isBusy: false },
      );
      state.all = all;
      state.tree = { favorites, keywords };
      /** clear selection only if ids received */
      if (ids.length) state.selectedKeywords = [];
    },
  )
  .addCase(
    merge.rejected,
    (state, { meta }) => {
      const ids = meta.arg.idsToMerge?.length ? meta.arg.idsToMerge : state.selectedKeywords;
      state.tree = updateKeywords(state.tree, ids, { isBusy: false });
    },
  );
