import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { Select } from '../../UIComponents'; // eslint-disable-line
import localization from '../../shared/strings';

const options = [
  {
    text: localization.SEARCH.optionAny,
    value: 'any',
  },
  {
    text: localization.SEARCH.optionImages,
    value: 'images',
  },
  {
    text: localization.SEARCH.optionVideo,
    value: 'video',
  },
  {
    text: localization.SEARCH.optionAudio,
    value: 'audio',
  },
  {
    text: localization.SEARCH.optionText,
    value: 'text',
  },
  {
    text: localization.SEARCH.optionPDF,
    value: 'pdf',
  },
  {
    text: localization.SEARCH.optionOfficeDocument,
    value: 'office',
  },
  {
    text: localization.SEARCH.optionSketch,
    value: 'sketch',
  },
  {
    text: localization.SEARCH.optionRAW,
    value: 'raw',
  },
  {
    text: localization.SEARCH.optionPhotoshop,
    value: 'photoshop',
  },
  {
    text: localization.SEARCH.option3D,
    value: '3d',
  },
  {
    text: localization.SEARCH.optionLinks,
    value: 'externalLink',
  },
];

function Types({ label, value, onChange }) {
  return (
    <div className="itemSearchFilters">
      <div className="labelItemSearchFilters">{label}</div>
      <div className="contentItemSearchFilters">
        <div className="defaultType">
          <Select
            changed={value !== 'any'}
            options={options}
            value={value}
            onChange={(event, value) => onChange(value)}
          />
        </div>
      </div>
    </div>
  );
}

Types.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  changed: PropTypes.bool,
};
export default Types;
