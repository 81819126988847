import { bytesToSize } from '../../../shared/utils';
import calculateBytesTotalSize from '../startUpload/calculateBytesTotalSize';
/**
 * Calculate total files size in groups
 * @param {*} groups
 * @returns {string}
 */
const calculateTotalSize = (groups) => bytesToSize(calculateBytesTotalSize(groups));

export default calculateTotalSize;
