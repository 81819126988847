import picsioConfig from '../../../../../config';
import sdk from '../../sdk';

/**
 * Fetch comments for the asset
 * @param {string} assetId
 * @returns Promise<AxiosResponse>
 */
export default function getComments(assetId) {
  if (picsioConfig.isMainApp) return sdk.assets.getComments(assetId);

  return sdk.assets.getCommentsPublic(
    picsioConfig.apiBaseUrl,
    window.websiteConfig.alias,
    assetId,
  );
}
