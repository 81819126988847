import TYPES from '../../action-types';

/**
 * @param {string} assetId
 * @param {data} data - approval data
 */
const updateApprove = (assetId, data) => async (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.UPDATE_APPROVE,
    payload: { assetId, data },
  });
};

export default updateApprove;
