import React, { useEffect, useState } from 'react';
import { Button, InputControlLabel } from '@picsio/ui';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Logger from '../../services/Logger';
import withErrorBoundary from '../ErrorBoundary/withErrorBoundary';
import PresetsList from './PresetsList';
import { getThemes } from '../../store/websiteSettings/actions';
import * as collectionHelpers from '../../store/helpers/collections';
import sdk from '../../sdk';
import Dialogs from '../../ui/dialogs';
import * as utils from '../../shared/utils';
import l18n from '../../shared/strings';
import { reloadApp, navigate } from '../../helpers/history';
import { normalizeWebsiteSettings, validateAlias, publishWebsite } from '../Websites/helpers';
import UiBlocker from '../../services/UiBlocker';
import { showDialog } from '../dialog';

import './styles.scss';

const WebsitePresetsDialog = ({ onClose, collectionId }) => {
  const dispatch = useDispatch();
  const [isNotShowAgain, setIsNotShowAgain] = useState(false);
  const [website, setWebsite] = useState(null);
  const [checkedPreset, setCheckedPreset] = useState(null);
  const [isSiteProcessing, setIsSiteProcessing] = useState(false);

  const user = useSelector((state) => state.user);
  const themes = useSelector((state) => state.websiteSettings.themes);
  const collections = useSelector((state) => state.collections.collections);
  const collectionsSearch = useSelector((state) => state.collections.search);
  const team = useSelector((state) => state.user.team);
  const roles = useSelector((state) => state.roles.items);
  const teammates = useSelector((state) => state.teammates.items);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getWebsite();

    if (!themes) {
      dispatch(getThemes());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWebsite = async () => {
    let collectionIds = collectionId;
    if (typeof collectionId === 'string') {
      collectionIds = [collectionId];
    }
    setIsSiteProcessing(true);
    try {
      const [collectionData] = await collectionHelpers.forceFindTagWithTagId({ collections, search: collectionsSearch, collectionIds });
      const { data: websiteData } = await sdk.websites.fetchWebsiteData(collectionId);
      const isNewWebsite = websiteData && !!websiteData.suggestions;

      const normalizedWebsiteData = normalizeWebsiteSettings({
        websiteData, collectionData, team, isNewWebsite,
      });
      setWebsite(normalizedWebsiteData);
    } catch (err) {
      const errorMessage = utils.getDataFromResponceError(err, 'msg');
      const errorStatus = utils.getStatusFromResponceError(err);

      Logger.error(new Error('Error manage website'), { error: err }, [
        'ManageWebsiteFailed',
        errorMessage,
      ]);

      if (errorMessage || errorStatus === 403) {
        showDialog({
          title: l18n.WEBSITES.titleError,
          text: errorStatus === 403 ? l18n.NO_PERMISSION_TO_ACCESS : errorMessage,
          textBtnCancel: null,
          textBtnOk: l18n.DIALOGS.btnOk,
          onOk: reloadApp,
          onCancel: reloadApp,
          onClose: reloadApp,
        });
        return;
      }

      showDialog({
        title: l18n.WEBSITES.titleError,
        text: l18n.WEBSITES.textWebsiteWasntFound,
        textBtnCancel: null,
        textBtnOk: l18n.DIALOGS.btnOk,
        onOk: reloadApp,
        onCancel: reloadApp,
        onClose: reloadApp,
      });
    } finally {
      setIsSiteProcessing(false);
    }
  };

  const handlePublishWebsite = async () => {
    setIsSiteProcessing(true);
    Logger.log('User', 'WebsitePresetsDialogPublish', collectionId);

    UiBlocker.block(l18n.WEBSITES.textSavingWebsite);

    const response = await validateAlias(website.alias, collectionId);

    if (response !== true) {
      Dialogs.Text({
        title: l18n.WEBSITES.titleErrors,
        html: response,
      });
      UiBlocker.unblock();
      setIsSiteProcessing(false);
    } else {
      publishWebsite({
        user, teammates, roles, collectionId, setIsSiteProcessing, website,
      })(dispatch);
    }
    onClose();
  };

  const handleToggleDoNotShowAgain = () => {
    setIsNotShowAgain(!isNotShowAgain);
    utils.LocalStorage.set('picsio.hideWebsitePresetsDialog', !isNotShowAgain);
  };

  const onCheckPreset = (preset) => {
    setCheckedPreset(preset);

    if (preset) {
      setWebsite({ ...website, ...preset.settings });
    }
  };

  const handleContinue = () => {
    Logger.log('User', 'WebsitePresetsDialogContinue', collectionId);
    navigate(`/websites/${collectionId}?tab=main`);
    onClose();
  };

  return (
    <div className="simpleDialog WebsitePresetsDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox">
        <div className="simpleDialogHeader">
          <h2 className="simpleDialogTitle">{l18n.DIALOGS.PUBLISH_WEBSITE.TITLE}</h2>
          <button
            className="simpleDialogBtnCross"
            onClick={onClose}
            type="button"
          >
            <Icon name="close" />
          </button>
        </div>
        <div className="simpleDialogSubHeader">
          <p>{l18n.DIALOGS.PUBLISH_WEBSITE.TEXT}</p>
        </div>
        <div className="WebsitePresetsDialog__top-line" />
        <div className="simpleDialogContent">
          <div className="simpleDialogContentInner">
            <PresetsList checkedPreset={checkedPreset} onCheckPreset={onCheckPreset} />
          </div>
        </div>
        <div className="WebsitePresetsDialog__bottom-line" />
        <div className="simpleDialogSubContent">
          <InputControlLabel
            label={l18n.DIALOGS.PUBLISH_WEBSITE.CHECKBOX_LABEL}
            id="notShowAgain"
            name="notShowAgain"
            value={isNotShowAgain}
            onChange={handleToggleDoNotShowAgain}
            control="checkbox"
            ControlProps={{
              'data-testid': 'btnPresetDialogCheckbox',
            }}
          />
        </div>
        <div className="simpleDialogFooter">
          <Button
            color="secondary"
            size="md"
            variant="contained"
            onClick={onClose}
            componentProps={{
              'data-testid': 'btnPresetDialogCancel',
            }}
          >
            {l18n.DIALOGS.PUBLISH_WEBSITE.CANCEL}
          </Button>
          <Choose>
            <When condition={checkedPreset}>
              <Button
                color="primary"
                size="md"
                variant="contained"
                onClick={handlePublishWebsite}
                disabled={isSiteProcessing}
                componentProps={{
                  'data-testid': 'btnPresetDialogPublish',
                }}
              >
                {l18n.DIALOGS.PUBLISH_WEBSITE.PUBLISH}
              </Button>
            </When>
            <Otherwise>
              <Button
                color="primary"
                size="md"
                variant="contained"
                onClick={handleContinue}
                componentProps={{
                  'data-testid': 'btnPresetDialogContinue',
                }}
              >
                {l18n.DIALOGS.PUBLISH_WEBSITE.CONTINUE}
              </Button>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </div>
  );
};

WebsitePresetsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  collectionId: PropTypes.string.isRequired,
};

export default withErrorBoundary(WebsitePresetsDialog, { className: 'errorBoundaryOverlay' });
