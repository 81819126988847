import sdk from '../../../sdk';

export default async function checkStorageLimit({
  selectedFolderPaths, filesSize, setShowStorageLimitWarning, setExcessStorageSize,
}) {
  const { data: { allowImport, excessiveStorageSize } } = await sdk.dropbox.checkStorageLimit(selectedFolderPaths, filesSize);

  if (!allowImport) {
    setShowStorageLimitWarning(true);
    setExcessStorageSize(excessiveStorageSize);
    return false;
  }
  return true;
}
