import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';
import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { getDataFromResponceError } from '../../../shared/utils';
import { showDialog } from '../../../components/dialog';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Change restrict settings
 * @param {string[]?} iDs - ids
 * @param {Object} value
 */
const changeRestrict = (iDs, value) => async (dispatch, getAll) => {
  let ids = iDs;
  const assetsStore = getAll().assets;
  if (ids === undefined) ids = assetsStore.selectedItems;

  async function doResctict(force) {
    try {
      dispatch({ type: TYPES.ASSETS.RESTRICT.START, payload: { ids } });

      const { data: result } = await sdk.assets.setRestrictSettings(ids, value, force);
      if (result) {
        dispatch({
          type: TYPES.ASSETS.RESTRICT.COMPLETE,
          payload: { ids, value },
        });
      }
    } catch (error) {
      const subcode = getDataFromResponceError(error, 'subcode');
      if (subcode === 'CantSetRestrictSettingsInPublishedCollectionError') {
        showDialog({
          title: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TITLE,
          text: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.TEXT,
          textBtnOk: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_OK,
          textBtnCancel: localization.DIALOGS.RESTRICTED_ASSET_DETECTED.BTN_CANCEL,
          onOk: () => doResctict(true),
        });
      } else {
        Toast(localization.RESTRICT.cantRestrict(ids.length), { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
        Logger.error(new Error('Can not restrict assets'), { error }, [
          'RestrictAssetsFailed',
          (error && error.message) || 'NoMessage',
        ]);
      }

      dispatch({
        type: TYPES.ASSETS.RESTRICT.FAILED,
        error,
      });
    }
  }

  try {
    await asyncCheckMultipleChanges(ids.length);
    doResctict();
  } catch (error) {
    /** User cancelled operation */
  }
};

export default changeRestrict;
