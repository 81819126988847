import React, {
  memo, useMemo, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { DotsVertical } from '@picsio/icons';
import { Menu, IconButton } from '@picsio/ui';
import PresetMenuItem from './PresetMenuItem';
import l18n from '../../../../shared/strings';

const PresetMenu = ({
  onDelete, onRename,
}) => {
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }

    setMenuOpen((prevValue) => !prevValue);
  }, []);

  const handleClickMenu = useCallback((e, action) => {
    if (typeof action === 'function') {
      action();
    }
    toggleMenu(e);
  }, [toggleMenu]);

  const controls = useMemo(() => ([
    {
      id: 'rename',
      text: l18n.WEBSITES.textPresetsRename,
      action: onRename,
    },
    {
      id: 'delete',
      text: l18n.WEBSITES.textPresetsDelete,
      action: onDelete,
    },
  ]), [onDelete, onRename]);

  return (
    <If condition={controls.length}>
      <IconButton
        ref={ref}
        buttonSize="default"
        color="default"
        component="button"
        disabled={false}
        id="PresetMenuOpener"
        onClick={toggleMenu}
        size="lg"
        componentProps={{ 'data-testid': 'btnMenuEditPreset' }}
      >
        <DotsVertical />
      </IconButton>
      <Menu
        target={ref}
        arrow
        padding="s"
        placement="bottom-end"
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        outsideClickListener
      >
        {controls.map((control) => (
          <PresetMenuItem key={control.id} {...control} onClick={handleClickMenu} />
        ))}
      </Menu>
    </If>
  );
};

PresetMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
};

export default memo(PresetMenu);
