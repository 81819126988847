import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import {
  Menu,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  Icon,
  Button,
} from '@picsio/ui';
import { Computer, Link } from '@picsio/icons';
import { useSelector } from 'react-redux';
import l18n from '../../../../shared/strings';
import Logger from '../../../../services/Logger';
import AddFromUrlDialog from '../../../../components/AddFromUrlDialog';
import DropboxChooser from '../../../../components/DropboxChooser';
import picsioConfig from '../../../../../../../config';

export default function useImportMenu({
  targetRef,
  placement = 'right-start',
  uploadCollectionId,
  importTo = 'collection',
  initiatedFrom = null,
}) {
  const parentRef = useRef(null);
  const rootRef = useRef(null); // Ref to store the root
  const dropboxUser = useSelector(
    (state) => state.user.connectedCloud?.dropbox,
  );
  const [isOpened, setOpened] = useState(false);
  const mobileInputRef = useRef();
  const close = useCallback(() => setOpened(false), [setOpened]);
  const toggleMenu = useCallback(() => {
    setOpened((prevValue) => !prevValue);
  }, [setOpened]);

  const handleUploadChange = useCallback(
    ({ target }) => {
      const { files } = target;
      if (!files || !files.length) return;
      Logger.log('User', 'ImportMenuFilesSelected', {
        assets_number: files.length,
      });
      window.dispatchEvent(
        new CustomEvent('importPanel:add', {
          detail: {
            files,
            uploadCollectionId,
          },
        }),
      );
      close();
    },
    [close, uploadCollectionId],
  );

  const handleMobileUploadChange = useCallback(() => {
    mobileInputRef.current.click();
  }, []);

  const handleFromUrlClick = useCallback(async () => {
    close();
    const $div = document.createElement('div');
    document.body.appendChild($div);
    const root = createRoot($div);

    const closeDialog = () => {
      root.unmount();
      document.body.removeChild($div);
    };

    root.render(<AddFromUrlDialog onClose={closeDialog} uploadCollectionId={uploadCollectionId} />);
  }, [close, uploadCollectionId]);

  const handleLiveSupport = useCallback(async (e) => {
    close();
    const { cloudName } = e.currentTarget.dataset;
    if (window.Intercom) {
      Logger.log('User', 'LiveChat');
      await window.Intercom('showNewMessage');
      setTimeout(() => {
        window.Intercom('private:sendMessage', `Hi, I'd like to import my data from ${cloudName} to Pics.io. Could you guide me through the process?`);
      }, 2000);
    }
    Logger.log('User', `${cloudName}ImportClicked`, { initiated_from: initiatedFrom });
  }, [close, initiatedFrom]);

  useEffect(() => {
    window.addEventListener('importPanel:closeImportMenu', close);
    return () => {
      window.removeEventListener('importPanel:closeImportMenu', close);
    };
  }, [close]);

  const handleOpenDropboxChooser = useCallback(async () => {
    close();
    if (!dropboxUser) {
      const authWindow = window.open(
        `https://www.dropbox.com/oauth2/authorize?client_id=${picsioConfig.dropboxClientId}&redirect_uri=${picsioConfig.dropboxRedirectUri}&response_type=code&token_access_type=offline`,
        '_blank',
      );
      const checkWindowClosed = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(checkWindowClosed);
          window.dispatchEvent(
            new CustomEvent('window:dropbox:auth:closed'),
          );
        }
      }, 1000);
    }
    DropboxChooser({ uploadCollectionId });
    Logger.log('User', 'DropboxImportClicked', { initiated_from: initiatedFrom });
  }, [close, dropboxUser, initiatedFrom, uploadCollectionId]);

  const menu = useCallback(() => {
    /** Mobile */
    if (window.innerWidth < 1024) {
      return (
        <If condition={isOpened}>
          <div className="simpleDialog">
            <div className="simpleDialogUnderlayer" />
            <div className="simpleDialogBox">
              <div className="simpleDialogHeader">
                <h2 className="simpleDialogTitle">
                  {l18n.IMPORT.MENU.mobileDialogTitle}
                </h2>
              </div>
              <div className="simpleDialogContent">
                <div className="simpleDialogContentInner">
                  <div
                    className="firstLine"
                    onKeyPress={handleMobileUploadChange}
                    onClick={handleMobileUploadChange}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon>
                      <Computer />
                    </Icon>
                    <div className="mobileDownloadText">
                      {l18n.IMPORT.MENU.upload}
                    </div>
                    <input
                      type="file"
                      multiple
                      ref={mobileInputRef}
                      onChange={handleUploadChange}
                    />
                  </div>
                  <div
                    className="secondLine"
                    onKeyPress={handleFromUrlClick}
                    role="button"
                    tabIndex={0}
                    onClick={handleFromUrlClick}
                  >
                    <Icon>
                      <Link />
                    </Icon>
                    <div className="mobileDownloadText">
                      {l18n.IMPORT.MENU.addExternal}
                    </div>
                  </div>
                </div>
              </div>
              <div className="simpleDialogFooter">
                <Button
                  color="secondary"
                  size="md"
                  variant="contained"
                  onClick={close}
                >
                  {l18n.DIALOGS.CANCEL}
                </Button>
              </div>
            </div>
          </div>
        </If>
      );
    }
    /** Desktop */
    return (
      <Menu
        target={targetRef}
        isOpen={isOpened}
        onClose={close}
        padding="m"
        placement={placement}
        outsideClickListener
        className="PicsioImportMenu"
      >
        <MenuItem
          className="PicsioImportMenu__item"
          onClick={Function.prototype}
        >
          <input type="file" multiple onChange={handleUploadChange} />
          <MenuItemIcon size="xl">
            <Computer />
          </MenuItemIcon>
          <MenuItemText>{l18n.IMPORT.MENU.upload}</MenuItemText>
        </MenuItem>
        <MenuItem
          className="PicsioImportMenu__item"
          onClick={handleFromUrlClick}
        >
          <MenuItemIcon size="xl">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link />
          </MenuItemIcon>
          <MenuItemText>{l18n.IMPORT.MENU.addExternal}</MenuItemText>
        </MenuItem>
        <If condition={importTo === 'collection'}>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleOpenDropboxChooser}
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/dropbox.svg"
                style={{ width: '24px', height: '24px' }}
                alt="Dropbox logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l18n.IMPORT.MENU.importDropbox}</MenuItemText>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="Google Drive"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/google-drive.svg"
                style={{ width: '24px', height: '20px' }}
                alt="Google drive logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l18n.IMPORT.MENU.importGD}</MenuItemText>
            <span className="labelBeta">{l18n.IMPORT.assisted}</span>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="OneDrive"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/one-drive.svg"
                style={{ width: '24px', height: '24px' }}
                alt="One drive logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l18n.IMPORT.MENU.importOneDrive}</MenuItemText>
            <span className="labelBeta">{l18n.IMPORT.assisted}</span>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="Box"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/box.svg"
                style={{ width: '24px', height: '24px' }}
                alt="Box logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l18n.IMPORT.MENU.importBox}</MenuItemText>
            <span className="labelBeta">{l18n.IMPORT.assisted}</span>
          </MenuItem>
          <MenuItem
            className="PicsioImportMenu__item"
            onClick={handleLiveSupport}
            data-cloud-name="SharePoint"
          >
            <MenuItemIcon size="xl">
              <img
                src="https://assets.pics.io/img/icons/importers/share-point.svg"
                style={{ width: '24px', height: '24px' }}
                alt="Share point logo"
              />
            </MenuItemIcon>
            <MenuItemText>{l18n.IMPORT.MENU.importSharePoint}</MenuItemText>
            <span className="labelBeta">{l18n.IMPORT.assisted}</span>
          </MenuItem>
        </If>
      </Menu>
    );
  }, [
    isOpened,
    handleUploadChange,
    close,
    placement,
    handleFromUrlClick,
    handleMobileUploadChange,
    handleLiveSupport,
    handleOpenDropboxChooser,
    importTo,
    targetRef,
  ]);

  useEffect(() => {
    const $parent = document.createElement('div');
    parentRef.current = $parent;
    document.body.appendChild($parent);

    rootRef.current = createRoot(parentRef.current); // Create root once
    rootRef.current.render(menu());
    return () => {
      rootRef.current.unmount();
      document.body.removeChild($parent);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    rootRef.current.render(menu()); // Reuse the existing root
  }, [isOpened, menu]);

  return { toggleMenu };
}
