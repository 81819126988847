import { createAsyncThunk } from '@reduxjs/toolkit';
import sdk from '../../../sdk';

const addLiveChatIconSeen = createAsyncThunk(
  'user/addLiveChatIconSeen',
  (_, { getState }) => {
    const { liveChatIconSeen } = getState().user.achievements;
    if (!liveChatIconSeen) {
      return sdk.users.setAchievement('liveChatIconSeen');
    }
    return null;
  },
);

export default addLiveChatIconSeen;
