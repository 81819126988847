import Toast from '../../components/Toast/Toast';
import l18n from '../../shared/strings';
/**
 * Validate asset name
 * @param {string} name
 * @param {*} options
 * @returns {boolean}
 */
export default function isValidAssetName(name, options = {}) {
  if (name.includes('/')) {
    if (options.showToast) Toast(l18n.TOAST.NAME_CAN_NOT_INCLUDE_SLASH, { type: 'error' });
    return false;
  }
  return true;
}
