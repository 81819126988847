import React, { useState, useRef, useCallback } from 'react';
import { Input, Popper, Avatar } from '@picsio/ui';
import { Ok } from '@picsio/icons';
import {
  bool, string, func, arrayOf, shape, oneOf,
} from 'prop-types';
import Tag from '../../components/Tag';

import './styles.scss';

const SearchableDropdown = ({
  options,
  isSearchable = false,
  onSelect,
  tagType = null,
  placeholder,
  placement = 'bottom-start',
  closeOnSelect = false,
  disablePortal = false,
  dataTestId,
}) => {
  const dropdownRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isDropdownOpened, setDropdownOpened] = useState(false);

  const toggleDropdown = useCallback(
    () => setDropdownOpened(!isDropdownOpened), [isDropdownOpened],
  );

  const handleSelect = useCallback((value) => {
    let selectedDropdownItems = [...selectedValue];

    if (value === 'any') {
      selectedDropdownItems = [];
    } else if (selectedDropdownItems.includes(value)) {
      selectedDropdownItems = selectedValue.filter((i) => i !== value);
    } else {
      selectedDropdownItems.push(value);
    }

    setSelectedValue(selectedDropdownItems);
    onSelect(selectedDropdownItems);

    if (closeOnSelect) {
      toggleDropdown();
    }
  }, [selectedValue, closeOnSelect, toggleDropdown, onSelect]);

  const handleSearch = useCallback((event) => {
    setSearchValue(event.target.value);
    const filtered = options.filter(
      (option) => option.label.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setFilteredOptions(filtered);
  }, [options]);

  const handleRemoveTag = useCallback((deletedValue) => {
    const filtered = selectedValue.filter((value) => value !== deletedValue);
    setSelectedValue(filtered);
    onSelect(filtered);
  }, [selectedValue, onSelect]);

  return (
    <div className="SearchableDropdown" data-testid={dataTestId}>
      <div className="SearchableDropdown__body" ref={dropdownRef}>
        <div className="SearchableDropdown__head">
          <div
            className="SearchableDropdown__head__item"
            onClick={toggleDropdown}
            role="presentation"
          >
            <span className="SearchableDropdown__head__text">
              {placeholder}
            </span>
            <span className="SearchableDropdown__head__triangle" />
          </div>
        </div>

        <Popper
          target={dropdownRef}
          isOpen={isDropdownOpened}
          onClose={toggleDropdown}
          placement={placement}
          disablePortal={disablePortal}
          offset={[0, 10]}
          hide={false}
          arrow={false}
          outsideClickListener
        >
          <div className="SearchableDropdown__menu">
            {isSearchable && (
              <div className="SearchableDropdown__search">
                <Input
                  placeholder="Search..."
                  value={searchValue}
                  onChange={handleSearch}
                />
              </div>
            )}
            <Choose>
              <When condition={filteredOptions.length !== 0}>
                <ul className="SearchableDropdown__list">
                  {filteredOptions.map((option) => (
                    <li
                      className="SearchableDropdown__list__item"
                      key={option.value}
                      onClick={() => handleSelect(option.value)}
                      role="presentation"
                    >
                      {Object.prototype.hasOwnProperty.call(
                        option,
                        'avatar',
                      ) && (
                        <span className="SearchableDropdown__list__item__avatar">
                          <Avatar
                            src={option.avatar}
                            userName={option.label}
                            size={20}
                          />
                        </span>
                      )}
                      <span>{option.label}</span>
                      {selectedValue.includes(option.value) && (
                        <Ok className="SearchableDropdown__list__item__iconOk" />
                      )}
                    </li>
                  ))}
                </ul>
              </When>
              <Otherwise>
                <div className="SearchableDropdown__noresults">
                  {`No results found for «${searchValue}»`}
                </div>
              </Otherwise>
            </Choose>
          </div>
        </Popper>
      </div>

      {selectedValue.length > 0 && (
        <div className="SearchableDropdown__checkedItems">
          {selectedValue.map((value) => {
            const currentOption = options.find((option) => option.value === value);
            return (
              <Tag
                onClose={() => handleRemoveTag(value)}
                key={value}
                type={tagType}
                avatar={tagType === 'user' ? currentOption?.avatar : null}
                text={currentOption.label}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
SearchableDropdown.propTypes = {
  options: arrayOf(
    shape({
      value: string.isRequired,
      avatar: string,
      label: string.isRequired,
    }),
  ).isRequired,
  dataTestId: string.isRequired,
  closeOnSelect: bool,
  placement: string,
  disablePortal: bool,
  tagType: oneOf(['user', 'collection', 'lightboard', 'inbox', 'keyword']),
  placeholder: string.isRequired,
  isSearchable: bool,
  onSelect: func.isRequired,
};

export default SearchableDropdown;
