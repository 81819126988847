import makeTree from './makeTree';

/**
 * Set favorites
 * @param {Object} tree
 * @param {Object[]} all
 * @param {string} id
 * @param {string} userId
 * @returns {Object}
 */
export default function changeFavorites(tree, all, id, userId) {
  const item = all.find((el) => el._id === id);
  const itemFavorite = tree.favorites.nodes.find((el) => el._id === id);
  let updatedTree = {};

  if (item) {
    if (itemFavorite === undefined) {
      if (!item.favorites) {
        item.favorites = [];
      }
      item.favorites.push(userId);
      updatedTree = makeTree(all, userId);
    } else {
      item.favorites = item.favorites.filter((_id) => _id !== userId);
      updatedTree = makeTree(all, userId);
    }
  }

  const result = {
    all: [...all],
    favorites: { ...updatedTree.favorites },
    savedSearches: { ...updatedTree.savedSearches },
  };

  return result;
}
