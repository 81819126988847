import axios from 'axios';
import picsioConfig from '../../../../../config';
import { getLatestCollection } from '../history';

/**
 * Get urls to download from zipper
 * @param {Object} data
 * @param {string} storageType
 * @returns {Promise}
 */
export default function getZipUrls(data, storageType) {
  const collectionId = getLatestCollection();
  if (collectionId) data.downloadFrom = collectionId;

  let zipperUrl = picsioConfig.zipperZipUrl;
  if (storageType === 'gd') {
    zipperUrl = picsioConfig.zipperZipUrlGD;
  }
  if (storageType === 's3') {
    zipperUrl = picsioConfig.zipperZipUrlS3;
  }
  return axios.post(
    zipperUrl,
    data,
    { headers: { 'Content-Type': 'application/json' } },
  );
}
