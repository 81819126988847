import { createReducer } from '@reduxjs/toolkit';
import initialState from './initialState';

import { reducer as addFiles } from './addFiles';
import { reducer as addToSelection } from './addToSelection';
import { reducer as removeFromSelection } from './removeFromSelection';
import { reducer as remove } from './remove';
import { reducer as setRequiredFields } from './setRequiredFields';
import { reducer as changeField } from './changeField';
import { reducer as addToListField } from './addToListField';
import { reducer as removeFromListField } from './removeFromListField';
import { reducer as addCustomField } from './addCustomField';
import { reducer as removeCustomField } from './removeCustomField';
import { reducer as startUpload } from './startUpload';
import { reducer as updateFile } from './updateFile';
import { reducer as preUploadValidation } from './preUploadValidation';

const importReducer = createReducer(
  initialState,
  (builder) => {
    addFiles(builder);
    addToSelection(builder);
    removeFromSelection(builder);
    remove(builder);
    setRequiredFields(builder);
    changeField(builder);
    addToListField(builder);
    removeFromListField(builder);
    addCustomField(builder);
    removeCustomField(builder);
    startUpload(builder);
    updateFile(builder);
    preUploadValidation(builder);
  },
);

export default importReducer;
