import sdk from '../../../sdk';
import Logger from '../../../services/Logger';
import TYPES from '../../action-types';
import { isRoutePreview } from '../../../helpers/history';

import checkMultipleChanges from './checkMultipleChanges';
/**
 * Remove meta field modified by user from DB
 * @param {string[]} ids
 * @param {string} fieldName
 */
const removeModifiedField = (fieldName) => (dispatch, getAll) => {
  Logger.log('User', 'ClickRemoveModifiedMetaFieldStatus', { fieldName });

  let ids = getAll().assets.selectedItems;

  if (ids.length === 0) {
    if (isRoutePreview()) {
      const previewID = window.location.pathname.split('/').pop();
      ids = [previewID];
    }
  }
  checkMultipleChanges(ids.length, doChange);

  async function doChange() {
    try {
      dispatch({
        type: TYPES.ASSETS.REMOVE_MODIFIED_FIELD.START,
        payload: {
          ids,
          fieldName,
        },
      });

      await sdk.assets.removeModifiedMetaFieldStatus(ids, fieldName);

      Logger.log('User', 'RemovedModifiedMetaFieldStatus', { fieldName });

      dispatch({
        type: TYPES.ASSETS.REMOVE_MODIFIED_FIELD.COMPLETE,
        payload: {
          ids,
          fieldName,
        },
      });
    } catch (error) {
      Logger.error(new Error('Error remove modified fields'), { error }, [
        'RemoveModifiedFieldFailed',
        (error && error.message) || 'NoMessage',
      ]);
      dispatch({ type: TYPES.ASSETS.REMOVE_MODIFIED_FIELD.FAILED, error });
    }
  }
};

export default removeModifiedField;
