/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import localization from '../../shared/strings';

export const deleteUnnamed = createAsyncThunk(
  'faces/deleteUnnamed',
  async (ids, {
    extra: {
      Logger, sdk, Toast,
    },
  }) => {
    try {
      await sdk.faces.deleteUnnamed();
      return ids;
    } catch (error) {
      Logger.error(new Error('Error delete Unnamed'), { error }, [
        'DeleteUnnamedFacesFailed',
        'Faces can not be delete Unnamed from backend.',
      ]);
      Toast(localization.FACESTREE.error);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      deleteUnnamed.pending,
      (state) => {
        state.isLoaded = false;
        state.isLoading = true;
        state.error = null;
      },
    ).addCase(
      deleteUnnamed.fulfilled,
      (state) => {
        state.all = state.all.filter((face) => !face.unnamed);
        state.tree = {
          ...state.tree,
          unnamed: {
            ...state.tree.unnamed,
            nodes: [],
          },
        };
      },
    ).addCase(
      deleteUnnamed.rejected,
      (state, { error }) => {
        state.isLoaded = false;
        state.isLoading = false;
        state.error = error;
      },
    );
};
