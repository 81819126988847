import TYPES from '../action-types';
import sdk from '../../sdk';
import store from '../index';
import { reloadRoute } from '../../helpers/history';

const getState = () => store.getState().lightboards;

/**
 * Set sort type to lightboard
 * @param {string} lightboardID
 * @param {string} value
 */
export const setSortTypeLigthboardsActions = (lightboardID, value) => (dispatch) => {
  dispatch({
    type: TYPES.LIGHTBOARDS.SET_SORT_TYPE,
    payload: { lightboardID, value },
  });
};

/**
 * Set sortType field to lightboard
 * @param {string} sortType
 * @param {boolean} onlyLocal
 */
export const setSortType = (sortType, onlyLocal) => {
  const { lightboardId } = store.getState().router.location.query;
  if (onlyLocal) {
    return setSortTypeLigthboardsActions(lightboardId, sortType)(store.dispatch);
  }
  reloadRoute();
};

/**
 * Get lightboard by id
 * @param {string} lightboardID
 * @returns {Promise}
 */
export const getLightboardWithId = async (lightboardID) => {
  let { lightboards } = getState();
  if (!lightboards) {
    const { data } = await sdk.lightboards.getAll();
    lightboards = data;
  }
  return lightboards.find((lb) => lb._id === lightboardID);
};
