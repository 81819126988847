import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@picsio/ui';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';

const DetailsPanelToggleButton = (props) => {
  const { toggleEditPanel } = props;

  const handleOnClick = useCallback(() => {
    Logger.log('User', 'EditWidgets');
    toggleEditPanel();
  }, [toggleEditPanel]);

  return (
    <div className="detailsPanel__toggle-edit">
      <Button
        color="primary"
        onClick={handleOnClick}
      >
        {localization.DETAILS.editWidgets}
      </Button>
    </div>
  );
};

DetailsPanelToggleButton.propTypes = {
  toggleEditPanel: PropTypes.func.isRequired,
};

export default DetailsPanelToggleButton;
