/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import { makeTree, makeSearchTree } from '../helpers/keywords';

/** to cancell the request */
let cancelToken = null;

export const PAGE_SIZE = 300;
export const search = createAsyncThunk(
  'keywords/search',
  async (payload, {
    extra: {
      sdk, Logger, Toast, utils, localization: l18n,
    }, getState,
  }) => {
    try {
      const {
        sortType, isFull, page, searchQuery,
      } = getState().keywords;

      /** if load next page */
      if (payload?.nextPage) {
        /** do not send next request if previous not ended OR if all keywords loaded */
        if (cancelToken || isFull) return {};
      } else if (cancelToken) {
        /** cancel previous request */
        cancelToken.cancel();
      }

      const sort = payload?.sort || sortType;

      cancelToken = axios.CancelToken.source();
      const params = {
        returnFavorites: true,
        returnChildren: true,
        from: payload?.nextPage ? page * PAGE_SIZE : 0,
        size: PAGE_SIZE,
        sortType: sort.type,
        sortOrder: sort.order,
      };
      if (searchQuery) {
        params.query = searchQuery;
        params.returnChildren = false;
      }

      const { data } = await sdk.keywords.search(params, cancelToken.token);
      cancelToken = null;

      return {
        keywords: data,
        nextPage: payload?.nextPage,
      };
    } catch (error) {
      cancelToken = null;
      if (axios.isCancel(error)) {
        /** Request cancelled by user */
        return {};
      }

      Logger.log('UI', 'ToastKeywordsNotLoaded');
      Toast(l18n.KEYWORDS.errorLoading, { autoClose: false, type: 'error' });

      const connection = utils.getNavigatorConnectionInfo();
      Logger.error(new Error('Can not get keywords'), { error }, [
        'GetKeywordsFailed',
        { errorMessage: (error && error.message) || 'NoMessage', connection },
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      search.pending,
      (state, { meta }) => {
        if (meta.arg?.nextPage) {
          state.isPageLoading = true;
        } else {
          state.isLoaded = false;
          state.isFull = false;
        }

        if (typeof meta.arg?.query !== 'undefined') state.searchQuery = meta.arg.query;
      },
    ).addCase(
      search.fulfilled,
      (state, { payload: { keywords, nextPage } }) => {
        /** if no keywords - do not update state */
        if (!keywords) return;

        if (nextPage) {
          state.page += 1;
          state.all = _uniqBy([...state.all, ...keywords], '_id');
        } else {
          state.all = _uniqBy(keywords, '_id');
          state.page = 1;
        }
        if (keywords.length === 0) state.isFull = true;

        state.tree = state.searchQuery
          ? makeSearchTree(state.all, state.searchQuery, state.sortType)
          : makeTree(state.all, state.sortType);
        state.isLoaded = true;
        state.isPageLoading = false;
        state.error = null;
      },
    ).addCase(
      search.rejected,
      (state, { error }) => {
        state.error = error;
        state.isLoaded = true;
        state.isPageLoading = false;
      },
    );
};
