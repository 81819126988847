import picsioConfig from '../../../../../../config';
import showDownloadDialog from '../../../helpers/fileDownloader/showDownloadDialog';
import store from '../../../store/index';

/**
 * Start drag item
 * @param {MouseEvent} event
 * */
const handleDragStart = (event, assetId, isSelected, select, downloadFiles) => {
  if (!picsioConfig.isMainApp) {
    event.preventDefault();
    return;
  }
  if (!window.dragElement) return;
  /** @type {boolean} */
  if (!isSelected) {
    select({ id: assetId, value: true });
  }
  const { selectedItems } = store.getState().assets;
  const assetsIds = selectedItems.map((id) => `${window.origin}/preview/${id}`).join('\r\n');
  /* if selected just now items length will change after receive new props */
  const selectedLength = selectedItems.length;
  const wrapperTiles = document.querySelector('.wrapperTiles');

  window.dragElement.querySelector('.countDragElements').textContent = selectedLength;

  const dt = event.dataTransfer;
  /**
   * IE and Edge incomplete support setData and setDragImage
   * @link https://caniuse.com/#feat=dragndrop
   */
  if (typeof dt.setData === 'function') dt.setData('text/plain', assetsIds);
  if (typeof dt.setDragImage === 'function') dt.setDragImage(window.dragElement, 0, 0);

  if (wrapperTiles) {
    wrapperTiles.classList.add('pointerEventsDisabled');
  }

  // show download dialog, if user draggedout asset from browser
  if (!downloadFiles) {
    return;
  }

  let showDrag = false;
  let timeout = -1;
  let isModifiedKeyPressed = false;

  const handlerSetDragTrue = (e) => {
    isModifiedKeyPressed = e.metaKey || e.ctrlKey || e.shiftKey || e.altKey;
    showDrag = true;
  };
  const handlerDragLeave = () => {
    showDrag = false;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!showDrag && !isModifiedKeyPressed) {
        showDownloadDialog(selectedItems);
        document.removeEventListener('dragenter', handlerSetDragTrue);
        document.removeEventListener('dragover', handlerSetDragTrue);
        document.removeEventListener('dragleave', handlerDragLeave);
      }
    }, 200);
  };

  document.addEventListener('dragenter', handlerSetDragTrue, { capture: true });
  document.addEventListener('dragover', handlerSetDragTrue, { capture: true });
  document.addEventListener('dragleave', handlerDragLeave, { capture: true });
};

export default handleDragStart;
