import { showDialog } from '../../../components/dialog';

const showError = (e, Toast, utils, localization) => {
  const errorStatus = utils.getStatusFromResponceError(e);
  const errorSubcode = utils.getDataFromResponceError(e, 'subcode');

  const dialogTitle = localization.SOMETHING_WENT_WRONG.TITLE;
  const dialogText = localization.SOMETHING_WENT_WRONG.TEXT;
  if (errorStatus === 403) {
    Toast(localization.NO_PERMISSION_TO_ACCESS, { type: 'error' });
  } else if (errorSubcode === 'HasCopyingAssetsError') {
    Toast(localization.TOAST.WARNING_BUSY_COLLECTION.TEXT, { type: 'error' });
  } else {
    showDialog({
      title: dialogTitle,
      text: dialogText,
    });
  }
};

export default showError;
