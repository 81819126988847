import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import TYPES from '../../action-types';

const getPages = (ids, revisionId) => async (dispatch, getAll) => {
  try {
    const checkExpiresAt = (expiresAt) => {
      if (!expiresAt) return true;
      const nowDate = new Date();
      const expiresAtDate = new Date(expiresAt);
      const hour = 3600000;
      return nowDate.getTime() + hour * 4 > expiresAtDate.getTime();
    };

    dispatch({ type: TYPES.ASSETS.FETCH_PAGES.START });
    const assetsFromStore = getAll().assets.items;
    let isNeedToFetchPages = false;
    if (ids.length === 2) {
      isNeedToFetchPages = true;
    } else {
      ids.forEach((id) => {
        const asset = assetsFromStore.find((item) => item._id === id);
        if (revisionId && asset.pages && !asset.pages[revisionId]) {
          isNeedToFetchPages = true;
        }
        if (revisionId && asset.pages && asset.pages[revisionId] && asset.pages[revisionId][0]) {
          isNeedToFetchPages = checkExpiresAt(asset.pages[revisionId][0].expiresAt);
        }
        if (!revisionId && asset.pages) {
          isNeedToFetchPages = checkExpiresAt(asset.pages.expiresAt);
        }
      });
    }

    if (!isNeedToFetchPages) return;

    let pagesData = [];
    if (!revisionId) {
      pagesData = await Promise.all(ids.map((id) => assetsHelpers.fetchPagesThumbnails(id)));
    } else {
      pagesData = await assetsHelpers.fetchPagesThumbnails(ids[0], revisionId);
    }
    const pages = {};
    ids.forEach((id, index) => {
      pages[id] = revisionId ? pagesData.data : pagesData[index].data;
    });

    dispatch({
      type: TYPES.ASSETS.FETCH_PAGES.COMPLETE,
      payload: {
        pages,
        revisionId,
      },
    });
  } catch (error) {
    dispatch({ type: TYPES.ASSETS.FETCH_PAGES.FAILED, error });
    Logger.error(new Error('Error fetching asset pages'), { error, showDialog: false }, [
      'GetAssetPagesFailed',
      (error && error.message) || 'NoMessage',
    ]);
  }
};
export default getPages;
