import React from 'react';
import { createRoot } from 'react-dom/client';
import AssetsLimitExceededDialog from '../components/AssetsLimitExceededDialog';

/**
 * Show assets limit exceeded dialog
 * @param {*} user - User from the store
 * @returns {void}
 */
export default function showAssetsLimitExceededDialogWithoutStore(user) {
  const $wrapper = document.createElement('div');
  $wrapper.classList.add('wrapperDialog');
  const $container = document.querySelector('#dialogRoot') || document.body;
  $container.appendChild($wrapper);
  const root = createRoot($wrapper);

  function destroy() {
    root.unmount();
    $container.removeChild($wrapper);
  }
  root.render(
    <AssetsLimitExceededDialog destroy={destroy} user={user} />,
  );
}
