/**
 * Parse iframe to get it's attributes
 * @param {string} iframe
 * @returns {object}
 */
const parseIframe = (iframe) => {
  const regEx = /(src|width|height|frameBorder|allow|feature|title|)=["']([^"']*)["']/gi;
  const iframeAttr = {};
  iframe.replace(regEx, (all, type, value) => {
    iframeAttr[type] = value;
  });
  return iframeAttr;
};

export default parseIframe;
