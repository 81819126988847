import * as utils from '../../../shared/utils';

/**
 * Normalize avatar src
 * @param {string} src
 * @param {string?} size
 * @param {boolean?} retina
 * @returns {string}
 */
export default function normalizeUserAvatarSrc(src, size, retina) {
  let normalizedUrl = src || '';
  if (normalizedUrl && normalizedUrl.startsWith('https://s3.amazonaws.com/content.pics.io/original/users')) {
    const url = new URL(normalizedUrl);

    // isSearchSuffix we use to test many avatars per team
    const isSearchSuffix = url && url.search;

    // temporary removing the suffix
    if (isSearchSuffix) {
      normalizedUrl = normalizedUrl.replace(isSearchSuffix, '');
    }

    const ext = utils.getFileExtension(normalizedUrl);
    let imageSize = '128';
    switch (size) {
    case 'large': {
      imageSize = '128';
      break;
    }
    case 'medium': {
      imageSize = '32';
      break;
    }
    case 'small': {
      imageSize = '16';
      break;
    }
    default:
      break;
    }

    if (retina) imageSize += '@2x';
    normalizedUrl = normalizedUrl.replace('/original', '');
    normalizedUrl = normalizedUrl.replace(ext, `${imageSize}.${ext}`);

    // returning the suffix back
    if (isSearchSuffix) {
      normalizedUrl += isSearchSuffix;
    }
  }
  return normalizedUrl;
}
