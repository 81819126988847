/* eslint-disable no-param-reassign */

import { createAsyncThunk } from '@reduxjs/toolkit';

const addCustomFace = createAsyncThunk(
  'asset/addCustomFace',
  async ({
    assetId,
    faceId,
    boundingBox,
    name,
    revisionId,
    isNewFace,
  }, {
    extra: {
      sdk, Logger, utils, showErrorDialog,
    },
  }) => {
    try {
      // if we are attaching existing person to new bb
      if (faceId) {
        await sdk.assets.addCustomFace(assetId, { faceId, boundingBox, revisionId });
        return {
          boundingBox, assetId, name, _id: faceId, revisionId, isNewFace,
        };
      }
      // if we are creating and attaching new person to new bb
      const { data } = await sdk.assets.addCustomFace(assetId, { name, boundingBox, revisionId });
      return { ...data, newFace: true, assetId, boundingBox, revisionId, isNewFace };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not apply custom face on asset';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error applying custom face on asset'),
        { error },
        ['ApplyCustomFaceOnAssetFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export default addCustomFace;

export const reducer = (builder) => {
  // update face tree if this is the newFace
  builder.addCase(
    addCustomFace.fulfilled,
    (state, { payload }) => {
      if (payload.isNewFace) {
        const newFaces = [];
        const data = payload?.eventData || payload;
        const isFacedExist = state.all.find((storeFaced) => storeFaced._id === data._id);
        if (!isFacedExist) {
          newFaces.push(data);
        }
        state.all = [...state.all, ...newFaces];
        state.tree = {
          ...state.tree,
          allFaces: {
            ...state.tree.allFaces,
            nodes: [
              ...state.tree.allFaces.nodes, ...newFaces,
            ],
          },
        };
      }
    },
  );
};
