/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import CONSTANTS from '@picsio/db/src/constants';

import { setField, changeFavorites } from '../helpers/keywords';

export const favorite = createAsyncThunk(
  'keywords/favorite',
  async ({ _id, path, value, showToast = true }, {
    extra: {
      sdk, localization, Toast, Logger,
    }, dispatch,
  }) => {
    try {
      await sdk.keywords.favorite(_id, value);

      if (showToast) {
        const actionText = value
          ? localization.COLLECTIONS.textAddedTo
          : localization.COLLECTIONS.textRemovedFrom;
        Toast(`"${path.split(CONSTANTS.NEW_PATH_DELIMITER).pop()}" ${actionText} ${localization.COLLECTIONS.titleFavorites}`, {
          type: 'success',
          btnOkValue: localization.textBtnUndo,
          onOk: () => dispatch(favorite({ _id, path, value: !value, showToast: false })),
        });
      }
    } catch (error) {
      Logger.error(new Error('Can not add keyword to favorites'), { error }, [
        'AddKeywordToFavoritesFailed',
        error?.message || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    favorite.pending,
    (state, { meta }) => {
      state.tree = setField(state.tree, meta.arg._id, 'isBusy', true);
    },
  )
  .addCase(
    favorite.fulfilled,
    (state, { meta }) => {
      const { all, keywords, favorites } = changeFavorites(
        state.tree,
        state.all,
        meta.arg._id,
        meta.arg.value,
        state.sortType,
      );
      state.all = all;
      state.tree = { keywords, favorites };
    },
  )
  .addCase(
    favorite.rejected,
    (state, { meta, error }) => {
      state.error = error;
      state.tree = setField(state.tree, meta.arg._id, 'isBusy', false);
    },
  );
