import Store from '../../index';
import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';

import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Change flag on assets
 * @param {string[]} ids
 * @param {string?} value
 */
const changeFlag = (ids, value) => async (dispatch, getAll) => {
  try {
    await asyncCheckMultipleChanges(ids.length);
  } catch (error) {
    /** User cancelled operation */
    return;
  }

  try {
    if (value === undefined || !['flagged', 'unflagged', 'rejected'].includes(value)) {
      const { items } = getAll().assets;
      const item = items.find((item) => item._id === ids[0]);
      if (item) {
        // determine next flag value
        // we use this while click flag on tile
        switch (item.flag) {
        case 'unflagged':
          value = 'flagged';
          break;
        case 'flagged':
          value = 'rejected';
          break;
        case 'rejected':
          value = 'unflagged';
          break;

        default:
          break;
        }
      } else {
        value = 'flagged';
      }
    }
    dispatch({
      type: TYPES.ASSETS.CHANGE_FLAG.START,
    });

    await assetsHelpers.setFlag(ids, value);

    dispatch({
      type: TYPES.ASSETS.CHANGE_FLAG.COMPLETE,
      payload: {
        ids,
        keys: ['flag'],
        values: [value],
        userId: Store.getState().user._id,
      },
    });
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : localization.DETAILS.textCantUpdate('flags');
    Toast(errorMessage, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
    Logger.error(new Error('Error change flag'), { error }, [
      'ChangeFlagFailed',
      (error && error.message) || 'NoMessage',
    ]);
    dispatch({ type: TYPES.ASSETS.CHANGE_FLAG.FAILED, error });
  }
};

export default changeFlag;
