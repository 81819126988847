import globalHistory, { isRouteSearch } from '../history';

export default function prepareSearchQuery() {
  let query = window.location.search.slice(1);
  if (!isRouteSearch()) {
    const latestSearch = globalHistory.entries.find(
      ({ pathname, search }) => isRouteSearch(pathname + search),
    );
    if (latestSearch) query = latestSearch.search.slice(1);
  }

  const result = {
    collectionIds: [],
  };
  // query is empty for proofing main page
  if (query) {
    query.split('&').forEach((item) => {
      const [key, value] = item.split('=');
      const decodedValue = decodeURIComponent(value);

      result[key] = key in result ? [].concat(result[key], decodedValue) : decodedValue;
    });
  }

  // if only one faceId this is string
  if (typeof result.faceIds === 'string') {
    result.faceIds = [result.faceIds];
  }

  return result;
}
