import TYPES from '../../action-types';

/** Set tmp item */
const setTmpItem = (id) => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.SET_TMP_ITEM,
    payload: { id },
  });
};

export default setTmpItem;
