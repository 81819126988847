import { showDialog, showErrorDialog, showDialogAsync } from '../../dialog';
import ua from '../../../ua';
import l18n from '../../../shared/strings';
import UiBlocker from '../../../services/UiBlocker';
import * as utils from '../../../shared/utils';
import Logger from '../../../services/Logger';
import sdk from '../../../sdk';
import Toast from '../../Toast';
import * as collectionsActions from '../../../store/actions/collections';
import getWebsiteProtocol from './getWebsiteProtocol';
import uploadImagesForWebsite from './uploadImagesForWebsite';

const API_UPLOAD_METHODS = {
  logoUrl: sdk.websites.uploadLogo,
  avatarUrl: sdk.websites.uploadAvatar,
  backgroundLoginPageUrl: sdk.websites.uploadBackground,
  thumbnailUrl: sdk.websites.uploadThumbnail,
};

const createWebsite = ({
  collectionId, website, subscriptionFeatures, manageBilling, force = false, imageData = undefined,
}) => async (dispatch) => {
  let createdWebsite = {};
  const {
    websitesLimit, websitesCount, obsolete, planId,
  } = subscriptionFeatures;

  const isTrialUser = planId === 'trial';

  const countAfterPublish = websitesCount + 1;

  if (countAfterPublish > websitesLimit && !force && !isTrialUser) {
    /** START @TODO  Need to remove condition "obsolete" when there are no OLD PLAN users */
    if (obsolete || ua.isMobileApp()) {
      showErrorDialog(l18n.WEBSITES.errorHaveReachedLimit);
      return;
    }
    /** END @TODO */

    if (!manageBilling) {
      showDialog({
        title: l18n.DIALOGS.ADD_ADDON_NOT_PERMISSION.TITLE,
        text: l18n.DIALOGS.ADD_ADDON_NOT_PERMISSION.TEXT,
        textBtnOk: l18n.DIALOGS.ADD_ADDON_NOT_PERMISSION.BTN_OK,
        textBtnCancel: null,
      });
      return;
    }

    const { promise } = showDialogAsync({
      title: l18n.DIALOGS.WARNING_ADDON_UPGRADE.TITLE,
      text: l18n.DIALOGS.WARNING_ADDON_UPGRADE.TEXT('website', 1),
      icon: 'notification',
      textBtnOk: l18n.DIALOGS.WARNING_ADDON_UPGRADE.OK,
      textBtnCancel: l18n.DIALOGS.WARNING_ADDON_UPGRADE.CANCEL,
      onOk: async () => {
        try {
          UiBlocker.block(l18n.WEBSITES.textCreatingWebsite);
          const { data } = await sdk.websites.create(collectionId, website, true);
          createdWebsite = data;
          UiBlocker.unblock();
        } catch (err) {
          const errorMessage = utils.getDataFromResponceError(err, 'msg') || '';
          Logger.error(new Error('Error create website'), { error: err }, [
            'CreateWebsiteFailed',
            errorMessage || 'NoMessage',
          ]);
          UiBlocker.unblock();
          showErrorDialog(
            errorMessage || l18n.WEBSITES.textWebsiteCreationUnsuccessful,
          );
        }
      },
    });
    try {
      await promise;
    } catch (error) {
      return;
    }

    Toast(l18n.TOAST.UPGRADE_ADDON_SUCCESS('1 website'));
  } else {
    try {
      UiBlocker.block(l18n.WEBSITES.textCreatingWebsite);
      const { data } = await sdk.websites.create(collectionId, website, force);
      createdWebsite = data;
      UiBlocker.unblock();
    } catch (err) {
      UiBlocker.unblock();
      const errorMessage = utils.getDataFromResponceError(err, 'msg') || '';
      Logger.error(new Error('Error create website'), { error: err }, [
        'CreateWebsiteFailed',
        errorMessage || 'NoMessage',
      ]);
      showErrorDialog(
        errorMessage || l18n.WEBSITES.textWebsiteCreationUnsuccessful,
      );
      return;
    }
  }

  const websiteId = createdWebsite._id;

  if (imageData) {
    try {
      /** upload images if user changed image picker */
      const uploads = Object.keys(imageData)
        .map((name) => API_UPLOAD_METHODS[name](websiteId, imageData[name].file).promise);
      if (uploads.length) await Promise.all(uploads);
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg')
          || l18n.WEBSITES.textWebsiteSomeImagesUnsuccessful;
      showErrorDialog(errorMessage);
    }
  }
  /** Upload images if user applyed preset */
  const uploadedImages = await uploadImagesForWebsite({ websiteId, settings: website });

  const websiteUrl = getWebsiteProtocol(website) + createdWebsite.alias;

  Toast(l18n.WEBSITES.alertWebsiteCreated, {
    type: 'success',
    btnOkValue: l18n.WEBSITES.textVisitWebsite,
    onOk: () => {
      window.open(websiteUrl, '_blank');
    },
  });

  dispatch(collectionsActions.setWebsite(collectionId, { ...createdWebsite, ...uploadedImages }));
};

export default createWebsite;
