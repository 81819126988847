import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import _union from 'lodash/union';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@picsio/ui';
import { Ok, NewCollection } from '@picsio/icons';
import SkeletonItem from './SkeletonItem';
import DropdownTreeIcon from './DropdownTreeIcon';
import AddItemForm from './AddItemForm';

export default function DropdownTree(props) {
  const [openedItems, setOpenedItems] = useState([]);
  const [propsOpenedItems, setPropsOpenedItems] = useState([]);
  const [adding, setAdding] = useState(null);
  const [, forceUpdate] = React.useState(0); // hook like forceUpdate
  const itemPaddingLeft = 15;

  useEffect(() => {
    if (JSON.stringify(propsOpenedItems) !== JSON.stringify(props.openedItems)) {
      setPropsOpenedItems(props.openedItems);
      setOpenedItems(_union(openedItems, props.openedItems));
    }
  }, [props.openedItems]);

  const stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleClick = (e, item, isChecked) => {
    stopPropagation(e);
    props.onClick(item, isChecked);
  };

  const handleLoadChildren = async (e, item) => {
    stopPropagation(e);

    if (openedItems.includes(item._id)) {
      setOpenedItems(openedItems.filter((id) => id !== item._id));
    } else if (item.nodes && item.nodes.length) {
      setOpenedItems([...openedItems, item._id]);
    } else {
      setOpenedItems([...openedItems, item._id]);
      await props.loadChildren(item);
      forceUpdate((n) => !n);
    }
  };

  const isParentOfMovedCollection = (item, movedItem) => {
    if (!movedItem) return false;
    return item.nodes && item.nodes.some((node) => `${movedItem.path}${movedItem.name}` === `${node.path}${node.name}`);
  };

  const findListItem = useCallback((id, items) => {
    let found;
    for (const listItem of (items || props.treeListItems)) {
      if (listItem._id === id) found = listItem;
      if (!found && listItem.nodes?.length) {
        found = findListItem(id, listItem.nodes);
      }
    }
    return found;
  }, [props.treeListItems]);

  const handleClickCreate = useCallback(async (e, item) => {
    stopPropagation(e);
    if (!openedItems.includes(item._id) && (item.nodes || item.hasChild)) {
      setAdding({ item, forbiddenNames: [], isProcessing: true });
      await handleLoadChildren(e, item);
    }
    const updatedItem = findListItem(item._id);
    const forbiddenNames = (updatedItem?.nodes || []).map(({ name }) => name.toLowerCase());

    setAdding({ item: updatedItem, forbiddenNames, isProcessing: false });
    if (!openedItems.includes(item._id)) setOpenedItems([...openedItems, item._id]);
  }, [props.treeListItems, openedItems, setOpenedItems, handleLoadChildren, findListItem]);

  const handleCreate = useCallback(async (item, value) => {
    setAdding({ ...adding, isProcessing: true });
    await props.createCollection(item, value);
    setAdding(null);
  }, [adding, setAdding, props.createCollection, openedItems]);

  const renderItem = (item, iconSpecial, level) => {
    if (item.hidden || item.deletedByTeammate) return null;
    const {
      collectionToMove,
      checkedItems,
      disableRoot,
      usePermissions,
      type,
      isFetching,
      fetchingId,
      disabledItems,
    } = props;
    const isChecked = checkedItems.includes(item._id);
    const isOpenedItems = openedItems.includes(item._id);
    const showArrow = item.hasChild || (item.nodes && !!item.nodes.length);
    const { permissions = {}, path } = item;
    const isRootDisabled = path === 'root' && disableRoot;
    let pathOfMovedCollection = '';
    if (collectionToMove) {
      pathOfMovedCollection = collectionToMove.path + collectionToMove.name;
    }

    let isDisabledByPermission = false;
    switch (type) {
    case 'attach': {
      isDisabledByPermission = permissions.editAssetCollections !== true;
      break;
    }

    case 'duplicate': {
      isDisabledByPermission = permissions.upload !== true;
      break;
    }

    case 'upload': {
      isDisabledByPermission = permissions.upload !== true;
      break;
    }

    case 'duplicateCollection': {
      isDisabledByPermission = permissions.createCollections !== true;
      break;
    }

    case 'move': {
      isDisabledByPermission = permissions.moveCollections !== true;
      break;
    }

    default: {
      isDisabledByPermission = false;
      break;
    }
    }

    let isItemDisabled = (disabledItems?.length && disabledItems.includes(item._id))
        || ((usePermissions || isRootDisabled)
          && (isRootDisabled
            || (collectionToMove
              && (isParentOfMovedCollection(item, collectionToMove)
                || path.startsWith(pathOfMovedCollection)
                || (item.nodes && item.nodes.find((node) => node.name === collectionToMove.name))
                || (item.name === collectionToMove.name && path === collectionToMove.path)))
            || isDisabledByPermission));

    if (checkedItems.length >= 10 && !isChecked && props.multipleSelection) {
      isItemDisabled = true;
    }
    const itemClassName = cn('dropdownTreeItem', {
      act: isChecked,
      openedItem: isOpenedItems || isRootDisabled,
      disabled: isItemDisabled,
    });

    const handleClickItem = (e) => !isItemDisabled && handleClick(e, item, isChecked);
    const handleClickArrow = (e) => handleLoadChildren(e, item);

    return (
      <div className={itemClassName} key={item._id}>
        <div
          className="row"
          role="button"
          tabIndex={0}
          onClick={handleClickItem}
          onKeyPress={handleClickItem}
          style={{ marginLeft: itemPaddingLeft * level }}
        >
          <If condition={showArrow}>
            <span
              className="arrowItem"
              role="button"
              onClick={handleClickArrow}
              onKeyPress={handleClickArrow}
              tabIndex={0}
            >
              open/close
            </span>
          </If>
          <span className="iconSubject">
            <DropdownTreeIcon iconSpecial={item.website ? 'website' : iconSpecial} />
          </span>
          <span className="dropdownTreeItemName">{item.name}</span>
          <If condition={props.createCollection}>
            <IconButton
              onClick={(e) => handleClickCreate(e, item)}
              disabled={!permissions.createCollections || adding?.item?._id === item._id}
              className="onHover"
            >
              <NewCollection />
            </IconButton>
          </If>
          <If condition={isChecked}>
            <div className="iconChecked">
              <Icon size="sm"><Ok /></Icon>
            </div>
          </If>
        </div>
        <If condition={isFetching && isOpenedItems && item._id === fetchingId}>
          <SkeletonItem itemPaddingLeft={itemPaddingLeft} level={level} iconSpecial={iconSpecial} />
        </If>
        <If condition={isOpenedItems || isRootDisabled || adding?.item?._id === item._id}>
          <div className="childList">
            <If condition={adding?.item?._id === item._id}>
              {adding.isProcessing
                ? (
                  <div className="dropdownTreeItem">
                    <SkeletonItem itemPaddingLeft={itemPaddingLeft} count={1} level={level} iconSpecial={iconSpecial} />
                  </div>
                )
                : (
                  <AddItemForm
                    forbiddenNames={adding.forbiddenNames}
                    item={adding.item}
                    onSubmit={handleCreate}
                    onCancel={setAdding}
                    indent={itemPaddingLeft * (level + 1)}
                  />
                )}
            </If>
            <If condition={item.isFetching}>
              <div className="dropdownTreeItem">
                <SkeletonItem
                  itemPaddingLeft={itemPaddingLeft}
                  level={level}
                  iconSpecial={iconSpecial}
                />
              </div>
            </If>
            <If condition={!item.isFetching && item.nodes}>
              {item.nodes.map((node) => renderItem(node, iconSpecial, level + 1))}
            </If>
          </div>
        </If>
      </div>
    );
  };

  return (
    <div className={`dropdownTreeWrapper ${props.additionalClass}`}>
      <div className="dropdownTree" onClick={stopPropagation}>
        <div className="dropdownTreeList">
          {props.treeListItems.map((item) => renderItem(item, props.iconSpecial, 1))}
        </div>
      </div>
    </div>
  );
}

/** default props */
DropdownTree.defaultProps = {
  disableRoot: false,
  createCollection: null,
  disabledItems: null,
  usePermissions: true,
  collectionToMove: {},
  iconSpecial: 'folder',
  isFetching: false,
  fetchingId: '',
  additionalClass: '',
  multipleSelection: false,
};

DropdownTree.propTypes = {
  onClick: PropTypes.func.isRequired,
  loadChildren: PropTypes.func.isRequired,
  createCollection: PropTypes.func,
  checkedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledItems: PropTypes.arrayOf(PropTypes.string),
  collectionToMove: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string,
  }),
  disableRoot: PropTypes.bool,
  treeListItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  iconSpecial: PropTypes.string,
  type: PropTypes.string.isRequired,
  usePermissions: PropTypes.bool,
  isFetching: PropTypes.bool,
  fetchingId: PropTypes.string,
  additionalClass: PropTypes.string,
  multipleSelection: PropTypes.bool,
};
