/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { string, func, arrayOf } from 'prop-types';
import cn from 'classnames';
import { Button, InputControlLabel } from '@picsio/ui';
import Logger from '../../services/Logger';
import UiBlocker from '../../services/UiBlocker';
import l18n from '../../shared/strings';
import { saveFile } from '../../shared/utils';
import * as utils from '../../shared/utils';

import Icon from '../Icon';
import { getDefaultFields } from './config';
import Toast from '../Toast';
import sdk from '../../sdk';
import Field from './Field';

const { EXPORT_TO_CSV_DIALOG } = l18n;
const KEY_ENTER = 13;
const KEY_ESC = 27;

const ExportCsvDialog = ({
  assetIds,
  destroy,
  title,
  textBtnCancel,
  textBtnOk,
}) => {
  const [exportAll, setExport] = useState(true);
  const [fields, setFields] = useState(getDefaultFields());

  const submit = useCallback(async () => {
    UiBlocker.block('Processing...');
    Logger.log('User', 'ExportToCsv', `${assetIds.length}`);
    const fieldNames = exportAll ? [] : fields.map((field) => (field.value ? field.name : null)).filter(Boolean);

    try {
      const { data: csv } = await sdk.assets.exportCSV(assetIds, fieldNames);
      const blob = new Blob([csv], { type: 'text/csv' });
      saveFile(blob, EXPORT_TO_CSV_DIALOG.fileName);
    } catch (err) {
      const errorMessage = utils.getDataFromResponceError(err, 'msg');
      const errorStatus = utils.getDataFromResponceError(err, 'status');
      switch (errorStatus) {
      case 403: {
        /** no permissions */
        Toast(errorMessage || EXPORT_TO_CSV_DIALOG.noPermissions, {
          autoClose: false,
          type: 'error',
        });
        break;
      }
      case 400: {
        /** validation error */
        Toast(EXPORT_TO_CSV_DIALOG.validationError + (errorMessage ? `: ${errorMessage}` : ''), {
          autoClose: false,
          type: 'error',
        });
        break;
      }
      default: {
        Logger.error(new Error('Can not export to csv'), { error: err, showDialog: true }, [
          'ExportToCsvFailed',
          (err && err.message) || 'NoMessage',
        ]);
      }
      }
    }
    destroy();
    UiBlocker.unblock();
  }, [assetIds, fields, exportAll, destroy]);

  const keyListener = useCallback((event) => {
    if (event.keyCode === KEY_ENTER) submit();
    if (event.keyCode === KEY_ESC) destroy();
  }, [destroy, submit]);

  useEffect(() => {
    window.addEventListener('keydown', keyListener);
    return () => window.removeEventListener('keydown', keyListener);
  }, [keyListener]);

  const handleCheckboxChange = useCallback((index, value) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index] = { ...prevFields[index], value };
      return newFields;
    });
  }, []);

  const setExportAll = useCallback(() => setExport(true), [setExport]);
  const unsetExportAll = useCallback(() => setExport(false), [setExport]);

  return (
    <div className="simpleDialog exportDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox">
        <div className="simpleDialogHeader">
          <span className="simpleDialogTitle">{title}</span>
          <span className="simpleDialogBtnCross" onClick={destroy} role="button" tabIndex={0}>
            <Icon name="close" />
          </span>
        </div>
        <div className="simpleDialogContent">
          <div className="simpleDialogContentInner">
            <div className="simpleDialogDescription">
              <p>{EXPORT_TO_CSV_DIALOG.text(assetIds.length)}</p>
              <div className="exportDialog-radios">
                <div className="exportDialog-row">
                  <InputControlLabel
                    control="radio"
                    value={exportAll}
                    label={EXPORT_TO_CSV_DIALOG.exportAllFields}
                    onChange={setExportAll}
                  />
                </div>
                <div className={cn('exportDialog-row', { 'exportDialog-row-expanded': !exportAll })}>
                  <InputControlLabel
                    control="radio"
                    value={!exportAll}
                    label={EXPORT_TO_CSV_DIALOG.exportSelectedFields}
                    onChange={unsetExportAll}
                  />
                  <If condition={!exportAll}>
                    <ul className="exportDialog-checkboxes">
                      {fields.map((field, index) => (
                        <Field {...field} key={field.name} onChange={handleCheckboxChange} index={index} />
                      ))}
                    </ul>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="simpleDialogFooter">
          <Button
            color="secondary"
            size="md"
            variant="contained"
            tabIndex={0}
            onClick={destroy}
          >
            {textBtnCancel}
          </Button>
          <Button
            color="primary"
            size="md"
            variant="contained"
            tabIndex={0}
            onClick={submit}
          >
            {textBtnOk}
          </Button>
        </div>
      </div>
    </div>
  );
};

ExportCsvDialog.propTypes = {
  assetIds: arrayOf(string).isRequired,
  destroy: func.isRequired,
  title: string,
  textBtnCancel: string,
  textBtnOk: string,
};

ExportCsvDialog.defaultProps = {
  title: EXPORT_TO_CSV_DIALOG.title,
  textBtnCancel: EXPORT_TO_CSV_DIALOG.textBtnCancel,
  textBtnOk: EXPORT_TO_CSV_DIALOG.textBtnOk,
};

export default ExportCsvDialog;
