import Logger from '@picsio/browser-logger';
import picsioConfig from '../../../../config';
import CONSTANTS from '../shared/constants';
import TrackingService from './TrackingService';

let amplitudeProdKey = picsioConfig.amplitude.externalKey;
let appName = 'Undefined';
if (picsioConfig.isMainApp) {
  appName = 'Main';
  amplitudeProdKey = picsioConfig.amplitude.mainKey;
}
if (picsioConfig.isProofing) {
  appName = 'Proofing';
}
if (picsioConfig.isSingleApp) {
  appName = 'Sas';
}
if (picsioConfig.isInboxApp) {
  appName = 'Inbox';
}

export default new Logger({
  env: picsioConfig.env,
  amplitudeSettings: {
    appName,
    key: picsioConfig.env === 'production' ? amplitudeProdKey : picsioConfig.amplitude.devKey,
    apiEndpoint: picsioConfig.amplitude.apiEndpoint || undefined,
  },
  sentrySettings: {
    appName,
    dsn: CONSTANTS.sentry.dsn,
    env: picsioConfig.env,
  },
  trackingService: TrackingService,
});
