import React, { createRef } from 'react';
import cn from 'classnames';
import {
  string, object, number, oneOfType, bool,
} from 'prop-types';
import _debounce from 'lodash/debounce';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _remove from 'lodash/remove';
import dayjs from 'dayjs';
import {
  ASYNC_JOB_STATUS_WAITING,
  ASYNC_JOB_STATUS_RUNNING,
  ASYNC_JOB_STATUS_FAILED,
  ASYNC_JOB_STATUS_REJECTED,
} from '@picsio/db/src/constants';
import picsioConfig from '../../../../../../config';
import * as pathHelper from '../../../helpers/paths';
import CONSTANTS from '../../../shared/constants';

import Logger from '../../../services/Logger';
import * as utils from '../../../shared/utils';
import localization from '../../../shared/strings';
import ToolbarPreviewTop from '../../toolbars/ToolbarPreviewTop';
import History from '../../history';
import ErrorBoundary from '../../ErrorBoundary';
import ua from '../../../ua';
import sdk from '../../../sdk';
import PubSubService from '../../../services/PubSubService';
import { showFileDeletedDialog } from '../../../helpers/errorHandler';
import * as assetsHelpers from '../../../helpers/assets';
import { checkUserAccess } from '../../../store/helpers/user';
import preparePermissions from '../../../helpers/assets/preparePermissions';
import prepareModifiedMetaFields from '../../../helpers/assets/prepareModifiedMetaFields';
import getNegativePermissions from '../../../helpers/assets/getNegativePermissions';

import {
  downloadAsset,
  downloadFile,
  downloadAssetWithWatermark,
} from '../../../helpers/fileDownloader';

import showDownloadDialog from '../../../helpers/fileDownloader/showDownloadDialog';
import { normalizeUserAvatarSrc } from '../../../store/helpers/teammates';
import * as helpers from '../../history/helper';
import { getSavedCurrentTime } from './Video/helpers';

import getDownloadUrl from '../../../helpers/getDownloadUrl';
import checkForThumbnailing from '../../../helpers/checkForThumbnailing';
import checkForConverting from '../../../helpers/checkForConverting';
import revisionUploader from '../../../helpers/revisionUploader';
import { preloadImage, pollImage } from '../../../helpers/images';
import { sendDownloadedNotification } from '../../../helpers/assets';

import Details from '../../details/view';
import Spinner from './Spinner'; // eslint-disable-line
import Placeholder from './Placeholder'; // eslint-disable-line
import Image from './Image'; // eslint-disable-line
import Video from './Video/index'; // eslint-disable-line
import Audio from './Audio'; // eslint-disable-line
import Pdf from './Pdf/index'; // eslint-disable-line
import Icon from '../../Icon';
import TranscriptPanel from '../../TranscriptPanel';

// store
import * as actions from '../../../store/actions/assets';
import * as facesActions from '../../../store/faces/actions';
import * as mainActions from '../../../store/actions/main';
import * as collections from '../../../store/actions/collections';
import * as userActions from '../../../store/actions/user';
import { fetchJobsStatus } from '../../../store/actions/notifications';
import { AssetAnalyticsScreen } from '../../Analytics';
import { checkDownloadConsent } from '../../../store/helpers/assets';
import { showDialog, showErrorDialog } from '../../dialog';
import Obj from './Obj/index';
import Multipage from './Multipage/index';
import Swipeable from '../../Swipeable';
import {
  back, navigate, history, isRouteSearch, isRouteSelectedAssets,
} from '../../../helpers/history';
import sendEventToIntercom from '../../../services/IntercomEventService';
import External from './External';
import PreviewWarning from '../../PreviewWarning';

function isHistoryPanelEnabled() {
  if (!picsioConfig.isMainApp) {
    return picsioConfig.access.commentShow || picsioConfig.access.revisionsShow;
  }
  return true;
}

function isActivityPanelEnabled() {
  if (!picsioConfig.isMainApp) {
    return (
      picsioConfig.access.titleShow
      || picsioConfig.access.descriptionShow
      || picsioConfig.access.flagShow
      || picsioConfig.access.ratingShow
      || picsioConfig.access.colorShow
      || picsioConfig.access.customFieldsShow
    );
  }
  return true;
}

function shouldRenderDetails() {
  return isActivityPanelEnabled() && utils.LocalStorage.get('picsio.infopanelOpened');
}

function shouldRenderHistory() {
  const historyPanelOpened = utils.LocalStorage.get('picsio.historypanelOpened');
  return isHistoryPanelEnabled() && (historyPanelOpened === null ? true : historyPanelOpened);
}

function shouldRenderTranscriptions() {
  return picsioConfig.isMainApp && utils.LocalStorage.get('picsio.transcriptionspanelOpened');
}

const shouldRenderRevisionsDropdown = () => {
  if (!picsioConfig.isMainApp) {
    return picsioConfig.access.revisionsShow;
  }
  return true;
};

class PreviewView extends React.Component {
  isLastRevisionTechnical = false;

  $list = createRef();

  constructor(props) {
    super(props);

    /** if hash in uri - show history panel */
    if (window.location.hash) {
      if (window.location.hash.includes('transcription')) {
        utils.LocalStorage.set('picsio.infopanelOpened', false);
        utils.LocalStorage.set('picsio.transcriptionspanelOpened', true);
        utils.LocalStorage.set('picsio.historypanelOpened', false);
      }
      if (window.location.hash.includes('comments')) {
        utils.LocalStorage.set('picsio.infopanelOpened', false);
        utils.LocalStorage.set('picsio.transcriptionspanelOpened', false);
        utils.LocalStorage.set('picsio.historypanelOpened', true);
      }
    }

    this.videoPlayer = React.createRef();
    this.audioPlayer = React.createRef();

    this.state = {
      currentAssetId: props.id,
      isLoaded: !!props.asset,
      spinnerText: '',
      isThumbnailing:
        props.asset
        && (props.asset.thumbnailing === 'waiting' || props.asset.thumbnailing === 'running'),
      isConverting:
        props.asset
        && (props.asset.converting === 'waiting' || props.asset.converting === 'running'),
      showDropArea: false,
      idDiffState: null,
      idActiveState: null,
      headRevisionId: null,
      activeRevisionNumber: null,
      diffRevisionNumber: null,
      tmpMarkers: [],
      markers: [],
      tmpBoundingBoxes: [],
      boundingBoxes: [],
      showHistory: shouldRenderHistory(),
      showDetails: shouldRenderDetails(),
      showTranscriptions: shouldRenderTranscriptions(),
      listenToAddMarker: false,
      listenToAddBoundingBox: false,
      nextMarkerNumber: 0,
      analyticsIsOpened: false,
      dataIsRecieved: false,
      isLoadedHistoryItemsLoaded: false,
      isLoadedAddComment: false,
      historyItems: [],
      revisionsNumbers: {},
      approvals: [],
      isApproveDisabled: false,
      commentsCount: 0,
      replyTo: null,
      revisionIsUploading: false,
      historyActions: {
        getActiveRevisionID: this.getActiveRevisionID,
        activateRevision: this.activateRevision,
        toggleRevisionComments: this.toggleRevisionComments,
        revertRevision: this.revertRevision,
        activateDiff: this.activateDiff,
        handleEyeClick: this.handleEyeClick,
        onCommentRemove: this.onCommentRemove,
        addReply: this.addReply,
        addReaction: this.addReaction,
        changeApprove: this.changeApprove,
        addComment: this.addComment,
        editComment: this.editComment,
        cancelReply: this.cancelReply,
      },
      commentTimeRange: [getSavedCurrentTime(props.id) || 0, getSavedCurrentTime(props.id) || 0],
      isCheckedAttachTime: true,
      selectedFace: {},
      facesAreShown: true,
      videoCurrentTime: getSavedCurrentTime(props.id) || 0,
      audioCurrentTime: getSavedCurrentTime(props.id, 'audio') || 0,
      hideRestrictedWarning: false,
      isImportingInProcess: false,
      isImportingFailed: false,
    };

    this.preloadNeighbors = _debounce(this.preloadNeighbors, 300);
  }

  static getDerivedStateFromProps(props, prevState) {
    const { asset, id } = props;
    let state = {
      currentAssetId: id,
      isThumbnailing: checkForThumbnailing(asset),
      isConverting: checkForConverting(asset),
    };

    if (prevState.currentAssetId !== props.id) {
      /** asset changed */
      state = {
        ...state,
        spinnerText: '',
        showDropArea: false,
        idDiffState: null,
        idActiveState: null,
        activeRevisionNumber: null,
        diffRevisionNumber: null,
        tmpMarkers: [],
        markers: [],
        tmpBoundingBoxes: [],
        boundingBoxes: [],
        isCheckedAttachTime: true,
        showHistory: shouldRenderHistory(),
        listenToAddMarker: false,
        listenToAddBoundingBox: false,
        nextMarkerNumber: 0,
        commentTimeRange: [getSavedCurrentTime(props.id) || 0, getSavedCurrentTime(props.id) || 0],
      };
    }
    if (asset !== null) {
      const isAllowedDownloading = !asset.trashed
        && asset.isSupportedForDownload
        && (picsioConfig.isMainApp ? asset.permissions.downloadFiles : picsioConfig.access.download);
      const isAllowedDeleting = !asset.trashed && picsioConfig.isMainApp && asset.permissions.deleteAssets;
      const isAllowedUploadingRevision = !asset.trashed
        && asset.canUploadRevisions
        && picsioConfig.isMainApp
        && asset.permissions.upload;

      state = {
        ...state,
        spinnerText:
          asset.uploadRevisionProgress !== null
            ? `Uploading revision ${asset.uploadRevisionProgress}%...`
            : prevState.spinnerText,
        isLoaded: true,
        isAllowedDownloading,
        isAllowedDeleting,
        isAllowedUploadingRevision,
      };
    }
    return state;
  }

  componentDidMount() {
    const { props } = this;
    const isImportingInProcess = [ASYNC_JOB_STATUS_WAITING, ASYNC_JOB_STATUS_RUNNING].includes(props.asset?.importing);
    const isImportingFailed = [ASYNC_JOB_STATUS_FAILED, ASYNC_JOB_STATUS_REJECTED].includes(props.asset?.importing);
    this.subscribe();
    window.addEventListener('revision:added', this.fetchData.bind(this, null), false);
    // hideImport and undelegate all events. @TODO: do we need it now?
    if (picsioConfig.isMainApp && props.importOpened) {
      props.mainActions.closeImport();
    }

    window.dispatchEvent(new Event('preview:opened'));

    if (!props.asset) {
      props.actions.getTmpAssets([props.id]);
    } else if (props.asset.archived) {
      Logger.log('User', 'ArchivedAssetOpened', { assetId: props.id });
    }
    Logger.log('User', 'PreviewShow', { assetId: props?.id, mimeType: props?.asset?.mimeType });
    this.runHotkeyListeners();
    if (props.asset && !isImportingInProcess && !isImportingFailed) this.fetchData(props.asset);
    this.checkThumbnailing();
    if (props.catalogViewMode === 'geo') this.preloadNeighbors();
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this;
    const isImportingInProcess = [ASYNC_JOB_STATUS_WAITING, ASYNC_JOB_STATUS_RUNNING].includes(props.asset?.importing);
    const isImportingFailed = [ASYNC_JOB_STATUS_FAILED, ASYNC_JOB_STATUS_REJECTED].includes(props.asset?.importing);

    if (prevProps.asset !== null && props.asset === null && picsioConfig.isMainApp) {
      /** if asset trashed -> it removed from store */
      if (prevProps.id === props.id) {
        props.actions.removeTmpItems();
        back(null, 'previewView');
      } else {
        /** if navigate to asset not from catalog (linkedAssets in Preview) */
        props.actions.getTmpAssets([props.id]);
      }
    }

    if (props.asset && !state.dataIsRecieved && !isImportingInProcess && !isImportingFailed) {
      this.fetchData(props.asset);
    }

    if (prevProps.id !== props.id) {
      if (!isImportingInProcess && !isImportingFailed) {
        this.fetchData(props.asset);
      }
      this.preloadNeighbors();
      if (!picsioConfig.isSingleApp) {
        Logger.log('User', 'PreviewShow', { assetId: props?.id, mimeType: props?.asset?.mimeType });
      }
    }
    if (prevProps.asset?.importing !== props.asset?.importing) {
      this.setState({ isImportingInProcess, isImportingFailed });
    }
    this.checkThumbnailing();
  }

  componentWillUnmount() {
    window.dispatchEvent(new Event('preview:closed'));
    this.unsubscribe();
    window.removeEventListener('revision:added', this.fetchData, false);
    this.stopHotkeyListeners();
    if (this.poller) this.poller.stop();
  }

  checkThumbnailing = () => {
    if (this.state.isThumbnailing) {
      if (this.poller) this.poller.stop();
      if (this.props.asset.thumbnail) {
        this.poller = pollImage(this.props.asset.thumbnail.big);
      }
    } else if (this.poller) {
      this.poller.stop();
    }
  };

  runHotkeyListeners = () => {
    window.addEventListener('hotkeys:preview:left', this.prev, false);
    window.addEventListener('hotkeys:preview:right', this.next, false);
    window.addEventListener('hotkeys:preview:esc', this.handleDestroy, false);
    window.addEventListener('hotkeys:preview:commandI', () => this.handlePanels('details'), false);
    window.addEventListener('hotkeys:preview:commandH', () => this.handlePanels('history'), false);
  };

  stopHotkeyListeners = () => {
    window.removeEventListener('hotkeys:preview:left', this.prev, false);
    window.removeEventListener('hotkeys:preview:right', this.next, false);
    window.removeEventListener('hotkeys:preview:esc', this.handleDestroy, false);
    window.removeEventListener(
      'hotkeys:preview:commandI',
      () => this.handlePanels('details'),
      false,
    );
    window.removeEventListener(
      'hotkeys:preview:commandH',
      () => this.handlePanels('history'),
      false,
    );
  };

  fillRevisionNumberMap = (revisions) => {
    const { revisionsNumbers } = this.state;

    revisions.forEach((item) => {
      if ('id' in item) {
        revisionsNumbers[item.id] = item.revisionNumber;
      }
    });
  };

  isSupportedForDiff = () => {
    const { SUPPORTED_DIFF_FORMATS } = CONSTANTS.formats;
    const {
      mimeType, customThumbnail, thumbnailing, isPdf, customVideo,
    } = this.props.asset || {};
    const { useGdPdfViewer } = picsioConfig.isMainApp ? this.props.user.settings || {} : window.websiteConfig;

    if (isPdf && !useGdPdfViewer) return true;

    const isSupportedImage = SUPPORTED_DIFF_FORMATS.includes(mimeType)
      || (!!customThumbnail && thumbnailing !== 'waiting' && thumbnailing !== 'running');
    if (isSupportedImage) return true;

    const isSupportedVideo = !!customVideo || (mimeType && mimeType === 'video/mp4');
    if (isSupportedVideo) return true;

    return false;
  };

  fetchData = async (_model) => {
    const model = _model || this.props.asset;
    if (!model) return;

    const isImportingInProcess = [ASYNC_JOB_STATUS_WAITING, ASYNC_JOB_STATUS_RUNNING].includes(model?.importing);
    const isImportingFailed = [ASYNC_JOB_STATUS_FAILED, ASYNC_JOB_STATUS_REJECTED].includes(model.importing);

    this.setState({
      isLoadedHistoryItemsLoaded: false, dataIsRecieved: true, isImportingInProcess, isImportingFailed,
    });

    try {
      const [{ data: revisions }, { data: comments }] = await helpers.fetchData(
        model._id,
        !model.canHaveRevisions,
      );
      const nextMarkerNumber = helpers.setMarkersNumber(comments);

      const approvalsIds = [];
      let techComments = [];
      const { revisionsNumbers } = this.state;

      if (revisions.length > 0) {
        helpers.normalizeRevisions(revisions);

        this.isLastRevisionTechnical = Boolean(revisions[revisions.length - 1].technical);
        const lastRevision = this.isLastRevisionTechnical && revisions.length > 1 ? revisions[revisions.length - 2] : revisions[revisions.length - 1];
        this.lastRevisionID = lastRevision.id;
        this.lastRevisionNumber = lastRevision.revisionNumber;
        revisions.forEach((item) => {
          item.theSameAs = revisions
            .filter((rev) => rev.md5Checksum === item.md5Checksum)
            .map((rev) => rev.revisionNumber)
            .filter((number) => number !== item.revisionNumber);
        });
      }
      if (!model.canHaveRevisions) {
        this.lastRevisionID = '0';
        this.lastRevisionNumber = 1;
      }

      // generate approvals revisions and comments
      if (model.approvals) {
        this.fillRevisionNumberMap(revisions);
        const modelApprovals = model.approvals.sort((date1, date2) => {
          if (date1.timestamp > date2.timestamp) { return -1; }
          if (date1.timestamp < date2.timestamp) { return 1; }
          return 0;
        });

        if (revisions.length > 0) {
          revisions.forEach((item) => {
            const approvedRevision = modelApprovals.find((approve) => approve.id === item.id);
            if (approvedRevision && approvedRevision.approved) { approvalsIds.push(approvedRevision.id); }
          });
        }

        techComments = model.approvals.map((approve) => helpers.makeTechComment(
          approve.approved,
          approve.id,
          approve.initiator,
          approve.timestamp,
          revisionsNumbers[approve.id],
          approve.userAvatar,
          approve.userDisplayName,
        ));
      }

      let historyItems = helpers.sortData(revisions.concat(comments).concat(techComments));
      historyItems = historyItems.map((item) => {
        const { userAvatar, uploader } = item;
        if (uploader && uploader.avatar) {
          return { ...item, uploader: { ...uploader, avatar: normalizeUserAvatarSrc(uploader.avatar) } };
        }
        if (userAvatar) {
          return { ...item, userAvatar: normalizeUserAvatarSrc(userAvatar) };
        }
        return item;
      });

      // if first element is not revision
      if (revisions.length === 0) {
        helpers.addInitialRevision(historyItems, model.canHaveRevisions);
      } else if (historyItems[0].revisionNumber && historyItems[0].revisionNumber === undefined) {
        helpers.addInitialRevision(historyItems);
      }
      const revisionsWithMarkers = helpers.setRevisionIdToComment(historyItems);

      const commentsFromHistoryItems = historyItems.filter((i) => !!i.revisionID);
      this.setCommentsWithMarkers(commentsFromHistoryItems, this.lastRevisionID || null);

      this.setState({
        isLoadedHistoryItemsLoaded: true,
        nextMarkerNumber,
        revisionsWithMarkers,
        historyItems,
        isSupportedForDiff: this.isSupportedForDiff(),
        revisionsNumbers,
        approvals: approvalsIds,
        commentsCount: comments.length,
      }, () => {
        if (window.location.hash) {
          this.scrollToElement(window.location.hash.substring(1));
        } else {
          this.scrollToBottom();
        }
        // This function makes markers on assets visible by default
        this.toggleRevisionComments(this.getActiveRevisionID());
      });
    } catch (error) {
      const errorStatus = utils.getStatusFromResponceError(error);
      if (errorStatus === 404) {
        showFileDeletedDialog();
      } else {
        Logger.error(new Error('Can not load revisions or comments'), { error, showDialog: true }, [
          'showWriteToSupportDialog',
          (error && error.message) || 'NoMessage',
        ]);
      }
      this.setState({ isLoadedHistoryItemsLoaded: true });
    }
  };

  scrollToBottom = () => {
    if (this.$list.current) {
      this.$list.current.scrollTop = this.$list.current.scrollHeight;
    }
  };

  /**
   * Generation of Approve/disaprove comment
   * @param {bool} isApproved - approved/disapproved status
   * @param {string} initiatorID - initiator ID
   * @param {string} timestamp - approved/disapproved date
   * @param {bool} isApproveDisabled - block approve when in progress
   * @param {string} revisionID - revision ID
   * @param {bool} highlightNotification - optional add css class for highlight element
   */
   generateApproveComment = (
     isApproved,
     initiatorID,
     timestamp,
     isApproveDisabled,
     revisionID,
     highlightNotification,
   ) => {
     const activeRevisionID = revisionID || this.getActiveRevisionID();
     const { approvals, historyItems, revisionsNumbers } = this.state;

     if (isApproved) {
       if (!approvals.includes(activeRevisionID)) {
         approvals.push(activeRevisionID);
       }
     } else {
       const index = approvals.indexOf(activeRevisionID);
       if (index >= 0) {
         approvals.splice(index, 1);
       }
     }

     this.fillRevisionNumberMap(historyItems);
     historyItems.push(
       helpers.makeTechComment(
         isApproved,
         activeRevisionID,
         initiatorID,
         timestamp,
         revisionsNumbers[activeRevisionID],
         false,
         false,
         highlightNotification,
       ),
     );

     const modelApprove = {
       approved: isApproved,
       id: activeRevisionID,
       initiator: initiatorID,
       timestamp,
     };

     this.props.actions.updateApprove(this.props.asset._id, modelApprove);
     this.setState({
       approvals, historyItems, revisionsNumbers, isApproveDisabled,
     }, this.scrollToBottom);
   };

   /**
 * Get id of active revision
 * @returns {string} revision id
 */
  getActiveRevisionID = () => this.state.idActiveState || this.lastRevisionID;

  receiveComment = (notification) => {
    const modelID = this.props.asset._id;
    const { data, initiator } = notification;
    const currentUserID = this.props.user._id;
    // if current user is an initiator
    if (initiator && initiator._id === currentUserID) return;

    // if the comment is for another image
    if (data.asset._id !== modelID) return;

    // if comment already added
    if (this.state.historyItems.some((item) => item._id === data.comment._id)) return;

    const commentToAttach = {
      ...data.comment,
      userDisplayName: data.initiatorName,
      userAvatar: initiator && initiator.avatar && normalizeUserAvatarSrc(initiator.avatar),
      highlightNotification: true,
    };

    commentToAttach.revisionID = this.lastRevisionID;
    const {
      revisionsWithMarkers, historyItems, commentsCount,
    } = this.state;
    let {
      nextMarkerNumber,
    } = this.state;
    const newData = [...historyItems, ...[commentToAttach]];
    if (commentToAttach.markers.length > 0) {
      if (revisionsWithMarkers[this.lastRevisionID] === undefined) revisionsWithMarkers[this.lastRevisionID] = 1;
      else revisionsWithMarkers[this.lastRevisionID] += 1;

      nextMarkerNumber += commentToAttach.markers.length;
    }

    this.addCommentsWithMarkers([commentToAttach]);

    this.setState({
      historyItems: newData, revisionsWithMarkers, nextMarkerNumber, commentsCount: commentsCount + 1,
    },
    this.scrollToBottom);
  };

 // Receive approve/disapprove notifications
 toggleApprove = (notification) => {
   if (this.isDifferentAsset(notification)) return;
   const currentUserID = this.props.user._id;
   const { type, initiator } = notification;

   if (currentUserID !== initiator._id) {
     const isApproved = type === 'asset.revision.approved';
     this.generateApproveComment(
       isApproved,
       initiator._id,
       notification.timestamp,
       false,
       notification.data.revisionId,
       true,
     );
   }
 };

 /**
 * Scroll list to block with id
 * @param {string} id
 */
 scrollToElement = (id) => {
   const $element = document.getElementById(id);
   if (this.$list.current && $element) {
     /** padding-top of .previewInfobox */
     const panelPaddingTop = 15;
     this.$list.current.scrollTop = $element.offsetTop - panelPaddingTop;
   } else {
     this.scrollToBottom();
   }
 };

 /**
 * Set active revision
 * @param {string} id - revision id
 * @returns {boolean} success or not
 */
  activateRevision = (id) => {
    this.setActiveRevision(id);
    return true;
  };

isDifferentAsset = (notification) => {
  const url = window.location.pathname;
  const assetId = url.substring(url.lastIndexOf('/') + 1);
  return notification.data.asset._id !== assetId;
};

/**
   * Activate diff
   * @param {string} revisionID
   * @param {number} activeRevisionNumber
   * @param {number} diffRevisionNumber
   */
 activateDiff = (revisionID, activeRevisionNumber, diffRevisionNumber) => {
   Logger.log('User', 'ActivityPanelShowDifference', { revisionID });

   this.setActiveDiff(revisionID, diffRevisionNumber, activeRevisionNumber);
 };

 /**
   * On click on "eye" on comment view
   * @param {number} index - index of comment in historyItems array
   * @param {object} params - params
   */
   toggleActiveComment = (index, params = {}) => {
     const { historyItems } = this.state;

     // if is not comment
     if (historyItems[index]._id === undefined) return;

     // if marker from inactive revision
     if (historyItems[index].revisionID !== this.getActiveRevisionID()) {
       return showDialog({
         title: localization.HISTORY.toggleActiveCommentTitle,
         text: localization.HISTORY.switchDialogText,
         textBtnCancel: localization.HISTORY.switchDialogCancel,
         textBtnOk: localization.HISTORY.switchDialogOk,
         onOk: () => {
           const revisionActivated = this.activateRevision(historyItems[index].revisionID);
           if (!revisionActivated) return;

           const prevMarkers = [...[], ...(this.props.markers || [])];
           const markers = historyItems[index].markers.map((marker) => ({
             ...marker,
             ...{
               userName: historyItems[index].userDisplayName,
               text: historyItems[index].html || historyItems[index].text,
             },
           }));
           prevMarkers.push({
             id: historyItems[index]._id,
             markers,
           });
           this.toggleMarkers(prevMarkers);
         },
       });
     }

     const markers = historyItems[index].markers.map((marker) => ({
       ...marker,
       ...{
         userName: historyItems[index].userDisplayName,
         text: historyItems[index].html || historyItems[index].text,
         mentions: historyItems[index].mentions,
       },
     }));

     const config = {};
     if (params.isVideo) {
       const { currentTime } = this.videoPlayer.current.$player.current.$video.current;
       config.video = {
         videoCurrentTime: historyItems[index].videoCurrentTime || currentTime,
       };
     }

     this.toggleMarkers(
       [
         {
           id: historyItems[index]._id,
           markers,
         },
       ],
       config,
     );
   };

   /** Revert revision
   * @param {string} revisionID
   */
  revertRevision = async (revisionID) => {
    const assetId = this.props.asset._id;
    this.setState({ isLoadedHistoryItemsLoaded: false });
    Logger.log('User', 'ActivityPanelRevertRevision');

    let newRevision;
    try {
      const { data } = await sdk.assets.revertRevision(assetId, revisionID);
      newRevision = data;
    } catch (err) {
      const _error = err instanceof Error ? err : new Error('Can not revert revision');
      Logger.error(_error, { error: err }, ['RevertRevisionFailed', (err && err.message) || 'NoMessage']);
      showErrorDialog(localization.HISTORY.textErrorRevertRevision);
    }
    if (newRevision) {
      this.props.actions.revertRevision(assetId, revisionID, newRevision.id);
      this.generateAndAddRevertRevisionItem(newRevision, true);
    }
    this.setState({ isLoadedHistoryItemsLoaded: true });
  };

  receiveCommentDeleted = (notification) => {
    if (this.isDifferentAsset(notification)) return;
    const { historyItems } = this.state;
    const deletedCommentIndex = historyItems.findIndex((item) => item._id === notification.data.comment._id);
    if (deletedCommentIndex !== -1) {
      historyItems[deletedCommentIndex].text = localization.HISTORY.textCommentDeleted;
      historyItems[deletedCommentIndex].highlightNotification = true;
      this.setState({ historyItems });
    }
  };

  receiveRevision = (notification) => {
    const modelID = this.props.asset._id;
    const currentUserID = this.props.user._id;
    const { initiator } = notification;

    // if the revision is for another image
    if (notification.data.asset._id !== modelID) return;
    // if initator the same user
    if (currentUserID === initiator._id) return;

    assetsHelpers.getRevisions(modelID).then(({ data: revisions }) => {
      const newRevision = revisions[revisions.length - 1];
      newRevision.revisionNumber = this.lastRevisionNumber + 1;
      newRevision.highlightNotification = true;
      if (!this.state.idActiveState) {
        this.activateRevision(this.lastRevisionID);
      }

      this.lastRevisionID = newRevision.id;
      this.isLastRevisionTechnical = false;
      this.lastRevisionNumber = newRevision.revisionNumber;

      this.setState({ historyItems: helpers.sortData([...this.state.historyItems, ...[newRevision]]) }, this.scrollToBottom);
    });
  };

  receiveRevertedRevision = (notification) => {
    const newRevision = notification.data.revision;
    const { initiator } = notification;

    if (newRevision) {
      newRevision.uploader = {
        avatar: initiator.avatar,
        displayName: initiator.displayName,
      };

      this.generateAndAddRevertRevisionItem(newRevision, false);
    }
  };

  generateAndAddRevertRevisionItem = (newRevision, activateRevision) => {
    newRevision.revisionNumber = this.lastRevisionNumber + 1;
    newRevision.theSameAs = this.state.historyItems
      .filter((item) => item.md5Checksum === newRevision.md5Checksum)
      .map((rev) => rev.revisionNumber)
      .filter((number) => number !== newRevision.revisionNumber);

    this.lastRevisionID = newRevision.id;
    this.isLastRevisionTechnical = false;
    this.lastRevisionNumber = newRevision.revisionNumber;

    const newHistoryItems = this.state.historyItems.map((item) => {
      if (!item.theSameAs || item.md5Checksum !== newRevision.md5Checksum) return item;
      return { ...item, theSameAs: [...item.theSameAs, newRevision.revisionNumber] };
    });

    this.setState({ historyItems: helpers.sortData([...newHistoryItems, newRevision]) }, () => {
      if (activateRevision) this.activateRevision(this.lastRevisionID);
      this.scrollToBottom();
    });
  };

  /** Toggle markers for revision
   * @param {string} revisionID
   */
     toggleRevisionComments = (revisionID) => {
       const { revisionsWithMarkers } = this.state;
       const numberOfComments = revisionsWithMarkers[revisionID];
       const numberActiveComments = this.state.markers.length;

       /**
       * Set markers for revision
       * @param {boolean} removePrev - remove previous markers, for switch revision
       */
       const setMarkers = (removePrev) => {
         const markers = removePrev ? [...[], ...this.state.markers] : [];
         this.state.historyItems.forEach((item) => {
           // if comment and comment for this revision and not already open and has markers
           if (
             item._id !== undefined
            && item.revisionID === revisionID
            && !this.state.markers.some((marker) => marker.id === item._id)
            && item.markers.length > 0
           ) {
             const newMarkers = item.markers.map((marker) => ({
               ...marker,
               ...{
                 userName: item.userDisplayName,
                 text: item.html || item.text,
                 mentions: item.mentions,
               },
             }));
             markers.push({
               id: item._id,
               markers: newMarkers,
             });
           }
         });
         this.toggleMarkers(markers);
       };

       // if revision is not active
       if (revisionID !== this.getActiveRevisionID()) {
         return showDialog({
           title: localization.HISTORY.toggleActiveCommentTitle,
           text: localization.HISTORY.switchDialogText,
           textBtnCancel: localization.HISTORY.switchDialogCancel,
           textBtnOk: localization.HISTORY.switchDialogOk,
           onOk: () => {
             const revisionActivated = this.activateRevision(revisionID);
             if (!revisionActivated) return;

             setMarkers(true);
           },
         });
       }

       if (numberOfComments > numberActiveComments) setMarkers();
       else this.toggleMarkers(this.state.markers);
     };

  /**
   * @param {Object} historyItems
   * @param {string} historyItems.text
   * @param {Array} [historyItems.markers]
   * @param {string} [historyItems.proofingGuestName]
   * @param {Array} mentions
   */
  addComment = async (historyItems, mentions = []) => {
    const { replyTo } = this.state;
    const { proofingGuestName } = historyItems;
    const data = replyTo ? { ...historyItems, replyTo: replyTo._id } : historyItems;

    this.cancelReply();
    this.setState({ isLoadedAddComment: true }, this.scrollToBottom);

    try {
      const { data: comment } = await assetsHelpers.addComment(this.props.asset._id, data);
      Logger.log('User', 'ActivityPanelCommentAdd', { assetId: this.props.asset._id });

      // convert marker numbers from String to Number
      comment.markers.forEach((marker) => {
        marker.number = Number(marker.number);
      });
      // add userName
      comment.userDisplayName = this.props.user.displayName || proofingGuestName;
      // add avatar
      comment.userAvatar = this.props.user.avatar;
      // add user ID
      comment.userId = this.props.user._id;
      // add revision id
      comment.revisionID = this.lastRevisionID;
      comment.mentions = mentions;

      const { historyItems, commentsCount } = this.state;
      if (comment.text !== '') {
        historyItems.push(comment);
        let { nextMarkerNumber, revisionsWithMarkers } = this.state;
        if (comment.markers.length > 0) {
          if (revisionsWithMarkers[this.lastRevisionID]
            === undefined) revisionsWithMarkers[this.lastRevisionID] = 1;
          else revisionsWithMarkers[this.lastRevisionID] += 1;

          nextMarkerNumber += comment.markers.length;
        }

        this.addCommentsWithMarkers([comment]);

        this.setState({
          historyItems,
          nextMarkerNumber,
          revisionsWithMarkers,
          commentsCount: commentsCount + 1,
          isLoadedAddComment: false,
        },
        () => {
          this.clearTmpMarkers();
          if (comment.markers.length > 0) {
            this.toggleActiveComment(historyItems.length - 1);
          }
          this.scrollToBottom();
        });

        this.props.actions.attachCommentToAsset(comment, this.props.asset._id);
      }
    } catch (err) {
      this.setState({ isLoadedAddComment: false });

      const _error = err instanceof Error ? err : new Error('Can not add comment');
      Logger.error(_error, { error: err }, ['CommentAddFailed', (err && err.message) || 'NoMessage']);
    }
  };

  editComment = async (editedText, commentId, mentions = []) => {
    const currentAssetId = this.props.asset._id;

    try {
      await assetsHelpers.editComment(currentAssetId, { text: editedText, commentId });
      this.props.actions.updateEditedComment(editedText, mentions, currentAssetId, commentId);
      const updatedComments = this.state.historyItems.map((comment) => {
        if (comment._id === commentId) {
          comment.text = editedText;
          comment.updatedAt = new Date();
          comment.mentions = mentions;
        }
        return comment;
      });
      this.setState({
        historyItems: updatedComments,

      });
      Logger.log('User', 'ActivityPanelCommentEdit');
    } catch (err) {
      const _error = err instanceof Error ? err : new Error('Can not edit comment');
      Logger.error(_error, { error: err }, ['CommentEditFailed', (err && err.message) || 'NoMessage']);
    }
  };

  onCommentRemove = async (commentId) => {
    try {
      await sdk.assets.deleteComment(this.props.asset._id, commentId);

      this.removeCommentMarkers(commentId);
    } catch (err) {
      const _error = err instanceof Error ? err : new Error('Can not remove comment');
      Logger.error(_error, { error: err }, ['CommentRemoveFailed', (err && err.message) || 'NoMessage']);
      showDialog({
        title: localization.HISTORY.titleDeletingCommentError,
        text: localization.HISTORY.textErrorDeletingComment,
        textBtnCancel: null,
      });
      return;
    }

    Logger.log('User', 'ActivityPanelCommentRemove');

    const { historyItems, commentsCount } = this.state;

    _remove(historyItems, (item) => item._id === commentId);

    this.setState({
      historyItems,
      commentsCount: commentsCount - 1,
    });
  };

  changeApprove = (value) => {
    const activeRevisionID = this.getActiveRevisionID();
    const url = window.location.pathname;
    const assetId = url.substring(url.lastIndexOf('/') + 1);
    Logger.log('User', 'ActivityPanelApprove', { assetId, activeRevisionID, value });

    if (value) {
      this.approve(assetId, activeRevisionID);
    } else {
      this.disapprove(assetId, activeRevisionID);
    }
  };

  approve = async (assetId, activeRevisionID) => {
    let { isApproveDisabled } = this.state;
    const timestamp = dayjs().format();

    this.setState({ isApproveDisabled: true });
    isApproveDisabled = false;

    try {
      await sdk.assets.approveRevision(assetId, activeRevisionID);
      this.generateApproveComment(true, this.props.user._id, timestamp, isApproveDisabled);
    } catch (err) {
      this.setState({ isApproveDisabled });
      const errorStatus = utils.getStatusFromResponceError(err);
      Logger.error(new Error('Can not approve revision'), { error: err }, [
        'ApproveRevisionFailed',
        (err && err.message) || 'NoMessage',
      ]);
      return showDialog({
        title: localization.HISTORY.titleApproveError,
        text: errorStatus === 403 ? localization.NO_PERMISSION_TO_ACCESS : localization.ERROR_UPDATING_ASSET,
        textBtnOk: localization.DIALOGS.btnOk,
        textBtnCancel: null,
      });
    }
  };

  disapprove = async (assetId, activeRevisionID) => {
    let { isApproveDisabled } = this.state;
    const timestamp = dayjs().format();

    this.setState({ isApproveDisabled: true });
    isApproveDisabled = false;

    try {
      await sdk.assets.disapproveRevision(assetId, activeRevisionID);
      this.generateApproveComment(false, this.props.user._id, timestamp, isApproveDisabled);
    } catch (err) {
      this.setState({ isApproveDisabled });
      Logger.error(new Error('Can not disapprove revision'), { error: err }, [
        'DisapproveRevisionFailed',
        (err && err.message) || 'NoMessage',
      ]);
      showDialog({
        title: localization.HISTORY.titleDialogError,
        text: localization.ERROR_UPDATING_ASSET,
        textBtnOk: localization.DIALOGS.btnOk,
        textBtnCancel: null,
      });
    }
  };

  receiveReaction = (notification) => {
    const currentGuestName = utils.getGuestName();
    const {
      user: { _id },
    } = this.props;
    const { commentId, initiatorName, reaction } = notification.data;
    const { userId, guestName } = reaction;
    if (((userId && _id !== userId) && picsioConfig.isMainApp) || (guestName && guestName !== currentGuestName)) {
      this.updateCommentReactions(commentId, { ...reaction, displayName: initiatorName || guestName });
    }
  };

  updateCommentReactions = (commenId, reaction) => {
    const { historyItems } = this.state;
    const { userId, value, guestName } = reaction;

    const validation = (item) => {
      if (guestName) {
        return item.value === value && item.guestName === guestName;
      }
      return item.value === value && item.userId === userId;
    };
    const updatedItems = historyItems.map((item) => {
      const { _id, reactions = [] } = item;

      if (_id === commenId) {
        const isExist = reactions.find(validation);
        const updatedReactions = isExist ? reactions.filter((i) => !validation(i)) : [...reactions, reaction];
        return {
          ...item,
          reactions: updatedReactions,
        };
      }
      return item;
    });

    this.setState({ historyItems: updatedItems });
  };

  addReaction = async (commentId, emojiName) => {
    const guestName = utils.getGuestName();

    const {
      user: { _id: userId, displayName },
      asset: { _id: assetId },
    } = this.props;

    try {
      await assetsHelpers.addReactionToComment(
        assetId,
        commentId,
        { userId, value: emojiName, guestName },
      );

      this.updateCommentReactions(commentId, {
        userId,
        displayName: displayName || guestName,
        value: emojiName,
        guestName,
      });

      Logger.log('User', 'ActivityPanelAddReaction', {
        assetId, commentId, userId, guestName,
      });
    } catch (err) {
      Logger.log('User', 'ActivityPanelAdddeactionFailed', {
        assetId, commentId, userId, guestName,
      });

      showDialog({
        title: localization.HISTORY.titleDialogError,
        text: localization.ERROR_ADD_REACTION,
        textBtnOk: localization.DIALOGS.btnOk,
        textBtnCancel: null,
      });
    }
  };

  handleEyeClick = (index) => {
    this.toggleActiveComment(index, { isVideo: !!this.getElVideo() });
  };

  cancelReply = () => {
    this.setState({ replyTo: null });
  };

  addReply = (comment) => {
    this.setState({ replyTo: comment });
  };

  subscribe = () => {
    PubSubService.subscribe('asset.comment.added', this.receiveComment);
    PubSubService.subscribe('asset.comment.deleted', this.receiveCommentDeleted);
    PubSubService.subscribe('asset.comment.reaction.changed', this.receiveReaction);
    PubSubService.subscribe('asset.revision.created', this.receiveRevision);
    PubSubService.subscribe('asset.revision.approved', this.toggleApprove);
    PubSubService.subscribe('asset.revision.disapproved', this.toggleApprove);
    PubSubService.subscribe('asset.revision.reverted', this.receiveRevertedRevision);
  };

  unsubscribe = () => {
    PubSubService.unsubscribe('asset.comment.added', this.receiveComment);
    PubSubService.unsubscribe('asset.comment.deleted', this.receiveCommentDeleted);
    PubSubService.unsubscribe('asset.comment.reaction.changed', this.receiveReaction);
    PubSubService.unsubscribe('asset.revision.created', this.receiveRevision);
    PubSubService.unsubscribe('asset.revision.approved', this.toggleApprove);
    PubSubService.unsubscribe('asset.revision.disapproved', this.toggleApprove);
    PubSubService.unsubscribe('asset.revision.reverted', this.receiveRevertedRevision);
  };

  preloadNeighbors = () => {
    const preloadRadius = 2;
    const { assetIndex } = this.props;
    let start = assetIndex - preloadRadius;
    start = start > 0 ? start : 0;
    const end = assetIndex + preloadRadius;
    const range = this.props.assetsStore.items.slice(start, end);
    range.forEach(async (asset) => {
      const size = this.props.user.previewThumbnailSize || 'default';
      if (asset.thumbnail && asset.thumbnail[size]) {
        try {
          await preloadImage(asset.thumbnail[size]);
        } catch (err) {
          Logger.info('[PREVIEW] Cannot preload neighbor');
        }
      }
    });
  };

  downloadRaw = async () => {
    try {
      if (!picsioConfig.isMainApp) {
        await checkDownloadConsent();
      }
    } catch (err) {
      // user click Cancel on Dialog
      return;
    }

    showDownloadDialog([this.props.id]);
  };

  addRevision = async (file) => {
    this.setState({ revisionIsUploading: true });
    await revisionUploader(file, this.props.asset);
    this.stopSpinner();
    this.setState({ revisionIsUploading: false });
  };

  handlePanels(panel) {
    const {
      showHistory, showDetails, analyticsIsOpened, showTranscriptions,
    } = this.state;
    const isHistoryAcivate = panel === 'history' && !showHistory;
    const isDetailsAcivate = panel === 'details' && !showDetails;
    const isAnalyticsAcivate = panel === 'analytics' && !analyticsIsOpened;
    const isTranscriptionsActive = panel === 'transcriptions' && !showTranscriptions;

    const getStatus = (status) => (status ? 'Show' : 'Hide');

    switch (panel) {
    case 'history': {
      sendEventToIntercom('Activity panel opened');
      Logger.log('User', `ActivityPanel${getStatus(isHistoryAcivate)}`);
      break;
    }
    case 'details': {
      Logger.log('User', `InfoPanel${getStatus(isDetailsAcivate)}`);
      break;
    }
    case 'analytics': {
      sendEventToIntercom('Asset analytics opened');
      Logger.log('User', `AnalyticsPanel${getStatus(isAnalyticsAcivate)}`);
      break;
    }
    case 'transcriptions': {
      sendEventToIntercom('Asset transcriptions opened');
      Logger.log('User', `TranscriptTPanel${getStatus(isTranscriptionsActive)}`);
      break;
    }
    default:
      return true;
    }

    this.setState({
      showHistory: isHistoryAcivate,
      showDetails: isDetailsAcivate,
      analyticsIsOpened: isAnalyticsAcivate,
      showTranscriptions: isTranscriptionsActive,
    });

    utils.LocalStorage.set('picsio.historypanelOpened', isHistoryAcivate);
    utils.LocalStorage.set('picsio.infopanelOpened', isDetailsAcivate);
    utils.LocalStorage.set('picsio.analyticsIsOpened', isAnalyticsAcivate);
    utils.LocalStorage.set('picsio.transcriptionspanelOpened', isTranscriptionsActive);

    window.dispatchEvent(new Event('preview:ui:resize'));
  }

  /**
   * On show revision
   * @param {String} id
   */
  setActiveRevision = (id) => {
    this.setState({
      idActiveState: id,
      idDiffState: null,
      markers: [],
      tmpMarkers: [],
    });
  };

  /**
   * Activate/deactivate diff view
   * @param {String} id - diff id
   * @param {Number} activeRevisionNumber
   * @param {Number} diffRevisionNumber
   */
  setActiveDiff = (id, activeRevisionNumber, diffRevisionNumber) => {
    if (this.props.asset.pages) {
      this.props.actions.getPages([this.props.asset._id], id);
    }
    // hide diff
    if (this.state.idDiffState === id) {
      this.setState({
        idDiffState: null,
        activeRevisionNumber: null,
        diffRevisionNumber: null,
      });
    } else {
      // show diff
      this.setState({
        idDiffState: id,
        activeRevisionNumber,
        diffRevisionNumber,
      });
    }
  };

  /**
   * Show spinner
   * @param {String} text
   */
  runSpinner = (text) => {
    this.setState({ spinnerText: text });
  };

  /**
   * Hide spinner
   * @param {Object} newState
   */
  stopSpinner = (newState = {}) => {
    this.setState({ ...newState, spinnerText: '' });
  };

  handleDestroy = () => {
    Logger.log('User', 'PreviewHide');
    this.props.actions.removeTmpItems();

    const prevSearchIndex = history.entries.findIndex((historyLocation) => isRouteSearch(historyLocation.pathname));
    const prevSelectedAssetsIndex = history.entries.findIndex((historyLocation) => isRouteSelectedAssets(historyLocation.pathname));

    if ((prevSelectedAssetsIndex !== -1) && (prevSelectedAssetsIndex < prevSearchIndex)) return back('/selectedAssets');
    return back('/search');
  };

  moveToTrash = () => {
    if (this.props.asset.inbox) {
      this.props.actions.deleteAssets([this.props.id], true);
    } else {
      this.props.actions.trashAssets([this.props.id]);
    }
  };

  retryImporting = () => {
    this.props.actions.retryImporting({ assetIds: [this.props.id] });
  }

  restoreFromTrash = () => {
    Logger.log('User', 'PreviewRestoreFromTrash', { assetId: this.props.id });
    this.runSpinner(localization.SPINNERS.LOADING);
    this.props.actions.restoreAssets([this.props.id]);
  };

  removeFromLightboard = () => {
    this.props.actions.removeFromLightboard(this.props.asset.lightboards[0], [
      this.props.asset._id,
    ]);
  };

  deleteNotFoundFile = () => {
    this.props.actions.removeNotFoundAssets([this.props.id]);

    if (this.props.next) return this.props.next();
    if (this.props.prev) return this.props.prev();
    return this.handleDestroy();
  };

  openEditor = () => {
    const { asset } = this.props;
    if (!asset) return;
    const extension = asset.fileExtension && asset.fileExtension.toLowerCase();
    Logger.log('User', 'PreviewEdit', extension || asset.mimeType);

    if (asset.isGoogleDriveDocument && asset.storageType === 'gd') {
      utils.openDocument(asset.storageId, asset.mimeType);
    } else if (asset.isEditableInPicsioEditor) {
      if (ua.browser.family === 'Safari') {
        Logger.log('UI', 'EditingNotSupportedInSafariDialog');
        showDialog({
          title: localization.PREVIEW_VIEW.editingNotSupportedInSafariTitle,
          text: localization.PREVIEW_VIEW.editingNotSupportedInSafari,
          textBtnOk: null,
          textBtnCancel: localization.DIALOGS.btnOk,
        });
      } else {
        navigate(`/develop/${this.props.id}`);
      }
    } else {
      Logger.log('UI', 'EditingNotSupportedDialog');
      showErrorDialog(localization.PREVIEW_VIEW.editingNotSupported);
    }
  };

  /**
   * Drag enter
   * @param {MouseEvent} event
   */
  onDragEnter = (event) => {
    if (!event.dataTransfer) return;

    event.preventDefault();
    event.stopPropagation();

    if (!this.props.asset.canUploadRevisions) return;
    if (picsioConfig.isMainApp && !this.props.asset.permissions.upload) return;
    if (picsioConfig.isMainApp && !this.props.user.subscriptionFeatures?.revisions) return;

    const fileType = event.dataTransfer.types[0];
    const isChromeDataTransferTypes = fileType === 'Files';
    const isMozDataTransferTypes = fileType === 'application/x-moz-file';
    const isSafariDataTransferTypes = fileType === 'public.file-url';

    if (isChromeDataTransferTypes || isMozDataTransferTypes || isSafariDataTransferTypes) {
      this.setState({ showDropArea: true });
    }
  };

  /**
   * Drag over
   * @param {MouseEvent} event
   */
  onDragOver(event) {
    event.preventDefault();
  }

  /**
   * Drop file
   * @param {MouseEvent} event
   */
  onDropFile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ showDropArea: false });

    if (!this.props.asset.canUploadRevisions) return;
    if (picsioConfig.isMainApp && !this.props.asset.permissions.upload) return;

    const { files } = event.dataTransfer;

    if (files.length > 1) {
      window.dispatchEvent(
        new CustomEvent('softError', {
          detail: {
            data: {
              message: localization.PREVIEW_VIEW.errorFilesCount,
            },
          },
        }),
      );
      return;
    }

    this.addRevision(files[0]);
  };

  /**
   * Toggle markers from history
   * @param {Array} markersToSet
   * @param {Object} params
   */
  toggleMarkers = (markersToSet, params = {}) => {
    let markers = [...this.state.markers];

    if (params.video) {
      this.videoPlayer.current.$player.current.setCurrentTime(params.video.videoCurrentTime, { pause: true });
      markers = markers.filter((marker) => {
        // remove other markers. because we cann't show few comments at the same time
        if (!markersToSet.some((item) => item.id === marker.id)) return false;
        return true;
      });
    }

    markersToSet.forEach((markersObj) => {
      const existsIndex = markers.findIndex((marker) => marker.id === markersObj.id);
      if (existsIndex > -1) {
        markers.splice(existsIndex, 1);
      } else {
        markers.push(markersObj);
      }
    });

    this.setState({ markers });
  };

  /**
   * Set to true listen to add marker
   * @param {number} nextMarkerNumber
   */
  setListenerToAddMarker = (nextMarkerNumber) => {
    this.setState({
      nextMarkerNumber,
      listenToAddMarker: true,
    });
  };

  /**
   * Set to true listen to add marker
   * @param {number} nextMarkerNumber
   */
   setListenerToAddBoundingBox = () => {
     this.setState({
       listenToAddBoundingBox: true,
     });
   };

  /**
   * Add temporary marker
   * @param {Object} marker - data for marker
   */
  addTmpMarker = (marker) => {
    if (marker === null) {
      return this.setState({ listenToAddMarker: false });
    }

    const tmpMarkers = [...this.state.tmpMarkers];
    marker.number = this.state.nextMarkerNumber + tmpMarkers.length;
    tmpMarkers.push(marker);
    this.setState({ tmpMarkers, listenToAddMarker: false });
  };

  /**
   * Add temporary boundingBox
   * @param {Object} marker - data for marker
   */
   addTmpBoundingBox = (boundingBox) => {
     if (boundingBox === null) {
       return this.setState({ listenToAddBoundingBox: false });
     }

     const tmpBoundingBoxes = [...this.state.tmpBoundingBoxes];
     tmpBoundingBoxes.push(boundingBox);
     this.setState({ tmpBoundingBoxes, listenToAddBoundingBox: false });
   };

  removeTmpBoundginBox = (index) => {
    const tmpBoundingBoxes = [...this.state.tmpBoundingBoxes];
    if (tmpBoundingBoxes[index]) {
      tmpBoundingBoxes.splice(index, 1);
      this.setState({ tmpBoundingBoxes });
    }
  };

  modifyTmpMarkers = (tmpMarkers) => this.setState({ tmpMarkers });

  modifyTmpBoundingBoxes = (tmpBoundingBoxes) => this.setState({ tmpBoundingBoxes });

  /**
   * Remove temporary marker by index
   * @param {number} index
   */
  removeTmpMarker = (index) => {
    const tmpMarkers = [...this.state.tmpMarkers];
    if (tmpMarkers[index]) {
      tmpMarkers.splice(index, 1);

      /* normalize numbers */
      if (tmpMarkers.length - 1 >= index) {
        for (let i = index; i < tmpMarkers.length; i++) {
          tmpMarkers[i].number -= 1;
        }
      }
      this.setState({ tmpMarkers });
    }
  };

  clearTmpMarkers = () => {
    this.setState({ tmpMarkers: [] });
  }

  /**
   * Download revision
   * @param {string} revisionID
   * @param {string} fileName
   */
  downloadRevision = async (revisionID, fileName) => {
    const { asset } = this.props;
    if (ua.browser.isOldSafari() && !ua.browser.isNotDesktop()) {
      showDownloadDialog([asset]);
      return;
    }

    this.runSpinner('Downloading revision ...');
    if (picsioConfig.isMainApp) {
      /** Main app */
      if (asset.watermarkId) {
        const data = {
          ids: [asset._id],
          revisionsIds: { [asset._id]: revisionID },
          isArchive: Boolean(asset.archived),
          mimeType: 'original',
          organizeByCollections: false,
          user: asset.user,
          withoutWatermark: false,
        };
        try {
          downloadAssetWithWatermark(data, asset.dataStorage, [asset], revisionID);
        } catch (err) {
          Logger.error(new Error('Error download revision with watermark', { error: err }));
        }
      } else {
        try {
          downloadAsset(asset._id, fileName, revisionID);
        } catch (err) {
          Logger.error(new Error('Error download revision', { error: err }));
        }
      }
    } else if (asset.watermarkId) {
      /** Website WITH watermark */
      const data = {
        ids: [asset._id],
        ignoreCache: true,
        revisionsIds: { [asset._id]: revisionID },
        isArchive: Boolean(asset.archived),
        mimeType: 'original',
        organizeByCollections: false,
        user: asset.user,
        withoutWatermark: false,
        enabledPolling: true,
      };
      if (picsioConfig.isProofing) {
        data.websiteId = picsioConfig.access._id;
      }
      try {
        downloadAssetWithWatermark(data, asset.dataStorage, [asset], revisionID);
      } catch (err) {
        Logger.error(new Error('Error download revision with watermark', { error: err }));
      }
    } else {
      /** Website WITHOUT watermark */
      try {
        const url = await getDownloadUrl({
          assetId: asset._id,
          revisionId: revisionID,
          allowDownloadByGS: false,
        });
        const blob = await downloadFile(url).promise;
        utils.saveFile(blob, fileName);
      } catch (err) {
        Logger.error(new Error('Error download revision on the website', { error: err }));
      }
    }
    this.stopSpinner();

    sendDownloadedNotification([asset]);
  };

  setVideoCurrentTime = (time) => {
    this.setState({ videoCurrentTime: time });
  };

  setAudioCurrentTime = (time) => {
    this.setState({ audioCurrentTime: time });
  };

  setVideoPlayerCurrentTime = (time) => {
    if (
      this.videoPlayer
      && this.videoPlayer.current
      && this.videoPlayer.current.$player
      && this.videoPlayer.current.$player.current
    ) {
      Logger.log('User', 'TranscriptTimeCodeClicked');
      this.videoPlayer.current.$player.current.setCurrentTime(time);
    }
  }

  setAudioPlayerCurrentTime = (time) => {
    if (this.audioPlayer && this.audioPlayer.current) {
      this.audioPlayer.current.setCurrentTime(time);
    }
  }

  getElVideo = () => this.videoPlayer.current
    && this.videoPlayer.current.$player.current
    && this.videoPlayer.current.$player.current.$video.current;

  addCommentsWithMarkers = (comments) => {
    const commentsWithMarkers = comments.filter((item) => item.videoCurrentTime !== undefined);

    this.setState({
      commentsWithMarkers: [...(this.state.commentsWithMarkers || []), ...commentsWithMarkers],
    });
  };

  setCommentsWithMarkers = (comments, headRevisionId) => {
    const commentsWithMarkers = comments.filter((item) => item.videoCurrentTime !== undefined);

    this.setState({ commentsWithMarkers, headRevisionId });
  };

  handleLoadedVideo = () => {
    const elVideo = this.getElVideo();
    if (elVideo) elVideo.addEventListener('play', () => this.setState({ markers: [] }));
  };

  prev = () => {
    Logger.log('User', 'PreviewPrev');
    const { assetsStore, assetIndex } = this.props;
    if (assetIndex < 1) return;

    const prevAssetId = assetsStore.items[assetIndex - 1]._id;
    navigate(`/preview/${prevAssetId}`);
  };

  next = () => {
    Logger.log('User', 'PreviewNext');
    const { assetsStore, assetIndex, actions } = this.props;

    /** if we on the 4th asset to the end of the loaded assets and store is not full -> load next page */
    if (assetIndex > assetsStore.items.length - 5 && !assetsStore.full && assetsStore.isLoaded) {
      actions.getAssets();
    }

    /** if we on the last asset -> do nothing */
    if (assetIndex >= assetsStore.items.length - 1) return;

    const nextAssetId = assetsStore.items[assetIndex + 1]._id;
    navigate(`/preview/${nextAssetId}`);
  };

  hideDropArea = () => this.state.showDropArea && this.setState({ showDropArea: false });

  isMarkersDisabled = () => {
    const { state, props } = this;
    const { asset } = props;
    const { useGdPdfViewer } = picsioConfig.isMainApp
      ? this.props.user.settings || {}
      : window.websiteConfig;
    return (
      (state.isThumbnailing && !asset.isVideo)
      || state.isConverting
      || asset.isAudio
      || asset.isObj
      || (asset.isGoogleDriveDocument && !asset.pdfProxies)
      || (asset.isPdf && useGdPdfViewer)
      || asset.isExternal
    );
  };

  removeCommentMarkers = (commenId) => {
    this.setState(({ markers }) => ({
      markers: markers.filter(({ id }) => id !== commenId),
    }));
  };

  handleHistorySwipeRight = (eventData) => {
    const { absX, dir } = eventData;
    if (absX > 70 && dir === 'Right') {
      this.handlePanels('history');
    }
  };

  handleDetailsSwipeRight = (eventData) => {
    const { absX, dir } = eventData;
    if (absX > 70 && dir === 'Right') {
      this.handlePanels('details');
    }
  };

  handleTranscriptionsSwipeRight = (eventData) => {
    const { absX, dir } = eventData;
    if (absX > 70 && dir === 'Right') {
      this.handlePanels('transcriptions');
    }
  };

  handleAssetSwipeLeft = (eventData) => {
    const { assetScale } = this.props;
    const { absX, dir, velocity } = eventData;
    if (absX > 70 && dir === 'Left' && velocity > 0.7 && assetScale <= 1) {
      this.next();
    }
  };

  handleAssetSwipeRight = (eventData) => {
    const { assetScale } = this.props;
    const { absX, dir, velocity } = eventData;
    if (absX > 70 && dir === 'Right' && velocity > 0.7 && assetScale <= 1) {
      this.prev();
    }
  };

  setCommentTimeRange = (arg) => {
    this.setState({ commentTimeRange: arg });
  };

  setAttachTime = (arg) => {
    if (arg) {
      this.setState({ isCheckedAttachTime: arg });
    } else {
      this.setState(({ isCheckedAttachTime }) => ({
        isCheckedAttachTime: !isCheckedAttachTime,
      }));
    }
  }

  handleClickOnBoundingBoxAndFacesDropdown = (boundingBox) => {
    const { faces } = this.props.asset;
    for (let i = 0; i < faces?.length; i++) {
      if (utils.compareBoundingBoxes(faces[i].boundingBox, boundingBox)) {
        this.setState(() => ({
          selectedFace: faces[i],
        }));
        break;
      }
    }
    Logger.log('Face', 'FaceRecogFacesListFaceAttached');
  };

  handleClickTempBoundingBox = (tmpBoundingBoxId) => {
    const selectedFaceArr = this.state.tmpBoundingBoxes.filter((tmpBoundingBox) => tmpBoundingBox.tempId === tmpBoundingBoxId);
    if (selectedFaceArr.length > 0) {
      this.setState({ selectedFace: selectedFaceArr[0] });
    }
  }

  handleClickOnFacesEye = () => {
    this.setState(({ facesAreShown }) => ({
      facesAreShown: !facesAreShown,
    }));
  }

  makeAggregatedDataFromAsset = (asset) => {
    const title = asset.title || '';
    const description = asset.description || '';
    const isRestricted = utils.isAssetRestricted(asset.restrictSettings) || false;
    const restrictStartAt = asset.restrictSettings.startAt || null;
    const restrictExpiresAt = asset.restrictSettings.expiresAt || null;
    const restrictStartAtPlaceholder = asset.restrictSettings.startAt || null;
    const restrictExpiresAtPlaceholder = asset.restrictSettings.expiresAt || null;
    const restrictReason = asset.restrictSettings.reason || null;
    const modifiedMetaFields = prepareModifiedMetaFields(asset.modifiedMetaFields);
    const isRemoveForever = Boolean(asset.inbox);
    const assignees = asset?.assignees?.map((assignee) => ({ _id: assignee.assigneeId }));
    const lightboards = asset.lightboards || [];
    const rolePermissions = (picsioConfig.isMainApp && this.props.user.role.permissions) || {};
    let permissions = preparePermissions(
      { ...asset.permissions, ...rolePermissions },
      asset.archived,
    );
    const tags = asset?.tags.map((tag) => ({ ...tag, path: pathHelper.checkAndRemoveRootFromTag(tag.path) }));
    if (asset.trashed) {
      permissions = getNegativePermissions(permissions);
    }
    return {
      ...asset,
      title,
      description,
      permissions,
      isRestricted,
      assignees,
      tags,
      restrictReason,
      restrictStartAt,
      restrictExpiresAt,
      restrictStartAtPlaceholder,
      restrictExpiresAtPlaceholder,
      modifiedMetaFields,
      isRemoveForever,
      lightboards,
      isArchived: asset.archived,
    };
  }

  handleHideRestrictedWarning = () => {
    this.setState({ hideRestrictedWarning: true });
  }

  render() {
    const { props, state } = this;
    const { asset, user } = props;
    const isNotSupportedVideo = asset && asset.isVideo && !asset.isSupportedVideo;
    const showPlaceholder = asset
      && !asset.is3DModel
      && !asset.isVideo
      && !asset.isAudio
      && !asset.source
      && (!asset.isPdf
        || (asset.isPdf && state.isConverting))
      && (state.isConverting
        || state.isThumbnailing
        || asset.thumbnail === null
        || (asset.thumbnail && asset.thumbnail.error));
    let isThumbnailingSkipped = false;
    if (
      showPlaceholder
      && asset.thumbnailing === 'skipped'
      && asset.thumbnailingReason
      && asset.thumbnailingReason.includes('BY_ACCOUNT_PLAN_LIMITS')

    ) {
      isThumbnailingSkipped = true;
    }
    const isThumbnailingRejected = asset && asset.thumbnailing === 'rejected';
    const showPrevArrow = props.assetIndex > 0;
    const showNextArrow = props.assetIndex > -1
      && !props.assetsStore.tmpItemIDs.includes(this.props.id)
      && props.assetIndex < props.assetsStore.items.length - 1;
    if (!asset) return <Spinner title={state.spinnerText || localization.SPINNERS.LOADING} />;
    const isAssetFromInbox = Boolean(asset.inbox);
    const { analyticsIsOpened } = state;
    const isRestricted = utils.isAssetRestricted(asset.restrictSettings);
    const rolePermissions = (picsioConfig.isMainApp && props.user.role.permissions) || {};
    const isDownloadArchiveAllowed = checkUserAccess('subscriptions', 'archive', null, user) && checkUserAccess('permissions', 'downloadArchive', null, user);
    const isWatermarkingAllowed = checkUserAccess('subscriptions', 'watermarks', null, user);
    const downloadSinglAssetShared = picsioConfig.isSingleApp && picsioConfig.access.download;
    const activeRevisionID = this.getActiveRevisionID();
    const revisionsTranscript = asset.transcripts && asset.transcripts.find((t) => t.revisionId === activeRevisionID);
    const selectedAssetAggregatedData = this.makeAggregatedDataFromAsset(asset);
    const showMarkers = !picsioConfig.isMainApp ? !!picsioConfig.access.commentShow : true;
    const restrictedWarningShow = !picsioConfig.isMainApp ? window.websiteConfig?.restrictedWarningShow : false;
    const showRestrictedWarning = !state.hideRestrictedWarning && restrictedWarningShow && isRestricted;

    return (
      <div
        className="preview"
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onMouseLeave={this.hideDropArea}
      >
        <If condition={
          !(asset
          && asset.pages
          && !asset.isPdf
          && !state.isThumbnailing)
        }
        >
          <ToolbarPreviewTop
            renameInProgress={props.assetsStore.inProgress.title}
            isAssetSelected={props.assetsStore.selectedItems.includes(asset._id)}
            selectedAssetsIds={props.assetsStore.selectedItems}
            selectAsset={props.actions.select}
            rootCollectionId={props.rootCollectionId}
            assetName={asset ? asset.name : localization.SPINNERS.LOADING}
            assetId={asset._id}
            onClose={picsioConfig.isSingleApp ? null : this.handleDestroy}
            permissions={asset.permissions}
            storageId={asset.storageId}
            storageType={asset.storageType}
            download={
              !picsioConfig.isMainApp
              && (picsioConfig.isProofing ? picsioConfig.access.downloadSingleFile : downloadSinglAssetShared)
              && asset.isDownloadable && {
                handler: this.downloadRaw,
              }
            }
            collectionsActions={props.collectionsActions}
            assetsActions={props.actions}
            analytics={{
              handler: () => this.handlePanels('analytics'),
              isActive: state.analyticsIsOpened,
            }}
            historyPanel={
              isHistoryPanelEnabled()
              && asset.hasAccess
              && !asset.trashed
              && !state.isImportingInProcess
              && !state.isImportingFailed && {
                handler: () => this.handlePanels('history'),
                isActive: state.showHistory,
              }
            }
            details={
              isActivityPanelEnabled()
              && asset.hasAccess
              && !asset.trashed
              && !state.isImportingInProcess
              && !state.isImportingFailed && {
                handler: () => this.handlePanels('details'),
                isActive: state.showDetails,
              }
            }
            transcriptions={
              picsioConfig.isMainApp
              && asset.hasAccess
              && !asset.trashed
              && !state.isImportingInProcess
              && !state.isImportingFailed && {
                handler: () => this.handlePanels('transcriptions'),
                isActive: state.showTranscriptions,
                showButton: asset.isVideo || asset.isAudio,
                transcribingParams: asset?.transcribingParams,
                transcriptViewedByInitiator: asset?.transcriptViewedByInitiator,
              }
            }
            isExternalAsset={asset.isExternal}
            source={asset.source}
            isSelectable={!state.isImportingInProcess && !state.isImportingFailed}
          />
        </If>
        <div
          className={cn('previewInfobox wrapperDetailsPanel', {
            openDetails: state.showHistory || state.openDetails,
          })}
        >
          {asset.hasAccess && state.showDetails && !asset.trashed && !state.isImportingInProcess && !state.isImportingFailed && (
            <Swipeable
              className="SwipeableDetails"
              onSwipedRight={
                state.showDetails ? (event) => this.handleDetailsSwipeRight(event) : () => {}
              }
            >
              <div className="previewInfoboxDetails">
                <Details
                  assets={[asset]}
                  assetsIds={[props.id]}
                  total={1}
                  actions={props.actions}
                  facesActions={props.facesActions}
                  mainActions={props.mainActions}
                  userActions={props.userActions}
                  tmpBoundingBoxes={state.tmpBoundingBoxes}
                  inProgress={props.assetsStore.inProgress}
                  addBoungingBox={this.setListenerToAddBoundingBox}
                  panelName="previewView.right"
                  textareaHeightNameLS="picsio.detailsDescriptionHeight"
                  panelWidth={props.panelWidth}
                  error={props.assetsStore.error}
                  user={props.user}
                  removeTmpBoundginBox={this.removeTmpBoundginBox}
                  isRemoveForever={isAssetFromInbox}
                  selectedFace={state.selectedFace}
                  onClickBoundingBox={this.handleClickOnBoundingBoxAndFacesDropdown}
                  onClickOnFacesEye={this.handleClickOnFacesEye}
                  facesAreShown={state.facesAreShown}
                  revisionID={state.idActiveState || this.lastRevisionID || state.headRevisionId}
                  selectedAssetsAggregatedData={selectedAssetAggregatedData}
                  watermarks={props.assetsStore.watermarks}
                />
              </div>
            </Swipeable>
          )}
          {asset.hasAccess && state.showHistory && !asset.trashed && !state.isImportingInProcess && !state.isImportingFailed && (
            <Swipeable
              className="SwipeableHistory"
              onSwipedRight={
                state.showHistory ? (event) => this.handleHistorySwipeRight(event) : () => {}
              }
            >
              <div className="previewInfoboxHistory">
                <History
                  customRef={this.$list}
                  lastRevisionID={this.lastRevisionID}
                  lastRevisionNumber={this.lastRevisionNumber}
                  isLastRevisionTechnical={this.isLastRevisionTechnical}
                  onScrollToBottom={this.scrollToBottom}
                  model={asset}
                  isLoaded={state.isLoadedHistoryItemsLoaded}
                  isLoadedAddComment={state.isLoadedAddComment}
                  historyItems={state.historyItems}
                  nextMarkerNumber={state.nextMarkerNumber}
                  revisionsWithMarkers={state.revisionsWithMarkers}
                  isSupportedForDiff={state.isSupportedForDiff}
                  approvals={state.approvals}
                  isApproveDisabled={state.isApproveDisabled}
                  commentsCount={state.commentsCount}
                  replyTo={state.replyTo}
                  historyActions={state.historyActions}
                  activeID={state.idActiveState}
                  diffID={state.idDiffState}
                  markers={state.markers}
                  tmpMarkers={state.tmpMarkers}
                  setActiveDiff={this.setActiveDiff}
                  onClickAddMarker={this.setListenerToAddMarker}
                  toggleMarkers={this.toggleMarkers}
                  clearTmpMarkers={this.clearTmpMarkers}
                  downloadRevision={this.downloadRevision}
                  markersDisabled={this.isMarkersDisabled()}
                  updateModelApprove={props.actions.updateApprove}
                  addCommentsWithMarkers={this.addCommentsWithMarkers}
                  setCommentsWithMarkers={this.setCommentsWithMarkers}
                  getElVideo={this.getElVideo}
                  isVideo={asset.isVideo}
                  isDownloadable={state.isAllowedDownloading}
                  user={props.user}
                  removeCommentMarkers={this.removeCommentMarkers}
                  commentTimeRange={this.state.commentTimeRange}
                  isCheckedAttachTime={this.state.isCheckedAttachTime}
                  setAttachTime={this.setAttachTime}
                  setCommentTimeRange={this.setCommentTimeRange}
                />
              </div>
            </Swipeable>
          )}
          {asset.hasAccess
            && picsioConfig.isMainApp
            && state.showTranscriptions
            && !asset.trashed
            && (asset.isVideo || asset.isAudio)
            && !state.isImportingInProcess
            && !state.isImportingFailed && (
            <Swipeable
              className="SwipeableTranscriptions"
              onSwipedRight={
                state.showTranscriptions
                  ? (event) => this.handleTranscriptionsSwipeRight(event) : () => { }
              }
            >
              <div className="previewInfoboxTranscriptions">
                <TranscriptPanel
                  assetId={asset._id}
                  assetName={asset.name}
                  panelWidth={props.panelWidth}
                  videoCurrentTime={asset.isVideo ? state.videoCurrentTime : state.audioCurrentTime}
                  setVideoPlayerCurrentTime={asset.isVideo ? this.setVideoPlayerCurrentTime : this.setAudioPlayerCurrentTime}
                  transcribing={asset.transcribing}
                  transcript={revisionsTranscript?.transcript}
                  transcriptLang={revisionsTranscript?.language}
                  transcriptPermission={asset.permissions?.autogenerateTranscript}
                  activeRevisionID={activeRevisionID}
                  isLoaded={state.isLoadedHistoryItemsLoaded}
                  manageTranscriptPermission={rolePermissions?.manageTranscripts}
                />
              </div>
            </Swipeable>
          )}
        </div>
        <div className="wrapperGallery">
          {/* Screen with analytics START */}
          {analyticsIsOpened && <AssetAnalyticsScreen asset={asset} />}
          {/* Screen with analytics END */}

          <If condition={showRestrictedWarning}>
            <PreviewWarning
              text={asset.restrictSettings?.reason || window.websiteConfig?.user?.policies?.restrictReason || localization.RESTRICT.RESTRICTED_REASON}
              onClose={this.handleHideRestrictedWarning}
            />
          </If>

          {/* Media file */}
          {asset && (
            <Swipeable
              className="SwipeableAsset"
              onSwipedLeft={showNextArrow ? (event) => this.handleAssetSwipeLeft(event) : () => {}}
              onSwipedRight={
                showPrevArrow ? (event) => this.handleAssetSwipeRight(event) : () => {}
              }
            >
              <div className="containerMediaFile">
                {/* thumbnailing/converting */}
                <Choose>
                  <When condition={showPlaceholder || !asset.hasAccess || isNotSupportedVideo}>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <Placeholder
                        model={asset}
                        thumbnailingError={
                          (asset.isEmpty && { code: 204 })
                        || (isNotSupportedVideo && { code: 205 })
                        || (asset.thumbnail && asset.thumbnail.error)
                        }
                        isThumbnailing={state.isThumbnailing}
                        isConverting={state.isConverting}
                        deleteNotFoundFile={this.deleteNotFoundFile}
                        removeFromLightboard={this.removeFromLightboard}
                        openEditor={this.openEditor}
                        addRevision={state.isAllowedUploadingRevision && this.addRevision}
                        handleDownload={state.isAllowedDownloading && this.downloadRaw}
                        moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                        isRemoveForever={isAssetFromInbox}
                        isSkipped={isThumbnailingSkipped}
                        isThumbnailingRejected={isThumbnailingRejected}
                        retryImporting={this.retryImporting}
                        isImportingInProcess={state.isImportingInProcess}
                        isImportingFailed={state.isImportingFailed}
                      />
                    </ErrorBoundary>
                  </When>
                  <When condition={asset.isVideo}>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <Video
                        ref={this.videoPlayer}
                        model={asset}
                        revisionID={state.idActiveState}
                        diffID={state.idDiffState}
                        activeRevisionNumber={state.activeRevisionNumber}
                        diffRevisionNumber={state.diffRevisionNumber}
                        listenToClick={state.listenToAddMarker}
                        addMarker={this.addTmpMarker}
                        markers={state.markers}
                        tmpMarkers={state.tmpMarkers}
                        removeMarker={this.removeTmpMarker}
                        modifyTmpMarkers={this.modifyTmpMarkers}
                        commentsWithMarkers={state.commentsWithMarkers}
                        toggleMarkers={this.toggleMarkers}
                        headRevisionId={state.headRevisionId}
                        handleLoadedVideo={this.handleLoadedVideo}
                        addRevision={state.isAllowedUploadingRevision && this.addRevision}
                        handleDownload={state.isAllowedDownloading && this.downloadRaw}
                        moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                        setCustomThumbnail={props.actions.setCustomThumbnail}
                        subscriptionFeatures={props.user.subscriptionFeatures}
                        teamId={picsioConfig.isMainApp ? props.user.team._id : undefined}
                        isRemoveForever={isAssetFromInbox}
                        setCommentTimeRange={this.setCommentTimeRange}
                        isCheckedAttachTime={this.state.isCheckedAttachTime}
                        commentsRange={this.state.commentTimeRange}
                        setVideoCurrentTime={this.setVideoCurrentTime}
                        transcript={revisionsTranscript?.transcript}
                        transcriptLang={revisionsTranscript?.language}
                        isHeadRevisionId={state.headRevisionId === this.getActiveRevisionID()}
                        handleTranscriptionsPanel={() => this.handlePanels('transcriptions')}
                        showMarkersTrack={showMarkers}
                        isShowHistoryPanel={state.showHistory}
                      />
                    </ErrorBoundary>
                  </When>
                  <When condition={asset.isPdf}>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <Pdf
                        userSettings={props.user.settings}
                        asset={asset}
                        revisionID={state.idActiveState}
                        diffID={state.idDiffState}
                        markers={state.markers}
                        tmpMarkers={state.tmpMarkers}
                        activeRevisionNumber={state.activeRevisionNumber}
                        diffRevisionNumber={state.diffRevisionNumber}
                        addMarker={this.addTmpMarker}
                        removeMarker={this.removeTmpMarker}
                        listenToClick={state.listenToAddMarker}
                        modifyTmpMarkers={this.modifyTmpMarkers}
                        addRevision={state.isAllowedUploadingRevision && this.addRevision}
                        openEditor={this.openEditor}
                        handleDownload={state.isAllowedDownloading && this.downloadRaw}
                        moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                        assetName={asset ? asset.name : localization.SPINNERS.LOADING}
                        close={this.handleDestroy}
                        downloadProofing={
                          !picsioConfig.isMainApp
                        && picsioConfig.access.download && {
                            handler: this.downloadRaw,
                          }
                        }
                        analytics={{
                          handler: () => this.handlePanels('analytics'),
                          isActive: this.state.analyticsIsOpened,
                        }}
                        history={
                          isHistoryPanelEnabled()
                        && !asset.trashed && {
                            handler: () => this.handlePanels('history'),
                            isActive: state.showHistory,
                          }
                        }
                        details={
                          isActivityPanelEnabled()
                        && !asset.trashed && {
                            handler: () => this.handlePanels('details'),
                            isActive: state.showDetails,
                          }
                        }
                        isRemoveForever={isAssetFromInbox}
                        showMarkers={showMarkers}
                      />
                    </ErrorBoundary>
                  </When>
                  <When condition={asset.isAudio}>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <Audio
                        ref={this.audioPlayer}
                        asset={asset}
                        isMainApp={picsioConfig.isMainApp}
                        revisionID={state.idActiveState}
                        addRevision={state.isAllowedUploadingRevision && this.addRevision}
                        handleDownload={state.isAllowedDownloading && this.downloadRaw}
                        moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                        isRemoveForever={isAssetFromInbox}
                        teamId={picsioConfig.isMainApp ? props.user.team._id : undefined}
                        audioCurrentTime={state.audioCurrentTime}
                        setAudioCurrentTime={this.setAudioCurrentTime}
                      />
                    </ErrorBoundary>
                  </When>
                  <When condition={asset.is3DModel}>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <Obj
                        asset={asset}
                        isMainApp={picsioConfig.isMainApp}
                        addRevision={state.isAllowedUploadingRevision && this.addRevision}
                        handleDownload={state.isAllowedDownloading && this.downloadRaw}
                        moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                        isRemoveForever={isAssetFromInbox}
                      />
                    </ErrorBoundary>
                  </When>
                  <When condition={asset.isExternal}>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <External
                        asset={asset}
                        moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                      />
                    </ErrorBoundary>
                  </When>
                  <Otherwise>
                    <ErrorBoundary className="errorBoundaryComponent">
                      <Choose>
                        <When condition={asset.pages}>
                          <Multipage
                            renameInProgress={props.assetsStore.inProgress.title}
                            isAssetSelected={props.assetsStore.selectedItems.includes(asset._id)}
                            selectedAssetsIds={props.assetsStore.selectedItems}
                            rootCollectionId={props.rootCollectionId}
                            collectionsActions={props.collectionsActions}
                            assetsActions={props.actions}
                            selectAsset={props.actions.select}
                            historyItems={state.historyItems}
                            isSupportedForDiff={state.isSupportedForDiff}
                            setActive={this.activateRevision}
                            isDownloadable={
                              state.isAllowedDownloading
                          && (!isRestricted || (isRestricted && rolePermissions.restrictedDownload))
                          && (asset.archived ? isDownloadArchiveAllowed : true)
                            }
                            showRevisions={shouldRenderRevisionsDropdown()}
                            activeRevisionID={this.getActiveRevisionID}
                            lastRevisionNumber={this.lastRevisionNumber}
                            addRevision={state.isAllowedUploadingRevision && this.addRevision}
                            isAllowedUploadingRevision={state.isAllowedUploadingRevision}
                            subscriptionFeatures={user?.subscriptionFeatures}
                            isRevisionUploading={state.revisionIsUploading}
                            model={asset}
                            revisionID={state.idActiveState}
                            diffID={state.idDiffState}
                            markers={state.markers}
                            tmpMarkers={state.tmpMarkers}
                            activeRevisionNumber={state.activeRevisionNumber}
                            diffRevisionNumber={state.diffRevisionNumber}
                            addMarker={this.addTmpMarker}
                            removeMarker={this.removeTmpMarker}
                            listenToClick={state.listenToAddMarker}
                            modifyTmpMarkers={this.modifyTmpMarkers}
                            openEditor={this.openEditor}
                            handleDownload={state.isAllowedDownloading && this.downloadRaw}
                            moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                            assetName={asset ? asset.name : localization.SPINNERS.LOADING}
                            close={this.handleDestroy}
                            downloadProofing={
                              !picsioConfig.isMainApp
                          && picsioConfig.access.download && {
                                handler: this.downloadRaw,
                              }
                            }
                            analytics={{
                              handler: () => this.handlePanels('analytics'),
                              isActive: this.state.analyticsIsOpened,
                            }}
                            history={
                              isHistoryPanelEnabled()
                          && !asset.trashed && {
                                handler: () => this.handlePanels('history'),
                                isActive: state.showHistory,
                              }
                            }
                            details={
                              isActivityPanelEnabled()
                          && !asset.trashed && {
                                handler: () => this.handlePanels('details'),
                                isActive: state.showDetails,
                              }
                            }
                            isRemoveForever={isAssetFromInbox}
                            getPages={props.actions.getPages}
                            showMarkers={showMarkers}
                          />
                        </When>
                        <Otherwise>
                          <Image
                            data={asset}
                            onClickBoundingBox={this.handleClickOnBoundingBoxAndFacesDropdown}
                            revisionID={state.idActiveState}
                            currentRevisionId={
                              state.idActiveState
                              || this.lastRevisionID
                              || state.headRevisionId
                            }
                            diffID={state.idDiffState}
                            markers={state.markers}
                            tmpMarkers={state.tmpMarkers}
                            activeRevisionNumber={state.activeRevisionNumber}
                            diffRevisionNumber={state.diffRevisionNumber}
                            addMarker={this.addTmpMarker}
                            removeMarker={this.removeTmpMarker}
                            listenToClick={state.listenToAddMarker}
                            listenToAddBoundingBox={state.listenToAddBoundingBox}
                            modifyTmpMarkers={this.modifyTmpMarkers}
                            addRevision={state.isAllowedUploadingRevision && this.addRevision}
                            openEditor={this.openEditor}
                            handleDownload={state.isAllowedDownloading && this.downloadRaw}
                            setUserOrientation={props.actions.setUserOrientation}
                            moveToTrash={state.isAllowedDeleting && this.moveToTrash}
                            isRemoveForever={isAssetFromInbox}
                            mainActions={props.mainActions}
                            assetActions={props.actions}
                            permissions={picsioConfig.isMainApp && props.user.role.permissions}
                            selectedFace={state.selectedFace}
                            modifyTmpBoundingBoxes={this.modifyTmpBoundingBoxes}
                            addTmpBoundingBox={this.addTmpBoundingBox}
                            tmpBoundingBoxes={state.tmpBoundingBoxes}
                            removeTmpBoundingBox={this.removeTmpBoundginBox}
                            onClickTempBoundingBox={this.handleClickTempBoundingBox}
                            facesAreShown={state.facesAreShown}
                            isWatermarkingAllowed={isWatermarkingAllowed}
                            showMarkers={showMarkers}
                            isThumbnailingRejected={isThumbnailingRejected}
                          />
                        </Otherwise>
                      </Choose>
                    </ErrorBoundary>
                  </Otherwise>
                </Choose>
              </div>
            </Swipeable>
          )}

          {/* arrow prev */}
          {showPrevArrow && (
            <div className="galleryArrowPrev" onClick={this.prev}>
              <span>
                <Icon name="arrowPrevPreview" />
              </span>
            </div>
          )}
          {/* arrow next */}
          {showNextArrow && (
            <div className="galleryArrowNext" onClick={this.next}>
              <span>
                <Icon name="arrowNextPreview" />
              </span>
            </div>
          )}

          {/* Placeholder trashed */}
          {asset.trashed && (
            <div className="placeholderTrashed" style={{ display: 'block' }}>
              <div className="inner">
                <span className="text">{localization.PREVIEW_VIEW.movedToTrash}</span>
                <span
                  className="btnRestore picsioDefBtn btnCallToAction"
                  onClick={this.restoreFromTrash}
                >
                  {localization.PREVIEW_VIEW.restore}
                </span>
              </div>
            </div>
          )}

          {/* Spinner */}
          {(!state.isLoaded || state.spinnerText) && (
            <Spinner title={state.spinnerText || localization.SPINNERS.LOADING} />
          )}
          {props.inProgress && <Spinner title={localization.SPINNERS.DOWNLOADING} />}
        </div>

        {/* Drop area */}
        <div
          className={`onDragenterPopup${state.showDropArea ? ' show' : ''}`}
          onDrop={this.onDropFile}
        >
          <span className="text">{localization.PREVIEW_VIEW.textDropFile}</span>
        </div>
      </div>
    );
  }
}

PreviewView.propTypes = {
  id: string.isRequired,
  assetsStore: object,
  assetIndex: number,
  asset: oneOfType([object, () => null]),
  inProgress: bool,
  panelWidth: number,
  user: object,
};

const ConnectedPreview = connect(
  (state, props) => {
    const assetIndex = state.assets.items
      ? state.assets.items.findIndex((asset) => asset._id === props.id)
      : -1;
    return {
      assetsStore: state.assets,
      assetIndex,
      asset: assetIndex > -1 ? state.assets.items[assetIndex] : null,
      inProgress:
        !picsioConfig.isMainApp && !!state.downloadList.items.find((n) => n._id === props.id),
      panelWidth: state.main.panelsWidth.previewView.right,
      catalogViewMode: state.main.catalogViewMode,
      catalogViewItemSize: state.main.catalogViewItemSize,
      importOpened: state.main.importOpened,
      user: state.user,
      assetScale: state.main.assetScale,
      rootCollectionId: picsioConfig.isMainApp && state.collections.collections.my._id,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    facesActions: bindActionCreators(facesActions, dispatch),
    mainActions: bindActionCreators(mainActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    notificationsActions: bindActionCreators({ fetchJobsStatus }, dispatch),
    collectionsActions: bindActionCreators(collections, dispatch),
  }),
)(PreviewView);

export default ConnectedPreview;
