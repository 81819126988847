/**
 * Find already added files
 * @param {Object} addedFiles
 * @param {Object[]} filesToAdd
 * @returns {Object[]} duplicated file names | [{ size: 12345, name: 'My library/collection one/file.png' }]
 */
const findLocalDuplicates = (addedFiles, filesToAdd) => filesToAdd
  .map((item) => {
    if (addedFiles[item.path]) {
      const index = addedFiles[item.path].findIndex((file) => file.name === item.name);
      if (index !== -1) return { size: item.file.size, name: item.path + item.name };
    }
    return null;
  })
  .filter(Boolean);

export default findLocalDuplicates;
