const sortGroups = (initGroups, metadataOrder) => {
  const sorted = initGroups.reduce((acc, group) => {
    const index = metadataOrder.findIndex((item) => item === group[0].id);
    if (index > -1) {
      acc.byOrder[index] = group;
    } else {
      acc.new.push(group);
    }
    return acc;
  }, { byOrder: [], new: [] });
  return [...sorted.byOrder.filter(Boolean), ...sorted.new];
};

export default sortGroups;
