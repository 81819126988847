/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import { updateUser } from '../actions/user';

export const addRequiredField = createAsyncThunk(
  'role/addRequiredField',
  async ({ roleId, fieldTitle }, {
    extra: { sdk, Toast, localization: l10n },
    getState,
    dispatch,
  }) => {
    try {
      await sdk.roles.addRequiredField(roleId, fieldTitle);

      /** update user role if needed */
      const { user, roles } = getState();
      const isCurrentUserRole = user.role?._id === roleId;
      if (isCurrentUserRole) {
        const role = roles.items.find(({ _id }) => roleId === _id);
        if (role) dispatch(updateUser({ role }));
      }
    } catch (error) {
      Toast(l10n.TOAST.REQUIRED_FIELDS.ADD, { autoClose: false, type: 'error' });
      throw error;
    }
  },
);

export const reducer = (builder) => builder
  .addCase(
    addRequiredField.pending,
    (state, { meta: { arg: { roleId, fieldTitle } } }) => {
      state.items.forEach((role) => {
        if (role._id === roleId) {
          if (!role.requiredFields) role.requiredFields = {};
          _set(role.requiredFields, fieldTitle, true);
        }
      });
    },
  )
  .addCase(
    addRequiredField.fulfilled,
    () => {
      /** do nothing */
    },
  )
  .addCase(
    addRequiredField.rejected,
    (state, { meta: { arg: { roleId, fieldTitle } }, error }) => {
      state.items.forEach((role) => {
        if (role._id === roleId) {
          _unset(role.requiredFields, fieldTitle);
        }
      });
      state.error = error;
    },
  );
