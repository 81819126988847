import React, { useCallback, memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import s from './PresetsItem.module.scss';
import PresetMenu from '../PresetMenu';
import { showDialog } from '../../../dialog';
import { deletePreset, renamePreset } from '../../../../store/websiteSettings/actions';
import l18n from '../../../../shared/strings';

const PresetsItem = ({
  isActive = false,
  image,
  preset = { isBusy: false },
  onApplyPreset,
  isEditable = false,
}) => {
  const dispatch = useDispatch();
  const presets = useSelector((state) => state.websiteSettings.presets);

  const handleDelete = useCallback(() => {
    showDialog({
      title: l18n.DIALOGS.DELETE_PRESET.TITLE,
      text: l18n.DIALOGS.DELETE_PRESET.TEXT(preset.name),
      textBtnOk: l18n.DIALOGS.DELETE_PRESET.OK,
      onOk: () => {
        dispatch(deletePreset(preset._id));
      },
    });
  }, [dispatch, preset._id, preset.name]);

  const handleRename = useCallback(() => {
    showDialog({
      title: l18n.DIALOGS.RENAME_PRESET.TITLE,
      textBtnOk: l18n.DIALOGS.RENAME_PRESET.OK,
      input: {
        label: l18n.DIALOGS.RENAME_PRESET.INPUT_LABEL,
        placeholder: l18n.DIALOGS.RENAME_PRESET.INPUT_PLACEHOLDER,
        value: preset.name,
        validate: (value) => {
          const isValid = presets.some((item) => item.name === value.trim());
          return isValid ? l18n.DIALOGS.RENAME_PRESET.INPUT_ERROR : !isValid;
        },
      },
      disableOk: (state) => {
        if (state?.errors?.input) {
          return true;
        }
        if (state.input && state.input.trim()) {
          return false;
        }
        return true;
      },
      onOk: (state) => {
        const newName = state.input;
        dispatch(renamePreset({ presetId: preset._id, newName }));
      },
    });
  }, [dispatch, presets, preset._id, preset.name]);

  const handleApply = useCallback(() => {
    onApplyPreset(preset);
  }, [onApplyPreset, preset]);

  return (
    <li
      className={cn(s.PresetsItem, isActive && s.isActive, preset.isBusy && s.isBusy)}
      onClick={!isActive ? handleApply : () => {}}
      role="presentation"
      data-testid={preset.name}
    >
      <div className={s.imageContainer}>
        <img src={image} width="254" height="129" alt={preset.name} />
      </div>
      <div className={s.titleContainer}>
        <div className={s.title}>{preset.name}</div>
        <If condition={isEditable}>
          <PresetMenu onDelete={handleDelete} onRename={handleRename} />
        </If>
      </div>
    </li>
  );
};

PresetsItem.propTypes = {
  isActive: PropTypes.bool,
  image: PropTypes.string.isRequired,
  preset: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isBusy: PropTypes.bool,
  }),
  onApplyPreset: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

export default memo(PresetsItem);
