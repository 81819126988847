import * as userActions from '../../reducers/user';
import Logger from '../../../services/Logger';
import sdk from '../../../sdk';
import { sortTypeFace } from '../../faces/actions';
import * as searchesActions from '../savedSearches';
import * as lightboardsActions from '../lightboards';
import * as inboxesActions from '../../inboxes/actions';
import { showDialog } from '../../../components/dialog';
import getCollections from '../collections/getCollections';
import localization from '../../../shared/strings';

const defaultSort = {
  type: 'name',
  order: 'asc',
};

const updateUserSortType = (payload) => async (dispatch, getState) => {
  const { collectionType, sortType, setBase = false } = payload;
  const prevSortType = getState().user[`${collectionType}SortType`] || defaultSort;

  try {
    dispatch(userActions.updateUserSortTypeStart(payload));

    if (sortType.type === 'custom' && setBase) {
      await sdk.users.updateUserSortType(collectionType, { ...sortType, base: prevSortType });
    } else {
      await sdk.users.updateUserSortType(collectionType, sortType);
    }

    switch (collectionType) {
    case 'collections': {
      await dispatch(getCollections());
      break;
    }
    case 'searches': {
      await searchesActions.getSavedSearches()(dispatch, getState);
      break;
    }
    case 'lightboards': {
      await lightboardsActions.getLightboards()(dispatch, getState);
      break;
    }
    case 'inboxes': {
      await dispatch(inboxesActions.getInboxes());
      break;
    }
    case 'faces': {
      await dispatch(sortTypeFace(sortType));
      break;
    }
    default:
      break;
    }

    dispatch(userActions.updateUserSortTypeSuccess({ ...payload, base: { ...(prevSortType || {}) } }));
  } catch (err) {
    dispatch(userActions.updateUserSortTypeFailure({ ...payload, prevSortType }));

    showDialog({
      title: localization.SOMETHING_WENT_WRONG.TITLE,
      text: localization.SOMETHING_WENT_WRONG.TEXT,
    });
    Logger.error(new Error('Update sort type'), { error: err }, [
      'UpdateUserSortTypeFailed',
      (err && err.message) || 'NoMessage',
    ]);
  }
};

export default updateUserSortType;
