import * as assetsHelpers from '../../../helpers/assets';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import Store from '../../index';
import * as utils from '../../../shared/utils';
import Toast from '../../../components/Toast';
import TYPES from '../../action-types';

import asyncCheckMultipleChanges from './asyncCheckMultipleChanges';
import { reloadCurrentPage } from '../../../helpers/history';

/**
 * Change color on assets
 * @param {string[]} ids
 * @param {string} value - must be one of "red | yellow | green | blue | purple | nocolor"
 */
const changeColor = (ids, value) => async (dispatch) => {
  try {
    await asyncCheckMultipleChanges(ids.length);
  } catch (error) {
    /** User cancelled operation */
    return;
  }

  try {
    dispatch({
      type: TYPES.ASSETS.CHANGE_COLOR.START,
    });

    await assetsHelpers.setColor(ids, value);

    dispatch({
      type: TYPES.ASSETS.CHANGE_COLOR.COMPLETE,
      payload: {
        ids,
        keys: ['color'],
        values: [value],
        userId: Store.getState().user._id,
      },
    });
  } catch (error) {
    const errorStatus = utils.getStatusFromResponceError(error);
    const errorMessage = errorStatus === 403
      ? localization.NO_PERMISSION_TO_ACCESS
      : localization.DETAILS.textCantUpdate('color');
    Toast(errorMessage, { autoClose: false, type: 'error', onOk: reloadCurrentPage, btnOkValue: localization.HISTORY.textBtnRefresh });
    Logger.error(new Error('Error change color'), { error }, [
      'ChangeColorFailed',
      (error && error.message) || 'NoMessage',
    ]);
    dispatch({ type: TYPES.ASSETS.CHANGE_COLOR.FAILED, error });
  }
};

export default changeColor;
