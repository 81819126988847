import getPermissionsCollection from '@picsio/db/src/collections/roles/getPermissionsCollection';

import { mergePermissions } from '../../../shared/utils';

/** For inbox app default value is `true`; form main app - `false` */
const defaultPermissions = getPermissionsCollection({ defaultValue: !!window.inbox });
/**
 * Combine permissions for files
 * @param {*[]} files
 * @returns {*}
 */
export default function groupPermissions(files) {
  return mergePermissions(
    files.map((file) => {
      /** permissions on collection may be not full, so we need to fill it with negative permissions */
      const permissions = file.collection?.permissions || getPermissionsCollection();
      return {
        ...defaultPermissions,
        ...permissions,
      };
    }),
  );
}
