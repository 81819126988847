import picsioUtils from '@picsio/utils';

export default function createAssetData({
  storageResponse,
  uploadItem,
  rootCollectionId,
  isUploadToS3,
  uploadId,
}) {
  const { collection, lightboard, file } = uploadItem;
  const data = { ...storageResponse, uploadId };
  /** for external assets */
  if (uploadItem.source) data.source = uploadItem.source;

  if (isUploadToS3) {
    const mimeType = picsioUtils.lookupMimeType(uploadItem.name.split('.').pop())
      || 'application/octet-stream';
    data.fileSize = uploadItem.file.size;
    data.name = uploadItem.name;
    data.mimeType = mimeType;
  } else {
    /** if upload result from Google Drive */
    data.googleId = storageResponse.id;
    delete data.id;
  }
  if (collection) {
    if (rootCollectionId !== collection._id) {
      data.tags = [{ _id: collection._id }];
    }
  } else if (lightboard) {
    data.lightboards = [{ _id: lightboard._id }];
  }

  if (file?.importFrom) {
    data.importFrom = file.importFrom;
    data.name = file.name;
  }

  return data;
}
