import sdk from '../../sdk';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';
import { showErrorDialog } from '../../components/dialog';

/**
 * Upload custom thumbnail
 * @param {string} assetId
 * @param {Blob} blob
 * @returns {string} - thumbnail url
 */
export default async function uploadThumbail(assetId, blob, setCustomThumbnail) {
  try {
    const { data } = await sdk.assets.addThumbnail(assetId, blob);
    /** sometimes the previous thumbnail is displayed, this causes a delay of 2000ms */
    const {
      _id,
      revisionId,
      thumbnailLink,
      thumbnail,
    } = data;
    setTimeout(() => {
      setCustomThumbnail(
        _id,
        revisionId,
        thumbnailLink,
        thumbnail,
      );
    }, 2000);
  } catch (err) {
    showErrorDialog(localization.DIALOGS.ERROR_CREATING_THUMBNAIL);
    Logger.error(new Error('Error upload custom thumbnail'), { error: err }, [
      'UploadCustomThumbnailFailed',
      (err && err.message) || 'NoMessage',
    ]);
  }
  return null;
}
