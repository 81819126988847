import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

import localization from '../../shared/strings';

import FacesDropdown from '../facesDropdown';

const Faces = ({ addFace, selectedFaces, removeFace, changed, fromSearch }) => (
  <div className="itemSearchFilters searchDropdownTop">
    <div className="labelItemSearchFilters">{localization.SEARCH.text.Faces}</div>
    <div className="contentItemSearchFilters">
      <FacesDropdown
        placeholder={localization.DROPDOWN.placeholderFaces}
        placeholderIcon="placeholderFace"
        icon="placeholderFace"
        filterText={localization.DROPDOWN.chooseFaces}
        createText={localization.DROPDOWN.createFace}
        checkedItems={selectedFaces}
        onCheckedHandler={addFace}
        onUncheckedHandler={removeFace}
        canCreate={false}
        changed={changed}
        fromSearch={fromSearch}
      />
    </div>
  </div>
);

Faces.propTypes = {
  selectedFaces: PropTypes.array,
  addFace: PropTypes.func,
  removeFace: PropTypes.func,
  changed: PropTypes.bool,
  fromSearch: PropTypes.bool,
};

export default Faces;
