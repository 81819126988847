import React, { useCallback, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { oneOf, string } from 'prop-types';
import { ClockIcon, File } from '@picsio/icons';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';
import { formatNumberWithSpaces, parseTime } from '../../shared/utils';
import { navigate } from '../../helpers/history';
import l18n from '../../shared/strings';

const AiCreditsBadge = ({ url = '/billing?tab=overview', type = 'credits' }) => {
  const team = useSelector((state) => state.user.team);
  const addonsFeatures = useSelector((state) => state.user.addonsFeatures || {});

  const aiCounters = useMemo(() => (team?.aiCounters || {}), [team?.aiCounters]);
  const transcribingMinutesUsed = useMemo(() => (aiCounters.transcribingMinutesUsed || 0), [aiCounters.transcribingMinutesUsed]);
  const transcribingMinutes = useMemo(() => (aiCounters.transcribingMinutes || 0), [aiCounters.transcribingMinutes]);
  const creditsUsed = useMemo(() => (aiCounters.creditsUsed || 0), [aiCounters.creditsUsed]);
  const credits = useMemo(() => (aiCounters.credits || 0), [aiCounters.credits]);
  const tier = useMemo(() => (addonsFeatures.aiKit?.title || 0), [addonsFeatures.aiKit?.title]);

  const numberOfCredits = useMemo(() => {
    const result = (team?.aiCreditsPaid || 0) - (team?.aiCreditsUsed || 0);
    const existingAiCredits = Math.max(result, 0); /** Do not render a negative value */

    const minutesResult = (team?.transcribingMinutesPaid || 0) - (team?.transcribingMinutesUsed || 0);
    const existingMinutes = Math.max(minutesResult, 0); /** Do not render a negative value */

    switch (type) {
    case 'minutes':
      return (transcribingMinutesUsed > transcribingMinutes ? 0 : transcribingMinutes - transcribingMinutesUsed || 0) + existingMinutes; /** Add existing minutes to new AI Kit */
    default:
      return (creditsUsed > credits ? 0 : credits - creditsUsed || 0) + existingAiCredits; /** Add existing old AI credits to new AI Kit */
    }
  }, [credits, creditsUsed, team?.aiCreditsPaid, team?.aiCreditsUsed, team?.transcribingMinutesPaid, team?.transcribingMinutesUsed, transcribingMinutes, transcribingMinutesUsed, type]);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    navigate(url);
  }, [url]);

  const tooltipText = useMemo(() => {
    let paid = team?.aiCreditsPaid || 0;
    let used = team?.aiCreditsUsed || 0;
    const paidMinutes = team?.transcribingMinutesPaid || 0;
    const usedMinutes = team?.transcribingMinutesUsed || 0;

    if (aiCounters) {
      paid = type === 'minutes' ? (transcribingMinutes + paidMinutes) : (credits + paid);
      used = type === 'minutes' ? (transcribingMinutesUsed + usedMinutes) : (creditsUsed + used);
    }

    return type === 'minutes'
      ? l18n.DETAILS.aiBadgeMinutesTooltipText(tier, parseTime((paid - used) * 60, { type: 'hoursAndMinutes' }), parseTime(used * 60, { type: 'hoursAndMinutes' }), styles.aiBadgeTooltip)
      : l18n.DETAILS.aiBadgeAssetsTooltipText(tier, formatNumberWithSpaces(paid - used), used, styles.aiBadgeTooltip);
  }, [aiCounters, credits, creditsUsed, team?.aiCreditsPaid, team?.aiCreditsUsed, team?.transcribingMinutesPaid, team?.transcribingMinutesUsed, tier, transcribingMinutes, transcribingMinutesUsed, type]);

  return (
    <Tooltip content={tooltipText} placement="top">
      <div
        className={styles.badge}
        tabIndex={0}
        onKeyPress={handleClick}
        role="button"
        onClick={handleClick}
        data-testId="badgeAI"
      >
        <span className={styles.icon}>
          <Choose>
            <When condition={type === 'minutes'}>
              <ClockIcon />
            </When>
            <Otherwise>
              <File />
            </Otherwise>
          </Choose>
        </span>
        {type === 'minutes' ? parseTime(numberOfCredits * 60, { type: 'hoursAndMinutes' }) : formatNumberWithSpaces(numberOfCredits)}
      </div>
    </Tooltip>
  );
};

AiCreditsBadge.propTypes = {
  url: string,
  type: oneOf(['credits', 'minutes']),
};

export default memo(AiCreditsBadge);
