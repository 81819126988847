import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES from '../../action-types';
import { navigate } from '../../../helpers/history';

import getThumbnails from './getThumbnails';

/**
 * Get assets by ids
 * @param {string[]} ids - assets ids
 */
const getSelectedAssets = createAsyncThunk(
  'assets.getSelectedAssets',
  async (ids, {
    dispatch, getState,
    extra: {
      showErrorDialog, Logger, localization, UiBlocker, sdk,
    },
  }) => {
    const { user, assets } = getState();
    try {
      UiBlocker.block('Loading data...');
      const { data } = await sdk.assets.getMany(ids || assets.selectedItems);
      // we need 'rolePermissions=null' for Proofing. We don't need to check assets permissions there.
      const { permissions: rolePermissions = null } = user?.role || {};
      const _ids = data.assets.map((asset) => asset._id);
      if (_ids.length) getThumbnails(_ids)(dispatch, getState);

      dispatch({
        type: TYPES.ASSETS.GET_SELECTED_ASSETS.COMPLETE,
        payload: {
          items: data.assets,
          selectedItemsIds: _ids,
          rolePermissions,
        },
      });
      navigate('/selectedAssets');
    } catch (error) {
      Logger.error(new Error('Error while trying get selected assets'), { error });
      dispatch({ type: TYPES.ASSETS.GET_SELECTED_ASSETS.FAILED, error });
      showErrorDialog(localization.CATALOG_VIEW.cantLoadData);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default getSelectedAssets;
