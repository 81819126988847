import ua from '../../../ua';
import Logger from '../../../services/Logger';
import sdk from '../../../sdk';

const removeMobileAppPushSubscription = async (user) => {
  const { pushSubscription } = user;
  if (pushSubscription) {
    try {
      await sdk.users.removePushSubscription(pushSubscription);
    } catch (err) {
      Logger.error(new Error('Remove mobile app subscription failed'), { error: err }, [
        'removeMobileAppSubscriptionFailed',
        (err && err.message) || 'NoMessage',
      ]);
      throw err;
    }
  } else {
    Logger.info('Mobile app pushSubscription is undefined');
  }
};

const removeBrowserPushSubscription = async () => {
  Logger.info('removing WebPush Subscription');
  /** remove webPush subscription from DB */
  try {
    const reg = await navigator.serviceWorker.ready;
    Logger.info('removing WebPush Subscription reg: ', JSON.stringify(reg));
    if (reg && 'pushManager' in reg) {
      const subscription = await reg.pushManager.getSubscription();
      if (subscription) {
        await sdk.users.removePushSubscription(subscription);
      }
    }
  } catch (err) {
    Logger.error(new Error('Remove webPush subscription failed'), { error: err }, [
      'removeWebPushSubscriptionFailed',
      (err && err.message) || 'NoMessage',
    ]);
    throw err;
  }
};

const removeCurrentUserWebPushSubscription = async (user) => {
  if (ua.isMobileApp()) {
    await removeMobileAppPushSubscription(user);
  } else if ('serviceWorker' in navigator) {
    await removeBrowserPushSubscription();
  }
};

export default removeCurrentUserWebPushSubscription;
