import React from 'react';
import {
  arrayOf, element, func, oneOfType, string,
} from 'prop-types';
import styles from './styles.module.scss';

const Placeholder = ({ children }) => (
  <div className={styles.placeholder}>{children}</div>
);

Placeholder.defaultProps = {
  children: null,
};

Placeholder.propTypes = {
  children: oneOfType([string, element, func, arrayOf(oneOfType([string, element, func]))]),
};

export default Placeholder;
