export default function createRevisionData(storageAsset) {
  const {
    headRevisionId, revisionId, fileSize, imageMediaMetadata,
  } = storageAsset;
  const { width, height, rotation } = imageMediaMetadata || {};
  return {
    revisionId: headRevisionId || revisionId,
    fileSize,
    width,
    height,
    rotation,
  };
}
