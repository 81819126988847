import Q from 'q';
import axios from 'axios';

/** Download by Google Storage */
function downloadByGS(downloadUrl, pollInterval = 5000) {
  let cancelled = false;
  const promise = Q.Promise(async (resolve, reject, notify) => {
    async function doDownload(count = 0) {
      if (cancelled) return null;

      count++;
      let res;
      try {
        const { data } = await axios.get(downloadUrl);
        res = data;
      } catch (error) {
        reject(error);
        return;
      }

      const { url, status } = res;

      if (url) {
        resolve(url);
        return;
      }
      if (status) notify(status);

      if (count > 360) {
        reject(`No download url after ${count} tries...`);
        return;
      }
      setTimeout(() => doDownload(count), pollInterval);
    }
    await doDownload();
  });

  const cancel = () => (cancelled = true);

  return { promise, cancel };
}

export default downloadByGS;
