export default function normalizeRoles(roles) {
  return roles.map((item) => {
    const role = { ...item };
    // In some cases we can lost role.name.
    // So it is a visual helper for team-managers. They can rename this role.
    if (!role.name) {
      role.name = 'Role name is undefined';
      role.error = 'nameError';
    }

    // Update required fields if user has old naming
    if (role.requiredFields && role.requiredFields['title+description']) {
      delete role.requiredFields['title+description'];
      if (!('title' in role.requiredFields)) role.requiredFields.title = true;
      if (!('description' in role.requiredFields)) role.requiredFields.description = true;
    }

    return role;
  });
}
