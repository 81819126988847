import picsioConfig from '../../../../config';
import ua from '../ua';
import CONSTANTS from '../shared/constants';

export default function checkBrowser() {
  if (CONSTANTS.browser[ua.browser.family] > ua.browser.major) {
    window.location = CONSTANTS.OLD_BROWSER_URL;
  }
  if (ua.browser && ua.browser.family) {
    document.body.classList.add(ua.browser.family.replace(/\s/g, ''));
  }
  if (ua.browser.isNotDesktop()) {
    document.body.classList.add('mobile');
  }

  const isMobileApp = picsioConfig.isMainApp && ua.isMobileApp();
  if (isMobileApp) {
    document.body.classList.add('isMobileApp');
  }

  const isIosMobileApp = (picsioConfig.isMainApp && ua.isMobileApp() && ua.getPlatform() === 'ios') || false;
  if (isIosMobileApp) {
    document.body.classList.add('iosMobileApp');
  }
}
