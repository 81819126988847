// import localization from '../../../shared/strings';
import * as utils from '../../../shared/utils';

/**
 * Make faces tree from list
 * @param {Object[]} allFaces
 * @param {string} sortType
 * @returns {Object}
 */
function facesMakeTree(allFaces) {
  const list = allFaces.map((node) => ({ ...node }));
  const tree = {
    favorites: {
      title: 'Favorites',
      root: true,
      _id: 'favorites',
      isOpen:
      utils.LocalStorage.get('picsio.facesTree.favorites.open') === null
      || utils.LocalStorage.get('picsio.facesTree.favorites.open'),
      nodes: [],
    },
    allFaces: {
      title: 'All persons',
      root: true,
      _id: 'allFaces',
      isOpen:
      utils.LocalStorage.get('picsio.facesTree.allFaces.open') === null
      || utils.LocalStorage.get('picsio.facesTree.allFaces.open'),
      nodes: [],
    },
    unnamed: {
      title: 'Unnamed faces',
      root: true,
      _id: 'unnamed',
      isOpen:
      utils.LocalStorage.get('picsio.facesTree.unnamed.open') === null || utils.LocalStorage.get('picsio.facesTree.unnamed.open'),
      nodes: [],
    },
  };

  tree.unnamed.nodes = list
    .map((face) => {
      /** add to favorites */
      if (face.isFavorite) tree.favorites.nodes.push(face);

      /** add to allFAces */
      if (!face.unnamed) {
        tree.allFaces.nodes.push(face);
      }
      return face;
    })
    .filter((face) => !!face.unnamed);

  return tree;
}

export default facesMakeTree;
