/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import getTeammatesWithRole from '../helpers/roles/getTeammatesWithRole';

export const removeRole = createAsyncThunk(
  'roles/remove',
  async (roleId, {
    extra: {
      sdk, Logger, utils, localization: l10n, showDialog, showDialogAsync,
    },
    getState,
  }) => {
    try {
      Logger.log('User', 'SettingsMyTeamRolesRemove');

      const teammates = getState().teammates.items;
      const users = getTeammatesWithRole(roleId, teammates);

      if (!users.length) {
        Logger.log('UI', 'ConfirmRemoveEmptyRoleDialog');
        const { promise } = showDialogAsync({
          title: l10n.TEAMMATES.titleDeleteRole,
          text: l10n.TEAMMATES.textDeleteRole,
          textBtnOk: l10n.TEAMMATES.btnOkDeleteRole,
          textBtnCancel: l10n.DIALOGS.btnCancel,
        });

        try {
          await promise;
        } catch (error) {
          /** User press "Cancel" */
          Logger.log('UI', 'ConfirmRemoveEmptyRoleDialogCancel');
          return null;
        }
      }

      await sdk.roles.delete(roleId);
      return roleId;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg');
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');

      if (errorSubcode === 'RoleInUseApiError') {
        showDialog({
          title: l10n.TEAMMATES.titleCantRemoveRole,
          text: l10n.TEAMMATES.textCantRemoveRole,
          textBtnOk: l10n.DIALOGS.btnOk,
          textBtnCancel: null,
        });
        Logger.log('UI', 'CantRemoveRoleDialog', { reason: 'RoleInUseApiError' });
        return null;
      }
      showDialog({
        title: l10n.TEAMMATES.titleCantRemoveRole,
        text: errorMessage,
        textBtnOk: l10n.DIALOGS.btnOk,
        textBtnCancel: null,
        onOk() {},
        onCancel() {},
      });

      Logger.error(
        new Error('Error remove role'),
        { Amplitude: 'CantRemoveRoleDialog', error },
        ['CantRemoveRoleDialog', (error && error.message) || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => builder.addCase(
  removeRole.pending,
  (state) => {
    state.loading = true;
    state.error = null;
  },
).addCase(
  removeRole.fulfilled,
  (state, { payload: roleId }) => {
    state.loading = false;
    state.error = null;
    if (roleId) state.items = state.items.filter(({ _id }) => _id !== roleId);
  },
).addCase(
  removeRole.rejected,
  (state, { error }) => {
    state.loading = false;
    state.error = error;
  },
);
