import React, { memo } from 'react';
import cn from 'classnames';
import { bool, func } from 'prop-types';

const Trash = ({ isActive = false, onClick }) => (
  <div
    className={cn('treeList-title PicsioCollapse', { act: isActive })}
    onKeyPress={onClick}
    tabIndex={0}
    role="button"
    onClick={onClick}
  >
    <div className="treeList-title-text">Trashed assets</div>
  </div>
);

Trash.propTypes = {
  onClick: func.isRequired,
  isActive: bool,
};

export default memo(Trash);
