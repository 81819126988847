import React, { useCallback } from 'react';
import { IconButton } from '@picsio/ui';
import { OpenIn } from '@picsio/icons';
import { string } from 'prop-types';
import Tooltip from '../Tooltip';
import Logger from '../../services/Logger';

export default function ButtonExternal({ url, favicon = null }) {
  const makeTooltipContent = useCallback(
    () => (
      <span className="catalogItem__tooltip-external">
        <If condition={favicon}>
          <img src={favicon} alt={url} height="16" width="auto" />
        </If>
        {url}
      </span>
    ),
    [url, favicon],
  );

  const handleClick = useCallback(
    () => {
      Logger.info('User', 'ThumbnailExternalSourceClicked', url);
      window.open(url, '_blank');
    },
    [url],
  );

  return (
    <Tooltip content={makeTooltipContent}>
      <IconButton
        size="md"
        className="catalogItem__button-external"
        color="inherit"
        onClick={handleClick}
        componentProps={{
          'data-testid': 'catalogItemExternal',
        }}
      >
        <Choose>
          <When condition={favicon}>
            <img src={favicon} height="16px" width="auto" alt="↗" />
          </When>
          <Otherwise>
            <OpenIn />
          </Otherwise>
        </Choose>
      </IconButton>
    </Tooltip>
  );
}

ButtonExternal.propTypes = {
  url: string.isRequired,
  favicon: string,
};
