import defaultWebsiteData from '../configs/defaultWebsiteData';
import getInitialSortType from './getInitialSortType';

const PASSWORD_STARS = '*✝*✝*✝*✝*'; // this is hard code , made with love✝ :-*

const normalizeWebsiteSettings = ({
  websiteData, collectionData, team, isNewWebsite = false, userSortingCustomFields = [],
}) => {
  let copyWebsiteData = { ...websiteData };

  if (isNewWebsite) {
    copyWebsiteData = {
      ...defaultWebsiteData,
      alias: copyWebsiteData.suggestions.alias,
    };
  } else {
    copyWebsiteData = { ...defaultWebsiteData, ...copyWebsiteData };
  }

  if (copyWebsiteData.isProtected) {
    copyWebsiteData.password = PASSWORD_STARS;
  }

  if (typeof copyWebsiteData.googleAnalyticsIdentifier !== 'string') {
    copyWebsiteData.googleAnalyticsIdentifier = '';
  }

  if (typeof copyWebsiteData.logoUrl !== 'string') {
    copyWebsiteData.logoUrl = '';
  }

  if (typeof copyWebsiteData.avatarUrl !== 'string') {
    copyWebsiteData.avatarUrl = '';
  }

  if (typeof copyWebsiteData.backgroundLoginPageUrl !== 'string') {
    copyWebsiteData.backgroundLoginPageUrl = '';
  }

  if (isNewWebsite) {
    copyWebsiteData.sortType = getInitialSortType(collectionData.sortType, copyWebsiteData.sortType);
    copyWebsiteData.overridedDisplayName = team.displayName;
    copyWebsiteData.overridedTagname = collectionData.name;
    copyWebsiteData.customGalleryTitle = collectionData.name;
  }

  if (copyWebsiteData.sortType.type.startsWith('meta.') && !copyWebsiteData.customFieldsSort) {
    copyWebsiteData.customFieldsSort = userSortingCustomFields.filter((customField) => `meta.${customField.title}` === copyWebsiteData.sortType.type);
  }

  return copyWebsiteData;
};

export default normalizeWebsiteSettings;
