import isHaveTeammatePermission from './isHaveTeammatePermission';

const checkIsSyncAllowed = (role, user) => {
  if (!user) return false;
  let isSyncAllowed = isHaveTeammatePermission('sync', user);

  if (isSyncAllowed) {
    const { allowedCollections } = role;
    if (allowedCollections && allowedCollections.length) {
      const rootCollection = allowedCollections.find((collection) => collection.path === '/root');
      isSyncAllowed = !!rootCollection;
    }
  }

  return !!isSyncAllowed;
};

export default checkIsSyncAllowed;
