import * as userActions from '../../reducers/user';
import sdk from '../../../sdk';
import * as utils from '../../../shared/utils';

const updateCustomerFooter = (footer) => async (dispatch, getState) => {
  try {
    const { data: result } = await sdk.users.updateCustomerFooter(footer);
    dispatch(
      userActions.updateUserSuccess({
        result: {
          customer: {
            ...getState().user.customer,
            invoice_settings: { ...getState().user.customer.invoice_settings, footer },
          },
        },
      }),
    );

    return result;
  } catch (error) {
    const errorMessage = utils.getDataFromResponceError(error, 'msg');
    return { error: errorMessage };
  }
};

export default updateCustomerFooter;
