import { createAsyncThunk } from '@reduxjs/toolkit';

import decrementCount from '../collections/decrementCount';

import { back, isRoutePreview } from '../../../helpers/history';
import isHaveTeammatePermission from '../../helpers/user/isHaveTeammatePermission';
import showError from './showError';
import deleteCollections from './deleteCollections';

const unarchiveAssets = createAsyncThunk(
  'archive/unarchiveAssets',
  async ({ ids, collectionId }, {
    extra: {
      sdk, utils, Toast, UiBlocker, localization,
    }, rejectWithValue, dispatch, getState,
  }) => {
    const { user } = getState();
    try {
      UiBlocker.block('Processing...');
      const { data: res } = await sdk.assets.unarchive(ids, collectionId);
      const { ids: assetsIds } = res;

      // if user unarchive asset from preview page - we move user back to search
      if (isRoutePreview()) {
        back();
      }
      const { archived } = getState().router.location.query;
      // if user on archive view - we remove unarchived assets
      const hide = !!archived;

      dispatch(deleteCollections({ ...res, user: getState().user }));
      dispatch(decrementCount(assetsIds.length));

      Toast(localization.TOAST.ASSETS_UNARCHIVED(assetsIds.length), { type: 'success', closeButton: false });

      // if unarchive all assets from collection, we need to change active collection
      const { items } = getState().assets;
      if (items.length === assetsIds.length) {
        const rootArchiveCollection = getState().archive.collections[0];
        dispatch({
          type: 'archive/setActiveCollection/fulfilled',
          payload: { collection: rootArchiveCollection },
        });
      }

      return { ids: assetsIds, hide };
    } catch (e) {
      showError(e, Toast, utils, localization);
      return rejectWithValue(e);
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default unarchiveAssets;
