import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  QuestionTransparent,
} from '@picsio/ui/dist/icons';
import { IconButton, Button } from '@picsio/ui';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';
import Tooltip from '../Tooltip';

/**
 * Help button component
 * @param {string} tooltipPosition
 * @param {string} component
 * @returns {JSX}
 */
const HelpButton = ({
  additionalClass = '',
  tooltipPosition = '',
  component = '',
  externalUrl = '',
  event = 'Help',
  text = null,
}) => {
  const path = localization.HELP_CENTER[component]?.url;
  const tooltipContent = !text ? localization.HELP_CENTER[component]?.tooltip : '';
  const handleClick = useCallback(() => {
    window.open(`${path ? `https://help.pics.io/${path}` : externalUrl}`, '_blank');
    Logger.log('User', event, component);
  }, [path, externalUrl, component, event]);

  return (
    <Tooltip content={tooltipContent} placement={tooltipPosition}>
      <Choose>
        <When condition={text}>
          <Button
            className={cn('importHelpButton', { [additionalClass]: additionalClass })}
            id="button-help"
            variant="text"
            color="primary"
            size="lg"
            startIcon={<QuestionTransparent />}
            onClick={handleClick}
          >
            {text}
          </Button>
        </When>
        <Otherwise>
          <IconButton
            id="button-help"
            className={cn('toolbarButton', { [additionalClass]: additionalClass })}
            additionalClass={additionalClass}
            onClick={handleClick}
            size="lg"
          >
            <QuestionTransparent />
          </IconButton>
        </Otherwise>
      </Choose>
    </Tooltip>
  );
};

HelpButton.propTypes = {
  text: PropTypes.string,
  additionalClass: PropTypes.string,
  tooltipPosition: PropTypes.string,
  component: PropTypes.string,
  externalUrl: PropTypes.string,
  event: PropTypes.string,
};

export default HelpButton;
